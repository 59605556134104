import React, { FunctionComponent, useCallback } from 'react';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { observer } from 'mobx-react';

import moment from 'moment';

import './includes/statusList.scss';
import { Store } from '@store/store';
import { ConfirmModalType } from '@core/models/ModalWindow';
import ModalService from '@core/services/ModalService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { SipService } from '@services/index';
import { EnumSipStatus } from '@services/sip/models';
import { Locations } from '@core/models/locations';
import { IAuxStatusesModel } from '@models/mobx-state-tree/auxStatuses.model';

import './includes/statusList.scss';
import cn from 'classnames';
import { formatTime } from '@components/main/order-page/Notifications/Notifications';


const StatusList: FunctionComponent = (): JSX.Element => {
    const [
        {
            isCallingRightNow,
            status: sipStatus,
        },
        { t },
        {
            hasNotificationWithFeedBack,
            currentOrder: {
                isEmptyCurrentOrder,
            },
            currentUser: {
                isReady: isReadyToAcceptOrder,
                unreadyAfterSave,
                setIsReady,
                mode,
                toggleIsReady,
                setUnreadyAfterSave,
                haveSipPlatinumAccountCredential,
                auxStatuses,
                setSelectedAuxStatus,
                selectedAuxStatus,
            },
            responsive: {
                mobile,
            },
            currentTab,
            inactivityTimer: {
                clearAuxStatusTimers,
                auxStatusTimerActive,
                auxStatusFillTimePassed,
                auxStatusForwardTimer,
            },
        },
        modalService,
    ] = useInstances(
        SipService,
        I18NService,
        Store,
        ModalService,
    );

    const onReadyStatusClick = useCallback(
        () => {
            if (mobile) {
                return;
            }

            if (unreadyAfterSave) {
                setUnreadyAfterSave(false);
            }

            if (!haveSipPlatinumAccountCredential && (
                mode === UserModeEnum.REGULAR
                || mode === UserModeEnum.CLIENT_SERVICE
            )) {
                void modalService.showConfirmModal(
                    t(
                        'Чтобы перейти в готов для звонка, необходимо указать корректные SIP данные. Свяжитесь с поддержкой.',
                        'To go ready to call, you need to provide correct SIP data. Please contact support.',
                    ),
                    ConfirmModalType.Yes,
                );
                return;
            }

            if (mode === UserModeEnum.PREDICTIVE) {
                // TODO: Добавить проверку наличия PREDICTIVE данных для звонка
                void modalService.showConfirmModal(
                    t(
                        'Чтобы перейти в готов для звонка, необходимо сменить режим SIP с предиктивного на обычный. Свяжитесь с поддержкой.',
                        'To go ready to call, you need to ask to change SIP mode from predictive to usual. Please contact support.',
                    ),
                    ConfirmModalType.Yes,
                );
                return;
            }

            if (mode === UserModeEnum.CLIENT_SERVICE && sipStatus < EnumSipStatus.DISCONNECTING_IN_PROGRESS) {
                setIsReady(true);
            }

            if (mode === UserModeEnum.REGULAR || mode === UserModeEnum.PROGRESSIVE) {
                if (!hasNotificationWithFeedBack) {
                    setIsReady(true);
                } else {
                    void modalService.showConfirmModal(
                        t(
                            'Чтобы перейти в готов для звонка, необходимо ответить на все уведомления требующие обратную связь.',
                            'To go ready to call, you need to answer all notifications that require feedback.',
                        ),
                        ConfirmModalType.Yes,
                    );
                }
            }

            setSelectedAuxStatus(null);
            clearAuxStatusTimers();
        },
        [sipStatus, unreadyAfterSave, toggleIsReady, setUnreadyAfterSave, hasNotificationWithFeedBack, mobile, isCallingRightNow, mode],
    );

    const onReadyAuxStatusClick = (status: IAuxStatusesModel) => {

        if (selectedAuxStatus && selectedAuxStatus.name === status.name) {
            return;
        }

        if (status.limit > 0 || status.notLimited) {
            setSelectedAuxStatus({ ...status });
        }
    };

    const onNotReadyStatusClick = useCallback(
        () => {
            if (mobile) {
                return;
            }

            if ((mode === UserModeEnum.REGULAR || mode === UserModeEnum.PROGRESSIVE) && !isEmptyCurrentOrder) {
                setUnreadyAfterSave(true);
                return;
            }

            if (mode === UserModeEnum.CLIENT_SERVICE) {
                if (isCallingRightNow) {
                    setUnreadyAfterSave(true);
                }

                if (sipStatus === EnumSipStatus.REGISTERED) {
                    setIsReady(false);
                }

                return;
            }

            setIsReady(false);
            clearAuxStatusTimers();
            setSelectedAuxStatus(null);
        },
        [sipStatus, isEmptyCurrentOrder, setUnreadyAfterSave, mobile, isCallingRightNow, mode],
    );

    const formedTextTitle = (isReady: boolean) => {
        if (isReadyToAcceptOrder && unreadyAfterSave) {
            if (mode === UserModeEnum.CLIENT_SERVICE) {
                return t('НЕ ГОТОВ ПОСЛЕ ЗВОНКА', 'UNREADY AFTER CALL');
            }

            return t('НЕ ГОТОВ ПОСЛЕ СОХРАНЕНИЯ', 'UNREADY AFTER SAVING');
        }

        if (isReady) {
            return t( 'ГОТОВ К СЛЕДУЮЩЕМУ ЗВОНКУ', 'READY TO NEXT CALL');
        }

        return t('НЕ ГОТОВ К СЛЕДУЮЩЕМУ ЗВОНКУ', 'UNREADY TO NEXT CALL');
    };

    if (currentTab === Locations.BILLING_LIST || currentTab === Locations.ORDER_CREATION || currentTab === Locations.RECALLS) {
        return <></>;
    }

    const getTime = (status: IAuxStatusesModel) => {
        if (auxStatusTimerActive && selectedAuxStatus && status.name === selectedAuxStatus.name) {
            return (
                <div
                    className={cn('timer', auxStatusForwardTimer && 'expired')}
                >
                    {formatTime(auxStatusFillTimePassed)}
                </div>);
        }

        return !status.notLimited && moment.utc(status.limit * 1000).format('mm:ss');
    };

    return (
        <div className="statusList" title=''>
            <div className="statusList__wrapper">
                <div
                    className="statusList__content"
                    onClick={onReadyStatusClick}
                    title={formedTextTitle(true)}
                >
                    <div className="icon ready" />
                    <span className="statusList__title">
                        {t('Готов к следующему звонку', 'Ready to next call')}
                    </span>
                </div>
                <div
                    className="statusList__content"
                    onClick={onNotReadyStatusClick}
                    title={formedTextTitle(false)}
                >
                    <div className="icon un-ready" />
                    <span className="statusList__title">
                        {t('Не готов к следующему звонку', 'Unready to next call')}
                    </span>
                </div>
                {
                    auxStatuses.map(status =>
                        <div
                            className={status.limit > 0 || status.notLimited ? 'statusList__content' : 'statusList__content statusList__content_disabled'}
                            onClick={() => onReadyAuxStatusClick(status)}
                            title={status.description.toUpperCase()}
                            key={status.id}
                        >
                            {status.icon ?
                                <img src={status.icon} alt="aux status" className="icon"/> :
                                <div className="icon ready" />
                            }
                            <span className="statusList__title">
                                {status.description}
                            </span>
                            <div className="statusList__limit">
                                {getTime(status)}
                            </div>
                        </div>,
                    )
                }
            </div>
        </div>
    );
};


export default observer(StatusList);
