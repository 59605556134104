import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@store/customerModeStore';
import SmsMessageList from '@components/main/customer-service-page-stage-2/sms-message-list/SmsMessageList';
import { CustomerServiceOrderInformationTabs } from '@/app/models/mobx-state-tree/customer-mode/orderInformationTabs.model';
import CustomerOrderTable from '@components/main/customer-service-page-stage-2/customer-order-table/CustomerOrderTable';
import ModalService from '@/app/core/services/ModalService';
import AppealsTable from '../appeals-table';

import './OrderInformation.scss';
import CreateAppealForm from '../create-appeal-form';


const OrderInformation: FunctionComponent = (): JSX.Element => {
    const [{ t }, customerMode, modalService] = useInstances(I18NService, CustomerModeStore, ModalService);

    const { orderInformationTabs } = customerMode;

    const {
        activeTab, switchToAppealsTab, switchToOrdersTab, switchToSMSTab,
    } = orderInformationTabs;

    const onCreateAppealClick = useCallback(() => {
        void modalService.showModal(CreateAppealForm);
    }, [modalService.showModal]);

    return (
        <div
            className="OrderInformation"
        >
            <div
                className="tabs-container"
            >
                <div
                    onClick={switchToOrdersTab}
                    className={cn(
                        'currentProducts__tab',
                        activeTab === CustomerServiceOrderInformationTabs.ORDERS && 'active',
                    )}
                >
                    <div className="tabs__text">
                        {t('Заказы', 'Orders')}
                    </div>
                </div>
                <div
                    onClick={switchToAppealsTab}
                    className={cn(
                        'currentProducts__tab',
                        activeTab === CustomerServiceOrderInformationTabs.APPEALS && 'active',
                    )}
                >
                    <div className="tabs__text">
                        {t('Обращения', 'Appeals')}
                    </div>

                </div>
                <div
                    data-cy="switchToSMSTab"
                    onClick={switchToSMSTab}
                    className={cn(
                        'currentProducts__tab',
                        activeTab === CustomerServiceOrderInformationTabs.SMS && 'active',
                    )}
                >
                    <div className="tabs__text">
                        {t('СМС', 'SMS')}
                    </div>
                </div>
                <div
                    className={cn(
                        'currentProducts__tab-link',
                        activeTab === CustomerServiceOrderInformationTabs.SMS && 'active',
                    )}
                    onClick={onCreateAppealClick}
                >
                    <div className="tabs__text">
                        {t('Создать обращение', 'Create appeal')}
                    </div>
                </div>
            </div>


            {activeTab === CustomerServiceOrderInformationTabs.ORDERS && (
                <CustomerOrderTable />
            )}
            {activeTab === CustomerServiceOrderInformationTabs.APPEALS && (
                <AppealsTable />
            )}
            {activeTab === CustomerServiceOrderInformationTabs.SMS && (
                <SmsMessageList />
            )}
        </div>
    );
};

export default observer(OrderInformation);
