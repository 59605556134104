import React, { useMemo, useRef } from 'react';
import SipMessageBoard from '@components/main/order-page/call-panel/call-widget/sip-message-board/SipMessageBoard';
import CallButtonAndPhoneCustomerService
    from '@components/main/order-page/call-panel/call-widget/call-button-and-phone-customer-service/CallButtonAndPhoneCustomerService';
import PanelUserLabel from '@components/main/order-page/call-panel/call-widget/PanelUserLabel/PanelUserLabel';
import './SipPanel.scss';
import ChangeOrderButtons
    from '@components/main/order-page/call-panel/call-widget/ChangeOrderButtons/ChangeOrderButtons';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import CollapseSummaryItems from '@UIElements/CollapseSummary/includes/CollapseSummaryItems';
import CollapseSummary from '@UIElements/CollapseSummary/CollapseSummary';
import currency from 'currency.js';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import useOnClickOutside from 'use-onclickoutside';
import FloatingCallButton from '@UIElements/FloatingCallButton/FloatingCallButton';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import ProductsService from '@services/order/ProductsService';
import { DeliveriesService } from '@/app/services';

/**
 * SipPanel для ClientService
 */
const SipPanel = () => {
    const [
        { currentOrder },
        { t },
        {
            isConditionForPromoCarts,
        },
    ] = useInstances(
        Store,
        I18NService,
        ProductsService,
    );

    const {
        totalCostOfSelectedProducts,
        mainItemsInBasket,
        giftsNotRelatedToOldPromotions,
        promoItemsInBasket,
        isACreatedOrder,
        currency: orderCurrency,
    } = currentOrder;

    const [
        {
            ui: {
                orderPage,
                bottomMobileTab: { activeBottomTab },
            },
            currentOrder: {
                offersBlockTabs: {
                    setIsCollapsed,
                },
            },
        },
        {
            selectedDeliveryPrice,
        },
    ] = useInstances(
        Store,
        DeliveriesService,
    );

    const {
        callPanelVisible,
        closeCallPanel,
    } = orderPage;

    const ref = useRef(null);

    const handleCloseCallPanel = () => {
        setIsCollapsed(false);
        closeCallPanel();
    };

    useOnClickOutside(ref, handleCloseCallPanel);

    const showCallPanel = callPanelVisible || activeBottomTab === BottomTabEnum.CALL;

    const cartPromo = useMemo<IBasketItemModel[]>(
        () => promoItemsInBasket.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed)),
        [promoItemsInBasket],
    );

    const cartGifts = useMemo<IBasketItemModel[]>(
        () => giftsNotRelatedToOldPromotions.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed)),
        [giftsNotRelatedToOldPromotions],
    );

    const cartMain = useMemo<IBasketItemModel[]>(
        () => mainItemsInBasket.filter((item: IBasketItemModel) => item.quantity > 0 && !item.removed),
        [mainItemsInBasket],
    );

    return (
        <>
            <div className={cn(['SipPanel-overlay', showCallPanel && 'visible'])} onClick={handleCloseCallPanel} />
            <FloatingCallButton />
            <div ref={ref} className={cn('SipPanel', showCallPanel && 'visible')}>
                <SipMessageBoard />
                {isACreatedOrder && (
                    <CollapseSummary
                        title={`${t('Сумма заказа', 'Order summary')}:`}
                        sum={currency(totalCostOfSelectedProducts).add(selectedDeliveryPrice).value}
                        currency={orderCurrency}
                        summaryBody={(
                            <div className="summary-body">
                                <CollapseSummaryItems
                                    cartMain={cartMain}
                                    cartGifts={cartGifts}
                                    cartPromo={cartPromo}
                                    isConditionForPromoCarts={isConditionForPromoCarts}
                                />
                                <div className="summary-row">
                                    <div className="item">{t('Заказ', 'Order')}</div>
                                    <div className="item">{totalCostOfSelectedProducts}</div>
                                </div>
                                <div className="summary-row">
                                    <div className="item">{t('Доставка', 'Delivery')}</div>
                                    <div className="item">{selectedDeliveryPrice}</div>
                                </div>
                            </div>
                        )}
                    />
                )}
                <div className="SipPanel__content">
                    <div className="top">
                        <CallButtonAndPhoneCustomerService />
                    </div>
                    <div className="bottom">
                        {isACreatedOrder && (
                            <ChangeOrderButtons />
                        )}
                        <PanelUserLabel />
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(SipPanel);
