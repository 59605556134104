import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';

import { IProductItemModel, ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IPromotionBuilderModel } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';

import PromoItemToAddSmart from '@components/main/order-page/order-products/catalog/Promo/PromoItemToAddSmart';
import PromoItemToAddPromotionBuilder from '@components/main/order-page/order-products/catalog/Promo/PromoItemToAddPromotionBuilder';
import PromotionItemToAdd from '@components/main/order-page/order-products/catalog/Promotion/PromotionItemToAdd';
import PromotionBuilderToAddSmart from '@components/main/order-page/order-products/catalog/PromotionBuilder/PromotionBuilderToAddSmart';
import SimpleGiftItemToAdd from '@components/main/order-page/order-products/offers-block/SimpleGiftItemToAdd';
import OldPromotionService from '@services/order/OldPromotionService';

import './BonusItem.scss';


type TBonusItemProps = {
    readonly promotionBuilderItem?: IPromotionBuilderModel;
    readonly bonusItem: IProductItemModel | null;
    readonly dataCyIndex: number;
};

const BonusItem: FunctionComponent<TBonusItemProps> = ({
    promotionBuilderItem,
    bonusItem,
    dataCyIndex,
}) => {
    const oldPromotionService = useInstance(OldPromotionService);

    if (promotionBuilderItem) {
        return (
            <PromotionBuilderToAddSmart
                promotionBuilder={promotionBuilderItem}
                dataCyValue={dataCyIndex}
            />
        );
    }

    if (!bonusItem) {
        return <></>;
    }

    if (bonusItem.isFromPromotionBuilder) {
        switch (bonusItem.subType) {
            case ProductItemSubTypeEnum.GIFT: return (
                <SimpleGiftItemToAdd
                    catalogItem={bonusItem}
                    dataCyValue={dataCyIndex}
                />
            );
            case ProductItemSubTypeEnum.PROMO: return (
                <PromoItemToAddPromotionBuilder
                    catalogItem={bonusItem}
                    dataCyValue={dataCyIndex}
                />
            );
            default: return <></>;
        }
    }

    switch (bonusItem.subType) {
        case ProductItemSubTypeEnum.OLD_PROMOTION: return (
            <PromotionItemToAdd
                promotionItem={bonusItem.getDataForBonusComponentOfOldPromotions()}
                translations={bonusItem.getTranslationsForBonusComponentOfOldPromotions()}
                promotionIsNotAvailable={oldPromotionService.checkAvailabilityOfAction(bonusItem)}
                isApplied={oldPromotionService.checkAppliedPromotionsForOffers(bonusItem)}
                onApplied={(): void => oldPromotionService.applyPromotionToProduct(bonusItem)}
            />
        );
        case ProductItemSubTypeEnum.GIFT: return (
            <SimpleGiftItemToAdd
                catalogItem={bonusItem}
                dataCyValue={dataCyIndex}
            />
        );
        case ProductItemSubTypeEnum.PROMO: return (
            <PromoItemToAddSmart
                catalogItem={bonusItem}
                dataCyValue={dataCyIndex}
            />
        );
        default: return <></>;
    }
};


export default observer(BonusItem);
