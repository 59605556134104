import { action } from 'mobx';
import {
    IDisposer,
    IJsonPatch,
    onPatch,
} from 'mobx-state-tree';

import { Store } from '@store/store';
import { IOrderCreationModel } from '@models/mobx-state-tree/orderCreation.model';
import OrderCreationService from '@services/order/OrderCreationService';


class OrderCreationEventSubscriptionService {
    private _disposerOnChangesMainOrderCreationData: IDisposer | undefined;

    private get _orderCreationModel(): IOrderCreationModel {
        return this._store.orderCreation;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderCreationService: OrderCreationService,
    ) {

    }

    private async onChangesMainOrderCreationData(patch: IJsonPatch, reversePatch: IJsonPatch): Promise<void> {
        const { op: patchOp, value: patchValue, path: patchPath } = patch;
        const { value: reversePatchValue } = reversePatch;

        const isReplace = patchOp === 'replace';
        const isSelectedSourceId = patchPath === '/selectedSourceId';
        const isSelectedPartnerId = patchPath === '/selectedPartnerId';
        const isSelectedCountryId = patchPath === '/selectedCountryId';
        const isSelectedCurrencyId = patchPath === '/selectedCurrencyId';
        const isValuesDoNotMatch = patchValue !== reversePatchValue;

        if (this._orderCreationModel.isPartnerIdAndCountryIdAndSourceIdSelected) {
            if (
                isReplace
                && (isSelectedSourceId || isSelectedPartnerId || isSelectedCountryId || isSelectedCurrencyId)
                && isValuesDoNotMatch
            ) {
                await this._orderCreationService.initializationSourceTypes();
            }
        }
        if (this._orderCreationModel.isPartnerIdAndCountryIdSelected) {
            if (
                isReplace
                && (isSelectedPartnerId || isSelectedCountryId)
                && isValuesDoNotMatch
            ) {
                await this._orderCreationService.initializationCurrency();
            }
        }
        if (this._orderCreationModel.isPartnerIdSelected){
            if (isReplace && isSelectedPartnerId && isValuesDoNotMatch) {
                await this._orderCreationService.initializationCountries();
            }
        }
    }

    @action
    public subscribe(): void {
        this._disposerOnChangesMainOrderCreationData = onPatch(
            this._orderCreationModel,
            this.onChangesMainOrderCreationData.bind(this),
        );
    }

    @action
    public unsubscribe(): void {
        if (this._disposerOnChangesMainOrderCreationData) {
            this._disposerOnChangesMainOrderCreationData();
        }
    }
}


export default OrderCreationEventSubscriptionService;
