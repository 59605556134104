import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import { ScriptBaseModel } from '@models/mobx-state-tree/scripts/scriptBaseModel';
import { IScriptDataModel, ScriptDataModel } from '@models/mobx-state-tree/scripts/scriptDataModel';

export const ScriptItemModel = ScriptBaseModel
    .props({
        // scriptCategory: t.map(ScriptBaseModel),
        scriptData: t.map(ScriptDataModel),
    })
    .views((self) => ({
        get scriptDataInfo(): IScriptDataModel[] {
            return [...self.scriptData.values()];
        },
        get isEmptyScriptThemes(): boolean {
            return [...self.scriptData.values()].length === 0;
        },
    }))
;


export interface IScriptItemModel extends Instance<typeof ScriptItemModel> {}
export interface IScriptItemModelSnapshotIn extends SnapshotIn<typeof ScriptItemModel> {}
export interface IScriptItemModelSnapshotOut extends SnapshotOut<typeof ScriptItemModel> {}