import { computed } from 'mobx';
import { IGeocode } from '@api/geo-negios-map-api-service/models';


class GeocodeModel {
    public static create(data: IGeocode): GeocodeModel {
        const model = new GeocodeModel();

        model.city = data.address.city;
        model.country = data.address.country;
        model.departmentId = data.address.departmentId ? Number(data.address.departmentId) : null;
        model.houseNumber = data.address.houseNumber ? Number(data.address.houseNumber) : null;
        model.provinceId = data.address.provinceId ? Number(data.address.provinceId) : null;
        model.latitude = Number(data.address.location.Latitude);
        model.longitude = Number(data.address.location.Longitude);
        model.postalCode = data.address.postalCode;
        model.state = data.address.state;
        model.ubigeo = Number(data.address.ubigeo);
        model.countryCode = data.countryCode;
        model.label = data.label;
        model._formattedAddress = data.formatted_address;

        return model;
    }

    @computed
    public get formattedAddress(): [string, string][] {
        return Object.entries(this._formattedAddress);
    }

    private _formattedAddress: Record<string, string>;

    city: string;

    country: string;

    departmentId: number | null;

    houseNumber: number | null;

    latitude: number;

    longitude: number;

    postalCode: string;

    provinceId: number | null;

    state: string;

    ubigeo: number;

    countryCode: string;

    label: string;
}


export default GeocodeModel;
