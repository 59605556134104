import React from 'react';
import cn from 'classnames';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import './PromoItemToAdd.scss';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import CoinsSVG from '@/app/components/resources/SVG/Coins/CoinsSVG';
import Button from '@/app/ui-elements/Button';
import LockSVG from '@/app/components/resources/SVG/Lock/LockSVG';
import { IPartOfCatalogItemForPromoInCatalog, ITranslationsForPromoItem } from './helpers/models';

interface IPromoItemToAddProps {
    className?: string;
    catalogItem: IPartOfCatalogItemForPromoInCatalog;
    disabled: boolean;
    isYetInBasket: boolean;
    preparedTranslations: ITranslationsForPromoItem;
    isConditionForAPromo: boolean;
    discountAmount: number | null;
    onClick: () => void;
    readonly dataCyValue?: number;
}

const PromoItemToAdd = ({
    className,
    catalogItem,
    disabled,
    isYetInBasket,
    preparedTranslations,
    isConditionForAPromo,
    discountAmount,
    onClick,
    dataCyValue = 0,
}: IPromoItemToAddProps): JSX.Element => (
    <div
        className={cn([
            className,
            'PromoItemToAdd__wrapper',
            disabled && 'disabled',
        ])}
    >
        {(disabled || !isConditionForAPromo) && (
            <div className="ProductBlur">
                <div className="lock-container">
                    <LockSVG />
                </div>
            </div>
        )}
        <div className="PromoItemToAdd__product_image__wrapper">
            <ProductImage
                className="PromoItemToAdd__product_image"
                productName={catalogItem.name}
                imageLink={catalogItem.image}
                productType={catalogItem.type}
            />
        </div>
        <span className="PromoItemToAdd__product_type">
            <ProductLabel
                productType={null}
                productSubType={catalogItem.subType}
                isPromotion={false}
                text={preparedTranslations.label}
            />
        </span>
        <div className="PromoItemToAdd__product_name" title={catalogItem.name}>
            {catalogItem.name}
        </div>
        <div className="PromoItemToAdd__product_price_wrapper">
            <CoinsSVG />
            <div
                className="product_price"
                title={catalogItem.price ? String(catalogItem.price) : undefined}
            >
                {catalogItem.price}
            </div>
            {discountAmount && (
                <div className="product_discount">
                    {`-${discountAmount}%`}
                </div>
            )}
        </div>
        {!disabled && isConditionForAPromo && (
            <Button
                variant="3"
                size="0"
                text={preparedTranslations.button_can_be_added}
                width="128px"
                onClick={onClick}
                dataCyValue={`add_${catalogItem.subType}_${dataCyValue}`}
            />
        )}
        {isYetInBasket && isConditionForAPromo && (
            <Button
                size="0"
                variant="1"
                width="128px"
                disabled={true}
                text={preparedTranslations.button_yet_added}
                dataCyValue={`added_${catalogItem.subType}_${dataCyValue}`}
            />
        )}
        {disabled && !isYetInBasket && isConditionForAPromo && (
            <div
                className="promo_alert"
                title={preparedTranslations.basket_have_promo_item_alert}
                data-cy={`alert1_${catalogItem.subType}_${dataCyValue}`}
            >
                {preparedTranslations.basket_have_promo_item_alert}
            </div>
        )}
        {!isConditionForAPromo && (
            <div
                className="promo_alert"
                title={preparedTranslations.should_add_more_main_products_alert}
                data-cy={`alert2_${catalogItem.subType}_${dataCyValue}`}
            >
                {preparedTranslations.should_add_more_main_products_alert}
            </div>
        )}
    </div>
);


export default PromoItemToAdd;
