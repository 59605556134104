import { action, observable } from 'mobx';
import LocalStorageService from '@core/services/LocalStorageService';

const NINE_HOURS = 1000 * 60 * 60 * 9;

/**
 * Сервис управляющий функционалом приветствия пользователя при авторизации в системе\
 * Сервис хранит метки:\
 * **GREETING_USER_ID, GREETING_FLAG, GREETING_TIMESTAMP** в localStorage
 * Ориентируясь на значения меток,\
 * предоставляет в доступ observable переменную shouldMakeGreeting,\
 * на основе которой компонент Notifications.tsx\
 * делает вывод о необходимости показать приветствие в UI
 */
class UserGreetingService {
    /**
     * Флаг того, что должны выполнить приветствие пользователя
     */
    @observable
    public shouldMakeGreeting = false;

    constructor(
        private readonly _localStorageService: LocalStorageService,
    ) {
    }

    @action
    private setShouldMakeGreeting = (value: boolean) => {
        this.shouldMakeGreeting = value;
    };

    /**
     * После того, как поприветствовали пользователя\
     * в localStorage запишем эту информацию,\
     * чтобы приветствовать 1 раз
     */
    @action
    public madeGreeting = (): void => {
        this._localStorageService.greetingFlag = '1';
    };

    /**
     * Получает метаданные по приветствию пользователя из localStorage\
     * Если данных нет, то:
     * 1. вызываем метод, который положит метаданные о пользователе в localStorage
     * 2. вызываем метод setShouldMakeGreeting, для того,\
     * чтобы пользователь был поприветствован
     *
     * Если данные есть, то выполяется проверка\
     * прошо ли 9 часов с момента приветствия:
     * 1. 9 часов прошло:
     *     * обновляем данные в localStorage
     *     * вызываем метод setShouldMakeGreeting, для того,\
     * чтобы пользователь был поприветствован
     * 2. 9 часов не прошло:
     *     * вызываем метод setShouldMakeGreeting
     * @param userId - идентификатор пользователя
     */
    public proceedUserMetadataToLocalStorage = (userId: string): void => {
        const currentTimeStamp = Math.ceil(new Date().getTime() / 1000);
        const [userIdLS, timeStampLS, greetingFlagLS] = this.getUserMetadataFromLocalStorageForAGreeting();

        if (userIdLS && userIdLS === userId) {
            if (timeStampLS) {
                const isGreetingMetadataExpired = (timeStampLS + NINE_HOURS) <= currentTimeStamp;
                const greetingFlag = greetingFlagLS || false;

                if (isGreetingMetadataExpired) {
                    this.initUserMetadataToLocalStorageForAGreeting(userId, currentTimeStamp);
                    this.setShouldMakeGreeting(true);
                } else {
                    this.setShouldMakeGreeting(!greetingFlag);
                }
            }
        } else {
            this.initUserMetadataToLocalStorageForAGreeting(userId, currentTimeStamp);
            this.setShouldMakeGreeting(true);
        }
    };

    /**
     * Заполнение метаданными о приветствии пользователя в localStorage
     * @param userId - идентификатор пользователя
     * @param currentTimeStamp - метка времени (СЕЙЧАС)
     */
    private initUserMetadataToLocalStorageForAGreeting = (userId: string, currentTimeStamp: number) => {
        this._localStorageService.greetingUserId = userId;
        this._localStorageService.greetingTimestamp = currentTimeStamp;
        this._localStorageService.greetingFlag = '0';
    };

    /**
     * Получить медатанные о приветствии пользователя из localStorage
     */
    private getUserMetadataFromLocalStorageForAGreeting = (): readonly [string, (number | null), (boolean | null)] => {
        const {
            greetingUserId: userIdLS,
            greetingTimestamp: timeStampLS,
            greetingFlag: greetingFlagLS,
        } = this._localStorageService;

        return [
            userIdLS,
            timeStampLS ? Number(timeStampLS) : null,
            greetingFlagLS ? Boolean(Number(greetingFlagLS)) : null,
        ] as const;
    };
}


export default UserGreetingService;
