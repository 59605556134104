import React, { FC } from 'react';
import { useCallibri } from '@/utils/useCallibri';



const Callibri: FC = () => {
    useCallibri();

    return (
        <></>
    );
};

export default Callibri;
