import React, { FunctionComponent } from 'react';
import map from 'lodash/map';
import { ProductItemSubTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import CollapseSummaryItem from '../CollapseSummaryItem';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';


export interface ICollapseSummaryItemsProps {
    cartMain: IBasketItemModel[];
    cartGifts: IBasketItemModel[];
    cartPromo: IBasketItemModel[];
    isConditionForPromoCarts: boolean;
}

const CollapseSummaryItems: FunctionComponent<ICollapseSummaryItemsProps> = ({
    cartMain,
    cartGifts,
    cartPromo,
    isConditionForPromoCarts,
}) => (
    <div className="summary-table">
        {cartMain.length === 0 && cartGifts.length === 0 && (
            <span className="no-items">No items</span>
        )}
        {cartMain.length > 0 && (
            map(cartMain, (item) => (
                <CollapseSummaryItem
                    key={item.id}
                    quantity={item.quantity}
                    productName={item.productItem.name}
                    total={item.totalPrice}
                    isGift={item.productItem.subType === ProductItemSubTypeEnum.GIFT}
                />
            ))
        )}
        {cartGifts.length > 0 && (
            map(cartGifts, (item) => (
                <CollapseSummaryItem
                    key={item.id}
                    quantity={item.quantity}
                    productName={item.productItem.name}
                    total={item.totalPrice}
                    isGift={item.productItem.subType === ProductItemSubTypeEnum.GIFT}
                />
            ))
        )}
        {cartPromo.length > 0 && isConditionForPromoCarts && (
            map(cartPromo, (item) => (
                <CollapseSummaryItem
                    key={item.id}
                    quantity={item.quantity}
                    productName={item.productItem.name}
                    total={item.totalPrice}
                    isGift={item.productItem.subType === ProductItemSubTypeEnum.GIFT}
                />
            ))
        )}
    </div>
);


export default CollapseSummaryItems;
