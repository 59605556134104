import { toFactory } from 'react-ioc';

import LocalStorageService from '@core/services/LocalStorageService';
import UserGreetingService from '@services/UserGreetingService';


const UserGreetingServiceDI = (): any => [
    UserGreetingService,
    toFactory(
        [
            LocalStorageService,
        ],
        (
            localStorageService: LocalStorageService,
        ) => new UserGreetingService(localStorageService),
    ),
];


export default UserGreetingServiceDI;
