import { DEFAULT_TAB } from '@core/constants/defaultStoreItems';
import { ITabValue } from '@UIElements/Tab/Tab';
import {
    Instance, types as t, SnapshotOut, SnapshotIn, IAnyModelType,
} from 'mobx-state-tree';

const TabModel = t
    .model('TabModel', {
        id: t.identifier,
        isPossibleRemove: t.boolean,
        label: t.string,
    });

export const CurrentCustomerTabs = t
    .model('CurrentCustomerTabs', {
        tabs: t.array(TabModel),
        activeTab: t.maybeNull(t.reference(t.late((): IAnyModelType => TabModel))),
    })
    .actions((self) => ({
        setActiveTab(tabId: string): void {
            const tabToActivate = self.tabs.find((tab) => tab.id === tabId);
            if (tabToActivate) {
                self.activeTab = tabToActivate.id;
            }
        },
    }))
    .actions((self) => ({
        addTab(id: string): void {
            const isExist = self.tabs.find((tab) => tab.id === id);
            if (!isExist) {
                const newTab = {
                    isPossibleRemove: true,
                    label: id,
                    id,
                };

                self.tabs.push(newTab);
            }

            self.setActiveTab(id); // делаем активным новый таб
        },

        deleteTab(tab: ITabValue): void {
            const maybeActiveTab: ITabModel = self.activeTab;
            if (maybeActiveTab) {
                const indexOfActiveTab = self.tabs.findIndex((item) => item.id === maybeActiveTab.id);
                const previousItemToActivate = self.tabs.find((item, index) => index === indexOfActiveTab - 1);
                if (previousItemToActivate) {
                    self.setActiveTab(previousItemToActivate.id);
                } else {
                    self.activeTab = undefined;
                }
            }

            self.tabs.remove(tab);
        },

        afterCreate() {
            // активируем дефолтный таб
            const tabToActivate = self.tabs.find((tab) => tab.id === DEFAULT_TAB.id);
            if (tabToActivate) {
                self.activeTab = tabToActivate.id;
            }
        },

        modifyDefaultItemLabel(label: string): void {
            const tabArray = self.tabs;
            tabArray[0].label = label;
        },
    }));

export interface ITabModel extends Instance<typeof TabModel> {}
export interface ITabModelSnapshotIn extends SnapshotIn<typeof TabModel> {}
export interface ITabModelSnapshotOut extends SnapshotOut<typeof TabModel> {}
export interface ICurrentCustomerTabsModel extends Instance<typeof CurrentCustomerTabs> {}
export interface ICurrentCustomerTabsSnapshotIn extends SnapshotIn<typeof CurrentCustomerTabs> {}
export interface ICurrentCustomerTabsSnapshotOut extends SnapshotOut<typeof CurrentCustomerTabs> {}
