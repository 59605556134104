import React, { useEffect, FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import SnackbarService from '@core/services/SnackbarService';

import Snackbar from './Snackbar';

import './includes/Snackbar.scss';


const SnackbarController: FunctionComponent = () => {
    const [{
        text,
        visible,
        actionText,
        onActionClick,
    },
    { t },
    ] = useInstances(
        SnackbarService,
        I18NService,
    );

    useEffect(() => {
        if (text.length > 60) {
            console.warn('Сообщение превысило рекоммендуемый размер в 60 символов. Используйте метод showModal для больших сообщений');
        }
    }, [text]);

    return (
        <Snackbar
            text={t(text)}
            visible={visible}
            actionText={actionText ? t(actionText) : ''}
            onActionClick={onActionClick}
        />
    );
};

export default observer(SnackbarController);
