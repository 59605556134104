import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import BasketService from '@services/order/BasketService';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';


const BasketServiceDI = (): any => [
    BasketService,
    toFactory(
        [
            Store,
            ModalService,
            I18NService,
        ],
        (
            store: Store,
            modalService: ModalService,
            I18NService: I18NService,
        ) => new BasketService(
            store,
            modalService,
            I18NService,
        ),
    ),
];


export default BasketServiceDI;
