import React, { FunctionComponent, useCallback } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import TextArea from '@UIElements/TextArea';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';

import './includes/OrderComments.scss';
import { IOrderCommentModel } from '@models/mobx-state-tree/orderComment.model';


export interface IOrderCommentsProps {
    comments: IOrderCommentModel[];
    orderCommentDraft: string;
}

const OrderComments: FunctionComponent<IOrderCommentsProps> = ({
    comments,
    orderCommentDraft,
}) => {
    const [store, { t }] = useInstances(Store, I18NService);

    const onChange = useCallback((event) => {
        const { target: { value } } = event;

        store.currentOrder.setOrderCommentDraft(value);
    }, []);

    return (
        <div className="orderComments__root">
            <div className="orderComments__list">
                {map(sortBy(comments, 'created_at'), (comment) => (
                    <div key={String(comment.created_at)} className="orderComments__item">
                        <div className="orderComments__item__header">
                            <div className="orderComments__item__author">
                                {comment.author}
                            </div>

                            {comment.created_at && (
                                <div className="orderComments__item__date">
                                    {`${moment(comment.created_at * 1000).format('HH:mm | DD.MM.YYYY')}`}
                                </div>
                            )}
                        </div>

                        <div className="orderComments__item__body">
                            <div className="orderComments__item__text">
                                {comment.text}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="orderComments__ownComment">
                <TextArea
                    placeholder={t('Комментарий', 'Comment')}
                    label={t('Ваш комментарий к заказу', 'Your comment on the order')}
                    width="100%"
                    height="110px"
                    value={orderCommentDraft}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};


export default observer(OrderComments);
