import { useState, useRef, useEffect, MutableRefObject } from 'react';

/**
 * Определяет, находится ли мышка поверх html элемента. Эффект hover.
 */
export const useHover = (): readonly [MutableRefObject<HTMLDivElement>, boolean] => {
    const [isHovered, setHovered] = useState(false);

    const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

    const handleMouseOver = () => setHovered(true);
    const handleMouseOut = () => setHovered(false);

    useEffect(() => {
        const node = ref.current;

        if (node) {
            node.addEventListener('mouseenter', handleMouseOver);
            node.addEventListener('mouseleave', handleMouseOut);
        } else {
            /**
             * 1. Если нет ноды, вероятная причина ошибки - ref не повешан на div,
             * 2. До основного return-a отрисовывается что-то другое:
             * т.е. есть return блок ранее, в котором рисуется компонент без ref-а
             */
            // eslint-disable-next-line no-console
            console.warn('useHover: No node found to apply eventlistener to track hover effect.');
        }

        return () => {
            if (node) {
                node.removeEventListener('mouseenter', handleMouseOver);
                node.removeEventListener('mouseleave', handleMouseOut);
            }
        };
    }, [ref.current]);

    return [ref, isHovered] as const;
};
