import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OrderType = t
    .model('OrderType', {
        id: t.integer,
        name: t.maybeNull(t.string),
    });


export interface IOrderTypeModel extends Instance<typeof OrderType> {}
export interface IOrderTypeModelSnapshotIn extends SnapshotIn<typeof OrderType> {}
export interface IOrderTypeModelSnapshotOut extends SnapshotOut<typeof OrderType> {}
