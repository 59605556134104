import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const SourceTypeModel = t
    .model('SourceTypeModel', {
        id: t.identifier,
        name: t.maybeNull(t.string),
        description: t.maybeNull(t.string),
    });


export interface ISourceTypeModel extends Instance<typeof SourceTypeModel> {}
export interface ISourceTypeModelSnapshotIn extends SnapshotIn<typeof SourceTypeModel> {}
export interface ISourceTypeModelSnapshotOut extends SnapshotOut<typeof SourceTypeModel> {}
