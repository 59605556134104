import ErrorBase from '@core/error/ErrorBase';
import { customI18NTFunction } from '@services/I18NService';

class SafariEditAccessError extends ErrorBase {
    constructor(t: customI18NTFunction) {
        const message = {
            key: 'Для браузера Safari невозможно редактирование доступов.',
            defaultValue: 'It is not possible to edit accesses for the Safari browser.',
        };

        super(message, t);
    }
}


export default SafariEditAccessError;
