import {
    action, computed, observable, ObservableMap,
} from 'mobx';
import { applySnapshot } from 'mobx-state-tree';
import { Base64 } from 'js-base64';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import currency from 'currency.js';
import values from 'lodash/values';

import { IDelivery, IDeliveryProducts, IGetDeliveryListParams, IOrder } from '@api/order-api-service/models';
import OrderApiService from '@api/order-api-service';
import { Store } from '@store/store';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { IFormModel } from '@models/mobx-state-tree/form.model';
import { ICurrentOrderModel, StatusOfCourierEnum } from '@models/mobx-state-tree/currentOrder.model';
import { IDeliveryModel, IDeliveryModelSnapshotIn, IDeliveryModelSnapshotOut } from '@models/mobx-state-tree/delivery.model';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ComparisonOperatorsOfDeliveryEnum } from '@models/mobx-state-tree/newModels/promotionBuilderDeliveryConditions.model';
import { ConditionsForFreeShipping, TypesOfConditionsForPromoCarts } from '@interfaces/form.interface';
import summaryOfNumbers from '@core/helpers/summaryOfNumbers';
import { ISelectedValue } from '@UIElements/Select/models';


type TOrder = IOrder;

interface IFetchedData {
    key: string;
    value: IDelivery[];
}

class DeliveriesService {
    private _fetchedData: ObservableMap<string, IDelivery[]> = observable.map();

    constructor(
        private readonly store: Store,
        private readonly orderApiService: OrderApiService,
        private readonly I18NService: I18NService,
    ) {

    }

    @action
    private _addFetchedData = (data: IFetchedData) => {
        this._fetchedData.set(data.key, data.value);
    };

    @computed
    public get selectedDelivery(): IDeliveryModel | undefined {
        return find<IDeliveryModel>(
            this._currentOrder.deliveryValues,
            (x) => x.id === this._currentOrder.selectedDeliveryId,
        );
    }

    @computed
    public get minDeliveryDate(): Date {
        return this.selectedDelivery?.minDeliveryDate
            ? new Date(new Date(this.selectedDelivery.minDeliveryDate).setHours(0, 0, 0, 0))
            : new Date();
    }

    @computed
    public get maxDeliveryDate(): Date | null {
        return this.selectedDelivery?.maxDeliveryDate
            ? new Date(new Date(this.selectedDelivery.maxDeliveryDate).setHours(0, 0, 0, 0))
            : null;
    }

    @computed
    public get workDays(): Array<number> | null {
        if (!this.selectedDelivery) {
            return null;
        }

        const { workDays } = this.selectedDelivery;

        return workDays;
    }

    public get daysOff(): Array<Date> | undefined {
        return this.selectedDelivery?.limitExceededDays
            ? [...this.selectedDelivery?.limitExceededDays].map(date => new Date(date))
            : undefined;

    }

    /**
     * Можем ли применить цену доставки из КА
     */
    @computed
    private get _isConditionToApplyShippingPriceFromPromotionBuilder(): boolean {
        const {
            env: {
                bonusOrderConditionsBase: {
                    switchConditionForAShippingPromotionBuilder,
                },
            },
            appliedPromotionBuilder,
            quantityOfMainCartsInPromotionBuilderBasket,
            quantityOfPromoCartsInPromotionBuilderBasket,
        } = this._currentOrder;

        /**
         * Если НЕ активен конструктор акций
         * или если активен, но по его настройкам !нужно! использовать настройки формы для расчета цены доставки
         */
        if (
            !appliedPromotionBuilder
            || appliedPromotionBuilder.deliveryConditions.useDefaultShippingPrice
        ) {
            return false;
        }

        const {
            countWithPromo,
            amountOfProducts,
            condition,
        } = appliedPromotionBuilder.deliveryConditions;

        /**
         * Значит без разницы сколько какого товара, нужно взять цену доставки из КА
         */
        if (condition === ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY) {
            return true;
        }

        // считаем количество товаров по условиям
        let quantityItemsInPromotionBuilder = quantityOfMainCartsInPromotionBuilderBasket;

        if (countWithPromo) {
            quantityItemsInPromotionBuilder += quantityOfPromoCartsInPromotionBuilderBasket;
        }

        // проверяем, удовлетворяет ли условию
        return switchConditionForAShippingPromotionBuilder(
            quantityItemsInPromotionBuilder,
            condition,
            amountOfProducts,
        );
    }

    /**
     * Расчитываем, доступна ли бесплатная доставка\
     * по настройкам формы
     *
     * Если применен КА, то считаем количество и цену товаров\
     * в корзине КА.
     *
     * Иначе считаем количество или цену товаров\
     * в обычной корзине товаров.
     *
     */
    @computed
    private get calculatedConditionForAFreeShippingByFormRules(): boolean {
        if (!this._currentForm.conditionForFreeShipping) {
            return false;
        }

        const {
            env: {
                bonusOrderConditionsBase: {
                    switchConditionForAFreeShipping,
                },
            },
            appliedPromotionBuilder,
            quantityOfMainCartsInPromotionBuilderBasket,
            quantityOfPromoCartsInPromotionBuilderBasket,
            quantityOfGiftCartsInPromotionBuilderBasket,
            quantityOfMainCarts,
            quantityOfPromoCarts,
            quantityOfGiftCarts,
            allBasketItems,
            allPromotionBuilderBasketItems,
        } = this._currentOrder;

        const {
            condition,
            quantity,
            additionalType,
            usePromo,
            useGift,
        } = this._currentForm.conditionForFreeShipping;

        if (quantity && additionalType === TypesOfConditionsForPromoCarts.PRODUCT_QUANTITY) {
            /**
             * Если не применен КА, то считаем количество товаров в обычной корзине
             */
            if (!appliedPromotionBuilder) {
                let quantityItemsInCart = quantityOfMainCarts;
                // помимо main товаров, для количества учитываются подарки и промо
                if (useGift && usePromo) {
                    quantityItemsInCart += quantityOfPromoCarts + quantityOfGiftCarts;
                }
                // помимо main товаров, для количества учитываются только подарки
                if (useGift && !usePromo) {
                    quantityItemsInCart += quantityOfGiftCarts;
                }
                // помимо main товаров, для количества учитываются только промо
                if (usePromo && !useGift) {
                    quantityItemsInCart += quantityOfPromoCarts;
                }

                return switchConditionForAFreeShipping(
                    quantityItemsInCart,
                    condition as ConditionsForFreeShipping,
                    quantity,
                );
            }

            /**
             * Если КА применен, то считаем количество товаров в корзине КА
             */
            let quantityItemsInCart = quantityOfMainCartsInPromotionBuilderBasket;
            // помимо main товаров, для количества учитываются подарки и промо
            if (useGift && usePromo) {
                quantityItemsInCart += quantityOfPromoCartsInPromotionBuilderBasket + quantityOfGiftCartsInPromotionBuilderBasket;
            }
            // помимо main товаров, для количества учитываются только подарки
            if (useGift && !usePromo) {
                quantityItemsInCart += quantityOfGiftCartsInPromotionBuilderBasket;
            }
            // помимо main товаров, для количества учитываются только промо
            if (usePromo && !useGift) {
                quantityItemsInCart += quantityOfPromoCartsInPromotionBuilderBasket;
            }

            return switchConditionForAFreeShipping(
                quantityItemsInCart,
                condition as ConditionsForFreeShipping,
                quantity,
            );
        }

        if (quantity && additionalType === TypesOfConditionsForPromoCarts.PRODUCT_PRICE) {
            /**
             * Если не применен КА, то считаем цену товаров в обычной корзине
             */
            if (!appliedPromotionBuilder) {
                const totalCostByCondition = reduce<number, number>(
                    map<IBasketItemModel, number>(
                        allBasketItems,
                        (x) => {
                            if (x.removed) {
                                return 0;
                            }

                            if (x.productItem.subType === ProductItemSubTypeEnum.PROMO && !usePromo) {
                                return 0;
                            }

                            return x.price * x.quantity;
                        },
                    ),
                    summaryOfNumbers,
                    0,
                );

                return switchConditionForAFreeShipping(
                    totalCostByCondition,
                    condition as ConditionsForFreeShipping,
                    quantity,
                );
            }

            /**
             * Если КА применен, то считаем цену товаров в корзине КА
             */
            const totalCostByCondition = reduce<number, number>(
                map<IBasketItemModel, number>(
                    allPromotionBuilderBasketItems,
                    (x) => {
                        if (x.productItem.subType === ProductItemSubTypeEnum.PROMO && !usePromo) {
                            return 0;
                        }

                        return x.price * x.quantity;
                    },
                ),
                summaryOfNumbers,
                0,
            );

            return switchConditionForAFreeShipping(
                totalCostByCondition,
                condition as ConditionsForFreeShipping,
                quantity,
            );
        }

        return false;
    }

    @computed
    public get isConditionForFreeShipping(): boolean {
        const {
            env: {
                bonusOrderConditionsBase: {
                    switchConditionForAShippingPromotionBuilder,
                },
            },
            appliedPromotionBuilder,
            quantityOfMainCartsInPromotionBuilderBasket,
            quantityOfPromoCartsInPromotionBuilderBasket,
        } = this._currentOrder;

        /**
         * Если активен конструктор акций
         * и по его настройкам !не! нужно использовать настройки формы для расчета цены доставки
         */
        if (appliedPromotionBuilder
            && !appliedPromotionBuilder.deliveryConditions.useDefaultShippingPrice
        ) {
            const {
                shippingPrice,
                countWithPromo,
                amountOfProducts,
                condition,
            } = appliedPromotionBuilder.deliveryConditions;
            /**
             * Если в КА установлена настройка применения цены "НЕ ЗАВИСИТ ОТ КОЛИЧЕСТВА"
             * и цена доставки = 0, то возвращаем true
             */
            if (condition === ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY
                && shippingPrice === 0
            ) {
                return true;
            }

            if (shippingPrice === 0) {
                // тогда считаем количество товаров по условиям
                let quantityItemsInPromotionBuilder = quantityOfMainCartsInPromotionBuilderBasket;

                if (countWithPromo) {
                    quantityItemsInPromotionBuilder += quantityOfPromoCartsInPromotionBuilderBasket;
                }

                return switchConditionForAShippingPromotionBuilder(
                    quantityItemsInPromotionBuilder,
                    condition,
                    amountOfProducts,
                );
            }
        }

        return this.calculatedConditionForAFreeShippingByFormRules;
    }

    @computed
    public get selectedDeliveryPrice(): number {
        const {
            appliedPromotionBuilder,
        } = this._currentOrder;

        if (
            this._isConditionToApplyShippingPriceFromPromotionBuilder
            && appliedPromotionBuilder?.deliveryConditions.shippingPrice
        ) {
            return appliedPromotionBuilder.deliveryConditions.shippingPrice;
        }

        if (!this.selectedDelivery || this.isConditionForFreeShipping || !this.selectedDelivery.price) {
            return 0;
        }

        return currency(this.selectedDelivery.price).value;
    }

    @computed
    public get deliveryValuesForComponent(): ISelectedValue<IDeliveryModel>[] {
        const { appliedPromotionBuilder, deliveryValues } = this._currentOrder;

        return map<IDeliveryModel, ISelectedValue<IDeliveryModel>>(
            deliveryValues,
            (delivery) => {
                let price = String(delivery.price);

                if (this._isConditionToApplyShippingPriceFromPromotionBuilder
                    && appliedPromotionBuilder?.deliveryConditions.shippingPrice
                ) {
                    price = String(appliedPromotionBuilder.deliveryConditions.shippingPrice);

                    if (price === '0') {
                        price = 'FREE';
                    }

                    return ({
                        label: `${delivery.name} ${price}`,
                        value: delivery,
                    });
                }

                if (this.isConditionForFreeShipping || price === '0') {
                    price = 'FREE';
                }

                return ({
                    label: `${delivery.name} ${price}`,
                    value: delivery,
                });
            },
        );
    }

    @computed
    get selectedDeliveryForComponent(): ISelectedValue<IDeliveryModelSnapshotOut> | null {
        if (this.selectedDelivery) {
            const { appliedPromotionBuilder } = this._currentOrder;

            let price = String(this.selectedDelivery.price);

            if (this._isConditionToApplyShippingPriceFromPromotionBuilder
                && appliedPromotionBuilder?.deliveryConditions.shippingPrice
            ) {
                price = String(appliedPromotionBuilder.deliveryConditions.shippingPrice);

                if (price === '0') {
                    price = 'FREE';
                }

                return {
                    label: `${this.selectedDelivery.name} ${price}`,
                    value: this.selectedDelivery,
                };
            }

            if (this.isConditionForFreeShipping || price === '0') {
                price = 'FREE';
            }

            return {
                label: `${this.selectedDelivery.name} ${price}`,
                value: this.selectedDelivery,
            };
        }

        return null;
    }

    public clearFetchedData = (): void => {
        this._fetchedData.clear();
    };

    private get _t(): customI18NTFunction {
        return this.I18NService.t;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get _currentForm(): IFormModel {
        return this._currentOrder.form;
    }

    private get customerDistrictValue(): string {
        if (!this._currentForm.customerDistrictAttribute) {
            return '';
        }

        return this._currentForm.customerDistrictAttribute.fieldValue;
    }

    private get customerZipAttributeValue(): string {
        if (!this._currentForm.customerZipAttribute) {
            return '';
        }

        return this._currentForm.customerZipAttribute.fieldValue;
    }

    private getSavedFetchData(key: string): IDelivery[] | undefined {
        return this._fetchedData.get(key);
    }

    private loadFormDelivery(orderRes?: TOrder): IGetDeliveryListParams {
        const partnerId = orderRes?.partner_id;
        const countryId = orderRes?.country_id;
        return {
            id: this._currentOrder.id,
            customerZip: this.customerZipAttributeValue,
            customerDistrict: this.customerDistrictValue,
            useForeignDeliveryBroker: this._currentForm.useForeignDeliveryBroker,
            countryId: countryId || this._currentOrder.countryId,
            partnerId: partnerId || this._currentOrder.partnerId,
            products: map<IBasketItemModel, IDeliveryProducts>(
                this._currentOrder.allCarts,
                (product) => ({
                    id: Number(product.productItem.productId),
                    price: product.price,
                    quantity: product.quantity,
                    gift: product.productItem.subType === ProductItemSubTypeEnum.GIFT ? 1 : 0,
                    promo: product.productItem.subType === ProductItemSubTypeEnum.PROMO ? 1 : 0,
                }),
            ),
        };
    }

    private static prepareDelivery(array: IDelivery[]): Record<string, IDeliveryModelSnapshotIn> {
        if (!array || !array.length) return {};

        const data = map<IDelivery, IDeliveryModelSnapshotIn>(
            array,
            (delivery) => ({
                id: String(delivery.id),
                name: delivery.name,
                price: delivery.price,
                haveExpress: delivery.have_express,
                country: delivery.country,
                minDeliveryDate: delivery.min_delivery_date,
                maxDeliveryDate: delivery.max_delivery_date,
                workDays: delivery.work_days,
                partnerId: delivery.partner_id,
                limitExceededDays: delivery.limit_exceeded_days,
            }),
        );

        return convertArrayToCollection<IDeliveryModelSnapshotIn>(data);
    }

    @action
    async fetchDeliveries(orderRes?: TOrder): Promise<void | never> {
        try {
            const hashKey = Base64.toBase64(JSON.stringify(this.loadFormDelivery()));
            // TODO: А мы кеш очищаем?
            const stored = this.getSavedFetchData(hashKey);

            if (!stored) {
                const newData = await this.orderApiService.getDeliveries(this.loadFormDelivery(orderRes));
                this._addFetchedData({
                    key: hashKey,
                    value: newData,
                });
            }

            const data = [...values(this.getSavedFetchData(hashKey))];
            if (data) {
                const deliveries = DeliveriesService.prepareDelivery([...data.values()]);
                applySnapshot(this.store.currentOrder.deliveries, deliveries);
            }

            if (data.length) {
                const deliveries = DeliveriesService.prepareDelivery(data);
                this._currentOrder.setStatusOfCouriers(StatusOfCourierEnum.UPLOADED_BY);
                applySnapshot(this._currentOrder.deliveries, deliveries);
            } else {
                this._currentOrder.setStatusOfCouriers(StatusOfCourierEnum.COURIERS_ARE_NOT_SUITABLE);
            }
        } catch (e) {
            this._currentOrder.setStatusOfCouriers(StatusOfCourierEnum.NOT_FOUND);
        }
    }

    @action
    public changeDeliveryTo = (val: Date | null): void => {
        const {
            setDeliveryTo,
        } = this._currentOrder;

        setDeliveryTo(val);
    };

    @action
    public changeDeliveryFrom = (val: Date | null): void => {
        const {
            deliveryTo,
            setDeliveryFrom,
        } = this._currentOrder;
        // Если дата "с" больше даты "по", то дату "по" очищаем.
        if (val && deliveryTo && (val.getTime() > deliveryTo.getTime())) {
            this.changeDeliveryTo(null);
        }

        setDeliveryFrom(val);
    };
}


export default DeliveriesService;
