import React, { FC, useCallback } from 'react';

import './SideBarDeepLanguageChangeScreenMenu.scss';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { ITranslationList } from '@api/translation-api-service/model';
import FlagIconCss from '@/plugins/FlagIconCss';


interface IItemProps {
    languageItem: ITranslationList;
    children: JSX.Element | JSX.Element[];
}

const Item: FC<IItemProps> = ({ languageItem, children }) => {
    const [
        { changeLanguage },
        { ui: { sidebar: { setLanguagesVisible } } },
    ] = useInstances(
        I18NService,
        Store,
    );

    const onClick = useCallback(() => {
        void changeLanguage(languageItem);
        setLanguagesVisible(false);
    }, [changeLanguage, languageItem, setLanguagesVisible]);

    return (
        <div
            className="item"
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const SideBarDeepLanguageChangeScreenMenu: FC = () => {
    const [
        { t, getParsedLanguageList },
        { ui: { sidebar: { setLanguagesVisible, languagesVisible } } },
    ] = useInstances(
        I18NService,
        Store,
    );

    const onBackButtonClick = useCallback(() => {
        setLanguagesVisible(false);
    }, [setLanguagesVisible]);

    return (
        <div className={cn('SideBarDeepLanguageChangeScreenMenu', languagesVisible && 'visible')}>
            <div className="header">
                <div className="arrow-back" onClick={onBackButtonClick} />
                <div className="text">
                    {t('Язык', 'Language')}
                </div>
            </div>
            <div className="items">
                {getParsedLanguageList.map((x, idx: number) => (
                    <Item languageItem={x} key={`language_${idx}`}>
                        <div className="icon">
                            <FlagIconCss
                                className="langIcon"
                                icon={x.icon}
                                squared={true}
                            />
                        </div>
                        <div className="title">{x.name}</div>
                    </Item>
                ))}
            </div>
        </div>
    );
};

export default observer(SideBarDeepLanguageChangeScreenMenu);
