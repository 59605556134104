import { toFactory } from 'react-ioc';

import SidebarService from '@core/services/SidebarService';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';


const SidebarServiceDI = (): any => [
    SidebarService,
    toFactory(
        [
            I18NService,
            Store,
        ],
        (
            I18NService: I18NService,
            store: Store,
        ) => new SidebarService(
            I18NService,
            store,
        ),
    ),
];


export default SidebarServiceDI;
