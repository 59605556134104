import React, {
    FunctionComponent,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import I18NService from '@services/I18NService';
import CounterInput from '@UIElements/CounterInput';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';

import ProductLabel from '@/app/ui-elements/ProductLabel';

import { Store } from '@/app/store/store';
import { AddModeEnum } from '@/app/services/order/OrderService';
import NomenclatureNavigationBar from '@UIElements/nomenclature-navigation-bar/NomenclatureNavigationBar';
import PromotionBuilderService from '@services/order/PromotionBuilderService';
import PriceEditInput from '@components/main/order-page/order-products/order-table-mobile/PriceEditInput';
import ProductImage from '../../includes/ProductImage';
import OrderTableQuantityInStock from '../../OrderTableQuantityInStock';
import ListOfNomenclatures from './ListOfNomenclatures';
import ButtonsToAddGiftsAndPromos from '../OptionalNomenclature/buttons-to-add-gifts-and-promos/ButtonsToAddGiftsAndPromos';


interface IProductRowProps {
    readonly mainItem: IBasketItemModel;
    readonly mandatoryGifts: IBasketItemModel[];
    readonly optionalGifts: IBasketItemModel[];
    readonly promoProduct: IBasketItemModel[];
    readonly canEditProductPrice: boolean;
    readonly canAddOptionalPromo: boolean;
    readonly canAddOptionalGift: boolean;
}


const PromotionBuilderNomenclatures: FunctionComponent<IProductRowProps> = ({
    mainItem,
    mandatoryGifts,
    optionalGifts,
    promoProduct,
    canEditProductPrice,
    canAddOptionalPromo,
    canAddOptionalGift,
}) => {
    const [
        {
            currentOrder: {
                offersBlockTabs,
            },
        },
        { t },
        { cancelPromotionBuilder },
    ] = useInstances(
        Store,
        I18NService,
        PromotionBuilderService,
    );

    const {
        setDisplayStatusOfBonusItems,
        setIsCollapsed,
    } = offersBlockTabs;

    const switchToAGiftChip = () => {
        setDisplayStatusOfBonusItems(AddModeEnum.gift);
        setIsCollapsed(false);
    };

    const switchToAPromoChip = () => {
        setDisplayStatusOfBonusItems(AddModeEnum.promo);
        setIsCollapsed(false);
    };

    const handlerNomenclatureNavigationBar = (type: string) => {
        if (type === 'cancel') {
            void cancelPromotionBuilder();
        }
    };

    return (
        <div data-cy="nomenclature_line">
            <div
                id={mainItem.id}
                className="currentProducts__table-row"
                data-cy="main_nomenclature"
            >
                <div className="currentProducts__table-product--info">
                    <div
                        className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
                    >
                        <div className="currentProducts__table-avatar">
                            <ProductImage
                                className="currentProducts__table-avatar-image"
                                productName={mainItem.productItem.name}
                                imageLink={mainItem.productItem.image}
                                productType={mainItem.productItem.type}
                            />
                        </div>
                        <div
                            className="currentProducts__table-product--name"
                            title={mainItem.productItem.name}
                            data-cy="product-name"
                        >
                            {mainItem.productItem.name}
                        </div>
                        <OrderTableQuantityInStock
                            quantityInStock={mainItem.productItem.quantityInStock}
                            item={mainItem.productItem}
                        />
                        <span
                            className={cn(
                                'currentProducts__table-text-label--webkit',
                            )}
                        >
                            <ProductLabel
                                productType={mainItem.productItem.type}
                                productSubType={mainItem.productItem.subType}
                                isPromotion={true}
                                text={t('Акция', 'Promotion')}
                            />
                        </span>
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-2"
                        data-cy="item_counter"
                    >
                        <CounterInput
                            onAdd={mainItem.addAmountPromotionBuilder}
                            onReduce={mainItem.reduceAmountPromotionBuilder}
                            onInputChange={mainItem.setAmountPromotionBuilder}
                            quantity={String(mainItem.quantity)}
                        />
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                    >
                        {!canEditProductPrice && (
                            <div
                                className="currentProducts__table-text"
                                data-cy="price-per-one"
                            >
                                {mainItem.promotionBuilderPrice}
                            </div>
                        )}
                        {canEditProductPrice && (
                            <div
                                className="currentProducts__table-text"
                                data-cy="price-input"
                            >
                                <PriceEditInput
                                    item={mainItem}
                                    className="currentProducts__table-text__price-input"
                                />
                            </div>
                        )}
                    </div>
                    <div className="currentProducts__table-col currentProducts__table-col-1">
                        <div
                            data-cy="total-cost"
                            className="currentProducts__table-text currentProducts__table-text--bold"
                        >
                            {mainItem.totalPrice}
                        </div>
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2"
                    >
                        <div className="currentProducts__actions">
                            <NomenclatureNavigationBar
                                isCancel
                                onChange={handlerNomenclatureNavigationBar}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* показываем обязательные подарки */}
            {mandatoryGifts.length > 0 && (
                <ListOfNomenclatures
                    basketItem={mandatoryGifts}
                    dataCy="mandatoryGifts"
                />
            )}
            {/* Блок для показа заглушки для выбора опционального подарка */}
            {canAddOptionalGift && (
                <ButtonsToAddGiftsAndPromos
                    forPromo={false}
                    forGift
                    callback={switchToAGiftChip}
                />
            )}
            {/* показываем необязательные подарки */}
            {optionalGifts.length > 0 && (
                <ListOfNomenclatures
                    basketItem={optionalGifts}
                    dataCy="optionalGifts"
                />
            )}
            {/* Блок для показа заглушки для выбора опционального промо */}
            {canAddOptionalPromo && (
                <ButtonsToAddGiftsAndPromos
                    forPromo
                    forGift={false}
                    callback={switchToAPromoChip}
                />
            )}
            {/* показываем промо товар */}
            {promoProduct.length > 0 && (
                <ListOfNomenclatures
                    basketItem={promoProduct}
                    dataCy="promoProduct"
                />
            )}
        </div>
    );
};


export default observer(PromotionBuilderNomenclatures);
