import { Base64 } from 'js-base64';
import get from 'lodash/get';
import { Inject } from 'react-ioc';
import ApiBase from '@core/api';
import { AxiosRequestConfigCustom } from '@api/models';
import { getEnvAuthEndpoint } from '@/env/requestEndpoints';
import { IAuthData } from './models';

interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class AuthApiService {
    @Inject apiBase: ApiBase;

    async auth(username: string, password: string): Promise<IAuthData | never> {
        const url = getEnvAuthEndpoint();

        const Authorization = `Basic ${Base64.encode(`${username}:${password}`)}`;

        const endpoint: AxiosRequestConfigCustom = {
            method: 'POST',
            url,
            data: {
                instance: 'reactjs',
            },
            headers: {
                Authorization,
            },
        };

        const response = await this.apiBase.customRequest<IWithResponse<IAuthData>>(endpoint);

        const { data, message = 'Unknown authentication error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }
}


export default AuthApiService;
