import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@store/customerModeStore';
import { ITabValue } from '@/app/ui-elements/Tab/Tab';
import {
    useState, useCallback, useEffect,
} from 'react';
import { useInstances } from 'react-ioc';

export const useTab = () => {
    const [{ t }, { currentCustomerTabs }] = useInstances(I18NService, CustomerModeStore);
    const {
        deleteTab, setActiveTab, activeTab: activeTabModel, modifyDefaultItemLabel,
    } = currentCustomerTabs;
    const [tabs, setTabs] = useState<ITabValue[]>();
    const [activeTab, setActiveTabInternal] = useState<ITabValue>();

    useEffect(() => {
        if (tabs) { // Втыкаем перевод в дефолтный таб, если изменили язык UI
            modifyDefaultItemLabel(t('Клиент', 'Customer'));
        }
    }, [t, tabs]);

    useEffect(() => {
        setTabs([
            ...currentCustomerTabs.tabs,
        ]);
    }, [currentCustomerTabs.tabs.length]);

    useEffect(() => {
        if (activeTabModel) {
            setActiveTabInternal(activeTabModel);
        }
    }, [activeTabModel]);

    const onCloseTab = useCallback((id: string) => {
        let memoizedFiltredIndex: number | undefined;
        let memoizedDeletedItem: ITabValue | undefined;

        setTabs(tabs?.filter((item, index) => { // удаляем элемент в стейте хука
            if (item.id === id) {
                memoizedFiltredIndex = index; // и запоминаем его
                memoizedDeletedItem = item;
            }

            return item.id !== id;
        }));

        if (tabs && tabs.length > 1 && activeTab?.id === memoizedDeletedItem?.id && memoizedFiltredIndex) {
            // если закрыли активную вкладку, то делаем активной предыдущую
            setActiveTabInternal(tabs[memoizedFiltredIndex - 1]); // изменяем стейт хука
        }

        if (memoizedDeletedItem) {
            deleteTab(memoizedDeletedItem); // удаляем из модели
        }
    }, [activeTab, tabs]);

    const onChooseTab = useCallback((id: string) => {
        const tabToActivate = tabs?.find((item) => item.id === id);
        if (tabToActivate) {
            setActiveTabInternal(tabToActivate); // изменяем стейт хука
            setActiveTab(tabToActivate.id); // модель
        }
    }, [tabs]);

    return [tabs, activeTab, onCloseTab, onChooseTab] as const;
};
