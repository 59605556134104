import { toFactory } from 'react-ioc';

import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';


const ModalServiceDI = (): any => [
    ModalService,
    toFactory(
        [I18NService],
        (I18NService: I18NService) => new ModalService(I18NService),
    ),
];


export default ModalServiceDI;
