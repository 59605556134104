import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import map from 'lodash/map';
import I18NService from '@services/I18NService';
import { observer } from 'mobx-react';
import Spinner from '@UIElements/Spinner';
import cn from 'classnames';
import { IOrderViewProductModel } from '@models/mobx-state-tree/orderViewProduct.model';
import { ICustomerServiceOrderModel } from '@models/mobx-state-tree/customer-mode/customerServiceOrder.model';
import OrdersTableButton from '@components/main/customer-service-page/orders-table/OrdersTableButton';
import { CustomerModeStore } from '@store/customerModeStore';


const OrdersTable: FunctionComponent = (): JSX.Element => {
    const [
        {
            store,
            customerServiceOrderList,
        },
        { t },
    ] = useInstances(
        CustomerModeStore,
        I18NService,
    );

    return (
        <div className="CustomerServicePageTable">
            <div className="CustomerServicePageTable__head">
                <div className="CustomerServicePageTable__col col-id">{`ID / ${t('Дата', 'Date')}`}</div>
                <div className="CustomerServicePageTable__col col-phones">{t('Телефоны', 'Phones')}</div>
                <div className="CustomerServicePageTable__col col-name">{t('Имя', 'Name')}</div>
                <div className="CustomerServicePageTable__col col-products">{t('Товары', 'Products')}</div>
                <div className="CustomerServicePageTable__col col-shipping">{t('Доставка', 'Shipping')}</div>
                <div className="CustomerServicePageTable__col col-price">{t('Полная стоимость', 'Total price')}</div>
                <div className="CustomerServicePageTable__col col-actions" />
            </div>
            {store.isLoading && <Spinner className={cn('e-button__Spinner')} />}
            {!store.isLoading && customerServiceOrderList.length > 0 && (
                <ul className="CustomerServicePageTable__body">
                    {map(customerServiceOrderList, (order: ICustomerServiceOrderModel) => (
                        <li
                            key={order.id}
                            className="CustomerServicePageTable__item"
                        >
                            <div className="CustomerServicePageTable__col col-id">
                                <div className="col-item">
                                    <div className="mobile-label">{`${t('ID', 'ID')}:`}</div>
                                    <span
                                        data-cy="customerService__orderView--orderId"
                                    >
                                        {order.id}
                                    </span>
                                </div>
                                <div className="col-item">
                                    <div className="mobile-label">{`${t('Создан', 'Created at')}:`}</div>
                                    {order.createdAt}
                                </div>
                            </div>
                            <div className="CustomerServicePageTable__col col-phones">
                                <div className="col-item">
                                    <span>Phone</span>
                                    <div
                                        data-cy="customerService__orderView--phone"
                                        className="phone-value"
                                    >
                                        {order.customer_phone}
                                    </div>
                                </div>
                                <div className="col-item">
                                    <span>Mobile</span>
                                    <div
                                        className="phone-value"
                                    >
                                        {order.customer_mobile}
                                    </div>
                                </div>
                            </div>
                            <div className="CustomerServicePageTable__col col-name">
                                <div
                                    className="mobile-label"
                                >
                                    {t('Полное имя', 'Full name')}
                                </div>
                                <span
                                    data-cy="customerService__orderView--fullName"
                                >
                                    {order.customerFullName}
                                </span>
                            </div>
                            <div className="CustomerServicePageTable__col col-products">
                                <div className="mobile-label">{t('Товары', 'Products')}</div>
                                <ul className="product-list">
                                    {map(order.productsValues, (product: IOrderViewProductModel, index) => (
                                        <li key={`${product.id}-${index}`}>
                                            {`${product.name} x ${product.quantity}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="CustomerServicePageTable__col col-shipping">
                                <div className="col-item">
                                    <span>{t('Адрес доставки', 'Shipping address')}</span>
                                    {order.customer_address}
                                </div>
                                <div className="col-item">
                                    <span>{t('Служба доставки', 'Shipping service')}</span>
                                    {order.shippingName}
                                </div>
                                <div className="col-row">
                                    <div className="col-item">
                                        <span>{t('С', 'From')}</span>
                                        {order.deliveryFrom}
                                    </div>
                                    <div className="col-item">
                                        <span>{t('До', 'To')}</span>
                                        {order.deliveryTo}
                                    </div>
                                    <div className="col-item">
                                        <span>{t('Цена доставки', 'Shipping price')}</span>
                                        {order.shippingPrice ? order.shippingPrice : '—'}
                                    </div>
                                </div>
                            </div>
                            <div className="CustomerServicePageTable__col col-price">
                                <div className="mobile-label">{`${t('Полная стоимость', 'Total price')}:`}</div>
                                {`${order.final_price} ${order.currency ? order.currency : ''}`}
                            </div>
                            <div className="CustomerServicePageTable__col col-actions">
                                <OrdersTableButton orderId={order.id} />
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            {!store.isLoading && customerServiceOrderList.length === 0 && (
                <div className="CustomerServicePageTable__empty">{t('Заказы не найдены', 'Orders not found')}</div>
            )}
        </div>
    );
};

export default observer(OrdersTable);
