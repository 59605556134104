import React, {
    FunctionComponent,
    useCallback,
    useRef,
    useState,
} from 'react';
import Button from '@UIElements/Button';
import cn from 'classnames';
import map from 'lodash/map';
import { IButtonProps } from '@UIElements/Button/models';
import useOnClickOutside from 'use-onclickoutside';

import './ButtonWithSplit.scss';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';


interface IItem {
    id: number | null;
    name: string;
}

interface IButtonWithSplitProps {
    buttonProps: IButtonProps;
    onSelect: (id: number | null) => void;
    items: IItem[];
    fullWidth?: boolean;
}


const ButtonWithSplit: FunctionComponent<IButtonWithSplitProps> = ({
    buttonProps, onSelect, items, fullWidth = false,
}) => {
    const [{ t }] = useInstances(I18NService);
    const [splitVisible, setSplitVisible] = useState(false);

    const hideSplit = useCallback(() => {
        setSplitVisible(false);
    }, [setSplitVisible]);

    const ref = useRef(null);
    useOnClickOutside(ref, hideSplit);

    const onButtonClick = useCallback(() => {
        setSplitVisible(!splitVisible);
    }, [splitVisible]);

    return (
        <div className={cn(['ButtonWithSplit', fullWidth && 'full-width'])} ref={ref}>
            <Button {...buttonProps} split onClick={onButtonClick} />
            <div
                className={cn([
                    'popup',
                    splitVisible && 'visible',
                ])}
            >
                <ul className="values">
                    {map(items, (item) => (
                        <li
                            key={String(item.id)}
                            onClick={() => onSelect(item.id)}
                            title={t(item.name)}
                        >
                            {t(item.name)}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ButtonWithSplit;
