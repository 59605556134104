import { normalize, schema } from 'normalizr';

const convertArrayToCollection = <T>(array: T[]): Record<string, T> => {
    // TODO: проверить является ли T примитивным типом
    // Если да, то нужно использовать другой способ создания коллекции
    // Либо задать тип таким образом, что массив примитивов передать в неё нельзя
    const { entities } = normalize(array, [new schema.Entity('collection')]);
    return entities.collection;
};

export default convertArrayToCollection;
