import React from 'react';

import './error-page.scss';

interface IErrorPage {
    text: string;
}

const ErrorPage = (props: IErrorPage) => {

    const { text } = props;

    return (
        <div className="error-page">
            {text}
        </div>
    );
};

export default ErrorPage;