import React, {
    ChangeEvent, FC, useCallback, useEffect, useState,
} from 'react';
import isFinite from 'lodash/isFinite';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';


interface IPriceEditInputProps {
    readonly item: IBasketItemModel;
    readonly className?: string;
}


const PriceEditInput: FC<IPriceEditInputProps> = ({
    item,
    className,
}) => {
    const [currentPrice, setCurrentPrice] = useState(item.price.toString());

    const updateItemPrice = (value: string) => {
        const textValue = value.replace(',', '.');
        let numberValue = parseFloat(textValue);

        if (!isFinite(numberValue)) {
            numberValue = 0;
        }

        item.setPrice(numberValue);
    };

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!item.removed) {
            const { value } = event.target;

            const regex = /^\d+([,.]\d{0,2})?$/;
            const isCorrectValue = regex.exec(value);

            if (isCorrectValue || value === '') {
                setCurrentPrice(value);

                if (!value.endsWith('.') && !value.endsWith(',')) {
                    updateItemPrice(value);
                }
            }
        }
    }, [item]);

    const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
        if (!item.removed) {
            const { value } = event.target;

            if (value === '') {
                setCurrentPrice('0');
            } else {
                const numberValue = parseFloat(value);
                setCurrentPrice(numberValue.toString());
            }
        }
    };

    useEffect(
        () => {
            setCurrentPrice(item.price.toString());
        },
        [item.price],
    );

    return (
        <input
            className={cn(className)}
            type="text"
            value={currentPrice}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="disabled"
            data-cy="price_input"
        />
    );
};


export default observer(PriceEditInput);
