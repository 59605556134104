import React from 'react';

import { block } from 'bem-cn';
import { useInstances } from 'react-ioc';
import { BillingListStore } from '@store/billingListStore';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';

import I18NService from '@services/I18NService';

import './billling-list-table.scss';


const BillingListTable = () => {

    const [
        billingListStore,
        { t },
    ] = useInstances(
        BillingListStore,
        I18NService,
    );

    const b = block('billing-list-table');

    const headerTitles = [
        t('Дата', 'Date'),
        t('Звонки', 'Сalls'),
        t('Заказы', 'Orders'),
        t('Одобренных заказов в день', 'Approved orders per day'),
        t('Время онлайн', 'Online time'),
        t('Цена среднего чека, USD', 'Price of an average bill, USD'),
    ];

    const { processedDataTable, sumCallsTable, sumOrdersTable, sumApprovesTable,
        sumAvgCheckTable, sumOnlineTimeTable, periodTable,
    } = billingListStore;

    return (
        <div className={b()}>
            <table className={b('table')}>
                <thead className={b('thead')}>
                    <tr>
                        {headerTitles.map((title) =>
                            <th
                                key={v4()}
                                className={b('th')}
                                align='left'
                            >
                                {title}
                            </th>,
                        )}
                    </tr>
                </thead>
                <tbody>
                    { processedDataTable.map((day, index) =>
                        <tr key={day.id} className={b('tr')}>
                            <td className={b('td')}>{day.date}</td>
                            <td className={b('td')}>{day.callsCount}</td>
                            <td className={b('td')}>{day.ordersCount}</td>
                            <td className={b('td',
                                { green: index % 2 === 0, purple: index % 2 !== 0 })}>{day.approvedCount}</td>
                            <td className={b('td')}>{day.onlineTime}</td>
                            <td className={b('td',
                                { green: index % 2 === 0, purple: index % 2 !== 0 })}>{day.avgCheckUSD}</td>
                        </tr>,
                    )}
                    <tr className={b('tr')}>
                        <td className={b('td', { sum: true } )}>{periodTable}</td>
                        <td className={b('td', { sum: true } )}>{sumCallsTable}</td>
                        <td className={b('td', { sum: true } )}>{sumOrdersTable}</td>
                        <td className={b('td', { sum: true } )}>{sumApprovesTable}</td>
                        <td className={b('td', { sum: true } )}>{sumOnlineTimeTable}</td>
                        <td className={b('td', { sum: true } )}>{sumAvgCheckTable}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default observer(BillingListTable);