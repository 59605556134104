import {
    types as t,
    Instance,
    SnapshotIn,
    SnapshotOut,
} from 'mobx-state-tree';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';


export const CallsCount = t
    .model('CallsCount', {
        /**
         * Количество разрешенных звонков для обычного режима телефонии
         */
        limitCalls: t.maybeNull(t.number),
        /**
         * Количество звонков на телефон
         */
        numberOfCallAttempts: t.optional(t.number, 0),
    })
    .actions((self) => ({
        incrementNumberOfCallAttempts(): void {
            self.numberOfCallAttempts++;
        },
        isBlockDueToCallLimit(mode: UserModeEnum, automaticCallsAmount: number): boolean {

            if (mode !== UserModeEnum.REGULAR || self.limitCalls === null) {
                return false;
            }

            if (self.limitCalls === 0) {
                return true;
            }

            /*
            ** automaticCallsAmount - количество попыток звонков для автонабора;
             */

            if (self.limitCalls && automaticCallsAmount >  self.limitCalls) {
                return false;
            }

            return  self.numberOfCallAttempts >= self.limitCalls;

        },
    }));


export interface ICallsCountModel extends Instance<typeof CallsCount> {}
export interface ICallsCountModelSnapshotIn extends SnapshotIn<typeof CallsCount> {}
export interface ICallsCountModelSnapshotOut extends SnapshotOut<typeof CallsCount> {}