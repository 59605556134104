import React, {
    useCallback,
    useState,
    KeyboardEvent,
    FunctionComponent,
    InputHTMLAttributes,
    useEffect,
    useMemo, ChangeEvent, useRef,
} from 'react';
import cn from 'classnames';

import useOnClickOutside from 'use-onclickoutside';

import useInputValue from '@core/hooks/useInputValue';
import InputCharacterCounter from '@UIElements/InputCharacterCounter';

import { InputTypes, ITextInputProps } from './models';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import Popup from '@UIElements/Popup';


// TODO Нужно полностью перепилить это мракобесие
const TextInput: FunctionComponent<ITextInputProps> = (props) => {
    const textInputWrapperStyles: Record<string, string> = {
        '--textInput-width': props.width || 'auto',
        '--textInput-height': props.height || '48px',
    };

    const isValid = props.isValid === undefined || props.isValid === null ? true : props.isValid;

    const {
        id,
        value,
        validType,
        maxlength,
        fixedPart,
        dataCyValue = null,
        options,
        onKeyDown,
    } = props;

    const type = props.type || InputTypes.TEXT;
    const changeValue = props.onChangeValue;
    const keyPressValue = props.onKeyPress;
    const className = props.className || '';
    const placeholder = props.placeholder || '';
    const label = props.label || '';
    const name = props.name || '';
    const error = props.error || '';
    const disabled = props.disabled || false;
    const changeValid = props.onChangeValid;
    const length = props.length || null;
    const onClickInput = props.onClick;
    const readonly = props.readonly || false;
    const inputMask = props.inputMask || false;

    const inputValue = useInputValue(value, isValid, validType, type);

    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    useEffect((): void => {
        if (changeValid) {
            changeValid(inputValue.valid);
        }

        if (changeValue) {
            changeValue(inputValue.value);
        }
    }, [inputValue.value, inputValue.valid]);

    const textInputWrapperClasses = useMemo<string>((): string => cn(
        'textInput__wrapper e-text-input',
        className,
        isFocus && 'focus',
        !inputValue.valid && 'error',
        (hasValue && !disabled || !hasValue && inputValue.value && inputValue.value.length && !disabled) && 'has-value',
    ),
    [className, isFocus, inputValue.valid, inputValue.value, hasValue, disabled]);

    const focusOnInput = (): void => {
        setHasValue(true);
        setIsFocus(true);
    };

    const blurOnInput = (): void => {
        setHasValue(false);
        setIsFocus(false);
    };

    const onFocus = useCallback<() => void>(focusOnInput, [hasValue, isFocus]);

    const onBlur = useCallback<() => void>(blurOnInput, [hasValue, isFocus]);

    const onKeyPress = useCallback<(event: KeyboardEvent<HTMLInputElement>) => void>(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (keyPressValue) {
                return keyPressValue(event);
            }

            return null;
        },
    [keyPressValue]);

    const onClick = useCallback<() => void>(() => {
        if (onClickInput) {
            return onClickInput();
        }

        return null;
    }, [onClickInput]);

    const toggleMenu = () => setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened);

    const onChangeValue = (e: ChangeEvent<HTMLInputElement> | string) => {
        const value = typeof e === 'string' ? e : e.target.value;
        props.onChangeValue(value);
    };

    const inputHTMLAttributes = useMemo<InputHTMLAttributes<HTMLInputElement>>(
        () => ({

            id,
            type,
            placeholder,
            name,
            disabled,
            onFocus,
            onBlur,
            onKeyPress,
            autoComplete: 'disabled',
            onClick,
            maxLength: maxlength,
            onChange: onChangeValue,
            value,
            onKeyDown,
            ...readonly ? { readOnly: readonly } : null,
            // ...readOnly(),
            ...dataCyValue ? { 'data-cy': dataCyValue } : null,
        }),
        [
            id,
            type,
            placeholder,
            name,
            disabled,
            onFocus,
            onBlur,
            onKeyPress,
            onClick,
            maxlength,
            value,
            dataCyValue,
            readonly,
            onChangeValue,
            onKeyDown,
        ],
    );

    const optionsArray = options && filter(options, option => option !== '');
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsMenuOpened(false));

    return (
        <div
            ref={ref}
            className={textInputWrapperClasses}
            style={textInputWrapperStyles}
            {...!inputValue.valid ? { 'data-cy': `${dataCyValue}_invalid` } : null}
            onClick={toggleMenu}
        >
            {label && (
                <div className="input-label">
                    <div className="input-label-text">{label}</div>
                </div>
            )}
            {!inputMask && !fixedPart && (
                <>
                    <input {...inputHTMLAttributes} autoComplete="disabled" />
                    {optionsArray && !isEmpty(optionsArray) &&
                    <Popup
                        className="select-popup"
                        isActive={isMenuOpened}
                        width={props.width}
                    >
                        <ul className="values">
                            {map(optionsArray, (option: string, index: number) =>
                                <li
                                    key={index}
                                    className="result"
                                    onClick={()=> onChangeValue(option)}
                                >
                                    {option}
                                </li>,
                            )}
                        </ul>
                    </Popup>
                    }
                </>
            )}
            {!inputMask && fixedPart && (
                <label htmlFor={id}>
                    <input {...inputHTMLAttributes} autoComplete="disabled"/>
                </label>
            )}
            {!inputValue.valid && (
                <div className="input-error">{error}</div>
            )}
            {length && value.length !== 0 && (
                <InputCharacterCounter count={value.length} length={length} />
            )}
        </div>
    );
};

export default TextInput;
