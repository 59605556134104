export const getEnvGetAuditOrdersEndpoint = (): string => process.env.REACT_APP_URL_API_GET_AUDIT_ORDERS || '';
export const getEnvGetAuditedOrdersEndpoint = (): string => process.env.REACT_APP_URL_API_GET_AUDITS || '';
export const getReactAppDevUrlApiHost: () => string = () => process.env.REACT_APP_DEV_URL_API_HOST || '';
export const getEnvGetRecallsEndpoint = (): string => process.env.REACT_APP_URL_API_GET_RECALLS || '';
export const getEnvGetByForeignId = (): string => process.env.REACT_APP_URL_API_GET_BY_FOREIGN_ID || '';
export const getEnvOrderUnblockVerificate = (): string => process.env.REACT_APP_URL_API_ORDER_UNBLOCK_VERIFICATE || '';
export const getEnvGetDeliveriesEndpoint = (): string => process.env.REACT_APP_URL_API_GET_DELIVERIES || '';
export const getEnvGetCustomerOrderSearchEndpoint = (): string => process.env.REACT_APP_URL_API_CUSTOMER_ORDER_SEARCH || '';
export const getEnvGetCustomerServiceSearchClientCardEndpoint = (): string => process.env.REACT_APP_URL_API_CUSTOMER_SERVICE_SEARCH_CLIENT_CARD || '';
export const getEnvGetCustomerServiceCreateOrEditClientCardEndpoint = (): string => process.env.REACT_APP_URL_API_CUSTOMER_SERVICE_CREATE_OR_EDIT_CLIENT_CARD || '';
export const getEnvGetCustomerServiceGetSubjectList = (): string => process.env.REACT_APP_URL_API_CUSTOMER_SERVICE_GET_SUBJECT_LIST || '';
export const getEnvCustomerServiceSaveComment = (): string => process.env.REACT_APP_URL_API_CUSTOMER_SERVICE_SAVE_COMMENT || '';
export const getEnvGetPartnerDeliveriesEndpoint = (): string => process.env.REACT_APP_URL_API_GET_PARTNER_DELIVERIES || '';
export const getEnvGetOrderCommentsEndpoint = (): string => process.env.REACT_APP_URL_API_GET_ORDER_COMMENTS || '';
export const getEnvGetOrderViewEndpoint = (): string => process.env.REACT_APP_URL_API_GET_ORDER_VIEW || '';
export const getEnvGetSimilarOrdersEndpoint = (): string => process.env.REACT_APP_URL_API_GET_SIMILAR_ORDERS || '';
export const getEnvOrderUpdateEndpoint = (): string => process.env.REACT_APP_URL_API_ORDER_UPDATE || '';
export const getEnvOrderAutoCallEndpoint = (): string => process.env.REACT_APP_URL_API_ORDER_AUTO_CALL || '';
export const getEnvPostProcessingPrestatus = (): string => process.env.REACT_APP_URL_API_POST_PROCESSING_PRESTATUS || '';
export const getEnvPostProcessingReject = (): string => process.env.REACT_APP_URL_API_POST_PROCESSING_REJECT || '';
export const getEnvAutoCallHangUpByTaskEndpoint = (): string => process.env.REACT_APP_URL_API_AUTO_CALL_HANGUP_BY_TASK || '';
export const getEnvGetOrderEndpoint = (): string => process.env.REACT_APP_URL_API_GET_ORDER || '';
export const getEnvGetUserEndpoint = (): string => process.env.REACT_APP_URL_API_GET_USER || '';
export const getEnvUserEventEndpoint = (): string => process.env.REACT_APP_URL_API_USER_EVENT || '';
export const getEnvGetTranslationsEndpoint = (): string => process.env.REACT_APP_URL_API_GET_TRANSLATIONS || '';
export const getEnvAuthEndpoint = (): string => process.env.REACT_APP_URL_API_AUTH || '';
export const getEnvRefreshTokenEndpoint = (): string => process.env.REACT_APP_URL_API_REFRESH_TOKEN || '';
export const getEnvGetCallHistoryEndpoint = (): string => process.env.REACT_APP_URL_API_GET_CALL_HISTORY || '';
export const getEnvKLADREndpoint = (): string => process.env.REACT_APP_URL_API_GET_KLADR_DATA || '';
export const getEnvPartnerProductListEndpoint = (): string => process.env.REACT_APP_URL_API_GET_PARTNER_PRODUCT_LIST || '';
export const getEnvSaveCommunicationQualityEndpoint = (): string => process.env.REACT_APP_URL_API_SAVE_COMMUNICATION_QUALITY || '';
export const getEnvGetCommunicationQualityListEndpoint = (): string => process.env.REACT_APP_URL_API_GET_COMMUNICATION_QUALITY_LIST || '';
export const getEnvGetUserCountriesEndpoint = (): string => process.env.REACT_APP_URL_API_GET_USER_COUNTRIES || '';
export const getEnvCreateCommunicationQualityResultEndpoint = (): string => process.env.REACT_APP_URL_API_CREATE_COMMUNICATION_QUALITY_RESULT || '';
export const getEnvChangeUserReadyEndpoint = (): string => process.env.REACT_APP_URL_API_CHANGE_USER_READY || '';
export const getEnvSetTimeActivityEndpoint = (): string => process.env.REACT_APP_URL_API_SET_TIME_ACTIVITY || '';
export const getEnvGetAuxEndpoit = (): string => process.env.REACT_APP_URL_API_GET_AUX || '';
export const getEnvAutoRecallAppointmentEndpoint = (): string => process.env.REACT_APP_URL_API_AUTO_RECALL_APPOINTMENT || '';
export const getEnvAutoNoAnswerAppointmentEndpoint = (): string => process.env.REACT_APP_URL_API_AUTO_NOANSWER_APPOINTMENT || '';
export const getEnvAppointmentOperator = (): string => process.env.REACT_APP_URL_API_APPOINTMENT_OPERATER || '';
export const getEnvAddressActionTimeEndpoint = (): string => process.env.REACT_APP_URL_API_SAVE_ADDRESS_ACTION_TIME || '';
export const getEnvGetGoogleAddressEndpoint = (): string => process.env.REACT_APP_URL_API_GET_GOOGLE_ADDRESS || '';
export const getEnvGetGeonegociosAddressEndpoint = (): string => process.env.REACT_APP_URL_API_GET_GEONEGIOS_ADDRESS || '';
export const getEnvGetGeonegociosGeocodeEndpoint = (): string => process.env.REACT_APP_URL_API_GET_GEONEGIOS_GEOCODE || '';
export const getEnvUpdateOrderProductPriceEndpoint = (): string => process.env.REACT_APP_URL_API_UPDATE_ORDER_PROCUCT_PRICE || '';
export const getEnvReleaseOrderEndpoint = (): string => process.env.REACT_APP_URL_API_RELEASE_ORDER || '';
export const getEnvOrderBlockEndpoint = (): string => process.env.REACT_APP_URL_API_ORDER_BLOCK || '';
export const getEnvCreateNotificationEndpoint = (): string => process.env.REACT_APP_URL_API_NOTIFICATION_CREATE || '';
export const getEnvGetNotificationEndpoint = (): string => process.env.REACT_APP_URL_API_NOTIFICATION_GET || '';
export const getEnvSetNotificationSuccessEndpoint = (): string => process.env.REACT_APP_URL_API_NOTIFICATION_SET_SUCCESS || '';
export const getEnvGetArticlesList = (): string => process.env.REACT_APP_URL_API_GET_ARTICLES_LIST || '';
export const getEnvOrderCreateEndpoint = (): string => process.env.REACT_APP_URL_API_ORDER_CREATE || '';
export const getEnvGetSourceListEndpoint = (): string => process.env.REACT_APP_URL_API_SOURCE_LIST || '';
export const getEnvGetUserPartnersListEndpoint = (): string => process.env.REACT_APP_URL_API_USER_PARTNERS_LIST || '';
export const getEnvGetCountryCurrency = (): string => process.env.REACT_APP_URL_API_GET_COUNTRY_CURRENCY || '';
export const getEnvGetSourceTypeListEndpoint = (): string => process.env.REACT_APP_URL_API_SOURCE_TYPE_LIST || '';
export const getEnvGetNodejsWebSocketEndpoint = (): string => process.env.REACT_APP_URL_NODEJS_WS || '';
export const getEnvSetUserLocale = (): string => process.env.REACT_APP_URL_API_SET_USER_LOCALE || '';
export const getEnvGetLanguages = (): string => process.env.REACT_APP_URL_API_GET_LANGUAGES || '';
export const getEnvGetOldPromotionList = (): string => process.env.REACT_APP_URL_API_GET_OLD_PROMOTION_LIST || '';
export const getEnvSetSuccessPhone = (): string => process.env.REACT_APP_URL_API_SET_SUCESS_PHONE || '';
export const getEnvGetOrderStatusFromPay = (): string => process.env.REACT_APP_URL_API_GET_ORDER_STATUS_FROM_PAY || '';
export const getEnvGetOrderSmsEndpoint = (): string => process.env.REACT_APP_URL_API_GET_SMS || '';
export const getEnvGetSpeedTestEndpoint = (): string => process.env.REACT_APP_URL_API_SPEED_TEST || '';
export const getEnvWikiTestEndpoint = (): string => process.env.REACT_APP_URL_API_GET_WIKI_TEST || '';
export const getEnvSaveTestResult = (): string => process.env.REACT_APP_URL_API_GET_SAVE_TEST_RESULT || '';
export const getEnvSaveFeedBack = (): string => process.env.REACT_APP_URL_API_GET_SAVE_FEEDBACK || '';
export const getEnvAddressClassifierGetData = (): string => process.env.REACT_APP_URL_API_GET_ADDRESS_CLASSIFIER_GET_DATA || '';
export const getEnvAddressClassifierSearch = (): string => process.env.REACT_APP_URL_API_GET_ADDRESS_CLASSIFIER_SEARCH || '';
export const getEnvAddressClassifierSearchByZip = (): string => process.env.REACT_APP_URL_API_GET_ADDRESS_CLASSIFIER_SEARCH_BY_ZIP || '';
export const getEnvScriptGet = (): string => process.env.REACT_APP_URL_API_SCRIPT_GET || '';
export const getEnvScriptAbset = (): string => process.env.REACT_APP_URL_API_SCRIPT_ABSET || '';

export const getEnvSendLogToKibana = (): string => process.env.REACT_APP_URL_API_SEND_LOG_TO_KIBANA || '';

export const getEnvGetOrderFastChangeQueueEndpoint = (): string => process.env.REACT_APP_URL_API_ORDER_FAST_CHANE_QUEUE || '';

export const getEnvCreateCallHistoryEndpoint = (): string => process.env.REACT_APP_URL_API_CREATE_CALL_HISTORY || '';
export const getEnvUpdateCallHistoryEndpoint = (): string => process.env.REACT_APP_URL_API_UPDATE_CALL_HISTORY || '';

export const getEnvTimezoneEndpoint = (): string => process.env.REACT_APP_URL_API_TIMEZONE || '';
export const getEnvChangeTimezoneEndpoint = (): string => process.env.REACT_APP_URL_API_CHANGE_TIMEZONE || '';
export const getEnvPromotionBuilderListEndpoint = (): string => process.env.REACT_APP_URL_API_GET_PROMOTION_BUILDER_LIST || '';
export const getEnvOperatorStatisticEndpoint = (): string => process.env.REACT_APP_URL_API_GET_OPERATOR_STATISTIC || '';
export const getEnvPartnerCountryList = (): string => process.env.REACT_APP_URL_API_GET_PARTNER_COUNTRY_LIST || '';
export const getEnvWikiGetDataEndpoint = (): string => process.env.REACT_APP_URL_API_GET_WIKI_DATA || '';
export const getEnvWikiGetCategoryByIDEndpoint = (): string => process.env.REACT_APP_URL_API_GET_CATEGORY_BY_ID || '';
export const getEnvWikiGetArticleByIDEndpoint = (): string => process.env.REACT_APP_URL_API_GET_ARTICLE_BY_ID || '';
