import filter from 'lodash/filter';
import {
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
    flow,
    getEnv,
    destroy, applySnapshot,
} from 'mobx-state-tree';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

import { ISelectedValue } from '@UIElements/Select/models';
import summaryOfNumbers from '@core/helpers/summaryOfNumbers';
import { AttrsKey } from '@interfaces/form.interface';

import { Delivery, IDeliveryModel } from '@models/mobx-state-tree/delivery.model';
import { CallHistoryEvent, ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { FormModel } from '@models/mobx-state-tree/form.model';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import { Country } from '@models/mobx-state-tree/country.model';
import { ISimilarOrderModel, SimilarOrder } from '@models/mobx-state-tree/similarOrder.model';
import { ICallHistoryModel } from '@models/mobx-state-tree/callHistory.model';
import { IOrderCommentModel } from '@models/mobx-state-tree/orderComment.model';
import { ISubStatusModel, SubStatus } from '@models/mobx-state-tree/subStatus.model';
import { OrderType } from '@models/mobx-state-tree/orderType.model';
import { IQueueTypeModel, QueueType } from '@models/mobx-state-tree/queueType.model';
import { OrderSource } from '@models/mobx-state-tree/orderSource.model';
import {  ICommunicationsQualityModel } from '@models/mobx-state-tree/communicationsQuality.model';
import { IEnv } from '@store/store';
import { customI18NTFunction } from '@services/I18NService';
import {
    DEFAULT_AUTOCALL_STATE, DEFAULT_COMMENT_AND_SMS_TAB, DEFAULT_OFFERS_BLOCK_TAB, DEFAULT_ORDER_FORM,
} from '@core/constants/defaultStoreItems';
import { CurrencyModel } from '@models/mobx-state-tree/currency.model';
import { BasketItemModel, IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import {
    IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum,
} from '@models/mobx-state-tree/newModels/ProductItem.model';

import { ITranslationsForPromoItem } from '@components/main/order-page/order-products/catalog/Promo/helpers/models';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import {
    IDeliveryConditionsModel,
} from '@models/mobx-state-tree/newModels/promotionBuilderDeliveryConditions.model';
import {
    IReplaceTransactionModelSnapshotIn,
    ReplaceTransactionModel,
    ReplaceTransactionTypeEnum,
} from './newModels/ReplaceTransactionModel.model';
import { OrderCommentsAndSmsTabs } from './ui/orderCommentsAndSmsTabsModel';
import { ISmsFromErpModel, SmsFromErpModel } from './customer-mode/SmsFromErp.model';
import { OffersBlockTabs } from './ui/offersBlockTabsModel';
import { CallAutomatization } from './callAutomatization.model';
import { IPromotionBuilderModel } from './newModels/PromotionBuilder.models';
import { MEXICO_CHAR_CODE, PERU_CHAR_CODE } from '@core/constants/charCodes';
import { AdditionalParamsCurrentOrderModel } from '@models/mobx-state-tree/ui/additionalParamsCurrentOrder.model';
import { IScriptItemModel, ScriptItemModel } from '@models/mobx-state-tree/scripts/scriptItemModel';
import { CallsCount } from '@models/mobx-state-tree/callsCount.model';
import { BlockButtonNoAnswer } from '@models/mobx-state-tree/blockButtonNoAnswer.model';


export enum StatusOfCourierEnum {
    NOT_FOUND = 'NOT_FOUND',
    COURIERS_ARE_NOT_SUITABLE = 'COURIERS_ARE_NOT_SUITABLE',
    UPLOADED_BY = 'UPLOADED_BY',
}

export const StatusOfCourierOptions: StatusOfCourierEnum[] = [
    StatusOfCourierEnum.COURIERS_ARE_NOT_SUITABLE,
    StatusOfCourierEnum.UPLOADED_BY,
    StatusOfCourierEnum.NOT_FOUND,
];

export const CurrentOrder = t
    .model('CurrentOrder', {
        id: t.optional(t.integer, 0),
        form: t.optional(FormModel, DEFAULT_ORDER_FORM),
        country: t.optional(Country, { id: '0', name: '' }),
        countryId: t.number,
        partnerId: t.number,
        paymentCard: t.maybeNull(t.optional(t.boolean, false)),
        deliveryFrom: t.maybeNull(t.Date),
        deliveryTo: t.maybeNull(t.Date),
        currency: t.maybeNull(CurrencyModel),
        similarOrders: t.map(SimilarOrder),
        subStatuses: t.map(SubStatus),
        deliveries: t.map(Delivery),
        orderCommentDraft: t.optional(t.string, ''),
        operatorMadeACallToTheClient: t.optional(t.boolean, false),
        dateOfRecall: t.maybeNull(t.Date),
        selectedStatus: t.maybeNull(t.integer),
        selectedSubStatus: t.maybeNull(t.integer),
        selectedDeliveryId: t.maybeNull(t.string),
        name: t.maybeNull(t.string),
        customerMobile: t.maybeNull(t.string),
        createdAt: t.maybeNull(t.number),
        updatedAt: t.maybeNull(t.number),
        recallDate: t.maybeNull(t.string),
        sourceUri: t.maybeNull(t.string),
        partnerCreatedAt: t.maybeNull(t.number),
        orderSourceUrl: t.maybeNull(t.string),
        customerEmail: t.maybeNull(t.string),
        customerFullName: t.maybeNull(t.string),
        customerMiddleName: t.maybeNull(t.string),
        customerFirstName: t.maybeNull(t.string),
        customerLastName: t.maybeNull(t.string),
        customerAddress: t.maybeNull(t.string),
        longitude: t.maybeNull(t.string),
        latitude: t.maybeNull(t.string),
        orderType: t.optional(OrderType, { id: 0, name: 'Unknown' }),
        showCardPayment: t.optional(t.boolean, false),
        source: t.optional(OrderSource, { id: 0 }),
        showApproveValidationErrors: t.optional(t.boolean, false),
        isTryingSaveOrderWithStatusCreated: t.optional(t.boolean, false),
        animationOnOrErrorFieldsActive: t.optional(t.boolean, false),
        animationOnAddToBasket: t.optional(t.boolean, false),
        selectedPhoneToCallId: t.maybeNull(t.string),
        abilityToDropACall: t.optional(t.boolean, false),
        lastCallHistoryEvent: t.maybeNull(CallHistoryEvent),
        groupId: t.maybeNull(t.string),
        disableChangeOrderStatusButtonsBeforeCall: t.optional(t.boolean, false),
        lastQueue: t.maybeNull(QueueType),
        forceInactiveButtonOfCall: t.optional(t.boolean, false),
        callPossibility: t.optional(t.boolean, true),
        /**
         * Для автонабора:\
         * Флаг того, что pddLimit автоматизация сработала\
         * В UI будет модалка и нужно дождаться её закрытия
         */
        pddModalWindowShown: t.optional(t.boolean, false),
        isACreatedOrder: t.optional(t.boolean, false),
        sessionCallCount: t.optional(t.number, 0),
        globalAttempts: t.optional(t.integer, 0),
        replaceTransaction: t.maybeNull(ReplaceTransactionModel), // транзакция замены набора на набор, либо товара в составе набора на другой товар
        basketItemPreview: t.maybeNull(BasketItemModel), // просмотр товара перед добавлением (через кнопку Detail в каталоге товаров)
        successPhone: t.maybeNull(t.string),
        orderCommentsAndSmsTabs: t.optional(OrderCommentsAndSmsTabs, DEFAULT_COMMENT_AND_SMS_TAB), // табы в блоке с Заказами/Обращениями/СМС
        offersBlockTabs: t.optional(OffersBlockTabs, DEFAULT_OFFERS_BLOCK_TAB),
        smsListFromErp: t.optional(t.array(SmsFromErpModel), []),
        callAutomatization: t.optional(CallAutomatization, DEFAULT_AUTOCALL_STATE),
        callsCount: t.optional(CallsCount, {}),
        blockButtonNoAnswer: t.optional(BlockButtonNoAnswer, {}),
        statusOfCouriers: t.optional(t.enumeration(StatusOfCourierOptions), StatusOfCourierEnum.NOT_FOUND),
        foreignId: t.maybeNull(t.integer),
        additionalParamsCurrentOrder: t.optional(AdditionalParamsCurrentOrderModel, {}),
        countryScriptItems: t.map(ScriptItemModel),
        leadProductScriptItem: t.optional(t.map(ScriptItemModel), {}),
        showScript: t.optional(t.boolean, false),
        openedScriptItem: t.optional(t.number, -1),
        openedScriptThemes: t.optional(t.array(t.number), []),
        isFirstLoadingScripts: t.optional(t.boolean, true),
        postProcessing: t.optional(t.boolean, false),
        similarCount: t.optional(t.number, 0),
    })
    .actions((self) => ({
        setPddModalWindowShown(value: boolean) {
            self.pddModalWindowShown = value;
        },
        setIsFirstLoadingScripts(): void {
            self.isFirstLoadingScripts = false;
        },
    }))
    .views((self) => ({
        get env(): IEnv {
            return getEnv<IEnv>(self);
        },
        get isUseLeadProductScript(): boolean {
            return [...self.leadProductScriptItem.values()].length !== 0;
        },
    }))
    .views((self) => ({
        /**
         * Если на уровне формы запрет на включение автонабора,
         * то не автоматизируем звонки
         */
        get startCallAutomatic(): boolean {
            if (self.form.notUseCallAutomatization) {
                return false;
            }

            return self.country.startCallAutomatic;
        },
        /**
         * Вернуть модель примененного К.А.
         */
        get appliedPromotionBuilder(): IPromotionBuilderModel | undefined {
            return self.additionalParamsCurrentOrder.promotionBuilderCatalog
                .get(self.additionalParamsCurrentOrder.activePromotionBuilderModelId || '');
        },

        get scriptItems(): IScriptItemModel[] {
            return self.isUseLeadProductScript ? [...self.leadProductScriptItem.values()] :
                [...self.countryScriptItems.values()];
        },

        get isCountryMexico(): boolean {
            return self.country.charCode === MEXICO_CHAR_CODE;
        },

        get isCountryPeru(): boolean {
            return self.country.charCode === PERU_CHAR_CODE;
        },

        get textMessageHistory(): ISmsFromErpModel[] {
            const { smsListFromErp } = self;
            return sortBy<ISmsFromErpModel>(
                smsListFromErp,
                ['createdAt'],
            );
        },
        get t(): customI18NTFunction {
            const { I18NService: { t } } = self.env;

            return t;
        },

        get generalAttrsArray(): IFormAttributesModel[] {
            return self.form.generalAttributeValues;
        },

        get addressAttributesValues(): IFormAttributesModel[] {
            return self.form.addressAttributeValues;
        },

        get similarOrdersValues(): ISimilarOrderModel[] {
            return [...self.similarOrders.values()];
        },

        get deliveryValues(): IDeliveryModel[] {
            return [...self.deliveries.values()];
        },

        get callHistoryValues(): ICallHistoryModel[] {
            return [...self.additionalParamsCurrentOrder.callHistory.values()];
        },

        get currentCommentsValues(): IOrderCommentModel[] {
            return [...self.additionalParamsCurrentOrder.comments.values()];
        },

        get allBasketItems(): IBasketItemModel[] {
            return [...self.additionalParamsCurrentOrder.basket.values()];
        },

        get allCatalogItems(): IProductItemModel[] {
            return [...self.additionalParamsCurrentOrder.catalog.values()];
        },

        get communicationsQualityValues(): ICommunicationsQualityModel[] {
            return [...self.additionalParamsCurrentOrder.communicationsQuality.values()];
        },

        get subStatusesValues(): ISubStatusModel[] {
            return [...self.subStatuses.values()];
        },
    }))
    .views((self) => ({
        get oldPromotionalItemsInCatalog(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.OLD_PROMOTION
                    && catalogItem.type === ProductItemTypeEnum.OLD_PROMOTION,
            );
        },

        get isEmptyScriptItems(): boolean {
            return self.scriptItems.length === 0;
        },

        get mainRegularItemsInCatalog(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.MAIN
                    && catalogItem.type === ProductItemTypeEnum.REGULAR
                    && !catalogItem.isFromPromotionBuilder,
            );
        },

        get giftItemsInCatalog(): IProductItemModel[] {
            if (self.appliedPromotionBuilder) {
                return filter<IProductItemModel>(
                    self.appliedPromotionBuilder._products,
                    (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.GIFT
                        && catalogItem.type === ProductItemTypeEnum.REGULAR
                        && !catalogItem.mandatoryGift,
                );
            }

            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.GIFT
                    && catalogItem.type === ProductItemTypeEnum.REGULAR
                    && !catalogItem.isFromPromotionBuilder,
            );
        },

        get promoItemsInCatalog(): IProductItemModel[] {
            if (self.appliedPromotionBuilder) {
                return filter<IProductItemModel>(
                    self.appliedPromotionBuilder._products,
                    (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.PROMO
                        && catalogItem.type === ProductItemTypeEnum.REGULAR,
                );
            }

            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.PROMO
                    && catalogItem.type === ProductItemTypeEnum.REGULAR
                    && !catalogItem.isFromPromotionBuilder,
            );
        },

        get dynamicSetItemsInCatalog(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.MAIN
                    && catalogItem.type === ProductItemTypeEnum.DYNAMIC_SET
                    && !catalogItem.isFromPromotionBuilder,
            );
        },

        get staticSetItemsInCatalog(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.MAIN
                    && catalogItem.type === ProductItemTypeEnum.STATIC_SET
                    && !catalogItem.isFromPromotionBuilder,
            );
        },

        get promotionBuilderInCatalog(): IPromotionBuilderModel[] {
            return [...self.additionalParamsCurrentOrder.promotionBuilderCatalog.values()];
        },

        get mainItemsInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && (
                        basketItem.productItem.type === ProductItemTypeEnum.REGULAR
                        || basketItem.productItem.type === ProductItemTypeEnum.STATIC_SET
                        || basketItem.productItem.type === ProductItemTypeEnum.DYNAMIC_SET
                    ),
            );
        },

        get mainItemsOnlyInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },

        get giftItemsInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.GIFT
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },

        get promoItemsInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.PROMO
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },

        get dynamicSetItemsInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && basketItem.productItem.type === ProductItemTypeEnum.DYNAMIC_SET,
            );
        },

        get staticSetItemsInBasket(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && basketItem.productItem.type === ProductItemTypeEnum.STATIC_SET,
            );
        },
    }))
    .views((self) => ({
        /**
         * Только эти товары показываем в поиске каталога
         * Остальные типы товаров доступны в офферах
         */
        get itemsToShowInCatalogTab(): IProductItemModel[] {
            return concat<IProductItemModel>(
                self.mainRegularItemsInCatalog,
                self.dynamicSetItemsInCatalog,
                self.staticSetItemsInCatalog,
            );
        },
    }))
    .views((self) => ({
        // Подарки по акции не показываются в общей таблице
        get giftsNotRelatedToOldPromotions(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.giftItemsInBasket,
                {
                    promotionMasterBasketItemId: null,
                },
            );
        },

        get quantityOfMainCarts(): number {
            return self.mainItemsInBasket
                .map((x: IBasketItemModel): number => {
                    if (x.removed) {
                        return 0;
                    }

                    return x.quantity;
                })
                .reduce(summaryOfNumbers, 0);
        },

        get quantityOfGiftsCarts(): number {
            return self.giftItemsInBasket
                .map((x: IBasketItemModel): number => (!x.removed ? x.quantity : 0))
                .reduce(summaryOfNumbers, 0);
        },

        get quantityOfPromoCarts(): number {
            return self.promoItemsInBasket
                .map((x: IBasketItemModel): number => (!x.removed ? x.quantity : 0))
                .reduce(summaryOfNumbers, 0);
        },

        get catalogMainItems(): IProductItemModel[] {
            if (!self.allCatalogItems.length) {
                return [];
            }

            return filter<IProductItemModel>(
                self.allCatalogItems,
                (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.MAIN,
            );
        },

        get partnerProductId(): string | undefined{
            return self.mainItemsOnlyInBasket[0]?.productItem?.partnerProductId;
        },
    }))
    .views((self) => ({
        get phonesToCall(): ISelectedValue<IFormAttributesModel>[] {
            const { t } = self.env.I18NService;

            return self.generalAttrsArray
                .filter((item: IFormAttributesModel) => item.isValid && (
                    item.id === AttrsKey.CUSTOMER_PHONE
                    || item.id === AttrsKey.CUSTOMER_MOBILE
                    || item.additionalPhone
                ))
                .map((phone: IFormAttributesModel): ISelectedValue<IFormAttributesModel> => ({
                    label: `${phone.label && t(phone.label)} ${phone.fieldValue}`,
                    value: phone,
                }));
        },

        get selectGeneralAttributeById(): IFormAttributesModel | undefined {
            if (!self.selectedPhoneToCallId) {
                return undefined;
            }

            return self.form.generalAttributes.get(self.selectedPhoneToCallId);
        },
    }))
    .views((self) => ({
        get activatePromoFrom(): number | null {
            return self.form.activatePromoFrom;
        },

        get allCarts(): IBasketItemModel[] {
            return self.allBasketItems.filter(
                (x: IBasketItemModel) => x.quantity > 0,
            );
        },

        get selectedPhoneToCall(): IFormAttributesModel | null {
            return self.selectedPhoneToCallId && self.selectGeneralAttributeById || null;
        },

        get allFieldForm(): IFormAttributesModel[] {
            return self.generalAttrsArray.concat(self.addressAttributesValues);
        },
    }))
    .views((self) => ({
        get selectedMainCarts(): IBasketItemModel[] { // TODO: refactoring
            if (self.mainItemsInBasket.length) {
                return self.mainItemsInBasket;
            }

            return [];
        },

        get addressAttributesKladerValues(): IFormAttributesModel[] {
            return self.addressAttributesValues.filter((item: IFormAttributesModel) => item.isKlader);
        },

        get canChangeOrderStatus(): boolean {
            if (!self.disableChangeOrderStatusButtonsBeforeCall) {
                return true;
            }

            return self.operatorMadeACallToTheClient;
        },

        get quantityOfGiftCarts(): number {
            return self.giftItemsInBasket
                .map((x: IBasketItemModel): number => {
                    if (x.removed) {
                        return 0;
                    }

                    return x.quantity;
                })
                .reduce(summaryOfNumbers, 0);
        },

        get quantityOfPromoCarts(): number {
            return self.promoItemsInBasket
                .map((x: IBasketItemModel): number => {
                    if (x.removed) {
                        return 0;
                    }

                    return x.quantity;
                })
                .reduce(summaryOfNumbers, 0);
        },

        selectedPhoneToCallForComponent(isForSipStatus?: boolean): ISelectedValue<string> | null {
            const { t } = self.env.I18NService;
            const { generalAttributes } = self.form;

            if (self.selectedPhoneToCall) {
                const phone = self.selectedPhoneToCall;

                if (isForSipStatus) {
                    return {
                        label: phone.fieldValue,
                        value: phone.value,
                    };
                }

                return {
                    label: `${phone.label && t(phone.label)} ${phone.fieldValue}`,
                    value: phone.value,
                };
            }

            const customerPhoneAttrItem = generalAttributes.get(AttrsKey.CUSTOMER_PHONE);

            if (customerPhoneAttrItem && !self.selectedPhoneToCall) {
                const { label, fieldValue, value } = customerPhoneAttrItem;
                if (isForSipStatus) {
                    return {
                        label: fieldValue,
                        value,
                    };
                }

                return {
                    label: `${label && t(label)} ${fieldValue}`,
                    value,
                };
            }

            return null;
        },

        isOpenedScriptTheme(id: number) {
            return self.openedScriptThemes.includes(id);
        },

        get phonesToCallForCallButton(): ISelectedValue<IFormAttributesModel>[] {
            return self.phonesToCall.filter((item: ISelectedValue<IFormAttributesModel>) => item.value.value.length > 0);
        },

        get isEmptyCurrentOrder(): boolean {
            return self.id === 0;
        },

        get mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved(): IBasketItemModel[] {
            return self.mainItemsInBasket.filter((item: IBasketItemModel) => item.quantity > 0 && !item.removed);
        },

        get itemsCountInBasket(): number {
            return self.quantityOfMainCarts + self.quantityOfGiftsCarts + self.quantityOfPromoCarts;
        },

        get mainItemsToShow(): IBasketItemModel[] { // todo: нужны ли следующие методы? ..ToShow()
            return self.mainItemsInBasket;
        },

        get giftItemsToShow(): IBasketItemModel[] { // todo: нужны ли следующие методы? ..ToShow()
            return self.giftItemsInBasket;
        },

        get promoItemsToShow(): IBasketItemModel[] { // todo: нужны ли следующие методы? ..ToShow()
            return self.promoItemsInBasket;
        },

        get dynamicSetsItemsToShow(): IBasketItemModel[] { // todo: нужны ли следующие методы? ..ToShow()
            return self.dynamicSetItemsInBasket;
        },

        get staticSetsItemsToShow(): IBasketItemModel[] { // todo: нужны ли следующие методы? ..ToShow()
            return self.staticSetItemsInBasket;
        },

        get haveItemsInBasket(): boolean {
            return self.additionalParamsCurrentOrder.basket.size > 0;
        },

        get haveItemsInCatalog(): boolean {
            if (self.additionalParamsCurrentOrder.activePromotionBuilderModelId) {
                return false;
            }

            return self.additionalParamsCurrentOrder.catalog.size > 0;
        },

        subStatusesValuesByStatus(
            status: OrderStatusesEnum.REJECT |
            OrderStatusesEnum.TRASH |
            OrderStatusesEnum.NO_ANSWER |
            OrderStatusesEnum.RECALL,
        ): ISubStatusModel[] {
            return self.subStatusesValues.filter((item: ISubStatusModel) => item.status === status);
        },
    }))
    .actions((self) => ({
        setIsTryingSaveOrderWithSave(val: boolean): void {
            self.isTryingSaveOrderWithStatusCreated = val;
        },

        setSuccessPhone(phone: string): void {
            self.successPhone = phone;
        },

        setOpenedScriptItem(index: number): void {
            self.openedScriptItem = index;
        },

        setOpenedScriptThemes(id: number): void {

            if (!self.isOpenedScriptTheme(id)) {
                self.openedScriptThemes.push(id);
            } else {
                self.openedScriptThemes.remove(id);
            }
        },

        clearOpenedScriptThemes(): void {
            self.openedScriptThemes.clear();
        },

        setShowScript(showScript: boolean): void {
            self.showScript = showScript;
        },
    }))
    .views((self) => ({
        invalidFields(trySaveOrderWithStatus?: OrderStatusesEnum): IFormAttributesModel[] {
            const {
                allFieldForm,
                setIsTryingSaveOrderWithSave,
                generalAttrsArray,
            } = self;

            const conditionForValidation = (x: IFormAttributesModel) => !x.isValid && (x.required || x.valueLength && !x.required);

            if (trySaveOrderWithStatus === OrderStatusesEnum.APPROVE) {
                setIsTryingSaveOrderWithSave(false);

                const conditionForValidation = (x: IFormAttributesModel) => {
                    return !x.isValid && (x.required || x.valueLength && !x.required) || !x.getIsMinLengthValid(trySaveOrderWithStatus);
                };

                return allFieldForm.filter(conditionForValidation);
            }

            setIsTryingSaveOrderWithSave(true);

            return generalAttrsArray.filter(conditionForValidation);
        },
    }))
    .views((self) => ({
        isAllFieldsValid(trySaveOrderWithStatus?: OrderStatusesEnum): boolean {
            return self.invalidFields(trySaveOrderWithStatus).length === 0;
        },
    }))
    .views((self) => ({
        get isVisibleNotifyError(): boolean {
            if (!self.selectedMainCarts.length) {
                return true;
            }

            return self.showApproveValidationErrors && !self.isAllFieldsValid();
        },

        /**
         * Подготовить превод для фразы о доступе к промо товару
         */
        prepareAlertAboutNeedToAddMoreMainProductsToAccessPromo(): string {
            if (!self.activatePromoFrom) {
                return '';
            }

            const amount = self.activatePromoFrom - self.quantityOfMainCarts;

            return self.t('Необходимо добавить еще {{amount}} основных товаров для доступа к промо',
                'You need to add {{amount}} more main products to access the promo',
                { amount });
        },
        /**
         * Подготовить переводы для лейблов товаров, в зависимости от типа товара
         */
        prepareLabelTranslation(productType: ProductItemTypeEnum, productSubType: ProductItemSubTypeEnum, isPromotion: boolean): string {
            if (isPromotion) {
                return self.t('Акция', 'Promotion');
            }

            if (productType === ProductItemTypeEnum.DYNAMIC_SET || productType === ProductItemTypeEnum.STATIC_SET) {
                return self.t('Набор', 'Set');
            }

            if (productSubType === ProductItemSubTypeEnum.MAIN) {
                return self.t('Продукт', 'Product');
            }

            if (productSubType === ProductItemSubTypeEnum.GIFT) {
                return self.t('Подарок', 'Gift');
            }

            if (productSubType === ProductItemSubTypeEnum.PROMO) {
                return self.t('Промо', 'Promo');
            }

            return '';
        },

        get saveErrorTextForNotify(): string {
            if (!self.selectedMainCarts.length) {
                return self.t(
                    'Добавьте хотя бы один основной товар в корзину.',
                    'Add at least one main product to your cart.',
                );
            }

            if (!self.isAllFieldsValid()) {
                const errorMessages = self.invalidFields()
                    .map(
                        (x: IFormAttributesModel): string => (x.label || ''),
                    )
                    .join(', ');

                return self.t(
                    'Поля с ошибками: {{errorMessages}}',
                    'Fields with errors: {{errorMessages}}',
                    { errorMessages },
                );
            }

            return 'No errors';
        },
    }))
    .views((self) => ({
        /**
         * Вернуть объект с переведенными фразами для компонента карточки промо товара
         */
        preparedTranslationsForPromoItemInCatalog(promoItem: IProductItemModel): ITranslationsForPromoItem {
            return {
                label: self.prepareLabelTranslation(promoItem.type, promoItem.subType, false),
                button_yet_added: self.t('Добавлено', 'Added'),
                button_can_be_added: self.t('Добавить', 'Add'),
                basket_have_promo_item_alert: self.t('В заказе может быть только 1 промо товар', 'There can be only 1 promo item in an order'),
                should_add_more_main_products_alert: self.prepareAlertAboutNeedToAddMoreMainProductsToAccessPromo(),
            };
        },
    }))
    .actions((self) => ({
        setLastQueue(queue: IQueueTypeModel | null): void {
            self.lastQueue = queue;
        },

        incrementSessionCallCount(): void {
            self.sessionCallCount += 1;
        },

        setCallPossibility(val: boolean): void {
            self.callPossibility = val;
        },

        setStatusOfCouriers(status: StatusOfCourierEnum): void {
            self.statusOfCouriers = status;
        },

        setAnimationOnOrErrorFieldsActive(value: boolean): void {
            self.animationOnOrErrorFieldsActive = value;
        },

        setAnimationOnAddToBasket(value: boolean): void {
            self.animationOnAddToBasket = value;
        },

        setAbilityToDropCall(val: boolean): void {
            self.abilityToDropACall = val;
        },

        setLastCallHistoryEvent(lastCallHistoryEvent: ICallHistoryEventModel): void {
            self.lastCallHistoryEvent = lastCallHistoryEvent;
        },

        setOperatorMadeACallToTheClient(operatorMadeACallToTheClient: boolean): void {
            self.operatorMadeACallToTheClient = operatorMadeACallToTheClient;
        },

        setOrderCommentDraft(value: string): void {
            self.orderCommentDraft = value;
        },

        setDateOfRecall(date: Date | null): void {
            self.dateOfRecall = date;
        },

        setPaymentCard(paymentCard: boolean): void {
            self.paymentCard = paymentCard;
        },

        setSelectedStatus(val: OrderStatusesEnum | null): void {
            self.selectedStatus = val;
        },

        setSelectedSubStatus(val: number | null): void {
            self.selectedSubStatus = val;
        },

        setForceInactiveButtonOfCall(val: boolean): void {
            self.forceInactiveButtonOfCall = val;
        },

        setSelectedDeliveryId(deliveryId: string | null): void {
            self.selectedDeliveryId = deliveryId;
        },

        setDeliveryFrom(date: Date | null): void {
            self.deliveryFrom = date;
        },

        setDeliveryTo(date: Date | null): void {
            self.deliveryTo = date;
        },

        setComment(text: string | null): void {
            self.additionalParamsCurrentOrder.comments.clear();
            if (text) {
                this.setOrderCommentDraft(text);
            }
        },

        setSelectedPhoneToCallId(id: string): void {
            const { callAutomatizationFinished } = self.callAutomatization;
            if (
                id === AttrsKey.CUSTOMER_PHONE
                || id === AttrsKey.CUSTOMER_MOBILE
                || id.includes(AttrsKey.ADDITIONAL_PHONE)
            ) {
                // если работает автонабор, то не позволяем выбирать другой номер телефона в UI
                if (self.startCallAutomatic && !callAutomatizationFinished) {
                    return;
                }
                //запрещаем выбирать другой номер во время звонка
                if (!self.callPossibility) {
                    return;
                }

                self.selectedPhoneToCallId = id;
            }
        },
    }))
    .actions((self) => ({
        showAnimationOrErrorFields: flow(function* (): Generator<Promise<void>> {
            self.setAnimationOnOrErrorFieldsActive(true);

            yield new Promise(() => setTimeout(
                () => self.setAnimationOnOrErrorFieldsActive(false), 1500,
            ));
        }),

        showAnimationOnAddToBasket: flow(function* (): Generator<Promise<void>> {
            self.setAnimationOnAddToBasket(true);

            yield new Promise(() => setTimeout(
                () => self.setAnimationOnAddToBasket(false), 200,
            ));
        }),
    }))
    .actions((self) => ({
        setShowApproveValidationErrors(value: boolean): void {
            self.showApproveValidationErrors = value;
            void self.showAnimationOrErrorFields();
        },
    }))
    .actions((self) => ({
        /**
         * Позволяет добавить товар в каталог товаров \
         * В snapshot currentOrder.catalog кладём модель IProductItemModel
         * @param productItemModel - Модель IProductItemModel товара
         */
        _pushToCatalog(productItemModel: IProductItemModel): void {
            self.additionalParamsCurrentOrder.catalog.put(productItemModel);
        },
        /**
         * Позволяет добавить товар в корзину \
         * В snapshot currentOrder.basket кладём модель IBasketItemModel
         * @param basketItemModel - Модель IBasketItemModel товара в корзине. Имеет дополнительные бизнес-атрибуты, ссылается на ProductItemModel
         */
        _pushToBasket(basketItemModel: IBasketItemModel): void {
            self.additionalParamsCurrentOrder.basket.put(basketItemModel);
        },
        /**
         * Позволяет удалить из модели экземпляр IBasketItemModel \
         * Удаляем ссылку на модель из map basket currentOrder
         * @param basketId - ID модели IBasketItemModel товара в корзине.
         */
        _removeBasketItem(basketId: string): void {
            self.additionalParamsCurrentOrder.basket.delete(basketId);
        },
        /**
         * Позволяет удалить из модели экземпляр IBasketItemModel \
         * Удаляем ссылку на модель из map promotionBuilderBasket currentOrder
         * @param basketId - ID модели IBasketItemModel товара в корзине К.А..
         */
        _removePromotionBuilderItem(basketId: string): void {
            self.additionalParamsCurrentOrder.promotionBuilderBasket.delete(basketId);
        },

        /**
         * Удаляет все товары из корзины для конструктора акций
         */
        clearPromotionBuilderBasket(): void {
            applySnapshot(self.additionalParamsCurrentOrder.promotionBuilderBasket, {});
        },
    }))
    /**
     * Дейстсвия при выполнении замены набора на другой набор, а также при замене товара внутри набора на другой товар
     */
    .views((self) => ({
        get replaceTransactionTo(): string | null {
            if (self.replaceTransaction) {
                return self.replaceTransaction.getTo;
            }
            return null;
        },
        get replaceTransactionIsReadyToProceedReplacement(): boolean {
            if (self.replaceTransaction) {
                return self.replaceTransaction.isReadyToProceedReplacement;
            }
            return false;
        },
    }))
    /**
     * Дейстсвия при выполнении замены набора на другой набор, а также при замене товара внутри набора на другой товар
     */
    .actions((self) => ({
        clearReplaceTransaction(): void {
            if (self.replaceTransaction) {
                destroy(self.replaceTransaction);
            }
        },
        /**
         * Создает модель транзакции замены товара
         * @param id - basketItemModel.id для SET. ISetProductComposition.id - для SET_ITEM
         * @param type - SET или SET_ITEM
         * @param parentProductId - ИД родительского товара (только для набора)
         */
        createReplaceTransaction(id: string, type: ReplaceTransactionTypeEnum, parentProductId?: string): void {
            const transaction: IReplaceTransactionModelSnapshotIn = {
                from: id,
                type,
                parentProductId,
            };

            self.replaceTransaction = ReplaceTransactionModel.create(transaction);
        },
    }))
    /**
     * Дейстсвия для модели basketItemPreview
     */
    .actions((self) => ({
        /**
         * Добавить модель IBasketItemModel в атрибут basketItemPreview \
         * (Предпросмотр товара перед добавлением)
         */
        _pushToBasketItemPreview(basketItemModel: IBasketItemModel): void {
            self.basketItemPreview = basketItemModel;
        },
        /**
         * Удалить модель IBasketItemModel из basketItemPreview \
         * (Предпросмотр товара перед добавлением)
         */
        _deleteBasketItemPreview(): void {
            if (self.basketItemPreview) {
                destroy(self.basketItemPreview);
            }
        },
    }))
    .views((self) => ({
        /**
         * Телефоны. Список атрибутов формы.
         */
        get phonesToCallForCallAutomatization(): IFormAttributesModel[] {
            return self.generalAttrsArray
                .filter((item: IFormAttributesModel) => item.isValid && (
                    item.id === AttrsKey.CUSTOMER_PHONE
                    || item.id === AttrsKey.CUSTOMER_MOBILE
                    || item.additionalPhone
                ));
        },
    }))
    .actions((self) => ({
        /**
         * @param value - Номер телефона
         * @returns ИД выбранного атрибута для выполнения автонабора
         */
        findAttrIdByPhone(value: string): string | null {
            const phones = self.phonesToCallForCallAutomatization;

            const foundPhone = phones.find((phone) => phone.value === value);

            if (foundPhone) {
                return foundPhone.id;
            }

            return null;
        },
        clearScripts(): void {
            self.leadProductScriptItem.clear();
            self.countryScriptItems.clear();
        },
    }))
    .views((self) => ({
        /**
         * Номер телефона для автонабора.
         *
         * 1. Получили заказ
         * 2. Если в заказе есть success-phone, то используем его.
         * 3. Если п.2 не выполнен, то пытаемся взять основной номер телефона. Если с ним все хорошо - используем его
         * 4. Если нет основного/с ним что-то не так - пытаемся взять мобильный. Если с ним все хорошо - используем его
         * 5. Если и с мобильным проблемы, то отключаем функционал автонабора.
         *
         * Для выбранного телефона нужно его выбрать в SIP панель select-е: метод setSelectedPhoneToCallId
         */
        phoneNumberForACallAutomatization(): string | null {
            if (self.successPhone && self.successPhone.length > 0) {
                const attrId = self.findAttrIdByPhone(self.successPhone);
                if (attrId) {
                    self.setSelectedPhoneToCallId(attrId as AttrsKey);
                    return self.successPhone;
                }
            }

            const customerPhone = self.generalAttrsArray
                .filter((item: IFormAttributesModel) => item.isValid && item.name === AttrsKey.CUSTOMER_PHONE);

            if (customerPhone.length > 0
                && customerPhone[0].value.length > 0
            ) {
                self.setSelectedPhoneToCallId(customerPhone[0].id as AttrsKey);
                return customerPhone[0].value;
            }

            const customerMobile = self.generalAttrsArray
                .filter((item: IFormAttributesModel) => item.isValid && item.name === AttrsKey.CUSTOMER_MOBILE);

            if (customerMobile.length > 0
                && customerMobile[0].value.length > 0
            ) {
                self.setSelectedPhoneToCallId(customerMobile[0].id as AttrsKey);
                return customerMobile[0].value;
            }

            return null;
        },
    }))
    /**
     * *****************
     * *****************
     *
     * БЛОК Конструктора акций
     *
     * *****************
     * *****************
     */
    .views((self) => ({
        get allPromotionBuilderBasketItems(): IBasketItemModel[] {
            return [...self.additionalParamsCurrentOrder.promotionBuilderBasket.values()];
        },
    }))
    .views((self) => ({
        /**
         * Вернуть условия по расчету доставки К.А.
         */
        get appliedPromotionBuilderDelivery(): IDeliveryConditionsModel | null {
            if (self.appliedPromotionBuilder) {
                return self.appliedPromotionBuilder.deliveryConditions;
            }

            return null;
        },

        get allGiftsBasketPromotionBuilder(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.GIFT
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },

        /**
         * Для компонента (компонент массив принимает)
         */
        get promoItemsBasketPromotionBuilder(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.PROMO
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },
        get mandatoryGiftsBasketPromotionBuilder(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.GIFT
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR
                    && basketItem.mandatoryGift,
            );
        },
        get optionalGiftsBasketPromotionBuilder(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.GIFT
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR
                    && !basketItem.mandatoryGift,
            );
        },

        /**
         * Для получения единсвенного промо в корзине
         */
        get promoItemBasketPromotionBuilder(): IBasketItemModel | undefined {
            return find<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.PROMO
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },
        get mainItemBasketPromotionBuilderArray(): IBasketItemModel[] {
            return filter<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },
        get mainItemBasketPromotionBuilder(): IBasketItemModel | undefined {
            return find<IBasketItemModel>(
                self.allPromotionBuilderBasketItems,
                (basketItem) => basketItem.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && basketItem.productItem.type === ProductItemTypeEnum.REGULAR,
            );
        },
    }))
    .views((self) => ({
        get totalCostOfSelectedProducts(): number {
            if (self.additionalParamsCurrentOrder.activePromotionBuilderModelId) {
                return self.allPromotionBuilderBasketItems
                    .map((x: IBasketItemModel): number => {
                        if (x.removed) {
                            return 0;
                        }

                        const price = x.productItem.mainProductPrice ? x.productItem.mainProductPrice : x.price;

                        return price * x.quantity;
                    })
                    .reduce(summaryOfNumbers, 0);
            }

            return self.allBasketItems
                .map((x: IBasketItemModel): number => {
                    if (x.removed) {
                        return 0;
                    }

                    return x.price * x.quantity;
                })
                .reduce(summaryOfNumbers, 0);
        },

        get quantityOfMainCartsInPromotionBuilderBasket(): number {
            return self.mainItemBasketPromotionBuilder?.quantity || 0;
        },

        get quantityOfPromoCartsInPromotionBuilderBasket(): number {
            return self.promoItemBasketPromotionBuilder?.quantity || 0;
        },
        get quantityOfGiftCartsInPromotionBuilderBasket(): number {
            return self.optionalGiftsBasketPromotionBuilder
                .concat(self.mandatoryGiftsBasketPromotionBuilder)
                .map((x: IBasketItemModel): number => x.quantity)
                .reduce(summaryOfNumbers, 0);
        },

        get limitAddedOptionalGifts(): boolean {
            const {
                form : {
                    maxGiftsCount,
                },
            } = self;

            /**
             * Если к заказу применен КА и у КА есть ограничения по количеству позиций ПНВ, то возвращаем true в случае
             * совпадения количества добавленных позиций ПНВ и настройки appliedPromotionBuilder['maxGiftsCount']
             */
            if (maxGiftsCount) {
                return self.giftItemsInBasket.length === maxGiftsCount;
            }

            return false;
        },
    }))
    .actions((self) => ({
        /**
         * Применить К.А. в корзину заказа
         * @param promotionBuilderModelId - идентификатор модели конструктора акций
         */
        applyPromotionBuilderToBasket(promotionBuilderModelId: string): void {
            self.additionalParamsCurrentOrder.activePromotionBuilderModelId = promotionBuilderModelId;
        },
        deactivatePromotionBuilderFromBasket(): void {
            self.additionalParamsCurrentOrder.activePromotionBuilderModelId = null;
        },
    }))
    .actions((self) => ({
        /**
         * Позволяет добавить товар в корзину конструктора акций\
         * В snapshot currentOrder.basket кладём модель IBasketItemModel
         * @param basketItemModel - Модель IBasketItemModel товара в корзине. Имеет дополнительные бизнес-атрибуты, ссылается на ProductItemModel
         */
        _pushToPromotionBuilderBasket(basketItemModel: IBasketItemModel): void {
            self.additionalParamsCurrentOrder.promotionBuilderBasket.put(basketItemModel);
        },
    }))
    .actions((self) => ({
        /**
         * Подготовить превод для фразы о доступе к промо товару в Конструкторе акций
         */
        prepareAlertAboutNeedToAddMoreMainProductsToAccessPromoPromotionBuilder(): string {
            if (!self.appliedPromotionBuilder?.promotionCountActivate) {
                return '';
            }

            const amount = self.appliedPromotionBuilder?.promotionCountActivate - self.quantityOfMainCartsInPromotionBuilderBasket;

            return self.t('Необходимо добавить еще {{amount}} основных товаров для доступа к промо',
                'You need to add {{amount}} more main products to access the promo',
                { amount });
        },
    }))
    .actions((self) => ({
        /**
         * Вернуть объект с переведенными фразами для компонента карточки промо товара\
         * в блоке офферов конструктора акций
         */
        preparedTranslationsForPromoItemInOffersForPromotionBuilder(promoItem: IProductItemModel): ITranslationsForPromoItem {
            return {
                label: self.prepareLabelTranslation(promoItem.type, promoItem.subType, false),
                button_yet_added: self.t('Добавлено', 'Added'),
                button_can_be_added: self.t('Добавить', 'Add'),
                basket_have_promo_item_alert: self.t('В заказе может быть только 1 промо товар', 'There can be only 1 promo item in an order'),
                should_add_more_main_products_alert: self.prepareAlertAboutNeedToAddMoreMainProductsToAccessPromoPromotionBuilder(),
            };
        },
    }));


export interface ICurrentOrderModel extends Instance<typeof CurrentOrder> {}
export interface ICurrentOrderSnapshotIn extends SnapshotIn<typeof CurrentOrder> {}
export interface ICurrentOrderSnapshotOut extends SnapshotOut<typeof CurrentOrder> {}
