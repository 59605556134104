import { SipService } from '@services/index';
import CallAutomatizationHandler from '@services/call-automatization/CallAutomatizationHandler';
import { Store } from '@store/store';
import { toFactory } from 'react-ioc';

const CallAutomatizationHandlerDI = (): any => [
    CallAutomatizationHandler,
    toFactory(
        [
            SipService,
            Store,
        ],
        (
            sipService: SipService,
            store: Store,
        ) => new CallAutomatizationHandler(
            sipService,
            store,
        ),
    ),
];


export default CallAutomatizationHandlerDI;
