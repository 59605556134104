import React, {
    FunctionComponent, useCallback, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { Scrollbars } from 'react-custom-scrollbars';
import map from 'lodash/map';

import { OrderService } from '@services/index';
import I18NService from '@services/I18NService';
import Button from '@UIElements/Button';
import SwapArrowsIcon from '@components/resources/SVG/Swap/SwapArrowsIcon';
import { IProductItemModel, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ReplaceTransactionTypeEnum } from '@models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import PromotionItemToAdd from '@components/main/order-page/order-products/catalog/Promotion/PromotionItemToAdd';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { STOCK_SWAP_UNIT } from '@core/constants/attributes';
import { isAlive } from 'mobx-state-tree';
import OldPromotionService from '@services/order/OldPromotionService';
import ProductsService from '@services/order/ProductsService';
import CrossIconSvg from './CrossIconSvg';
import ProductSetToAdd from './ProductSetToAdd';
import ProductItemToAdd from './ProductItemToAdd';
import ProductImage from './ProductImage';

const SCROLLBAR_WIDTH = { width: '100%' };

const ScrollBarWrapper = (props: any) => <div {...props} className="items crutch" data-cy="swap_catalog" />;

interface IOrderTableProps {
    readonly isShow: boolean;
}

const SwapProduct: FunctionComponent<IOrderTableProps> = ({
    isShow,
}) => {
    const [
        orderService,
        { t },
        oldPromotionService,
        productsService,
    ] = useInstances(
        OrderService,
        I18NService,
        OldPromotionService,
        ProductsService,
    );

    const [replacementType, setReplacementType] = useState<ReplaceTransactionTypeEnum | null>(null);
    const [productItemToReplaceBy, setProductItemToReplaceBy] = useState<IProductItemModel | null>(null);
    const [isReadyToProccedReplacement, setIsReadyToProceedReplacement] = useState<boolean>(false);

    const {
        currentOrder: {
            replaceTransactionTo,
            replaceTransaction,
            replaceTransactionIsReadyToProceedReplacement,
            additionalParamsCurrentOrder: {
                catalog,
                basket,
            },
            dynamicSetItemsInCatalog,
            staticSetItemsInCatalog,
        },
    } = orderService;

    useEffect(() => {
        if (replaceTransaction) {
            setReplacementType(replaceTransaction.type);
        }
    }, [replaceTransaction]);

    useEffect(() => {
        if (replaceTransaction && isAlive(replaceTransaction)) {
            if (replaceTransaction.to) {
                const productItem = catalog.get(replaceTransaction.to); // TODO : вынести в action
                if (productItem) setProductItemToReplaceBy(productItem); // то, чем будем заменять
            }
        }
    }, [replaceTransactionTo, replaceTransaction]);

    useEffect(() => {
        setIsReadyToProceedReplacement(replaceTransactionIsReadyToProceedReplacement);
    }, [replaceTransactionIsReadyToProceedReplacement]);

    const allSets = useMemo<IProductItemModel[]>(
        () => dynamicSetItemsInCatalog.concat(staticSetItemsInCatalog),
        [dynamicSetItemsInCatalog, staticSetItemsInCatalog],
    );

    const selectedBasket = useMemo<IBasketItemModel | undefined>(
        () => {
            if (replacementType === ReplaceTransactionTypeEnum.SET_ITEM) {
                if (replaceTransaction?.parentProductId) {
                    return basket.get(replaceTransaction.parentProductId);
                }
            }

            return basket.get(replaceTransaction?.from || '');
        },
        [replaceTransaction, replacementType],
    );

    const onSwap = useCallback(
        () => {
            if (replaceTransaction && selectedBasket) {
                orderService.replaceTransactionOnSwap(replaceTransaction, selectedBasket.id);
            }
        },
        [
            replaceTransaction,
            selectedBasket,
        ],
    );

    const clearReplaceTransaction = () => {
        if (replaceTransaction && selectedBasket) {
            orderService.cancelReplacement(replaceTransaction, selectedBasket.id);
        }
    };

    const onReplacePromo = async (catalogItem: IProductItemModel) => {
        if (
            replaceTransaction
            && replacementType === ReplaceTransactionTypeEnum.PROMOTIONS
            && selectedBasket
        ) {
            await oldPromotionService.replacePromoFromProduct(selectedBasket, catalogItem);

            replaceTransaction.setTo(selectedBasket.promotionId || '');

            onSwap();
        }
    };

    if (!replaceTransaction) {
        // невозможный кейс для этого компонента, workaround для TS
        // eslint-disable-next-line no-console
        console.warn('replaceTransaction undefined or null. This code should be unreachable, so you probably made a mistake');
        return <></>;
    }

    if (replacementType === ReplaceTransactionTypeEnum.PROMOTIONS && selectedBasket) {
        return (
            <div
                id={STOCK_SWAP_UNIT}
                className={cn(
                    'currentProducts__table',
                    !isShow && 'hiddenBlock',
                )}
            >
                <div className="swapProduct__panel replacePromo">
                    <div
                        className="swapProduct__panel--close"
                        title={t('Отменить замену', 'Cancel swap')}
                        onClick={clearReplaceTransaction}
                    >
                        <CrossIconSvg />
                    </div>
                </div>
                <div className="currentProducts_items_to_add">
                    <Scrollbars
                        style={SCROLLBAR_WIDTH}
                        hideTracksWhenNotNeeded={true}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={372}
                        universal={true}
                        renderView={ScrollBarWrapper}
                    >
                        {map<IProductItemModel, JSX.Element>(
                            oldPromotionService.getReplacementPromotionList(selectedBasket),
                            (catalogItem) => (
                                <PromotionItemToAdd
                                    key={catalogItem.id}
                                    promotionIsNotAvailable={false}
                                    promotionItem={catalogItem.getDataForBonusComponentOfOldPromotions()}
                                    translations={catalogItem.getTranslationsForBonusComponentOfOldPromotions()}
                                    isApplied={false}
                                    onApplied={(): Promise<void> => onReplacePromo(catalogItem)}
                                />
                            ),
                        )}
                    </Scrollbars>
                </div>
            </div>
        );
    }

    if (replacementType === ReplaceTransactionTypeEnum.SET) {
        const productItemToReplace = productsService.getProductItemModelByBasketItemModelID(replaceTransaction.from);

        if (!productItemToReplace) {
            // тоже невозможный кейс для этого компонента, workaround для TS
            // eslint-disable-next-line no-console
            console.warn('productItemToReplace undefined or null. This code should be unreachable, so you probably made a mistake');
            return <></>;
        }

        const preparedListOfReplacement: IProductItemModel[] = [productItemToReplace, ...allSets.filter((item: IProductItemModel) => item.id !== productItemToReplace.id)];

        return (
            <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
                <div className="currentProducts_items_to_add">
                    <Scrollbars
                        style={SCROLLBAR_WIDTH}
                        hideTracksWhenNotNeeded={true}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={372}
                        universal={true}
                        renderView={ScrollBarWrapper}
                    >
                        {/**
                         * todo: overflow скроллбара не позволяет вылезти из-под него карточке набора.
                         * Сейчас реализовано стилем crutch -> выставляет margin-bottom у последнего элемента
                         * */}
                        {preparedListOfReplacement.map((item: IProductItemModel, index) => {
                            if (index === 0) {
                                return (
                                    <ProductSetToAdd
                                        className="choosed"
                                        key={item.id}
                                        catalogItem={item}
                                        isChoosedForReplacement={true}
                                    />
                                );
                            }

                            return (
                                <ProductSetToAdd
                                    key={item.id}
                                    catalogItem={item}
                                    isShownForReplacement={true}
                                />
                            );
                        })}
                    </Scrollbars>
                </div>
                <div className="swapProduct__panel">
                    <div
                        className="swapProduct__panel--item choosed"
                        title={productItemToReplace?.name}
                    >
                        <div className="image_wrapper">
                            <ProductImage
                                className="box_img"
                                productName=""
                                imageLink={productItemToReplace.image}
                                productType={productItemToReplace.type}
                            />
                        </div>
                        <div className="product_name">{productItemToReplace.name}</div>
                    </div>
                    <div className="swapProduct__panel--icon"><SwapArrowsIcon /></div>
                    <div
                        className={cn('swapProduct__panel--item', productItemToReplaceBy && 'choosed')}
                        title={productItemToReplaceBy?.name}
                    >
                        <div className="image_wrapper">
                            {productItemToReplaceBy && (
                                <ProductImage
                                    className="box_img"
                                    productName=""
                                    imageLink={productItemToReplaceBy.image}
                                    productType={productItemToReplaceBy.type}
                                />
                            )}
                            {!productItemToReplaceBy && (
                                <ProductImage
                                    className="box_img"
                                    productName=""
                                    imageLink=""
                                    productType={ProductItemTypeEnum.STATIC_SET}
                                />
                            )}
                        </div>
                        <div className="product_name">
                            {productItemToReplaceBy?.name}
                            {!productItemToReplaceBy && t('Выберите товар для замены', 'Choose product for replacement')}
                        </div>
                    </div>
                    <div className="swapProduct__panel--btn">
                        <Button
                            className="button__submit"
                            variant="7"
                            size="1"
                            text={t('Заменить', 'Swap')}
                            onClick={onSwap}
                            disabled={!isReadyToProccedReplacement}
                            preloaderColor="#fff"
                        />
                    </div>
                    <div
                        className="swapProduct__panel--close"
                        title={t('Отменить замену', 'Cancel swap')}
                        onClick={clearReplaceTransaction}
                    >
                        <CrossIconSvg />
                    </div>
                </div>
            </div>
        );
    }

    if (replacementType === ReplaceTransactionTypeEnum.SET_ITEM) {
        if (!replaceTransaction.parentProductId) {
            // eslint-disable-next-line no-console
            console.warn('replaceTransaction.parentProductId is undefined or null. This code should be unreachable, so you probably made a mistake');
            return <></>;
        }

        const dynamicSetItem = basket.get(replaceTransaction.parentProductId);
        const setComposition = dynamicSetItem?.setProductCompositions;
        if (!setComposition) {
            // eslint-disable-next-line no-console
            console.warn('setComposition is undefined or null. This code should be unreachable, so you probably made a mistake');
            return <></>;
        }

        const setItem: ISetProductComposition | undefined = setComposition.get(replaceTransaction.from);
        const originalItem: IProductItemModel = setComposition.get(replaceTransaction.from)?.originalProductItem;
        const setItemToReplace: IProductItemModel = setComposition.get(replaceTransaction.from)?.selectedProductItem;
        const alternatives: IProductItemModel[] | undefined = setItem?.getAllAlternatives;

        if (!alternatives) {
            // eslint-disable-next-line no-console
            console.warn('alternatives is undefined or null. This code should be unreachable, so you probably made a mistake');
            return <></>;
        }

        const alternativesWithDefaultProduct = [setItemToReplace]
            .concat([originalItem, ...alternatives].filter((item: IProductItemModel) => item.productId !== setItemToReplace.productId));

        return (
            <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
                <div className="currentProducts_items_to_add">
                    <Scrollbars
                        style={SCROLLBAR_WIDTH}
                        hideTracksWhenNotNeeded={true}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={372}
                        universal={true}
                        renderView={ScrollBarWrapper}
                    >
                        {/**
                         * todo: overflow скроллбара не позволяет вылезти из-под него карточке набора.
                         * Сейчас реализовано стилем crutch -> выставляет margin-bottom у последнего элемента
                         * */}
                        {alternativesWithDefaultProduct.map((item: IProductItemModel, index) => {
                            if (index === 0) {
                                return (
                                    <ProductItemToAdd
                                        className="choosed"
                                        key={item.id}
                                        catalogItem={item}
                                        isShownForReplacement={true}
                                        isChoosedForReplacement={true}
                                        dataCyValue={index}
                                    />
                                );
                            }

                            return (
                                <ProductItemToAdd
                                    key={item.id}
                                    catalogItem={item}
                                    isShownForReplacement={true}
                                    dataCyValue={index}
                                />
                            );
                        })}
                    </Scrollbars>
                </div>
                <div className="swapProduct__panel">
                    <div
                        className="swapProduct__panel--item choosed"
                        title={setItemToReplace && setItemToReplace.name || ''}
                    >
                        <div className="image_wrapper">
                            <ProductImage
                                className="box_img"
                                productName=""
                                imageLink={setItemToReplace.image}
                                productType={setItemToReplace.type}
                            />
                        </div>
                        <div className="product_name">{setItemToReplace.name}</div>
                    </div>
                    <div className="swapProduct__panel--icon"><SwapArrowsIcon /></div>
                    <div
                        className={cn('swapProduct__panel--item', productItemToReplaceBy && 'choosed')}
                        title={productItemToReplaceBy && productItemToReplaceBy.name || ''}
                    >
                        <div className="image_wrapper">
                            {productItemToReplaceBy && (
                                <ProductImage
                                    className="box_img"
                                    productName=""
                                    imageLink={productItemToReplaceBy.image}
                                    productType={productItemToReplaceBy.type}
                                />
                            )}
                            {!productItemToReplaceBy && (
                                <ProductImage
                                    className="box_img"
                                    productName=""
                                    imageLink=""
                                    productType={ProductItemTypeEnum.REGULAR}
                                />
                            )}
                        </div>
                        <div className="product_name">
                            {productItemToReplaceBy && productItemToReplaceBy.name}
                            {!productItemToReplaceBy && t('Выберите товар для замены', 'Choose product for replacement')}
                        </div>
                    </div>
                    <div className="swapProduct__panel--btn">
                        <Button
                            className="button__submit"
                            variant="7"
                            size="1"
                            text={t('Заменить', 'Swap')}
                            onClick={onSwap}
                            disabled={!isReadyToProccedReplacement}
                            preloaderColor="#fff"
                        />
                    </div>
                    <div
                        className="swapProduct__panel--close"
                        title={t('Отменить замену', 'Cancel swap')}
                        onClick={clearReplaceTransaction}
                    >
                        <CrossIconSvg />
                    </div>
                </div>
            </div>
        );
    }

    // eslint-disable-next-line no-console
    return <></>;
};


export default observer(SwapProduct);
