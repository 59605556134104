import {
    action, IReactionDisposer, observable, reaction,
} from 'mobx';

import { Store } from '@store/store';
import SipService from '@services/sip/SipService';
import CustomerService from '@services/CustomerService';
import { Locations } from '@core/models/locations';
import HistoryService from '@services/HistoryService';
import { CustomerModeStore } from '@store/customerModeStore';
import { UserService } from '@services/index';
import isFeatureFlagCustomerService from '@core/helpers/isFeatureFlagCustomerService';
import getPhoneForCypressTestingIncomingCall from '@/env/getPhoneForCypressTestingIncomingCall';
import isCypressTestingMode from '@/env/isCypressTestingMode';
import getDelayForIncomingCallInMilliseconds from '@/env/getDelayForIncomingCallInMilliseconds';
import { EnumSipStatus } from '@services/sip/models';


class ClientServiceModeEventSubscriptionService {
    private readonly _store: Store;

    private readonly _historyService: HistoryService;

    private readonly _sipService: SipService;

    private readonly _customerService: CustomerService;

    private readonly _customerModeStore: CustomerModeStore;

    private readonly _userService: UserService;

    private _disposerOnChangeAutoSearch: IReactionDisposer | undefined;

    private disposerOnIncomingCall: IReactionDisposer | undefined;

    private _disposerOnLogout: IReactionDisposer | undefined;

    private _disposerOnIncomingCallStage2: IReactionDisposer | undefined;

    @observable
    private _incomingCallTestMark = false;

    private static get _cypressTestModeForAnIncomingCall(): boolean {
        return isFeatureFlagCustomerService() && isCypressTestingMode();
    }

    constructor(
        store: Store,
        sipService: SipService,
        customerService: CustomerService,
        historyService: HistoryService,
        customerModeStore: CustomerModeStore,
        userService: UserService,
    ) {
        this._store = store;
        this._customerService = customerService;
        this._sipService = sipService;
        this._historyService = historyService;
        this._customerModeStore = customerModeStore;
        this._userService = userService;

        // Если это режим КлС и cy-тестов, то через N количество миллисекунд после инициализации этого класса
        // произойдет фейковый входящий вызов
        if (ClientServiceModeEventSubscriptionService._cypressTestModeForAnIncomingCall) {
            setTimeout(
                () => {
                    this._incomingCallTestMark = true;
                    this._sipService.sip.setStatus(EnumSipStatus.LIVE);
                },
                Number(getDelayForIncomingCallInMilliseconds()),
            );
        }
    }

    @action
    public subscribe(): void {
        this.unsubscribe();

        this._disposerOnLogout = reaction<boolean>(
            () => this._userService.isAuthCookieValid,
            (loggedIn) => {
                if (!loggedIn) {
                    this._customerModeStore.clearCustomerServiceOrderList();
                    this._customerModeStore.store.clearSearchForm();
                }
            },
        );

        // Когда приходит звонок, то мы очищаем форму поиска заказов и задаем телефон равных входящему номеру, ставим метку что надо сделать поиск
        this.disposerOnIncomingCall = reaction<boolean>(
            () => this._sipService.isAnIncomingCallToACustomer
                && Boolean(this._sipService.phoneNumber)
                && !isFeatureFlagCustomerService(),
            (isClientServiceIncomingCall) => {
                if (isClientServiceIncomingCall) {
                    this._customerModeStore.store.clearSearchForm();
                    this._store.clearOrderView();
                    this._customerModeStore.store.setCustomerPhone(this._sipService.phoneNumber);
                    this._customerModeStore.store.setShouldMadeAutoSearch(true);
                }
            },
        );

        // TODO: Объединить с предыдущей реакцией
        this._disposerOnChangeAutoSearch = reaction<boolean>(
            () => this._customerModeStore.store.shouldMadeAutoSearch && !isFeatureFlagCustomerService(),
            (value) => {
                if (value) {
                    void this._customerService.getOrdersByParams(); // ищем заказы по параметрам
                    // При входящем звонке автоматически выполняется поиск по этому заказу и пользователя перекидываем на страницу КлС
                    this._customerModeStore.store.setShouldMadeAutoSearch(false);
                    this._customerModeStore.store.setSelectedPhoneToCall(this._sipService.getPhoneNumber());
                    this._historyService.history.push(Locations.CUSTOMER_SERVICE);
                }
            },
        );

        // Если это режим КлС и cy-тестов, то ждем фейкового события входящего вызова
        if (ClientServiceModeEventSubscriptionService._cypressTestModeForAnIncomingCall) {
            this._disposerOnIncomingCallStage2 = reaction<boolean>(
                () => this._incomingCallTestMark,
                this._processingOfIncomingCallConditions.bind(this),
            );
        }

        if (isFeatureFlagCustomerService()) {
            // блок для второго этапа под feature-flag-ом:
            this._disposerOnIncomingCallStage2 = reaction<boolean>(
                () => this._sipService.isAnIncomingCallToACustomer && Boolean(this._sipService.phoneNumber),
                this._processingOfIncomingCallConditions.bind(this),
            );
        }
    }

    @action
    public unsubscribe(): void {
        if (this._disposerOnChangeAutoSearch) {
            this._disposerOnChangeAutoSearch();
        }

        if (this.disposerOnIncomingCall) {
            this.disposerOnIncomingCall();
        }

        if (this._disposerOnLogout) {
            this._disposerOnLogout();
        }

        if (this._disposerOnIncomingCallStage2) {
            this._disposerOnIncomingCallStage2();
        }
    }

    private async _processingOfIncomingCallConditions(isClientServiceIncomingCall: boolean): Promise<void> {
        if (isClientServiceIncomingCall) {
            const phone = ClientServiceModeEventSubscriptionService._cypressTestModeForAnIncomingCall
                ? getPhoneForCypressTestingIncomingCall()
                : this._sipService.getPhoneNumber();

            this._store.clearOrderView();
            this._customerModeStore.clearOrdersFilterModel();
            this._customerModeStore.store.setCustomerPhone(phone);
            this._customerModeStore.store.setSelectedPhoneToCall(phone);

            await this._customerService.getClientCard();
            await this._customerService.getOrdersByParamsStage2(); // ищем заказы по параметрам
            await this._customerService.getFAQArticlesByParamsStage2(); // ищем faq зная заказы
            // this._store.customerServiceStore.setShouldMadeAutoSearch(true);
        }
    }
}


export default ClientServiceModeEventSubscriptionService;
