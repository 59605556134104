import { useLocation, useParams } from 'react-router-dom';

interface IParamsValues {
    [key: string]: string | null;
}

const useQueriesHook = (paramsArray: string[])=> {

    const { id } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const paramsValues: IParamsValues = {};

    paramsArray.forEach((param) => {
        paramsValues[param] = params.get(param);
    });

    return { id, paramsValues };

};

export default useQueriesHook;