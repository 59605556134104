import moment from 'moment';

const days = 7;

export const getDaysBetweenDates = (startDate: string, endDate: string, format: string) => {

    const begin = moment(startDate).clone();
    const end  = moment(endDate);

    const dates: string[] = [];

    while (begin.isSameOrBefore(end)) {
        dates.push(begin.format(format));
        begin.add(1, 'days');
    }
    return dates;
};

export const startOfPrevPrevWeek = moment().subtract(days * 3 - 1, 'days').format('MM-DD-YYYY');
export const endOfPrevPrevWeek = moment().subtract(days * 2, 'days').format('MM-DD-YYYY');
export const startOfPrevWeek = moment().subtract(days * 2 - 1, 'days').format('MM-DD-YYYY');
export const endOfPrevWeek = moment().subtract(days, 'days').format('MM-DD-YYYY');
export const startOfCurrentWeek = moment().subtract(days - 1, 'days').format('MM-DD-YYYY');
export const endOfCurrentWeek = moment().format('MM-DD-YYYY');

export const currentMonth = moment().format('MM');
export const prevMonth = moment().add(-1, 'M').format('MM');
export const prevPrevMonth = moment().add(-2, 'M').format('MM');

export const startOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
export const endOfCurrentMonth   = moment().endOf('month').format('YYYY-MM-DD');
export const startOfPrevMonth = moment().add(-1, 'M').startOf('month').format('YYYY-MM-DD');
export const endOfPrevMonth   = moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD');
export const startOfPrevPrevMonth = moment().add(-2, 'M').startOf('month').format('YYYY-MM-DD');
export const endOfPrevPrevMonth   = moment().add(-2, 'M').endOf('month').format('YYYY-MM-DD');


export const getFullFormatTime = (seconds: number): string => {

    const hours = Math.floor(moment.duration(seconds, 'seconds').asHours());
    const minutes = moment.duration(seconds, 'seconds').minutes();
    const sec = moment.duration(seconds, 'seconds').seconds();

    return `${hours > 9 ? hours : '0' + String(hours)}:${minutes > 9 ? minutes : '0' + String(minutes)}:${sec > 9 ? sec : '0' + String(sec)}`;

};



