import React, {
    FunctionComponent, useMemo, useState,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import SmoothCollapse from 'react-smooth-collapse';
import map from 'lodash/map';
import SwiperCore, { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { Store } from '@/app/store/store';
import I18NService from '@services/I18NService';
import { ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import OffersService, { IDispatchingBonusItems } from '@services/order/OffersService';
import BonusItem from './bonus-item/BonusItem';
import Chips from './chips/BlockWithBonusChips';

import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './BlockWithBonusProducts.scss';

SwiperCore.use([Scrollbar]);

const BlockWithBonusProducts: FunctionComponent = () => {
    const [interval, setIntervalLink] = useState<NodeJS.Timeout | null>(null);

    const [
        { dispatchingBonusGoods, isBonusItemInCatalogAvailable },
        { t },
        {
            currentOrder: {
                offersBlockTabs: {
                    isCollapsed,
                },
            },
        },
    ] = useInstances(
        OffersService,
        I18NService,
        Store,
    );

    const swiperOptions = useMemo<Swiper>(
        () => ({
            freeMode: true,
            mousewheel: true,
            direction: 'horizontal',
            spaceBetween: 8,
            slidesPerView: 'auto',
            scrollbar: {
                draggable: true,
            },
            observer: true,
            onInit: (swiper) => {
                const int = setInterval(() => {
                    swiper.update();
                }, 100);

                setIntervalLink(int);
            },
            onDestroy: () => {
                if (interval) {
                    clearInterval(interval);
                }
            },
        }),
        [interval],
    );

    return (
        <div className="BlockWithBonusProducts">
            <div className="BlockWithBonusProducts__collapse">
                <Chips />
                <SmoothCollapse
                    expanded={!isCollapsed}
                    heightTransition="0.4s ease"
                >
                    {dispatchingBonusGoods.length === 0 && (
                        <div
                            className="no_items_found"
                        >
                            {t('Ничего не нашлось', 'No items found')}
                        </div>
                    )}
                    <Swiper
                        {...swiperOptions}
                        data-cy="BlockWithBonusProductsSwiperWrapper"
                    >
                        {map<IDispatchingBonusItems, JSX.Element>(
                            dispatchingBonusGoods,
                            (item, index) => {
                                if (item.productItemFlag) {
                                    const productItem = item.productItem!;

                                    return (
                                        <SwiperSlide
                                            key={productItem.id}
                                            className={cn([
                                                'BlockWithBonusProducts__swiper_slide',
                                                isBonusItemInCatalogAvailable(productItem) && 'available',
                                                !isBonusItemInCatalogAvailable(productItem) && 'unavailable',
                                                productItem.subType === ProductItemSubTypeEnum.OLD_PROMOTION && 'bonus_item__OLD_PROMOTION',
                                                productItem.subType === ProductItemSubTypeEnum.GIFT && 'bonus_item__GIFT',
                                                productItem.subType === ProductItemSubTypeEnum.PROMO && 'bonus_item__PROMO',
                                            ])}
                                        >
                                            <BonusItem
                                                bonusItem={productItem}
                                                dataCyIndex={index}
                                            />
                                        </SwiperSlide>
                                    );
                                }

                                const promotionBuilderItem = item.promotionBuilderItem!;

                                return (
                                    <SwiperSlide
                                        key={promotionBuilderItem.id}
                                        className={cn([
                                            'BlockWithBonusProducts__swiper_slide',
                                            'available',
                                            // isBonusItemInCatalogAvailable(promotionBuilderItem) && 'available',
                                            // !isBonusItemInCatalogAvailable(promotionBuilderItem) && 'unavailable',
                                            'bonus_item__PROMOTION_BUILDER',
                                        ])}
                                    >
                                        <BonusItem
                                            promotionBuilderItem={promotionBuilderItem}
                                            bonusItem={null}
                                            dataCyIndex={index}
                                        />
                                    </SwiperSlide>
                                );
                            },
                        )}
                    </Swiper>
                </SmoothCollapse>
            </div>
        </div>
    );
};

export default observer(BlockWithBonusProducts);
