import React, { useState, ReactNode, useCallback } from 'react';
import cn from 'classnames';
import Slider, { Settings } from 'react-slick';
import { Scrollbars } from 'react-custom-scrollbars';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import ProductBenefit from '@UIElements/ProductBenefit';
import ProductImage from '@images/ProductImage.jpg';
import ProductImage2 from '@images/product1.jpg';
import I18NService from '@services/I18NService';


enum ProductTabs {
    PRODUCT = 'PRODUCT',
    ABOUT = 'ABOUT',
    DESCRIPTION = 'DESCRIPTION',
    CONTRAINDICATIONS = 'CONTRAINDICATIONS',
}

interface IDataTab {
    text: string;
    state: ProductTabs;
}


const ProductCard = (): JSX.Element => {
    const ACTIVE_TAB = 'activeTab';

    const { t } = useInstance(I18NService);

    const [tabs, setTabs] = useState<Record<string, 'activeTab'>>({ [ProductTabs.PRODUCT]: ACTIVE_TAB });

    const images: string[] = [ProductImage, ProductImage2];

    const scrollbarsStyles: Record<string, string> = {
        height: '100%',
    };

    const dataTabs: IDataTab[] = [
        { text: t('Продукт', 'Product'), state: ProductTabs.PRODUCT },
        { text: t('Об', 'About'), state: ProductTabs.ABOUT },
        { text: t('Описание', 'Description'), state: ProductTabs.DESCRIPTION },
        { text: t('Противопоказания', 'Contraindications'), state: ProductTabs.CONTRAINDICATIONS },
    ];

    const settingsSlider: Settings = {
        arrows: false,
        dots: true,
        dotsClass: 'dots',
        appendDots: (dots: ReactNode): JSX.Element => <ul>{dots}</ul>,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const openTab = useCallback((tabId: ProductTabs) => setTabs({ [tabId]: ACTIVE_TAB }), [tabs]);

    const ImageTemplate = ({ image }: { image: string }): JSX.Element => (
        <div className="slide">
            <div className="image">
                <img
                    className="image-img"
                    src={image}
                    alt="ProductImage"
                />
            </div>
        </div>
    );

    const TabTemplate = ({ text, state }: { text: string; state: ProductTabs }): JSX.Element => (
        <div
            className={cn('tab', tabs[state])}
            onClick={() => openTab(state)}
        >
            {text}
        </div>
    );

    return (
        <div className="productCard__wrapper">
            <div className="b-product-card">
                <div className="wrap">
                    <div className="product-slider">
                        <Slider className="slider" {...settingsSlider}>
                            {
                                images.map((x: string) => (
                                    <ImageTemplate
                                        key={x}
                                        image={x}
                                    />
                                ))
                            }
                        </Slider>
                    </div>
                    <div className="product-info">
                        <div className="product-name">Goji Cream</div>
                        <div className="product-tabs">
                            {
                                dataTabs.map((x: IDataTab) => (
                                    <TabTemplate
                                        key={x.state}
                                        text={x.text}
                                        state={x.state}
                                    />
                                ))
                            }
                        </div>
                        <div className="tabs-container">
                            <div className={cn('tab-container', tabs[ProductTabs.PRODUCT])}>
                                <Scrollbars
                                    style={scrollbarsStyles}
                                    hideTracksWhenNotNeeded={true}
                                    universal={false}
                                >
                                    <div className="productCard__container__row">
                                        <div className="productCard__name">Tube:</div>
                                        <div className="productCard__value">50 ml</div>
                                    </div>
                                    <div className="productCard__container__row">
                                        <div className="productCard__name">Product by:</div>
                                        <div className="productCard__value">Hendel Garden</div>
                                    </div>
                                    <div className="productCard__container__row">
                                        <div className="productCard__name">Manufactured by:</div>
                                        <div className="productCard__value">
                                            By Hendel Graden, LLP. Manufactured in the Russian and
                                            foreign industrial facilities in the environment that
                                            fully meets the requirements of both domestic Russian
                                            (technical regulations of the Customs Union) and
                                            international standards (ISO, GMPc).
                                        </div>
                                    </div>
                                </Scrollbars>
                            </div>
                            <div className={cn('tab-container', tabs[ProductTabs.ABOUT])}>
                                <Scrollbars
                                    style={scrollbarsStyles}
                                    hideTracksWhenNotNeeded={true}
                                    universal={false}
                                >
                                    <ProductBenefit
                                        title="ENERGY BOOSTER"
                                        text="Goji Cream acts as an energy booster for mature skin, offering the perfect solution for its repair."
                                    />
                                    <ProductBenefit
                                        title="FIGHTS AGAINST AGE-RELATED CHANGES"
                                        text="Goji Cream mobilizes internal reserves of skin, helping it fight against age-related changes"
                                    />
                                    <ProductBenefit
                                        title="REMOVES TRACES"
                                        text="Goji Cream removes traces of fatigue, smooths and moisturizes skin, noticeably improves skin tonicity and firmness"
                                    />
                                </Scrollbars>
                            </div>
                            <div className={cn('tab-container', tabs[ProductTabs.DESCRIPTION])}>
                                <Scrollbars
                                    style={scrollbarsStyles}
                                    hideTracksWhenNotNeeded={true}
                                    universal={false}
                                >
                                    <div className="productCard__value full-width text-content">
                                        Revitalizing cream with goji berries extract is a true
                                        energy boost for perfect regeneration of a mature skin. It
                                        activates the internal aging potential of the skin, removes
                                        signs of fatigue, smoothes and moisturizes, visibly improves
                                        skin tone and resistance.
                                    </div>
                                </Scrollbars>
                            </div>
                            <div className={cn('tab-container', tabs[ProductTabs.CONTRAINDICATIONS])}>
                                <Scrollbars
                                    style={scrollbarsStyles}
                                    hideTracksWhenNotNeeded={true}
                                    universal={false}
                                >
                                    <div className="productCard__value full-width text-content">
                                        Goji is POSSIBLY SAFE when taken appropriately by mouth,
                                        short-term. It has been used safely for up to 3 months. In
                                        very rare cases, goji fruit can cause increased sensitivity
                                        to sunlight, liver damage, and allergic reactions.
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default observer(ProductCard);
