import React, { FC } from 'react';
import './Accordion.scss';


const Accordion: FC = ({ children }) => (
    <div className="Accordion">
        {children}
    </div>
);

export default Accordion;
