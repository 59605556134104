import { OrderStatusesEnum } from '@api/order-api-service/models';
import { ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { OrderService, SipService } from '@services/index';
import { Store } from '@store/store';
import { computed, IReactionDisposer, reaction } from 'mobx';
import CallAutomatizationHandler from '../call-automatization/CallAutomatizationHandler';
import { EnumSipStatus, ReasonsForEndingConversation } from '../sip/models';

/**
 * "Присвоить статус NOANSWER при отсутствии ответа на звонок после (Секунд)":\
 * если "Назначить статус "Недозвон" при сбросе" [noAnswerIfBusy] деактивирован,\
 * то NOANSWER присвоится через N секунд гудков (без ответа клиентом)
 */
class NoAnswerAfterSecHandler {
    constructor(
        private readonly store: Store,
        private readonly _sipService: SipService,
        private readonly _callAutomatization: CallAutomatizationHandler,
        private readonly _orderService: OrderService,
    ) {
    }

    private _establishingCallInterval: NodeJS.Timeout | undefined;

    private _progressCallSeconds = 0;

    private _disposeOnOutgoingCallProgress: IReactionDisposer | undefined;

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get _noAnswerAfterSec(): number {
        return this._currentOrder.country.noAnswerAfterSec;
    }

    private get _noAnswerIfBusy(): boolean {
        return this._currentOrder.country.noAnswerIfBusy;
    }

    private get _statusAutomatizationActive(): boolean {
        return this._currentOrder.country.statusAutomatizationActive;
    }

    @computed
    private get callHistoryEvent(): ICallHistoryEventModel | null {
        return this._currentOrder.lastCallHistoryEvent;
    }

    private setCallProgressSeconds(value: number) {
        this._progressCallSeconds = value;
    }

    public unsubscribe = (): void => {
        if (this._disposeOnOutgoingCallProgress) {
            this._disposeOnOutgoingCallProgress();
        }
    };

    public subscribe = (): void => {
        this.unsubscribe();

        this._disposeOnOutgoingCallProgress = reaction(
            () => this._currentUser.mode === UserModeEnum.REGULAR
                && this._sipService.getSipStatus() === EnumSipStatus.PROGRESS
                && this._statusAutomatizationActive,
            // если звоним, а клиент не отвечает на звонок
            (isACallProgressActive) => {
                if (isACallProgressActive) {
                    if (this._currentOrder.startCallAutomatic ? this._callAutomatization.isThisCallLastInCallAutomatization : true) {
                        this._establishingCallInterval = setInterval(() => {
                            this.proceedNoAnswerAfterSecAutomatization();
                        }, 1000);
                    }
                } else if (this._establishingCallInterval) {
                    clearInterval(this._establishingCallInterval);
                    this.setCallProgressSeconds(0);
                }
            },
        );
    };

    private proceedNoAnswerAfterSecAutomatization() {
        this.setCallProgressSeconds(this._progressCallSeconds + 1);

        if (this._progressCallSeconds >= this._noAnswerAfterSec) {
            void this.nativeBehavior();
        }
    }

    public nativeBehavior = async (): Promise<void> => {
        // eslint-disable-next-line no-console
        console.info('Status automatization has been triggered by parameter: noanswer_after_sec = ', this._noAnswerAfterSec);
        if (this.callHistoryEvent) {
            this.callHistoryEvent.setEndCall(ReasonsForEndingConversation.AUTOMATION_STATUSES);
        }

        void this._sipService.indicateTheEndOfTheCall();
        this._sipService.endCall();
        await this._orderService.automaticallyAssignStatus(OrderStatusesEnum.NO_ANSWER);
        this.setCallProgressSeconds(0);
    };
}

export default NoAnswerAfterSecHandler;
