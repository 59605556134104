import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import I18NService from '@services/I18NService';
import { IWithPossibleImageAndType } from '@models/mobx-state-tree/customer-mode/orderSearchFilter.model';
import SelectDummy from '@/app/ui-elements/SelectDummy';
import { CustomLiRendererProps } from '@/app/ui-elements/SelectDummy/SelectDummy';
import { observer } from 'mobx-react';
import React from 'react';
import { useInstance } from 'react-ioc';
import { useProductSelect } from './helpers/useProductSelect';
import './ProductFilterSelect.scss';

const LiWithProductImage = (props: CustomLiRendererProps<IWithPossibleImageAndType>): JSX.Element => {
    const {
        onSelect, key, id, value,
    } = props;

    return (
        <li
            className="product_list_items"
            key={key}
            onClick={onSelect}
            id={id}
            title={value.label}
        >
            <div className="flex__row">
                <div className="image_wrapper">
                    <ProductImage
                        productName=""
                        imageLink={value.image}
                        productType={value.type}
                    />
                </div>
                <div className="custom_label">{value.label}</div>
            </div>
        </li>
    );
};

/**
 * Использует хук, который передает внутрь компонента всю нужную информацию и обсепечивает логику компонента
 */
const ProductFilterSelect = (): JSX.Element => {
    const { t } = useInstance(I18NService);
    const [onChange, valuesSorted, selectedValue] = useProductSelect();

    return (
        <SelectDummy
            label={t('Название товара', 'Product name')}
            onChange={onChange}
            value={selectedValue}
            values={valuesSorted}
            hasIcon={false}
            inputId="product_filter_select"
            disabled={false}
            invalid={false}
            validationMessage=""
            customLiRenderer={LiWithProductImage}
        />
    );
};


export default observer(ProductFilterSelect);
