import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

export const ScriptBaseDataModel = t
    .model('ScriptBaseDataModel', {
        id: t.identifier,
        key: t.number,
        color: t.string,
        text: t.string,
    });


export interface IScriptBaseDataModel extends Instance<typeof ScriptBaseDataModel> {}
export interface IScriptBaseDataModelSnapshotIn extends SnapshotIn<typeof ScriptBaseDataModel> {}
export interface IScriptBaseDataModelSnapshotOut extends SnapshotOut<typeof ScriptBaseDataModel> {}