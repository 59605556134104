import ErrorService from '@core/services/ErrorService';
import I18NService from '@services/I18NService';
import BeepSound from '@core/constants/BeepSound';


class AudioService {
    private _audioInstance: HTMLAudioElement | null = null;

    constructor(
        private readonly _errorService: ErrorService,
        private readonly _I18NService: I18NService,
    ) {}

    private _createInstance(audioB64Value: string): void {
        const strB64 = `data:audio/mpeg;base64,${audioB64Value}`;

        this._audioInstance = new Audio(strB64);

        this._audioInstance.addEventListener(
            'ended',
            () => {
                this._audioInstance = null;
            },
        );
    }

    private async _play(): Promise<void> {
        if (this._audioInstance) {
            try {
                await this._audioInstance.play();
            } catch (error) {
                const { name } = error;

                if (name === 'NotAllowedError' || name === 'NotSupportedError') {
                    // eslint-disable-next-line no-console
                    console.warn(error);
                }
            }
        } else {
            // eslint-disable-next-line no-console
            console.error(
                this._I18NService.t(
                    'Состояние аудиозаписи не определено.',
                    'Audio recording status is undefined.',
                ),
            );
        }
    }

    public startBeepSound(): void {
        this._createInstance(BeepSound);
        void this._play();
    }
}


export default AudioService;
