import React from 'react';

/**
 * Мэтчит все символы после последнего слэша
 */
const regExpForJiraUrl = new RegExp(/([^/]+$)/g);

export const jiraLinkRenderer = (link: string) => {
    const label = link.match(regExpForJiraUrl);

    if (label) {
        return (
            <a
                className="link__jira"
                href={link}
                rel="noopener noreferrer"
                target="_blank"
            >
                {label[0]}
            </a>
        );
    }

    return (
        <a
            className="rgt-text-truncate link__jira"
            href={link}
            rel="noopener noreferrer"
            target="_blank"
        >
            {link}
        </a>
    );
};
