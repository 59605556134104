import React, {
    FunctionComponent, useCallback, useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@store/customerModeStore';
import cn from 'classnames';
import GridTable from '@UIElements/GridTable';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import { v4 } from 'uuid';
import each from 'lodash/each';
import orderBy from 'lodash/orderBy';
import './AppealsTable.scss';
import { ICustomerAppealModel } from '@/app/models/mobx-state-tree/customer-mode/customerAppeals.model';
import { Transition } from 'react-transition-group';
import Appeal from './includes';
import { jiraLinkRenderer } from './includes/jiraLinkRenderer';

type IColsNames = 'jira_url' | 'id' | 'created_at' | 'subject' | 'status';

const AppealsTable: FunctionComponent = (): JSX.Element => {
    const [{ t }, customerMode] = useInstances(I18NService, CustomerModeStore);

    const { clientCard } = customerMode;
    const { appeals, setAppealForAView, selectedAppealForAView } = clientCard;

    const appealsSorted = useMemo<ICustomerAppealModel[]>(
        () => orderBy(
            appeals.filter((appeal) => appeal.jiraUrl),
            (appeal) => Number(appeal.id), 'desc',
        ),
        [appeals.length],
    );

    const onAppealRowClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element = event.target as HTMLDivElement;
        if (element.tagName === 'A') {
            // если нажали на ссылку с тикетом Jira
            return;
        }

        if (element.classList.contains('grid_row')) {
            setAppealForAView(element.id);
            return;
        }

        let parent: HTMLElement | null | undefined = element.parentElement;

        while (!parent?.classList?.contains('grid_row')) {
            parent = parent?.parentElement;
        }

        setAppealForAView(parent.id);
    }, [appeals]);

    const cols: IGridTableColumn[] = [
        {
            id: 'jira_url',
            label: 'JIRA URL',
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'id',
            label: '#',
            minMax_MaxValue: '1fr',
            minMax_MinValue: '50px',
        },
        {
            id: 'created_at',
            label: t('Дата создания', 'Date of creation'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '95px',
        },
        {
            id: 'subject',
            label: t('Тема обращения', 'Subject of the appeal'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '262px',
        },
        {
            id: 'status',
            label: t('Статус', 'Status'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '73px',
        },
    ];

    let rows: WithRows<IColsNames, IBaseCellData>[] = [];

    if (appealsSorted.length > 0) {
        rows = appealsSorted.map((item) => ({
            jira_url: {
                id: v4(),
                content: item.jiraUrl!,
            },
            id: {
                id: v4(),
                content: item.id,
            },
            created_at: {
                id: v4(),
                content: item.createdAt,
            },
            subject: {
                id: v4(),
                content: item.appealSubject,
            },
            status: {
                id: v4(),
                content: item.status,
            },
        }));
    }

    const RenderAppealsTableContent = (rowsData: WithRows<IColsNames, IBaseCellData>[]) => {
        const data: JSX.Element[] = [];

        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            each(row, (value, key) => {
                if (key === 'jira_url') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                key={key}
                                title={value.content}
                            >
                                {jiraLinkRenderer(value.content)}
                            </div>
                        ),
                    );
                } else {
                    if (key === 'id') { // устанавливаем для строки таблицы идентификатор обращения
                        rowId = value.content;
                    }

                    rowCell.push(
                        (
                            <div
                                key={key}
                                className="grid_cell grid_cell_height"
                            >
                                <div
                                    className="rgt-text-truncate"
                                    title={value.content}
                                >
                                    {value.content}
                                </div>
                            </div>
                        ),
                    );
                }
            });

            data.push(
                (
                    <div
                        className="grid_row"
                        data-cy="customer_order-appeals_table_row"
                        onClick={onAppealRowClick}
                        id={rowId}
                        key={rowId}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    if (appealsSorted.length > 0) {
        return (
            <>
                <div
                    className={cn(['appeal_view__wrapper'])}
                >
                    <div
                        className={cn([
                            'appeal_view__wrapper-content',
                            selectedAppealForAView && 'active',
                        ])}
                    >
                        <Transition
                            in={Boolean(selectedAppealForAView)}
                            timeout={400}
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state): JSX.Element => (
                                <div className={state}>
                                    <Appeal />
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>

                <GridTable
                    className="order_information-grid-table-appeals"
                    columns={cols}
                    rows={rows}
                    rowsSize={rows.length}
                    cellsRenderer={RenderAppealsTableContent}
                    tableContentHeight={645}
                    renderHeaderSeparately={true}
                />
            </>
        );
    }

    return (
        <div
            className="result-alert"
        >
            <div className="message_header">
                {t('Нет обращений', 'No appeals')}
            </div>
            <div className="message_content">
                {t('По звонящему клиенту не найдено ни одного обращения', 'No appeals found for the calling customer')}
            </div>
        </div>
    );
};

export default observer(AppealsTable);
