import { customI18NTFunction } from '@services/I18NService';

class OfflineError extends Error {
    constructor(t: customI18NTFunction) {
        super();

        if (t) {
            this.message = t(
                'Отсутствует интернет подключение.',
                'No internet connection',
            );
        } else {
            this.message = 'No internet connection';
        }
    }
}


export default OfflineError;
