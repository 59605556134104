import axios, {
    AxiosError, AxiosResponse, AxiosStatic,
} from 'axios';

import { AxiosRequestConfigCustom } from '@api/models';

export class AxiosBase {
    private _accessToken: null | string = null;

    private _axios: AxiosStatic;

    get fetch(): <T = any, R = AxiosResponse<T>>(config: AxiosRequestConfigCustom) => Promise<R> {
        return this._axios.request;
    }

    constructor() {
        this._axios = axios;

        this._axios
            .interceptors
            .request
            .use(
                this._interceptorsRequestUse.bind(this),
                (error: AxiosError) => Promise.reject(error),
            );

        this._axios
            .interceptors
            .response
            .use(
                this._interceptorsResponseUse.bind(this),
                (error: Error): Promise<any> => Promise.reject(error),
            );
    }

    private static _withHeader = (token: string) => ({
        Authorization: `Bearer ${token}`,
    });

    initToken = (accessToken: string) => {
        this._accessToken = accessToken;
    };

    private _interceptorsResponseUse(response: AxiosResponse): AxiosResponse {
        return response;
    }

    private _interceptorsRequestUse(config: AxiosRequestConfigCustom): Promise<AxiosRequestConfigCustom> {
        return new Promise(((resolve) => {
            if (this._accessToken?.length) {
                config.headers = AxiosBase._withHeader(this._accessToken);
            }

            if (!config.headers.Authorization?.length && !config.noTokenRequired) {
                //reject(new NoAccessTokenInRequestHeaderError(config.url || ''));

                return;
            }

            resolve(config);

            return;
        }));
    }
}


const axiosBase = new AxiosBase();


export default axiosBase;
