import { Store } from '@store/store';
import { action, computed } from 'mobx';
import filter from 'lodash/filter';
import each from 'lodash/each';
import reduce from 'lodash/reduce';

import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { OrderService } from '@services/index';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import { customI18NTFunction } from '@services/I18NService';
import BasketService from '@services/order/BasketService';
import { IDispatchingBonusItems } from '@services/order/OffersService';
import { IPromotionBuilderModel } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';
import { ConfirmModalType } from '@core/models/ModalWindow';
import MissingItemsForPromotionBuilder
    from '@components/main/order-page/modal-windows/missing-items-for-promotion-builder/MissingItemsForPromotionBuilder';

type TPromotionBuilderModel = IPromotionBuilderModel;

/**
 * Сервис, который содержит методы и вычисляемые свойства, для работы с Конструктором акций.
 */
class PromotionBuilderService {
    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _I18NService: I18NService,
        private readonly _modalService: ModalService,
        private readonly _basketService: BasketService,
    ) {
    }

    public getTextAboutAmountOfGoodsThatAreMissing = (promotionBuilderModel: IPromotionBuilderModel): string | undefined => {
        const { mainItemForPromotionBuilder } = promotionBuilderModel;

        if (mainItemForPromotionBuilder) {
            const mainItemAmountToAdd = this._currentOrder.additionalParamsCurrentOrder.catalog
                .get(promotionBuilderModel.mainProductModelId)?.amountToAdd;

            const mainItemFromCartToWhichWeApplyPromotionBuilder = this._basketService.findAnItemInMainProductByTypeAndPartnerProductId(
                mainItemForPromotionBuilder.type,
                mainItemForPromotionBuilder.subType,
                mainItemForPromotionBuilder.partnerProductId,
            );

            if (mainItemFromCartToWhichWeApplyPromotionBuilder && mainItemAmountToAdd) {
                const howManyItemsAreLeftToApplyPromotionBuilder = mainItemAmountToAdd - mainItemFromCartToWhichWeApplyPromotionBuilder.quantity;

                if (howManyItemsAreLeftToApplyPromotionBuilder > 0) {
                    return this._t(
                        'Осталось добавить {{amount}} {{productName}} до возможности применения акции.',
                        'All that\'s left to add is {{amount}} {{productName}} before the stock can be applied.',
                        {
                            amount: howManyItemsAreLeftToApplyPromotionBuilder,
                            productName: mainItemFromCartToWhichWeApplyPromotionBuilder.productItem.name,
                        },
                    );
                }
            }
        }

        return undefined;
    };

    /**
     * Возвращает количество основного товара,
     * которое еще нужно добавить,
     * для того чтобы можно было применить КА
     */
    public getAmountOfGoodsThatAreMissing = (promotionBuilderModel: IPromotionBuilderModel): number | undefined => {
        const { mainItemForPromotionBuilder } = promotionBuilderModel;

        if (mainItemForPromotionBuilder) {
            const mainItemAmountToAdd = this._currentOrder.additionalParamsCurrentOrder.catalog
                .get(promotionBuilderModel.mainProductModelId)?.amountToAdd;

            const mainItemFromCartToWhichWeApplyPromotionBuilder = this._basketService.findAnItemInMainProductByTypeAndPartnerProductId(
                mainItemForPromotionBuilder.type,
                mainItemForPromotionBuilder.subType,
                mainItemForPromotionBuilder.partnerProductId,
            );

            if (mainItemFromCartToWhichWeApplyPromotionBuilder && mainItemAmountToAdd) {
                const howManyItemsAreLeftToApplyPromotionBuilder = mainItemAmountToAdd - mainItemFromCartToWhichWeApplyPromotionBuilder.quantity;

                if (howManyItemsAreLeftToApplyPromotionBuilder > 0) {
                    return howManyItemsAreLeftToApplyPromotionBuilder;
                }
            }
        }

        return undefined;
    };

    /**
     * Получить доступные конструкторы акций относительно основных товаров в корзине
     */
    @computed
    public get availablePromotionBuildersForMainItemsInBasket(): IDispatchingBonusItems[] {
        if (this._currentOrder.additionalParamsCurrentOrder.activePromotionBuilderModelId) {
            return [];
        }

        return reduce<IBasketItemModel, IDispatchingBonusItems[]>(
            this._currentOrder.mainItemsOnlyInBasket,
            (acc, basketMainItem) => {
                // Находим доступные конструкторы акций для главного товара из массива с акциями
                const _promotionBuildersInCatalog = this._getAllAvailablePromotionBuildersInCatalog(basketMainItem.productItem.partnerProductId);

                if (!_promotionBuildersInCatalog.length) {
                    return acc;
                }

                each<IPromotionBuilderModel>(
                    _promotionBuildersInCatalog,
                    (_promotionBuilderInCatalog) => {
                        /**
                         * Если есть доступная акция для этого товара\
                         * и если есть подарки на складе для этой акции
                         * и главный товар не удален
                         * и не применена старая акция
                         * и количество основного товара в корзине соответствует количеству для применения К.А.
                         */
                        if (
                            !basketMainItem.removed
                            && !basketMainItem.promotionId
                        ) {
                            let isYetAddedToAccumulator = false;

                            each<IDispatchingBonusItems>(
                                acc,
                                (value) => {
                                    if (value.promotionBuilderItem?.id === _promotionBuilderInCatalog.id) {
                                        isYetAddedToAccumulator = true;
                                    }
                                },
                            );

                            // Если ранее не добавляли эту акции в аккумулирующий массив, то добавляем
                            if (!isYetAddedToAccumulator) {
                                acc.push({
                                    productItemFlag: false,
                                    promotionBuilderItem: _promotionBuilderInCatalog,
                                    promotionBuilderFlag: true,
                                });
                            }
                        }
                    },
                );

                return acc;
            },
            [],
        );
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    /**
     * Вернуть список доступных К.А. относительно идентификатора партнерского товара
     * @param partnerProductId id партнерского товара
     */
    private _getAllAvailablePromotionBuildersInCatalog(partnerProductId: string): IPromotionBuilderModel[] {
        return filter<IPromotionBuilderModel>(
            this._currentOrder.promotionBuilderInCatalog,
            (item) => {
                const mainItem = this._currentOrder.additionalParamsCurrentOrder.catalog
                    .get(item.mainProductModelId);

                return !!(mainItem && mainItem.partnerProductId === partnerProductId);
            },
        );
    }

    /**
     * Применить К.А. к главному товару в корзине
     * Метод формирует модели BasketItemModel для каждого товара в акции и заносит их корзину К.А..
     */
    @action
    public applyPromotionBuilder = async (promotionBuilderModel: TPromotionBuilderModel): Promise<void> => {
        const {
            missingOptionalGifts,
        } = promotionBuilderModel;

        let res = true;

        if (missingOptionalGifts.length) {
            res = await this._modalService.showModal<any>(
                MissingItemsForPromotionBuilder,
                {
                    missingOptionalGifts,
                },
            );
        }

        if (res) {
            this._currentOrder.applyPromotionBuilderToBasket(promotionBuilderModel.id);
            this._basketService.addMandatoryItemsFromPromotionBuilderToBasket(promotionBuilderModel);
        }
    };

    @action
    public cancelPromotionBuilder = async (): Promise<void> => {
        const res = await this._modalService.showConfirmModal(
            this._t(
                'Уверены что хотите отменить конструктор акции?',
                'Are you sure you want to cancel the promotion builder?',
            ),
            ConfirmModalType.YesCancel,
        );

        if (res) {
            const {
                clearPromotionBuilderBasket,
                deactivatePromotionBuilderFromBasket,
            } = this._currentOrder;

            this._basketService.transferringPriceOfMainProductFromPromotionBuilder();
            clearPromotionBuilderBasket();
            deactivatePromotionBuilderFromBasket();
        }
    };
}


export default PromotionBuilderService;
