import PersonSVG from '@/app/components/resources/SVG/Person/PersonSVG';
import PhoneSVG from '@/app/components/resources/SVG/Phone/PhoneSVG';
import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@/app/store/customerModeStore';
import InputDummy from '@/app/ui-elements/InputDummy';
import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import './CustomerInfo.scss';


const CustomerInfo: FunctionComponent = (): JSX.Element => {
    const [customerMode, { t }] = useInstances(CustomerModeStore, I18NService);
    const { clientCard } = customerMode;

    return (
        <div
            className="customer_info_wrapper"
        >
            <div className="headline">
                {t('Информация о клиенте', 'Customer info')}
            </div>
            {!clientCard.isLoading && (
                <div className="customer_info_block">
                    {clientCard.customerName && (
                        <div className="customer_info_item">
                            <InputDummy
                                disabled={true}
                                inputId="Customer_name"
                                type="text"
                                invalid={false}
                                validationMessage=""
                                label={t('Имя киента', 'Customer name')}
                                hasIcon={true}
                                value={clientCard.customerName}
                            >
                                <PersonSVG />
                            </InputDummy>
                        </div>
                    )}
                    {clientCard.customerPhone && (
                        <div className="customer_info_item">
                            <InputDummy
                                disabled={true}
                                inputId="Customer_phone"
                                type="tel"
                                invalid={false}
                                validationMessage=""
                                label={t('Телефон клиента', 'Customer phone')}
                                hasIcon={true}
                                value={clientCard.customerPhone}
                            >
                                <PhoneSVG />
                            </InputDummy>
                        </div>
                    )}
                    {clientCard.customerAddress && (
                        <div className="customer_info_item">
                            <InputDummy
                                disabled={true}
                                inputId="Customer_address"
                                type="text"
                                invalid={false}
                                validationMessage=""
                                label={t('Адрес', 'Address')}
                                hasIcon={false}
                                value={clientCard.customerAddress}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomerInfo;
