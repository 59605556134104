import React, {
    FC, useCallback, useEffect, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import cn from 'classnames';
import padStart from 'lodash/padStart';
import { observer } from 'mobx-react';
import I18NService from '@services/I18NService';
import NotificationPollingService from '@services/NotificationPollingService';
import Button from '@UIElements/Button';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { OrderService, UserService } from '@/app/services';
import DivWithDangerouslySetInnerHTML from '@/app/ui-elements/DivWithDangerouslySetInnerHTML';

import './Notifications.scss';
import CloseIconSvg from '@/app/ui-elements/Notify/includes/CloseIconSvg';
import CheckSucceedIcon from '@/app/components/resources/SVG/CheckSucceed/CheckSucceedIcon';


export const formatTime = (milliseconds: number): string => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = (milliseconds - minutes * 60000) / 1000;

    return `${padStart(String(minutes), 2, '0')}:${padStart(String(seconds), 2, '0')}`;
};

const Notifications: FC = () => {
    const [
        store,
        { t },
        nPolling,
        userService,
        orderService,
    ] = useInstances(
        Store,
        I18NService,
        NotificationPollingService,
        UserService,
        OrderService,
    );

    const { userGreetingService, isAuthCookieValid } = userService;
    const { shouldMakeGreeting, madeGreeting } = userGreetingService;

    const {
        inactivityTimer,
        currentOrder: {
            isEmptyCurrentOrder,
            postProcessing,
        },
        ui: {
            orderPage: {
                callPanelVisible,
                notificationHiddenOnScroll,
            },
            bottomMobileTab: {
                activeBottomTab,
            },
            sidebar: {
                statusVisible,
                languagesVisible,
            },
        },
        currentUser,
        notificationWithFeedbackToView,
    } = store;

    const { username, selectedAuxStatus } = currentUser;

    const {
        viewFormFillTimePassed,
        saveFormFillTimePassed,
        modalFormFillTimePassed,
        auxStatusFillTimePassed,
        viewFormFillTimeLimit,
        saveFormFillTimeLimit,
        modalFormFillTimeLimit,
        viewTimerActive,
        saveTimerActive,
        modalTimerActive,
        auxStatusTimerActive,
        auxStatusForwardTimer,
    } = inactivityTimer;

    let time = null;
    let isExpired = false;
    let type = null;

    if (viewTimerActive && viewFormFillTimeLimit !== null) {
        time = viewFormFillTimePassed;
        if (viewFormFillTimeLimit * 1000 <= viewFormFillTimePassed) {
            isExpired = true;
        }
        type = 'view';
    } else if (saveTimerActive && saveFormFillTimeLimit !== null) {
        time = saveFormFillTimePassed;
        if (saveFormFillTimeLimit * 1000 <= saveFormFillTimePassed) {
            isExpired = true;
        }
        type = 'save';
    } else if (modalTimerActive && modalFormFillTimeLimit !== null) {
        time = modalFormFillTimePassed;
        if (modalFormFillTimeLimit * 1000 <= modalFormFillTimePassed) {

            isExpired = true;
        }
        type = 'modal';
    } else if (auxStatusTimerActive) {
        time = auxStatusFillTimePassed;

        if (auxStatusForwardTimer) {
            isExpired = true;
        }

        type = 'auxStatus';

    }

    const { notificationWithoutFeedbackToView } = store;

    const [response, setResponse] = useState<string>('');
    const [pending, setPending] = useState<boolean>(false);
    const [_shouldMakeGreeting, setShouldMakeGreeting] = useState<boolean>(false);
    const [didClosedGreeting, setDidClosedGreeting] = useState<boolean>(false);

    const onCloseGreeting = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
        setDidClosedGreeting(true);
    }, [madeGreeting]);

    useEffect(() => {
        if (shouldMakeGreeting) {
            setShouldMakeGreeting(shouldMakeGreeting);

            setTimeout(() => {
                madeGreeting();
            }, 1000);
        }
    }, [shouldMakeGreeting]);

    const onResponseChange: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void = useCallback((e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        setResponse(e.currentTarget.value);
    }, [setResponse]);

    const onFeedBack: () => Promise<void> = useCallback(async () => {
        if (!notificationWithoutFeedbackToView) {
            return;
        }

        setPending(true);

        try {
            const result = await orderService.setNotificationSuccess(
                parseInt(notificationWithoutFeedbackToView.id, 10),
                notificationWithoutFeedbackToView.isFeedback ? response : undefined,
            );

            if (result) {
                setResponse('');
                store.removeNotification(notificationWithoutFeedbackToView.id);
                // Чтобы следуюзщее уведомление получили прямо сейчас.
                nPolling.reinitialize();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setPending(false);
        }
    }, [setPending, notificationWithoutFeedbackToView]);

    if (!time && !notificationWithoutFeedbackToView && !auxStatusTimerActive) {
        return null;
    }

    const hidden = callPanelVisible || activeBottomTab === BottomTabEnum.MENU || activeBottomTab === BottomTabEnum.CALL || statusVisible || languagesVisible;

    if (hidden || !isAuthCookieValid) {
        return null;
    }

    if (notificationWithFeedbackToView) {
        return null;
    }

    return (
        <div
            className={cn(
                'Notifications',
                type && `${type}-timer`,
                isEmptyCurrentOrder && 'no-order',
                notificationHiddenOnScroll && 'hideOnScroll',
            )}
        >
            <div className="Notifications__content">
                {(!!time || time === 0 && auxStatusTimerActive) && (
                    <div className="timer_wrapper">
                        <div
                            className={cn('timer', isExpired && 'expired')}
                        >
                            {formatTime(time)}
                        </div>
                        {type === 'view' && (
                            <div className="message">
                                {t(
                                    'Пожалуйста, заполните заказ, иначе он будет заблокирован.',
                                    'Please fill in the order, otherwise it will be blocked.',
                                )}
                            </div>
                        )}
                        {type === 'save' && postProcessing && (
                            <div className="message">
                                {t(
                                    'Заполните форму и завершите работу с заказом.',
                                    'Fill in the form and complete the order.',
                                )}
                            </div>
                        )}
                        {type === 'save' && !postProcessing && (
                            <div className="message">
                                {t(
                                    'Заполните форму и измените статус заказа.',
                                    'Fill in the form and change the order status.',
                                )}
                            </div>
                        )}
                        {type === 'modal' && (
                            <div className="message">
                                {t(
                                    'Пожалуйста, Выберите статус заказа.',
                                    'Please select an order status.',
                                )}
                            </div>
                        )}
                        {type === 'auxStatus' && selectedAuxStatus && (
                            <div className="message">
                                {selectedAuxStatus.description}
                            </div>
                        )}
                    </div>
                )}
                {(_shouldMakeGreeting && !didClosedGreeting) && (
                    <div
                        className="greetings"
                    >
                        <div
                            className="success_icon"
                        >
                            <CheckSucceedIcon />
                        </div>
                        <div
                            className="message"
                        >
                            <p>
                                {t(
                                    'С возвращением, {{operatorName}}!',
                                    'Welcome back, {{operatorName}}!',
                                    { operatorName: username },
                                )}
                            </p>
                            {t(
                                'Рады видеть Вас снова. Хорошего дня!',
                                'Great to have you back, have a good day!',
                            )}
                        </div>

                        <div
                            className="close_greeting_btn"
                            onClick={onCloseGreeting}
                        >
                            <CloseIconSvg />
                        </div>
                    </div>
                )}
                {!!notificationWithoutFeedbackToView && (
                    <div className="notification_wrapper">
                        {/* eslint-disable-next-line react/no-danger */}
                        <DivWithDangerouslySetInnerHTML className="message" html={notificationWithoutFeedbackToView.message} />
                        <div className="feedback-action">
                            {notificationWithoutFeedbackToView.isFeedback && (
                                <textarea
                                    disabled={pending}
                                    value={response}
                                    onChange={onResponseChange}
                                    placeholder={t('Введите комментарий', 'Enter commentary')}
                                />
                            )}
                            <Button
                                className="button"
                                disabled={pending || (notificationWithoutFeedbackToView.isFeedback && !response.length)}
                                variant="7"
                                onClick={onFeedBack}
                                size="1"
                                text={t('Отправить', 'Send')}
                                width="100%"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(Notifications);
