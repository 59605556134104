import {
    Instance, types as t, SnapshotOut, SnapshotIn, getEnv,
} from 'mobx-state-tree';

import { IEnv } from '@store/store';
import ValidateService from '@core/services/ValidateService';
import { MAX_INT_32_BIT } from '@core/constants/limits';


export const CustomerServiceStore = t
    .model('CustomerServiceState', {
        customerOrderId: t.string,
        customerName: t.string,
        customerPhone: t.string,
        depth: t.number,
        limit: t.number,
        isLoading: t.boolean,
        sipHost: t.maybeNull(t.string),
        shouldMadeAutoSearch: t.optional(t.boolean, false),
        selectedPhoneToCall: t.maybeNull(t.string),
    })
    .views((self) => ({
        get validateService(): ValidateService {
            const { validateService } = getEnv<IEnv>(self);

            return validateService;
        },
    }))
    .actions((self) => ({
        setCustomerOrderId(value: string): void {
            if (!value.length) {
                self.customerOrderId = '';

                return;
            }

            if (self.validateService.isWithoutLeadingZero(value) && Number(value) < MAX_INT_32_BIT) {
                self.customerOrderId = value;
            }
        },

        setCustomerPhone(value: string): void {
            if (!value.length) {
                self.customerPhone = '';

                return;
            }

            if (self.validateService.isFiguresValid(value)) {
                self.customerPhone = value;
            }
        },

        setCustomerName(value: string): void {
            self.customerName = value;
        },

        setIsLoading(value: boolean): void {
            self.isLoading = value;
        },

        clearSearchForm(): void {
            self.customerOrderId = '';
            self.customerName = '';
            self.customerPhone = '';
        },

        setSipHost(value: string | null): void {
            self.sipHost = value;
        },

        setShouldMadeAutoSearch(value: boolean): void {
            self.shouldMadeAutoSearch = value;
        },

        setSelectedPhoneToCall(value: string | null): void {
            self.selectedPhoneToCall = value;
        },
    }));


export interface ICustomerServiceStoreModel extends Instance<typeof CustomerServiceStore> {}
export interface ICustomerServiceStoreSnapshotIn extends SnapshotIn<typeof CustomerServiceStore> {}
export interface ICustomerServiceStoreSnapshotOut extends SnapshotOut<typeof CustomerServiceStore> {}
