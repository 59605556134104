import React, {
    FunctionComponent, useEffect, useRef, useState,
} from 'react';
import useOnClickOutside from 'use-onclickoutside';
import NavigationSvg from '@components/resources/SVG/Navigation/Navigation';
import cn from 'classnames';
import { Transition } from 'react-transition-group';

import './NomenclatureNavigationBar.scss';


type TNomenclatureNavigationBarProps = {
    readonly isCancel?: boolean;
    readonly isSwap?: boolean;
    readonly isDelete?: boolean;
    readonly rollUpEvent?: boolean;
    readonly text?: {
        readonly cancel?: string;
        readonly swap?: string;
        readonly delete?: string;
    };
    onChange(type: string): void;
};


const NomenclatureNavigationBar: FunctionComponent<TNomenclatureNavigationBarProps> = ({
    isCancel,
    isSwap,
    isDelete,
    onChange,
    text,
    rollUpEvent,
}) => {
    const [isProductNavigationBar, setIsProductNavigationBar] = useState<boolean>(false);

    const productNavBarRef = useRef(null);

    useOnClickOutside(
        productNavBarRef,
        () => setIsProductNavigationBar(false),
    );

    useEffect(
        () => {
            if (rollUpEvent) {
                setIsProductNavigationBar(false);
            }
        },
        [rollUpEvent],
    );

    return (
        <>
            <div
                className="Nav__chip"
                onClick={(): void => setIsProductNavigationBar((prevState) => !prevState)}
                data-cy="toggle_row_nav_panel"
            >
                <NavigationSvg
                    className={cn(isProductNavigationBar && 'activeChip')}
                />
            </div>
            <Transition
                in={isProductNavigationBar}
                timeout={100}
            >
                {(state) => (
                    <div
                        ref={productNavBarRef}
                        className={cn('ProductNavBar', state)}
                    >
                        {isCancel && (
                            <span
                                className="item__bar noBorderRadiusBottom"
                                onClick={() => onChange('cancel')}
                                data-cy="cancel_promo"
                            >
                                {text?.cancel || 'Cancel'}
                            </span>
                        )}
                        {isSwap && (
                            <span
                                className="item__bar noBorderRadiusBottom"
                                onClick={() => onChange('swap')}
                                data-cy="navBar_swap"
                            >
                                {text?.swap || 'Swap'}
                            </span>
                        )}
                        {isDelete && (
                            <span
                                className={cn(
                                    'item__bar',
                                    (isSwap || isCancel) && 'noBorderRadiusTop',
                                )}
                                onClick={() => onChange('delete')}
                                data-cy="preliminary_deletion_of_nomenclature"
                            >
                                {text?.delete || 'Delete'}
                            </span>
                        )}
                    </div>
                )}
            </Transition>
        </>
    );
};


export default NomenclatureNavigationBar;
