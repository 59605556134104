import React from 'react';
import cn from 'classnames';


interface IMicOffSvg {
    className?: string;
}

const MicOffSvg = ({ className, ...props }: IMicOffSvg): JSX.Element => (
    <svg
        className={cn(['micOffSvg', className])}
        {...props}
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.1772 10.0348V3.50053C14.1772 1.56357 12.537 0 10.5051 0C8.62014 0 7.09012 1.35354 6.88203 3.09213L14.1772 10.0348ZM17.9471 10.5016C17.4453 10.5016 17.0047 10.8516 16.9312 11.33C16.87 11.7034 16.7843 12.0768 16.6619 12.4152L18.2164 13.8971C18.5836 13.197 18.8529 12.4385 18.9876 11.6334C19.0732 11.0383 18.5836 10.5016 17.9471 10.5016ZM0.358025 1.82027C-0.119342 2.27534 -0.119342 3.01045 0.358025 3.46552L6.83307 9.64979V10.1515C6.83307 11.5401 7.56748 12.8586 8.82822 13.547C9.74623 14.0488 10.5541 14.0604 11.3007 13.9088L13.3326 15.8457C12.4635 16.2308 11.4966 16.4525 10.5051 16.4525C7.39612 16.4525 4.53192 14.3872 4.07904 11.33C4.00559 10.8516 3.56495 10.5016 3.0631 10.5016C2.42661 10.5016 1.93701 11.0383 2.02269 11.6334C2.58574 15.0873 5.64578 17.8177 9.28111 18.3428V21.0032C9.28111 21.6449 9.83191 22.17 10.5051 22.17C11.1783 22.17 11.7291 21.6449 11.7291 21.0032V18.3428C12.843 18.1911 13.8956 17.8177 14.8504 17.2926L19.1222 21.3649C19.5996 21.8199 20.3707 21.8199 20.8481 21.3649C21.3254 20.9098 21.3254 20.1747 20.8481 19.7196L2.08389 1.82027C1.60652 1.36521 0.835391 1.36521 0.358025 1.82027Z"
            fill="#505050"
        />
    </svg>
);

export default MicOffSvg;
