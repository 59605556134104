import * as React from 'react';
import IconCheck from '@images/Check.svg';
import cn from 'classnames';
import { FunctionComponent } from 'react';

interface IProductBenefitProps {
    className?: string;
    onClick?: () => void;
    state?: string;
    title?: string;
    text?: string;
}

const ProductBenefit: FunctionComponent<IProductBenefitProps> = ({
    className = '',
    onClick,
    state,
    text,
    title,
}) => (
    <div
        className={cn(['product-benefit__wrapper', 'e-benefit', className])}
        onClick={onClick}
    >
        <input type="hidden" value={state} />
        <div className="icon">
            <img src={IconCheck} alt="Check" className="icon-svg" />
        </div>
        <div className="infoProduct__benefit">
            <div className="title">{title}</div>
            <div className="text">{text}</div>
        </div>
    </div>
);


export default ProductBenefit;
