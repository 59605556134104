import { toFactory } from 'react-ioc';
import RecallsService from '@services/RecallsService';
import { Store } from '@store/store';
import RecallsApiService from '../core/api/recalls-api-service';
import ModalService from '../core/services/ModalService';
import I18NService from '../services/I18NService';


const RecallsServiceDI = (): any => [
    RecallsService,
    toFactory([
        RecallsApiService,
        Store,
        ModalService,
        I18NService,
    ],
    (
        recallsApiService: RecallsApiService,
        store: Store,
        modalService: ModalService,
        I18NService: I18NService,
    ) => new RecallsService(
        recallsApiService,
        store,
        modalService,
        I18NService,
    )),
];


export default RecallsServiceDI;
