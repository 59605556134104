import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import './billing-list-stats.scss';
import I18NService from '@services/I18NService';
import { useInstances } from 'react-ioc';
import { BillingListStore } from '@store/billingListStore';
import { block } from 'bem-cn';

const BillingListStats: FunctionComponent = () => {

    const [
        billingListStore,
        { t },
    ] = useInstances(
        BillingListStore,
        I18NService,
    );

    const b = block('billing-list-stats');

    const { workedDays, approvedOrders, totalOrders } = billingListStore;

    const approvePDay = Math.floor(approvedOrders * 100 / totalOrders) || 0;

    return (
        <>
            <div className={b()}>
                <div id="totalApprove" className={b('item')}>
                    <div className={b('item-value')}>
                        {approvedOrders}
                    </div>
                    <div className={b('item-title')}>
                        {t('Всего одобренных', 'Total approved')}
                    </div>
                </div>
                <div id="approvePDay" className={b('item')}>
                    <div className={b('item-value-red')}>
                        {approvePDay}%
                    </div>
                    <div className={b('item-title')}>
                        {t('Одобренных в день', 'Approved on the day')}
                    </div>
                </div>
                <div id="totalOrders" className={b('item')}>
                    <div className={b('item-value')}>
                        {totalOrders}
                    </div>
                    <div className={b('item-title')}>
                        {t('Всего заказов', 'Total orders')}
                    </div>
                </div>
                <div id="daysWorked" className={b('item')}>
                    <div className={b('item-value')}>
                        {workedDays}
                    </div>
                    <div className={b('item-title')}>
                        {t('Дней отработано', 'Days worked')}
                    </div>
                </div>
            </div>

        </>
    );
};


export default observer(BillingListStats);
