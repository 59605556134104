import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OrderSource = t
    .model('OrderSource', {
        id: t.integer,
        name: t.maybeNull(t.string),
        isCardPaymentAvailable: t.maybeNull(t.boolean),
        code: t.maybeNull(t.number),
        isFixedDeliveryPrice: t.maybeNull(t.boolean),
        showQueueInOrder: t.optional(t.boolean, false),
        /**
        * Cкрывать дату заказа
        */
        hideOrderCreatedAt: t.optional(t.boolean, false),
    });


export interface IOrderSourceModel extends Instance<typeof OrderSource> {}
export interface IOrderSourceModelSnapshotIn extends SnapshotIn<typeof OrderSource> {}
export interface IOrderSourceModelSnapshotOut extends SnapshotOut<typeof OrderSource> {}
