import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import {
    OrderService, SipService, UserService,
} from '@services/index';
import UserApiService from '@api/user-api-service';
import CommonStorageEventSubscriptionService from '@services/subscription/CommonStorageEventSubscriptionService';


const CommonStorageEventSubscriptionServiceDI = (): any => [
    CommonStorageEventSubscriptionService,
    toFactory(
        [
            Store,
            OrderService,
            SipService,
            UserService,
            UserApiService,
        ],
        (
            store: Store,
            orderService: OrderService,
            sipService: SipService,
            userService: UserService,
            userApiService: UserApiService,
        ) => new CommonStorageEventSubscriptionService(
            store,
            orderService,
            sipService,
            userService,
            userApiService,
        ),
    ),
];


export default CommonStorageEventSubscriptionServiceDI;
