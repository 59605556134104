import React from 'react';
import cn from 'classnames';


interface IAddPhoneRoundIconSvgProps {
    className?: string;
}

const AddPhoneRoundIconSvg = ({ className, ...props }: IAddPhoneRoundIconSvgProps): JSX.Element => (
    <svg
        className={cn(['addPhoneRoundIconSvg', className])}
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="10" cy="10" fill="transparent" r="9.5" stroke="#d1d1d6" />
        <path d="m14.6668 10.6666h-4v4h-1.3333v-4h-4v-1.33335h4v-4h1.3333v4h4z" fill="#c7c7cc" />
    </svg>
);

export default AddPhoneRoundIconSvg;
