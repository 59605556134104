import moment, { months } from 'moment';
import 'moment/locale/ru';
import map from 'lodash/map';
import {
    currentMonth,
    endOfCurrentMonth,
    endOfPrevMonth,
    endOfPrevPrevMonth,
    getDaysBetweenDates, prevMonth, prevPrevMonth, startOfCurrentMonth,
    startOfPrevMonth,
    startOfPrevPrevMonth,
} from '@components/main/billing-list-page/billing-list-hooks/utils';

const useSwitchMonths = () => {

    const allDatesPrevPrevMonth = getDaysBetweenDates(startOfPrevPrevMonth, endOfPrevPrevMonth, 'YYYY-MM-DD');
    const allDatesPrevMonth = getDaysBetweenDates(startOfPrevMonth, endOfPrevMonth, 'YYYY-MM-DD');
    const allCurrentMonth = getDaysBetweenDates(startOfCurrentMonth, endOfCurrentMonth, 'YYYY-MM-DD');

    moment.locale('ru');

    const listMonths = map(months(), (month) => month.slice(0, 3));

    const monthsNames = [listMonths[+prevPrevMonth - 1], listMonths[+prevMonth - 1], listMonths[+currentMonth - 1]];

    const allDatesMonths = [ allDatesPrevPrevMonth, allDatesPrevMonth, allCurrentMonth ];

    return { monthsNames, allDatesMonths };

};

export default useSwitchMonths;
