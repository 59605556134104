import React, { FunctionComponent } from 'react';
import map from 'lodash/map';
import { observer } from 'mobx-react';

import { Scrollbars } from 'react-custom-scrollbars';
import DeletedPromotionsAndProducts, { IDeletedProductFromPromotion } from '@/app/order-prepare/DeletedPromotionsAndProducts';
import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';
import Button from '@UIElements/Button';
import AccordionItem from '@UIElements/Accordion/AccordionItem';
import Accordion from '@UIElements/Accordion/Accordion';
import ListOfRemotePromotion
    from '@components/main/order-page/modal-windows/deleted-promotions-and-products-info/list-of-remote-promotion/ListOfRemotePromotion';

import DeletedOptionalGiftsFromPromotionBuilder
    from '@components/main/order-page/modal-windows/deleted-promotions-and-products-info/deleted-optional-gifts-from-promotion-builder/DeletedOptionalGiftsFromPromotionBuilder';

import './includes/DeletedPromotionsAndProductsInfo.scss';


export interface IDeletedPromotionsAndProductsInfoProps extends IModalFunctionComponentPropsBase {
    model: DeletedPromotionsAndProducts;
}


const DeletedPromotionsAndProductsInfo: FunctionComponent<IDeletedPromotionsAndProductsInfoProps> = ({
    model: {
        namesOfDeletedPromotionBuildersAndOldPromotions,
        allDeletedProductsFromPromotion,
        disbandedOldPromotions,
        namesOfDisbandedOldPromotions,
    },
    success,
    t,
}) => {
    return (
        <div className="DeletedPromotionsAndProductsInfo">
            <div className="DeletedPromotionsAndProductsInfo__body">
                <Scrollbars
                    autoHeight={true}
                    autoHeightMax="68vh"
                >
                    {/* Удаленные товары для каждого конструктора акций */}
                    {allDeletedProductsFromPromotion.length > 0 && (
                        <div className="block">
                            {
                                map<IDeletedProductFromPromotion, JSX.Element>(
                                    allDeletedProductsFromPromotion,
                                    (item) => (
                                        <Accordion
                                            key={item.promotion.id}
                                        >
                                            <AccordionItem
                                                title={
                                                    t(
                                                        'Изменения в составе акций: {{promotionBuilderName}}',
                                                        'Changes in promotion: {{promotionBuilderName}}',
                                                        { promotionBuilderName: item.promotion.name },
                                                    )
                                                }
                                                payload={(
                                                    <DeletedOptionalGiftsFromPromotionBuilder
                                                        products={[...item.products.values()]}
                                                    />
                                                )}
                                                dataCy="toggle_deleted_products_from_promotion_builder"
                                            />
                                        </Accordion>
                                    ),
                                )
                            }
                        </div>
                    )}
                    {/* Удаленные КА и Старые акции */}
                    {namesOfDeletedPromotionBuildersAndOldPromotions.length > 0 && (
                        <div className="block">
                            <Accordion>
                                <AccordionItem
                                    title={t('Удаленные акции', 'Deleted promotions')}
                                    payload={(
                                        <ListOfRemotePromotion
                                            namesOfDeletedPromotionBuilders={namesOfDeletedPromotionBuildersAndOldPromotions}
                                            dataCy="deleted_promotion_builders_and_old_promotions"
                                        />
                                    )}
                                    dataCy="toggle_deleted_promotion_builders_and_old_promotions"
                                />
                            </Accordion>
                        </div>
                    )}
                    {/* Расформированные старые акции */}
                    {disbandedOldPromotions.size > 0 && (
                        <div className="block">
                            <Accordion>
                                <AccordionItem
                                    title={t('Расформированные акции', 'Disbanded promotions')}
                                    payload={(
                                        <ListOfRemotePromotion
                                            namesOfDeletedPromotionBuilders={namesOfDisbandedOldPromotions}
                                            dataCy="disbanded_old_promotions"
                                        />
                                    )}
                                    dataCy="toggle_disbanded_old_promotions"
                                />
                            </Accordion>
                        </div>
                    )}
                </Scrollbars>

            </div>
            <Button
                className="continueButton"
                dataCyValue="DeletedPromotionsAndProductsInfo__continueButton"
                variant="7"
                size="1"
                text={t('Продолжить', 'Continue')}
                onClick={success}
                padding="15px"
            />
        </div>
    );
};


export default observer(DeletedPromotionsAndProductsInfo);
