import moment from 'moment';
import { gray, lightblue, lightgray } from '@core/constants/colors';
import {
    startOfPrevPrevWeek,
    startOfPrevWeek,
    startOfCurrentWeek, endOfPrevPrevWeek, endOfPrevWeek, endOfCurrentWeek, getDaysBetweenDates,
} from '@components/main/billing-list-page/billing-list-hooks/utils';

export const useSwitchWeeks = (periodNumber: number) => {

    const beginDate = periodNumber === 0 ? startOfPrevPrevWeek : periodNumber === 1 ? startOfPrevWeek : startOfCurrentWeek;
    const endDate = periodNumber === 0 ? endOfPrevPrevWeek : periodNumber === 1 ? endOfPrevWeek : endOfCurrentWeek;

    const weekDates = getDaysBetweenDates(beginDate, endDate, 'DD.MM');

    const dates = [
        {
            begin: moment(startOfPrevPrevWeek).format('DD.MM'),
            end: moment(endOfPrevPrevWeek).format('DD.MM'),
            color: lightgray,
            period: 0,
        },
        {
            begin: moment(startOfPrevWeek).format('DD.MM'),
            end: moment(endOfPrevWeek).format('DD.MM'),
            color: gray,
            period: 1,
        },
        {
            begin: moment(startOfCurrentWeek).format('DD.MM'),
            end: moment(endOfCurrentWeek).format('DD.MM'),
            color: lightblue,
            period: 2,
        },
    ];

    return { weekDates, dates };

};