import React, {
    FC, useCallback, useState,
} from 'react';
import { observer } from 'mobx-react';
import ButtonWithSplit from '@UIElements/ButtonWithSplit';
import find from 'lodash/find';
import map from 'lodash/map';
import cn from 'classnames';
import { IChangeStatusButtonModel } from '@models/mobx-state-tree/changeStatusButtons.model';
import OrderService from '@services/order/OrderService';
import { IChangeStatusVirtualButtonModel } from '@models/mobx-state-tree/changeStatusVirtualButton.model';
import Button from '@UIElements/Button';
import { useInstances } from 'react-ioc';
import SipService from '@services/sip/SipService';
import SipServiceBase from '@services/sip/SipServiceBase';
import LocalStorageService from '@core/services/LocalStorageService';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import './ChangeOrderButtons.scss';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import CallAutomatizationHandler from '@/app/services/call-automatization/CallAutomatizationHandler';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';

interface IChangeOrderButtonsProps {
    onAnyStatusSelected?: () => void;
    showOnlyOneStatusButton?: boolean;
}

const ChangeOrderButtons: FC<IChangeOrderButtonsProps> = ({ onAnyStatusSelected = null, showOnlyOneStatusButton = false }) => {
    const [
        orderService,
        sipService,
        sipServiceBase,
        { currentOrder: {
            canChangeOrderStatus,
            isACreatedOrder,
            additionalParamsCurrentOrder: {
                activePromotionBuilderModelId,
            },
            startCallAutomatic,
            form : {
                changeStatusButtonsValues,
                fastChangeQueuesValues,
                disableStatusSubStatusPhoneButtons,
            },
            selectedStatus,
            postProcessing,
            blockButtonNoAnswer: {
                blockedNoAnswer,
            },
        },
        currentUser: {
            mode,
        },
        },
        { t },
        callAutomatization,
        localStorageService,
    ] = useInstances(
        OrderService,
        SipService,
        SipServiceBase,
        Store,
        I18NService,
        CallAutomatizationHandler,
        LocalStorageService,
    );
    const { callAutomatizationFinished } = callAutomatization;

    const changeQueueButtonProps = {
        className: 'button',
        /**
         * Очередь нельзя менять, если работает автонабор
         */
        disabled: startCallAutomatic && !callAutomatizationFinished,
        variant: '3',
        size: '1',
        text: t('Сменить очередь', 'Change queue'),
        width: '100%',
    };

    const {
        isCallingRightNow,
    } = sipService;

    const {
        preUpdateOrder,
    } = orderService;

    const canChangeStatus =  mode === UserModeEnum.PROGRESSIVE ? true : canChangeOrderStatus;

    const [isSavingOrder, setIsSavingOrder] = useState<boolean>(false);
    const isStateButtonEnable = canChangeStatus && !isCallingRightNow && !disableStatusSubStatusPhoneButtons
        && !(startCallAutomatic && !callAutomatizationFinished); // или если автонабор не завершил свою работу

    const onSelectQueue = useCallback((id: number | null) => {
        if (id === null) {
            return;
        }
        const selected = find(fastChangeQueuesValues, { id });
        if (!selected) {
            return;
        }

        if (onAnyStatusSelected) {
            onAnyStatusSelected();
        }

        void orderService.preFastChangeOrderQueue(id, selected.name);
    }, [fastChangeQueuesValues]);

    const onStatusSelected = async (id: number | null) => {
        if (!id) {
            return;
        }

        if (onAnyStatusSelected) {
            onAnyStatusSelected();
        }
        setIsSavingOrder(true);
        const result = await preUpdateOrder(id);

        if (result) {
            sipServiceBase.setRecallAvailability(false);
            localStorageService.removeItem('orderId');
            localStorageService.removeItem('taskIdProgressiveAutocall');
        }

        setIsSavingOrder(false);

    };

    const onStatusSelectedInButton = useCallback(
        (id: number | null) => () => onStatusSelected(id),
        [changeStatusButtonsValues, activePromotionBuilderModelId],
    );

    const statusButtonsValues = postProcessing && showOnlyOneStatusButton ?
        changeStatusButtonsValues.filter((button: IChangeStatusButtonModel) => button.id === selectedStatus) :
        changeStatusButtonsValues;

    return (
        <div className="buttons ChangeOrderButtons">
            {fastChangeQueuesValues.length > 0 && !isACreatedOrder && (
                <div className="change-queue-btn-wrapper">
                    <ButtonWithSplit
                        buttonProps={changeQueueButtonProps}
                        items={fastChangeQueuesValues}
                        onSelect={onSelectQueue}
                    />
                </div>
            )}
            {map(statusButtonsValues, (button: IChangeStatusButtonModel) => {
                if (button.id !== OrderStatusesEnum.APPROVE) {
                    if (button.virtualStatuses.length) {
                        let items: IChangeStatusVirtualButtonModel[] = button.virtualStatuses;

                        if (button.includeSelf) {
                            items = [...button.virtualStatusesValues, {
                                id: button.id,
                                name: button.name,
                            }];
                        }

                        return (
                            <ButtonWithSplit
                                key={String(button.id)}
                                buttonProps={{
                                    className: cn('button status', (!isStateButtonEnable || isSavingOrder) && 'disabled'),
                                    disabled: !isStateButtonEnable || isSavingOrder || button.id === OrderStatusesEnum.NO_ANSWER && blockedNoAnswer,
                                    variant: !isStateButtonEnable ? 'default' : String(button.id),
                                    size: showOnlyOneStatusButton ? '2' : '1',
                                    text: t(button.name),
                                    width: showOnlyOneStatusButton ? '100%' : '128px',
                                }}
                                items={items}
                                onSelect={onStatusSelected}
                            />
                        );
                    }

                    return (
                        <Button
                            key={String(button.id)}
                            className={cn('button status', (!isStateButtonEnable || isSavingOrder) && 'disabled')}
                            disabled={!isStateButtonEnable || isSavingOrder
                                || button.id === OrderStatusesEnum.RECALL && !sipServiceBase.recallAvailability
                                || button.id === OrderStatusesEnum.NO_ANSWER && blockedNoAnswer
                            }
                            variant={String(button.id)}
                            onClick={onStatusSelectedInButton(button.id)}
                            size={showOnlyOneStatusButton ? '2' : '1'}
                            text={t(button.name)}
                            width={showOnlyOneStatusButton ? '100%' : '128px'}
                        />
                    );
                }

                return null;
            })}
            {map(statusButtonsValues, (button) => {
                if (button.id === OrderStatusesEnum.APPROVE) {
                    if (button.virtualStatuses.length) {
                        let items: IChangeStatusVirtualButtonModel[] = button.virtualStatuses;

                        if (button.includeSelf) {
                            items = [...button.virtualStatusesValues, {
                                id: button.id,
                                name: button.name,
                            }];
                        }

                        return (
                            <ButtonWithSplit
                                key={button.id}
                                buttonProps={{
                                    className: cn('button status', (!isStateButtonEnable || isSavingOrder) && 'disabled'),
                                    disabled: !isStateButtonEnable || isSavingOrder,
                                    variant: button.id,
                                    size: '2',
                                    text: t(button.name),
                                    width: '100%',
                                }}
                                items={items}
                                onSelect={onStatusSelected}
                                fullWidth
                            />
                        );
                    }

                    return (
                        <div
                            className="approve-btn-wrapper"
                            key={button.id}
                            data-cy="approve_button"
                        >
                            <Button
                                className={cn('button status', (!isStateButtonEnable || isSavingOrder) && 'disabled')}
                                disabled={!isStateButtonEnable || isSavingOrder}
                                variant={button.id}
                                onClick={onStatusSelectedInButton(button.id)}
                                size="2"
                                text={t(button.name)}
                                width="100%"
                            />
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
};

export default observer(ChangeOrderButtons);
