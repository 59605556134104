import React, { FC } from 'react';
import cn from 'classnames';


interface IMenuIconSvgProps {
    className?: string;
}

const MenuIconSvg: FC<IMenuIconSvgProps> = ({ className }) => (
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(['MenuIconSvg', className])}
    >
        <path
            d="M19.2188 16.1484H7.03125C6.59973 16.1484 6.25 15.7987 6.25 15.3672C6.25 14.9357 6.59973 14.5859 7.03125 14.5859H19.2188C19.6503 14.5859 20 14.9357 20 15.3672C20 15.7987 19.6503 16.1484 19.2188 16.1484ZM3.125 16.9297C3.125 16.4982 2.77527 16.1484 2.34375 16.1484C1.91299 16.1484 1.5625 15.7979 1.5625 15.3672C1.5625 14.9364 1.91299 14.5859 2.34375 14.5859C2.77451 14.5859 3.125 14.9364 3.125 15.3672C3.125 15.7987 3.47473 16.1484 3.90625 16.1484C4.33777 16.1484 4.6875 15.7987 4.6875 15.3672C4.6875 14.0748 3.63617 13.0234 2.34375 13.0234C1.05133 13.0234 0 14.0748 0 15.3672C0 16.6596 1.05133 17.7109 2.34375 17.7109C2.77527 17.7109 3.125 17.3612 3.125 16.9297ZM20 8.84375C20 8.41223 19.6503 8.0625 19.2188 8.0625H7.03125C6.59973 8.0625 6.25 8.41223 6.25 8.84375C6.25 9.27527 6.59973 9.625 7.03125 9.625H19.2188C19.6503 9.625 20 9.27527 20 8.84375ZM3.125 10.4062C3.125 9.97473 2.77527 9.625 2.34375 9.625C1.91299 9.625 1.5625 9.27451 1.5625 8.84375C1.5625 8.41299 1.91299 8.0625 2.34375 8.0625C2.77451 8.0625 3.125 8.41299 3.125 8.84375C3.125 9.27527 3.47473 9.625 3.90625 9.625C4.33777 9.625 4.6875 9.27527 4.6875 8.84375C4.6875 7.55133 3.63617 6.5 2.34375 6.5C1.05133 6.5 0 7.55133 0 8.84375C0 10.1362 1.05133 11.1875 2.34375 11.1875C2.77527 11.1875 3.125 10.8378 3.125 10.4062ZM20 2.59375C20 2.16223 19.6503 1.8125 19.2188 1.8125H7.03125C6.59973 1.8125 6.25 2.16223 6.25 2.59375C6.25 3.02527 6.59973 3.375 7.03125 3.375H19.2188C19.6503 3.375 20 3.02527 20 2.59375ZM3.125 4.15625C3.125 3.72473 2.77527 3.375 2.34375 3.375C1.91299 3.375 1.5625 3.02451 1.5625 2.59375C1.5625 2.16299 1.91299 1.8125 2.34375 1.8125C2.77451 1.8125 3.125 2.16299 3.125 2.59375C3.125 3.02527 3.47473 3.375 3.90625 3.375C4.33777 3.375 4.6875 3.02527 4.6875 2.59375C4.6875 1.30133 3.63617 0.25 2.34375 0.25C1.05133 0.25 0 1.30133 0 2.59375C0 3.88617 1.05133 4.9375 2.34375 4.9375C2.77527 4.9375 3.125 4.58777 3.125 4.15625Z"
            fill="#C2C2C2"
        />
    </svg>
);

export default MenuIconSvg;
