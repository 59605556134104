import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router-dom';

import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { Locations } from '@core/models/locations';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '@components/main/bottom-mobile-menu/BottomMobileTabs';
import OrdersTable from '@components/main/customer-service-page/orders-table/OrdersTable';
import OrderSearch from '@components/main/customer-service-page/order-search/OrderSearch';

import './CustomerServicePage.scss';


const CustomerModePage: FunctionComponent = (): JSX.Element => {
    const [
        {
            currentUser,
        },
        { t },
    ] = useInstances(
        Store,
        I18NService,
    );

    const navigate = useNavigate();

    if (currentUser.mode !== UserModeEnum.CLIENT_SERVICE) {
        // Если пытаемся отрисовать компонент для пользователя не в режиме клиентского сервиса то шлем его лесом на корень.
        navigate(Locations.MAIN);
    }

    return (
        <div className="CustomerServicePage">
            <div className="CustomerServicePage__wrapper">
                <div className="CustomerServicePage__pageTitle">
                    {t('Заказы', 'Orders')}
                </div>
                <OrderSearch />
                <div className="CustomerServicePage__content">
                    <OrdersTable />
                </div>
            </div>
            <BottomMobileTabs page={BottomMobileTabsPageEnum.ORDER} />
        </div>
    );
};


export default observer(CustomerModePage);
