import React, {
    FunctionComponent,
    useEffect, useMemo,
    useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
} from 'swiper/core';

import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import { ICallHistoryModel } from '@models/mobx-state-tree/callHistory.model';

import { OrderStatusesEnum } from '@api/order-api-service/models';
import ArrowNavSvg from '@components/resources/SVG/ArrowNav/ArrowNav';
import Tip from './Tip';
import Slide from './Slide';
import { ISlide } from './Slide/model';
import { ITip } from './Tip/model';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './includes/CallHistory.scss';
import { CallHistoryForVerificator } from './CallHistoryForVerificator';
import Spinner from '@UIElements/Spinner';

SwiperCore.use([Navigation]);


export interface ICallHistoryWidgetProps {
    isOrderView?: {
        orderId: number;
    };
}


const CallHistoryWidget: FunctionComponent<ICallHistoryWidgetProps> = ({ isOrderView }): JSX.Element => {
    const [tips, setTips] = useState<ITip[]>([]);
    const [slides, setSlides] = useState<ISlide[]>([]);

    const [{ currentOrder, orderToView, currentUser }, { t }] = useInstances(Store, I18NService);

    const callHistoryList = useMemo<ICallHistoryModel[]>(
        () => {
            if (isOrderView) {
                const order = orderToView.find((order) => order.id === isOrderView.orderId);

                return order?.callHistoryValues || [];
            }

            return currentOrder.callHistoryValues;
        },
        [isOrderView, orderToView, currentOrder.callHistoryValues],
    );

    useEffect(() => {
        const tips = callHistoryList.map((x: ICallHistoryModel) => {
            let endStatusName: string | null = null;
            let endStatusId: OrderStatusesEnum | null = null;
            if (x.endStatus && Number(x.endStatus.id) !== OrderStatusesEnum.TECH) {
                endStatusName = x.endStatus.name;
            }

            if (!x.user && x.disposition?.toLowerCase() === 'failed' && x.predictiveTaskId) {
                endStatusId = OrderStatusesEnum.CRASH;
            }

            return ({
                id: x.id,
                updatedAt: x.updatedAt,
                userId: x.user ? x.user.id : '?',
                username: x.user ? x.user.username : '?',
                startStatusName: x.startStatus && x.startStatus.name,
                endStatusName,
                endStatusId,
            });
        });

        setTips(tips);

        const slides = callHistoryList.map((x: ICallHistoryModel) => {
            let endStatusId: OrderStatusesEnum | null = null;
            if (x.endStatus && Number(x.endStatus.id) !== OrderStatusesEnum.TECH) {
                endStatusId = Number(x.endStatus.id);
            }

            if (!x.user && x.disposition?.toLowerCase() === 'failed' && x.predictiveTaskId) {
                endStatusId = 11;
            }

            return ({
                id: x.id,
                createdAt: x.createdAt,
                startStatusId: x.startStatus && Number(x.startStatus.id),
                endStatusId,
                recordingFile: x.recordingFile,
                name: x?.user?.username,
            });
        });

        setSlides(slides);
    }, [callHistoryList]);

    const callHistoryContent = () => {

        if (currentUser.isVerificator && slides?.length) {
            return (
                <CallHistoryForVerificator
                    slides={slides}
                    t={t}
                />
            );
        }

        return (
            <>
                {
                    tips.map((item: ITip, idx: number): JSX.Element => (
                        <Tip key={`tip__${idx}`} tip={item} />
                    ))
                }
                <div className="swiper__navButtons">
                    <ArrowNavSvg
                        className="arrowNav_prev"
                        width={15}
                        height={15}
                    />
                    <ArrowNavSvg
                        className="arrowNav_next"
                        width={15}
                        height={15}
                    />
                </div>
                <Swiper
                    className="swiper__customWrapper"
                    grabCursor
                    navigation={{
                        nextEl: '.arrowNav_next',
                        prevEl: '.arrowNav_prev',
                    }}
                    speed={500}
                    slidesPerView="auto"
                >
                    {
                        slides.map((item: ISlide, idx: number): JSX.Element => (
                            <SwiperSlide
                                key={`slide__${idx}`}
                                className="swiper__customSlideWrapper"
                            >
                                <Slide
                                    slide={item}
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </>
        );
    };

    if (currentOrder?.additionalParamsCurrentOrder?.loadCallHistory) {
        return (
            <div className="callHistory__spinner">
                <Spinner className="preloader"/>
            </div>
        );
    }

    return (
        <div className="callHistory__wrapper">
            <div className="b-call-history">
                <div className="history-title">
                    {`${t('История звонков', 'History calls')} (${callHistoryList.length})`}
                </div>
                {callHistoryContent()}
            </div>
        </div>
    );
};


export default observer(CallHistoryWidget);
