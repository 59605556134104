import InternetSpeedMetricSubscriptionService from '@services/subscription/InternetSpeedMetricSubscriptionService';
import { toFactory } from 'react-ioc';
import { OrderService, SipService } from '@services/index';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import { Store } from '@store/store';
import LocalStorageService from '@core/services/LocalStorageService';


const InternetSpeedMetricSubscriptionServiceDI = (): any => [
    InternetSpeedMetricSubscriptionService,
    toFactory(
        [
            Store,
            OrderService,
            InternetSpeedMetricService,
            SipService,
            LocalStorageService,
        ],
        (
            store: Store,
            orderService: OrderService,
            internetSpeedMetricService: InternetSpeedMetricService,
            sipService: SipService,
            localStorageService: LocalStorageService,
        ) => new InternetSpeedMetricSubscriptionService(
            store,
            orderService,
            internetSpeedMetricService,
            sipService,
            localStorageService,
        ),
    ),
];


export default InternetSpeedMetricSubscriptionServiceDI;
