import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { block } from 'bem-cn';

import LocalStorageService from '@app/core/services/LocalStorageService';
import CookieService from '@core/services/CookiesService';

import { Locations } from '@core/models/locations';


import { useInstances } from 'react-ioc';
import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';

import './includes/NoInternetModal.scss';

const timeHoverModal = 3000;

const NoInternetModal: FC<IModalFunctionComponentPropsBase> = ({ t }) => {

    const [
        cookieService,
        localStorageService,
    ] = useInstances(
        CookieService,
        LocalStorageService,
    );

    const navigate = useNavigate();

    const b = block('no-internet-modal');

    const logOut = () => {
        navigate(Locations.LOGIN);
        cookieService.clearCallibriCookies();
        cookieService.logout();
        localStorageService.removeCallibriStorageItems();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            logOut();
        }, timeHoverModal);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className={b()}>
            <div className={b('title')}>
                {t('Плохое качество интеренет соединения...', 'Low internet connection...' )}
            </div>
            <div className={b('text')}>
                {t('Вы будете перенаправлены на страницу авторизации.', 'You will be redirected to the login page.' )}
            </div>
        </div>

    );
};

export default NoInternetModal;