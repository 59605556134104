import { toFactory } from 'react-ioc';

import AuthService from '@services/AuthService';
import { Store } from '@store/store';
import CookieService from '@core/services/CookiesService';
import UserService from '@services/UserService';
import AuthApiService from '@api/auth-api-service';


const AuthServiceDI = (): any => [
    AuthService,
    toFactory(
        [
            Store,
            CookieService,
            UserService,
            AuthApiService,
        ],
        (
            store,
            cookieService,
            userService,
            authApiService,
        ) => new AuthService(
            store,
            cookieService,
            userService,
            authApiService,
        ),
    ),
];


export default AuthServiceDI;
