import { toFactory } from 'react-ioc';

import OrderUpdateService from '@services/order/OrderUpdateService';
import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';
import I18NService from '@services/I18NService';
import ErrorService from '@core/services/ErrorService';
import { DeliveriesService } from '@services/index';


const OrderUpdateServiceDI = (): any => [
    OrderUpdateService,
    toFactory(
        [
            Store,
            OrderApiService,
            I18NService,
            ErrorService,
            DeliveriesService,
        ],
        (
            store: Store,
            orderApiService: OrderApiService,
            I18NService: I18NService,
            errorService: ErrorService,
            deliveriesService: DeliveriesService,
        ) => new OrderUpdateService(
            store,
            orderApiService,
            I18NService,
            errorService,
            deliveriesService,
        ),
    ),
];


export default OrderUpdateServiceDI;
