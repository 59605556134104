import React, { FunctionComponent } from 'react';

import ProblemBtnMobile
    from '@components/main/order-page/call-panel/call-widget/call-button-and-phone/includes/ProblemBtnMobile';
import ProblemBtnDesktop
    from '@components/main/order-page/call-panel/call-widget/call-button-and-phone/includes/ProblemBtnDesktop';
import { MatchMediaHOC } from 'react-match-media';


interface IIProblemBtnProps {
    isDisable: boolean;
}


const Small = MatchMediaHOC(ProblemBtnMobile, '(max-width: 480px)');
const Large = MatchMediaHOC(ProblemBtnDesktop, '(min-width: 481px)');

const ProblemBtn: FunctionComponent<IIProblemBtnProps> = ({ isDisable }): JSX.Element => (
    <>
        <Small isDisable={isDisable} />
        <Large isDisable={isDisable} />
    </>
);

export default ProblemBtn;
