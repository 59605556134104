import {
    applySnapshot,
    getEnv,
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';

import { CustomerServiceStore } from '@models/mobx-state-tree/customer-mode/customerService.model';
import {
    DEFAULT_CUSTOMER_SERVICE_CLIENT_CARD,
    DEFAULT_CUSTOMER_SERVICE_ORDER_FILTER,
    DEFAULT_CUSTOMER_SERVICE_STORE,
    DEFAULT_CLIENT_TABS,
    DEFAULT_CLIENT_SERVICE_CURRENT_CUSTOMER_TABS,
    DEFAULT_CUSTOMER_SERVICE_CREATE_APPEAL,
} from '@core/constants/defaultStoreItems';
import {
    CustomerServiceOrder,
    ICustomerServiceOrderModel,
} from '@models/mobx-state-tree/customer-mode/customerServiceOrder.model';
import {
    ICustomerOrdersTableData,
    ICustomerOrderMainProduct,
} from '@components/main/customer-service-page-stage-2/customer-order-table/CustomerOrderTable';
import { customI18NTFunction } from '@services/I18NService';
import {
    CustomerServiceFAQArticleStage2,
    ICustomerServiceFAQArticleStage2Model,
} from '@models/mobx-state-tree/customerServiceFAQArticleStage2.model';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { ICustomerServiceProductModel } from '@models/mobx-state-tree/customerServiceProductModel.model';
import { IEnvCustomerStore } from '@store/customerModeStore';
import { ISmsFromErpModel } from '@models/mobx-state-tree/customer-mode/SmsFromErp.model';
import {
    CustomerServiceClientCard,
    ICustomerServiceClientCardSnapshotIn,
} from '@models/mobx-state-tree/customer-mode/customerServiceClientCard.model';
import { CustomerServiceOrderStage2, ICustomerServiceOrderStage2Model } from '../customerServiceOrderStage2.model';
import { OrderSearchFilter } from './orderSearchFilter.model';
import { ProductItemTypeEnum, ProductItemSubTypeEnum } from '../newModels/ProductItem.model';
import { OrderInformationTabs } from './orderInformationTabs.model';
import { CurrentCustomerTabs } from './currentCustomerTabs.model';
import { CreateAppeal } from './createAppeal.model';


export const CustomerModeModel = t
    .model('CustomerModeModel', {
        store: t.optional(CustomerServiceStore, DEFAULT_CUSTOMER_SERVICE_STORE),
        customerServiceOrders: t.optional(t.array(CustomerServiceOrder), []),
        clientCard: t.optional(CustomerServiceClientCard, DEFAULT_CUSTOMER_SERVICE_CLIENT_CARD), // STAGE 2
        shouldCreateClientCard: t.optional(t.boolean, false), // если true, то показываем оператору форму создания клиентской карточки STAGE 2
        isErrorWhileCreatingClientCard: t.optional(t.boolean, false), // если получили ошибку при создании клиентской карточки, то ставим флаг
        errorMessageWhileCreatingClientCard: t.optional(t.string, ''), // если получили ошибку при создании клиентской карточки, то сохраним её текст сюда
        orders: t.map(CustomerServiceOrderStage2), // STAGE 2
        orderInformationTabs: t.optional(OrderInformationTabs, DEFAULT_CLIENT_TABS), // табы в блоке с Заказами/Обращениями/СМС
        currentCustomerTabs: t.optional(CurrentCustomerTabs, DEFAULT_CLIENT_SERVICE_CURRENT_CUSTOMER_TABS),
        ordersFilter: t.optional(OrderSearchFilter, DEFAULT_CUSTOMER_SERVICE_ORDER_FILTER), // STAGE 2
        faqPending: t.optional(t.boolean, false), // STAGE 2
        faqArticles: t.optional(t.map(CustomerServiceFAQArticleStage2), {}), // STAGE 2
        appealCreationModel: t.optional(CreateAppeal, DEFAULT_CUSTOMER_SERVICE_CREATE_APPEAL), // Модель создания обращения
    })
    .views((self) => ({
        get ordersValues(): ICustomerServiceOrderStage2Model[] {
            return [...self.orders.values()];
        },

        get customerServiceOrderList() {
            return [...self.customerServiceOrders.values()];
        },

        get faqArticlesValues() {
            return [...self.faqArticles.values()];
        },

        get t(): customI18NTFunction {
            const { I18NService } = getEnv<IEnvCustomerStore>(self);

            return I18NService.t;
        },

        get rawOrdersLength(): number {
            return self.orders.size;
        },
    }))
    .views((self) => ({
        /**
         * Список заказов в формате интерфейса ICustomerOrdersTableData, необходимый для отрисовки в UI.
         * Дополнен атрибутами, по которым можно выполнять фильтрацию.
         */
        get customerServiceOrderListStage2(): ICustomerOrdersTableData[] {
            return map<ICustomerServiceOrderStage2Model, ICustomerOrdersTableData>(
                self.ordersValues,
                (order) => ({
                    date: order.createdAt || '—',
                    flag: order.countryFlag,
                    orderId: order.orderId,
                    orderTotalCost: String(order.final_price) || '—',
                    status: order.status,
                    erpStatusType: order.erpStatusType,
                    allPhones: order.allPhones,
                    customerFullName: order.customerFullName || '',
                    product: map<ICustomerServiceProductModel, ICustomerOrderMainProduct>(
                        order.allProducts,
                        (product) => ({
                            id: product.id,
                            name: product.name,
                            image: product.image,
                            type: product.type,
                            subType: product.subType,
                            isPromotion: product.isPromotion,
                            isGeneral: product.isGeneral,
                        }),
                    ),
                }),
            );
        },

        get textMessageHistory(): ISmsFromErpModel[] {
            return sortBy<ISmsFromErpModel>(
                reduce<ICustomerServiceOrderStage2Model, ISmsFromErpModel[]>(
                    self.ordersValues,
                    (acc, { smsListFromErp }) => (smsListFromErp?.length
                        ? concat<ISmsFromErpModel>(acc, smsListFromErp)
                        : acc),
                    [],
                ),
                ['createdAt'],
            );
        },

        /**
         * Подготовить переводы для лейблов товаров, в зависимости от типа товара
         */
        prepareLabelTranslation(productType: ProductItemTypeEnum, productSubType: ProductItemSubTypeEnum, isPromotion: boolean): string {
            if (isPromotion) {
                return self.t('Акция', 'Promotion');
            }

            if (productType === ProductItemTypeEnum.DYNAMIC_SET || productType === ProductItemTypeEnum.STATIC_SET) {
                return self.t('Набор', 'Set');
            }

            if (productSubType === ProductItemSubTypeEnum.MAIN) {
                return self.t('Продукт', 'Product');
            }

            if (productSubType === ProductItemSubTypeEnum.GIFT) {
                return self.t('Подарок', 'Gift');
            }

            if (productSubType === ProductItemSubTypeEnum.PROMO) {
                return self.t('Промо', 'Promo');
            }

            return '';
        },
    }))
    .views((self) => ({
        /**
         * Возвращает отфильтрованные заказы в зависимости от данных, которые были введены в ordersFilter модели
         */
        get filteredOrdersForAView(): ICustomerOrdersTableData[] {
            const {
                _clientName,
                _orderId,
                _phoneNumber,
                _selectedProduct,
            } = self.ordersFilter;

            const orders: ICustomerOrdersTableData[] = self.customerServiceOrderListStage2;

            const filteredArray = filter<ICustomerOrdersTableData>(
                orders,
                ({
                    allPhones,
                    customerFullName,
                    orderId,
                }) => includes<string>(allPhones, _phoneNumber)
                    && includes<string>(customerFullName.toLowerCase(), _clientName.toLowerCase())
                    && includes<string>(orderId, _orderId),
            );

            if (_selectedProduct) {
                const { name } = _selectedProduct;

                // если указан в фильтре товар, то добавляем фильтр по нему
                return filter<ICustomerOrdersTableData>(
                    filteredArray,
                    ({
                        product: orderProduct,
                    }) => some<ICustomerOrderMainProduct>(
                        orderProduct,
                        (product) => String(product.name) === String(name),
                    ),
                );
            }


            return filteredArray;
        },
    }))
    .actions((self) => ({
        resetAppealCreationModel(): void {
            applySnapshot(self.appealCreationModel, DEFAULT_CUSTOMER_SERVICE_CREATE_APPEAL);
        },
        createClientCard(clientCard: ICustomerServiceClientCardSnapshotIn): void {
            applySnapshot(self.clientCard, clientCard);
        },

        clearCustomerServiceOrderList(): void {
            applySnapshot(self.customerServiceOrders, []);
        },

        setCustomerServiceOrderList(value: ICustomerServiceOrderModel): void {
            self.customerServiceOrders.push(value);
        },

        clearOrdersFilterModel(): void {
            applySnapshot(self.ordersFilter, DEFAULT_CUSTOMER_SERVICE_ORDER_FILTER);
        },

        setFaqPending(value: boolean) {
            self.faqPending = value;
        },

        setFaqArticles(value: ICustomerServiceFAQArticleStage2Model[]) {
            applySnapshot(self.faqArticles, convertArrayToCollection(value));
        },

        clearFaqArticles() {
            applySnapshot(self.faqArticles, {});
        },

        fillCreateClientCardError(errorMessage: string) {
            self.isErrorWhileCreatingClientCard = true;
            self.errorMessageWhileCreatingClientCard = errorMessage;
        },

        resetCreateClientCardError() {
            self.isErrorWhileCreatingClientCard = false;
            self.errorMessageWhileCreatingClientCard = '';
        },

        setShouldCreateClientCard(value: boolean) {
            self.shouldCreateClientCard = value;
        },
    }));


export interface ICustomerModeModel extends Instance<typeof CustomerModeModel> {}
export interface ICustomerModeModelSnapshotIn extends SnapshotIn<typeof CustomerModeModel> {}
export interface ICustomerModeModelSnapshotOut extends SnapshotOut<typeof CustomerModeModel> {}
