import React, {
    FunctionComponent, MutableRefObject, useEffect, useRef, useState,
} from 'react';
import cn from 'classnames';

import './AutodialCounter.scss';


type TAutodialCounterProps = {
    readonly lastAttempt?: boolean;
    readonly value: number;
};


const AutodialCounter: FunctionComponent<TAutodialCounterProps> = ({
    value,
    lastAttempt,
}) => {
    const [curValue, setCurValue] = useState<number>(1);
    const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

    useEffect(
        () => {
            inputRef.current.style.transform = value > curValue ? 'translateY(-100%)' : 'translateY(100%)';
            inputRef.current.style.opacity = '0';

            setTimeout(() => {
                inputRef.current.style.transitionDuration = '0s';
                inputRef.current.style.transform = value > curValue ? 'translateY(100%)' : 'translateY(-100%)';
                inputRef.current.style.opacity = '0';

                setCurValue(
                    Number(value !== 100 ? value : 99),
                );

                setTimeout(() => {
                    inputRef.current.style.transitionDuration = '0.3s';
                    inputRef.current.style.transform = 'translateY(0)';
                    inputRef.current.style.opacity = '1';
                }, 20);
            }, 250);
        },
        [value],
    );

    return (
        <div
            className="AutodialCounter_main"
            data-cy="call_automatization_counter"
        >
            <div
                className={cn(
                    'AutodialCounter',
                    Boolean(lastAttempt) && 'lastAttempt',
                )}
            >
                <div
                    className={cn(
                        'AutodialCounter__wrapper',
                    )}
                >
                    <input
                        className="AutodialCounter__input"
                        maxLength={2}
                        ref={inputRef}
                        type="text"
                        value={curValue}
                        readOnly
                    />
                </div>
            </div>
        </div>
    );
};


export default AutodialCounter;
