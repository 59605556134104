import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { v4 } from 'uuid';


export const SmsFromErpModel = t
    .model('SmsFromErpModel', {
        id: t.integer,
        phone: t.string,
        text: t.string,
        error: t.string,
        status: t.string,
        template: t.string,
        createdAt: t.string,
    })
    .volatile(() => ({
        _identifyKey: v4(),
    }))
    .views((self) => ({
        get identifyKey(): string {
            return self._identifyKey;
        },
    }));


export interface ISmsFromErpModel extends Instance<typeof SmsFromErpModel> {}
export interface ISmsFromErpModelSnapshotIn extends SnapshotIn<typeof SmsFromErpModel> {}
export interface ISmsFromErpModelSnapshotOut extends SnapshotOut<typeof SmsFromErpModel> {}
