import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

export const ScriptBaseModel = t
    .model('ScriptBaseModel', {
        id: t.identifier,
        name: t.string,
        sort: t.maybeNull(t.number),
        key: t.number,
    });


export interface IScriptBaseCategoryModel extends Instance<typeof ScriptBaseModel> {}
export interface IScriptBaseCategoryModelSnapshotIn extends SnapshotIn<typeof ScriptBaseModel> {}
export interface IScriptBaseCategoryModelSnapshotOut extends SnapshotOut<typeof ScriptBaseModel> {}