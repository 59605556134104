import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import InputDummy from '@/app/ui-elements/InputDummy';
import Spinner from '@/app/ui-elements/Spinner';
import FlagIconCss from '@/plugins/FlagIconCss';
import I18NService from '@services/I18NService';
import some from 'lodash/some';
import { CustomerModeStore } from '@/app/store/customerModeStore';
import Button from '@/app/ui-elements/Button';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import StatusName
    from '@components/main/customer-service-page-stage-2/customer-order-table/helpers/includes/StatusName';
import { OrderStatusesTypeERPEnum } from '@api/order-api-service/models';

import GridTable from '@UIElements/GridTable';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import { v4 } from 'uuid';
import each from 'lodash/each';

import ProductImage from '../../order-page/order-products/order-table/includes/ProductImage';
import ProductFilterSelect from './helpers/includes/ProductFilterSelect';

import './CustomerOrderTable.scss';


export interface ICustomerOrdersTableData {
    date: string;
    flag: string | null;
    orderId: string;
    product: ICustomerOrderMainProduct[] | null;
    orderTotalCost: string;
    status: string;
    erpStatusType: OrderStatusesTypeERPEnum;
    // атрибуты для фильтрации:
    allPhones: string;
    customerFullName: string;
}

export interface ICustomerOrderMainProduct {
    id: string;
    name: string;
    image: string | null;
    isGeneral: boolean; // является ли главным товаром
    type: ProductItemTypeEnum;
    subType: ProductItemSubTypeEnum;
    isPromotion: boolean;
}

export interface WithPossibleProduct {
    product: null | ICustomerOrderMainProduct[];
    id: string;
    content: string;
}

export interface WithPossibleEPRStatus {
    erpStatusType: OrderStatusesTypeERPEnum;
    id: string;
    content: string;
}

type IColsNames = 'date' | 'country' | 'orderId' | 'mainItems' | 'price' | 'status';

const CustomerOrderTable: FunctionComponent = (): JSX.Element => {
    const [{ t }, customerMode] = useInstances(I18NService, CustomerModeStore);

    const {
        filteredOrdersForAView, ordersFilter, rawOrdersLength, prepareLabelTranslation, currentCustomerTabs, store,
    } = customerMode;

    const { isLoading: storeIsLoading } = store;

    const { addTab } = currentCustomerTabs;
    const {
        _phoneNumber, _clientName, _orderId, onChangeClientName, onChangeOrderId, onChangePhoneNumber, clearOrdersFilter,
    } = ordersFilter;

    const onOrderRowClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element = event.target as HTMLDivElement;
        if (element.classList.contains('grid_row')) {
            addTab(element.id);
            return;
        }

        let parent: HTMLElement | null | undefined = element.parentElement;

        while (!parent?.classList?.contains('grid_row')) {
            parent = parent?.parentElement;
        }

        addTab(parent.id);
    }, [filteredOrdersForAView]);

    const cols: IGridTableColumn[] = [
        {
            id: 'date',
            label: t('Дата', 'Date'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'country',
            label: t('Страна', 'Country'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '50px',
        },
        {
            id: 'orderId',
            label: t('Номер заказа', 'Order id'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '95px',
        },
        {
            id: 'mainItems',
            label: t('Главные товары', 'Main items'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '262px',
        },
        {
            id: 'price',
            label: t('Цена', 'Price'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'status',
            label: t('Статус', 'Status'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '73px',
        },
    ];

    let rows: WithRows<IColsNames, IBaseCellData | WithPossibleProduct | WithPossibleEPRStatus>[] = [];

    if (filteredOrdersForAView.length > 0) {
        rows = filteredOrdersForAView.map((item) => ({
            date: {
                id: v4(),
                content: item.date,
            },
            country: {
                id: v4(),
                content: item.flag || '',
            },
            orderId: {
                id: v4(),
                content: item.orderId,
            },
            mainItems: {
                id: v4(),
                product: item.product,
                content: '', // TODO: дефиниция требует content, а он тут не нужен.
            },
            price: {
                id: v4(),
                content: item.orderTotalCost,
            },
            status: {
                id: v4(),
                content: item.status,
                erpStatusType: item.erpStatusType,
            },
        }));
    }

    const RenderContentWithImage = (rowsData: WithRows<IColsNames, IBaseCellData | WithPossibleProduct | WithPossibleEPRStatus>[]) => {
        const data: JSX.Element[] = [];

        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            each(row, (value, key) => {
                if (key === 'orderId') {
                    rowId = value.content;

                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                data-cy="customer_order_table_row_orderId"
                                key={key}
                            >
                                {value.content}
                            </div>
                        ),
                    );
                } else if (key === 'status') {
                    const data = value as WithPossibleEPRStatus;

                    rowCell.push(
                        (
                            <div className="grid_cell grid_cell_height" key={key}>
                                <StatusName
                                    statusName={data.content}
                                    erpStatusType={data.erpStatusType}
                                />
                            </div>
                        ),
                    );
                } else if (key === 'country') {
                    rowCell.push(
                        (
                            <div className="grid_cell grid_cell_height" key={key}>
                                {value.content && (
                                    <FlagIconCss
                                        className="langIcon round"
                                        icon={value.content}
                                        squared={true}
                                    />
                                )}
                                {!value.content && (
                                    '—'
                                )}
                            </div>
                        ),
                    );
                } else if (key === 'mainItems') {
                    const data = value as WithPossibleProduct;

                    rowCell.push(
                        <div className="grid_cell grid_cell_height grid_cell--products" key={key}>
                            {data.product
                            && data.product?.length > 0
                            && data.product.map((generalProduct) => generalProduct.isGeneral && (
                                <div
                                    className="generalProductLine"
                                    key={generalProduct.id}
                                    title={generalProduct.name}
                                >
                                    <div
                                        className="product_image"
                                    >
                                        <ProductImage
                                            productName=""
                                            productType={generalProduct.type}
                                            imageLink={generalProduct.image}
                                        />
                                    </div>
                                    <div className="product_name">
                                        <ProductLabel
                                            productType={generalProduct.type}
                                            productSubType={generalProduct.subType}
                                            isPromotion={generalProduct.isPromotion}
                                            text={prepareLabelTranslation(
                                                generalProduct.type,
                                                generalProduct.subType,
                                                generalProduct.isPromotion,
                                            )}
                                        />
                                        {generalProduct.name}
                                    </div>
                                </div>
                            ))}
                            {!data.product
                            || (data.product && data.product.length === 0)
                            || (data.product && !some(data.product, (generalProduct) => generalProduct.isGeneral))
                            && (
                                <div
                                    className="generalProductLine"
                                >
                                    —
                                </div>
                            )}
                        </div>,
                    );
                } else {
                    rowCell.push(
                        (
                            <div
                                key={key}
                                className="grid_cell grid_cell_height"
                            >
                                <div
                                    className="rgt-text-truncate"
                                >
                                    {value.content}
                                </div>
                            </div>
                        ),
                    );
                }
            });

            data.push(
                (
                    <div
                        className="grid_row"
                        data-cy="customer_order-orders_table_row"
                        onClick={onOrderRowClick}
                        id={rowId}
                        key={rowId}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    return (
        <>
            <div className="customer_order_controls_wrapper">
                <div className="customer_order_block_controls">
                    <div className="customer_order_item_name">
                        <ProductFilterSelect />
                    </div>
                    <div className="customer_order_item_order_id">
                        <InputDummy
                            disabled={false}
                            inputId="Order_id"
                            type="text"
                            invalid={false}
                            validationMessage=""
                            label={t('Номер заказа', 'Order id')}
                            hasIcon={false}
                            value={_orderId}
                            onChange={onChangeOrderId}
                        />
                    </div>
                    <div className="customer_order_item_client_name">
                        <InputDummy
                            disabled={false}
                            inputId="Client_name"
                            type="text"
                            invalid={false}
                            validationMessage=""
                            label={t('Имя клиента', 'Client name')}
                            hasIcon={false}
                            value={_clientName}
                            onChange={onChangeClientName}
                        />
                    </div>
                    <div className="customer_order_item_phone_number">
                        <InputDummy
                            disabled={false}
                            inputId="Phone_number"
                            type="tel"
                            invalid={false}
                            validationMessage=""
                            label={t('Телефон', 'Telephone')}
                            hasIcon={false}
                            value={_phoneNumber}
                            onChange={onChangePhoneNumber}
                        />
                    </div>
                </div>
            </div>
            {storeIsLoading && (
                <div
                    className="result-alert"
                >
                    <Spinner
                        className={cn('e-button__Spinner')}
                        spinnerColor="#1665D8"
                    />
                </div>

            )}
            {filteredOrdersForAView && filteredOrdersForAView.length > 0 && !storeIsLoading && (
                <GridTable
                    className="customer_order-grid-orders-table"
                    columns={cols}
                    rows={rows}
                    rowsSize={rows.length}
                    cellsRenderer={RenderContentWithImage}
                    tableContentHeight={551}
                />
            )}
            {rawOrdersLength === 0 && !storeIsLoading && (
                <div
                    className="result-alert"
                >
                    <div className="message_header">
                        {t('Нет результатов', 'No results')}
                    </div>
                    <div className="message_content">
                        {t('По звонящему клиенту не найдено ни одного заказа', 'No order found for the calling customer')}
                    </div>
                </div>
            )}
            {filteredOrdersForAView
            && filteredOrdersForAView.length === 0
            && rawOrdersLength !== 0
            && !storeIsLoading && (
                <div
                    className="result-alert"
                >
                    <div className="message_header">{t('Нет результатов', 'No results')}</div>
                    <div className="message_content">
                        {t(
                            'Мы не смогли найти совпадения по вашему запросу. Измените или сбросьте текущий запрос',
                            'We could not find a match for your query. Change or reset the current query',
                        )}
                    </div>
                    <div className="message_button">
                        <Button
                            size="0"
                            variant={0}
                            width="100%"
                            text={t('Очистить', 'Clear')}
                            onClick={clearOrdersFilter}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(CustomerOrderTable);
