import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const ChangeStatusVirtualButton = t
    .model('ChangeStatusVirtualButton', {
        id: t.maybeNull(t.number),
        name: t.string,
    });


export interface IChangeStatusVirtualButtonModel extends Instance<typeof ChangeStatusVirtualButton> {}
export interface IChangeStatusVirtualButtonSnapshotIn extends SnapshotIn<typeof ChangeStatusVirtualButton> {}
export interface IChangeStatusVirtualButtonSnapshotOut extends SnapshotOut<typeof ChangeStatusVirtualButton> {}
