import React, { useState } from 'react';

import { ISlide } from '@components/main/order-page/call-history/CallHistoryWidget/Slide/model';
import moment from 'moment-timezone';
import { TFunction } from 'i18next';
import { v4 } from 'uuid';

import './includes/CallHistoryForVerificator.scss';
interface CallHistoryForVerificatorProps {
    slides: ISlide[];
    t: TFunction;
}

export const CallHistoryForVerificator = ({ slides, t }: CallHistoryForVerificatorProps) => {

    const [openHistoryCalls, setOpenHistoryCalls] = useState(false);
    const toggleOpenHistoryCalls = () => setOpenHistoryCalls(prev => !prev);

    const historySourceCalls = () => {
        return (
            <div className={openHistoryCalls ? 'callHistory__audiosource callHistory__audiosource_active' : 'callHistory__audiosource'}>
                {
                    slides.map((item: ISlide): JSX.Element => (
                        <div className="callHistory__row" key={v4()}>
                            {item?.recordingFile &&
                                <audio controls>
                                    <source src={item?.recordingFile}/>
                                </audio>}
                            {item?.name &&
                                <div className="date callHistory__date">{item?.name}</div>
                            }
                            {item?.createdAt && (
                                <div className="date callHistory__date">
                                    {moment(item?.createdAt * 1000).tz('UTC').format('DD.MM | HH:mm')}
                                </div>
                            )}
                        </div>
                    ))
                }
            </div>
        );
    };

    return (
        <div className="callHistory__dropdown">
            <p>{t('Откройте информацию по звонкам', 'Open call information')}</p>
            <input type={'checkbox'} onClick={toggleOpenHistoryCalls}/>
            {historySourceCalls()}
        </div>
    );
};
