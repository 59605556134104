import { computed } from 'mobx';

import {
    CALLIBRI,
    GREETING_FLAG,
    GREETING_TIMESTAMP,
    GREETING_USER_ID,
    INTERNET_SPEED_PROBLEMS,
    STORAGE_INACTIVITY_KEY,
    STORAGE_TRANSLATION_KEY,
} from '@core/constants/local';
import { ITranslationList } from '@api/translation-api-service/model';
import { TInternetSpeedProblems } from '@core/services/InternetSpeedMetricService';


export type TInactivityDataItem = {
    callDuration: number;
    viewTimePassed: number;
    modalTimePassed: number;
    saveTimePassed: number;
    orderId: number;
    userId: number;
    timestamp: number;
} | null;

export type TStorageInactivityItems = Record<string, TInactivityDataItem>;

export type TStorageLanguageData = {
    languageList: ITranslationList[];
    timestamp: number;
} | null;

export type TDataStorageTranslationItems = Record<string, TStorageLanguageData>;


class LocalStorageService {
    private static get _localStorage(): Storage {
        return window.localStorage;
    }

    private static _setItem(key: string, value: any): void {
        this._localStorage.setItem(
            key,
            JSON.stringify(value),
        );
    }

    private static _getItem(key: string): any {
        const item = this._localStorage.getItem(key);

        if (item) {
            return JSON.parse(item);
        }

        return null;
    }

    public setItem(key: string, value: any): void {
        LocalStorageService._setItem(key, value);
    }

    public getItem(key: string): any {
        return LocalStorageService._getItem(key);
    }

    public removeItem(key: string): void {
        LocalStorageService._removeItem(key);
    }

    private static _removeItem(key: string): any {
        this._localStorage.removeItem(key);
    }

    public set greetingFlag(value: string) {
        LocalStorageService._setItem(
            GREETING_FLAG,
            value,
        );
    }

    @computed
    public get greetingFlag(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return LocalStorageService._getItem(GREETING_FLAG);
    }

    public set greetingTimestamp(value: number) {
        LocalStorageService._setItem(
            GREETING_TIMESTAMP,
            value,
        );
    }

    @computed
    public get greetingTimestamp(): number {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return LocalStorageService._getItem(GREETING_TIMESTAMP);
    }

    public set greetingUserId(value: string) {
        LocalStorageService._setItem(
            GREETING_USER_ID,
            value,
        );
    }

    @computed
    public get greetingUserId(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return LocalStorageService._getItem(GREETING_USER_ID);
    }

    public set internetSpeedProblems(value: TInternetSpeedProblems) {
        LocalStorageService._setItem(
            INTERNET_SPEED_PROBLEMS,
            value,
        );
    }

    @computed
    public get internetSpeedProblems(): TInternetSpeedProblems {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return LocalStorageService._getItem(INTERNET_SPEED_PROBLEMS);
    }

    public set storageInactivity(value: TStorageInactivityItems) {
        LocalStorageService._setItem(
            STORAGE_INACTIVITY_KEY,
            value,
        );
    }

    @computed
    public get storageInactivity(): TStorageInactivityItems {
        const storage = LocalStorageService._getItem(STORAGE_INACTIVITY_KEY);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return storage || {};
    }

    public set storageTranslation(value: TDataStorageTranslationItems) {
        LocalStorageService._setItem(
            STORAGE_TRANSLATION_KEY,
            value,
        );
    }

    @computed
    public get storageTranslation(): TDataStorageTranslationItems {
        const storage = LocalStorageService._getItem(STORAGE_TRANSLATION_KEY);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return storage || {};
    }

    public removeCallibriStorageItems(): void {
        const items = CALLIBRI;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        items.forEach((item): string => LocalStorageService._removeItem(item));
    }
}




export default LocalStorageService;
