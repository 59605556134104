import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IRestoreIconSvg {
    className?: string;
}


const RestoreIcon: FunctionComponent<IRestoreIconSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['RestoreIconSvg', className])}
        {...props}
        width="21px"
        height="21px"
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Group"
                transform="translate(0.500000, 0.500000)"
                fillRule="nonzero"
            >
                <circle
                    id="Oval"
                    stroke="#C2C2C2"
                    fill="#F8F8F8"
                    cx="10"
                    cy="10"
                    r="10"
                />
                <path
                    d="M7.32352,13.16889 C7.22115,13.09929 7.12094,13.0202 7.02507,12.93408 C6.80271,12.73366 6.45984,12.75208 6.26023,12.97416 C6.06009,13.19651 6.07796,13.5388 6.30032,13.739 C6.43194,13.8576 6.57115,13.967 6.71388,14.0643 C6.80732,14.1279 6.91348,14.1585 7.0183,14.1585 C7.19163,14.1585 7.36198,14.0756 7.46652,13.9215 C7.63471,13.6743 7.57079,13.33735 7.32352,13.16889 Z"
                    id="Path"
                    fill="#8E8E93"
                />
                <path
                    d="M15.6875,9.30721 C15.6875,6.10515 13.12707,3.5 9.97972,3.5 C7.09724,3.5 4.66245,5.69131 4.316321,8.59735 C4.281112,8.89446 4.493175,9.16394 4.79028,9.19915 C5.08522,9.23544 5.35686,9.02256 5.39234,8.72519 C5.6732,6.36406 7.64567,4.58333 9.97972,4.58333 C12.52961,4.58333 14.6042,6.70233 14.6042,9.30721 C14.6042,11.50638 13.12328,13.354 11.12805,13.8775 L11.78265,12.94342 C11.95436,12.69831 11.89505,12.36058 11.64994,12.18888 C11.40538,12.0169 11.06711,12.07675 10.89567,12.32158 L9.53609,14.2616 C9.53528,14.2629 9.53474,14.2643 9.53392,14.2656 C9.52092,14.2843 9.5109,14.3052 9.50034,14.3255 C9.49465,14.3366 9.48707,14.3474 9.48219,14.3588 C9.4757,14.3742 9.4719,14.3907 9.46676,14.4067 C9.46107,14.4243 9.45403,14.4419 9.45024,14.4598 C9.4478,14.472 9.44753,14.4847 9.44563,14.4969 C9.44238,14.5199 9.43886,14.543 9.43859,14.566 C9.43859,14.5681 9.43805,14.5703 9.43805,14.5725 C9.43805,14.5852 9.44103,14.5969 9.44184,14.6093 C9.44319,14.6302 9.44428,14.6508 9.44807,14.6711 C9.45132,14.6881 9.45647,14.7044 9.46134,14.7209 C9.46622,14.738 9.47055,14.7548 9.47705,14.7713 C9.48355,14.7878 9.49195,14.803 9.50007,14.8187 C9.50792,14.8341 9.51551,14.8495 9.52472,14.8642 C9.53447,14.8793 9.54557,14.8929 9.55667,14.907 C9.56724,14.9202 9.57726,14.9338 9.5889,14.9462 C9.60299,14.9609 9.61842,14.9736 9.63413,14.9866 C9.64334,14.9944 9.6512,15.0034 9.66094,15.0107 L11.56409,16.3963 C11.66024,16.4661 11.77182,16.5 11.88232,16.5 C12.0497,16.5 12.2149,16.4228 12.3208,16.2771 C12.49657,16.0352 12.44349,15.6964 12.20163,15.5204 L11.38805,14.9281 C13.8553,14.2876 15.6875,12.01392 15.6875,9.30721 Z"
                    id="Path"
                    fill="#8E8E93"
                />
                <path
                    d="M5.82894,11.41526 C5.82678,11.40578 5.82271,11.39685 5.82028,11.38737 C5.81296,11.36028 5.80457,11.33347 5.79319,11.30828 C5.79184,11.3053 5.7913,11.30178 5.78994,11.2988 C5.72305,11.15743 5.67511,11.04151 5.63855,10.93426 C5.5424,10.65124 5.23474,10.49849 4.95144,10.59572 C4.66815,10.69186 4.51676,10.99953 4.6129,11.28282 C4.65976,11.42068 4.71799,11.56314 4.79463,11.72835 C4.79544,11.73024 4.79707,11.73187 4.79788,11.73403 C4.81549,11.77764 4.83932,11.81772 4.86721,11.85483 C4.87399,11.86376 4.8813,11.87189 4.88861,11.88056 C4.91624,11.91306 4.94711,11.94285 4.98178,11.96804 C4.98584,11.97101 4.98936,11.97481 4.99369,11.97778 C5.03215,12.00406 5.07467,12.02383 5.11936,12.03981 C5.13074,12.04387 5.14184,12.04712 5.15349,12.05037 C5.19953,12.06337 5.24719,12.07231 5.29757,12.07258 C5.29838,12.07258 5.29919,12.07285 5.30001,12.07285 C5.56163,12.07285 5.78019,11.88597 5.83057,11.63897 C5.83788,11.60376 5.84167,11.5672 5.84167,11.52983 C5.84167,11.49055 5.83707,11.45237 5.82894,11.41526 Z"
                    id="Path"
                    fill="#8E8E93"
                />
            </g>
        </g>
    </svg>
);


export default RestoreIcon;
