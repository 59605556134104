import React, { FunctionComponent, useCallback, useState } from 'react';
import cn from 'classnames';
import SmoothCollapse from 'react-smooth-collapse';

import { ICurrencyModel } from '@models/mobx-state-tree/currency.model';

import './CollapseSummary.scss';


interface ICollapsedSummaryProps {
    className?: string;
    title?: string;
    summaryBody: JSX.Element;
    sum: number;
    currency: ICurrencyModel | null;
}


const CollapseSummary: FunctionComponent<ICollapsedSummaryProps> = ({
    title,
    className,
    summaryBody,
    sum,
    currency,
}): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const onClick = useCallback(() => setIsCollapsed((prevState) => !prevState), [setIsCollapsed]);

    return (
        <div className="collapse-summary__wrapper">
            <div
                className={cn('e-summary', className, {
                    active: !isCollapsed,
                })}
            >
                <div
                    className="summary-head"
                    onClick={onClick}
                >
                    <div className="summary-head-icon" />
                    <div className="summary-head-name">{title}</div>
                    <div className="summary-head-value">
                        {sum}
                        {currency && ` ${currency.code}`}
                    </div>
                </div>
                <SmoothCollapse
                    className="smooth-collapse-summary"
                    expanded={!isCollapsed}
                    heightTransition="0.4s ease"
                >
                    {
                        summaryBody
                    }
                </SmoothCollapse>
            </div>
        </div>
    );
};


export default CollapseSummary;
