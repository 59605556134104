import { Store } from '@store/store';
import KladrApiService from '@api/kladr-api-service';
import { IKladrResultModelSnapshotIn } from '@models/mobx-state-tree/kladrResult.model';
import { AttrsKey } from '@interfaces/form.interface';


class KladrService {

    constructor(
        private readonly store: Store,
        private readonly kladrApiService: KladrApiService,

    ) {
    }

    async getDataAddressClassifierSearch(
        country: string,
        formAttributeName: string,
        withParents: 0 | 1,
        search: string,
        orderId: number,
        parentData?: string,
    ) : Promise<{ results: IKladrResultModelSnapshotIn[] } | never | void > {

        try {
            const response = formAttributeName === AttrsKey.CUSTOMER_ZIP ?

                await this.kladrApiService.getAddressClassifierSearchByZip(orderId, search)

                :

                await this.kladrApiService.getAddressClassifierSearch(
                    country,
                    formAttributeName,
                    withParents,
                    search,
                    parentData,
                );

            if (response?.data) {
                this.store.currentOrder.form.createKladrResultsForAllForms(response.data);
            }

            if (response?.results) {
                return {
                    results: response?.results,
                };
            }
        } catch (e) {
            console.log(e);
        }

    }

}

export default KladrService;