import {
    Instance, types as t, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';


export const Delivery = t
    .model('Delivery', {
        id: t.identifier,
        name: t.maybeNull(t.string),
        price: t.maybeNull(t.number),
        haveExpress: t.maybeNull(t.boolean),
        country: t.maybeNull(t.string),
        minDeliveryDate: t.maybeNull(t.string),
        maxDeliveryDate: t.maybeNull(t.string),
        partnerId: t.maybeNull(t.number),
        workDays: t.maybeNull(t.array(t.number)),
        limitExceededDays: t.maybeNull(t.array(t.string)),
    });


export interface IDeliveryModel extends Instance<typeof Delivery> {}
export interface IDeliveryModelSnapshotIn extends SnapshotIn<typeof Delivery> {}
export interface IDeliveryModelSnapshotOut extends SnapshotOut<typeof Delivery> {}
