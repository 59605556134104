import React, {
    FunctionComponent, useCallback, useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import OrderService from '@/app/services/order/OrderService';
import I18NService from '@services/I18NService';
import CounterInput from '@UIElements/CounterInput';
import Button from '@UIElements/Button';
import PriceEditInput from '@components/main/order-page/order-products/order-table-mobile/PriceEditInput';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import { ReplaceTransactionTypeEnum } from '@/app/models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import Counter from '@/app/ui-elements/Counter';

import ProductLabel from '@/app/ui-elements/ProductLabel';
import DropDownListWithContentsOfSet
    from '@components/main/order-page/order-products/order-table/includes/DropDownListWithContentsOfSet';
import DropDownListOfGiftsByAction
    from '@components/main/order-page/order-products/order-table/includes/DropDownListOfGiftsByAction';
import ProductRowNavPanel from '@components/main/order-page/order-products/order-table/includes/ProductRowNavPanel';
import OldPromotionService from '@services/order/OldPromotionService';
import BasketService from '@services/order/BasketService';
import ProductsService from '@services/order/ProductsService';
import OrderTableQuantityInStock from './OrderTableQuantityInStock';
import RemoveItemButton from './includes/RemoveItemButton';
import ProductImage from './includes/ProductImage';
import SwapItemButton from './includes/SwapItemButton';


interface IProductRowProps {
    readonly item: IBasketItemModel;
    readonly canEditProductPrice: boolean;
    readonly isShownForPreview?: boolean;
}


const OrderTableProductRow: FunctionComponent<IProductRowProps> = ({
    item,
    canEditProductPrice,
    isShownForPreview,
}) => {
    const [
        orderService,
        { t },
        oldPromotionService,
        basketService,
        productsService,
    ] = useInstances(
        OrderService,
        I18NService,
        OldPromotionService,
        BasketService,
        ProductsService,
    );
    const { allowGifts } = orderService.currentOrder.form;
    const { prepareLabelTranslation } = orderService.currentOrder;

    const isSomeOfMyChildrenItemsOutOfStock = basketService.isSomeOfMyChildrenItemsOutOfStock(item);

    const handleRemoveBasketItem = useCallback(
        () => {
            if (item.isPromoApplied) {
                oldPromotionService.removePromoGiftsFromBasket(item.id);
            }

            orderService.currentOrder._removeBasketItem(item.id);
        },
        [orderService.currentOrder],
    );

    const preparedTranslationForParentItem = useMemo<string>(
        () => prepareLabelTranslation(item.productItem.type, item.productItem.subType, item.isPromoApplied),
        [item.productItem.type, item.productItem.subType, item.isPromoApplied, t],
    );

    const showProductRow = useCallback(
        () => productsService.showProductRow(item),
        [item.removed],
    );

    return (
        <div data-cy="nomenclature_line">
            <div
                id={item.id}
                className="currentProducts__table-row"
                data-cy="main_nomenclature"
            >
                <div className="currentProducts__table-product--info">
                    {!item.removed && (item.productItem.type === ProductItemTypeEnum.DYNAMIC_SET || item.productItem.type === ProductItemTypeEnum.STATIC_SET) && (
                        <div className="highlight_collapsed" />
                    )}
                    {item.removed && (
                        <div className="blur">
                            <div className="button_wrapper">
                                <div className="removed_notification">
                                    {t('Вы удалили товар {{productName}} из корзины', 'You removed {{productName}} from basket', { productName: item.productItem.name })}
                                </div>
                                <Button
                                    variant="3"
                                    size="0"
                                    text={t('Вернуть', 'Return')}
                                    onClick={showProductRow}
                                    dataCyValue={`button_show_in_row_${item.productItem.productId}`}
                                    padding='16px'
                                />
                                {item.productItem.subType !== ProductItemSubTypeEnum.GIFT && (
                                    <div className="remove_fully" data-cy="remove_fully">
                                        <RemoveItemButton
                                            typeIcon="trash"
                                            onClick={handleRemoveBasketItem}
                                        />
                                    </div>
                                )}
                                {item.productItem.subType === ProductItemSubTypeEnum.GIFT && allowGifts && (
                                    <div className="remove_fully" data-cy="remove_fully">
                                        <RemoveItemButton
                                            typeIcon="trash"
                                            onClick={handleRemoveBasketItem}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
                    >
                        <div className="currentProducts__table-avatar">
                            <ProductImage
                                className="currentProducts__table-avatar-image"
                                productName={item.productItem.name}
                                imageLink={item.productItem.image}
                                productType={item.productItem.type}
                            />
                        </div>
                        <div
                            className="currentProducts__table-product--name"
                            title={item.productItem.name}
                            data-cy="product-name"
                        >
                            {item.productItem.name}
                        </div>
                        <OrderTableQuantityInStock
                            quantityInStock={item.productItem.quantityInStock}
                            item={item.productItem}
                            isSomeOfMyChildrenItemsOutOfStock={isSomeOfMyChildrenItemsOutOfStock && item.productItem.type === ProductItemTypeEnum.DYNAMIC_SET}
                        />
                        <span
                            className={cn(
                                'currentProducts__table-text-label--webkit',
                            )}
                        >
                            <ProductLabel
                                productType={item.productItem.type}
                                productSubType={item.productItem.subType}
                                isPromotion={item.isPromoApplied}
                                text={preparedTranslationForParentItem}
                            />
                        </span>
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-2"
                        data-cy="item_counter"
                    >
                        {(item.productItem.subType === ProductItemSubTypeEnum.PROMO || item.isPromoApplied
                        || (item.productItem.subType === ProductItemSubTypeEnum.GIFT && !allowGifts))
                        // если применена старая акция _OR_ это простое промо _OR_ (подарок _AND_ подарки запрещены)
                        && (
                            <Counter
                                value={String(item.quantity)}
                            />
                        )}
                        {(item.productItem.subType !== ProductItemSubTypeEnum.PROMO && !item.isPromoApplied
                        && !(item.productItem.subType === ProductItemSubTypeEnum.GIFT && !allowGifts))
                        // если не применена старая акция _AND_ это не простое промо _AND_ _NOT_(это подарок _AND_ подарки запрещены)
                        && (
                            <CounterInput
                                onAdd={item.addAmount}
                                onReduce={item.reduceAmount}
                                onInputChange={item.setAmount}
                                quantity={String(item.quantity)}
                                editable={item.editable}
                            />
                        )}
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                    >
                        {(!canEditProductPrice && item.productItem.subType !== ProductItemSubTypeEnum.GIFT || item.isPromoApplied) && (
                            <div className="currentProducts__table-text" data-cy="price-per-one">
                                {item.price}
                            </div>
                        )}
                        {(canEditProductPrice && item.productItem.subType !== ProductItemSubTypeEnum.GIFT && !item.isPromoApplied) && (
                            <div className="currentProducts__table-text" data-cy="price-input">
                                <PriceEditInput
                                    item={item}
                                    className="currentProducts__table-text__price-input"
                                />
                            </div>
                        )}
                        {item.productItem.subType === ProductItemSubTypeEnum.GIFT && (
                            <div className="currentProducts__table-text">{t('Бесплатно', 'Free')}</div>
                        )}
                    </div>
                    <div className="currentProducts__table-col currentProducts__table-col-1">
                        {item.productItem.subType !== ProductItemSubTypeEnum.GIFT && (
                            <div
                                data-cy="total-cost"
                                className="currentProducts__table-text currentProducts__table-text--bold"
                            >
                                {item.totalPrice}
                            </div>
                        )}
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2"
                    >
                        <div className="currentProducts__actions">
                            {(
                                item.productItem.type === ProductItemTypeEnum.DYNAMIC_SET
                                || item.productItem.type === ProductItemTypeEnum.STATIC_SET
                            )
                            && !isShownForPreview
                            && (
                                <SwapItemButton
                                    id={item.id}
                                    type={ReplaceTransactionTypeEnum.SET}
                                />
                            )}
                            {item.productItem.type === ProductItemTypeEnum.REGULAR
                            && item.productItem.subType === ProductItemSubTypeEnum.MAIN
                            && item.isPromoApplied
                            && oldPromotionService.canBeReplacedByAnotherPromotion(item)
                            && (
                                <SwapItemButton
                                    id={item.id}
                                    type={ReplaceTransactionTypeEnum.PROMOTIONS}
                                />
                            )}
                            {( item.editable
                            ) && (
                                <ProductRowNavPanel
                                    isShownForPreview={!!isShownForPreview}
                                    basket={item}
                                    handleRemoveBasketItem={handleRemoveBasketItem}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!item.removed
                && item.isPromoApplied
                && item.productItem.type === ProductItemTypeEnum.REGULAR
                && item.productItem.subType === ProductItemSubTypeEnum.MAIN
                && (
                    <DropDownListOfGiftsByAction
                        basketItem={item}
                    />
                )}
            {(!item.removed
                && (item.productItem.type === ProductItemTypeEnum.DYNAMIC_SET || item.productItem.type === ProductItemTypeEnum.STATIC_SET))
                && (
                    <DropDownListWithContentsOfSet
                        basketItem={item}
                        isShownForPreview={!!isShownForPreview}
                    />
                )}
        </div>
    );
};


export default observer(OrderTableProductRow);
