import I18NService from '@services/I18NService';
import { CustomerService } from '@/app/services';
import { CustomerModeStore } from '@store/customerModeStore';
import { ISelectValue } from '@UIElements/SelectDummy/SelectDummy';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';

export const useCreateAppeal = (): readonly [string, ISelectValue[], (() => void), ((id: string) => void), string, ((e: SyntheticEvent<HTMLTextAreaElement>) => void), boolean, string, ((e: SyntheticEvent<HTMLTextAreaElement>) => void), (() => any)] => {
    const [customerService, customerModeStore, { t }] = useInstances(CustomerService, CustomerModeStore, I18NService);
    const { appealCreationModel, clientCard } = customerModeStore;
    const { lastAppealId } = clientCard;
    const {
        subjectsForAView, modifyDefaultItemLabel, setSelectedSubject, setCustomSubject, selectedAppealSubjectModel, isAppealSuccessfulCreated,
    } = appealCreationModel;

    const [selectedSubject, setSelectedSubjectInternal] = useState<string>('');
    const [subjectList, setSubjectList] = useState<ISelectValue[]>([]);
    const [appealText, setAppealText] = useState<string>(''); // текст обращения храним только в хуке
    const [customSubject, setCustomSubjectInternal] = useState<string>('');
    const [isFormReadyToBeSent, setIsFormReadyToBeSent] = useState<boolean>(false);

    useEffect(() => {
        if (subjectsForAView.length) {
            setSubjectList(subjectsForAView);
        }
    }, [subjectsForAView]);

    useEffect(() => {
        if (!selectedAppealSubjectModel && isAppealSuccessfulCreated) {
            setSelectedSubjectInternal('');
            setAppealText('');
            if (customSubject.length) {
                setCustomSubject('');
                setCustomSubjectInternal('');
            }
        }
    }, [selectedAppealSubjectModel, isAppealSuccessfulCreated, customSubject]);

    useEffect(() => {
        if (subjectsForAView) { // Втыкаем перевод в опцию 'Другая тема обращения', если изменили язык UI
            modifyDefaultItemLabel(t('Другое', 'Other'));
        }
    }, [t, subjectsForAView]);

    const fetchSubjectList = useCallback(() => { // запрашиваем с бэка список тем обращений на языке UI пользователя
        void customerService.getSubjectListForAppeal();
    }, []);

    const onSelectSubject = useCallback((id: string) => {
        const subjectItem = subjectList.find((item) => item.id === id);

        if (subjectItem) {
            setSelectedSubjectInternal(subjectItem.label); // хук
            setSelectedSubject(subjectItem.id); // модель
        }
    }, [subjectList, subjectsForAView]);

    const onChangeAppealText = useCallback((e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        setAppealText(e.currentTarget.value);
        // TODO: debounce save comment appeal
    }, []);

    const onChangeCustomSubject = useCallback((e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        setCustomSubjectInternal(e.currentTarget.value); // хук
        setCustomSubject(e.currentTarget.value); // модель
    }, []);

    /**
     * Проверяем, можем ли отправить обращение на создание с финальным статусом
     */
    useEffect(() => {
        if (selectedAppealSubjectModel && selectedAppealSubjectModel.id === 'OTHER' && customSubject.length === 0) {
            setIsFormReadyToBeSent(false);
            return;
        }

        if (appealText.length > 0) {
            setIsFormReadyToBeSent(true);
        }
    }, [appealText, customSubject, selectedAppealSubjectModel]);

    const createAppeal = useCallback(() => {
        if (selectedAppealSubjectModel && selectedAppealSubjectModel.id === 'OTHER') {
            void customerService.createAppealInFinalState(lastAppealId, appealText, undefined, customSubject);
            return;
        }

        if (selectedAppealSubjectModel) {
            void customerService.createAppealInFinalState(lastAppealId, appealText, Number(selectedAppealSubjectModel.id));
        }
    }, [lastAppealId, selectedAppealSubjectModel, appealText, customSubject]);

    return [
        selectedSubject,
        subjectList,
        fetchSubjectList,
        onSelectSubject,
        appealText,
        onChangeAppealText,
        isFormReadyToBeSent,
        customSubject,
        onChangeCustomSubject,
        createAppeal,
    ] as const;
};
