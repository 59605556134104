import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import {
    IProductItemModelSnapshotIn,
    ProductItemSubTypeEnum,
    ProductItemTypeEnum,
} from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IBasketItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IPromotionBuilderSnapshotIn } from '@models/mobx-state-tree/newModels/PromotionBuilder.models';


export type IReturnPrepareCatalogAndBasketData = {
    catalog: Record<string, IProductItemModelSnapshotIn>;
    basket: Record<string, IBasketItemModelSnapshotIn>;
    /**
     * Доступные конструкторы акций
     */
    promotionBuilderCatalog: Record<string, IPromotionBuilderSnapshotIn>;
    /**
     * Корзина с примененным конструктором акций в заказе
     */
    promotionBuilderBasket: Record<string, IBasketItemModelSnapshotIn>;
    /**
     * ID активного конструктора акций в заказе
     */
    activePromotionBuilderModelId: string | null;
    /**
     * Наличие основного товара
     */
    mainProduct: IBasketItemModelSnapshotIn | null;
};

export type TCatalogIdsForPromoRequest = {
    id: string;
    productId: number;
};


class IntermediateStateCatalogsAndBaskets {
    private static _conversionToRecordFormat<T>(mapObject: Map<string, T>): Record<string, T> {
        let recordObject: Record<string, T> = {};

        for (const [key, value] of mapObject.entries()) {
            recordObject = {
                ...recordObject,
                [key]: value,
            };
        }

        return recordObject;
    }

    private static _findMainProduct(mapObject: Map<string, IBasketItemModelSnapshotIn>) {
        return [...mapObject.values()].find((item) => item.main && item.withLead) || null;
    }

    private readonly _catalog = new Map<string, IProductItemModelSnapshotIn>();

    private readonly _basket = new Map<string, IBasketItemModelSnapshotIn>();

    private readonly _promotionBuilderList = new Map<string, IPromotionBuilderSnapshotIn>();

    private readonly _promotionBuilderBasket = new Map<string, IBasketItemModelSnapshotIn>();

    private _activePromotionBuilderModelId: string | null = null;

    public get catalogValues(): IProductItemModelSnapshotIn[] {
        return [...this._catalog.values()];
    }

    public get basketValues(): IBasketItemModelSnapshotIn[] {
        return [...this._basket.values()];
    }

    public get promotionBuilderListValues(): IPromotionBuilderSnapshotIn[] {
        return [...this._promotionBuilderList.values()];
    }

    public get activePromotionBuilderModelId(): string {
        return this._activePromotionBuilderModelId || '';
    }

    public set activePromotionBuilderModelId(val: string) {
        this._activePromotionBuilderModelId = val;
    }

    public get mainItemsInCatalog(): IProductItemModelSnapshotIn[] {
        return filter<IProductItemModelSnapshotIn>(
            this.catalogValues,
            (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.MAIN
                && catalogItem.type === ProductItemTypeEnum.REGULAR,
        );
    }

    public get giftItemsInCatalog(): IProductItemModelSnapshotIn[] {
        return filter<IProductItemModelSnapshotIn>(
            this.catalogValues,
            (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.GIFT
                && catalogItem.type === ProductItemTypeEnum.REGULAR,
        );
    }

    public get promoItemsInCatalog(): IProductItemModelSnapshotIn[] {
        return filter<IProductItemModelSnapshotIn>(
            this.catalogValues,
            (catalogItem) => catalogItem.subType === ProductItemSubTypeEnum.PROMO
                && catalogItem.type === ProductItemTypeEnum.REGULAR,
        );
    }

    public get catalog(): Map<string, IProductItemModelSnapshotIn> {
        return this._catalog;
    }

    public get basket(): Map<string, IBasketItemModelSnapshotIn> {
        return this._basket;
    }

    public get promotionBuilderList(): Map<string, IPromotionBuilderSnapshotIn> {
        return this._promotionBuilderList;
    }

    public get promotionBuilderBasket(): Map<string, IBasketItemModelSnapshotIn> {
        return this._promotionBuilderBasket;
    }

    public get uniqIdsMainProduct(): number[] {
        const productIds: number[] = map<TCatalogIdsForPromoRequest, number>(
            this.catalogIdsForPromoRequest,
            ({ productId }) => productId,
        );

        if (productIds.length) {
            return uniq<number>(productIds);
        }

        return [];
    }

    public get catalogIdsForPromoRequest(): TCatalogIdsForPromoRequest[] {
        return map<IProductItemModelSnapshotIn, TCatalogIdsForPromoRequest>(
            this.mainItemsInCatalog,
            ({ id, productId }) => ({
                id,
                productId: Number(productId),
            }),
        );
    }

    public isThisGiftExistInCatalog(partnerProductId: string): boolean {
        return some<IProductItemModelSnapshotIn>(
            this.giftItemsInCatalog,
            {
                partnerProductId,
                type: ProductItemTypeEnum.REGULAR,
                subType: ProductItemSubTypeEnum.GIFT,
            },
        );
    }

    /**
     * Получить идентификатор модели ProductItemModel из catalog-а по partnerProductId, типу и подтипу товара.
     * Позволяет сделать линк на ProductItemModel при создании BasketItemModel.
     * @param partnerProductId - идентификатор партнерского товара из таблицы partner_product
     * @param productType - ProductItemTypeEnum
     * @param productSubType - ProductItemSubTypeEnum
     */
    public getProductItemModelFromCatalogByPartnerProductIdAndProductTypeAndProductSubType(
        partnerProductId: string,
        productType: ProductItemTypeEnum,
        productSubType: ProductItemSubTypeEnum,
    ): IProductItemModelSnapshotIn | undefined {
        return find<IProductItemModelSnapshotIn>(
            this.catalogValues,
            {
                partnerProductId,
                subType: productSubType,
                type: productType,
            },
        );
    }

    public getProductItemModelFromCatalogForPromotionBuilder(
        partnerProductId: string,
        productType: ProductItemTypeEnum,
        productSubType: ProductItemSubTypeEnum,
    ): IProductItemModelSnapshotIn | undefined {
        return find<IProductItemModelSnapshotIn>(
            this.catalogValues,
            {
                partnerProductId,
                subType: productSubType,
                type: productType,
                isFromPromotionBuilder: true,
            },
        );
    }

    public returnCatalogsAndBasketsToCurrentOrderModelFormat(): IReturnPrepareCatalogAndBasketData {
        return {
            catalog: IntermediateStateCatalogsAndBaskets._conversionToRecordFormat<IProductItemModelSnapshotIn>(this._catalog),
            basket: IntermediateStateCatalogsAndBaskets._conversionToRecordFormat<IBasketItemModelSnapshotIn>(this._basket),
            promotionBuilderCatalog: IntermediateStateCatalogsAndBaskets._conversionToRecordFormat<IPromotionBuilderSnapshotIn>(this._promotionBuilderList),
            promotionBuilderBasket: IntermediateStateCatalogsAndBaskets._conversionToRecordFormat<IBasketItemModelSnapshotIn>(this._promotionBuilderBasket),
            activePromotionBuilderModelId: this._activePromotionBuilderModelId,
            mainProduct: IntermediateStateCatalogsAndBaskets._findMainProduct(this._basket),
        };
    }

    public pushToCatalog = (catalogItem: IProductItemModelSnapshotIn): void => {
        this._catalog.set(catalogItem.id, catalogItem);
    };

    public deleteItemFromCatalogByKey = (key: string): void => {
        this._catalog.delete(key);
    };

    public pushToBasket = (basketItem: IBasketItemModelSnapshotIn): void => {
        this._basket.set(basketItem.id, basketItem);
    };

    public deleteItemFromBasketByKey = (key: string): void => {
        this._basket.delete(key);
    };

    public pushToPromotionBuilderList = (promotionBuilderItem: IPromotionBuilderSnapshotIn): void => {
        this._promotionBuilderList.set(promotionBuilderItem.id, promotionBuilderItem);
    };

    public deleteItemFromPromotionBuilderListByKey = (key: string): void => {
        this._promotionBuilderList.delete(key);
    };

    public pushToPromotionBuilderBasket = (promotionBuilderBasketItem: IBasketItemModelSnapshotIn): void => {
        this._promotionBuilderBasket.set(promotionBuilderBasketItem.id, promotionBuilderBasketItem);
    };

    public clearPromotionBuilderBasket = (): void => {
        this._promotionBuilderBasket.clear();
    };

    public clearActivePromotionBuilderModelId = (): void => {
        this._activePromotionBuilderModelId = null;
    };
}


export default IntermediateStateCatalogsAndBaskets;
