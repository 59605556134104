import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';

import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import CallHistoryWidget from '@components/main/order-page/call-history/CallHistoryWidget/CallHistoryWidget';
import FlagIcon from '@/plugins/FlagIcon';
import OrderFetchService from '@services/order/OrderFetchService';
import Spinner from '@UIElements/Spinner';
import ContentBlock from '@UIElements/ContentBlock';
import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';

import './OrderViewPage.scss';
import { IOrderViewModel } from '@models/mobx-state-tree/orderView.model';

interface IOrderViewComponentProps {
    orderIdToOpen: string;
}

const OrderViewComponent: FunctionComponent<IOrderViewComponentProps> = ({ orderIdToOpen }) => {
    const [
        {
            orderToViewForComponent,
        },
        orderFetchService,
        { t },
    ] = useInstances(
        Store,
        OrderFetchService,
        I18NService,
    );
    const [orderToDiplay, setOrderToDisplay] = useState<IOrderViewModel>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const isOrderExist = orderToViewForComponent().find((order) => String(order.id) === orderIdToOpen);
        if (!isOrderExist) {
            void orderFetchService.fetchOrderView(Number(orderIdToOpen));
            return;
        }

        setOrderToDisplay(isOrderExist);
        setLoading(false);
    }, [orderToViewForComponent().length, orderIdToOpen]);

    if (loading || !orderToDiplay) {
        return (
            <Spinner />
        );
    }

    const {
        id,
        customerFullName,
        countryName,
        countryCharCode,
        orderTypeName,
        createdAt,
        sourceName,
        customerAge,
        customerEmail,
        customerMobile,
        customerPhone,
        customerZip,
        customerCity,
        customerStreet,
        customerHouseNumber,
        customerDistrict,
        customerProvince,
        customerState,
        productsViews,
        deliveryFrom,
        deliveryTo,
        shippingName,
        shippingPrice,
        finalPrice,
    } = orderToDiplay;

    return (
        <div className="OrderViewComponent__wrapper">
            <div className="order-head">
                <div className="order-info">
                    <div className="order-tag">
                        {`${t('Тип', 'Type')}: ${orderTypeName ? t(orderTypeName, 'Type') : orderTypeName}`}
                    </div>
                    <div className="order-name">
                        {countryName && countryCharCode && (
                            <div className="order-flag" title={t(countryName)}>
                                <FlagIcon
                                    className="langIcon"
                                    code={countryCharCode.toLowerCase()}
                                    squared={true}
                                />
                            </div>
                        )}
                        <div className="name">
                            {`${t('Заказ', 'Order')} ${id}`}
                        </div>
                    </div>
                    <div className="order-date">
                        <div className="item">
                            {t('Дата создания', 'Date created')}
                            :
                            {' '}
                            <span className="font-weight-bold value">
                                {
                                    createdAt && moment(createdAt * 1000).format(
                                        'HH:mm | DD.MM.YYYY',
                                    )
                                }
                                {!createdAt && '?'}
                            </span>
                        </div>
                        <div className="item">
                            {t('Источник', 'Source')}
                            :
                            {' '}
                            {sourceName && (
                                <span>{sourceName}</span>
                            )}
                            {!sourceName && '?'}
                        </div>
                    </div>
                    <div className="order-total">
                        {`${t('Полная стоимость', 'Total cost')}:`}
                        {` ${finalPrice}`}
                    </div>
                </div>
                {id && (
                    <CallHistoryWidget isOrderView={{
                        orderId: id,
                    }}
                    />
                )}
            </div>
            <ContentBlock title={t('Информация о клиенте', 'Customer Info')}>
                <div className="CustomerServicePageInfo">
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Имя покупателя', 'Customer name')}</div>
                        <div className="CustomerServicePageInfo__value">{customerFullName}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Телефон', 'Phone')}</div>
                        <div className="CustomerServicePageInfo__value">{customerPhone}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Мобильный', 'Mobile')}</div>
                        <div className="CustomerServicePageInfo__value">{customerMobile}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Email', 'Email')}</div>
                        <div className="CustomerServicePageInfo__value">{customerEmail}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Возраст', 'Age')}</div>
                        <div className="CustomerServicePageInfo__value">{customerAge}</div>
                    </div>
                </div>
            </ContentBlock>
            <ContentBlock title={t('Информация о заказе', 'Order info')}>
                <div className="CustomerServicePageProducts">
                    <div className="CustomerServicePageProducts__head">
                        <div className="CustomerServicePageProducts__image">{t('Наименование', 'Item')}</div>
                        <div className="CustomerServicePageProducts__name" />
                        <div className="CustomerServicePageProducts__quantity">{t('Количество', 'Quantity')}</div>
                        <div className="CustomerServicePageProducts__price">{t('Цена', 'Price')}</div>
                        <div className="CustomerServicePageProducts__total">{t('Сумма', 'Cost')}</div>
                    </div>
                    <div
                        data-cy="orderView__cartsBody"
                        className="CustomerServicePageProducts__body"
                    >
                        {productsViews.map(({
                            name,
                            gift,
                            promo,
                            quantity,
                            price,
                            cost,
                            image,
                            type,
                            products,
                        }, index): JSX.Element => (
                            <div
                                data-cy="orderView__cartRow"
                                className="CustomerServicePageProducts__item"
                                key={index}
                            >
                                <div className="CustomerServicePageProducts__image">
                                    <ProductImage
                                        className="product-image"
                                        imageLink={image}
                                        productType={type}
                                        productName={name}
                                    />
                                </div>
                                <div className="CustomerServicePageProducts__details">
                                    <div className="CustomerServicePageProducts__row CustomerServicePageProducts__row--main">
                                        <div className="CustomerServicePageProducts__name">
                                            {name}
                                            {(promo || gift) && (
                                                <>
                                                    <br />
                                                    <small
                                                        className={cn(
                                                            'CustomerServicePageProducts__name--type',
                                                            { gift, promo },
                                                        )}
                                                    >
                                                        {promo && t('Промо', 'Promo')}
                                                        {gift && t('Подарок', 'Gift')}
                                                    </small>
                                                </>
                                            )}
                                        </div>
                                        <div className="CustomerServicePageProducts__quantity">
                                            <span>X</span>
                                            {quantity}
                                        </div>
                                    </div>
                                    <div className="CustomerServicePageProducts__row CustomerServicePageProducts__row--secondary">
                                        <div className="CustomerServicePageProducts__price">
                                            <span>
                                                {`${t('Одна штука', 'One item')}:`}
                                            </span>
                                            {price}
                                        </div>
                                        <div className="CustomerServicePageProducts__total">
                                            <span>
                                                {`${t('Всего', 'Total')}:`}
                                            </span>
                                            {cost}
                                        </div>
                                    </div>
                                </div>
                                {products.length > 0 && (
                                    <div
                                        data-cy="orderView__setInfoContainer"
                                        className="CustomerServicePageProducts__setInfo"
                                    >
                                        {products.map(({ name, quantity, id }) => (
                                            <div
                                                key={id}
                                                className="setItem"
                                            >
                                                <p
                                                    data-cy="orderView__setItem"
                                                >
                                                    {`${name} x ${quantity}`}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </ContentBlock>
            <ContentBlock title={t('Адрес', 'Address')}>
                <div className="CustomerServicePageInfo">
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Страна', 'Country')}</div>
                        <div className="CustomerServicePageInfo__value">{countryName}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Почтовый индекс', 'Postal code')}</div>
                        <div className="CustomerServicePageInfo__value">{customerZip}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Город', 'City')}</div>
                        <div className="CustomerServicePageInfo__value">{customerCity}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Улица', 'Street')}</div>
                        <div className="CustomerServicePageInfo__value">{customerStreet}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Номер дома', 'House number')}</div>
                        <div className="CustomerServicePageInfo__value">{customerHouseNumber}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Район', 'District')}</div>
                        <div className="CustomerServicePageInfo__value">{customerDistrict}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Область', 'Province')}</div>
                        <div className="CustomerServicePageInfo__value">{customerProvince}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Штат', 'State')}</div>
                        <div className="CustomerServicePageInfo__value">{customerState}</div>
                    </div>
                </div>
            </ContentBlock>
            <ContentBlock title={t('Доставка', 'Shipping')}>
                <div className="CustomerServicePageInfo">
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('С', 'From')}</div>
                        <div className="CustomerServicePageInfo__value">{deliveryFrom}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('До', 'To')}</div>
                        <div className="CustomerServicePageInfo__value">{deliveryTo}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Служба доставки', 'Shipping service')}</div>
                        <div className="CustomerServicePageInfo__value">{shippingName}</div>
                    </div>
                    <div className="CustomerServicePageInfo__item">
                        <div className="CustomerServicePageInfo__label">{t('Стоимость доставки', 'Shipping price')}</div>
                        <div className="CustomerServicePageInfo__value">{shippingPrice}</div>
                    </div>
                </div>
            </ContentBlock>
        </div>
    );
};

export default observer(OrderViewComponent);
