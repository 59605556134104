import React, { FunctionComponent } from 'react';


interface ICollapseSummaryItemProps {
    productName: string;
    quantity: number;
    isGift: boolean;
    total?: number;
}

const CollapseSummaryItem: FunctionComponent<ICollapseSummaryItemProps> = ({
    productName,
    quantity,
    isGift,
    total,
}) => (
    <div className="table-row">
        <div className="name">
            {productName}
        </div>
        <div className="count">
            {`x${quantity}`}
        </div>
        <div className="value">
            {isGift && 'Gift'}
            {!isGift && `${total}`}
        </div>
    </div>
);


export default CollapseSummaryItem;
