import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Transition } from 'react-transition-group';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import PopupWithoutAnimations from '@/app/ui-elements/Popup/PopupWithoutAnimations';
import GiftSVG from '@/app/components/resources/SVG/ProductLabels/GiftSVG';
import ProductSVG from '@/app/components/resources/SVG/ProductLabels/ProductSVG';
import Button from '@/app/ui-elements/Button';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import CoinsSVG from '@/app/components/resources/SVG/Coins/CoinsSVG';
import { useHover } from '@/utils/useHover';
import { IPromotionDataForComponent, ITranslationsForPromotionItem } from './helpers/models';
import './PromotionItemToAdd.scss';


interface IPromotionItemToAddProps {
    className?: string;
    isApplied: boolean; // применена ли акция
    promotionIsNotAvailable: boolean;
    promotionItem: IPromotionDataForComponent;
    translations: ITranslationsForPromotionItem;
    disabled?: boolean;
    onApplied(): void;
}


const PromotionItemToAdd: FunctionComponent<IPromotionItemToAddProps> = ({
    className,
    isApplied,
    promotionItem,
    translations,
    disabled = false,
    onApplied,
    promotionIsNotAvailable,
}) => {
    const [hoverRef, isHovered] = useHover();

    return (
        <div
            ref={hoverRef}
            className={cn([
                className,
                'PromotionToApply__wrapper',
                disabled && 'disabled',
                isHovered && 'hovered',
            ])}
            data-cy={`PromotionToApply__wrapper_${promotionItem.promotionId}`}
        >
            <div className="PromotionToApply__product_image__wrapper">
                {/* Картинка основного товара, к которому применили акцию */}
                <ProductImage
                    className="PromotionToApply__product_image"
                    productName={promotionItem.name}
                    imageLink={promotionItem.image}
                    productType={promotionItem.type}
                />
                <div className="PromotionToApply__product_gallery__wrapper">
                    {/* Галлерея картинок, которые идут в комплекте к акции. Показываем до 4 картинок */}
                    {promotionItem.items
                    && promotionItem.items.length <= 4
                    && promotionItem.items.map((item, index) => (
                        <ProductImage
                            className="PromotionToApply__product_image"
                            productName=""
                            imageLink={item.image}
                            productType={item.type}
                            key={`${index}-${item.name}`}
                        />
                    ))}
                    {promotionItem.items
                    && promotionItem.items.length > 4
                    && promotionItem.items
                        .filter((item, index: number) => index <= 2)
                        .map((item, index) => (
                            <ProductImage
                                className="PromotionToApply__product_image"
                                productName=""
                                imageLink={item.image}
                                productType={item.type}
                                key={`${index}-${item.name}`}
                            />
                        ))}
                    {promotionItem.items
                    && promotionItem.items.length > 4
                    && promotionItem.items[3] && (
                        <ProductImage
                            className="PromotionToApply__product_image"
                            productName=""
                            imageLink={promotionItem.items[3].image}
                            productType={promotionItem.items[3].type}
                            key={`${3}-${promotionItem.items[3].name}`}
                        >
                            <div className="blur">
                                <div className="remaining_number">{promotionItem.items.length - 3}</div>
                            </div>
                        </ProductImage>
                    )}
                </div>
            </div>
            <div className="PromotionToApply__product_name" title={promotionItem.promotionName}>
                {promotionItem.promotionName}
            </div>
            <div className="PromotionToApply__product_price_wrapper">
                <CoinsSVG />
                <div
                    className="product_price"
                    title={String(promotionItem.promotionPrice)}
                    data-cy="main_product_special_price"
                >
                    {promotionItem.promotionPrice}
                </div>
            </div>
            {isApplied && promotionIsNotAvailable && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={translations.button_there_is_no_possibility}
                    dataCyValue={`promotion_is_applied_${promotionItem.promotionId}`}
                />
            )}
            {!isApplied && (
                <Button
                    variant="3"
                    size="0"
                    text={translations.button_can_be_added}
                    width="128px"
                    onClick={onApplied}
                    dataCyValue={`promotion_not_applied_${promotionItem.promotionId}`}
                />
            )}
            {isApplied && !promotionIsNotAvailable && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={translations.button_yet_added}
                    dataCyValue={`promotion_is_applied_${promotionItem.promotionId}`}
                />
            )}
            <span className="PromotionToApply__product_type">
                <ProductLabel
                    productType={null}
                    productSubType={null}
                    isPromotion={true}
                    text={translations.label}
                />
            </span>
            <div className="PromotionToApply_curtain">
                <Transition
                    in={isHovered}
                    timeout={200}
                    mountOnEnter
                    unmountOnExit
                >
                    {(state): JSX.Element => (
                        <PopupWithoutAnimations
                            className="PromotionToApply__popup"
                            autoHeightMax={194}
                        >
                            <ul>
                                {/* MAIN товар, к которому применена акция: */}
                                <li
                                    className={state}
                                    key="main_item_to_which_promo_is_applied"
                                    style={{ transitionDelay: '0.01s' }}
                                >
                                    <div className="li_row">
                                        <div className="product_icon">
                                            <ProductSVG />
                                        </div>
                                        <div
                                            className="product_name_and_quantity"
                                            title={`${promotionItem.name} (${promotionItem.quantity})`}
                                        >
                                            {`${promotionItem.name} (${promotionItem.quantity})`}
                                        </div>
                                    </div>
                                    <div className="li_row">
                                        <div className="price_icon">
                                            <CoinsSVG />
                                        </div>
                                        <div
                                            className="product_price"
                                            title={String(promotionItem.price)}
                                        >
                                            {promotionItem.price}
                                        </div>
                                        {promotionItem.discount && (
                                            <div className="product_discount">
                                                {`-${promotionItem.discount}%`}
                                            </div>
                                        )}
                                    </div>
                                </li>
                                {/* Товары, которые входят в состав акции: */}
                                {promotionItem.items.map((item, index) => (
                                    <li
                                        className={state}
                                        key={item.name}
                                        // получаем для первого элемента 0.02s delay и далее +0.01s для каждого последующего
                                        style={{ transitionDelay: `${(2 + index) / 100}s` }}
                                    >
                                        {/* Пока что в promotion могут быть только gift-ы */}
                                        <div className="li_row">
                                            <div className="product_icon">
                                                <GiftSVG />
                                            </div>
                                            <div
                                                className="product_name_and_quantity"
                                                title={`${item.name} (${item.quantity})`}
                                            >
                                                {`${item.name} (${item.quantity})`}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </PopupWithoutAnimations>
                    )}
                </Transition>
            </div>
        </div>
    );
};


export default observer(PromotionItemToAdd);
