import React, { FunctionComponent, useEffect } from 'react';

import moment from 'moment';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import { LineChart, ResponsiveContainer, XAxis, Line, Tooltip } from 'recharts';

import { BillingListStore } from '@store/billingListStore';

import BillingListService from '@components/main/billing-list-page/BillingListService';

import BillingListTooltipContent
    from '@components/main/billing-list-page/billing-list-tooltip-content/billing-list-tooltip-content';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { gray, lightblue, lightgray } from '@core/constants/colors';

import './billing-list-months.scss';

interface IBillingListMonths {
    allDatesMonths: string[][];
    monthsNames: string[];
}

const BillingListMonths: FunctionComponent<IBillingListMonths> = (
    {
        allDatesMonths,
        monthsNames,
    }) => {

    const [
        billingListStore,
        {
            fetchOperatorStatisticList,
        },
    ] = useInstances(
        BillingListStore,
        BillingListService,
    );

    const dateFrom = moment().subtract(3, 'months').format('YYYY-MM-DD');

    useEffect(() => {
        void fetchOperatorStatisticList(dateFrom);
    }, []);

    const { months } = billingListStore;

    const getOrdersForMonth = (monthNumber: number) => map(allDatesMonths[monthNumber], (date) => {
        return filter(months[monthNumber], (el) => date === el.date)[0]?.approvedCount;
    });

    const ordersFirstMonth = getOrdersForMonth(0);
    const ordersSecondMonth = getOrdersForMonth(1);
    const ordersThirdMonth = getOrdersForMonth(2);

    const dataOrdersAndDateForChart = map(allDatesMonths[0], (date: string, index) => {
        return {
            date: moment(date).format('DD'),
            [monthsNames[0]]: ordersFirstMonth[index],
            [monthsNames[1]]: ordersSecondMonth[index],
            [monthsNames[2]]: ordersThirdMonth[index],
        };
    });

    return (
        <div className='billing-list-months'>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={dataOrdersAndDateForChart}
                >
                    <XAxis
                        dataKey="date"
                        style={{
                            fontSize: '10px',
                            lineHeight: '12px',
                        }}
                        tickLine={false}
                        height={12}
                        interval={6}
                    />
                    <Tooltip
                        content={<BillingListTooltipContent/>}
                        position={{ y: 90 }}
                        offset = {0}
                    />
                    <Line
                        type="monotone"
                        dataKey={monthsNames[0]}
                        stroke={lightgray}
                        dot={false}
                        strokeWidth={3}
                    />
                    <Line
                        type="monotone"
                        dataKey={monthsNames[1]}
                        stroke={gray}
                        dot={false}
                        strokeWidth={1}
                    />
                    <Line
                        type="monotone"
                        dataKey={monthsNames[2]}
                        stroke={lightblue}
                        dot={false}
                        strokeWidth={2}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default observer(BillingListMonths);
