import {
    types as t, Instance, SnapshotIn, SnapshotOut, getEnv,
} from 'mobx-state-tree';
import { IEnv } from '@store/store';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';

interface IVolatile {
    viewTimer: null | NodeJS.Timeout;
    viewTimerPassedTimer: null | NodeJS.Timeout;
    saveTimer: null | NodeJS.Timeout;
    saveTimerPassedTimer: null | NodeJS.Timeout;
    modalTimerPassedTimer: null | NodeJS.Timeout;
    modalTimer: null | NodeJS.Timeout;
    auxStatusTimerPassedTimer: null | NodeJS.Timeout;
    auxStatusTimer: null | NodeJS.Timeout;
}

export const OperatorInactivityNotificationTimersModel = t
    .model('OperatorInactivityNotification', {
        /**
         * same as order.id
         */
        orderId: t.number,
        /**
         * same as user.id
         */
        userId: t.number,

        /**
         * Лимит времени на просмотр заказа\
         * перед звонком
         */
        viewFormFillTimeLimit: t.maybeNull(t.number),
        /**
         * Время, прошедшее в режиме limit_time_view
         */
        viewFormFillTimePassed: t.optional(t.number, 0),
        viewFormFillNotificationVisible: t.optional(t.boolean, false),

        /**
         * Лимит времени на сохранение заказа
         */
        saveFormFillTimeLimit: t.maybeNull(t.number),
        /**
         * Время, прошедшее в режиме limit_time_save
         */
        saveFormFillTimePassed: t.optional(t.number, 0),
        saveFormFillNotificationVisible: t.optional(t.boolean, false),

        /**
         * Лимит времени на выставление статуса в
         * модальном окне
         */
        modalFormFillTimeLimit: t.maybeNull(t.number),
        /**
         * Время, прошедшее в режиме limit_time_modal
         */
        modalFormFillTimePassed: t.optional(t.number, 0),
        modalFormFillNotificationVisible: t.optional(t.boolean, false),

        auxStatusFillTimePassed: t.optional(t.number, 0),

        auxStatusForwardTimer: t.optional(t.boolean, false),

        /**
         * Время на сохранение заказа в режиме limit_time_save\
         * если звонок был короткий
         */
        saveFormFilterShortCall: t.optional(t.number, 60),
        /**
         * Время на сохранение заказа в режиме limit_time_save\
         * если звонок был длинный
         */
        saveFormFilterLongCall: t.optional(t.number, 480),
        /**
         * Продолжительность звонка, относительно которой\
         * определяем длинный или короткий был звонок
         */
        saveFormFillDurationCall: t.optional(t.number, 40),

        /**
         * Длительность последнего звонка
         */
        previousCallDuration: t.optional(t.number, 0),
        /**
         * Был ли последний звонок коротким?
         */
        wasLastCallShort: t.optional(t.boolean, false),
    })
    .volatile(() => <IVolatile> {
        viewTimer: null,
        viewTimerPassedTimer: null,
        saveTimer: null,
        saveTimerPassedTimer: null,
        modalTimer: null,
        modalTimerPassedTimer: null,
        auxStatusTimerPassedTimer: null,
    })
    .views((self) => ({
        get inactivityOperatorStorageService(): InactivityOperatorStorageService {
            const { inactivityOperatorStorageService } = getEnv<IEnv>(self);

            return inactivityOperatorStorageService;
        },
        get viewTimerActive(): boolean {
            return !!self.viewTimerPassedTimer;
        },
        get modalTimerActive(): boolean {
            return !!self.modalTimerPassedTimer;
        },
        get saveTimerActive(): boolean {
            return !!self.saveTimerPassedTimer;
        },
        get auxStatusTimerActive(): boolean {
            return !!self.auxStatusTimerPassedTimer;
        },
        get anyTimerActive(): boolean {
            return !!self.viewTimerPassedTimer || !!self.saveTimerPassedTimer;
        },
    }))
    .actions((self) => ({
        setWasLastCallShort: (value: boolean): void => {
            self.wasLastCallShort = value;
        },
        setViewFormFillNotificationVisible: (value: boolean): void => {
            self.viewFormFillNotificationVisible = value;
        },
        setSaveFormFillNotificationVisible: (value: boolean): void => {
            self.saveFormFillNotificationVisible = value;
        },
        setModalFormFillNotificationVisible: (value: boolean): void => {
            self.modalFormFillNotificationVisible = value;
        },
        clearViewTimer: (): void => {
            if (self.viewTimer) {
                clearTimeout(self.viewTimer);
                self.viewTimer = null;
            }
        },
        clearSaveTimer: (): void => {
            if (self.saveTimer) {
                clearTimeout(self.saveTimer);
                self.saveTimer = null;
            }
        },
        clearModalTimer: (): void => {
            if (self.modalTimer) {
                clearTimeout(self.modalTimer);
                self.modalTimer = null;
            }
        },
        clearAuxStatusTimer: (): void => {
            if (self.auxStatusTimer) {
                clearTimeout(self.auxStatusTimer);
                self.auxStatusTimer = null;
            }
        },
        clearViewTimePassedTimer: (): void => {
            if (self.viewTimerPassedTimer) {
                clearInterval(self.viewTimerPassedTimer);
                self.viewTimerPassedTimer = null;
            }
        },
        clearSaveTimePassedTimer: (): void => {
            if (self.saveTimerPassedTimer) {
                clearInterval(self.saveTimerPassedTimer);
                self.saveTimerPassedTimer = null;
            }
        },
        clearModalTimePassedTimer: (): void => {
            if (self.modalTimerPassedTimer) {
                clearInterval(self.modalTimerPassedTimer);
                self.modalTimerPassedTimer = null;
            }
        },
        clearAuxStatusTimePassedTimer: (): void => {
            if (self.auxStatusTimerPassedTimer) {
                clearInterval(self.auxStatusTimerPassedTimer);
                self.auxStatusTimerPassedTimer = null;
            }
        },
        setSaveFormFillTimeLimit: (saveFormFillTimeLimit: number ) => {
            self.saveFormFillTimeLimit = saveFormFillTimeLimit;
        },
        setModalFormFillTimeLimit: (modalFormFillTimeLimit: number ) => {
            self.modalFormFillTimeLimit = modalFormFillTimeLimit;
        },
    }))
    .actions((self) => ({
        clearOrderTimePassed: (): void => {
            self.inactivityOperatorStorageService.storeClearTimePassedForOrderAfterSave(self.orderId, self.userId);
        },
        showViewNotification: (): void => {
            self.setViewFormFillNotificationVisible(true);
            self.clearViewTimer();
        },
        showSaveNotification: (): void => {
            self.setSaveFormFillNotificationVisible(true);
            self.clearSaveTimer();
        },
        showModalNotification: (): void => {
            self.setModalFormFillNotificationVisible(true);
            self.clearModalTimer();
        },
        setViewFormFillTimePassed: (value: number): void => {
            self.viewFormFillTimePassed = value;
            self.inactivityOperatorStorageService.storeViewTimePassed(value, self.orderId, self.userId);
        },
        setModalFormFillTimePassed: (value: number): void => {
            self.modalFormFillTimePassed = value;
            self.inactivityOperatorStorageService.storeModalTimePassed(value, self.orderId, self.userId);
        },
        setSaveFormFillTimePassed: (value: number): void => {
            self.saveFormFillTimePassed = value;
            self.inactivityOperatorStorageService.storeSaveTimePassed(value, self.orderId, self.userId);
        },
        setAuxStatusFillTimePassed: (value: number): void => {
            self.auxStatusFillTimePassed = value;
        },
        setPreviousCallDuration: (value: number): void => {
            self.previousCallDuration = value;
            self.inactivityOperatorStorageService.storeCallDuration(value, self.orderId, self.userId);
        },
        setAuxStatusForwardTimer: (auxStatusForwardTimer: boolean): void => {
            self.auxStatusForwardTimer = auxStatusForwardTimer;
        },
        clearAuxStatusTimers: (): void => {
            self.clearAuxStatusTimePassedTimer();
            self.clearAuxStatusTimer();
        },
    }))
    .actions((self) => ({
        startViewTimer: (): void => {
            if (self.viewFormFillTimeLimit === null) {
                return;
            }

            self.clearViewTimer();
            self.clearViewTimePassedTimer();

            // Каждую секунду пишем сколько уже натикало
            self.viewTimerPassedTimer = setInterval(
                () => {
                    self.setViewFormFillTimePassed(self.viewFormFillTimePassed + 1000);
                },
                1000,
            );

            const timeout = self.viewFormFillTimeLimit * 1000 - self.viewFormFillTimePassed;

            if (timeout >= 0) {
                self.viewTimer = setTimeout(
                    self.showViewNotification,
                    timeout,
                );
            }
        },
        startSaveTimer: (postProcessing ?: boolean): void => {

            if (!postProcessing) {
                if (self.previousCallDuration > self.saveFormFillDurationCall) {
                    self.setWasLastCallShort(false);
                    self.saveFormFillTimeLimit = self.saveFormFilterLongCall;
                } else {
                    self.setWasLastCallShort(true);
                    self.saveFormFillTimeLimit = self.saveFormFilterShortCall;
                }
            }

            if (self.saveFormFillTimeLimit === null) {
                return;
            }

            self.clearSaveTimer();
            self.clearSaveTimePassedTimer();

            // Каждую секунду пишем сколько уже натикало
            self.saveTimerPassedTimer = setInterval(
                () => {
                    self.setSaveFormFillTimePassed(self.saveFormFillTimePassed + 1000);
                },
                1000,
            );

            const timeout = self.saveFormFillTimeLimit * 1000 - self.saveFormFillTimePassed;

            /**
             * таймер истекает в любом случае по таймауту
             * таймаут может быть отрицательным, значит получили заказ,
             * в котором уже был режим limit_time_save и он истёк
             * должны всё равно создать уведомление
             * и перевести оператора в unready
             */
            self.saveTimer = setTimeout(
                self.showSaveNotification,
                timeout,
            );
        },
        startModalTimer: (): void => {

            if (self.modalFormFillTimeLimit === null) {
                return;
            }

            self.clearModalTimer();
            self.clearModalTimePassedTimer();
            // Каждую секунду пишем сколько уже натикало
            self.modalTimerPassedTimer = setInterval(
                () => {
                    self.setModalFormFillTimePassed(self.modalFormFillTimePassed + 1000);
                },
                1000,
            );

            const timeout = self.modalFormFillTimeLimit * 1000 - self.modalFormFillTimePassed;

            self.modalTimer = setTimeout(
                self.showModalNotification,
                timeout,
            );
        },
        startAuxStatusTimer: (limit: number): void => {

            self.setAuxStatusForwardTimer(false);

            self.clearAuxStatusTimers();

            let subtrahend = 1000;

            self.setAuxStatusFillTimePassed(limit * 1000);
            // Каждую секунду пишем сколько уже натикало
            self.auxStatusTimerPassedTimer = setInterval(
                () => {
                    self.setAuxStatusFillTimePassed(self.auxStatusFillTimePassed - subtrahend);
                },
                1000,
            );

            self.auxStatusTimer = setTimeout(
                () => {
                    subtrahend = -1000;
                    self.setAuxStatusForwardTimer(true);
                    self.clearAuxStatusTimer();
                },
                limit * 1000,
            );
        },
    }))
    .actions((self) => ({
        stopAllTimers: () => {
            self.clearSaveTimer();
            self.clearViewTimer();
            self.clearModalTimer();
            self.clearAuxStatusTimer();
            self.clearSaveTimePassedTimer();
            self.clearViewTimePassedTimer();
            self.clearModalTimePassedTimer();
            self.clearAuxStatusTimers();
        },
    }))
    .actions((self) => ({
        startTimer: (postProcessing: boolean): void => {
            self.stopAllTimers();
            /* Запускает нужный таймер в зависимости от состояния
             или не запускает если запускать нечего */

            if (postProcessing) {

                if (self.modalFormFillTimePassed) {
                    return self.startModalTimer();
                }

                return self.startViewTimer();
            }

            if (self.previousCallDuration && self.saveFormFillTimePassed) {
                // Есть опция таймера save и восстановлено время таймера
                self.startSaveTimer();
            } else if (self.viewFormFillTimeLimit) {
                self.startViewTimer();
            }
        },
    }));


export interface IOperatorInactivityNotificationTimersModel extends Instance<typeof OperatorInactivityNotificationTimersModel> {}
export interface IOperatorInactivityNotificationTimersModelSnapshotIn extends SnapshotIn<typeof OperatorInactivityNotificationTimersModel> {}
export interface IOperatorInactivityNotificationTimersModelSnapshotOut extends SnapshotOut<typeof OperatorInactivityNotificationTimersModel> {}
