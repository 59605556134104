import React, { FC } from 'react';
import cn from 'classnames';


interface IOrderIconSvgProps {
    className?: string;
}

const OrderIconSvg: FC<IOrderIconSvgProps> = ({ className }) => (
    <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(['OrderIconSvg', className])}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5625 1.95312H10.5425C10.2056 0.824883 9.15824 0 7.92188 0C6.68551 0 5.63816 0.824883 5.30121 1.95312H1.28125C0.849766 1.95312 0.5 2.30289 0.5 2.73438V19.2188C0.5 19.6502 0.849766 20 1.28125 20H14.5625C14.994 20 15.3438 19.6502 15.3438 19.2188V2.73438C15.3438 2.30289 14.994 1.95312 14.5625 1.95312ZM2.0625 18.4375V3.51562H5.96875C6.40023 3.51562 6.75 3.16586 6.75 2.73438C6.75 2.0882 7.2757 1.5625 7.92188 1.5625C8.56805 1.5625 9.09375 2.0882 9.09375 2.73438C9.09375 3.16586 9.44352 3.51562 9.875 3.51562H13.7812V18.4375H2.0625ZM4.79688 11.9531C4.36539 11.9531 4.01562 12.3029 4.01562 12.7344C4.01562 13.1659 4.36539 13.5156 4.79688 13.5156H11.0469C11.4784 13.5156 11.8281 13.1659 11.8281 12.7344C11.8281 12.3029 11.4784 11.9531 11.0469 11.9531H4.79688ZM4.79688 10.3906C4.36539 10.3906 4.01562 10.0409 4.01562 9.60938C4.01562 9.17789 4.36539 8.82812 4.79688 8.82812H11.0469C11.4784 8.82812 11.8281 9.17789 11.8281 9.60938C11.8281 10.0409 11.4784 10.3906 11.0469 10.3906H4.79688Z"
            fill="#C2C2C2"
        />
    </svg>
);

export default OrderIconSvg;
