import { reaction } from 'mobx';
import { IDisposer } from 'mobx-state-tree';
import { Store } from '@store/store';
import NotificationApiService from '@api/notification-api-service';
import ErrorService from '@core/services/ErrorService';
import { NotificationModel } from '@models/mobx-state-tree/Notification.model';
import OfflineError from '@core/error/OfflineError';


const NOTIFICATION_POLL_INTERVAL = 60000;


class NotificationPollingService {
    private _pollingNotificationTimeout: number | null = null;

    private _disposerOnUserLoginStateChanged: IDisposer;

    constructor(
        private readonly store: Store,
        private readonly notificationApiService: NotificationApiService,
        private readonly errorService: ErrorService,
    ) {}

    init(): void {
        this._disposerOnUserLoginStateChanged = reaction<boolean>(
            () => !!this.store.currentUser.id,
            (isLoggedIn) => {
                if (!isLoggedIn) {
                    this.stopPolling();
                    this.store.clearNotifications();
                } else if (this.store.currentUser.id) {
                    try {
                        void this.startPolling();
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                }
            },
        );

        if (this.store.currentUser.id) {
            try {
                void this.startPolling();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    }

    destroy(): void {
        if (this._disposerOnUserLoginStateChanged) {
            this._disposerOnUserLoginStateChanged();
        }

        this.stopPolling();
    }

    reinitialize(): void {
        this.destroy();
        this.init();
    }

    private pollingTimeout = (): Promise<void> => new Promise(((resolve): void => {
        this._pollingNotificationTimeout = setTimeout(resolve, NOTIFICATION_POLL_INTERVAL);
    }));

    private stopPolling = (): void => {
        if (this._pollingNotificationTimeout) {
            clearTimeout(this._pollingNotificationTimeout);
        }
    };

    private startPolling = async (): Promise<void> => {
        try {
            if (this.store.currentUser.id && !this.store.hasNotifications) {
                const notificationFromApi = await this.notificationApiService.get({ userId: this.store.currentUser.id });

                if (notificationFromApi) {
                    // Получили уведолмение из api
                    const hasInStore = this.store.notifications.has(String(notificationFromApi.id));

                    if (!hasInStore) {
                        const n = NotificationModel.create({
                            id: String(notificationFromApi.id),
                            isFeedback: notificationFromApi.is_feedback,
                            message: notificationFromApi.message,
                        });

                        this.store.addNotification(n);
                    }
                }

                /**
                 * Только после того как уведомления перестали приходить или получено информационное уведомление,
                 * то ставим метку, что все нотификации с серера выгорели и можно жить дальше
                 *
                 * Да, может быть так что будет информационное, а за ним требующее обратной связи и мы пустим опера
                 * до формы когда следом ему придет блокирующее уведомление, требующее обратной связи.
                 */
                if (!notificationFromApi || !notificationFromApi.is_feedback) {
                    this.store.setNotificationReceivedFromServer(true);
                }

                if (
                    this.store.shouldGoToReadyIfNoNotificationsWithFeedback
                    && !this.store.hasNotificationWithFeedBack
                    && !this.store.currentUser.predictiveAutocall
                    && this.store.currentUser.haveSipPlatinumAccountCredential
                    && !this.store.currentUser.needSpeedTest
                ) {
                    this.store.currentUser.setIsReady(true);
                    this.store.setShouldGoToReadyIfNoNotificationsWithFeedback(false);
                }

                //await this.pollingTimeout();
                //await this.startPolling();
            } else {
                this.stopPolling();
            }
        } catch (e) {
            if ('onLine' in navigator) {
                const isOffline = !navigator.onLine;
                if (isOffline) {
                    try {
                        throw this.errorService.toReturnAFabricatedError(OfflineError);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn(e);
                    }
                }
            } else {
                // eslint-disable-next-line no-console
                console.error(e);
                this.stopPolling();
            }
        }
    };
}


export default NotificationPollingService;
