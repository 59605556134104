import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import OrderCreationService from '@services/order/OrderCreationService';
import OrderCreationEventSubscriptionService from '@services/subscription/OrderCreationEventSubscriptionService';

const OrderCreationEventSubscriptionServiceDI = (): any => [
    OrderCreationEventSubscriptionService,
    toFactory(
        [
            Store,
            OrderCreationService,
        ],
        (
            store: Store,
            orderCreationService: OrderCreationService,
        ) => new OrderCreationEventSubscriptionService(
            store,
            orderCreationService,
        ),
    ),
];


export default OrderCreationEventSubscriptionServiceDI;
