import React, {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router-dom';

import {
    UserService,
} from '@/app/services';
import FullscreenLoading from '@components/main/fullscreen-loading/FullscreenLoading';
import Sidebar from '@components/main/sidebar/sidebar';
import I18NService from '@services/I18NService';
import Notifications from '@components/main/order-page/Notifications/Notifications';
import FloatingTopNotifications from '@components/main/FloatingTopNotifications/FloatingTopNotifications';
import { Store } from '@store/store';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import SipPanel from '@components/main/sip-panel/SipPanel';
import isChromeBrowser from '@/utils/isChromeBrowser';
import NotAllowedBrowserMessage from '@components/main/NotAllowedBrowserMessage/NotAllowedBrowserMessage';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import { DEFAULT_USER } from '@core/constants/defaultStoreItems';
import Callibri from '@components/main/Callibri/Callibri';
import { Locations } from '@core/models/locations';
import ModalContainer from '@components/main/modal-container';
import RecallsPage from '@components/main/recalls-page/Recalls';
import BillingListPage from '@components/main/billing-list-page';
import OrderCreationPage from '@components/main/order-creation-page/OrderCreationPage';
import FindOrder from '@components/main/find-order/FindOrder';
import IndexPage from '@components/main/index-page/IndexPage';
import { WebSocketService, SipService } from '@app/services';


const Main: FunctionComponent = () => {
    const [
        {
            currentUser: {
                id: userId,
                mode,
                callCenter,
                needSpeedTest,
                validSpeedTest,
            },
            currentTab,
        },
        {
            isAuthCookieValid,
            initUser,
        },
        { t },
        {
            isProcessOfMeasuringSpeed,
            isInternetSpeedIsUnsatisfactory,
        },
        webSocketService,
        sipService,
    ] = useInstances(
        Store,
        UserService,
        I18NService,
        InternetSpeedMetricService,
        WebSocketService,
        SipService,
    );

    const [loading, setLoading] = useState(true);


    const checkBrowser = useMemo<boolean>(
        () => (callCenter?.checkBrowser || false) && !isChromeBrowser(),
        [callCenter],
    );

    const navigate = useNavigate();

    const isVisibleFullscreenLoading = useMemo<boolean>(
        () => loading
            || (isInternetSpeedIsUnsatisfactory && needSpeedTest)
            || (!needSpeedTest && validSpeedTest === false)
            || (isAuthCookieValid && userId === DEFAULT_USER.id),
        [
            loading,
            isInternetSpeedIsUnsatisfactory,
            isAuthCookieValid,
            userId,
            needSpeedTest,
            validSpeedTest,
        ],
    );

    useEffect(
        () => {
            if (!isAuthCookieValid) {
                navigate(Locations.LOGIN);
            }
        },
        [isAuthCookieValid],
    );

    useEffect(
        () => {
            initUser()
                .finally(() => {
                    setLoading(false);

                    // Если нет метки для замеров скорости интернета, то скачиваем заказ обычным способом
                    if (!needSpeedTest) {

                        if (sipService.sip.isConnected) {
                            //sipService.connectionRestart();
                        } else {
                            sipService.connect();
                        }
                    }
                });

            return () => {
                sipService.disconnect();
            };

        },
        [],
    );

    useEffect(
        () => {
            if (isAuthCookieValid && userId !== DEFAULT_USER.id) {
                webSocketService.on();
            }
            return () => {
                webSocketService.off();
            };
        },
        [isAuthCookieValid, userId],
    );

    if (isProcessOfMeasuringSpeed) {
        return (
            <FullscreenLoading
                text={t(
                    'Происходит измерение скорости интернет-соединения',
                    'The speed of the Internet connection is being measured.',
                )}
            />
        );
    }

    if (isVisibleFullscreenLoading) {
        return (
            <FullscreenLoading
                text="Loading"
            />
        );
    }

    if (checkBrowser) {
        return <NotAllowedBrowserMessage />;
    }

    const renderSelectPage = (currentTab: string): JSX.Element => {
        switch (currentTab) {
            case Locations.MAIN:
                return <IndexPage />;
            case Locations.RECALLS:
                return <RecallsPage />;
            case Locations.FIND_ORDER:
                return <FindOrder />;
            case Locations.BILLING_LIST:
                return <BillingListPage />;
            case Locations.ORDER_CREATION:
                return <OrderCreationPage />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <ModalContainer />
            <Sidebar show/>
            <Callibri />
            <FloatingTopNotifications>
                <Notifications />
            </FloatingTopNotifications>
            {renderSelectPage(currentTab)}
            {mode === UserModeEnum.CLIENT_SERVICE ? <SipPanel /> : <></>}
        </>
    );
};


export default observer(Main);
