import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class DynamicSetBadConfigurationError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'Количество продуктов в составе динамического набора и в orderProducts.set отличается!';
    }
}


export default DynamicSetBadConfigurationError;
