import React, { FunctionComponent, useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import { Locations } from '@core/models/locations';
import { useNavigate } from 'react-router-dom';


interface IOrdersTableButtonProps {
    orderId: number;
}


const OrdersTableButton: FunctionComponent<IOrdersTableButtonProps> = ({ orderId }): JSX.Element => {
    const { t } = useInstance(I18NService);

    const navigate = useNavigate();

    const clickHandler = useCallback(() => {
        navigate(`${Locations.ORDER_VIEW}/${orderId}`);
    }, [orderId]);

    return (
        <button
            data-cy="customerService__orderSearch--toView"
            className="CustomerServicePageTable__btn"
            type="button"
            onClick={clickHandler}
        >
            {t('Просмотр', 'View')}
        </button>
    );
};


export default observer(OrdersTableButton);
