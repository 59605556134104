import { useState, useRef, useEffect, MutableRefObject } from 'react';


export const useInputFocus = (): readonly [MutableRefObject<HTMLInputElement>, boolean] => {
    const [isFocused, setFocused] = useState(false);

    const ref = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    useEffect(() => {
        const node = ref.current;

        if (node) {
            node.addEventListener('focus', onFocus);
            node.addEventListener('blur', onBlur);
        }

        return () => {
            if (node) {
                node.removeEventListener('focus', onFocus);
                node.removeEventListener('blur', onBlur);
            }
        };
    }, [ref.current]);

    return [ref, isFocused] as const;
};
