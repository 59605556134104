import { toFactory } from 'react-ioc';
import { CustomerService } from '@services/index';
import CustomerApiService from '@api/customer-api-service';
import { Store } from '@store/store';
import { CustomerModeStore } from '@store/customerModeStore';
import UserApiService from '@api/user-api-service';
import OrderApiService from '@api/order-api-service';


const CustomerServiceDI = (): any => [
    CustomerService,
    toFactory(
        [
            CustomerApiService,
            Store,
            CustomerModeStore,
            UserApiService,
            OrderApiService,
        ],
        (
            customerApiService: CustomerApiService,
            rootStore: Store,
            customerModeStore: CustomerModeStore,
            userApiService: UserApiService,
            orderApiService: OrderApiService,
        ) => new CustomerService(
            customerApiService,
            rootStore,
            customerModeStore,
            userApiService,
            orderApiService,
        ),
    ),
];


export default CustomerServiceDI;
