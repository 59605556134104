import get from 'lodash/get';
import isArray from 'lodash/isArray';

import ApiBase from '@core/api';
import { IS_READY_STATUS_ENUM } from '@api/auth-api-service/models';
import {
    getEnvGetDeliveriesEndpoint,
    getEnvGetOrderEndpoint,
    getEnvGetOrderCommentsEndpoint,
    getEnvGetSimilarOrdersEndpoint,
    getEnvOrderUpdateEndpoint,
    getEnvAutoNoAnswerAppointmentEndpoint,
    getEnvAutoRecallAppointmentEndpoint,
    getEnvAddressActionTimeEndpoint,
    getEnvGetGoogleAddressEndpoint,
    getEnvUpdateOrderProductPriceEndpoint,
    getEnvReleaseOrderEndpoint,
    getEnvGetOrderFastChangeQueueEndpoint,
    getEnvOrderCreateEndpoint,
    getEnvGetPartnerDeliveriesEndpoint,
    getEnvGetOrderViewEndpoint,
    getEnvOrderAutoCallEndpoint,
    getEnvPostProcessingPrestatus,
    getEnvSetSuccessPhone,
    getEnvGetOrderStatusFromPay,
    getEnvGetOrderSmsEndpoint,
    getEnvOrderBlockEndpoint,
    getEnvPostProcessingReject,
} from '@/env/requestEndpoints';

import {
    IDelivery,
    IGetDeliveryListParams,
    IOrder,
    IOrderComment,
    IOrderUpdate,
    ISimilarOrder,
    ISaveAddressAccessTimeData,
    IGoogleAddressData,
    IUpdateOrderProductPriceArgs,
    IUpdateOrderProductPriceData,
    IReleaseOrderArgs,
    ICreateOrder,
    TERPOrderStatus, ISmsFromErp, TSmsListFromErp,
} from './models';
import XHR400Error from '../../error/XHR400Error';
import { Inject } from 'react-ioc';
import { ConfirmModalType } from '@core/models/ModalWindow';
import ModalService from '@core/services/ModalService';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}

interface IWithReadyStatus {
    is_ready: IS_READY_STATUS_ENUM;
}

export interface IDataOrder<T> extends IWithReadyStatus {
    order: T;
}


class OrderApiService {
    @Inject apiBase: ApiBase;

    @Inject modalService: ModalService;

    async getOrder(): Promise<IDataOrder<IOrder>> {
        const response = await this.apiBase.get<IWithResponse<IDataOrder<IOrder>>>(getEnvGetOrderEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getOrderFromAutoCall(task: number): Promise<IDataOrder<IOrder>> {

        const response = await this.apiBase.get<IWithResponse<IDataOrder<IOrder>>>(getEnvOrderAutoCallEndpoint(), { task_id: task });

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getOrderStatusFromERP(orderIds: number[]): Promise<TERPOrderStatus> {
        const response = await this.apiBase.get<IWithResponse<TERPOrderStatus>>(getEnvGetOrderStatusFromPay(), { order_id: orderIds });

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        if (isArray(data) && !data.length) {
            return {};
        }

        return data;
    }

    async orderUpdate(order: IOrderUpdate): Promise<IOrder> {
        const response = await this.apiBase.post<IWithResponse<IDataOrder<[IOrder]>>>(getEnvOrderUpdateEndpoint(), order);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return get(data, 'order[0]', {}) as IOrder;
    }

    async orderPrestatus(orderId: number, status: number, subStatus: number, recallDate: string | null): Promise<void> {

        try {
            const dataForSend = {
                order_id: orderId,
                status: status,
                sub_status: subStatus,
                recall_date: recallDate,
            };

            const response = await this.apiBase.post<IWithResponse<ISimilarOrder[]>>(getEnvPostProcessingPrestatus(), dataForSend);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }
        } catch (e) {
            console.log(e);
        }

    }

    async postProcessingReject(orderId: number): Promise<void> {

        try {
            const dataForSend = {
                order_id: orderId,
            };

            const response = await this.apiBase.post<IWithResponse<ISimilarOrder[]>>(getEnvPostProcessingReject(), dataForSend);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }
        } catch (e) {
            console.log(e);
        }

    }

    async fastChangeOrderQueue(orderId: number, queueId: number): Promise<void> {
        const dataToSend = {
            order_id: orderId,
            queue_id: queueId,
        };

        const response = await this.apiBase.post<IWithResponse<IDataOrder<[IOrder]>>>(getEnvGetOrderFastChangeQueueEndpoint(), dataToSend);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }
    }

    async getSimilarOrders(orderId: number): Promise<ISimilarOrder[]> {
        const dataForSend = {
            id: orderId,
        };

        const response = await this.apiBase.get<IWithResponse<ISimilarOrder[]>>(getEnvGetSimilarOrdersEndpoint(), dataForSend);

        const { data = [], message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getOrderComments(orderId: number): Promise<IOrderComment[]> {
        const dataForSend = {
            order_id: orderId,
        };

        const response = await this.apiBase.get<IWithResponse<IOrderComment[]>>(getEnvGetOrderCommentsEndpoint(), dataForSend);

        const { data = [], message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getOrderViewById(orderId: number): Promise<IDataOrder<IOrder>> {
        const dataForSend = {
            id: orderId,
        };

        const response = await this.apiBase.get<IWithResponse<IDataOrder<IOrder>>>(
            getEnvGetOrderViewEndpoint(),
            dataForSend,
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getDeliveries(formDelivery: IGetDeliveryListParams): Promise<IDelivery[] | never> {
        const {
            id, customerDistrict, customerZip, products, useForeignDeliveryBroker, partnerId, countryId,
        } = formDelivery;

        /**
         * Если use_foreign_delivery_broker = true, то отправляем запрос на брокер:
         * https://api.stage.2wcall.com/v2/doc#/Order/get_v2_order_shipping_list_by_broker
         *
         * False - отправляем запрос на получение фиксированной цены доставки от партнерских КС из CRM:
         * https://api.stage.2wcall.com/v2/doc#/PartnerShipping/get_v2_partner_shipping_list
         *
         * Null - используем список партнерских КС из CRM
         */
        if (useForeignDeliveryBroker) {
            const dataForSend = {
                id,
                params: {
                    customer_zip: customerZip,
                    customer_district: customerDistrict,
                    products,
                },
            };
            const response = await this.apiBase.get<IWithResponse<IDelivery[]>>(getEnvGetDeliveriesEndpoint(), dataForSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return data;
        }

        const dataForSend = {
            partner_id: partnerId,
            country_id: countryId,
        };

        const response = await this.apiBase.get<IWithResponse<IDelivery[]>>(getEnvGetPartnerDeliveriesEndpoint(), dataForSend);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async autoNoAnswerAppointment(orderId: number): Promise<[]> {
        const response = await this.apiBase.post<IWithResponse<[]>>(
            getEnvAutoNoAnswerAppointmentEndpoint(),
            { order_id: orderId },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async autoRecallAppointment(orderId: number): Promise<[]> {
        const response = await this.apiBase.post<IWithResponse<[]>>(
            getEnvAutoRecallAppointmentEndpoint(),
            { order_id: orderId },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    /**
     * Сообщаем на бэк номер телефона, \
     * по которому был совершен звонок и статус звонка в SIP = EnumSipStatus.LIVE
     */
    async setSuccessPhone(orderId: number, phone: string): Promise<[]> {
        const response = await this.apiBase.post<IWithResponse<[]>>(
            getEnvSetSuccessPhone(),
            {
                order_id: orderId,
                phone,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async saveAddressActiveTime(callHistoryId: number): Promise<ISaveAddressAccessTimeData> {
        const dataToSent = {
            action_address_time: 1,
            call_history_id: callHistoryId,
        };

        const response = await this.apiBase.post<IWithResponse<ISaveAddressAccessTimeData>>(getEnvAddressActionTimeEndpoint(), dataToSent);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getAddressFromGoogle(search: string): Promise<IGoogleAddressData> {
        const dataToSent = {
            search,
        };

        try {
            const response = await this.apiBase.post<IWithResponse<IGoogleAddressData>>(getEnvGetGoogleAddressEndpoint(), dataToSent);

            const { data, message = 'Unknown error' } = response;
            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return data;
        } catch (e) {
            const { message } = e;
            if (e instanceof XHR400Error) {
                // Не нашли такой адрес в геокодере
                throw new Error(message);
            }
            // eslint-disable-next-line no-console
            console.error(e);
            throw new Error(e);
        }
    }

    async updateOrderProductPrice(params: IUpdateOrderProductPriceArgs): Promise<boolean> {
        const dataToSent = {
            id: params.productId,
            price: params.price,
        };

        const response = await this.apiBase.post<IWithResponse<IUpdateOrderProductPriceData>>(getEnvUpdateOrderProductPriceEndpoint(), dataToSent);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }

    async releaseOrder(params: IReleaseOrderArgs): Promise<boolean> {
        try {
            const dataToSent = {
                order_id: params.orderId,
            };

            const response = await this.apiBase.post<IWithResponse<IUpdateOrderProductPriceData>>(getEnvReleaseOrderEndpoint(), dataToSent);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return isSuccess;

        } catch (e) {
            console.log(e);
            return false;
        }

    }

    async showResponseMessage(message: string): Promise<void> {
        await this.modalService.showConfirmModal(
            message,
            ConfirmModalType.Yes,
        );
    }

    async blockOrder(orderId: number): Promise<boolean> {

        try {
            const dataToSent = {
                order_id: orderId,
            };

            const response = await this.apiBase.post<IWithResponse<IUpdateOrderProductPriceData>>(getEnvOrderBlockEndpoint(), dataToSent);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            if (message === 'This order queue is not available for this operator') {
                await this.showResponseMessage(message);
                return false;
            }

            return isSuccess;

        } catch (e) {
            console.log(e);
            if (e?.message) {
                await this.showResponseMessage(e.message);
            }
            return false;
        }
    }

    async createOrder(countryId: number, partnerId: number, selectedCurrencyId: number, sourceId?: number, typeSourceId?: number): Promise<ICreateOrder> {
        const dataForSend = {
            country_id: countryId,
            partner_id: partnerId,
            currency_id: selectedCurrencyId,
        };

        const response = await this.apiBase.post<IWithResponse<ICreateOrder>>(
            getEnvOrderCreateEndpoint(),
            Object.assign(
                dataForSend,
                sourceId ? { source_id: sourceId } : null,
                typeSourceId ? { type_source_id: typeSourceId } : null,
            ),
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    public async getSms(foreignId: number): Promise<TSmsListFromErp> {
        const response = await this.apiBase.post<IWithResponse<ISmsFromErp[]>>(
            getEnvGetOrderSmsEndpoint(),
            { foreign_id: [foreignId] },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return {
            foreignId,
            smsList: data,
        };
    }
}


export default OrderApiService;
