import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { block } from 'bem-cn';
import EllipseSVG from '@components/resources/SVG/Ellipse/EllipseSVG';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';

import './billing-list-nav.scss';

interface IBillingListNav {
    period: string;
    setPeriod: (period: string) => void;
    dates: IDates[];
    setPeriodNumber: (periodNumber: number) => void;
    monthsNames: string[];
}

export interface IDates {
    begin: string;
    end: string;
    color: string;
    period: number;
}

const BillingListNav: FunctionComponent<IBillingListNav> = (
    {
        period,
        setPeriod,
        dates,
        setPeriodNumber,
        monthsNames,
    },
) => {

    const [
        { t },
    ] = useInstances(
        I18NService,
    );

    const b = block('billing-list-nav');

    return (
        <div className={b()}>
            <div className={b('tabs')}>
                <div
                    className={b('tab', { active: period === 'weeks' })}
                    onClick={()=> setPeriod('weeks')}
                >
                    {t('Неделя', 'Week')}
                </div>
                <div
                    className={b('tab', { active: period === 'months' })}
                    onClick={()=> setPeriod('months')}
                >
                    {t('Месяц', 'Month')}
                </div>
            </div>
            <div className={b('periods')}>
                {dates.map((date, index) => (
                    <div
                        className={b('period')}
                        key={v4()}
                    >
                        <div
                            className={b('svg')}
                            onClick={() => setPeriodNumber(date.period)}
                        >
                            <EllipseSVG color={date.color}/>
                        </div>
                        <div className={b('date')}>
                            {period === 'weeks' && (
                                <>
                                    <span>{date.begin}</span>
                                    <span>{date.end}</span>
                                </>
                            )}
                            {period === 'months' && (
                                <span>{t(`${monthsNames[index]}`)}</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default observer(BillingListNav);