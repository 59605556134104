import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import some from 'lodash/some';

import { Store } from '@/app/store/store';
import isFeatureFlagCallibri from '@/app/core/helpers/isFeatureFlagCallibri';
import CallibriInner from './CallibriInner';


/**
 * ID колл центров, где нужно включить Callibri
 */
const callCenterIds = [
    '3', '4', '5', '6', '8', '24', '37',
];

const Callibri: FC = () => {
    const {
        currentUser: {
            callCenter,
        },
    } = useInstance(
        Store,
    );


    if (callCenter) {
        const isSuitableCallCenter = some(
            callCenterIds,
            (_id) => _id === callCenter.id,
        );

        if (isSuitableCallCenter) {
            // eslint-disable-next-line no-console
            console.info('Callibri tured on for call center with id', callCenter.id);

            return <CallibriInner />;
        }
    }

    if (isFeatureFlagCallibri()) {
        // eslint-disable-next-line no-console
        console.info('Callibri tured on with feature-flag');

        return <CallibriInner />;
    }

    return (
        <></>
    );
};

export default observer(Callibri);
