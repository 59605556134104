import { toFactory } from 'react-ioc';

import WebSocketService from '@services/websocket/WebSocketService';
import { Store } from '@store/store';
import {
    PostProcessingService,
    SipService,
    UserService,
} from '@services/index';
import NotificationApiService from '@api/notification-api-service';



const WebSocketServiceDI = (): any => [
    WebSocketService,
    toFactory(
        [
            Store,
            SipService,
            UserService,
            NotificationApiService,
            PostProcessingService,
        ],
        (
            store: Store,
            sipService: SipService,
            userService: UserService,
            notificationApiService: NotificationApiService,
            postProcessingService: PostProcessingService,
        ) => new WebSocketService(
            store,
            sipService,
            userService,
            notificationApiService,
            postProcessingService,
        ),
    ),
];


export default WebSocketServiceDI;
