import React from 'react';

import { useInstances } from 'react-ioc';
import { block } from 'bem-cn';

import { observer } from 'mobx-react';

import BillingListTable from '@components/main/billing-list-page/billing-list-table/billing-list-table';
import BillingListInfo from '@components/main/billing-list-page/billing-list-info/billing-list-info';
import BillingListStats from '@components/main/billing-list-page/billing-list-stats/billing-list-stats';
import BillingListChart from '@components/main/billing-list-page/billing-list-chart/billing-list-chart';
import Button from '@UIElements/Button';

import I18NService from '@services/I18NService';

import './billing-list-page.scss';

const BillingListPage = () => {

    const [
        { t },
    ] = useInstances(
        I18NService,
    );

    const b = block('billing-list-page');

    return (
        <div className={b()}>
            <div className={b('header')}>
                <BillingListInfo />
                <BillingListStats />
                <BillingListChart />
                <div className={b('button')}>
                    <Button
                        variant="0"
                        size="0"
                        text={t('Задать вопрос', 'Ask a question')}
                        padding='16px'
                        disabled={true}
                    />
                </div>
            </div>

            <BillingListTable />
        </div>
    );
};

export default observer(BillingListPage);