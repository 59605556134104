import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import cn from 'classnames';
import ProblemSvg from '@components/main/order-page/call-panel/call-widget/includes/ProblemSvg';
import { Store } from '@store/store';


interface IIProblemBtnMobileProps {
    isDisable: boolean;
}


const ProblemBtnMobile: FunctionComponent<IIProblemBtnMobileProps> = ({ isDisable }): JSX.Element => {
    const [
        store,
    ] = useInstances(
        Store,
    );

    const { currentOrder } = store;
    const { setCommunicationQualityVisible } = store.ui.sidebar;

    const {
        communicationsQualityValues,
        lastCallHistoryEvent,
    } = currentOrder;

    const lastCallHistoryEventId = !!lastCallHistoryEvent && lastCallHistoryEvent.id;
    const communicationQualityWasSent = !!lastCallHistoryEvent && lastCallHistoryEvent.communicationQualityWasSent;

    const onProblemBtnClick = () => {
        if (communicationsQualityValues.length === 0 || !lastCallHistoryEventId || communicationQualityWasSent) {
            // Если нет критериев оценки качества связи то и не показываем модалку вовсе
            return;
        }

        setCommunicationQualityVisible(true);
    };

    return (
        <div
            className={cn([
                'call-time-button',
                'call-problem-button',
                isDisable && 'disabled',
            ])}
            onClick={onProblemBtnClick}
        >
            <ProblemSvg className={cn(!isDisable && 'active')} />
        </div>
    );
};

export default observer(ProblemBtnMobile);
