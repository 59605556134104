import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OperatorStatisticModel = t
    .model('OperatorStatistic', {
        id: t.identifier,
        date: t.string,
        onlineTime: t.integer,
        ordersCount: t.integer,
        avgCheckUSD: t.number,
        callsCount: t.integer,
        approvedCount: t.integer,
    });


export interface IOperatorStatisticModel extends Instance<typeof OperatorStatisticModel> {}
export interface IOperatorStatisticModelSnapshotIn extends SnapshotIn<typeof OperatorStatisticModel> {}
export interface IOperatorStatisticModelSnapshotOut extends SnapshotOut<typeof OperatorStatisticModel> {}
