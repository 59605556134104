import ScriptApiService from '@api/script-api-service';
import { applySnapshot, IStateTreeNode, IType } from 'mobx-state-tree';
import { Store } from '@store/store';

import { v4 } from 'uuid';
import map from 'lodash/map';
import filter from 'lodash/filter';

import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { IScriptItem, IScriptDataItem } from '@api/script-api-service/models';
import {
    IScriptItemModelSnapshotIn,
} from '@models/mobx-state-tree/scripts/scriptItemModel';
import { IScriptDataModelSnapshotIn } from '@models/mobx-state-tree/scripts/scriptDataModel';


class ScriptDataService {
    private responseForCountry: IScriptItem[] | void;

    constructor(
        private readonly _scriptApiService: ScriptApiService,
        private readonly _store: Store,
    ) {
    }

    static createScriptDataModelSnaphotIn(data: IScriptDataItem): IScriptDataModelSnapshotIn {
        return {
            key: data.id,
            id: v4(),
            color: data.color,
            text: data.text,
            scriptTheme: {
                key: v4(),
                id: data.id,
                name: data.script_theme.name,
            },
        };
    }

    static createScriptItemModelSnapshotIn(item: IScriptItem): IScriptItemModelSnapshotIn {
        return {
            key: item.id,
            id: v4(),
            name: item.name,
            sort: item.sort,
            scriptData: convertArrayToCollection<IScriptDataModelSnapshotIn>(
                map<IScriptDataItem, IScriptDataModelSnapshotIn>(
                    item.script_data,
                    (data) => ScriptDataService.createScriptDataModelSnaphotIn(data),
                ),
            ),
        };
    }

    private _transformeResponseApplySnapshot = (
        response: IScriptItem[],
        scriptItemModel: IStateTreeNode<IType<Record<string, IScriptItemModelSnapshotIn>, any, any>>,
    ) => {

        const transformedScriptItems = convertArrayToCollection<IScriptItemModelSnapshotIn>(
            map<IScriptItem, IScriptItemModelSnapshotIn>(
                response,
                (item) => ScriptDataService.createScriptItemModelSnapshotIn(item),
            )) || {};

        applySnapshot(
            scriptItemModel,
            transformedScriptItems,
        );

    };

    private _clearScripts(): void {
        this._store.currentOrder.clearScripts();
    }

    async getScript(countryId: string, orderId: number, partnerProductId?: string): Promise<void> {

        this._clearScripts();

        if (Number(countryId) > 0) {

            if (partnerProductId) {

                const responseForLeadProduct = await this._scriptApiService.getScript(countryId, partnerProductId);

                if (responseForLeadProduct) {
                    this._transformeResponseApplySnapshot(
                        responseForLeadProduct,
                        this._store.currentOrder.leadProductScriptItem,
                    );

                    const [script] = responseForLeadProduct;

                    if (script?.id) {
                        await this._scriptApiService.absetScript(orderId, script.id);
                    }
                }

            }

            const responseForCountry = await this._scriptApiService.getScript(countryId);

            this.responseForCountry = responseForCountry;

            if (responseForCountry) {
                this._transformeResponseApplySnapshot(
                    responseForCountry,
                    this._store.currentOrder.countryScriptItems,
                );
            }
        }
    }


    public setScriptItem(id: number): void {

        if (this.responseForCountry) {
            const responseForLeadProduct = filter(this.responseForCountry, (item) => item.id === id);

            this._transformeResponseApplySnapshot(
                responseForLeadProduct,
                this._store.currentOrder.leadProductScriptItem,
            );
        }

    }
}

export default ScriptDataService;