import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OrderPageUIStateModel = t
    .model('OrderPageUIStateModel', {
        fabVisible: t.optional(t.boolean, true),
        callPanelVisible: t.optional(t.boolean, false),
        notificationHiddenOnScroll: t.optional(t.boolean, false),
    })
    .actions((self) => ({
        toggleCallPanel: (): void => {
            self.fabVisible = !self.fabVisible;
            self.callPanelVisible = !self.callPanelVisible;
        },
        closeCallPanel: (): void => {
            self.fabVisible = true;
            self.callPanelVisible = false;
        },
        openCallPanel: (): void => {
            self.fabVisible = false;
            self.callPanelVisible = true;
        },
        setNotificationHiddenOnScroll: (value: boolean): void => {
            self.notificationHiddenOnScroll = value;
        },
    }));


export interface IOrderPageUIStateModel extends Instance<typeof OrderPageUIStateModel> {}
export interface IOrderPageUIStateModelSnapshotIn extends SnapshotIn<typeof OrderPageUIStateModel> {}
export interface IOrderPageUIStateModelSnapshotOut extends SnapshotOut<typeof OrderPageUIStateModel> {}
