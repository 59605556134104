import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import ValidateService from '@core/services/ValidateService';
import I18NService from '@services/I18NService';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';


const StoreDI = (): any => [
    Store,
    toFactory(
        [
            ValidateService,
            I18NService,
            InactivityOperatorStorageService,
        ],
        (
            validateService: ValidateService,
            I18NService: I18NService,
            inactivityOperatorStorageService: InactivityOperatorStorageService,
        ) => Store.create(
            validateService,
            I18NService,
            inactivityOperatorStorageService,
        ),
    ),
];


export default StoreDI;
