import React, { FunctionComponent, useCallback, useState } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import { Store } from '@store/store';
import { OrderService, SipService } from '@/app/services';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import { ICommunicationsQualityModel } from '@models/mobx-state-tree/communicationsQuality.model';
import { ReasonsForEndingConversation } from '@services/sip/models';
import Button from '@UIElements/Button';

import './includes/CommunicationQualityModal.scss';


const CommunicationQualityModal: FunctionComponent = (): JSX.Element => {
    const [
        store,
        modalService,
        sipService,
        orderService,
        { t },
    ] = useInstances(
        Store,
        ModalService,
        SipService,
        OrderService,
        I18NService,
    );

    const [selectedCQ, setSelectedCQ] = useState<string[]>([]);
    const [additionalComment, setAdditionalComment] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const {
        communicationsQualityValues,
        form: {
            disableStatusSubStatusPhoneButtons: disabled,
        },
    } = store.currentOrder;

    const onCancel = useCallback(
        () => {
            if (disabled) {
                return;
            }
            modalService.cancel();
        }, [],
    );

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAdditionalComment(event.target.value);
    };

    const continuE = (): void => {
        if (!selectedCQ.length || disabled) {
            return;
        }

        modalService.cancel();
        void orderService.saveCommunicationQuality(selectedCQ, additionalComment ? `${comment}, ${additionalComment}` : comment);
    };

    const abort = (): void => {
        if (!selectedCQ.length || disabled) {
            return;
        }

        const { lastCallHistoryEvent } = store.currentOrder;

        if (lastCallHistoryEvent) {
            const now = new Date();
            const timeAbort = Math.floor((now.getTime() - lastCallHistoryEvent.startTime) / 1000);

            lastCallHistoryEvent.setIsAbort(true);
            lastCallHistoryEvent.setTimeAbort(timeAbort);
            lastCallHistoryEvent.setEndCall(ReasonsForEndingConversation.POOR_CONNECTION_QUALITY);
        }

        modalService.cancel();
        sipService.endCall();
        void orderService.saveCommunicationQuality(selectedCQ, additionalComment ? `${comment}, ${additionalComment}` : comment);
    };

    const toggleCQ = (cqId: string, question: string): void => {
        const alreadySelected = selectedCQ.includes(cqId);

        if (alreadySelected) {
            setSelectedCQ([]);
        } else {
            setSelectedCQ([cqId]);
            setComment(question);
        }
    };

    const onContinue = useCallback(
        () => continuE(),
        [selectedCQ, comment, additionalComment],
    );

    const onAbort = useCallback(
        () => abort(),
        [selectedCQ, comment, additionalComment],
    );

    const onToggleCQ = useCallback(
        (cqId: string, question: string) => toggleCQ(cqId, question),
        [selectedCQ, comment],
    );

    return (
        <div className="CommunicationQualityModal">
            <div className="CommunicationQualityModal__container">
                <div className="CommunicationQualityModal__header">
                    <h3 className="CommunicationQualityModal__main_header">
                        {t('Проблема с CRM', 'Problem with CRM')}
                    </h3>
                    <div className="CommunicationQualityModal__sub_header">
                        {t(
                            'Пожалуйста, оцените нашу поддержку о проблемах',
                            'Please rate talk our support about problems',
                        )}
                    </div>
                </div>
                <div className="CommunicationQualityModal__block">
                    {communicationsQualityValues.map((item: ICommunicationsQualityModel) => (
                        <div key={item.id} className="CommunicationQualityModal__item">
                            <label htmlFor={`cq-${item.id}`}>
                                <div className="cq_checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedCQ.includes(item.id)}
                                        value={item.id}
                                        id={`cq-${item.id}`}
                                        onChange={() => onToggleCQ(item.id, t(item.question || ''))}
                                    />
                                </div>
                                <div className="cq_name">{t(item.question || '')}</div>
                            </label>
                        </div>
                    ))}
                </div>
                <div className="CommunicationQualityModal__block__bottom">
                    <b>{t('Добавьте ваш комментарий', 'Add your comment')}</b>
                    <textarea
                        className="CommunicationQualityModal__textarea"
                        placeholder={t('Оставьте комментарий...', 'Write a comment...')}
                        value={additionalComment}
                        onChange={onChange}
                        maxLength={140}
                    />
                </div>
                <div className="CommunicationQualityModal__block__bottom">
                    <Button
                        variant="7"
                        size="1"
                        text={t('Продолжить', 'Continue')}
                        width="120px"
                        onClick={onContinue}
                        disabled={disabled}
                    />
                    <Button
                        variant="5"
                        size="1"
                        text={t('Прервать', 'Abort')}
                        width="120px"
                        onClick={onAbort}
                        disabled={disabled}
                    />
                    <Button
                        variant="2"
                        size="1"
                        text={t('Отмена', 'Cancel')}
                        width="120px"
                        onClick={onCancel}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};


export default observer(CommunicationQualityModal);
