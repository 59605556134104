import moment from 'moment-timezone';

interface IRemainingTimeResult {
    time: string;
    totalMinutes: number;
}

// Делаем число положительным, округляем к меньшему значению, если число однозначное добавляем впереди ноль
const formatTime = (time: number): string => Math.floor(Math.abs(time)).toString().padStart(2, '0');

export const getRemainingTime = (recallDateByCallCenter: string): IRemainingTimeResult => {

    // Преобразуем строку в объект даты с помощью moment
    const recallDate = moment(recallDateByCallCenter);

    // Получаем текущее время браузера с помощью moment
    const currentDate = moment();
    // Вычисляем разницу между датами
    const duration = moment.duration(recallDate.diff(currentDate));

    // Выяснем положительное или отрицательное число получается
    if (duration.asDays() < 0) {
        return {
            time: '',
            totalMinutes: 0,
        };
    }

    // Преобразуем разницу в дни, в часы и минуты
    const days = formatTime(duration.asDays()); // кол-во полных суток,
    const hours = formatTime(duration.asHours() % 24); // кол-во оставшихся часов,
    const minutes = formatTime(duration.asMinutes() % 60); // оставшиеся минуты

    return {
        time: `${days}:${hours}:${minutes}`, // кол-во полных суток,
        totalMinutes: Math.floor(duration.asMinutes()), // все оставшееся время в минутах
    };

};