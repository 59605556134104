import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';
import PartnerApiService from '@api/partner-api-service';
import { OrderCreationService, OrderFetchService } from '@services/order';
import SourceApiService from '@api/source-api-service';
import UserApiService from '@api/user-api-service';
import CountryApiService from '@api/country-api-service';
import { SipService } from '@services/index';
import ModalService from '@core/services/ModalService';
import KibanaService from '@services/KibanaService';
import I18NService from '@services/I18NService';


const OrderCreationServiceDI = (): any => [
    OrderCreationService,
    toFactory(
        [
            Store,
            OrderApiService,
            PartnerApiService,
            SourceApiService,
            UserApiService,
            OrderFetchService,
            CountryApiService,
            SipService,
            ModalService,
            I18NService,
            KibanaService,
        ],
        (
            store: Store,
            orderApiService: OrderApiService,
            partnerApiService: PartnerApiService,
            sourceApiService: SourceApiService,
            userApiService: UserApiService,
            orderFetchService: OrderFetchService,
            countryApiService: CountryApiService,
            sipService: SipService,
            modalService: ModalService,
            I18NService: I18NService,
            kibanaService: KibanaService,
        ) => new OrderCreationService(
            store,
            orderApiService,
            partnerApiService,
            sourceApiService,
            userApiService,
            orderFetchService,
            countryApiService,
            sipService,
            modalService,
            I18NService,
            kibanaService,
        ),
    ),
];


export default OrderCreationServiceDI;
