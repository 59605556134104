import React, { FunctionComponent, useCallback, useState } from 'react';
import cn from 'classnames';


interface ITextAreaProps {
    className?: string;
    placeholder?: string;
    onChange?: (val: any) => void; // TODO: убрать any
    label?: string;
    error?: string;
    id?: string;
    name?: string;
    value?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
}


const TextArea: FunctionComponent<ITextAreaProps> = ({
    className,
    error,
    height = '',
    width = '',
    onChange = () => {},
    value,
    placeholder,
    name,
    id,
    label,
    disabled,
}) => {
    const [isFocus, setIsFocus] = useState(false);

    const classNameContent = `${cn(
        'e-textarea-input',
        isFocus ? 'focus' : '',
        className,
        { error },
    )}`;

    const textAreaStyles: Record<string, string> = {
        '--textArea-height': height,
        '--textArea-width': width,
    };

    const onFocus = useCallback(() => {
        setIsFocus(true);
    }, [setIsFocus]);

    const onBlur = useCallback(() => {
        setIsFocus(false);
    }, [setIsFocus]);

    const onChangeClick = useCallback((e) => {
        onChange(e);
    }, [onChange]);

    return (
        <div className="textArea__wrapper" style={textAreaStyles}>
            <div className={classNameContent}>
                <div className="input-label">
                    <div className="input-label-text">{label}</div>
                </div>
                <textarea
                    placeholder={placeholder}
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChangeClick}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                />
                <div className="input-error">{error}</div>
            </div>
        </div>
    );
};


export default TextArea;
