import { customI18NTFunction } from '@services/I18NService';

interface IParams {
    readonly key: string;
    readonly defaultValue: string;
}


class ErrorBase extends Error {
    constructor(messageTranslate: IParams, t: customI18NTFunction | undefined) {
        super();

        if (t) {
            this.message = t(
                messageTranslate.key,
                messageTranslate.defaultValue,
            );
        } else {
            this.message = messageTranslate.defaultValue;
        }
    }
}


export default ErrorBase;
