import React, { FunctionComponent } from 'react';
import padStart from 'lodash/padStart';


interface TimeFromSecondsProps {
    duration: number;
}

const secondsToTime = (secondsTotal: number): { h: number; m: number; s: number } => {
    secondsTotal = Math.round(secondsTotal);
    const hours = Math.floor(secondsTotal / (60 * 60));

    const divisorForMinutes = secondsTotal % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return {
        h: hours,
        m: minutes,
        s: seconds,
    };
};

const TimeFromSeconds: FunctionComponent<TimeFromSecondsProps> = ({ duration }) => {
    const { h, m, s } = secondsToTime(duration);

    return (
        <div
            className="TimeFromSeconds__duration"
            data-cy="call_timer"
        >
            {h > 0 && `${padStart(String(h), 2, '0')}:`}
            {`${padStart(String(m), 2, '0')}:${padStart(String(s), 2, '0')}`}
        </div>
    );
};

export default TimeFromSeconds;
