import React, { FunctionComponent } from 'react';

import './includes/ContentBlock.scss';


interface IContentBlockProps {
    children: JSX.Element;
    title?: string;
    headerButtons?: JSX.Element;
    isWithoutHeader?: boolean;
    id?: string;
}


const ContentBlock: FunctionComponent<IContentBlockProps> = ({
    children, title, headerButtons, isWithoutHeader = false, id = '',
}) => (
    <div className="contentBlock" id={id}>
        {!isWithoutHeader
        && (
            <div className="contentBlock__header">
                {title && (
                    <div className="contentBlock__title">{title}</div>
                )}
                {headerButtons && (
                    <div className="contentBlock__actionButton">
                        {headerButtons}
                    </div>
                )}
            </div>
        )}
        <div className="contentBlock__content">{children}</div>
    </div>
);

export default ContentBlock;
