import ApiBase from '@core/api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
    getEnvAddressClassifierGetData,
    getEnvAddressClassifierSearch,
    getEnvAddressClassifierSearchByZip,
    getEnvKLADREndpoint,
} from '@/env/requestEndpoints';
import { IKladrResultModelSnapshotIn } from '@models/mobx-state-tree/kladrResult.model';

import { IKladrResult, IKladrSearchResult } from './models';
import { Inject } from 'react-ioc';
import { AttrsKey } from '@interfaces/form.interface';


interface IGetKladrData {
    results: IKladrResult[];
    confirm_address: string[];
}

interface IGetKladrResponse {
    status: 'success' | 'error';
    data: IGetKladrData;
    message?: string;
}

interface IAddressClassifierGetDataResponse {
    status: 'success' | 'error';
    data: string[];
    message?: string;
}

interface IGetAddressClassifierSearchResponse {
    status: 'success' | 'error';
    data: IKladrSearchResult[];
    message?: string;
}

const isString = (value: string | IKladrResult): value is string =>  {
    return typeof value === 'string';
};

const prepareDataForCladAutoFill = (results: string[] | IKladrResult[], confirmAddress?: string[]): IKladrResultModelSnapshotIn[] => {
    return  results
        .map((v: string | IKladrResult) => {
            return <IKladrResultModelSnapshotIn>{
                id: isString(v) ? v : String(v.id),
                text: isString(v) ? v : v.text,
                path: isString(v) ? null : v.path,
                depth: isString(v) ? null : v.depth,
                sort: isString(v) ? null : v.sort,
                name: isString(v) ? v : v.name,
                partnerFormAttributeId: isString(v) ? null : v.partner_form_attribute_id,
                partnerFormId: isString(v) ? null : v.partner_form_id,
                num: isString(v) ? null : v.num,
                countryId: isString(v) ? null : String(v.country_id),
                confirmData: confirmAddress || results,
            };
        })
        .filter((item: IKladrResultModelSnapshotIn) => item.name && item.name.length > 0);
};

export const prepareDataForSearchCladAutoFill = (results: IKladrSearchResult[], formAttributeName: string): IKladrResultModelSnapshotIn[] => {

    const searchFormAttributeName = results.map(el => el[formAttributeName as AttrsKey]);

    return  searchFormAttributeName
        .map((v: string) => {

            return <IKladrResultModelSnapshotIn>{
                id: v,
                text: v,
                path: null,
                depth: null,
                sort: null,
                formAttributeName,
                name: v,
                partnerFormAttributeId: null,
                partnerFormId: null,
                num: null,
                countryId: null,
                confirmData: searchFormAttributeName,
            };
        })
        .filter((item: IKladrResultModelSnapshotIn) => item.name && item.name.length > 0);
};

class KladrApiService {
    @Inject apiBase: ApiBase;

    async getKladrData(
        partnerFormAttributeId: number, // ID аттрибута формы.
        kladerInAllString: 0 | 1, // Считать ли переданный шаблон началом строки (при 0) или искать совпадение в любом месте строки (при 1)
        isKladerOnly: 0 | 1, // Параметр запрещает использовать данные не из кладра для указанного partner_form_attribute_id
        valueForSearch: string, // Значение для поиска
    ): Promise<{ results: IKladrResultModelSnapshotIn[] } | never> {
        const dataForSend = {
            partner_form_attribute_id: partnerFormAttributeId,
            klader_in_all_string: kladerInAllString,
            is_klader_only: isKladerOnly,
            search: valueForSearch,
        };

        const response = await this.apiBase.get<IGetKladrResponse>(getEnvKLADREndpoint(), dataForSend);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return {
            results: prepareDataForCladAutoFill(
                data.results,
                data.confirm_address,
            ),
        };
    }

    async getAddressClassifierSearch(
        country: string,
        formAttributeName: string,
        withParents: 0 | 1,
        search: string,
        parentData?: string,
    ): Promise<{ results: IKladrResultModelSnapshotIn[]; data:  IKladrSearchResult[] } | never | void > {

        try {
            const dataForSend = {
                country,
                form_attribute_name: formAttributeName,
                with_parents: withParents,
                search,
                ...(parentData ? { parent_data: parentData } : {}),
            };

            const response = await this.apiBase.get<IGetAddressClassifierSearchResponse>(getEnvAddressClassifierSearch(), dataForSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            if (isEmpty(data)) {
                return { results: [], data: [] };
            }

            return {
                results: prepareDataForSearchCladAutoFill(data, formAttributeName),
                data,
            };
        } catch (e) {
            console.log(e);
        }
    }

    async getAddressClassifierSearchByZip(
        orderId: number,
        customerZip: string,
    ): Promise<{ results: IKladrResultModelSnapshotIn[]; data:  IKladrSearchResult[] } | never | void > {
        try {
            const dataForSend = {
                order_id: orderId,
                customer_zip: customerZip,
            };

            const response = await this.apiBase.get<IGetAddressClassifierSearchResponse>(getEnvAddressClassifierSearchByZip(), dataForSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return {
                results: prepareDataForSearchCladAutoFill(data, AttrsKey.CUSTOMER_ZIP),
                data,
            };

        } catch (e) {
            console.log(e);
        }
    }

    async getAddressClassifierGetData(
        value: string,
        sourceAttributeId: number,
        targetAttributeId: number,
    ): Promise<{ results: IKladrResultModelSnapshotIn[] } | never | void > {

        try {
            const dataForSend = {
                data: value,
                source_attribute_id: sourceAttributeId,
                target_attribute_id: targetAttributeId,
            };

            const response = await this.apiBase.get<IAddressClassifierGetDataResponse>(getEnvAddressClassifierGetData(), dataForSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            if (isEmpty(data)) {
                return { results: [] };
            }

            return {
                results: prepareDataForCladAutoFill(data),
            };
        } catch (e) {
            console.log(e);
        }
    }
}


export default KladrApiService;
