import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { CallHistory } from '@models/mobx-state-tree/callHistory.model';
import { OrderComment } from '@models/mobx-state-tree/orderComment.model';
import { CommunicationsQuality } from '@models/mobx-state-tree/communicationsQuality.model';
import { BasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ProductItemModel } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { PromotionBuilder } from '@models/mobx-state-tree/newModels/PromotionBuilder.models';

export const AdditionalParamsCurrentOrderModel = t
    .model('AdditionalParamsCurrentOrderModel', {
        basket: t.map(BasketItemModel), // то что хотят купить
        catalog: t.map(ProductItemModel), // то что могут купить
        /**
         * Список конструкторов акций в каталоге
         */
        promotionBuilderCatalog: t.map(PromotionBuilder),
        /**
         * Список товаров конструктора акций в корзине
         */
        promotionBuilderBasket: t.map(BasketItemModel),
        /**
         * ИД активного конструктора акций
         */
        activePromotionBuilderModelId: t.maybeNull(t.string),
        mainProduct: t.maybeNull(BasketItemModel),
        callHistory: t.map(CallHistory),
        comments: t.map(OrderComment),
        communicationsQuality: t.map(CommunicationsQuality),
        loadBasket: t.optional(t.boolean, false),
        loadCallHistory: t.optional(t.boolean, false),
    })
    .actions((self) => ({
        setLoadBasket(loadBasket: boolean): void {
            self.loadBasket = loadBasket;
        },
        setLoadCallHistory(callHistory: boolean): void {
            self.loadCallHistory = callHistory;
        },
    }));


export interface IAdditionalParamsCurrentOrderModel extends Instance<typeof AdditionalParamsCurrentOrderModel> {}
export interface IAdditionalParamsCurrentOrderModelSnapshotIn extends SnapshotIn<typeof AdditionalParamsCurrentOrderModel> {}
export interface IAdditionalParamsCurrentOrderModelSnapshotOut extends SnapshotOut<typeof AdditionalParamsCurrentOrderModel> {}