import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface ISearchIconSvg {
    className?: string;
}


const SearchIconSvg: FunctionComponent<ISearchIconSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['SearchIconSvg', className])}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.4869 18.3183 14.3145 17.176 15.7618L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.7618 17.176C14.3145 18.3183 12.4869 19 10.5 19ZM15.1598 15.0317C15.1368 15.0509 15.1145 15.0713 15.0929 15.0929C15.0713 15.1145 15.0509 15.1368 15.0317 15.1598C13.8609 16.2986 12.2624 17 10.5 17C6.91015 17 4 14.0899 4 10.5C4 6.91015 6.91015 4 10.5 4C14.0899 4 17 6.91015 17 10.5C17 12.2624 16.2986 13.8609 15.1598 15.0317Z"
            fill="#C2C2C2"
        />
    </svg>
);


export default SearchIconSvg;
