import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const CurrencyModel = t
    .model('CurrencyModel', {
        id: t.number,
        code: t.string,
    });


export interface ICurrencyModel extends Instance<typeof CurrencyModel> {}
export interface ICurrencyModelSnapshotIn extends SnapshotIn<typeof CurrencyModel> {}
export interface ICurrencyModelSnapshotOut extends SnapshotOut<typeof CurrencyModel> {}
