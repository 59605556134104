import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import SipService from '@services/sip/SipService';
import NotificationSubscriptionService from '@services/subscription/NotificationSubscriptionService';
import OrderService from '@services/order/OrderService';

const NotificationSubscriptionServiceDI = (): any => [
    NotificationSubscriptionService,
    toFactory(
        [
            Store,
            OrderService,
            SipService,
        ],
        (
            store: Store,
            orderService: OrderService,
            sipService: SipService,
        ) => new NotificationSubscriptionService(
            store,
            orderService,
            sipService,
        ),
    ),
];


export default NotificationSubscriptionServiceDI;
