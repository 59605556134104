import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

import { Country } from '@models/mobx-state-tree/country.model';


export const CallCenter = t
    .model('CallCenter', {
        id: t.identifier,
        name: t.maybeNull(t.string),
        description: t.maybeNull(t.string),
        country: t.maybeNull(Country),
        communicationServerId: t.optional(t.integer, 0),
        checkBrowser: t.optional(t.boolean, false),
    });


export interface ICallCenterModel extends Instance<typeof CallCenter> {}
export interface ICallCenterModelSnapshotIn extends SnapshotIn<typeof CallCenter> {}
export interface ICallCenterModelSnapshotOut extends SnapshotOut<typeof CallCenter> {}
