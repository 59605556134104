import {
    IOperatorStatisticModel, IOperatorStatisticModelSnapshotIn,
    OperatorStatisticModel,
} from '@models/mobx-state-tree/operatorStatistic.model';
import { applySnapshot, Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import slice from 'lodash/slice';

import {
    startOfPrevPrevWeek, startOfPrevWeek, startOfCurrentWeek, currentMonth,
    endOfPrevPrevWeek, endOfPrevWeek, endOfCurrentWeek,
    getDaysBetweenDates, getFullFormatTime, prevMonth, prevPrevMonth,
} from '@components/main/billing-list-page/billing-list-hooks/utils';
import moment from 'moment';

const days = 14; // Количество дней отображенных в таблице расчетного листка;

export const BillingListModel = t

    .model('BillingListModel', {
        operatorStatistic: t.map(OperatorStatisticModel),
    })

    .views(((self) => ({
        get operatorStatisticList(): IOperatorStatisticModel[] {
            return [...self.operatorStatistic.values()];
        },
    })))

    .views(((self) => ({

        get prevPrevWeek(): IOperatorStatisticModel[] {
            const dates = getDaysBetweenDates(startOfPrevPrevWeek, endOfPrevPrevWeek, 'YYYY-MM-DD');
            return map(dates, (date) => filter(self.operatorStatisticList, (day) => day.date === date)[0]);
        },

        get prevWeek(): IOperatorStatisticModel[] {
            const dates = getDaysBetweenDates(startOfPrevWeek, endOfPrevWeek, 'YYYY-MM-DD');
            return map(dates, (date) => filter(self.operatorStatisticList, (day) => day.date === date)[0]);
        },

        get currentWeek(): IOperatorStatisticModel[] {
            const dates = getDaysBetweenDates(startOfCurrentWeek, endOfCurrentWeek, 'YYYY-MM-DD');
            return map(dates, (date) => filter(self.operatorStatisticList, (day) => day.date === date)[0]);
        },
    })))
    .views(((self) => ({
        get maxApprovement(): number {
            return reduce(self.operatorStatisticList, (r, day) => day.approvedCount > r ? r = day.approvedCount : r, 0);
        },
        get workedDays(): number {
            return self.operatorStatisticList.length;
        },
        get totalOrders(): number {
            return reduce(self.operatorStatisticList, (r, day) => day.ordersCount + r, 0);
        },
        get approvedOrders(): number {
            return reduce(self.operatorStatisticList, (r, day) => day.approvedCount + r, 0);
        },
    })))
    .views(((self) => ({
        get weeks(): IOperatorStatisticModel[][] {
            return map(self.prevPrevWeek, (day, index) => [self.prevPrevWeek[index], self.prevWeek[index],  self.currentWeek[index]]);
        },
        get months(): IOperatorStatisticModel[][] {
            const currentMonthData =  filter(self.operatorStatisticList, (day) => +day.date.split('-')[1] === +currentMonth);
            const prevMonthData =  filter(self.operatorStatisticList, (day) => +day.date.split('-')[1] === +prevMonth);
            const prevPrevMonthData =  filter(self.operatorStatisticList, (day) => +day.date.split('-')[1] === +prevPrevMonth);

            return [prevPrevMonthData, prevMonthData, currentMonthData];
        },
        get dataForTable(): IOperatorStatisticModel[] {
            return slice(self.operatorStatisticList, -days);
        },
    })))
    .views(((self) => ({
        get processedDataTable(): any[] {
            return map(self.dataForTable, (day) => ({ ...day,
                date: moment(day.date).format('DD.MM.YYYY'),
                avgCheckUSD: Math.floor(day.avgCheckUSD),
                onlineTime: moment.utc(moment.duration(day.onlineTime, 'seconds').as('milliseconds')).format('HH:mm:ss'),
            }));
        },
        get sumCallsTable(): number {
            return reduce(self.dataForTable, (sum, day) => sum + day.callsCount, 0);
        },
        get sumOrdersTable(): number {
            return reduce(self.dataForTable, (sum, day) => sum + day.ordersCount, 0);
        },
        get sumApprovesTable(): number {
            return reduce(self.dataForTable, (sum, day) => sum + day.approvedCount, 0);
        },
        get sumAvgCheckTable(): number {
            return Math.floor(reduce(self.dataForTable, (sum, day) => sum + day.avgCheckUSD, 0));
        },
        get sumOnlineTimeTable(): string {
            const sumOnlineTime: number = reduce(self.dataForTable, (sum, day) => sum + +day.onlineTime, 0);
            const time = getFullFormatTime(sumOnlineTime);
            return time;
        },
        get periodTable(): string {
            const endIndex = self.dataForTable.length - 1;
            const start = moment(self.dataForTable[0]?.date).format('DD.MM');
            const end = moment(self.dataForTable[endIndex]?.date).format('DD.MM');
            return `${start} - ${end}`;
        },
    })))
    .views(((self) => ({
        get weeksApproves(): number[] {
            const firstWeekApprove = reduce(self.prevPrevWeek, (acc, day) => day ? acc + day.approvedCount : acc, 0);
            const secondWeekApprove = reduce(self.prevWeek, (acc, day) => day ? acc + day.approvedCount : acc, 0);
            const thirdWeekApprove = reduce(self.currentWeek, (acc, day) => day ? acc + day.approvedCount : acc, 0);
            return [firstWeekApprove, secondWeekApprove, thirdWeekApprove];
        },
    })))
    .actions(((self) => ({
        setBillingList(operatorStatisticIn: Record<string, IOperatorStatisticModelSnapshotIn>): void {
            applySnapshot(self.operatorStatistic, operatorStatisticIn);
        },
    })));

export interface IBillingListModel extends Instance<typeof BillingListModel> {}
export interface IBillingListModelSnapshotIn extends SnapshotIn<typeof BillingListModel> {}
export interface IBillingListModelSnapshotOut extends SnapshotOut<typeof BillingListModel> {}
