export enum CurrentProductsTabs {
    CURRENT_ORDER = 'CURRENT_ORDER',
    SIMILAR_ORDER = 'SIMILAR_ORDER',
    ADD_FROM_CATALOG = 'ADD_FROM_CATALOG',
}

export interface IDataTab {
    text: string;
    state: CurrentProductsTabs;
    svg?: string;
}
