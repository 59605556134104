import React, { useEffect, useRef } from 'react';

import './resize-control.scss';
import getPropertyStyleFromHtmlElement from '@/utils/getPropertyStyleFromHtmlElement';
import { minEndX, minWidthBCallPanel } from '@core/constants/resize';

const ResizeControl = () => {

    const ref = useRef<HTMLDivElement>(null);
    const refGrab = useRef<HTMLButtonElement | null>(null);

    let resize: HTMLDivElement | null = null;
    let grab: HTMLButtonElement | null = null;

    let bCallPanel: HTMLDivElement | null = null;
    let orderPage: HTMLDivElement | null = null;
    let portalNotification: HTMLDivElement | null = null;

    const getElements = () => {
        resize = ref.current;
        grab = refGrab.current;
        bCallPanel = document.querySelector('.b-call-panel');
        orderPage = document.querySelector('.order-page');
        portalNotification = document.querySelector('#top-floating-notification-portal');
    };

    const stopPreventBehavior = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    function mouseMove(
        e:  MouseEvent,
        startX?: number,
        rightResize?: string,
        widthBCallPanel?: string,
    ) {

        stopPreventBehavior(e);
        const endX = e.clientX;

        if (endX < minEndX) {
            return;
        }

        const changedWidthBCallPanel = parseInt(widthBCallPanel!, 10) - endX + startX!;

        if (changedWidthBCallPanel < minWidthBCallPanel) {
            return;
        }

        const resize = ref.current;

        const move = parseInt(rightResize!, 10) - endX + startX!;

        resize!.style.right = `${move}px`;
        bCallPanel!.style.width = `${changedWidthBCallPanel}px`;
        orderPage!.style.paddingRight = `${changedWidthBCallPanel}px`;
        portalNotification!.style.paddingRight = `${changedWidthBCallPanel}px`;
    }

    const mouseUp = (event: any, mouseMoveFunction: any, mouseUpFunction: any) => {
        stopPreventBehavior(event);
        document.removeEventListener('mousemove', mouseMoveFunction);
        document.removeEventListener('mouseup', mouseUpFunction);
    };

    function mouseDown(this: HTMLButtonElement, e: MouseEvent): void  {
        stopPreventBehavior(e);
        const startX = e.clientX;

        const rightResize = getPropertyStyleFromHtmlElement(resize!, 'right');
        const widthBCallPanel = getPropertyStyleFromHtmlElement(bCallPanel!, 'width');

        function mouseMoveFunction(this: Document, event: MouseEvent) {
            mouseMove(event, startX, rightResize!, widthBCallPanel!);
        }
        function mouseUpFunction(this: Document, event: MouseEvent) {
            mouseUp(event, mouseMoveFunction, mouseUpFunction);
        }

        document.addEventListener('mousemove', mouseMoveFunction);
        document.addEventListener('mouseup', mouseUpFunction);

    }

    useEffect(() => {
        getElements();
        grab!.addEventListener('mousedown', mouseDown);

        return () => {
            grab!.removeEventListener('mousedown', mouseDown);
        };
    }, []);

    return (
        <div className="resize-control" ref={ref}>
            <button
                className="resize-control__grab"
                ref = {refGrab}
            ></button>
            <div className="resize-control__stick"></div>
        </div>
    );
};

export default ResizeControl;