import CryptoJS from 'crypto-js';
import { DECRIPTION_IV, DECRIPTION_KEY } from '@core/constants/decription-key-iv';

export const getDecription = (text: string | null):string | null => {

    if (!text || text.length === 0) {
        return null;
    }

    const key = CryptoJS.enc.Utf8.parse(DECRIPTION_KEY);
    const iv  = CryptoJS.enc.Utf8.parse(DECRIPTION_IV);

    const ciphertext = CryptoJS.enc.Base64.parse(text);    // Use Base64-Encoder.
    const encryptedCP = CryptoJS.lib.CipherParams.create({
        ciphertext: ciphertext,
        formatter: CryptoJS.format.OpenSSL,                                     // Optional, but required for encryptedCP.toString()
    });
    const decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv });

    return  decryptedWA.toString(CryptoJS.enc.Utf8) as string || null;

};