import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export enum BottomTabEnum {
    MENU = 'MENU',
    CALL = 'CALL',
    ORDER = 'ORDER',
    PROFILE = 'PROFILE',
}

const activeBottomTabOptions = [
    BottomTabEnum.ORDER,
    BottomTabEnum.CALL,
    BottomTabEnum.MENU,
    BottomTabEnum.PROFILE,
];

export const BottomMobileMenuUIStateMode = t
    .model('BottomMobileMenuUIStateMode', {
        activeBottomTab: t.optional(t.enumeration('ACTIVE_TAB', activeBottomTabOptions), BottomTabEnum.ORDER),
    })
    .actions((self) => ({
        setActiveTab: (tab: BottomTabEnum): void => {
            self.activeBottomTab = tab;
        },
    }));


export interface IBottomMobileMenuUIStateMode extends Instance<typeof BottomMobileMenuUIStateMode> {}
export interface IBottomMobileMenuUIStateModeSnapshotIn extends SnapshotIn<typeof BottomMobileMenuUIStateMode> {}
export interface IBottomMobileMenuUIStateModeSnapshotOut extends SnapshotOut<typeof BottomMobileMenuUIStateMode> {}
