import get from 'lodash/get';

import ApiBase from '@core/api';
import { getEnvOperatorStatisticEndpoint } from '@/env/requestEndpoints';
import { TOperatorStatistic } from '@api/operator-statistic-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}

interface IOperatorStatisticRequestArgs {
    /**
     * Начальная дата в формате Y-m-d "2021-01-01"
     */
    date_from: string;
    /**
     * Конечная дата в формате Y-m-d "2021-01-02"
     */
    date_to: string;
    /**
     * ID оператора
     */
    operator_id: number;
    /**
     * Временная зона "UTC"
     */
    timezone: string;
}

class OperatorStatisticApiService {
    @Inject apiBase: ApiBase;

    public async getOperatorStatistic(dateFrom: string, dateTo: string, operatorId: number, timezone: string): Promise<TOperatorStatistic[] | never> {

        const args: IOperatorStatisticRequestArgs = {
            date_from: dateFrom,
            date_to: dateTo,
            operator_id: operatorId,
            timezone: timezone,
        };

        const response = await this.apiBase.get<IWithResponse<TOperatorStatistic[]>>(getEnvOperatorStatisticEndpoint(), args);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }
}


export default OperatorStatisticApiService;
