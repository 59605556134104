import { toFactory } from 'react-ioc';

import DeliveriesService from '@services/DeliveriesService';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';


const DeliveriesServiceDI = (): any => [
    DeliveriesService,
    toFactory(
        [
            Store,
            OrderApiService,
            I18NService,
        ],
        (
            store: Store,
            orderApiService: OrderApiService,
            I18NService: I18NService,
        ) => new DeliveriesService(
            store,
            orderApiService,
            I18NService,
        ),
    ),
];


export default DeliveriesServiceDI;
