import UserAgentParser from 'ua-parser-js';

const CHROME_BROWSER_NAME = 'Chrome';

const isChromeBrowser = (): boolean => {
    const parser = new UserAgentParser();
    const browser = parser.getBrowser();

    return browser.name === CHROME_BROWSER_NAME;
};

export default isChromeBrowser;
