import React, { FunctionComponent, useCallback, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import currency from 'currency.js';

import CollapseSummary from '@UIElements/CollapseSummary/CollapseSummary';
import CollapseSummaryItems from '@UIElements/CollapseSummary/includes/CollapseSummaryItems';
import ScriptCallTabs from '@components/main/order-page/order-scripts/script-call-tabs/ScriptCallTabs';
import ScriptsViewComponent from '@components/main/order-page/order-scripts/scripts-view-component/scripts-view-component';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import { IChangeStatusButtonModel } from '@models/mobx-state-tree/changeStatusButtons.model';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import ChangeOrderButtons
    from '@components/main/order-page/call-panel/call-widget/ChangeOrderButtons/ChangeOrderButtons';
import CallButtonAndPhone
    from '@components/main/order-page/call-panel/call-widget/call-button-and-phone/CallButtonAndPhone';
import Button from '@UIElements/Button';
import ModalService from '@core/services/ModalService';
import ChangeOrderButtonsInModal
    from '@components/main/order-page/call-panel/call-widget/ChangeOrderButtonsInModal/ChangeOrderButtonsInModal';
import SipMessageBoard from '@components/main/order-page/call-panel/call-widget/sip-message-board/SipMessageBoard';
import PanelUserLabel from '@components/main/order-page/call-panel/call-widget/PanelUserLabel/PanelUserLabel';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import ProductsService from '@services/order/ProductsService';
import { DeliveriesService } from '@/app/services';

import './includes/CallWidget.scss';
import useQueriesHook from '@components/hooks/useQueriesHook';
import { UNEDITABLE } from '@core/constants/urlSearchParams';
import VerifierService from '@services/VerifierService';
import { Locations } from '@core/models/locations';
import { useNavigate } from 'react-router-dom';


interface ICallWidgetProps {
    className?: string;
    status?: string;
    buttons?: IChangeStatusButtonModel[];
}

export enum EnumTypeOrder {
    CREATED = 2,
}

const CallWidget: FunctionComponent<ICallWidgetProps> = ({
    className,
}) => {
    const [
        {
            currentOrder: {
                id: orderId,
                totalCostOfSelectedProducts,
                mainItemsInBasket,
                giftItemsInBasket,
                promoItemsInBasket,
                currency: orderCurrency,
                additionalParamsCurrentOrder: {
                    activePromotionBuilderModelId,
                },
                mainItemBasketPromotionBuilderArray,
                allGiftsBasketPromotionBuilder,
                promoItemsBasketPromotionBuilder,
                form : {
                    extraPrice,
                },
                isEmptyCurrentOrder,
                showScript,
                orderType,
                postProcessing,
                isACreatedOrder,
                selectedStatus,
            },
            currentUser: {
                mode,
                setIsReady,
                setUnreadyAfterSave,
                unreadyAfterSave,
            },
            inactivityTimer: {
                modalTimerActive,
            },
            clearCurrentOrder,
        },
        { t },
        modalService,
        {
            isConditionForPromoCarts,
            isConditionForPromoCartsPromotionBuilder,
        },
        {
            selectedDeliveryPrice,
        },
        {
            unblockVerifier,
        },
    ] = useInstances(
        Store,
        I18NService,
        ModalService,
        ProductsService,
        DeliveriesService,
        VerifierService,
    );

    const onCombinedStatusButtonClick = useCallback(() => {
        void modalService.showModal(ChangeOrderButtonsInModal);
    }, [modalService.showModal]);

    /**
     * В прогрессивном режиме не показывать компонент CollapseSummary если ордер пустой;
     */

    const showCollapseSummery = mode !== UserModeEnum.PROGRESSIVE && !isEmptyCurrentOrder;

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { paramsValues } = useQueriesHook([`${UNEDITABLE}`]);
    const { uneditable } = paramsValues;

    const editable = uneditable === null;

    const handleCloseOrder = async (): Promise<void> => {
        const result = await unblockVerifier(orderId);
        if (result) {
            navigate(Locations.MAIN);
            if (unreadyAfterSave) {
                setIsReady(false);
                setUnreadyAfterSave(false);
            }
            clearCurrentOrder();
        }
    };

    return (
        <div className={cn('b-call-widget', className)}>
            <SipMessageBoard />
            <div className="b-call-widget__block">
                {showCollapseSummery && !activePromotionBuilderModelId && (
                    <CollapseSummary
                        title={`${t('Сумма заказа', 'Order summary')}:`}
                        sum={currency(totalCostOfSelectedProducts).add(selectedDeliveryPrice).add(Number(extraPrice)).value}
                        currency={orderCurrency}
                        summaryBody={(
                            <div className="summary-body">
                                <CollapseSummaryItems
                                    cartMain={mainItemsInBasket.filter((item: IBasketItemModel) => item.quantity > 0 && !item.removed)}
                                    cartGifts={giftItemsInBasket.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed))}
                                    cartPromo={promoItemsInBasket.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed))}
                                    isConditionForPromoCarts={isConditionForPromoCarts}
                                />
                                <div className="summary-row">
                                    <div className="item">{t('Заказ', 'Order')}</div>
                                    <div className="item">{totalCostOfSelectedProducts}</div>
                                </div>
                                <div className="summary-row">
                                    <div className="item">{t('Доставка', 'Delivery')}</div>
                                    <div className="item">{selectedDeliveryPrice}</div>
                                </div>
                                <div className="summary-row">
                                    <div className="item">{t('Добавочная стоимость', 'Extra price')}</div>
                                    <div className="item">{Number(extraPrice)}</div>
                                </div>
                            </div>
                        )}
                    />
                )}

                {showCollapseSummery && activePromotionBuilderModelId && (
                    <CollapseSummary
                        title={`${t('Сумма заказа', 'Order summary')}:`}
                        sum={currency(totalCostOfSelectedProducts).add(selectedDeliveryPrice).add(Number(extraPrice)).value}
                        currency={orderCurrency}
                        summaryBody={(
                            <div className="summary-body">
                                <CollapseSummaryItems
                                    cartMain={mainItemBasketPromotionBuilderArray.filter((item: IBasketItemModel) => item.quantity > 0 && !item.removed)}
                                    cartGifts={allGiftsBasketPromotionBuilder.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed))}
                                    cartPromo={promoItemsBasketPromotionBuilder.filter(((item: IBasketItemModel) => item.quantity > 0 && !item.removed))}
                                    isConditionForPromoCarts={isConditionForPromoCartsPromotionBuilder}
                                />
                                <div className="summary-row">
                                    <div className="item">{t('Заказ', 'Order')}</div>
                                    <div className="item">{totalCostOfSelectedProducts}</div>
                                </div>
                                <div className="summary-row">
                                    <div className="item">{t('Доставка', 'Delivery')}</div>
                                    <div className="item">{selectedDeliveryPrice}</div>
                                </div>
                                <div className="summary-row">
                                    <div className="item">{t('Добавочная стоимость', 'Extra price')}</div>
                                    <div className="item">{Number(extraPrice)}</div>
                                </div>
                            </div>
                        )}
                    />
                )}
                {orderType.id !== EnumTypeOrder.CREATED && !isEmptyCurrentOrder &&
                    <div className="b-call-widget__scripts">
                        <ScriptCallTabs setLoading={setLoading}/>
                        {showScript && <ScriptsViewComponent loading={loading}/>}
                    </div>
                }

                {!showScript && <CallButtonAndPhone />}
                {!showScript && <div className="b-call-widget__block__padding">
                    <div className="call-footer">
                        {(!postProcessing || isACreatedOrder) && editable &&
                            <>
                                <div className="change-order-buttons">
                                    <ChangeOrderButtons />
                                </div>
                                <div className="show-order-buttons-in-modal">
                                    <Button
                                        className="button"
                                        variant="7"
                                        size="1"
                                        onClick={onCombinedStatusButtonClick}
                                        text={t('Изменить статус', 'Change status')}
                                        width="100%"
                                    />
                                </div>
                            </>
                        }
                        {!editable &&
                        <div
                            className="approve-btn-wrapper"
                            data-cy="approve_button"
                        >
                            <Button
                                className={'button status'}
                                variant={2}
                                onClick={handleCloseOrder}
                                size="2"
                                text={t('Закрыть заказ', 'Close order')}
                                width="100%"
                            />
                        </div>
                        }
                        {postProcessing && selectedStatus && !modalTimerActive &&
                        <div className="change-order-buttons">
                            <ChangeOrderButtons
                                showOnlyOneStatusButton = {true}
                            />
                        </div>
                        }
                        <PanelUserLabel />
                    </div>
                </div>}
            </div>
        </div>
    );
};


export default observer(CallWidget);
