import React, {
    FunctionComponent,
    useCallback, useMemo,
} from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import CoinsSVG from '@/app/components/resources/SVG/Coins/CoinsSVG';
import LockSVG from '@/app/components/resources/SVG/Lock/LockSVG';
import I18NService from '@services/I18NService';
import { IProductItemModel } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import BasketService from '@/app/services/order/BasketService';
import ProductsService from '@/app/services/order/ProductsService';
import Button from '@/app/ui-elements/Button';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import { Store } from '@store/store';

import ProductImage from '../order-table/includes/ProductImage';

import '../order-table/includes/ProductItemToAdd.scss';

interface ISimpleGiftItemToAddProps {
    className?: string;
    catalogItem: IProductItemModel;
    disabled?: boolean;
    dataCyValue?: number;
}

const SimpleGiftItemToAdd: FunctionComponent<ISimpleGiftItemToAddProps> = ({
    className,
    catalogItem,
    disabled = false,
    dataCyValue,
}): JSX.Element => {
    const [
        {
            currentOrder: {
                prepareLabelTranslation,
                limitAddedOptionalGifts,
                form: {
                    maxGiftsCount,
                },
            },
        },
        { t },
        productsService,
        basketService,
    ] = useInstances(
        Store,
        I18NService,
        ProductsService,
        BasketService,
    );

    const onClick = useCallback(() => {
        if (!disabled) {
            basketService.addOptionalGiftOrPromoFromPromotionBuilderToBasket(catalogItem);
        }
    }, [disabled]);

    const preparedTranslationForParentItem = useMemo<string>(
        () => prepareLabelTranslation(catalogItem.type, catalogItem.subType, false),
        [catalogItem.type, catalogItem.subType, t],
    );

    const isPossibleToAddToBasket = () => productsService.abilityToAddAnOptionalGiftToPromotionBuilderBasket(catalogItem);

    const titleForTooltip = useMemo<string | null>(
        () => {
            if (limitAddedOptionalGifts) {
                return t(
                    'В настройках этой акции можно добавлять не больше {{maxGiftsCount}} номенклатур подарков на выбор.',
                    'You can add no more than {{maxGiftsCount}} nomenclatures of gifts to choose from in the settings for this promotion.',
                    {
                        maxGiftsCount: maxGiftsCount,
                    },
                );
            }

            if (!isPossibleToAddToBasket()) {
                return t(
                    'Вы уже добавили этот товар в корзину. Увеличьте его количество.',
                    'You have already added this product to your cart. Increase its quantity.',
                );
            }

            return null;
        },
        [t, isPossibleToAddToBasket, limitAddedOptionalGifts, maxGiftsCount],
    );

    return (
        <div
            className={cn([
                className,
                'ProductItemToAdd__wrapper',
                disabled && 'disabled',
            ])}
        >
            {!isPossibleToAddToBasket() && (
                <div className="ProductBlur">
                    <div className="lock-container">
                        <LockSVG />
                    </div>
                </div>
            )}
            <div className="ProductItemToAdd__product_image__wrapper">
                <ProductImage
                    className="ProductItemToAdd__product_image"
                    productName={catalogItem.name}
                    imageLink={catalogItem.image}
                    productType={catalogItem.type}
                />
            </div>
            <span className="ProductItemToAdd__product_type">
                <ProductLabel
                    productType={null}
                    productSubType={catalogItem.subType}
                    isPromotion={false}
                    text={preparedTranslationForParentItem}
                />
            </span>
            <div className="ProductItemToAdd__product_name" title={catalogItem.name}>
                {catalogItem.name}
            </div>
            <div className="ProductItemToAdd__product_price_wrapper">
                <CoinsSVG />
                <div className="product_price">{t('Бесплатно', 'Free')}</div>
            </div>
            {isPossibleToAddToBasket() && !limitAddedOptionalGifts && (
                <Button
                    variant="3"
                    size="0"
                    text={t('Добавить', 'Add')}
                    width="128px"
                    onClick={onClick}
                    dataCyValue={`add_${catalogItem.subType}_${dataCyValue}`}
                />
            )}
            {!isPossibleToAddToBasket() && !limitAddedOptionalGifts && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={t('Добавлено', 'Added')}
                    titleForTooltip={titleForTooltip}
                    dataCyValue={`added_${catalogItem.subType}_${dataCyValue}`}
                />
            )}
            {limitAddedOptionalGifts && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={t('Недоступно', 'Not available')}
                    titleForTooltip={titleForTooltip}
                    dataCyValue={`added_${catalogItem.subType}_${dataCyValue}`}
                />
            )}
        </div>
    );
};


export default observer(SimpleGiftItemToAdd);
