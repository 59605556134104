import { toFactory } from 'react-ioc';

import UserApiService from '@api/user-api-service';
import TimezoneService from '@services/TimezoneService';
import SupportApiService from '@api/support-api-service';


const TimezoneServiceDI = (): any => [
    TimezoneService,
    toFactory(
        [
            UserApiService,
            SupportApiService,
        ],
        (
            userApiService: UserApiService,
            supportApiService: SupportApiService,
        ) => new TimezoneService(
            userApiService,
            supportApiService,
        ),
    ),
];


export default TimezoneServiceDI;