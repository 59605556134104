import find from 'lodash/find';
import filter from 'lodash/filter';

import {
    IAnyModelType,
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';

import { DEFAULT_DELIVERY_CONDITIONS_PROMOTION_BUILDER } from '@core/constants/defaultStoreItems';
import {
    IProductItemModel, ProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum,
} from './ProductItem.model';
import { DeliveryConditions } from './promotionBuilderDeliveryConditions.model';


export const PromotionBuilder = t
    .model('PromotionBuilder', {
        id: t.identifier, // генерируемый идентификатор uuid\v4
        promotionBuilderId: t.number,
        name: t.string,
        description: t.string,
        /**
         * TODO: ProductItemModel должен позволять определять эти типы товаров:
         *  - Они должны принадлежать promotionBuilder элементу
         *  - Они должны иметь соответствующий тип (подарок, промо, основной и т.д.)
         *  - Они должны иметь соответствующую характеристику:
         *      - обязательный/нет для подарка
         *      - если обязательный, то количество товара должно быть указано
         *      - если нет, то сколько можно максимум выбрать?
         * Список товаров для К.А. (CATALOG VIEW):
         * - основной товар (всегда должен быть)
         * - подарки обязательные (опционально)
         * - подарки необязательные (опционально)
         * - промо-товары (опционально)
         */
        products: t.map(t.reference(t.late((): IAnyModelType => ProductItemModel))),
        mainProductModelId: t.string,
        /**
         * Количество основного товара,
         * с которого становятся доступными промо товары
         */
        promotionCountActivate: t.number,
        /**
         * Если настройка включена, то у нас будет линк на 1 промо товар, \
         * его мы будем должны добавить сразу в корзину и запретить удалять или изменять
         * Если выключена, то оператор сможет решать сам - выбрать его или нет.
         */
        allPromoMandatory: t.optional(t.boolean, false),
        deliveryConditions: t.optional(DeliveryConditions, DEFAULT_DELIVERY_CONDITIONS_PROMOTION_BUILDER),
        /**
         * Максимальное количество номенклатур подарков. 0 - неограниченное количество
         */
        maxGiftsCount: t.integer,
    })
    .views((self) => ({
        get _products(): IProductItemModel[] {
            return [...self.products.values()] as IProductItemModel[];
        },
    }))
    .views((self) => ({
        get mainItemForPromotionBuilder(): IProductItemModel | undefined {
            return find<IProductItemModel>(
                self._products,
                {
                    type: ProductItemTypeEnum.REGULAR,
                    subType: ProductItemSubTypeEnum.MAIN,
                },
            );
        },

        get promoProducts(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self._products,
                {
                    type: ProductItemTypeEnum.REGULAR,
                    subType: ProductItemSubTypeEnum.PROMO,
                },
            );
        },

        get optionalGifts(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self._products,
                {
                    type: ProductItemTypeEnum.REGULAR,
                    subType: ProductItemSubTypeEnum.GIFT,
                    mandatoryGift: false,
                },
            );
        },

        get mandatoryGifts(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self._products,
                {
                    type: ProductItemTypeEnum.REGULAR,
                    subType: ProductItemSubTypeEnum.GIFT,
                    mandatoryGift: true,
                },
            );
        },
    }))
    .views((self) => ({
        /**
         * Отсутствующие ПНВ на складе
         */
        get missingOptionalGifts(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.optionalGifts,
                {
                    productIsInStock: false,
                },
            );
        },

        /**
         * Имеющиеся ПНВ на складе
         */
        get presentOptionalGifts(): IProductItemModel[] {
            return filter<IProductItemModel>(
                self.optionalGifts,
                {
                    productIsInStock: true,
                },
            );
        },
    }));


export interface IPromotionBuilderModel extends Instance<typeof PromotionBuilder> {}
export interface IPromotionBuilderSnapshotIn extends SnapshotIn<typeof PromotionBuilder> {}
export interface IPromotionBuilderSnapshotOut extends SnapshotOut<typeof PromotionBuilder> {}
