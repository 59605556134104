import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import ClientServiceModeEventSubscriptionService from '@services/subscription/ClientServiceModeEventSubscriptionService';
import SipService from '@services/sip/SipService';
import CustomerService from '@services/CustomerService';
import HistoryService from '@services/HistoryService';
import UserService from '@services/UserService';
import { CustomerModeStore } from '@store/customerModeStore';


const ClientServiceModeEventSubscriptionServiceDI = (): any => [
    ClientServiceModeEventSubscriptionService,
    toFactory(
        [
            Store,
            SipService,
            CustomerService,
            HistoryService,
            CustomerModeStore,
            UserService,
        ],
        (
            store: Store,
            sipService: SipService,
            customerService: CustomerService,
            historyService: HistoryService,
            customerModeStore: CustomerModeStore,
            userService: UserService,
        ) => new ClientServiceModeEventSubscriptionService(
            store,
            sipService,
            customerService,
            historyService,
            customerModeStore,
            userService,
        ),
    ),
];


export default ClientServiceModeEventSubscriptionServiceDI;
