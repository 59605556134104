import { toFactory } from 'react-ioc';

import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import LocalStorageService from '@core/services/LocalStorageService';


const InactivityOperatorStorageServiceDI = (): any => [
    InactivityOperatorStorageService,
    toFactory(
        [
            LocalStorageService,
        ],
        (
            localStorageService: LocalStorageService,
        ) => new InactivityOperatorStorageService(
            localStorageService,
        ),
    ),
];


export default InactivityOperatorStorageServiceDI;
