import React, {
    FunctionComponent, useCallback, useRef, useState, useMemo, useEffect,
} from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import IcCheckSvg from '@UIElements/CladAutofill/includes/IcCheckSvg';
import IcDropDownSvg from '@UIElements/CountrySelect/includes/IcDropDownSvg';
import Popup from '@UIElements/Popup/Popup';
import useOnClickOutside from 'use-onclickoutside';
import ClearSelectionButton from '@UIElements/InputAutocomplete/ClearSelectionButton';

import { ISelectedValue, ISelectProps, SELECT_DEFAULT } from '@UIElements/Select/models';

import './includes/Select.scss';


const Select: FunctionComponent<ISelectProps> = (props): JSX.Element => {
    const {
        onSelect,
        placeholder = '',
        values = [],
        className = '',
        width,
        height = '48px',
        error = '',
        withoutCheckIcon = false,
        disabled = false,
        isAClosedList,
        dataCyValue = null,
        key,
        showClearSelectedButton = false,
        clearSelectedButton = () => {},
        id = '',
        keys,
        setKey,
    } = props;

    const label = !props.label ? '' : props.label;
    const selected = !props.selected ? SELECT_DEFAULT : props.selected;

    const isPlaceholder = placeholder.length > 0 && isEmpty(selected.value);

    const selectStyles: Record<string, string> = { '--select-height': height, ...(width ? { '--select-width': width } : null) };

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    const wrapperRef = useRef(null);

    const selectClassesWrapper = useMemo<string>(
        (): string => cn(
            'select__wrapper e-select',
            className,
            { error },
            { selected: selected && selected.label },
            { closed: !isMenuOpened },
            { opened: isMenuOpened },
            disabled && 'disabled',
        ),
        [className, error, selected, isMenuOpened, disabled],
    );

    const selectHandlerClasses = useMemo<string>(
        (): string => cn(
            'handler',
            { placeholder: isPlaceholder },
            { selected: selected && selected.label },
            { closed: !isMenuOpened },
            { opened: isMenuOpened },
            !withoutCheckIcon && 'add-more-padding',
        ),
        [isPlaceholder, selected, isMenuOpened],
    );

    const upArrow = useMemo<string>(
        (): string => (isMenuOpened ? 'arrow--up' : ''),
        [isMenuOpened],
    );

    const onClickOutside = (): void => {
        setIsMenuOpened(false);
    };

    useOnClickOutside(wrapperRef, onClickOutside);

    const selectItem = (selected: ISelectedValue<any>): void => {
        setIsMenuOpened(false);
        onSelect(selected);
    };

    const select = useCallback<(selected: ISelectedValue<any>) => void>(selectItem, [selected]);

    const toggleMenu = useCallback<() => void>((): void => {
        if (disabled && !isAClosedList) {
            return;
        }

        setIsMenuOpened((prev: boolean) => !prev);
    }, [isMenuOpened, disabled, isAClosedList]);

    useEffect(
        () => {
            if (isAClosedList && isMenuOpened) {
                toggleMenu();
            }
        },
        [isAClosedList, isMenuOpened],
    );

    return (
        <div id={id} ref={wrapperRef} className={selectClassesWrapper} style={selectStyles} key={key}>
            {label.length > 0 && (
                <div className="input-label">
                    <div className="input-label-text">{label}</div>
                </div>
            )}
            {error.length > 0 && (
                <div className="input-error">{error}</div>
            )}
            {selected && !isEmpty(selected.value) && showClearSelectedButton &&
            <ClearSelectionButton className="clear-selection" onClick={clearSelectedButton} />}
            {!withoutCheckIcon && selected && !isEmpty(selected.value) && (
                <IcCheckSvg
                    className={cn([
                        'checkIcon',
                        upArrow,
                    ])}
                    onClick={toggleMenu}
                />
            )}
            {selected && isEmpty(selected.value) && (
                <IcDropDownSvg
                    className={cn([
                        'arrow',
                        upArrow,
                    ])}
                    onClick={toggleMenu}
                />
            )}
            <div
                className={selectHandlerClasses}
                onClick={toggleMenu}
                data-cy={dataCyValue}
            >
                {isPlaceholder ? placeholder : selected?.label}
            </div>
            <Popup
                className="select-popup"
                isActive={isMenuOpened}
                width={width}
            >
                <ul className="values">
                    {
                        values.map((value: ISelectedValue<any>, idx: number) => (
                            <li
                                key={`${value.label}-${value}-${idx}`}
                                onClick={() => {
                                    select(value);
                                    if (id === 'customer_gender' && setKey && !!keys?.length) {
                                        setKey(keys[idx]);
                                    }
                                }}
                            >
                                {value.label}
                            </li>
                        ))
                    }
                </ul>
            </Popup>
        </div>
    );
};


export default Select;
