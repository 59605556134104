import { toFactory } from 'react-ioc';

import CommunicationQualityApiService from '@api/communication-quality-api-service';
import OrderService from '@services/order/OrderService';
import { Store } from '@store/store';
import DeliveriesService from '@services/DeliveriesService';
import UserService from '@services/UserService';
import ModalService from '@core/services/ModalService';
import OrderUpdateService from '@services/order/OrderUpdateService';
import OrderFetchService from '@services/order/OrderFetchService';
import OrderApiService from '@api/order-api-service';
import I18NService from '@services/I18NService';
import NotificationApiService from '@api/notification-api-service';
import DevicesService from '@core/services/DevicesService';
import KibanaService from '@services/KibanaService';
import UserApiService from '@api/user-api-service';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import CookieService from '@core/services/CookiesService';
import GeoNegociosMapService from '@services/GeoNegociosMapService';
import LocalStorageService from '@core/services/LocalStorageService';
import VerifierService from '@services/VerifierService';


const OrderServiceDI = (): any => [
    OrderService,
    toFactory(
        [
            Store,
            DeliveriesService,
            UserService,
            ModalService,
            OrderUpdateService,
            OrderFetchService,
            CommunicationQualityApiService,
            OrderApiService,
            I18NService,
            NotificationApiService,
            LocalStorageService,
            DevicesService,
            KibanaService,
            UserApiService,
            InternetSpeedMetricService,
            CookieService,
            GeoNegociosMapService,
            VerifierService,
        ],
        (
            store: Store,
            deliveriesService: DeliveriesService,
            userService: UserService,
            modalService: ModalService,
            orderUpdateService: OrderUpdateService,
            orderFetchService: OrderFetchService,
            communicationQualityApiService: CommunicationQualityApiService,
            orderApiService: OrderApiService,
            I18NService: I18NService,
            notificationApiService: NotificationApiService,
            localStorageService: LocalStorageService,
            devicesService: DevicesService,
            kibanaService: KibanaService,
            userApiService: UserApiService,
            internetSpeedMetricService: InternetSpeedMetricService,
            cookieService: CookieService,
            geoNegociosMapService: GeoNegociosMapService,
            verifierService: VerifierService,
        ) => new OrderService(
            store,
            deliveriesService,
            userService,
            modalService,
            orderUpdateService,
            orderFetchService,
            communicationQualityApiService,
            orderApiService,
            I18NService,
            notificationApiService,
            localStorageService,
            devicesService,
            kibanaService,
            cookieService,
            internetSpeedMetricService,
            userApiService,
            geoNegociosMapService,
            verifierService,
        ),
    ),
];


export default OrderServiceDI;
