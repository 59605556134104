import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcApproveStatusSvg {
    className?: string;
}


const IcApproveStatusSvg: FunctionComponent<IIcApproveStatusSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['IcApproveStatusSvg', className])}
        {...props}
        width="24" // 12
        height="24" // 12
        viewBox="0 0 24 24" // 0 0 12 12
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
            fill="#34C759"
        />
        <path
            d="M16.4525 8.5625L14.5325 6.6425C14.1825 6.2925 13.6125 6.2925 13.2625 6.6425C12.9125 6.9925 12.9125 7.5625 13.2625 7.9125L15.7325 10.3825C16.1225 10.7725 16.7525 10.7725 17.1425 10.3825L22.9925 4.5325C23.3425 4.1825 23.3425 3.6125 22.9925 3.2625C22.6425 2.9125 22.0725 2.9125 21.7225 3.2625L16.4525 8.5625Z"
            fill="#34C759"
        />
    </svg>

);


export default IcApproveStatusSvg;
