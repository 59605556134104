import {
    action, computed, transaction,
} from 'mobx';
import { sprintf } from 'sprintf-js';

import CommunicationQualityApiService from '@api/communication-quality-api-service';
import OrderApiService from '@api/order-api-service';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import DateOfRecall from '@components/main/order-page/modal-windows/date-of-recall';
import SubStatuses from '@components/main/order-page/modal-windows/sub-statuses';
import { AttrsKey } from '@interfaces/form.interface';
import DeliveriesService from '@services/DeliveriesService';
import OrderFetchService from '@services/order/OrderFetchService';
import UserService from '@services/UserService';
import OrderUpdateService from '@services/order/OrderUpdateService';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
import { Store } from '@store/store';
import { IFormModel } from '@models/mobx-state-tree/form.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import NotificationApiService, { IGetNotificationData, INotificationData } from '@api/notification-api-service';
import { ReasonsForEndingConversation, SipHttpErrorCode } from '@services/sip/models';
import { ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { IOperatorInactivityNotificationTimersModel } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import { ConfirmModalAction, ConfirmModalType } from '@core/models/ModalWindow';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import XHR400Error from '@core/error/XHR400Error';
import DevicesService from '@core/services/DevicesService';
import KibanaService from '@services/KibanaService';
import SafariEditAccessError from '@core/error/SafariEditAccessError';
import isShouldSendLogToKibana from '@/env/isShouldSendLogToKibana';
import {
    IReplaceTransactionModel,
    ReplaceTransactionStatusEnum,
    ReplaceTransactionTypeEnum,
} from '@models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import { STOCK_SWAP_UNIT } from '@core/constants/attributes';
import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';
import CookieService from '@core/services/CookiesService';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import UserApiService from '@api/user-api-service';
import OrderBase from '@services/order/OrderBase';
import GeoNegociosMapService from '@services/GeoNegociosMapService';
import LocalStorageService from '@core/services/LocalStorageService';
import dateConversionToSave from '@core/helpers/dateConversionToSave';
import VerifierService from '@services/VerifierService';

const ORDER_POLLING_INTERVAL = 2000;

export interface GeoCodingSideEffectResult {
    shouldStopSaveEvent: boolean;
}

export enum AddModeEnum {
    main = 'main',
    gift = 'gift',
    promo = 'promo',
    sets = 'sets',
    PROMOTIONS = 'PROMOTIONS',
    ALL = 'ALL',
}


class OrderService extends OrderBase {
    private _pollingOrderTimeout: number;

    private _sipCode: SipHttpErrorCode;

    constructor(
        private readonly store: Store,
        private readonly deliveriesService: DeliveriesService,
        private readonly userService: UserService,
        private readonly modalService: ModalService,
        private readonly orderUpdateService: OrderUpdateService,
        private readonly orderFetchService: OrderFetchService,
        private readonly communicationQualityApiService: CommunicationQualityApiService,
        private readonly orderApiService: OrderApiService,
        private readonly I18NService: I18NService,
        private readonly notificationApiService: NotificationApiService,
        private readonly localStorageService: LocalStorageService,
        private readonly _devicesService: DevicesService,
        private readonly _kibanaService: KibanaService,
        private readonly _cookieService: CookieService,
        private readonly _internetSpeedMetricService: InternetSpeedMetricService,
        private readonly _userApiService: UserApiService,
        private readonly _geoNegociosMapService: GeoNegociosMapService,
        private readonly _verifierService: VerifierService,
    ) {
        super();
    }

    private get _t(): customI18NTFunction {
        return this.I18NService.t;
    }

    private get callHistoryEvent(): ICallHistoryEventModel | null {
        return this.currentOrder.lastCallHistoryEvent;
    }

    public setSipCode(code: SipHttpErrorCode): void {
        this._sipCode = code;
    }

    private get getSipCode(): SipHttpErrorCode {
        return this._sipCode;
    }

    @computed
    get currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    @computed
    get currentUser(): IUserModel {
        return this.store.currentUser;
    }

    @computed
    get inactivityTimer(): IOperatorInactivityNotificationTimersModel {
        return this.store.inactivityTimer;
    }

    @computed
    get currentForm(): IFormModel {
        return this.currentOrder.form;
    }

    @computed
    get paymentCard(): boolean {
        return Boolean(this.currentOrder.paymentCard);
    }

    @computed
    get dateOfRecall(): Date | null {
        return this.currentOrder.dateOfRecall;
    }

    @computed
    get unreadyAfterSave(): boolean {
        return this.currentUser.unreadyAfterSave;
    }

    @computed
    get customerAddress(): string {
        const customerAddress = this.currentForm.addressAttributes.get(AttrsKey.CUSTOMER_ADDRESS);

        if (customerAddress) {
            const { fieldValue = '' } = customerAddress;

            return fieldValue;
        }

        return '';
    }

    private pendingOrderTimeout = (): Promise<void> => new Promise(((resolve): void => {
        this._pollingOrderTimeout = setTimeout(resolve, ORDER_POLLING_INTERVAL);
    }));

    private async _releaseOrder(orderId: number): Promise<void> {
        try {
            await this.orderApiService.releaseOrder({ orderId });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
    }

    public async releaseOrderAutoCallAfterReload(): Promise<void> {
        const orderId = this.localStorageService.getItem('orderId');

        if (orderId) {
            await this.orderApiService.releaseOrder({ orderId });
            this.localStorageService.removeItem('orderId');
        }
    }

    private async _orderProcessingErrors(error: Error): Promise<void> {
        // Если ошибки произошли на этапе создания каталога и корзины из данных заказа
        if (OrderService.theseAreErrorsFromCatalogAndBasketPrepareService(error)) {

            const { orderId, problemItems, message, problemSetId } = error as IBasicOrderPrepareCatalogAndBasketError;

            if (problemSetId) {

                error.message = OrderService.addProblematicProductsAndSetIdToErrorMessage(message, problemItems, problemSetId);

                // eslint-disable-next-line no-console
                console.warn(error);
            } else {

                error.message = OrderService.addProblematicProductsToErrorMessage(message, problemItems);

                // eslint-disable-next-line no-console
                console.warn(error);
            }

            this.stopPollingOrder();

            if (isShouldSendLogToKibana()) {
                await this._kibanaService.sendLog(error, orderId);
            }

            await this.modalService.showConfirmModal(
                this._t(
                    'Заказ {{orderId}} имеет неправильную конфигурацию в части настройки товаров. Работа с этим заказом невозможна. Подтвердите получение другого заказа.',
                    'Order {{orderId}} has an incorrect configuration in terms of product settings. It is not possible to work with this order. Press ok to receive another order.',
                    { orderId },
                ),
                ConfirmModalType.Yes,
                10000,
                ConfirmModalAction.Yes,
            );

            if (this.store.currentUser.isVerificator) {
                await this._verifierService.unblockVerifier(orderId);
            } else {
                await this._releaseOrder(orderId);
            }
        }

        // eslint-disable-next-line no-console
        console.error(error);
    }

    private pendingOrder = async (): Promise<boolean> => {
        const { isReady } = this.currentUser;
        const { isEmptyCurrentOrder } = this.currentOrder;

        if (this.currentUser.selectedAuxStatus && !this.currentUser.selectedAuxStatus?.orderGet) {
            return false;
        }

        if (isEmptyCurrentOrder && isReady) {
            try {
                const { wasFetched, orderRes } = await this.orderFetchService.fetchOrder();

                if (wasFetched) {
                    // Получили новый заказ, сразу переполучим список доставки
                    await this.deliveriesService.fetchDeliveries(orderRes);
                }
            } catch (e) {
                await this._orderProcessingErrors(e);
                this.store.clearCurrentOrder();
            }
        }

        return !isEmptyCurrentOrder;
    };

    private async showModalWarningWindow(): Promise<boolean> {
        const confirmText = (status: string): string => this._t(
            'Вы уверены, что хотите изменить статус на {{statusName}}?',
            'Are you sure you want to change the status to {{statusName}}?',
            { statusName: status },
        );

        let text: string;
        let status: { id: number | null; name: string } | undefined;
        let statusName: string;

        if (this.currentOrder.selectedStatus !== OrderStatusesEnum.CREATED) {
            status = this.currentForm.statuses.get(String(this.currentOrder.selectedStatus));
            if (status) {
                statusName = this._t(status.name);
            } else {
                statusName = 'Unknown';
            }
        } else {
            statusName = 'CREATED';
        }

        switch (this.currentOrder.selectedStatus) {
            case OrderStatusesEnum.APPROVE: {
                text = confirmText(statusName);
                break;
            }

            case OrderStatusesEnum.RECALL:
            case OrderStatusesEnum.NO_ANSWER:
            case OrderStatusesEnum.REJECT:
            case OrderStatusesEnum.TRASH:
            case OrderStatusesEnum.CREATED: {
                return true;
            }

            default: {
                text = confirmText(statusName);
                break;
            }
        }

        return this.modalService.showConfirmModal(text, ConfirmModalType.YesCancel);
    }

    private async showModalWhenSaving(): Promise<boolean | never> {
        switch (this.currentOrder.selectedStatus) {
            case OrderStatusesEnum.RECALL: {
                const result = await this.modalService.showModal(SubStatuses);
                if (result) {
                    return this.modalService.showModal(DateOfRecall);
                }
                return false;
            }

            case OrderStatusesEnum.REJECT:
            case OrderStatusesEnum.TRASH:
            case OrderStatusesEnum.NO_ANSWER: {
                return this.modalService.showModal(SubStatuses);
            }

            case OrderStatusesEnum.APPROVE:
            case OrderStatusesEnum.CREATED: {
                return true;
            }

            default: {
                return true;
            }
        }
    }

    private async _checkEquipmentForWork(): Promise<boolean> {
        try {
            await this._devicesService.checkEquipmentForWork();

            return true;
        } catch (error) {
            if (error instanceof SafariEditAccessError) {
                return true;
            }

            return false;
        }
    }

    @action
    public saveCommunicationQuality = async (communicationQualityIds: string[], comment?: string | undefined): Promise<void> => {
        if (!this.currentOrder.lastCallHistoryEvent) {
            return;
        }

        const result = await this.communicationQualityApiService.save(
            this.currentOrder.id,
            this.currentOrder.lastCallHistoryEvent.id || 0,
            this.currentUser.id,
            this.currentOrder.lastCallHistoryEvent.isAbort,
            this.currentOrder.lastCallHistoryEvent.timeAbort || 0,
            communicationQualityIds,
            String(this.getSipCode),
            comment || '',
        );

        if (result) {
            this.currentOrder.lastCallHistoryEvent.setCommunicationQualityWasSent(true);
        }
    };

    @action
    public stopPollingOrder = (): void => {
        if (this._pollingOrderTimeout) {
            clearTimeout(this._pollingOrderTimeout);
        }
    };

    @action
    public startPollingOrder = async (): Promise<void> => {
        if (!await this._checkEquipmentForWork()) {
            this.currentUser.setIsReady(false);

            return;
        }

        if (this.store.notificationWithFeedbackToView) {
            this.currentUser.setIsReady(false);
        }

        if (this.store.notificationReceivedFromServer) {
            const orderNotEmpty = await this.pendingOrder();

            if (!orderNotEmpty) {
                await this.pendingOrderTimeout();
                await this.startPollingOrder();
            } else {
                this.stopPollingOrder();
            }
        } else {
            await this.pendingOrderTimeout();
            await this.startPollingOrder();
        }
    };

    @action
    setSelectedDeliveryId(deliveryId: string): void {
        this.currentOrder.setSelectedDeliveryId(deliveryId);
    }

    @action
    changePaymentCard(val: boolean): void {
        this.currentOrder.setPaymentCard(val);
    }

    @action
    changeDateOfRecall(val: Date | null) {
        this.currentOrder.setDateOfRecall(val);
    }

    @action
    clearMutableProperties(): void {
        if (this.currentOrder.selectedStatus) {
            this.currentOrder.setSelectedStatus(null);
        }

        if (this.currentOrder.selectedSubStatus) {
            this.currentOrder.setSelectedSubStatus(null);
        }

        if (this.currentOrder.dateOfRecall) {
            this.currentOrder.setDateOfRecall(null);
        }
    }

    @computed
    private get stringToGoogleGeoCoding(): string {
        const stringToGoogleGeoCodingParts: string[] = [];

        this.currentOrder.addressAttributesKladerValues.forEach((item: IFormAttributesModel) => {
            const value = item.value.trim();
            if (item.value.trim()) {
                stringToGoogleGeoCodingParts.push(value);
            }
        });

        if (!stringToGoogleGeoCodingParts.length) {
            return '';
        }

        if (this.currentOrder.country.name) {
            stringToGoogleGeoCodingParts.unshift(this.currentOrder.country.name);
        }

        return stringToGoogleGeoCodingParts.join(', ');
    }

    private async getAddressFromGoogle(): Promise<string | null> {
        if (!this.stringToGoogleGeoCoding) {
            return null;
        }

        try {
            const result = await this.orderApiService.getAddressFromGoogle(this.stringToGoogleGeoCoding);

            if (!result.zipCode) {
                return null;
            }

            return result.zipCode;
        } catch (e) {
            const { message } = e;
            if (message.indexOf('ZERO_RESULT') !== -1) {
                // Не нашли такой адрес в геокодере
                return null;
            }

            // eslint-disable-next-line no-console
            console.error(e);
            throw new Error(e);
        }
    }

    async doCheckZipViaGeoCodingSideEffect(): Promise<GeoCodingSideEffectResult> {
        const notStop = {
            shouldStopSaveEvent: false,
        };

        if (!this.currentOrder.form.customerZipAttribute) {
            return notStop;
        }

        if (this.currentOrder.form.customerZipAttribute.isKladrOnly) {
            return notStop;
        }

        const shouldStop = {
            shouldStopSaveEvent: true,
        };

        const { useGeonegociosMap, useMap: useGoogleMap } = this.currentForm;

        if (!useGeonegociosMap && !useGoogleMap) {
            return notStop;
        }

        const enteredZipCode = this.currentOrder.form.customerZipAttribute.value;

        let zip = '';
        if (useGoogleMap) {
            zip = await this.getAddressFromGoogle() || '';
        } else if (useGeonegociosMap && this._geoNegociosMapService.abilityToSearchForAddressesOnMap) {
            try {
                const response = await this._geoNegociosMapService.fetchMapDataGeonegocios();
                zip = response.address.postalCode;
            } catch (e) {
                console.warn(e);

                return notStop; // Ошибка не критична, продолжаем работу метода
            }


        }

        if (!zip || zip === enteredZipCode) {
            return notStop;
        }

        const confirmText = this._t(
            'Введённый ZIP код отличается от ZIP-кода Google. Хотите применить ZIP-код Google?',
            'The ZIP code you entered is different from the Google ZIP code. Want to apply Google zip code?',
        );

        const confirm = await this.modalService.showConfirmModal(confirmText, ConfirmModalType.YesCancel);

        if (confirm) {
            this.currentOrder.form.customerZipAttribute.setValue(zip);
            // Указываем zip код гугла
            // И останавливаем сохранение заказа
            return shouldStop;
        }


        return notStop;
    }

    @action
    async preFastChangeOrderQueue(queueId: number, queueName: string): Promise<void> {
        const confirmResult = await this.modalService.showConfirmModal(
            this._t(
                'Вы уверены, что хотите изменить очередь заказа на {{queueName}}?',
                'Are you sure you want to change the order queue to {{queueName}}?',
                { queueName },
            ),
            ConfirmModalType.YesCancel,
        );

        if (confirmResult) {
            try {
                await this.orderApiService.fastChangeOrderQueue(
                    this.currentOrder.id,
                    queueId,
                );
                await this.modalService.showConfirmModal(
                    this._t(
                        'Очередь заказа изменена на {{queueName}}.',
                        'The order queue has been changed to {{queueName}}.',
                        { queueName },
                    ),
                    ConfirmModalType.Yes,
                );

                // UI-306 После смены очереди очищаем текущий заказ и получаем новый
                // WCALL-3759 бек самостоятельно снимает блокировку с оператора
                this.store.inactivityTimer.clearOrderTimePassed();
                if (this.unreadyAfterSave) {
                    this.store.currentUser.setIsReady(false);
                }

                this.store.clearCurrentOrder();
            } catch (e) {
                await this.modalService.showConfirmModal(
                    this._t(
                        'Ошибка при смене очереди заказа на {{queueName}}.',
                        'An error occurred when changing the order queue {{queueName}}.',
                        { queueName },
                    ),
                    ConfirmModalType.Yes,
                );
            }
        }
    }

    @action fastChangeOrderQueue = async (queueId: number): Promise<void> => this.orderApiService.fastChangeOrderQueue(this.currentOrder.id, queueId);

    @action preUpdateOrder = async (status: OrderStatusesEnum): Promise<boolean | never> => {
        const {
            isACreatedOrder,
            canChangeOrderStatus,
            mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved,
            isAllFieldsValid,
            invalidFields,
            isEmptyCurrentOrder,
            _deleteBasketItemPreview,
            clearReplaceTransaction,
            additionalParamsCurrentOrder: {
                activePromotionBuilderModelId,
            },
            deliveryTo,
            deliveryFrom,
            form: {
                approveWithoutShipping,
            },
            postProcessing,
            id,
        } = this.currentOrder;

        const {
            mode,
            setUnreadyAfterSave,
            setIsReady,
        } = this.currentUser;

        const {
            selectedDelivery,
        } = this.deliveriesService;

        const { modalTimerActive,
            clearOrderTimePassed,
            clearModalTimePassedTimer,
        } = this.inactivityTimer;

        // Если пытаемся сохранить заказ, то очищаем активные модели по предпросмотру товара и по замене товара, если вдруг такие есть
        clearReplaceTransaction();
        _deleteBasketItemPreview();

        const canChangeStatus =  mode === UserModeEnum.PROGRESSIVE ? true : canChangeOrderStatus;

        if (
            !isACreatedOrder
            && !canChangeStatus
            || (!this.modalService.isAFreeState && !postProcessing)
        ) {
            return false;
        }

        this.currentOrder.setSelectedStatus(status);

        if (!postProcessing || (postProcessing && (!modalTimerActive || isACreatedOrder))) {

            if (!mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved.length
                && !activePromotionBuilderModelId
            ) {
                this.currentOrder.setShowApproveValidationErrors(true);
                this.store.ui.bottomMobileTab.setActiveTab(BottomTabEnum.ORDER);
                this.store.ui.orderPage.closeCallPanel();

                const elementId = 'EmptyValueForMainProducts';

                OrderService.scrollToDOMElementById(elementId);

                return false;
            }

            if (status === OrderStatusesEnum.APPROVE) {
                if (!approveWithoutShipping && !selectedDelivery) {
                    this.store.ui.bottomMobileTab.setActiveTab(BottomTabEnum.ORDER);
                    this.store.ui.orderPage.closeCallPanel();

                    const elementId = 'approveWithoutShipping';

                    OrderService.scrollToDOMElementById(elementId);

                    return false;
                }

                /* Только для апрува.
                Попробуем геокодировать адрес, проверим zip код который гугл выдает, если он будет отличатся от введенного
                пользователем, то покажем модалку, и попросим его проверить zipCode еще раз.
                Предоставим выбор использовать уже введенный или тот что гугл определил. */
                const { shouldStopSaveEvent } = await this.doCheckZipViaGeoCodingSideEffect();

                if (shouldStopSaveEvent) {
                    return false;
                }
            }

            if (!isAllFieldsValid(status)) {
                this.store.ui.bottomMobileTab.setActiveTab(BottomTabEnum.ORDER);
                this.store.ui.orderPage.closeCallPanel();
                this.currentOrder.setShowApproveValidationErrors(true);
                const { name: elementId } = invalidFields(status)[0];

                if (elementId) {
                    OrderService.scrollToDOMElementById(elementId);
                }

                return false;
            }

            if (status === OrderStatusesEnum.APPROVE && !deliveryTo && !approveWithoutShipping ||
                status === OrderStatusesEnum.APPROVE && !deliveryFrom && !approveWithoutShipping) {

                const elementId = 'orderShippingDatePicker';
                OrderService.scrollToDOMElementById(elementId);
                this.currentOrder.setShowApproveValidationErrors(true);
                return false;
            }
        }

        try {

            if (!postProcessing) {
                const confirmResult = await this.showModalWarningWindow();

                if (confirmResult) {
                    const modalResult = await this.showModalWhenSaving();

                    if (modalResult) {
                        await this.orderUpdateService.updateOrder();
                        clearOrderTimePassed();

                        if (this.unreadyAfterSave) {
                            setIsReady(false);
                            setUnreadyAfterSave(false);
                        }

                        this.store.clearCurrentOrder();
                        return true;
                    }
                }
            } else {
                const confirmResult = await this.showModalWarningWindow();

                if (confirmResult) {

                    if (status === OrderStatusesEnum.APPROVE) {
                        this.inactivityTimer.stopAllTimers();
                        this.modalService.clearModalStack();
                    }

                    const modalResult = modalTimerActive ?
                        await this.showModalWhenSaving() : true;

                    if (modalResult) {

                        if (!modalTimerActive) {
                            await this.orderUpdateService.updateOrder();
                            this.store.clearCurrentOrder();
                        }

                        if (modalTimerActive) {
                            await this.orderApiService.orderPrestatus(
                                id,
                                status,
                                this.currentOrder.selectedSubStatus!,
                                this.currentOrder.dateOfRecall ? dateConversionToSave(this.currentOrder.dateOfRecall) : null,
                            );

                            clearModalTimePassedTimer();
                        }

                        clearOrderTimePassed();

                        if (this.unreadyAfterSave) {
                            setIsReady(false);
                            setUnreadyAfterSave(false);
                        }

                        return true;
                    }
                }
            }

        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            const errorMessage = this._t('Ошибка во время сохранения заказа.', 'Error while saving order.');
            void this.modalService.showErrorNotificationModal(`${errorMessage} ${this._t(e.message)}`);
        } finally {
            if (!isEmptyCurrentOrder && !postProcessing) {
                this.clearMutableProperties();
            }
        }

        return false;
    };

    @action
    async automaticallyAssignStatus(status: OrderStatusesEnum.RECALL | OrderStatusesEnum.NO_ANSWER): Promise<void> {
        const confirmText: (val: string) => string = (status: string): string => sprintf(
            this._t(
                'Заказу автоматически назначен статус %s',
                'An order would be assigned an automatic status %s',
            ),
            status,
        );

        let message = '';

        try {
            switch (status) {
                case OrderStatusesEnum.NO_ANSWER: {
                    const orderId = this.currentOrder.id;
                    await this.orderApiService.autoNoAnswerAppointment(orderId);
                    this.store.setPrevSavedOrderId(orderId);

                    message = confirmText('NO ANSWER');

                    break;
                }

                case OrderStatusesEnum.RECALL: {
                    const orderId = this.currentOrder.id;
                    await this.orderApiService.autoRecallAppointment(orderId);
                    this.store.setPrevSavedOrderId(orderId);

                    message = confirmText('RECALL');

                    break;
                }

                default: {
                    throw new Error(this._t(
                        'Автоматический статус не определен',
                        'Automatic status undefined',
                    ));
                }
            }

            await this.modalService.showConfirmModal(
                message,
                ConfirmModalType.Yes,
                10000,
                ConfirmModalAction.Yes,
            );

            this.store.inactivityTimer.clearOrderTimePassed();

            if (this.unreadyAfterSave) {
                this.store.currentUser.setIsReady(false);
            }

            this.store.clearCurrentOrder();
        } catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        }
    }

    @action
    async setOrderProductPrice(productId: number, price: number): Promise<boolean> {
        if (!productId || price < 0) {
            return false;
        }

        return this.orderApiService.updateOrderProductPrice({ productId, price });
    }

    @action
    async releaseCurrentOrder(): Promise<boolean> {
        if (!this.currentOrder.id) {
            return false;
        }

        return this.orderApiService.releaseOrder({ orderId: this.currentOrder.id });
    }

    @action
    async createNotification(trigger: string, wastedCall?: boolean): Promise<INotificationData | null> {
        if (!this.currentOrder.id) {
            return null;
        }

        const orderId = this.currentOrder.id || null;

        return this.notificationApiService.create({ trigger, orderId, wastedCall });
    }

    @action
    async getNotification(): Promise<IGetNotificationData | false> {
        return this.notificationApiService.get({ userId:  this.currentUser.id });
    }

    @action
    async setNotificationSuccess(id: number, feedback: string | undefined): Promise<boolean> {
        try {
            return await this.notificationApiService.setSuccess({ id, feedback });
        } catch (e) {
            if (e instanceof XHR400Error) {
                // WCALL-2688 если сервер возвращает statusCode 400 и id не найдено, значит уведомление было удалено на сервере, требуется удалить уведомление с клиент.
                return true;
            }

            throw e;
        }
    }

    @action
    public onWholesaleRequest(basketId: string, quantity: number): null {
        const { setWholesaleEnabled, hideWholesaleConfirm } = this.currentForm;
        const { appliedPromotionBuilder, additionalParamsCurrentOrder: { basket },
            additionalParamsCurrentOrder: { promotionBuilderBasket }  } = this.currentOrder;

        const message = this._t('Вы уверены в количестве?', 'Are you sure about the quantity?');

        void this.modalService.showConfirmModal(message, ConfirmModalType.YesCancel)
            .then((result: boolean) => {
                if (!appliedPromotionBuilder) {
                    const basketItem = basket.get(basketId);

                    if (!result) {
                        hideWholesaleConfirm();
                        return;
                    }

                    // Включаем режим опта
                    setWholesaleEnabled(true);

                    if (basketItem) {
                        basketItem.setAmount(quantity);
                        hideWholesaleConfirm();
                    }

                    return;
                }

                const basketItem = promotionBuilderBasket.get(basketId);

                if (!result) {
                    hideWholesaleConfirm();
                    return;
                }

                // Включаем режим опта
                setWholesaleEnabled(true);

                if (basketItem) {
                    basketItem.setAmountPromotionBuilder(quantity);
                    hideWholesaleConfirm();
                }
            });

        return null;
    }

    @action
    public leavePageModal(
        onCancel: () => void,
        onConfirm: () => void,
        hasSession: boolean,
    ): null {
        const message = hasSession
            ? this._t(
                'В данный момент совершается звонок. Вы уверены что хотите покинуть страницу?',
                'A call is being made at the moment. Are you sure you want to leave the page?',
            ) : this._t(
                'У вас есть не сохраненные изменения, вы уверены что хотите покинуть страницу?',
                'You have unsaved changes, are you sure you want to leave the page?',
            );

        void this.modalService.showConfirmModal(message, ConfirmModalType.YesCancel)
            .then((result: boolean) => {
                if (result) {
                    transaction(() => {
                        if (this.callHistoryEvent && hasSession) {
                            this.callHistoryEvent.setEndCall(ReasonsForEndingConversation.PAGE_REFRESH);
                        }
                        void this.releaseCurrentOrder();
                        this.store.clearCurrentOrder();
                        this.store.inactivityTimer.stopAllTimers();
                    });

                    onConfirm();
                } else {
                    onCancel();
                }
            });

        return null;
    }

    @action
    public async setSuccessPhone(phone: string): Promise<void> {
        if (this.currentOrder.successPhone !== phone) {
            this.currentOrder.setSuccessPhone(phone); // меняем в модели
            try {
                await this.orderApiService.setSuccessPhone(this.currentOrder.id, phone); // сообщаем на бэк
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    }

    /**
     * Метод создает состояние замены и скроллит до блока замены
     */
    public createReplaceTransaction(id: string, type: ReplaceTransactionTypeEnum, parentProductId?: string): void {
        this.currentOrder.createReplaceTransaction(
            id, type, parentProductId,
        );

        // Выносим скролл в отсроченный стек вызовов, т.к. компоненты не успевают отрисоваться и id недоступны
        setTimeout(
            () => {
                OrderService.scrollToDOMElementById(STOCK_SWAP_UNIT);
            },
            0,
        );
    }

    /**
     * Метод сеттит статус и скроллит до товара к которому применили замену
     */
    public replaceTransactionOnSwap(replaceTransaction: IReplaceTransactionModel, basketId: string): void {
        replaceTransaction.setStatus(ReplaceTransactionStatusEnum.SHOULD_COMMIT);

        // Выносим скролл в отсроченный стек вызовов, т.к. компоненты не успевают отрисоваться и id недоступны
        setTimeout(
            () => OrderService.scrollToDOMElementById(basketId),
            0,
        );
    }

    /**
     * Метод очищает состояние замены и скроллит до товара к которому применяли замену
     */
    public cancelReplacement(replaceTransaction: IReplaceTransactionModel, basketId: string): void {
        this.currentOrder.clearReplaceTransaction();

        // Выносим скролл в отсроченный стек вызовов, т.к. компоненты не успевают отрисоваться и id недоступны
        setTimeout(
            () => OrderService.scrollToDOMElementById(basketId),
            0,
        );
    }
}


export default OrderService;
