import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const PartnerModel = t
    .model('PartnerModel', {
        id: t.identifier,
        name: t.maybeNull(t.string),
    });


export interface IPartnerModel extends Instance<typeof PartnerModel> {}
export interface IPartnerModelSnapshotIn extends SnapshotIn<typeof PartnerModel> {}
export interface IPartnerModelSnapshotOut extends SnapshotOut<typeof PartnerModel> {}
