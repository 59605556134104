import React from 'react';
import cn from 'classnames';


interface IProps {
    className?: string;
}

const CallButtonEndSvg = ({ className, ...props }: IProps): JSX.Element => (
    <svg
        className={cn(['CallButtonEndSvg', className])}
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="28" cy="28" r="28" fill="#FF3B30" />
        <circle opacity="0.3" cx="28" cy="28" r="27.5" stroke="#3A3A3A" />
        <path
            d="M19.2616 32.06L21.5949 30.205C22.1549 29.7617 22.4816 29.085 22.4816 28.3733V25.34C26.0049 24.1967 29.8199 24.185 33.3549 25.34V28.385C33.3549 29.0967 33.6816 29.7733 34.2416 30.2167L36.5633 32.06C37.4966 32.795 38.8266 32.725 39.6666 31.885L41.0899 30.4617C42.0232 29.5283 42.0233 27.9767 41.0316 27.1017C33.5533 20.4983 22.2833 20.4983 14.8049 27.1017C13.8133 27.9767 13.8133 29.5283 14.7466 30.4617L16.1699 31.885C16.9983 32.725 18.3283 32.795 19.2616 32.06V32.06Z"
            fill="white"
        />
    </svg>
);

export default CallButtonEndSvg;
