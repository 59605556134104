import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';

export enum CustomerServiceOrderInformationTabs {
    ORDERS = 'ORDERS',
    APPEALS = 'APPEALS',
    SMS = 'SMS',
}

export const tabOptions: CustomerServiceOrderInformationTabs[] = [
    CustomerServiceOrderInformationTabs.ORDERS,
    CustomerServiceOrderInformationTabs.APPEALS,
    CustomerServiceOrderInformationTabs.SMS,
];

export const OrderInformationTabs = t
    .model('OrderInformationTabs', {
        activeTab: t.optional(t.enumeration(tabOptions), CustomerServiceOrderInformationTabs.ORDERS),
    })
    .actions((self) => ({
        switchToOrdersTab(): void {
            self.activeTab = CustomerServiceOrderInformationTabs.ORDERS;
        },
        switchToAppealsTab(): void {
            self.activeTab = CustomerServiceOrderInformationTabs.APPEALS;
        },
        switchToSMSTab(): void {
            self.activeTab = CustomerServiceOrderInformationTabs.SMS;
        },
    }));


export interface IOrderInformationTabsModel extends Instance<typeof OrderInformationTabs> {}
export interface IOrderInformationTabsSnapshotIn extends SnapshotIn<typeof OrderInformationTabs> {}
export interface IOrderInformationTabsSnapshotOut extends SnapshotOut<typeof OrderInformationTabs> {}
