import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const CallHistoryStatus = t
    .model('CallHistoryStatus', {
        id: t.identifier,
        name: t.string,
    });


export interface ICallHistoryStatusModel extends Instance<typeof CallHistoryStatus> {}
export interface ICallHistoryStatusModelSnapshotIn extends SnapshotIn<typeof CallHistoryStatus> {}
export interface ICallHistoryStatusModelSnapshotOut extends SnapshotOut<typeof CallHistoryStatus> {}
