import React, { useCallback, useMemo, useState } from 'react';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import map from 'lodash/map';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { sprintf } from 'sprintf-js';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import CounterInput from '@UIElements/CounterInput';
import PriceEditInput from '@components/main/order-page/order-products/order-table-mobile/PriceEditInput';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IProductItemModel } from '@models/mobx-state-tree/newModels/ProductItem.model';

import './OrderTableMobile.scss';
import ProductsService from '@services/order/ProductsService';
import EmptyMainProductsNotification from '../includes/EmptyMainProductsNotification';


enum TabEnum {
    'ADD' = 'ADD',
    'REMOVE' = 'REMOVE', // TODO: Удаление через конфирм или отмену
    'CURRENT' = 'CURRENT',
    'MAIN' = 'MAIN',
    'PROMO' = 'PROMO',
    'GIFT' = 'GIFT',
    'SIMILAR' = 'SIMILAR',
}

enum AddTypeEnum {
    'MAIN' = 'MAIN',
    'PROMO' = 'PROMO',
    'GIFT' = 'GIFT',
}


const OrderTableMobile = () => {
    const [
        {
            currentOrder: {
                totalCostOfSelectedProducts,
                similarOrdersValues,
                activatePromoFrom,
                mainItemsInBasket,
                promoItemsInBasket,
                giftsNotRelatedToOldPromotions,
                mainItemsToShow,
                promoItemsToShow,
                giftItemsToShow,
                animationOnOrErrorFieldsActive,
                form: {
                    allowGifts,
                },
            },
            currentUser: {
                canEditProductPrice,
            },
        },
        { t },
        {
            hasPromoItemsInCart,
            isConditionForPromoCarts,
        },
    ] = useInstances(
        Store,
        I18NService,
        ProductsService,
    );

    const [tab, setTab] = useState<TabEnum>(TabEnum.CURRENT);
    const [addItem, setAddItem] = useState<IBasketItemModel>();
    const [addType, setAddType] = useState<AddTypeEnum>(AddTypeEnum.MAIN);

    const selectedProductItem = useMemo<IProductItemModel>(
        () => addItem?.productItem || {} as IProductItemModel,
        [addItem],
    );

    const scrollToBlockTop = () => {
        const element = document.getElementById('order-items');
        if (element) {
            element.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    };

    const setTabAdd = (item: IBasketItemModel, type: AddTypeEnum) => {
        scrollToBlockTop();
        setTab(TabEnum.ADD);
        setAddItem(item);
        setAddType(type);
    };

    const onAddClose = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.CURRENT);
    }, [setTab]);

    const onCurrentTabClick = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.CURRENT);
    }, [setTab]);

    const onSimilarTabClick = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.SIMILAR);
    }, [setTab]);

    const onMainTabClick = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.MAIN);
    }, [setTab]);

    const onPromoTabClick = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.PROMO);
    }, [setTab]);

    const onGiftTabClick = useCallback(() => {
        scrollToBlockTop();
        setTab(TabEnum.GIFT);
    }, [setTab]);

    return (
        <div className="OrderTableMobile">
            <div className="tabs">
                <div
                    className={cn('tab', tab === TabEnum.CURRENT && 'active')}
                    onClick={onCurrentTabClick}
                >
                    {t('Текущий', 'Current')}
                </div>
                <div
                    className={cn('tab', tab === TabEnum.SIMILAR && 'active')}
                    onClick={onSimilarTabClick}
                >
                    {t('Похожие ({{count}})', 'Similar ({{count}})', { count: similarOrdersValues.length })}
                </div>
                <div
                    className={cn('tab', tab === TabEnum.MAIN && 'active')}
                    onClick={onMainTabClick}
                >
                    {t('Основной', 'Main')}
                </div>
                <div
                    className={cn('tab', tab === TabEnum.PROMO && 'active')}
                    onClick={onPromoTabClick}
                >
                    {t('Промо', 'Promo')}
                </div>
                {allowGifts && (
                    <div
                        className={cn('tab', tab === TabEnum.GIFT && 'active')}
                        onClick={onGiftTabClick}
                    >
                        {t('Подарок', 'Gift')}
                    </div>
                )}
            </div>
            <div className="tabs-content">
                {!mainItemsToShow.length && (
                    <EmptyMainProductsNotification
                        className="EmptyValueForMainProducts no-items"
                        animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                    />
                )}

                {tab === TabEnum.ADD && addItem && addType && (
                    <div className="add">
                        <div className="row">
                            <div className="add-item-image">
                                <ProductImage
                                    className="ProductItemToAdd__product_image"
                                    productName={selectedProductItem.name}
                                    imageLink={selectedProductItem.image}
                                    productType={selectedProductItem.type}
                                />
                            </div>
                            <div className="add-item-name">
                                {selectedProductItem.name}
                                {addType === AddTypeEnum.GIFT && (
                                    <div className="label label-gift">{t('Подарок', 'Gift')}</div>
                                )}
                                {addType === AddTypeEnum.PROMO && (
                                    <div className="label label-promo">{t('Промо', 'Promo')}</div>
                                )}
                            </div>
                            <div className="add-item-close-action-wrapper">
                                <div
                                    className="add-item-close-action"
                                    onClick={onAddClose}
                                />
                            </div>
                        </div>
                        <div className="control-wrapper">
                            <div className="title">
                                {t('Цена за единицу', 'Price one item')}
                            </div>
                            <div className="control">
                                {(!canEditProductPrice || addType !== AddTypeEnum.MAIN) && (
                                    <div className="price">
                                        {addItem.price}
                                    </div>
                                )}
                                {addType === AddTypeEnum.MAIN && canEditProductPrice && (
                                    <PriceEditInput
                                        item={addItem}
                                        className="price-input"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="control-wrapper">
                            <div className="title">
                                {t('Количество', 'Quantity')}
                            </div>
                            <div className="control">
                                <CounterInput
                                    onAdd={addItem.addAmount}
                                    onReduce={addItem.reduceAmount}
                                    onInputChange={addItem.setAmount}
                                    quantity={String(addItem.quantity)}
                                />
                            </div>
                        </div>
                        <div className="add-total">
                            <div className="title">
                                {t('Итоговая цена товара', 'Total item price')}
                            </div>
                            <div className="price">
                                {addItem.totalPrice}
                            </div>
                        </div>
                    </div>
                )}
                {tab === TabEnum.CURRENT && (
                    <>
                        {map(mainItemsToShow, (item) => (
                            <div className="current" key={item.id}>
                                <div className="current-item">
                                    <div className="current-item-image">
                                        <ProductImage
                                            className="ProductItemToAdd__product_image"
                                            productName={item.productItem.name}
                                            imageLink={item.productItem.image}
                                            productType={item.productItem.type}
                                        />
                                    </div>
                                    <div className="current-item-name">
                                        <div>
                                            {item.productItem.name}
                                            <span className="amount">
                                                <span>&nbsp;x&nbsp;</span>
                                                <span>{item.quantity}</span>
                                            </span>
                                        </div>
                                        <div className="current-item-price-info">
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Один предмет', 'One item')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.price}
                                                </div>
                                            </div>
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Итого', 'Total')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.totalPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="current-item-action" onClick={item.hideInRow} />
                                </div>
                            </div>
                        ))}
                        {map(giftItemsToShow, (item) => (
                            <div className="current" key={item.id}>
                                <div className="current-item">
                                    <div className="current-item-image">
                                        <ProductImage
                                            className="ProductItemToAdd__product_image"
                                            productName={item.productItem.name}
                                            imageLink={item.productItem.image}
                                            productType={item.productItem.type}
                                        />
                                    </div>
                                    <div className="current-item-name">
                                        <div>
                                            {item.productItem.name}
                                            <span className="amount">
                                                <span>&nbsp;x&nbsp;</span>
                                                <span>{item.quantity}</span>
                                            </span>
                                            <br />
                                            <div className="label label-gift">Gift</div>
                                        </div>
                                        <div className="current-item-price-info">
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Один предмет', 'One item')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.price}
                                                </div>
                                            </div>
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Итого', 'Total')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.totalPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="current-item-action" onClick={item.hideInRow} />
                                </div>
                            </div>
                        ))}
                        {map(promoItemsToShow, (item) => (
                            <div className="current" key={item.id}>
                                <div className="current-item">
                                    <div className="current-item-image">
                                        <ProductImage
                                            className="ProductItemToAdd__product_image"
                                            productName={item.productItem.name}
                                            imageLink={item.productItem.image}
                                            productType={item.productItem.type}
                                        />
                                    </div>
                                    <div className="current-item-name">
                                        <div>
                                            {item.productItem.name}
                                            <span className="amount">
                                                <span>&nbsp;x&nbsp;</span>
                                                <span>{item.quantity}</span>
                                            </span>
                                            <br />
                                            <div className="label label-promo">Promo</div>
                                        </div>
                                        <div className="current-item-price-info">
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Один предмет', 'One item')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.price}
                                                </div>
                                            </div>
                                            <div className="current-item-price-info--item">
                                                <div className="current-item-price-info--label">
                                                    {`${t('Итого', 'Total')}:`}
                                                </div>
                                                <div className="current-item-price-info--value">
                                                    {item.totalPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="current-item-action" onClick={item.hideInRow} />
                                </div>
                            </div>
                        ))}
                        <div className="current-total">
                            <div className="title">
                                {t('Итоговая стоимость', 'Total price')}
                            </div>
                            <div className="price">
                                {totalCostOfSelectedProducts}
                            </div>
                        </div>
                    </>
                )}

                {tab === TabEnum.SIMILAR && (
                    <div className="similarOrders">
                        <div className="row header">
                            <div className="col">
                                {t('заказ', 'order')}
                            </div>
                            <div className="col">
                                {t('продукт', 'product')}
                            </div>
                            <div className="col">
                                {t('клиент', 'client')}
                            </div>
                        </div>
                        {similarOrdersValues.length === 0 && (
                            <span className="no-items">
                                {t('Нет похожих заказов', 'No similar orders')}
                            </span>
                        )}
                        {similarOrdersValues.length > 0 && map(similarOrdersValues, (similarOrder) => (
                            <div className="row" key={String(similarOrder.id)}>
                                <div className="col">
                                    {`# ${similarOrder.id}`}
                                    <br />
                                    {`at ${similarOrder.formattedCreatedDate}`}
                                    <br />
                                    {`partner ${similarOrder.partner}`}
                                    <br />
                                    {`# at partner ${similarOrder.foreignId}`}
                                    {similarOrder.status && (
                                        <>
                                            <br />
                                            {`status ${t(similarOrder.status)}`}
                                        </>
                                    )}
                                </div>
                                <div className="col">
                                    {similarOrder.products}
                                </div>
                                <div className="col">
                                    {similarOrder.customerFullName}
                                    <br />
                                    {similarOrder.maskPhone && similarOrder.encryptedCustomerPhone}
                                    {!similarOrder.maskPhone && similarOrder.customerPhone}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {tab === TabEnum.MAIN && (
                    <div className="items-tab-content">
                        {mainItemsInBasket.length > 0 && map(mainItemsInBasket, (basket: IBasketItemModel) => (
                            <div className="item" key={basket.id} onClick={() => setTabAdd(basket, AddTypeEnum.MAIN)}>
                                <ProductImage
                                    className="ProductItemToAdd__product_image"
                                    productName={basket.productItem.name}
                                    imageLink={basket.productItem.image}
                                    productType={basket.productItem.type}
                                />
                            </div>
                        ))}
                        {mainItemsInBasket.length === 0 && (
                            <span className="no-items">{t('Нет главных товаров', 'No main items')}</span>
                        )}
                    </div>
                )}

                {tab === TabEnum.GIFT && (
                    <div className="items-tab-content">
                        {giftsNotRelatedToOldPromotions.length > 0 && map(giftsNotRelatedToOldPromotions, (basket: IBasketItemModel) => (
                            <div className="item" key={basket.id} onClick={() => setTabAdd(basket, AddTypeEnum.GIFT)}>
                                <ProductImage
                                    className="ProductItemToAdd__product_image"
                                    productName={basket.productItem.name}
                                    imageLink={basket.productItem.image}
                                    productType={basket.productItem.type}
                                />
                            </div>
                        ))}
                        {giftsNotRelatedToOldPromotions.length === 0 && (
                            <span className="no-items">{t('Нет подарков', 'No gift items')}</span>
                        )}
                    </div>
                )}

                {tab === TabEnum.PROMO && activatePromoFrom && (
                    <div className="items-tab-content">
                        {hasPromoItemsInCart && (
                            <span className="no-items">
                                {t(
                                    'Заказ может содержать только 1 промо продукт',
                                    'An order can only contain 1 promo product',
                                )}
                            </span>
                        )}
                        {!isConditionForPromoCarts && activatePromoFrom && (
                            <div className="no-items">
                                {sprintf(
                                    t(
                                        'Промо продукты будут доступны если в заказе будет более %d основных товаров',
                                        'Promo products will be available if the order contains more than %d main products',
                                    ),
                                    activatePromoFrom,
                                )}
                            </div>
                        )}
                        {isConditionForPromoCarts && promoItemsInBasket.length > 0 && map(promoItemsInBasket, (basket: IBasketItemModel) => (
                            <div
                                className={cn('item', hasPromoItemsInCart && 'disabled')}
                                key={basket.id}
                                onClick={() => !hasPromoItemsInCart && setTabAdd(basket, AddTypeEnum.PROMO)}
                            >
                                <ProductImage
                                    className="ProductItemToAdd__product_image"
                                    productName={basket.productItem.name}
                                    imageLink={basket.productItem.image}
                                    productType={basket.productItem.type}
                                />
                            </div>
                        ))}
                        {isConditionForPromoCarts && promoItemsInBasket.length === 0 && (
                            <span className="no-items">{t('Нет промо-товаров', 'No promo items')}</span>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};


export default observer(OrderTableMobile);
