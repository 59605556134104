import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { ProductItemModel } from './newModels/ProductItem.model';


export const FormDelivery = t
    .model('FormDelivery', { // todo: проверить всё ли корректно
        id: t.maybeNull(t.number),
        customerZip: t.maybeNull(t.number),
        customerDistrict: t.maybeNull(t.string),
        products: t.optional(t.array(ProductItemModel), []),
    });


export interface IFormDeliveryModel extends Instance<typeof FormDelivery> {}
export interface IFormDeliveryModelSnapshotIn extends SnapshotIn<typeof FormDelivery> {}
export interface IFormDeliveryModelSnapshotOut extends SnapshotOut<typeof FormDelivery> {}
