import Cookies from 'universal-cookie';

import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import { ConfirmModalType } from '@core/models/ModalWindow';


const TEST_COOKIE_NAME = 'TEST_COOKIE';


class CheckCookieEnabledService {
    constructor(
        private readonly modalService: ModalService,
        private readonly I18NService: I18NService,
    ) {}

    private static _isCookieEnabled(): boolean {
        const cookies = new Cookies();
        const value = 'test';
        cookies.set(TEST_COOKIE_NAME, value);
        // testCookie.set(value, { expires: 1 });
        const cookieValue = cookies.get(TEST_COOKIE_NAME);
        cookies.remove(TEST_COOKIE_NAME);

        return cookieValue === value;
    }

    check(): void {
        const isCookieEnabled = CheckCookieEnabledService._isCookieEnabled();
        if (!isCookieEnabled) {
            const text = this.I18NService.t(
                'Для корректной работы сайта необходимо включить cookies и local storage.',
                'Cookies and Local storage must be enabled for the site to work properly.',
            );
            void this.modalService.showConfirmModal(text, ConfirmModalType.Yes);
        }
    }
}


export default CheckCookieEnabledService;
