import { IPromotionBuilderModel } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';

import { useManageCatalogPromotionBuilder } from './helpers/useManageCatalogPromotionBuilder';
import PromotionBuilderToAdd from './PromotionBuilderToAdd';

interface IPromoItemToAddSmartProps {
    readonly promotionBuilder: IPromotionBuilderModel;
    readonly dataCyValue: number;
}

/**
 * Родитель для dummy компонента промо товара PromotionBuilderToAdd
 * Использует хук, который передает внутрь компонента всю нужную информацию
 */
const PromotionBuilderToAddSmart: FunctionComponent<IPromoItemToAddSmartProps> = ({
    promotionBuilder,
    dataCyValue,
}) => {
    const [
        preparedCatalogItemForView,
        freeLabelTranslated,
        curtainHeaderTranslations,
        buttonTranslations,
        applyPromotionBuilder,
        getTextAboutAmountOfGoodsThatAreMissing,
        getAmountOfGoodsThatAreMissing,
    ] = useManageCatalogPromotionBuilder(promotionBuilder);

    return (
        <PromotionBuilderToAdd
            isApplied={false}
            promotionBuilder={preparedCatalogItemForView}
            translations={buttonTranslations}
            curtainHeader={curtainHeaderTranslations}
            disabled={false}
            onApplied={() => applyPromotionBuilder(promotionBuilder)}
            promotionIsNotAvailable={false}
            translatedLabelForAGiftPrice={freeLabelTranslated}
            dataCyValue={dataCyValue}
            quantityOfGoodsBeforeApplication={getTextAboutAmountOfGoodsThatAreMissing(promotionBuilder)}
            amountOfGoogdsThatAreMissing={getAmountOfGoodsThatAreMissing(promotionBuilder)}
        />
    );
};

export default observer(PromotionBuilderToAddSmart);
