import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface ICoinsSVGProps {
    className?: string;
    onClick?: () => void;
}


const CoinsSVG: FunctionComponent<ICoinsSVGProps> = ({ className, onClick, ...props }): JSX.Element => (
    <svg
        className={cn(['CoinsSVG', className])}
        onClick={onClick}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            clipPath="url(#clip0)"
        >
            <rect
                y="2.61719"
                width="1"
                height="8.13178"
                fill="#C2C2C2"
            />
            <path
                d="M4.66736 4.66662C7.33815 4.66662 9.33379 3.61081 9.33379 2.66675C9.33379 1.72278 7.3381 0.666931 4.66736 0.666931C1.99662 0.666931 0.000976562 1.72278 0.000976562 2.66675C0.000976562 3.61081 1.99658 4.66662 4.66736 4.66662Z"
                fill="#C2C2C2"
            />
            <path
                d="M4.66639 12.6663C5.05621 12.6663 5.4314 12.6437 5.78829 12.6024C5.48745 12.2158 5.23867 11.7867 5.0523 11.326C4.92424 11.3303 4.79573 11.3333 4.66634 11.3333C3.14026 11.3333 1.69503 11.0309 0.597 10.4818C0.380798 10.3737 0.181842 10.2576 0 10.1346V10.6665C0 11.6105 1.9956 12.6663 4.66639 12.6663Z"
                fill="#C2C2C2"
            />
            <path
                d="M4.66643 9.99975C4.68012 9.99975 4.69359 9.99953 4.70723 9.99948C4.6799 9.78097 4.66563 9.55842 4.66563 9.33262C4.66563 9.1069 4.67985 8.88448 4.70723 8.66615C4.69359 8.66615 4.68008 8.66664 4.66643 8.66664C3.14035 8.66664 1.69517 8.36425 0.597089 7.81522C0.380842 7.70712 0.181842 7.59098 0 7.46808V7.99991C4.44494e-05 8.94391 1.99564 9.99975 4.66643 9.99975Z"
                fill="#C2C2C2"
            />
            <path
                d="M4.66643 7.33316C4.79871 7.33316 4.92921 7.3305 5.05807 7.32543C5.26503 6.81769 5.54786 6.3487 5.8927 5.93279C5.49346 5.9768 5.08314 6.00013 4.66643 6.00013C3.14035 6.00013 1.69517 5.69774 0.597089 5.14871C0.380842 5.04056 0.181842 4.92446 0 4.80151V5.33339C4.44494e-05 6.27741 1.99564 7.33316 4.66643 7.33316Z"
                fill="#C2C2C2"
            />
            <path
                d="M6.66701 7.12277C6.37853 7.55646 6.17242 8.04936 6.07103 8.57902C6.02423 8.82318 5.99951 9.07507 5.99951 9.33266C5.99951 9.52632 6.01365 9.71675 6.04036 9.90312C6.10601 10.361 6.24945 10.7939 6.45685 11.1883C6.67448 11.6022 6.96237 11.9736 7.30477 12.2863C8.01631 12.9362 8.96264 13.3331 9.99996 13.3331C12.2058 13.3331 14.0004 11.5385 14.0004 9.33266C14.0004 7.12677 12.2058 5.33221 9.99996 5.33221C9.77198 5.33221 9.54844 5.35168 9.33073 5.38849C8.2218 5.57602 7.26667 6.22138 6.66701 7.12277Z"
                fill="#C2C2C2"
            />
            <path
                d="M7.29869 8.81448L7.29867 8.81454C7.2665 8.98237 7.24951 9.15551 7.24951 9.33264C7.24951 9.46568 7.25922 9.59663 7.27761 9.72496L7.29869 8.81448ZM7.29869 8.81448C7.36832 8.45073 7.50988 8.11208 7.70823 7.81388L7.29869 8.81448ZM7.56375 10.6079L7.56375 10.6079C7.71337 10.8924 7.91138 11.1479 8.14694 11.363L8.14694 11.363C8.63644 11.8101 9.28662 12.0826 9.99951 12.0826C11.5156 12.0826 12.7495 10.8488 12.7495 9.33264C12.7495 7.81648 11.5156 6.58264 9.99951 6.58264C9.84281 6.58264 9.68911 6.59602 9.53932 6.62134M7.56375 10.6079L9.53932 6.62134M7.56375 10.6079C7.42122 10.3369 7.32271 10.0395 7.27762 9.72497L7.56375 10.6079ZM9.53932 6.62134C8.7779 6.75011 8.12095 7.1935 7.70824 7.81387L9.53932 6.62134Z"
                stroke="white"
                strokeWidth="0.5"
            />
        </g>
        <defs>
            <clipPath
                id="clip0"
            >
                <rect
                    width="14"
                    height="14"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>

);


export default CoinsSVG;
