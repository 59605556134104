import {
    IAnyModelType, Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { IProductItemModel, ProductItemModel } from '@models/mobx-state-tree/newModels/ProductItem.model';


export const PromotionModel = t
    .model('PromotionModel', {
        id: t.identifier,
        name: t.string, // название акции
        productItem: t.reference(t.late((): IAnyModelType => ProductItemModel)), // Продукт для которого актуальная акция
        paidAmount: t.number, // Сколько товара надо оплатить чтобы участвовать в акции, это кол-во изменить нельзя
        specialPrice: t.maybeNull(t.number), // Какая будет цена продукта, если null то брать из partnerProduct
        gifts: t.map(t.late((): IAnyModelType => ProductItemModel)), // Товары по акции
    })
    .views((self) => ({
        get giftsValues(): IProductItemModel[] {
            return [...self.gifts.values()] as IProductItemModel[];
        },

        get price(): number | null {
            const {
                specialPrice,
                productItem,
            } = self;

            return specialPrice
                ? specialPrice
                : (productItem as IProductItemModel).price;
        },
    }));


export interface IPromotionModel extends Instance<typeof PromotionModel> {}
export interface IPromotionModelSnapshotIn extends SnapshotIn<typeof PromotionModel> {}
export interface IPromotionModelSnapshotOut extends SnapshotOut<typeof PromotionModel> {}
