import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const CallHistoryProvider = t
    .model('CallHistoryProvider', {
        id: t.identifier,
        name: t.string,
    });


export interface ICallHistoryProviderModel extends Instance<typeof CallHistoryProvider> {}
export interface ICallHistoryProviderModelSnapshotIn extends SnapshotIn<typeof CallHistoryProvider> {}
export interface ICallHistoryProviderModelSnapshotOut extends SnapshotOut<typeof CallHistoryProvider> {}
