import { toFactory } from 'react-ioc';

import NotificationPollingService from '@services/NotificationPollingService';
import NotificationApiService from '@api/notification-api-service';
import ErrorService from '@core/services/ErrorService';
import { Store } from '@store/store';


const NotificationPollingServiceDI = (): any => [
    NotificationPollingService,
    toFactory(
        [Store, NotificationApiService, ErrorService],
        (store: Store, nApi: NotificationApiService, errorService: ErrorService) => new NotificationPollingService(store, nApi, errorService),
    ),
];

export default NotificationPollingServiceDI;
