import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { EnumOrderCommentsAndSmsTabs } from '@/app/models/mobx-state-tree/ui/orderCommentsAndSmsTabsModel';
import ContentBlock from '@UIElements/ContentBlock';
import I18NService from '@services/I18NService';
import { Store } from '@/app/store/store';

import OrderComments from '../order-comments/OrderComments';
import './includes/OrderCommentsAndSms.scss';
import SmsMessagesTable from './includes/SmsMessagesTable';

const OrderCommentsAndSms: FunctionComponent = (): JSX.Element => {
    const [
        { currentOrder },
        { t },
    ] = useInstances(
        Store,
        I18NService,
    );
    const { orderCommentDraft, currentCommentsValues, orderCommentsAndSmsTabs } = currentOrder;
    const { activeTab, switchToSMSTab, switchToCommentsTab } = orderCommentsAndSmsTabs;

    return (
        <ContentBlock
            id="order-comments-and-sms"
            isWithoutHeader={true}
        >
            <div className="comments-and-sms_wrapper">
                <div className="tabs-container">
                    <div
                        onClick={switchToCommentsTab}
                        className={cn(
                            'currentProducts__tab',
                            activeTab === EnumOrderCommentsAndSmsTabs.COMMENTS && 'active',
                        )}
                    >
                        <div className="tabs__text">
                            {t('Комментарии', 'Comments')}
                        </div>
                    </div>
                    <div
                        data-cy="switchToSMSTab"
                        onClick={switchToSMSTab}
                        className={cn(
                            'currentProducts__tab',
                            activeTab === EnumOrderCommentsAndSmsTabs.SMS && 'active',
                        )}
                    >
                        <div className="tabs__text">
                            {t('СМС', 'SMS')}
                        </div>

                    </div>
                </div>

                {activeTab === EnumOrderCommentsAndSmsTabs.COMMENTS && (
                    <OrderComments
                        orderCommentDraft={orderCommentDraft}
                        comments={currentCommentsValues}
                    />
                )}
                {activeTab === EnumOrderCommentsAndSmsTabs.SMS && (
                    <SmsMessagesTable />
                )}
            </div>
        </ContentBlock>
    );
};


export default observer(OrderCommentsAndSms);
