import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OrderComment = t
    .model('OrderComment', {
        id: t.identifier,
        author: t.maybeNull(t.string),
        text: t.string,
        created_at: t.maybeNull(t.number),
    });


export interface IOrderCommentModel extends Instance<typeof OrderComment> {}
export interface IOrderCommentModelSnapshotIn extends SnapshotIn<typeof OrderComment> {}
export interface IOrderCommentModelSnapshotOut extends SnapshotOut<typeof OrderComment> {}
