import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import ProductLabel from '@UIElements/ProductLabel';
import Button from '@UIElements/Button';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';

import NomenclatureWrapper from '../../../nomenclature-wrapper/NomenclatureWrapper';

interface IOptionalNomenclatureProps {
    forPromo: boolean;
    forGift: boolean;
    callback: () => void;
}

const ButtonsToAddGiftsAndPromos: FunctionComponent<IOptionalNomenclatureProps> = ({
    forPromo,
    forGift,
    callback,
}) => {
    const [
        {
            currentOrder: {
                prepareLabelTranslation,
            },
        },
        { t },
    ] = useInstances(
        Store,
        I18NService,
    );

    return (
        <>
            {forPromo && (
                <NomenclatureWrapper
                    subtype={ProductItemSubTypeEnum.PROMO}
                >
                    <div className="currentProducts__table-restore" />
                    <div className="currentProducts__table-avatar">
                        <ProductImage
                            className="currentProducts__table-avatar-image"
                            productName=""
                            imageLink=""
                            productType={ProductItemTypeEnum.DYNAMIC_SET}
                            forPlaceholderPromotionBuilder
                        />
                    </div>
                    <div
                        className="currentProducts__table-product--name promotionBuilder-placeholder"
                        data-cy={`optional-placeholder-for-${ProductItemSubTypeEnum.PROMO}-name`}
                    >
                        {t('Выберите промо', 'Choose a promo')}
                    </div>
                    <span
                        className={cn(
                            'currentProducts__table-text-label--webkit',
                        )}
                    >
                        <ProductLabel
                            productType={ProductItemTypeEnum.REGULAR}
                            productSubType={ProductItemSubTypeEnum.PROMO}
                            isPromotion={false}
                            text={prepareLabelTranslation(
                                ProductItemTypeEnum.REGULAR,
                                ProductItemSubTypeEnum.PROMO,
                                false,
                            )}
                        />
                    </span>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2 promotionBuilder_optional_gift"
                    >
                        <div className="currentProducts__actions">
                            <Button
                                variant="2"
                                size="0"
                                text={t('Выберите промо', 'Choose a promo')}
                                width="135px"
                                onClick={callback}
                                dataCyValue={`optional-placeholder-for-${ProductItemSubTypeEnum.PROMO}-add-button`}
                            />
                        </div>
                    </div>
                </NomenclatureWrapper>
            )}
            {forGift && (
                <NomenclatureWrapper
                    subtype={ProductItemSubTypeEnum.GIFT}
                >
                    <div className="currentProducts__table-restore" />
                    <div className="currentProducts__table-avatar">
                        <ProductImage
                            className="currentProducts__table-avatar-image"
                            productName=""
                            imageLink=""
                            productType={ProductItemTypeEnum.DYNAMIC_SET}
                            forPlaceholderPromotionBuilder
                        />
                    </div>
                    <div
                        className="currentProducts__table-product--name promotionBuilder-placeholder"
                        data-cy={`optional-placeholder-for-${ProductItemSubTypeEnum.GIFT}-name`}
                    >
                        {t('Выберите подарок', 'Choose a gift')}
                    </div>
                    <span
                        className={cn(
                            'currentProducts__table-text-label--webkit',
                        )}
                    >
                        <ProductLabel
                            productType={ProductItemTypeEnum.REGULAR}
                            productSubType={ProductItemSubTypeEnum.GIFT}
                            isPromotion={false}
                            text={prepareLabelTranslation(
                                ProductItemTypeEnum.REGULAR,
                                ProductItemSubTypeEnum.GIFT,
                                false,
                            )}
                        />
                    </span>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2 promotionBuilder_optional_gift"
                    >
                        <div className="currentProducts__actions">
                            <Button
                                variant="2"
                                size="0"
                                text={t('Выберите подарок', 'Choose a gift')}
                                width="135px"
                                onClick={callback}
                                dataCyValue={`optional-placeholder-for-${ProductItemSubTypeEnum.GIFT}-add-button`}
                            />
                        </div>
                    </div>
                </NomenclatureWrapper>
            )}
        </>
    );
};


export default observer(ButtonsToAddGiftsAndPromos);
