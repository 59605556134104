import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import { OrderService } from '@services/index';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import BasketService from '@services/order/BasketService';
import PromotionBuilderService from '@services/order/PromotionBuilderService';


const PromotionBuilderServiceDI = (): any => [
    PromotionBuilderService,
    toFactory(
        [
            Store,
            OrderService,
            I18NService,
            ModalService,
            BasketService,
        ],
        (
            store: Store,
            orderService: OrderService,
            I18NService: I18NService,
            modalService: ModalService,
            basketService: BasketService,
        ) => new PromotionBuilderService(
            store,
            orderService,
            I18NService,
            modalService,
            basketService,
        ),
    ),
];


export default PromotionBuilderServiceDI;
