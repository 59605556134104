import {
    Instance,
    types as t,
    SnapshotIn,
    SnapshotOut,
} from 'mobx-state-tree';


export const NotificationModel = t
    .model('NotificationModel', {
        id: t.identifier,
        isFeedback: t.optional(t.boolean, false),
        message: t.string,
    });

export interface INotificationModel extends Instance<typeof NotificationModel> {}
export interface INotificationModelSnapshotIn extends SnapshotIn<typeof NotificationModel> {}
export interface INotificationModelSnapshotOut extends SnapshotOut<typeof NotificationModel> {}
