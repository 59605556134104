import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export const RecallProductsModel = t
    .model('RecallProducts', {
        id: t.identifier,
        gift: t.maybeNull(t.boolean),
        promo: t.maybeNull(t.boolean),
        name: t.maybeNull(t.string),
    });


export interface IRecallProductsModel extends Instance<typeof RecallProductsModel> {}
export interface IRecallProductsModelSnapshotIn extends SnapshotIn<typeof RecallProductsModel> {}
export interface IRecallProductsModelSnapshotOut extends SnapshotOut<typeof RecallProductsModel> {}