import React, {
    FunctionComponent,
    useCallback, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import { IProductItemModel, ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { OrderService } from '@app/services';
import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import I18NService from '@services/I18NService';
import ProductLabel from '@UIElements/ProductLabel';
import CoinsSVG from '@components/resources/SVG/Coins/CoinsSVG';
import LockSVG from '@components/resources/SVG/Lock/LockSVG';
import Button from '@UIElements/Button';

import './ProductItemToAdd.scss';
import ProductsService from '@services/order/ProductsService';
import BasketService from '@services/order/BasketService';

interface IProductImageProps {
    className?: string;
    catalogItem: IProductItemModel;
    disabled?: boolean;
    isShownForReplacement?: boolean; // флаг того, что товар отображается и его можно выбрать в качестве замены
    isChoosedForReplacement?: boolean; // флаг того, что товар отображается, но он выбран в качестве товара, который хотят заменить
    dataCyValue?: number;
}

const ProductItemToAdd: FunctionComponent<IProductImageProps> = ({
    className,
    catalogItem,
    disabled = false,
    isShownForReplacement,
    isChoosedForReplacement,
    dataCyValue,
}): JSX.Element => {
    const [
        orderService,
        { t },
        productsService,
        basketService,
    ] = useInstances(
        OrderService,
        I18NService,
        ProductsService,
        BasketService,
    );
    const [choosed, setChoosed] = useState<boolean>(false);
    const { replaceTransactionTo, prepareLabelTranslation } = orderService.currentOrder;
    const [blur, setBlur] = useState<boolean>(false);

    useEffect(() => {
        if (replaceTransactionTo) {
            if (replaceTransactionTo === catalogItem.id) {
                setChoosed(true);
                return;
            }
            setChoosed(false);
        }
    }, [replaceTransactionTo]);

    const onClick = useCallback(() => {
        const { replaceTransaction } = orderService.currentOrder;
        if (!isChoosedForReplacement) { // если это НЕ уже выбранный для замены товар
            if (isShownForReplacement) { // если это товар, который можно выбрать в качестве замены
                if (replaceTransaction) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    replaceTransaction.setTo(catalogItem.id);
                }

                return;
            }
            if (!disabled) {
                basketService.addItemFromCatalogToBasket(catalogItem);
            }
        }
    }, [disabled, isShownForReplacement, isChoosedForReplacement]);

    const preparedTranslationForParentItem = useMemo<string>(
        () => prepareLabelTranslation(catalogItem.type, catalogItem.subType, false) as string,
        [catalogItem.type, catalogItem.subType, t],
    );

    const isPossibleToAddToBasket = useMemo<boolean>(
        () => productsService.isPossibleToAddToBasket(catalogItem),
        [productsService.isPossibleToAddToBasket(catalogItem)],
    );

    useEffect(() => {
        if (!isPossibleToAddToBasket) setBlur(false);
    }, [isPossibleToAddToBasket]);

    const addBlur = () => {
        if (blur === false) setBlur(true);
    };

    const titleForTooltip = useMemo<string | null>(
        () => {
            if (!isPossibleToAddToBasket) {
                return !blur ?
                    t(
                        'Товар добавлен в корзину.',
                        'This product added to your cart.',
                    )
                    :
                    t(
                        'Вы уже добавили этот товар в корзину. Увеличьте его количество.',
                        'You have already added this product to your cart. Increase its quantity.',
                    );
            }

            return null;
        },
        [t, isPossibleToAddToBasket, blur],
    );


    return (
        <div
            className={cn([
                className,
                'ProductItemToAdd__wrapper',
                disabled && 'disabled',
                choosed && isShownForReplacement && 'choosed',
            ])}
        >
            {(!isChoosedForReplacement && !isPossibleToAddToBasket) && (
                <div className="ProductBlur">
                    <div className="lock-container">
                        <LockSVG />
                    </div>
                </div>
            )}
            <div className="ProductItemToAdd__product_image__wrapper">
                <ProductImage
                    className="ProductItemToAdd__product_image"
                    productName={catalogItem.name}
                    imageLink={catalogItem.image}
                    productType={catalogItem.type}
                />
            </div>
            {!isShownForReplacement && (
                <span className="ProductItemToAdd__product_type">
                    <ProductLabel
                        productType={null}
                        productSubType={catalogItem.subType}
                        isPromotion={false}
                        text={preparedTranslationForParentItem}
                    />
                </span>
            )}
            <div className="ProductItemToAdd__product_name" title={catalogItem.name}>
                {catalogItem.name}
            </div>
            {!isShownForReplacement && (
                <div className="ProductItemToAdd__product_price_wrapper">
                    <CoinsSVG />
                    {catalogItem.subType === ProductItemSubTypeEnum.GIFT && (
                        <div className="product_price">{t('Бесплатно', 'Free')}</div>
                    )}
                    {catalogItem.subType !== ProductItemSubTypeEnum.GIFT && (
                        <div className="product_price">{catalogItem.price}</div>
                    )}
                </div>
            )}
            {!isChoosedForReplacement && isPossibleToAddToBasket && (
                <Button
                    variant="3"
                    size="0"
                    text={t('Добавить', 'Add')}
                    width="128px"
                    onClick={onClick}
                    dataCyValue={`add_${catalogItem.subType}_${dataCyValue}`}
                />
            )}
            {/* Заглушка -> тут кнопка added неактивная взять из компонента PromoItemToAdd */}
            {isChoosedForReplacement && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={t('Выбрано', 'Choosed')}
                    titleForTooltip={titleForTooltip}
                    dataCyValue={`choosed_${catalogItem.subType}_${dataCyValue}`}
                />
            )}
            {(!isChoosedForReplacement && !isPossibleToAddToBasket) && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={t('Добавлено', 'Added')}
                    titleForTooltip={titleForTooltip}
                    dataCyValue={`added_${catalogItem.subType}_${dataCyValue}`}
                    onMouseOut={addBlur}
                    onMouseEnter={addBlur}
                />
            )}
        </div>
    );
};


export default observer(ProductItemToAdd);
