import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';

import { TStatusInfo } from '@api/auth-api-service/models';
import { CHANGE_PRODUCT_PRICE_PERMISSION_NAME } from '@core/constants/permissions';

import { IStatusInfoModel, StatusInfo } from '@models/mobx-state-tree/statusInfo.model';
import { Sip } from '@models/mobx-state-tree/sip.model';
import { CallCenter } from '@models/mobx-state-tree/callCenter.model';
import { Language } from './language.model';
import {
    AuxStatusesModel,
    IAuxStatusesModel,
    IAuxStatusesModelSnapshotIn,
} from '@models/mobx-state-tree/auxStatuses.model';


export enum UserModeEnum {
    'REGULAR' = 'REGULAR',
    'PREDICTIVE' = 'PREDICTIVE',
    'CLIENT_SERVICE' = 'CLIENT_SERVICE',
    'PROGRESSIVE' = 'PROGRESSIVE',
}

export const User = t
    .model('User', {
        id: t.optional(t.integer, 0),
        username: t.optional(t.string, 'Guest'),
        unreadyAfterSave: t.optional(t.boolean, false),
        timezoneId: t.maybeNull(t.integer),
        timezoneName: t.maybeNull(t.string),
        email: t.maybeNull(t.string),
        authKey: t.maybeNull(t.string),
        status: t.maybeNull(t.integer),
        createdAt: t.maybeNull(t.integer),
        updatedAt: t.maybeNull(t.integer),
        firstName: t.maybeNull(t.string),
        lastName: t.maybeNull(t.string),
        phone: t.maybeNull(t.string),
        registrationToken: t.maybeNull(t.string),
        isReady: t.maybeNull(t.boolean),
        buttonUi: t.optional(t.string, ''),
        isVerificator: t.optional(t.boolean, false),
        checkUnready: t.maybeNull(t.boolean),
        teamId: t.maybeNull(t.integer),
        autoCall: t.maybeNull(t.boolean),
        webrtc: t.maybeNull(t.boolean),
        incomingCall: t.maybeNull(t.boolean),
        lastActivity: t.maybeNull(t.integer),
        useMessenger: t.maybeNull(t.boolean),
        ip: t.maybeNull(t.string),
        outerIp: t.maybeNull(t.string),
        autoCallSystemBlock: t.maybeNull(t.boolean),
        autoCallBlockTime: t.maybeNull(t.integer),
        readyToCall: t.maybeNull(t.boolean),
        sipPlatinum: t.optional(Sip, {}),
        sipAutoCall: t.optional(Sip, {}),
        userAgent: t.maybeNull(t.string),
        filename: t.maybeNull(t.string),
        profileImageFile: t.maybeNull(t.frozen()),
        sip: t.maybeNull(Sip),
        callCenter: t.maybeNull(CallCenter),
        statusInfo: t.optional(t.array(StatusInfo), []),
        permissions: t.optional(t.array(t.string), []),
        predictiveAutocall: t.optional(t.boolean, false),
        customerService: t.optional(t.boolean, false),
        language: t.maybeNull(Language),
        needSpeedTest: t.optional(t.boolean, false),
        validSpeedTest: t.maybeNull(t.boolean),
        disableProgressiveMode: t.optional(t.boolean, false),
        auxStatusesData: t.map(AuxStatusesModel),
        selectedAuxStatus: t.maybeNull(AuxStatusesModel),
        isTimeChangedSipHost: t.optional(t.boolean, false),
    })
    .views(((self) => ({
        get haveSipPlatinumAccountCredential(): boolean {
            return !!(self.sipPlatinum.sipHost && self.sipPlatinum.sipLogin && self.sipPlatinum.sipPass);
        },

        get statusName(): TStatusInfo {
            const status = self.statusInfo.find((x: IStatusInfoModel) => x.id === self.status);
            if (status && status.name) {
                return status.name as TStatusInfo;
            }

            return 'Unknown';
        },

        get canEditProductPrice(): boolean {
            return self.permissions.includes(CHANGE_PRODUCT_PRICE_PERMISSION_NAME);
        },

        get mode(): UserModeEnum {
            if (self.customerService) {
                return UserModeEnum.CLIENT_SERVICE;
            }

            if (self.autoCall && !self.disableProgressiveMode) {
                return UserModeEnum.PROGRESSIVE;
            }

            if (self.predictiveAutocall) {
                return UserModeEnum.PREDICTIVE;
            }

            return UserModeEnum.REGULAR;
        },

        get auxStatuses(): IAuxStatusesModel[] {
            return [...self.auxStatusesData.values()];
        },
    })))
    .actions(((self) => ({
        setIsReady(val: boolean): void {
            if (val === self.isReady) {
                return;
            }

            self.isReady = val;
        },

        setDisableProgressiveMode(disable: boolean): void {
            self.disableProgressiveMode = disable;
        },

        setProfileImage(fileList: FileList) {
            self.profileImageFile = fileList.item(0);
        },

        setSipLogin(val: string) {
            self.sipPlatinum.setSipLogin(val);
        },

        setSipPass(val: string) {
            self.sipPlatinum.setSipPass(val);
        },

        setSipHost(val: string) {
            self.sipPlatinum.setSipHost(val);
        },

        toggleIsReady(): void {
            self.isReady = !self.isReady;
        },

        toggleUnreadyAfterSave(): void {
            self.unreadyAfterSave = !self.unreadyAfterSave;
        },

        setUnreadyAfterSave(value: boolean): void {
            self.unreadyAfterSave = value;
        },

        setTimezoneName(value: string | null) {
            self.timezoneName = value;
        },

        setCustomerUser(value: boolean) {
            self.customerService = value;
        },

        setChangeToPredictiveAutoCall(value: boolean): void {
            self.predictiveAutocall = value;
        },
        setSelectedAuxStatus(status: IAuxStatusesModelSnapshotIn | null): void {
            if (!status) {
                self.selectedAuxStatus = null;
                return;
            }
            self.selectedAuxStatus = AuxStatusesModel.create(status);
        },
        setIsTimeChangedSipHost(value: boolean) {
            self.isTimeChangedSipHost = value;
        },
    })));


export interface IUserModel extends Instance<typeof User> {}
export interface IUserModelSnapshotIn extends SnapshotIn<typeof User> {}
export interface IUserModelSnapshotOut extends SnapshotOut<typeof User> {}
