import {
    IReactionDisposer, reaction,
} from 'mobx';
import { SipService } from '@services/index';
import { Store } from '@store/store';

import { EnumSipStatus } from '../sip/models';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';

class CallHandler {
    constructor(
        private readonly _sipService: SipService,
        private readonly store: Store,
    ) {
    }

    private _disposeStartCallRegularMode: IReactionDisposer | undefined;

    private _disposeOnEndCallRegularMode: IReactionDisposer | undefined;

    public unsubscribe = (): void => {
        if (this._disposeStartCallRegularMode) {
            this._disposeStartCallRegularMode();
        }
        if (this._disposeOnEndCallRegularMode) {
            this._disposeOnEndCallRegularMode();
        }
    };

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    public subscribe = (): void => {
        this.unsubscribe();

        if (this._currentUser.mode === UserModeEnum.REGULAR) {

            this._disposeStartCallRegularMode = reaction(
                () => this._sipService.getSipStatus() === EnumSipStatus.LIVE
                && this._currentOrder.blockButtonNoAnswer.acceptTimeLimitForBlockNoAnswer,
                (startCall) => {
                    if (startCall) {
                        // разговор начался
                        this.store.currentOrder.blockButtonNoAnswer.startTimerBlockNoAnswer();
                    }
                },
            );

            this._disposeOnEndCallRegularMode = reaction(
                () => (this._sipService.getSipStatus() === EnumSipStatus.ENDED
                    || this._sipService.getSipStatus() === EnumSipStatus.FAIL
                    || this._sipService.getSipStatus() === EnumSipStatus.REGISTRATION_FAIL)
                && this._currentOrder.blockButtonNoAnswer.acceptTimeLimitForBlockNoAnswer,
                (isCallEnd) => {
                    if (isCallEnd) {
                        // значит закончили разговор
                        this.store.currentOrder.blockButtonNoAnswer.stopTimerBlockNoAnswer();
                    }
                },
            );

        }

    };

}

export default CallHandler;
