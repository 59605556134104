import CatalogDoesNotContainAnItemForAPromotionalGiftError
    from '@core/error/order-prepare-errors/CatalogDoesNotContainAnItemForAPromotionalGiftError';
import DidFindAStaticSetMappingToAnAffiliateProductError
    from '@core/error/order-prepare-errors/DidFindAStaticSetMappingToAnAffiliateProductError';
import DidNotFindAComparisonOfAlternativeProductWithPartnerProductError
    from '@core/error/order-prepare-errors/DidNotFindAComparisonOfAlternativeProductWithPartnerProductError';
import DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError
    from '@core/error/order-prepare-errors/DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError';
import DidNotFindDynamicSetMappingWithPartnerProductError
    from '@core/error/order-prepare-errors/DidNotFindDynamicSetMappingWithPartnerProductError';
import DynamicSetBadConfigurationError from '@core/error/order-prepare-errors/DynamicSetBadConfigurationError';
import InGiftBalancesError from '@core/error/order-prepare-errors/InGiftBalancesError';
import ItemWasNotFoundInCatalogError from '@core/error/order-prepare-errors/ItemWasNotFoundInCatalogError';
import NoGiftForAPromotionalItemWasFoundError
    from '@core/error/order-prepare-errors/NoGiftForAPromotionalItemWasFoundError';
import NoItemForPromotionWasFoundInCatalogError
    from '@core/error/order-prepare-errors/NoItemForPromotionWasFoundInCatalogError';
import PartnerProductIntegrityError from '@core/error/order-prepare-errors/PartnerProductIntegrityError';
import ProductInOrderWithoutPartnerProductError
    from '@core/error/order-prepare-errors/ProductInOrderWithoutPartnerProductError';
import QuantityInSetIsNullError from '@core/error/order-prepare-errors/QuantityInSetIsNullError';
import SetWithEmptyProductsError from '@core/error/order-prepare-errors/SetWithEmptyProductsError';
import UnknownShippingPriceConditionError
    from '@core/error/order-prepare-errors/promotionBuilder/UnknownShippingPriceConditionError';
import IncorrectCombinationOfAttributesError from '@core/error/order-prepare-errors/promotionBuilder/IncorrectCombinationOfAttributesError';
import UnknownPromotionBuilderError from '@core/error/order-prepare-errors/promotionBuilder/UnknownPromotionBuilderError';
import ProductThatNotBelongPromotionBuilderError from '@core/error/order-prepare-errors/promotionBuilder/ProductThatNotBelongPromotionBuilderError';
import MaxGiftsCountError from '@core/error/order-prepare-errors/promotionBuilder/MaxGiftsCountError';


abstract class OrderBase {
    protected static theseAreErrorsFromCatalogAndBasketPrepareService(error: Error): boolean {
        return error instanceof CatalogDoesNotContainAnItemForAPromotionalGiftError
            || error instanceof DidFindAStaticSetMappingToAnAffiliateProductError
            || error instanceof DidNotFindAComparisonOfAlternativeProductWithPartnerProductError
            || error instanceof DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError
            || error instanceof DidNotFindDynamicSetMappingWithPartnerProductError
            || error instanceof DynamicSetBadConfigurationError
            || error instanceof InGiftBalancesError
            || error instanceof ItemWasNotFoundInCatalogError
            || error instanceof NoGiftForAPromotionalItemWasFoundError
            || error instanceof NoItemForPromotionWasFoundInCatalogError
            || error instanceof PartnerProductIntegrityError
            || error instanceof ProductInOrderWithoutPartnerProductError
            || error instanceof QuantityInSetIsNullError
            || error instanceof SetWithEmptyProductsError
            || error instanceof UnknownShippingPriceConditionError
            || error instanceof IncorrectCombinationOfAttributesError
            || error instanceof UnknownPromotionBuilderError
            || error instanceof ProductThatNotBelongPromotionBuilderError
            || error instanceof MaxGiftsCountError;
    }

    public static addProblematicProductsToErrorMessage(msg: string, problemItems: Record<string, unknown> | Record<string, unknown>[]): string {
        return `${msg} Товары, с которыми произошли проблемы: ${JSON.stringify(problemItems, null, 4)}`;
    }

    public static addProblematicProductsAndSetIdToErrorMessage(msg: string, problemItems: Record<string, unknown> | Record<string, unknown>[], problemSetId: number | undefined): string {
        return `${msg} Товары, с которыми произошли проблемы: ${JSON.stringify(problemItems, null, 4)}, id набора: ${problemSetId}`;
    }

    protected static scrollToDOMElementById(elementId: string): void {
        const ref = document.getElementById(elementId);

        ref?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
}


export default OrderBase;
