
import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import I18NService from '@services/I18NService';
import { useInstance } from 'react-ioc';

interface IEmptyMainProductsNotificationProps {
    readonly animationOnOrErrorFieldsActive: boolean;
    className: string;
}


const EmptyMainProductsNotification: FunctionComponent<IEmptyMainProductsNotificationProps> = ({
    animationOnOrErrorFieldsActive = false,
    className,
}) => {
    const { t } = useInstance(I18NService);

    return (
        <div
            id="EmptyValueForMainProducts"
            className={cn(
                className,
                animationOnOrErrorFieldsActive && 'animate-me bounce',
            )}
        >
            {t(
                'Для сохранения заказа нужен минимум один главный товар',
                'To save an order, you need at least one main product',
            )}
        </div>
    );
};


export default observer(EmptyMainProductsNotification);
