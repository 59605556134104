import { customI18NTFunction } from '@services/I18NService';

class XHR401Error extends Error {
    constructor(t: customI18NTFunction) {
        super();

        if (t) {
            this.message = t(
                'Неверные учетные данные.',
                'Invalid credential',
            );
        } else {
            this.message = 'Invalid credential';
        }
    }
}


export default XHR401Error;
