import React, {
    FunctionComponent, useCallback, useMemo,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import find from 'lodash/find';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import I18NService from '@services/I18NService';
import Select from '@UIElements/Select';
import CallButton from '@UIElements/CallButton';
import { ISelectedValue } from '@UIElements/Select/models';
import SipService from '@services/sip/SipService';
import { EnumSipStatus } from '@services/sip/models';
import cn from 'classnames';
import MicOffSvg from '@components/main/order-page/call-panel/call-widget/includes/MicOffSvg';
import { CustomerModeStore } from '@store/customerModeStore';


const CallButtonAndPhoneCustomerService: FunctionComponent = (): JSX.Element => {
    const [
        { t },
        sipService,
        {
            customerServiceOrderList,
            store: {
                selectedPhoneToCall,
                setSelectedPhoneToCall,
            },
        },
    ] = useInstances(
        I18NService,
        SipService,
        CustomerModeStore,
    );

    const phonesToCallForCallButton: ISelectedValue<string>[] = useMemo(
        () => {
            const allPhones = map(customerServiceOrderList, (item) => {
                const items = [item.customer_mobile || '', item.customer_phone];

                return items
                    .filter(Boolean)
                    .filter((phone) => phone !== '—');
            });

            const allPhonesUniq = uniq(flatten(allPhones)).filter(Boolean);

            return map(allPhonesUniq, (phone) => ({
                label: phone,
                value: phone,
            }));
        },
        [customerServiceOrderList],
    );

    const {
        status: sipStatus,
        muted,
        isCallingRightNow,
        isAnIncomingCallToACustomer,
    } = sipService;

    const onCallClick = useCallback(() => {
        if (!isCallingRightNow) {
            const phone = selectedPhoneToCall || '';
            void sipService.call(phone);
        } else {
            sipService.endCall();
        }
    }, [isCallingRightNow, selectedPhoneToCall]);

    const onSelectedPhone = useCallback((selected: ISelectedValue<string>) => {
        if (setSelectedPhoneToCall) {
            setSelectedPhoneToCall(selected.value);
        }
    }, []);

    const onMuteClick = useCallback(() => {
        if (!(
            sipStatus === EnumSipStatus.LIVE
            || sipStatus === EnumSipStatus.DIALING
            || sipStatus === EnumSipStatus.PROGRESS
        )) {
            return;
        }

        sipService.muted = !muted;
    }, [sipService, sipStatus, muted]);

    const selectedPhoneToCallForComponent: ISelectedValue<string> | null = useMemo(() => {
        if (!selectedPhoneToCall) {
            return null;
        }

        if (phonesToCallForCallButton.length === 0) {
            return {
                value: selectedPhoneToCall,
                label: selectedPhoneToCall,
            };
        }

        const item = find(phonesToCallForCallButton, { value: selectedPhoneToCall });

        if (!item) {
            return null;
        }

        return item;
    }, [selectedPhoneToCall, phonesToCallForCallButton]);

    return (
        <div className="call-widget__center">
            <div className="call-info">
                <div className="call-info-inputs">
                    <Select
                        disabled={sipStatus !== EnumSipStatus.REGISTERED}
                        onSelect={onSelectedPhone}
                        values={phonesToCallForCallButton}
                        selected={selectedPhoneToCallForComponent}
                        placeholder={t('Укажите номер для звонка', 'Enter the number to call')}
                        label={t('Телефон клиента', 'Customer phone')}
                        width="280px"
                        withoutCheckIcon
                        {...isAnIncomingCallToACustomer ? { isAClosedList: true } : null}
                    />
                </div>
            </div>

            <div className="call-time">
                <div className="call-time-row">
                    <div
                        className={cn([
                            'call-time-button',
                            'mic-off',
                            !(
                                sipStatus === EnumSipStatus.LIVE
                                || sipStatus === EnumSipStatus.DIALING
                                || sipStatus === EnumSipStatus.PROGRESS
                            ) && 'disabled',
                            muted && 'muted',
                        ])}
                        onClick={onMuteClick}
                    >
                        <MicOffSvg />
                    </div>
                    <div className="call-button-action">
                        <CallButton
                            onClick={onCallClick}
                            status={sipStatus}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CallButtonAndPhoneCustomerService);
