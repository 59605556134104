import React, { FunctionComponent } from 'react';
import map from 'lodash/map';

import './includes/ListOfRemotePromotion.scss';


type TListOfRemotePromotionBuildersProps = {
    readonly namesOfDeletedPromotionBuilders: string[];
    readonly dataCy?: string;
};


const ListOfRemotePromotion: FunctionComponent<TListOfRemotePromotionBuildersProps> = ({
    namesOfDeletedPromotionBuilders,
    dataCy,
}) => (
    <div
        className="ListOfRemotePromotionBuilders"
        data-cy={dataCy || ''}
    >
        {
            map<string, JSX.Element>(
                namesOfDeletedPromotionBuilders,
                (name) => (
                    <span
                        key={name}
                        className="ListOfRemotePromotionBuilders__nameItem"
                    >
                        {name}
                    </span>
                ),
            )
        }
    </div>
);


export default ListOfRemotePromotion;
