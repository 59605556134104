import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';

export const CustomerServiceOrderCountry = t
    .model('CustomerServiceOrderCountry', {
        charCode: t.string,
        name: t.string,
    });

export interface ICustomerServiceOrderCountryModel extends Instance<typeof CustomerServiceOrderCountry> {}
export interface ICustomerServiceOrderCountrySnapshotIn extends SnapshotIn<typeof CustomerServiceOrderCountry> {}
export interface ICustomerServiceOrderCountrySnapshotOut extends SnapshotOut<typeof CustomerServiceOrderCountry> {}
