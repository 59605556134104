import { EnumSipStatus } from '@services/sip/models';


export interface ICallButtonProps {
    status: EnumSipStatus;
    onClick: () => void;
    forceInactive?: boolean;
}

export enum EnumCallButtonPhase {
    INACTIVE = 'INACTIVE',
    READY = 'READY',
    CALL = 'CALL',
    RINGING = 'RINGING',
}

export interface ICallButtonEnhance {
    phase: EnumCallButtonPhase;
    onClick: () => void;
    forceInactive?: boolean;
}
