import some from 'lodash/some';
import each from 'lodash/each';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import { v4 } from 'uuid';

import { IOrderProduct } from '@api/order-api-service/models';
import IntermediateStateCatalogsAndBaskets from '@/app/order-prepare/IntermediateStateCatalogsAndBaskets';
import { IPartnerProduct } from '@api/partner-api-service/models';
import {
    IProductItemModelSnapshotIn,
    ProductItemSubTypeEnum,
} from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IBasketItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IPromotionBuilderSnapshotIn } from '@models/mobx-state-tree/newModels/PromotionBuilder.models';
import DeletedPromotionsAndProducts from '@/app/order-prepare/DeletedPromotionsAndProducts';
import MaxGiftsCountError from '@core/error/order-prepare-errors/promotionBuilder/MaxGiftsCountError';
import ProductInOrderWithoutPartnerProductError from '@core/error/order-prepare-errors/ProductInOrderWithoutPartnerProductError';
import UnknownPromotionBuilderError from '@core/error/order-prepare-errors/promotionBuilder/UnknownPromotionBuilderError';
import ProductThatNotBelongPromotionBuilderError from '@core/error/order-prepare-errors/promotionBuilder/ProductThatNotBelongPromotionBuilderError';


class OrderPrepareWithPromotionBuilderApplied {
    private static _deleteAnItemById(id: number, items: IOrderProduct[]): void {
        const idx = findIndex<IOrderProduct>(
            items,
            {
                id,
            },
        );

        if (idx !== -1) {
            items.splice(idx, 1);
        }
    }

    constructor(
        private readonly _intermediateStateCatalogsAndBaskets: IntermediateStateCatalogsAndBaskets,
        private readonly _deletedPromotionsAndProducts: DeletedPromotionsAndProducts,
    ) {}

    private _extractAppliedPromotionBuilderFromIntermediateState(
        promotionBuilderItems: IPromotionBuilderSnapshotIn[],
        partnerProductId: string,
        promotionBuilderId: number,
    ): IPromotionBuilderSnapshotIn | undefined {
        const mainProductsWithOnePartnerProductId = filter<IProductItemModelSnapshotIn>(
            this._intermediateStateCatalogsAndBaskets.mainItemsInCatalog,
            {
                partnerProductId,
            },
        );

        const allPromotionBuilders = filter<IPromotionBuilderSnapshotIn>(
            promotionBuilderItems,
            {
                promotionBuilderId,
            },
        );

        return find<IPromotionBuilderSnapshotIn>(
            allPromotionBuilders,
            (item) => some<IProductItemModelSnapshotIn>(
                mainProductsWithOnePartnerProductId,
                {
                    id: item.mainProductModelId,
                },
            ),
        );
    }

    private _disbandPromotionBuilderAndIsolateMainProduct(
        mainProduct: IOrderProduct,
        mainProductModelIdFromPromotionBuilder: string,
    ): void {
        const {
            catalog,
            pushToBasket,
            mainItemsInCatalog,
        } = this._intermediateStateCatalogsAndBaskets;

        const mainProductFromPromotionBuilder = catalog.get(mainProductModelIdFromPromotionBuilder);

        if (mainProductFromPromotionBuilder) {
            const mainProductFromCatalog = find<IProductItemModelSnapshotIn>(
                mainItemsInCatalog,
                {
                    partnerProductId: mainProductFromPromotionBuilder.partnerProductId,
                },
            );

            if (mainProductFromCatalog) {
                const mainBasketItem: IBasketItemModelSnapshotIn = {
                    id: v4(),
                    existingOrderProductId: mainProduct.id,
                    price: mainProduct.price,
                    quantity: mainProduct.quantity,
                    productItem: mainProductFromCatalog.id,
                    editablePrice: false,
                    removed: false,
                };

                pushToBasket(mainBasketItem);
            }
        }
    }

    public searchForProductsAddedWithPromotionBuilder(
        orderId: number,
        orderProducts: IOrderProduct[],
        rawPartnerProducts: IPartnerProduct[],
    ): void {
        const mainProduct = find<IOrderProduct>(
            orderProducts || [],
            {
                gift: false,
                promo: false,
            },
        );

        if (mainProduct) {
            const {
                promotion_builder_id: promotionBuilderId,
                product: {
                    partnerProduct: PP,
                },
            } = mainProduct;

            const mainPartnerProduct = find<IPartnerProduct>(
                rawPartnerProducts,
                {
                    id: PP?.id,
                },
            );

            if (mainPartnerProduct) {
                const selectedDeletedPromotionBuilder = this._extractAppliedPromotionBuilderFromIntermediateState(
                    this._deletedPromotionsAndProducts.deletedPromotionBuilderItems,
                    mainPartnerProduct.id.toString(),
                    promotionBuilderId,
                );

                /**
                 * Если акция обнаружена в удаленных, то расформировываем её
                 */
                if (selectedDeletedPromotionBuilder) {
                    this._disbandPromotionBuilderAndIsolateMainProduct(
                        mainProduct,
                        selectedDeletedPromotionBuilder.mainProductModelId,
                    );
                } else {
                    const currentAppliedPromotionBuilder = this._extractAppliedPromotionBuilderFromIntermediateState(
                        this._intermediateStateCatalogsAndBaskets.promotionBuilderListValues,
                        mainPartnerProduct.id.toString(),
                        promotionBuilderId,
                    );

                    if (currentAppliedPromotionBuilder) {
                        const {
                            pushToPromotionBuilderBasket,
                        } = this._intermediateStateCatalogsAndBaskets;

                        const _orderProducts: IOrderProduct[] = orderProducts;
                        const mandatoryGifts: IProductItemModelSnapshotIn[] = [];
                        const optionalGifts: IProductItemModelSnapshotIn[] = [];
                        const promoProducts: IProductItemModelSnapshotIn[] = [];

                        each<string>(
                            keys(currentAppliedPromotionBuilder.products),
                            (key) => {
                                const _productItem = this._intermediateStateCatalogsAndBaskets.catalog.get(key);

                                if (_productItem?.isFromPromotionBuilder) {
                                    if (_productItem.subType === ProductItemSubTypeEnum.MAIN) {
                                        OrderPrepareWithPromotionBuilderApplied._deleteAnItemById(mainProduct.id, _orderProducts);

                                        const mainBasketItem: IBasketItemModelSnapshotIn = {
                                            id: v4(),
                                            existingOrderProductId: mainProduct.id,
                                            price: mainProduct.price,
                                            quantity: mainProduct.quantity,
                                            productItem: _productItem.id,
                                            editablePrice: false,
                                            removed: false,
                                        };

                                        pushToPromotionBuilderBasket(mainBasketItem);
                                    }

                                    if (_productItem.subType === ProductItemSubTypeEnum.GIFT) {
                                        if (_productItem.mandatoryGift) {
                                            mandatoryGifts.push(_productItem);
                                        } else {
                                            optionalGifts.push(_productItem);
                                        }
                                    }

                                    if (_productItem.subType === ProductItemSubTypeEnum.PROMO) {
                                        promoProducts.push(_productItem);
                                    }
                                }
                            },
                        );

                        each<IProductItemModelSnapshotIn>(
                            mandatoryGifts,
                            (mandatoryGiftItem) => {
                                const _mandatoryGiftItem = find<IOrderProduct>(
                                    _orderProducts,
                                    {
                                        promo: false,
                                        gift: true,
                                        product: {
                                            partnerProduct: {
                                                id: Number(mandatoryGiftItem.partnerProductId),
                                            },
                                        },
                                    },
                                );

                                if (_mandatoryGiftItem) {
                                    OrderPrepareWithPromotionBuilderApplied._deleteAnItemById(_mandatoryGiftItem.id, _orderProducts);

                                    const optionalGiftBasketItem: IBasketItemModelSnapshotIn = {
                                        id: v4(),
                                        existingOrderProductId: _mandatoryGiftItem.id,
                                        price: 0,
                                        quantity: _mandatoryGiftItem.quantity,
                                        productItem: mandatoryGiftItem.id,
                                        editablePrice: false,
                                        removed: false,
                                        mandatoryGift: true,
                                    };

                                    pushToPromotionBuilderBasket(optionalGiftBasketItem);
                                }
                            },
                        );

                        const { maxGiftsCount } = currentAppliedPromotionBuilder;
                        let optionalGiftsCount = 0;

                        each<IProductItemModelSnapshotIn>(
                            optionalGifts,
                            (optionalGiftItem) => {
                                const _optionalGiftItem = find<IOrderProduct>(
                                    _orderProducts,
                                    {
                                        promo: false,
                                        gift: true,
                                        product: {
                                            partnerProduct: {
                                                id: Number(optionalGiftItem.partnerProductId),
                                            },
                                        },
                                    },
                                );

                                if (_optionalGiftItem) {
                                    OrderPrepareWithPromotionBuilderApplied._deleteAnItemById(_optionalGiftItem.id, _orderProducts);

                                    /**
                                     * Если подарка нет на складе, то не добавляем его в номенклатуру
                                     */
                                    if (!optionalGiftItem.quantityInStock) {
                                        this._deletedPromotionsAndProducts.pushDeletedProductsFromPromotion(
                                            optionalGiftItem,
                                            currentAppliedPromotionBuilder,
                                        );

                                        return;
                                    }

                                    const optionalGiftBasketItem: IBasketItemModelSnapshotIn = {
                                        id: v4(),
                                        existingOrderProductId: _optionalGiftItem.id,
                                        price: 0,
                                        quantity: _optionalGiftItem.quantity,
                                        productItem: optionalGiftItem.id,
                                        editablePrice: false,
                                        removed: false,
                                        mandatoryGift: false,
                                    };

                                    pushToPromotionBuilderBasket(optionalGiftBasketItem);

                                    optionalGiftsCount++;
                                }
                            },
                        );

                        if (maxGiftsCount > 0 && optionalGiftsCount > maxGiftsCount) {
                            throw new MaxGiftsCountError(
                                orderId,
                                orderProducts,
                            );
                        }

                        each<IProductItemModelSnapshotIn>(
                            promoProducts,
                            (promoProductItem) => {
                                const _promoProductItem = find<IOrderProduct>(
                                    _orderProducts,
                                    {
                                        promo: true,
                                        gift: false,
                                        product: {
                                            partnerProduct: {
                                                id: Number(promoProductItem.partnerProductId),
                                            },
                                        },
                                    },
                                );

                                if (_promoProductItem) {
                                    OrderPrepareWithPromotionBuilderApplied._deleteAnItemById(_promoProductItem.id, _orderProducts);

                                    const promoBasketItem: IBasketItemModelSnapshotIn = {
                                        id: v4(),
                                        existingOrderProductId: _promoProductItem.id,
                                        price: _promoProductItem.price,
                                        quantity: _promoProductItem.quantity,
                                        productItem: promoProductItem.id,
                                        editablePrice: false,
                                        removed: false,
                                        mandatoryPromo: currentAppliedPromotionBuilder.allPromoMandatory,
                                    };

                                    pushToPromotionBuilderBasket(promoBasketItem);
                                }
                            },
                        );

                        if (!_orderProducts.length) {
                            this._intermediateStateCatalogsAndBaskets.activePromotionBuilderModelId = currentAppliedPromotionBuilder.id;
                        } else {
                            /**
                             * Получили заказ, в котором есть товар,
                             * у которого либо нет promotion_builder_id (т.е. он не относится к конструктору акций),
                             * либо у него promotion_builder_id отличается
                             */
                            throw new ProductThatNotBelongPromotionBuilderError(
                                orderId,
                                {
                                    order_products: _orderProducts,
                                    applied_promotion_builder_id: promotionBuilderId,
                                },
                            );
                        }
                    } else {
                        /**
                         * Ошибка: К.А., который пытаемся добавить в корзину не был найден в промежуточном состоянии каталога,
                         * а значит, скорее всего, не был возвращен в promotion-builder/list API
                         */
                        throw new UnknownPromotionBuilderError(
                            orderId,
                            {
                                promotion_builder_id: promotionBuilderId,
                            },
                        );
                    }
                }
            } else {
                /**
                 * Основной товар К.А. в заказе не найден в партнерских товарах
                 */
                throw new ProductInOrderWithoutPartnerProductError(
                    orderId,
                    mainProduct,
                );
            }
        }
    }
}


export default OrderPrepareWithPromotionBuilderApplied;
