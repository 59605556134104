import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const QueueType = t
    .model('QueueType', {
        id: t.integer,
        name: t.maybeNull(t.string),
    });


export interface IQueueTypeModel extends Instance<typeof QueueType> {}
export interface IQueueTypeModelSnapshotIn extends SnapshotIn<typeof QueueType> {}
export interface IQueueTypeModelSnapshotOut extends SnapshotOut<typeof QueueType> {}
