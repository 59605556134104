import { customI18NTFunction } from '@services/I18NService';

class XHR404Error extends Error {
    constructor(t: customI18NTFunction | undefined, errorMessage: string) {
        super();

        if (t) {
            this.message = t(
                'Данные не найдены.',
                errorMessage,
            );
        } else {
            this.message = errorMessage;
        }
    }
}


export default XHR404Error;
