import React, {
    FunctionComponent, useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import TextInput from '@UIElements/TextInput';
import ContentBlock from '@UIElements/ContentBlock';
import Select from '@UIElements/Select';

import { Store } from '@store/store';

import { AttrsKey } from '@interfaces/form.interface';
import I18NService from '@services/I18NService';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import cn from 'classnames';

import AddedPhone from './added-phone/AddPhone';


const OrderCustomerInfo: FunctionComponent = (): JSX.Element => {
    const [
        { currentOrder },
        { t },
    ] = useInstances(Store, I18NService);
    const {
        form,
        showApproveValidationErrors,
        animationOnOrErrorFieldsActive,
        selectedStatus,
    } = currentOrder;

    const addPhone = () => currentOrder.form.addPhone();

    useEffect(() => {
        if (!form.addedPhonesByOperator.length) {
            currentOrder.form.renderAdditinalPhones();
        }
    }, []);

    return (
        <ContentBlock title={t('Информация о клиенте', 'Customer Info')} id='customerInfo'>
            <div className="orderCustomerInfo__form">
                <div className="orderCustomerInfo__form-block">
                    {
                        form.generalAttrsForForm.map((attribute: IFormAttributesModel) => {

                            const isMinLengthValid = attribute.getIsMinLengthValid(selectedStatus);
                            const isValid = attribute.isValid && isMinLengthValid;

                            const CustomerInput = (props?: any) => (
                                <TextInput
                                    id={attribute.name}
                                    className={cn([
                                        'order-input',
                                        animationOnOrErrorFieldsActive && !isValid && 'animate-me bounce',
                                        attribute.name === AttrsKey.CUSTOMER_MOBILE
                                        && attribute.fieldValue === currentOrder.successPhone
                                        && 'highlight__success-phone',
                                        attribute.name === AttrsKey.CUSTOMER_PHONE
                                        && attribute.customerPhone?.originalValue === currentOrder.successPhone
                                        && 'highlight__success-phone',
                                    ])}
                                    width="296px"
                                    height="40px"
                                    error={attribute.textError}
                                    key={attribute.id}
                                    label={attribute.label ? t(attribute.label) : ''}
                                    onClick={() => currentOrder.setSelectedPhoneToCallId((attribute.id as AttrsKey))}
                                    readonly={attribute.disabled}
                                    isValid={(showApproveValidationErrors || attribute.isExceedingMaxLength) ? isValid : true}
                                    onChangeValue={(value: string) => attribute.setValue(value)}
                                    value={attribute.fieldValue}
                                    type={attribute.fieldType}
                                    dataCyValue={`general_data_attribute_${attribute.id}`}
                                    options={attribute.data}
                                    typeInput={attribute.type}
                                    {...props}
                                />
                            );

                            if (attribute.name === AttrsKey.CUSTOMER_PHONE && attribute.customerPhone) {

                                return CustomerInput({
                                    fixedPart: attribute.customerPhone.invariablePhonePart,
                                    placeholder: '',
                                    onKeyDown: attribute.customerPhone.onKeyDown,
                                });
                            }

                            if (attribute.type === 'list') {

                                const values = attribute.values.map(el => ({ label: el, value: el }));

                                const error = showApproveValidationErrors && !attribute.isValid ?
                                    t('Поле не валидно', 'The field is not valid.') : '';

                                return Select({
                                    id: attribute.name,
                                    className : cn([
                                        'order-input',
                                        animationOnOrErrorFieldsActive && !attribute.isValid && 'animate-me bounce',
                                    ]),
                                    error: error,
                                    selected: attribute.selected,
                                    onSelect: (value: { label: string; value: string }) => {
                                        attribute.onSelect(value);
                                    },
                                    setKey: attribute.setKey,
                                    values,
                                    width: '296px',
                                    height: '40px',
                                    placeholder: attribute.label ? t(attribute.label) : '',
                                    key: attribute.id,
                                    keys: attribute.keys,
                                    showClearSelectedButton: true,
                                    clearSelectedButton: () => {
                                        attribute.onSelect({ label : '', value: '' }, true);
                                    },
                                    label: attribute.label ? t(attribute.label) : '',
                                });
                            }

                            return CustomerInput({
                                placeholder: attribute.label ? t(attribute.label) : '',
                            });
                        })
                    }
                    {
                        form.addedPhonesByOperator.map((field: IFormAttributesModel, idx: number) => (
                            <AddedPhone
                                id={field.id}
                                className={cn([
                                    animationOnOrErrorFieldsActive && !field.isValid && 'animate-me bounce',
                                    field.fieldValue === currentOrder.successPhone && 'highlight__success-phone',
                                ])}
                                key={field.id}
                                index={idx}
                                label={field.getAdditionalPhoneLabel(idx)}
                                placeholder={field.getAdditionalPhoneLabel(idx)}
                                onRemove={() => form.deleteAddedPhone(field.id)}
                                onAdd={addPhone}
                                onChangeNumber={(value: string) => field.setValue(value)}
                                value={field.fieldValue}
                                isValid={showApproveValidationErrors ? field.isValid : true}
                                onClick={() => currentOrder.setSelectedPhoneToCallId((field.id))}
                            />
                        ))
                    }
                </div>
            </div>
        </ContentBlock>
    );
};


export default observer(OrderCustomerInfo);
