import { IReactionDisposer, reaction } from 'mobx';
import {
    IDisposer,
    IJsonPatch,
    onPatch,
} from 'mobx-state-tree';

import { Store } from '@store/store';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { OrderService } from '@services/order';
import SipService from '@services/sip/SipService';
import { DEFAULT_CURRENT_ORDER, DEFAULT_USER } from '@core/constants/defaultStoreItems';
import UserService from '@services/UserService';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { IS_READY_STATUS_ENUM } from '@api/auth-api-service/models';
import UserApiService from '@api/user-api-service';
import { IAuxStatusesModel } from '@models/mobx-state-tree/auxStatuses.model';


class CommonStorageEventSubscriptionService {
    private _disposerChangeCallMode: IReactionDisposer;

    private _disposerOnChangeUserReady: IDisposer;

    private _disposerOnChangeSelectedAuxStatus: IDisposer;

    private _disposerResetUiState: IReactionDisposer;

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _currentUser(): IUserModel {
        return this._store.currentUser;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _sipService: SipService,
        private readonly _userService: UserService,
        private readonly _userApiService: UserApiService,
    ) {}

    private async onChangeUserReady(patch: IJsonPatch, reversePatch: IJsonPatch): Promise<void> {
        // Добавлена проверка this._currentUser.id === DEFAULT_USER.id в рамках https://2wtrade-tasks.atlassian.net/browse/UI-249
        if (!this._userService.isAuthCookieValid || this._currentUser.id === DEFAULT_USER.id) {
            return;
        }

        const { op: patchOp, value: patchValue, path: patchPath } = patch;
        const { value: reversePatchValue } = reversePatch;

        const isReplace = patchOp === 'replace';
        const isReadyChangePath = patchPath === '/isReady';

        if (isReplace && isReadyChangePath && reversePatchValue !== patchValue) {
            const status = patchValue ? IS_READY_STATUS_ENUM.READY : IS_READY_STATUS_ENUM.UN_READY;

            if (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE) {
                if (status === IS_READY_STATUS_ENUM.READY) {
                    this._sipService.connect();
                } else {
                    this._sipService.disconnect();
                }
            }

            try {
                await this._userApiService.changeUserReady(status);
                if (status === IS_READY_STATUS_ENUM.READY) {
                    await this._userService.startUserActivityPolling();
                }
            } catch (e) {
                // TODO: Обработать ситуацию когда api не смог сменить статус пользователя.
                // eslint-disable-next-line no-console
                console.error(e);
                this._currentUser.setIsReady(false);
            }
        }
    }

    public subscribe = (): void => {
        // Подписка на событие о смене режима звонка: обычный / предиктивный (predictive auto call)
        this._disposerChangeCallMode = reaction(
            (): boolean => Number(this._currentOrder.id) === DEFAULT_CURRENT_ORDER.id && this._currentUser.predictiveAutocall,
            (condition: boolean) => {
                if (condition) {
                    this._orderService.stopPollingOrder();
                    this._currentUser.setIsReady(false);
                }
            },
        );

        this._disposerOnChangeUserReady = onPatch(
            this._store.currentUser,
            this.onChangeUserReady.bind(this),
        );

        this._disposerOnChangeSelectedAuxStatus = reaction(
            (): IAuxStatusesModel | null => this._currentUser.selectedAuxStatus,
            (condition: IAuxStatusesModel | null) => {
                if (this._currentOrder.isEmptyCurrentOrder && !this._store.notificationWithFeedbackToView) {
                    if (condition as IAuxStatusesModel) {
                        void this._userService.changeAuxStatus(condition!);
                        this._currentUser.setIsReady(true);
                        if (this._currentUser.mode === UserModeEnum.PROGRESSIVE) {
                            this._sipService.disconnect();
                        }
                    } else if ((this._currentUser.isReady || !this._currentUser.isReady) && this._currentUser.mode === UserModeEnum.PROGRESSIVE) {
                        this._sipService.connect();
                    }
                }
            },
        );

        /**
         * Если статус точек слома дизайна изменился то скидываем ui к дефолтному значению
         */
        this._disposerResetUiState = reaction<string>(
            () => this._store.responsive.hash,
            () => {
                this._store.resetUIState();
            },
        );
    };

    public unsubscribe = (): void => {
        if (this._disposerChangeCallMode) {
            this._disposerChangeCallMode();
        }

        if (this._disposerOnChangeUserReady) {
            this._disposerOnChangeUserReady();
        }

        if (this._disposerOnChangeSelectedAuxStatus) {
            this._disposerOnChangeSelectedAuxStatus();
        }

        if (this._disposerResetUiState) {
            this._disposerResetUiState();
        }
    };
}


export default CommonStorageEventSubscriptionService;
