import React, { FC, useCallback } from 'react';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';

import { Store } from '@store/store';
import { observer } from 'mobx-react';
import { ITranslationList } from '@api/translation-api-service/model';
import FlagIconCss from '@/plugins/FlagIconCss';

import './includes/langList.scss';


const LanguageList: FC = () => {
    const [
        {
            changeLanguage,
            getParsedLanguageList,
        },
        {
            responsive: {
                mobile,
            },
        },
    ] = useInstances(I18NService, Store);

    const onClick = useCallback((languageItem: ITranslationList) => () => {
        if (!mobile) {
            void changeLanguage(languageItem);
        }
    }, [I18NService]);

    return (
        <div
            className="langList"
        >
            <div className="langList__wrapper">
                {
                    getParsedLanguageList.map((x, idx: number) => (
                        <div
                            className="langList__content"
                            key={`language_${idx}`}
                            onClick={onClick(x)}
                            title={x.name}
                        >
                            <FlagIconCss
                                className="langIcon"
                                icon={x.icon}
                                squared={true}
                            />
                            <span
                                className="langList__title"
                            >
                                {x.name}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};


export default observer(LanguageList);
