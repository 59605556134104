import { toFactory } from 'react-ioc';

import CheckCookieEnabledService from '@core/services/CheckCookieEnabledService';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';


const CheckCookieEnabledServiceDI = (): any => [
    CheckCookieEnabledService,
    toFactory(
        [ModalService, I18NService],
        (modalService: ModalService, I18NService: I18NService) => new CheckCookieEnabledService(modalService, I18NService),
    ),
];


export default CheckCookieEnabledServiceDI;
