import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import cn from 'classnames';
import ProblemSvg from '@components/main/order-page/call-panel/call-widget/includes/ProblemSvg';
import { Store } from '@store/store';
import ModalService from '@core/services/ModalService';
import CommunicationQualityModal
    from '@components/main/order-page/modal-windows/communication-quality-modal/CommunicationQualityModal';


interface IIProblemBtnDesktopProps {
    isDisable: boolean;
}


const ProblemBtnDesktop: FunctionComponent<IIProblemBtnDesktopProps> = ({ isDisable }): JSX.Element => {
    const [
        store,
        modalService,
    ] = useInstances(
        Store,
        ModalService,
    );

    const { currentOrder } = store;

    const {
        communicationsQualityValues,
        lastCallHistoryEvent,
        form: {
            disableStatusSubStatusPhoneButtons: disabled,
        },
    } = currentOrder;

    const lastCallHistoryEventId = !!lastCallHistoryEvent && lastCallHistoryEvent.id;
    const communicationQualityWasSent = !!lastCallHistoryEvent && lastCallHistoryEvent.communicationQualityWasSent;

    const onProblemBtnClick = () => {
        if (isDisable || communicationsQualityValues.length === 0 || !lastCallHistoryEventId || communicationQualityWasSent || disabled) {
            // Если нет критериев оценки качества связи то и не показываем модалку вовсе
            return;
        }

        void modalService.showModal(CommunicationQualityModal);
    };

    return (
        <div
            className={cn([
                'call-time-button',
                'call-problem-button',
                isDisable && 'disabled',
            ])}
            onClick={onProblemBtnClick}
        >
            <ProblemSvg className={cn(!isDisable && 'active')} />
        </div>
    );
};

export default observer(ProblemBtnDesktop);
