import { observable } from 'mobx';


class SnackbarModel {
    @observable
    public text: string;

    @observable
    public actionText?: string;

    public onActionClick?: () => void;

    constructor(
        text: string,
        actionText?: string,
        onActionClick?: () => void,
    ) {
        this.text = text;
        this.actionText = actionText;
        this.onActionClick = onActionClick;
    }
}


export default SnackbarModel;
