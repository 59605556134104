import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';

export enum EnumOrderCommentsAndSmsTabs {
    COMMENTS = 'COMMENTS',
    SMS = 'SMS',
}

export const tabOptions: EnumOrderCommentsAndSmsTabs[] = [
    EnumOrderCommentsAndSmsTabs.COMMENTS,
    EnumOrderCommentsAndSmsTabs.SMS,
];

export const OrderCommentsAndSmsTabs = t
    .model('OrderCommentsAndSmsTabs', {
        activeTab: t.optional(t.enumeration(tabOptions), EnumOrderCommentsAndSmsTabs.COMMENTS),
    })
    .actions((self) => ({
        switchToCommentsTab(): void {
            self.activeTab = EnumOrderCommentsAndSmsTabs.COMMENTS;
        },
        switchToSMSTab(): void {
            self.activeTab = EnumOrderCommentsAndSmsTabs.SMS;
        },
    }));


export interface IOrderCommentsAndSmsTabsModel extends Instance<typeof OrderCommentsAndSmsTabs> {}
export interface IOrderCommentsAndSmsTabsSnapshotIn extends SnapshotIn<typeof OrderCommentsAndSmsTabs> {}
export interface IOrderCommentsAndSmsTabsSnapshotOut extends SnapshotOut<typeof OrderCommentsAndSmsTabs> {}
