import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import OldPromotionService from '@services/order/OldPromotionService';
import { OrderService } from '@services/index';
import OffersService from '@services/order/OffersService';
import ProductsService from '@services/order/ProductsService';
import BasketService from '@services/order/BasketService';
import PromotionBuilderService from '@services/order/PromotionBuilderService';


const OffersServiceDI = (): any => [
    OffersService,
    toFactory(
        [
            Store,
            OrderService,
            OldPromotionService,
            ProductsService,
            BasketService,
            PromotionBuilderService,
        ],
        (
            store: Store,
            orderService: OrderService,
            oldPromotionService: OldPromotionService,
            productsService: ProductsService,
            basketService: BasketService,
            promotionBuilderService: PromotionBuilderService,
        ) => new OffersService(
            store,
            orderService,
            oldPromotionService,
            productsService,
            basketService,
            promotionBuilderService,
        ),
    ),
];


export default OffersServiceDI;
