import React, { FunctionComponent } from 'react';
import { useInstance } from 'react-ioc';
import map from 'lodash/map';

import { IProductItemModel } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';

import Button from '@UIElements/Button';
import I18NService from '@services/I18NService';

import './includes/MissingItemsForPromotionBuilder.scss';


export interface IMissingItemsForPromotionBuilderProps extends IModalFunctionComponentPropsBase {
    missingOptionalGifts: IProductItemModel[];
}


const MissingItemsForPromotionBuilder: FunctionComponent<IMissingItemsForPromotionBuilderProps> = ({
    missingOptionalGifts,
    cancel,
    success,
}) => {
    const { t } = useInstance(I18NService);

    return (
        <div className="MissingItemsForPromotionBuilder">
            <div className="MissingItemsForPromotionBuilder__title">
                {
                    t(
                        'У выбранной акции отсутствуют данные товары на складе\n',
                        'The selected stock is out of stock',
                    )
                }
            </div>
            <div className="MissingItemsForPromotionBuilder__body">
                {missingOptionalGifts.length > 0 && (
                    <div className="missingItemsColumn">
                        <div className="optionalGifts__title">
                            {
                                t(
                                    'Подарки на выбор:',
                                    'Gifts to choose from:',
                                )
                            }
                        </div>
                        {
                            map<IProductItemModel, JSX.Element>(
                                missingOptionalGifts,
                                (gift) => (
                                    <div
                                        key={gift.id}
                                        className="productName"
                                        data-cy={`missing_optional_gift_${gift.nameWithoutSpaces}`}
                                    >
                                        {gift.name}
                                    </div>
                                ),
                            )
                        }
                    </div>
                )}
            </div>
            <div className="MissingItemsForPromotionBuilder__footer">
                <Button
                    dataCyValue="not_agree_to_apply_promotion_without_gifts"
                    variant="2"
                    size="1"
                    text={t('Отмена', 'Cancel')}
                    width="120px"
                    onClick={cancel}
                />
                <Button
                    dataCyValue="agree_to_apply_promotion_without_gifts"
                    variant="7"
                    size="1"
                    text={t('Продолжить', 'Continue')}
                    width="120px"
                    onClick={success}
                />
            </div>
        </div>
    );
};


export default MissingItemsForPromotionBuilder;
