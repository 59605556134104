import React, { FunctionComponent } from 'react';
import cn from 'classnames';


import { useToggle } from '@/utils/useToggle';
import IcAccordionChevronUpSvg from '@UIElements/Accordion/IcAccordionChevronUpSvg';

import './Accordion.scss';


export interface IAccordionItemProps {
    title: string | JSX.Element;
    payload: string | JSX.Element;
    dataCy?: string;
}

const AccordionItem: FunctionComponent<IAccordionItemProps> = ({ title, payload, dataCy }) => {
    const [expanded, toggle] = useToggle(false);

    return (
        <div className="AccordionItem">
            <div
                className="AccordionItem__title"
                onClick={toggle}
                data-cy={dataCy || ''}
            >
                <div
                    className={cn([
                        'AccordionItem__title__value',
                        expanded && 'AccordionItem__title__value__expanded',
                    ])}
                >
                    {title}
                </div>
                <div
                    className={cn([
                        'AccordionItem__toggle_button',
                        !expanded && 'AccordionItem__toggle_button__collapsed',
                    ])}
                >
                    <IcAccordionChevronUpSvg />
                </div>
            </div>
            <div
                className={cn([
                    'AccordionItem__payload',
                    expanded && 'AccordionItem__payload__expanded',
                ])}
            >
                {payload}
            </div>
        </div>
    );
};

export default AccordionItem;
