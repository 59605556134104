import each from 'lodash/each';
import find from 'lodash/find';
import every from 'lodash/every';
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import { v4 } from 'uuid';

import PromotionBuilderApiService from '@api/promotion-builder-api-service';
import { TPromotionBuilder, TPromoBuilderProduct } from '@api/promotion-builder-api-service/models';
import { IPartnerProduct } from '@api/partner-api-service/models';
import {
    IProductItemModelSnapshotIn,
    ProductItemSubTypeEnum,
    ProductItemTypeEnum,
} from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import { IPromotionBuilderSnapshotIn } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';
import IntermediateStateCatalogsAndBaskets from '@/app/order-prepare/IntermediateStateCatalogsAndBaskets';
import UnknownShippingPriceConditionError
    from '@core/error/order-prepare-errors/promotionBuilder/UnknownShippingPriceConditionError';
import {
    ComparisonOperatorsOfDeliveryEnum,
    IDeliveryConditionsModelSnapshotIn,
} from '@models/mobx-state-tree/newModels/promotionBuilderDeliveryConditions.model';
import PartnerProductIntegrityError from '@core/error/order-prepare-errors/PartnerProductIntegrityError';
import IncorrectCombinationOfAttributesError from '@core/error/order-prepare-errors/promotionBuilder/IncorrectCombinationOfAttributesError';
import DeletedPromotionsAndProducts from '@/app/order-prepare/DeletedPromotionsAndProducts';


/**
 * Сервис по запросу конструкторов акций, а так же по их наполнению в модель mobx
 */
class PromotionBuilderFetchAndFill {
    /**
     * Перекодирует численное значение условия применения стоимости доставки для К.А. в перечислимый тип
     * @param value 0 | 1 | 3 | 5 | null - допустимые значения
     * @param promotionBuilderId - идентификатор конструктора акций
     * @param orderId - идентификатор заказа
     * @returns ComparisonOperatorsOfDeliveryEnum or throws Error
     */
    private static _switchPriceCondition(
        value: number | null,
        promotionBuilderId: number,
        orderId: number,
    ): ComparisonOperatorsOfDeliveryEnum {
        switch (value) {
            case null:
            case 0:
                return ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY;
            case 1:
                return ComparisonOperatorsOfDeliveryEnum.EQUAL;
            case 3:
                return ComparisonOperatorsOfDeliveryEnum.MORE;
            case 5:
                return ComparisonOperatorsOfDeliveryEnum.MORE_OR_EQUAL;
            default:
                throw new UnknownShippingPriceConditionError(
                    orderId,
                    {
                        promotion_builder_id: promotionBuilderId,
                        promotion_shipping_price_condition: value,
                    },
                );
        }
    }

    constructor(
        private readonly _promotionBuilderApiService: PromotionBuilderApiService,
        private readonly _intermediateStateCatalogsAndBaskets: IntermediateStateCatalogsAndBaskets,
        private readonly _deletedPromotionsAndProducts: DeletedPromotionsAndProducts,
    ) {}

    public async fillInPromotionBuilders(
        orderId: number,
        rawPartnerProducts: IPartnerProduct[],
    ): Promise<void> {
        const {
            uniqIdsMainProduct,
        } = this._intermediateStateCatalogsAndBaskets;

        if (uniqIdsMainProduct.length) {
            const promotionBuilderListAPI = await this._promotionBuilderApiService.fetchPromotionBuilderList(
                orderId,
                uniqIdsMainProduct,
            );

            each<TPromotionBuilder>(
                promotionBuilderListAPI.promotionBuilderList,
                (promotionBuilder) => {
                    const {
                        id: promotionBuilderId,
                        name: promotionBuilderName,
                        promotion_description: promotionDescr,
                        main_products_data: mainProducts,
                        gift_products_data: optionalGifts,
                        default_gift_products_data: mandatoryGifts,
                        promo_products_data: promoProducts,
                        promotion_count_activate: promotionCountActivate,
                        all_promo_mandatory: allPromoMandatory,
                        promotion_builder_delivery: delivery,
                        max_gifts_count: maxGiftsCount,
                    } = promotionBuilder;

                    /**
                     * Создаем модели товаров: опциональные/обязательные подарки и промо
                     * Затем будем линковать эти товары для каждого К.А. в разрезе основных товаров
                     */
                    const _mandatoryGifts: IProductItemModelSnapshotIn[] = [];
                    const _optionalGifts: IProductItemModelSnapshotIn[] = [];
                    const _promoProducts: IProductItemModelSnapshotIn[] = [];

                    each<TPromoBuilderProduct>(
                        optionalGifts.products,
                        (optionalGift) => {
                            const partnerProductDataForAGift = find<IPartnerProduct>(
                                rawPartnerProducts,
                                (item) => item.id === optionalGift.id,
                            );

                            if (!partnerProductDataForAGift) {
                                throw new PartnerProductIntegrityError(
                                    orderId,
                                    {
                                        optional_gift_product_data: optionalGift,
                                    },
                                );
                            }

                            // Наборы товаров не могут входить в состав К.А. (изменения в ТЗ были зафиксированы)
                            if (!partnerProductDataForAGift.is_product_set && !partnerProductDataForAGift.is_dynamic_set) {
                                const optionalGiftItem: IProductItemModelSnapshotIn = {
                                    id: v4(),
                                    type: ProductItemTypeEnum.REGULAR,
                                    subType: ProductItemSubTypeEnum.GIFT,
                                    partnerProductId: String(partnerProductDataForAGift.id),
                                    productId: String(partnerProductDataForAGift.product.id),
                                    name: partnerProductDataForAGift.product.name,
                                    price: 0,
                                    quantityInStock: partnerProductDataForAGift.quantity, // Кол-во товара на складе
                                    image: partnerProductDataForAGift.image,
                                    isFromPromotionBuilder: true,
                                    maxAmount: optionalGift.count,
                                    mandatoryGift: false,
                                    originalSetProductCompositions: null,
                                };

                                _optionalGifts.push(optionalGiftItem);
                            }
                        },
                    );

                    each<TPromoBuilderProduct>(
                        mandatoryGifts.products,
                        (mandatoryGift) => {
                            const partnerProductDataForAGift = find<IPartnerProduct>(
                                rawPartnerProducts,
                                (item) => item.id === mandatoryGift.id,
                            );

                            if (!partnerProductDataForAGift) {
                                throw new PartnerProductIntegrityError(
                                    orderId,
                                    {
                                        default_gift_products_data: mandatoryGift,
                                    },
                                );
                            }

                            // Наборы товаров не могут входить в состав К.А. (изменения в ТЗ были зафиксированы)
                            if (!partnerProductDataForAGift.is_product_set && !partnerProductDataForAGift.is_dynamic_set) {
                                const mandatoryGiftItem: IProductItemModelSnapshotIn = {
                                    id: v4(),
                                    type: ProductItemTypeEnum.REGULAR,
                                    subType: ProductItemSubTypeEnum.GIFT,
                                    partnerProductId: String(partnerProductDataForAGift.id),
                                    productId: String(partnerProductDataForAGift.product.id),
                                    name: partnerProductDataForAGift.product.name,
                                    price: 0,
                                    quantityInStock: partnerProductDataForAGift.quantity, // Кол-во товара на складе
                                    image: partnerProductDataForAGift.image,
                                    isFromPromotionBuilder: true,
                                    maxAmount: mandatoryGift.count,
                                    mandatoryGift: true,
                                    originalSetProductCompositions: null,
                                };

                                _mandatoryGifts.push(mandatoryGiftItem);
                            }
                        },
                    );

                    each<TPromoBuilderProduct>(
                        promoProducts.products,
                        (promoProduct) => {
                            const partnerProductDataForAPromo = find<IPartnerProduct>(
                                rawPartnerProducts,
                                (partnerProduct) => promoProduct.id === partnerProduct.id,
                            );

                            if (!partnerProductDataForAPromo) {
                                throw new PartnerProductIntegrityError(
                                    orderId,
                                    {
                                        promo_product_data: promoProduct,
                                    },
                                );
                            }

                            // Наборы товаров не могут входить в состав К.А. (изменения в ТЗ были зафиксированы)
                            if (!partnerProductDataForAPromo.is_product_set && !partnerProductDataForAPromo.is_dynamic_set) {
                                const promoProductItem: IProductItemModelSnapshotIn = {
                                    id: v4(),
                                    type: ProductItemTypeEnum.REGULAR,
                                    subType: ProductItemSubTypeEnum.PROMO,
                                    partnerProductId: String(partnerProductDataForAPromo.id),
                                    productId: String(partnerProductDataForAPromo.product.id),
                                    mandatoryGift: false,
                                    name: partnerProductDataForAPromo.product.name,
                                    price: promoProduct.price,
                                    originalPrice: partnerProductDataForAPromo.in_currency && partnerProductDataForAPromo.price ? Number(partnerProductDataForAPromo.price) : null, // от этой цены будем считать скидку
                                    quantityInStock: partnerProductDataForAPromo.quantity, // Кол-во товара на складе
                                    image: partnerProductDataForAPromo.image,
                                    isFromPromotionBuilder: true,
                                    originalSetProductCompositions: null,
                                    maxAmount: promoProduct.count,
                                };

                                _promoProducts.push(promoProductItem);
                            }
                        },
                    );

                    /**
                     * Акцию можно добавить в каталог если есть главный товар и имеются все обязательные подарки на складе.
                     */
                    const itIsPossibleToAddAPromotionBuildToCatalog = mainProducts.products.length;

                    /**
                     * Создаем модели товаров в каталог относительно главных товаров (их может быть > 1),\
                     * Кейс: имеем массив из 3 основных товаров. Должны создать 3 модели К.А. и соответствующий набор товаров под них
                     */
                    if (itIsPossibleToAddAPromotionBuildToCatalog) {
                        each<TPromoBuilderProduct>(
                            mainProducts.products,
                            (mainProduct) => {
                                /**
                                 * 1. Ищем товар в партнерских товарах. Основной товар должен быть в валюте (in_currency = true).
                                 * 2. Промо товары и подарки могут быть без валюты.
                                 */
                                const partnerProductData = find<IPartnerProduct>(
                                    rawPartnerProducts,
                                    (item) => item.id === mainProduct.id && item.in_currency,
                                );

                                if (!partnerProductData) {
                                    throw new PartnerProductIntegrityError(
                                        orderId,
                                        {
                                            main_product_data: mainProduct,
                                        },
                                    );
                                }

                                if (partnerProductData?.is_dynamic_set || partnerProductData?.is_product_set) {
                                    // ТЗ переделали: к набору товаров не может быть применен К.А.
                                    return;
                                }

                                const price = mainProduct.price || Number(partnerProductData.price);
                                if (!price) {
                                    return;
                                }

                                const mainProductItem: IProductItemModelSnapshotIn = {
                                    id: v4(),
                                    type: ProductItemTypeEnum.REGULAR,
                                    subType: ProductItemSubTypeEnum.MAIN,
                                    partnerProductId: String(partnerProductData.id),
                                    productId: String(partnerProductData.product.id),
                                    mandatoryGift: false,
                                    name: partnerProductData.product.name,
                                    price,
                                    quantityInStock: partnerProductData.quantity, // Кол-во товара на складе
                                    image: partnerProductData.image,
                                    isFromPromotionBuilder: true,
                                    amountToAdd: mainProduct.count,
                                    originalSetProductCompositions: null,
                                    mainProductPrice: mainProduct.main_product_price,
                                };

                                this._intermediateStateCatalogsAndBaskets.pushToCatalog(mainProductItem);

                                const allBonusesProductsForPromotionBuilder = concat<IProductItemModelSnapshotIn>(
                                    _mandatoryGifts,
                                    _optionalGifts,
                                    _promoProducts,
                                );

                                each<IProductItemModelSnapshotIn>(
                                    allBonusesProductsForPromotionBuilder,
                                    (item) => this._intermediateStateCatalogsAndBaskets.pushToCatalog(item),
                                );

                                const preparedProductsRecord = reduce<string, Record<string, string>>(
                                    map<IProductItemModelSnapshotIn, string>(
                                        allBonusesProductsForPromotionBuilder,
                                        (product) => product.id,
                                    ),
                                    (acc, key) => {
                                        if (!acc[key]) {
                                            acc[key] = key;
                                        }

                                        return acc;
                                    },
                                    {
                                        [mainProductItem.id]: mainProductItem.id,
                                    },
                                );

                                /**
                                 * Если promotion_use_default_shipping_price = false\
                                 * promotion_shipping_price_condition = null,\
                                 * то выбрасываем ошибку, т.к. это недопустимое пересечение атрибутов
                                 */
                                if (!delivery.promotion_use_default_shipping_price && delivery.promotion_shipping_price_condition === null) {
                                    throw new IncorrectCombinationOfAttributesError(
                                        orderId,
                                        {
                                            promotion_builder_id: promotionBuilderId,
                                            promotion_shipping_price_condition: delivery.promotion_shipping_price_condition,
                                            promotion_use_default_shipping_price: delivery.promotion_use_default_shipping_price,
                                        },
                                    );
                                }

                                const deliveryConditions: IDeliveryConditionsModelSnapshotIn = {
                                    id: 'DeliveryCondition',
                                    useDefaultShippingPrice: delivery.promotion_use_default_shipping_price,
                                    shippingPrice: delivery.promotion_shipping_price,
                                    countWithPromo: delivery.promotion_delivery_count_with_promo,
                                    amountOfProducts: delivery.promotion_delivery_count_products,
                                    condition: PromotionBuilderFetchAndFill._switchPriceCondition(
                                        delivery.promotion_shipping_price_condition,
                                        promotionBuilderId,
                                        orderId,
                                    ),
                                };

                                // товары наполнили -> можно создать модель К.А.
                                const promotionBuilderModel: IPromotionBuilderSnapshotIn = {
                                    id: v4(),
                                    promotionBuilderId,
                                    mainProductModelId: mainProductItem.id,
                                    name: promotionBuilderName,
                                    description: promotionDescr,
                                    products: preparedProductsRecord,
                                    maxGiftsCount: maxGiftsCount || 0,
                                    promotionCountActivate,
                                    allPromoMandatory,
                                    deliveryConditions,
                                };

                                /**
                                 * Заносим удаленные (в будущем) опциональные подарки в модель для оповещения
                                 */
                                each<IProductItemModelSnapshotIn>(
                                    _optionalGifts,
                                    (gift) => {
                                        if (gift.quantityInStock <= 0) {
                                            this._deletedPromotionsAndProducts.pushDeletedProductsFromPromotion(
                                                gift,
                                                promotionBuilderModel,
                                            );
                                        }
                                    },
                                );

                                /**
                                 * Все обязательные подарки должны присутствовать на складе.
                                 */
                                const checkQuantityInStockForMandatoryGifts = every<IProductItemModelSnapshotIn>(
                                    _mandatoryGifts,
                                    (giftItem) => giftItem.quantityInStock > 0,
                                );

                                if (checkQuantityInStockForMandatoryGifts) {
                                    this._intermediateStateCatalogsAndBaskets.pushToPromotionBuilderList(promotionBuilderModel);
                                } else {
                                    this._deletedPromotionsAndProducts.pushToDeletedPromotionBuilder(promotionBuilderModel);
                                }
                            },
                        );
                    }
                },
            );
        }
    }
}


export default PromotionBuilderFetchAndFill;
