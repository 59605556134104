export enum TypesOfConditionsForPromoCarts {
    PRODUCT_QUANTITY = 'product_quantity',
    PRODUCT_PRICE = 'product_price',
}

export enum ConditionsForFreeShipping {
    EQUALLY = '=',
    NOT_EQUAL = '!=',
    MORE = '>',
    LESS = '<',
    MORE_OR_EQUAL = '>=',
    LESS_OR_EQUAL = '<=',
}

export enum AttrsKey {
    CUSTOMER_FULL_NAME = 'customer_full_name',
    CUSTOMER_GENDER = 'customer_gender',
    CUSTOMER_MOBILE = 'customer_mobile',
    CUSTOMER_EMAIL = 'customer_email',
    CUSTOMER_CITY = 'customer_city',
    CUSTOMER_STREET = 'customer_street',
    CUSTOMER_HOUSE_NUMBER = 'customer_house_number',
    CUSTOMER_ZIP = 'customer_zip',
    CUSTOMER_PHONE = 'customer_phone',
    CUSTOMER_ADDRESS_ADD = 'customer_address_add',
    CUSTOMER_PROVINCE = 'customer_province',
    CUSTOMER_DISTRICT = 'customer_district',
    CUSTOMER_STATE = 'customer_state',
    CUSTOMER_ADDRESS_ADDITIONAL = 'customer_address_additional',
    CUSTOMER_LANDMARK = 'customer_landmark',
    CUSTOMER_BARANGAY = 'customer_barangay',
    CUSTOMER_REGION = 'customer_region',
    CUSTOMER_AGE = 'customer_age',
    CUSTOMER_ADDRESS = 'customer_address',
    ADDITIONAL_PHONE = 'additional_phone',
}

export interface IPromoProducts {
    product_id: number;
    product_name: string;
    price: number;
}

export interface IAdditionalPrice {
    addition_type: IAdditionType;
    condition: ConditionsForFreeShipping;
    sign: number;
    use_promo: boolean; // Учитывать промо товары
    use_gift: boolean; // Учитывать подарки
}

export interface IAdditionType {
    id: number;
    type: TypesOfConditionsForPromoCarts;
    name: string;
}

export interface IFormAttributes {
    values: string;
    data: string[];
    id: number;
    name: AttrsKey;
    label: string;
    required: boolean;
    type: string;
    regex: string;
    length: number;
    is_klader: boolean;
    is_kladr_only: boolean;
    is_coordinate: boolean;
    is_disabled_user_data: boolean;
    min_length: number | null;
}

export interface IValuesFormAttribute {
    [key: string]: string;
}

export interface IVirtualButton {
    name: string;
    substatuses: Record<number, string>;
}

export interface IForm {
    id: number;
    use_map: boolean;
    check_address: boolean;
    allow_gifts: boolean | null;
    mask_phones: boolean;
    use_foreign_delivery_broker: boolean;
    use_messenger: boolean;
    min_delivery_days: number;
    disabled_buttons: boolean;
    addition_type: string;
    condition: string;
    quantity: number;
    activate_promo_from: number;
    use_promotion_builder: boolean | null;
    check_zip: boolean;
    max_recall_date: number;
    use_azure_map: boolean;
    use_geonegocios_map: boolean;
    card_payment: boolean;
    card_payment_hold_min: number;
    promoProducts: IPromoProducts[];
    additionalPrice: IAdditionalPrice | null;
    buttons: Record<number, string>;
    generalAttrs: IFormAttributes[];
    addressAttrs: IFormAttributes[];
    virtual_buttons: Record<number, IVirtualButton>;
    sources: any[];
    fastChangeQueue?: IFastChangeQueueRawData;
    /**
     * Учитывать остатки на складе для подарков
     */
    consider_remains: boolean;
    /**
     * Разрешить оптовую продажу
     * https://2wtrade-tasks.atlassian.net/browse/UI-270
     */
    wholesale: boolean;
    /**
     * Количество товаров при достижении которого следует запрашивать подтверждение
     * Если null или 0 то разрешение спрашивать не надо.
     * https://2wtrade-tasks.atlassian.net/browse/UI-270
     */
    wholesale_confirm: number | null;
    /**
     * Не использовать автонабор
     */
    not_use_auto_call_start: boolean;
    /**
     * Можно ли аппрувить заказ без К.С.
     * по умолчанию true, т.е. можно
     */
    approve_without_shipping: boolean;
    data: string[];
    enable_recall_after_call_seconds: number | null;
    extra_price: number;
    max_gifts_count: number;
    use_address_classifier: boolean;
    set_zip_by_district: boolean;
    structure: Record<string, string>;
}

export interface IFastChangeQueueRawData {
    id: number;
    queue: {
        id: number;
        name: string;
    }[];
}
