import {
    getEnv,
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';
import map from 'lodash/map';

import { ISetProductComposition, SetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import { PromotionModel } from '@models/mobx-state-tree/newModels/Promotion.model';
import {
    IPartOfCatalogItemForPromotionInCatalog,
    IPromotionDataForComponent, ITranslationsForPromotionItem,
} from '@components/main/order-page/order-products/catalog/Promotion/helpers/models';
import each from 'lodash/each';
import { IEnv } from '@store/store';

export enum ProductItemTypeEnum {
    REGULAR = 'REGULAR',
    STATIC_SET = 'STATIC_SET',
    DYNAMIC_SET = 'DYNAMIC_SET',
    SET_COMPOSITION_ITEM = 'SET_COMPOSITION_ITEM',
    OLD_PROMOTION = 'OLD_PROMOTION',
}

export enum ProductItemSubTypeEnum {
    GIFT = 'GIFT',
    MAIN = 'MAIN',
    PROMO = 'PROMO',
    SET_COMPOSITION_ITEM = 'SET_COMPOSITION_ITEM',
    OLD_PROMOTION = 'OLD_PROMOTION',
}

export const subTypeOptions: ProductItemSubTypeEnum[] = [
    ProductItemSubTypeEnum.GIFT,
    ProductItemSubTypeEnum.MAIN,
    ProductItemSubTypeEnum.PROMO,
    ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
    ProductItemSubTypeEnum.OLD_PROMOTION,
];

export const typeOptions: ProductItemTypeEnum[] = [
    ProductItemTypeEnum.REGULAR,
    ProductItemTypeEnum.STATIC_SET,
    ProductItemTypeEnum.DYNAMIC_SET,
    ProductItemTypeEnum.SET_COMPOSITION_ITEM,
    ProductItemTypeEnum.OLD_PROMOTION,
];

// Бизнес терминология
// Продукт - что
// Партнерский продукт - у кого, какое(ширина, высота и т.п.), за сколько и за какую валюту

// Единичный товар
export const ProductItemModel = t
    .model('ProductItemModel', {
        id: t.identifier, // генерируемый идентификатор uuid\v4
        type: t.optional(t.enumeration(typeOptions), ProductItemTypeEnum.REGULAR),
        subType: t.optional(t.enumeration(subTypeOptions), ProductItemSubTypeEnum.MAIN),
        partnerProductId: t.string, // идентификатор товара в таблице partner_product на бэке
        productId: t.string, // идентификатор товара в таблице product на бэке
        name: t.string,
        price: t.maybeNull(t.number), // Стоимость 1 ед товара
        originalPrice: t.maybeNull(t.number), // Для промо товара, если в ПП есть цена и она в валюте, то от этой цены будем считать скидку
        quantityInStock: t.number, // Кол-во товара на складе
        quantityInSet: t.maybeNull(t.number),
        image: t.maybeNull(t.string),
        // TODO Временно удалены свойства description и translatedDescription в рамках задачи https://2wtrade-tasks.atlassian.net/browse/UI-405
        // description: t.maybeNull(t.string),
        // translatedDescription: t.maybeNull(t.string),
        originalSetProductCompositions: t.maybeNull(t.map(SetProductComposition)), // Исходный состав сета, если товар типа статического или динамического сета, иначе null
        oldPromotionalProduct: t.maybeNull(PromotionModel), // Если это товар с типом OLD_PROMOTIONAL, то у него должно быть свойство с информацией по акции
        quantityInOldPromotion: t.maybeNull(t.number), // Количество подарка по акции

        /**
         * Атрибуты, специфичные для promotionBuilder:
         */
        /**
         * Этот товар из конструктора акций
         */
        isFromPromotionBuilder: t.optional(t.boolean, false),
        /**
         * Для необязательного подарка будет указано число, \
         * Больше этого количества нельзя указать для этого подарка
         */
        maxAmount: t.maybeNull(t.number),
        /**
         * Для обязательного подарка и основного товара К.А. \
         * будет указываться количество товара, которое нужно добавить \
         * в корзину при применении К.А.
         */
        amountToAdd: t.maybeNull(t.number),
        mandatoryGift: t.optional(t.boolean, false), // Подарок обязательный,
        mainProductPrice: t.maybeNull(t.number),
    })
    .views((self) => ({
        /**
         * Для cypress теста
         */
        get nameWithoutSpaces(): string {
            return self.name.replace(/\s+/g, '');
        },

        /**
         * Если true, значит товар на складе имеется
         */
        get productIsInStock(): boolean {
            return !!self.quantityInStock;
        },

        /**
         * Процент скидки от цены товара в ПП, если там есть цена и она в валюте
         * Если целое число, то показываем в форме ХХ
         * Если есть нецелая часть, то показываем в формате ХХ.Х без округления
         */
        get discountAmount(): number | null {
            if (!self.originalPrice || !self.price) {
                return null;
            }


            if (self.price < self.originalPrice) { // скидка будет, только если цена меньше исходной, иначе это наценка))
                const percent = (self.price / self.originalPrice * 100 - 100) * -1;

                const fractionalPart = Math.floor((percent % 1) * 10); // десятичную часть сдвигаем на 1 влево, чтобы взять первое число после запятой
                const integerPart = Math.trunc(percent); // целая часть
                if (fractionalPart) {
                    return Number(`${integerPart}.${fractionalPart}`);
                }

                return integerPart;
            }

            return null;
        },

        /**
         * Вернуть состав набора товаров, если таковой есть
         */
        get originalSetProductCompositionsValues(): ISetProductComposition[] {
            const { originalSetProductCompositions } = self;

            if (originalSetProductCompositions) {
                return [...originalSetProductCompositions.values()];
            }

            return [];
        },

        getTranslationsForBonusComponentOfOldPromotions(): ITranslationsForPromotionItem {
            const { I18NService: { t } } = getEnv<IEnv>(self);

            return {
                button_there_is_no_possibility: t(
                    'Недоступна',
                    'Not available',
                ),
                button_can_be_added: t(
                    'Применить',
                    'Apply',
                ),
                button_yet_added: t(
                    'Применена',
                    'Applied',
                ),
                label: t(
                    'Акция',
                    'Promotion',
                ),
            };
        },

        getDataForBonusComponentOfOldPromotions(): IPromotionDataForComponent {
            if (self.type === ProductItemTypeEnum.OLD_PROMOTION && self.subType === ProductItemSubTypeEnum.OLD_PROMOTION) {
                const {
                    id: promotionId,
                    giftsValues,
                    price,
                    name,
                    paidAmount,
                    productItem: {
                        image,
                    },
                } = self.oldPromotionalProduct!;

                return {
                    type: ProductItemTypeEnum.OLD_PROMOTION,
                    subType: ProductItemSubTypeEnum.OLD_PROMOTION,
                    name: self.name,
                    promotionName: name,
                    promotionPrice: price!,
                    quantity: paidAmount || null,
                    image: image || null,
                    discount: null,
                    promotionId,
                    price,
                    items: map<IProductItemModel, IPartOfCatalogItemForPromotionInCatalog>(
                        giftsValues,
                        (item) => ({
                            type: item.type,
                            subType: item.subType,
                            name: item.name,
                            image: item.image,
                            quantity: item.quantityInOldPromotion,
                            discount: null,
                            price: 0,
                            partnerProductId: item.partnerProductId,
                        }),
                    ),
                };
            }

            return {} as IPromotionDataForComponent;
        },
    }))
    .views((self) => ({
        /**
         * Вернуть массив partnerProductId товаров состава cтарой акции
         * А так же массив названий товаров состава старой акции
         * В массивы будет включена информация по товару, относительно которого делаем запрос
         * @returns readonly [ names[], partnerProductIds[] ]
         */
        dataOfPromotionInclucingProductNameAndPartnerProductIds(): readonly [string[], string[]] {
            const promotionItem = self.getDataForBonusComponentOfOldPromotions();
            const names: string[] = [self.name]; // сам товар тоже добавляем
            const partnerProductIds: string[] = [self.partnerProductId]; // сам товар тоже добавляем

            each(promotionItem.items, (gift) => {
                names.push(gift.name);
                partnerProductIds.push(gift.partnerProductId);
            });

            return [names, partnerProductIds] as const;
        },
        /**
         * Вернуть массив partnerProductId товаров состава набора
         * А так же массив названий товаров состава набора
         * В массивы будет включена информация по товару, относительно которого делаем запрос
         * @returns readonly [ names[], partnerProductIds[] ]
         */
        dataOfSetIncludingProductNamesAndPartnerProductIds(): readonly [string[], string[]] {
            const setComposition = self.originalSetProductCompositionsValues;
            const names: string[] = [self.name]; // сам товар тоже добавляем
            const partnerProductIds: string[] = [self.partnerProductId]; // сам товар тоже добавляем

            each(setComposition, (setCompositionItem) => {
                names.push(setCompositionItem.originalProductItem.name);
                partnerProductIds.push(setCompositionItem.originalProductItem.partnerProductId);

                if (setCompositionItem.alternatives) {
                    const alternativesArr = setCompositionItem.getAllAlternatives;

                    each(alternativesArr, (alternativeItem) => {
                        names.push(alternativeItem.name);
                        partnerProductIds.push(alternativeItem.partnerProductId);
                    });
                }
            });

            return [names, partnerProductIds] as const;
        },
    }))
    .actions((self) => ({
        setPrice(value: number): void {
            self.price = value;
        },
    }));


export interface IProductItemModel extends Instance<typeof ProductItemModel> {}
export interface IProductItemModelSnapshotIn extends SnapshotIn<typeof ProductItemModel> {}
export interface IProductItemModelSnapshotOut extends SnapshotOut<typeof ProductItemModel> {}
