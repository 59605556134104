import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';

import { CallHistoryStatus } from '@models/mobx-state-tree/callHistoryStatus.model';
import { CallHistoryProvider } from '@models/mobx-state-tree/callHistoryProvider.model';
import { CallHistoryUser } from '@models/mobx-state-tree/callHistoryUser.model';


export const CallHistory = t
    .model('CallHistory', {
        id: t.identifier,
        user: t.maybeNull(CallHistoryUser),
        orderId: t.number,
        startTime: t.number,
        endTime: t.maybeNull(t.number),
        startStatus: t.maybeNull(CallHistoryStatus),
        endStatus: t.maybeNull(CallHistoryStatus),
        duration: t.maybeNull(t.number),
        createdAt: t.maybeNull(t.number),
        updatedAt: t.maybeNull(t.number),
        actionAddressTime: t.maybeNull(t.number),
        phone: t.maybeNull(t.string),
        recordingFile: t.maybeNull(t.string),
        disposition: t.maybeNull(t.string),
        billSec: t.maybeNull(t.number),
        userSip: t.maybeNull(t.string),
        provider: t.maybeNull(CallHistoryProvider),
        isWebRTC: t.maybeNull(t.boolean),
        pdd: t.maybeNull(t.string),
        operatorRefreshed: t.maybeNull(t.boolean),
        sipCode: t.maybeNull(t.string),
        predictiveTaskId: t.maybeNull(t.number),
        groupId: t.maybeNull(t.string),
        endCall: t.maybeNull(t.number),
    });


export interface ICallHistoryModel extends Instance<typeof CallHistory> {}
export interface ICallHistoryModelSnapshotIn extends SnapshotIn<typeof CallHistory> {}
export interface ICallHistoryModelSnapshotOut extends SnapshotOut<typeof CallHistory> {}
