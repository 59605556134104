import React, { FC } from 'react';
import Accordion from '@UIElements/Accordion/Accordion';
import AccordionItem, { IAccordionItemProps } from '@UIElements/Accordion/AccordionItem';
import map from 'lodash/map';

import './FAQ.scss';
import Spinner from '@UIElements/Spinner';


export interface IItem extends IAccordionItemProps {
    id: string;
}

interface IProps {
    title: string;
    noResultMessage: string;
    items: IItem[];
    pending?: boolean;
    pendingText?: string;
}

const FAQ: FC<IProps> = (
    {
        title,
        noResultMessage,
        items,
        pending = false,
        pendingText = '',
    },
) => (
    <div className="FAQ">
        <div className="FAQ__header">
            {title}
        </div>
        {pending && (
            <Spinner className="FAQ__spinner" text={pendingText} />
        )}
        {!pending && items.length === 0 && (
            <div className="no-result">
                {noResultMessage}
            </div>
        )}
        {!pending && items.length > 0 && (
            <Accordion>
                {map(items, (item) => (
                    <AccordionItem
                        key={item.id}
                        title={item.title}
                        payload={item.payload}
                    />
                ))}
            </Accordion>
        )}
    </div>
);

export default FAQ;
