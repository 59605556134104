import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import { OrderStatusesTypeERPEnum } from '@api/order-api-service/models';
import I18NService from '@services/I18NService';
import './StatusName.scss';

type TStatusNameProps = {
    readonly statusName: string;
    readonly erpStatusType: OrderStatusesTypeERPEnum;
};


const StatusName: FunctionComponent<TStatusNameProps> = ({
    statusName,
    erpStatusType,
}) => {
    const { t } = useInstance(I18NService);

    const statusToClassName = () => {
        switch (erpStatusType) {
            case OrderStatusesTypeERPEnum.UNDEFINED:
                return statusName
                    .toLowerCase()
                    .replace(/ /g, '_');
            case OrderStatusesTypeERPEnum.INITIAL_STATUS:
                return 'erpStatusType__initial';
            case OrderStatusesTypeERPEnum.INTERMEDIATE_STATUS:
                return 'erpStatusType__intermediate';
            case OrderStatusesTypeERPEnum.FINAL_STATUS:
                return 'erpStatusType__final';
            default:
                return '';
        }
    };

    const statusLabel = () => {
        if (erpStatusType !== OrderStatusesTypeERPEnum.UNDEFINED) {
            return t(statusName, statusName);
        }

        return statusName;
    };

    return (
        <div
            className={cn([
                'erpStatusName',
                statusToClassName(),
            ])}
            title={statusLabel()}
        >
            {statusLabel()}
        </div>
    );
};


export default observer(StatusName);
