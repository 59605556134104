import { toFactory } from 'react-ioc';
import { BillingListStore } from '@store/billingListStore';

const BillingListStoreDI = (): any => [
    BillingListStore,
    toFactory(
        () => BillingListStore.create(),
    ),
];

export default BillingListStoreDI;