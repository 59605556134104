import { action, observable } from 'mobx';
import { FunctionComponent } from 'react';
import { customI18NTFunction } from '@services/I18NService';


export enum ConfirmModalType {
    YesCancel = 1,
    Yes = 2,
}

export enum ConfirmModalAction {
    Yes = 'Yes',
    Cancel = 'Cancel',
}

export interface IModalTemplate<T1, T2> {
    id: number | null;
    visible: boolean | null;
    template: FunctionComponent<T1> | null;
    modalEvent: IModalEvent<T2> | null;
}

export interface IModalEvent<TResultModal> {
    resolve(value?: TResultModal): void;
    reject: (value?: boolean | PromiseLike<boolean>) => void;
}

export interface IModalFunctionComponentPropsBase {
    cancel(result?: any): void;
    success(result?: any): void;
    readonly t: customI18NTFunction;
}


export const DEFAULT_MODAL_ID = 0;


class ModalWindow<TData, TResultModal> implements IModalTemplate<TData, TResultModal> {
    public static ids = DEFAULT_MODAL_ID;

    @observable
    public id: number | null = null;

    @observable
    public visible: boolean | null = null;

    @observable
    public template: FunctionComponent<TData> | null = null;

    @observable
    public modalEvent: IModalEvent<TResultModal> | null = null;

    @observable
    public hideModalHeader: boolean;

    constructor(
        visible: boolean | null,
        template: FunctionComponent<TData> | null,
        modalEvent: IModalEvent<TResultModal> | null,
        hideModalHeader: boolean | undefined,
    ) {
        this.id = ModalWindow.ids++;
        this.visible = visible;
        this.template = template;
        this.modalEvent = modalEvent;
        this.hideModalHeader = hideModalHeader ? true : false;
    }

    @action
    public cancel(imprint?: any): void {
        if (this.modalEvent) {
            this.modalEvent.resolve(imprint);
        }
    }

    @action
    public success(imprint?: TResultModal): void {
        if (this.modalEvent) {
            this.modalEvent.resolve(imprint);
        }
    }
}

export default ModalWindow;
