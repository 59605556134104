import map from 'lodash/map';
import filter from 'lodash/filter';
import { IModelType, IMSTMap, ModelProperties } from 'mobx-state-tree';


import OrderApiService from '@api/order-api-service';
import {
    IOrderProductUpdate,
    IOrderUpdate,
    ISetCompositionRecord,
    OrderStatusesEnum,
} from '@api/order-api-service/models';
import { AttrsKey } from '@interfaces/form.interface';
import { Store } from '@store/store';
import dateConversionToSave from '@core/helpers/dateConversionToSave';
import { IFormModel } from '@models/mobx-state-tree/form.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import ErrorService from '@core/services/ErrorService';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import OfflineError from '@core/error/OfflineError';
import { DeliveriesService } from '@services/index';


enum AddressConfirmationFlag {
    DEFAULT = 0,
    NEEDS_A_CHECK = 1,
    VERIFIED_ADDRESS = 2,
    WITHOUT_CONFIRMATION = 3,
}


class OrderUpdateService {
    private get _t(): customI18NTFunction {
        return this.I18NService.t;
    }

    private get currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get currentForm(): IFormModel {
        return this.store.currentOrder.form;
    }

    private get _paymentCard(): boolean {
        return Boolean(this.currentOrder.paymentCard);
    }

    private get _isCardPaymentAvailable(): boolean {
        return Boolean(this.currentOrder.showCardPayment);
    }

    private get _selectedDeliveryFrom(): Date | null {
        return this.currentOrder.deliveryFrom;
    }

    private get _selectedDeliveryTo(): Date | null {
        return this.currentOrder.deliveryTo;
    }

    private get _dateOfRecall(): Date | null {
        return this.currentOrder.dateOfRecall;
    }

    private get _selectedStatus(): OrderStatusesEnum | null {
        return this.currentOrder.selectedStatus;
    }

    private get _selectedSubStatus(): number | null {
        return this.currentOrder.selectedSubStatus;
    }

    private get _orderCommentDraft(): string {
        return this.currentOrder.orderCommentDraft;
    }

    constructor(
        private readonly store: Store,
        private readonly orderApiService: OrderApiService,
        private readonly I18NService: I18NService,
        private readonly errorService: ErrorService,
        private readonly _deliveriesService: DeliveriesService,
    ) {}

    private static prepareSetProductCompositionsForOrderUpdate = (data: IMSTMap<IModelType<ModelProperties, ISetProductComposition>>): { set: ISetCompositionRecord[] } => ({
        set: map<ISetProductComposition, ISetCompositionRecord>(
            [...data.values()],
            ({ selectedProductItem: { partnerProductId, quantityInSet, productId } }) => ({
                id: Number(partnerProductId),
                quantity: quantityInSet,
                product_id: Number(productId),
            }),
        ),
    });

    private _addressesInfoToSave(): Record<AttrsKey, string> {
        return this.currentForm.addressAttributesForFormToSave
            .reduce((a: Record<string, string>, x: IFormAttributesModel) => {
                const key: AttrsKey = x.name as AttrsKey;

                if (!a[key]) {
                    const item = this.currentForm.addressAttributes.get(key);

                    if (!item) {
                        a[key] = '';
                    } else {
                        a[key] = item.fieldValue;
                    }
                }
                return a;
            }, {});
    }

    private _generalInfoToSave(): Record<AttrsKey, string | number | null> {
        return this.currentForm.generalAttrsForForm
            .reduce((a: Record<string, string | number | null>, x: IFormAttributesModel) => {
                const key: AttrsKey = x.name as AttrsKey;

                if (!a[key]) {
                    const item = this.currentForm.generalAttributes.get(key);

                    if (key === 'customer_gender') {
                        a[key] = item?.key ? Number(item.key) : null;
                    } else {
                        a[key] = item ? item.value : '';
                    }
                }

                return a;
            }, {});
    }

    private _selectedProductsInNormalMode(): IOrderProductUpdate[] {
        const notRemovedItems = filter<IBasketItemModel>(
            this.currentOrder.allCarts,
            (item) => {
                if (
                    item.productItem.subType === ProductItemSubTypeEnum.GIFT
                    && item.promotionId
                    && item.promotionMasterBasketItemId
                ) {
                    const promotionMasterBasketItem = this.currentOrder.additionalParamsCurrentOrder.basket.get(item.promotionMasterBasketItemId);

                    if (!promotionMasterBasketItem) {
                        // Акционный подарок связан с товаром которого нет в корзине
                        return false;
                    }

                    return !promotionMasterBasketItem.removed;
                }

                return !item.removed;
            },
        );

        return map<IBasketItemModel, IOrderProductUpdate>(
            notRemovedItems,
            ({
                existingOrderProductId,
                quantity,
                promotionId,
                setProductCompositions,
                price,
                productItem: {
                    productId,
                    subType,
                    type,
                },
            }) => {
                const isPromo = subType === ProductItemSubTypeEnum.PROMO;
                const isGift = subType === ProductItemSubTypeEnum.GIFT;
                const isMain = subType === ProductItemSubTypeEnum.MAIN;

                let existingOrderProductIdOrderUpdate = null;
                if (typeof existingOrderProductId === 'number') {
                    existingOrderProductIdOrderUpdate = { id: existingOrderProductId };
                }

                let setProductsCompositionsForOrderUpdate = null;
                if (type === ProductItemTypeEnum.DYNAMIC_SET && setProductCompositions) {
                    setProductsCompositionsForOrderUpdate = OrderUpdateService.prepareSetProductCompositionsForOrderUpdate(setProductCompositions);
                }

                let promotionForWhichProductWasAdded = null;
                if ((isGift || isMain) && promotionId) {
                    promotionForWhichProductWasAdded = { package_id: Number(promotionId) };
                }

                return {
                    product_id: Number(productId),
                    promo: isPromo,
                    gift: isGift,
                    price,
                    quantity,
                    ...existingOrderProductIdOrderUpdate,
                    ...setProductsCompositionsForOrderUpdate,
                    ...promotionForWhichProductWasAdded,
                };
            },
        );
    }

    private _selectedProductsInPromotionalBuilderMode(promotionBuilderId: number): IOrderProductUpdate[] {
        const { allPromotionBuilderBasketItems } = this.currentOrder;

        return map<IBasketItemModel, IOrderProductUpdate>(
            allPromotionBuilderBasketItems,
            ({
                existingOrderProductId,
                quantity,
                price,
                productItem: {
                    productId,
                    subType,
                },
            }) => {
                const isPromo = subType === ProductItemSubTypeEnum.PROMO;
                const isGift = subType === ProductItemSubTypeEnum.GIFT;

                let existingOrderProductIdOrderUpdate = null;
                if (typeof existingOrderProductId === 'number') {
                    existingOrderProductIdOrderUpdate = { id: existingOrderProductId };
                }

                return {
                    product_id: Number(productId),
                    promo: isPromo,
                    gift: isGift,
                    price,
                    quantity,
                    promotion_builder_id: promotionBuilderId,
                    ...existingOrderProductIdOrderUpdate,
                };
            },
        );
    }

    private _selectedProductsToSave(): IOrderProductUpdate[] {
        const { appliedPromotionBuilder } = this.currentOrder;

        if (appliedPromotionBuilder) {
            return this._selectedProductsInPromotionalBuilderMode(appliedPromotionBuilder.promotionBuilderId);
        }

        return this._selectedProductsInNormalMode();
    }

    private _addedPhonesInfoToSave(): string[] {
        return this.currentForm.addedPhonesByOperator.map((x: IFormAttributesModel) => x.value);
    }

    private _deliveryFromToSave(): { delivery_from: string } | null {
        if (this._selectedDeliveryFrom) {
            return {
                delivery_from: dateConversionToSave(this._selectedDeliveryFrom),
            };
        }

        return null;
    }

    private _deliveryToToSave(): { delivery_to: string } | null {
        if (this._selectedDeliveryTo) {
            return {
                delivery_to: dateConversionToSave(this._selectedDeliveryTo),
            };
        }

        return null;
    }

    private _isCardPaymentToSave(): { is_card_payment: boolean } | null {
        if (this._isCardPaymentAvailable) {
            return { is_card_payment: this._paymentCard };
        }

        return null;
    }

    private _recallDateToSave(): { recall_date: string } | null {
        if (this._selectedStatus === OrderStatusesEnum.RECALL && this._dateOfRecall) {
            return {
                recall_date: dateConversionToSave(this._dateOfRecall),
            };
        }

        return null;
    }

    private _additionalPhonesToSave(): { additional_phones: string[] } | null {
        const isPhones = this._addedPhonesInfoToSave().some((x) => !!x.length);

        if (isPhones) {
            return {
                additional_phones: this._addedPhonesInfoToSave(),
            };
        }

        return null;
    }

    private _callHistoryGroupIdToSave(): { call_history_group_id: string } | null {
        const { groupId } = this.currentOrder;

        if (groupId) {
            return {
                call_history_group_id: groupId,
            };
        }

        return null;
    }

    private _subStatusToSave(): { sub_status: number } | null {
        if (this._selectedSubStatus) {
            return { sub_status: this._selectedSubStatus };
        }

        return null;
    }

    private _commentToSave(): { comment: string } | null {
        if (this._orderCommentDraft.length) {
            return { comment: this._orderCommentDraft };
        }

        return null;
    }

    private _shippingIdToSave(): { shipping_id: number } | null {
        const { selectedDelivery } = this._deliveriesService;

        if (selectedDelivery) {
            return { shipping_id: Number(selectedDelivery.id) };
        }

        return null;
    }

    private _shippingPriceToSave(): { shipping_price: number } {
        const { selectedDelivery } = this._deliveriesService;

        if (selectedDelivery) {
            if (this._deliveriesService.isConditionForFreeShipping) {
                return {
                    shipping_price: 0,
                };
            }

            return {
                shipping_price: Number(selectedDelivery.price),
            };
        }

        return {
            shipping_price: 0,
        };
    }

    private _coordinatesToSave(): { longitude: string; latitude: string } | null {
        const { longitude, latitude } = this.currentOrder;

        if (longitude && latitude) {
            return { longitude, latitude };
        }

        return null;
    }

    private _confirmAddressToSave(): { confirm_address: AddressConfirmationFlag } | null {
        const { checkAddress, addressAttributeValues } = this.currentForm;

        const isAValueNotFromTheKladr = addressAttributeValues
            .some((address: IFormAttributesModel): boolean => {
                const { hasValueInTheConfirmData, name } = address.kladrResult;

                if (!name) {
                    return false;
                }

                return name.length > 0 && !hasValueInTheConfirmData;
            });

        if (
            checkAddress
            && isAValueNotFromTheKladr
            && this._selectedStatus === OrderStatusesEnum.APPROVE
        ) {
            return { confirm_address: AddressConfirmationFlag.NEEDS_A_CHECK };
        }

        return null;
    }

    private dataCollectionToUpdate(): IOrderUpdate | null {
        if (this._selectedStatus === null) {
            return null;
        }

        return {
            id: this.currentOrder.id,
            status: this._selectedStatus,
            products: this._selectedProductsToSave(),
            general: this._generalInfoToSave(),
            address: this._addressesInfoToSave(),
            ...this._callHistoryGroupIdToSave(),
            ...this._recallDateToSave(),
            ...this._additionalPhonesToSave(),
            ...this._isCardPaymentToSave(),
            ...this._deliveryFromToSave(),
            ...this._deliveryToToSave(),
            ...this._subStatusToSave(),
            ...this._commentToSave(),
            ...this._shippingIdToSave(),
            ...this._shippingPriceToSave(),
            ...this._coordinatesToSave(),
            ...this._confirmAddressToSave(),
        };
    }

    async updateOrder(): Promise<void> {
        const { id: orderId } = this.currentOrder;

        try {
            const data = this.dataCollectionToUpdate();

            if (!data) {
                // eslint-disable-next-line no-console
                console.error('status is null');
            } else {
                await this.orderApiService.orderUpdate(data);
                this.store.inactivityTimer.inactivityOperatorStorageService.storeClearTimePassedForOrderAfterSave(
                    orderId,
                    this.store.currentUser.id,
                );
                this.store.setPrevSavedOrderId(orderId);
            }
        } catch (e) {
            if ('onLine' in navigator) {
                const isOffline = !navigator.onLine;
                if (isOffline) {
                    throw this.errorService.toReturnAFabricatedError(OfflineError);
                }
            }

            throw new Error(e instanceof Error ? this._t(e.message, e.message) : e);
        }
    }
}

export default OrderUpdateService;
