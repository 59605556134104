import some from 'lodash/some';
import each from 'lodash/each';
import filter from 'lodash/filter';
import { computed } from 'mobx';

import { Store } from '@store/store';
import { OrderService } from '@services/index';
import OldPromotionService from '@services/order/OldPromotionService';
import { IProductItemModel, ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import BasketService from '@services/order/BasketService';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';


type TBasketItemModel = IBasketItemModel;
type TProductItemModel = IProductItemModel;


/**
 * Сервис, который содержит методы и вычисляемые свойства для работы с уже добавленными товарами.
 */
class ProductsService {
    @computed
    public get hasPromoItemsInCart(): boolean { // todo: проверить корректность работы
        const { promoItemsInBasket } = this._currentOrder;

        if (promoItemsInBasket.length > 0) {
            return filter(promoItemsInBasket, (item) => item.quantity > 0).length > 0;
        }

        return false;
    }

    @computed
    public get isConditionForPromoCarts(): boolean {
        if (!this._currentOrder.activatePromoFrom) {
            return false;
        }

        return this._currentOrder.quantityOfMainCarts >= this._currentOrder.activatePromoFrom;
    }

    /**
     * Проверяет выполнение условия по добавлению промо-товаров
     */
    @computed
    public get isConditionForPromoCartsPromotionBuilder(): boolean {
        const { appliedPromotionBuilder, quantityOfMainCartsInPromotionBuilderBasket } = this._store.currentOrder;

        if (!appliedPromotionBuilder?.promotionCountActivate) {
            return false;
        }

        return quantityOfMainCartsInPromotionBuilderBasket >= appliedPromotionBuilder.promotionCountActivate;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _oldPromotionService: OldPromotionService,
        private readonly _basketService: BasketService,
    ) {
    }

    /**
     * Получить состав набора товаров по ProductItemModel.ID
     * @param id - IProductItemModel.ID
     */
    public catalogItemOriginalSetProductCompositionsValues(id: string): ISetProductComposition[] | null {
        const catalogItem = this._currentOrder.additionalParamsCurrentOrder.catalog.get(id);

        if (!catalogItem) {
            return null;
        }

        if (!catalogItem?.originalSetProductCompositionsValues) {
            return null;
        }

        return catalogItem?.originalSetProductCompositionsValues;
    }

    /**
     * Проверяет возможность добавления главных товаров и подарков в корзину.
     * @param catalogItem - модель IProductItemModel
     */
    public isPossibleToAddToBasket(catalogItem: TProductItemModel): boolean {
        // Если подарок ранее был добавлен в корзину, то возвращает true
        if (catalogItem.subType === ProductItemSubTypeEnum.GIFT) {
            return this.abilityToAddAGiftToBasket(catalogItem);
        }

        // Если не все товары (catalogItem) в корзине с примененной акцией, то возвращает false.
        if (catalogItem.subType === ProductItemSubTypeEnum.MAIN) {
            return this._oldPromotionService.checkIfAddedProductsHaveAppliedPromotions(catalogItem);
        }

        return true;
    }

    /**
     * Проверяет на наличие ранее добавленных подарков.
     * @param catalogItem - модель IProductItemModel
     */
    public abilityToAddAGiftToBasket(catalogItem: TProductItemModel): boolean {
        return !some<TBasketItemModel>(
            this._currentOrder.giftsNotRelatedToOldPromotions,
            {
                productItem: {
                    productId: catalogItem.productId,
                },
            },
        );
    }

    /**
     * Проверяет на наличие ранее добавленных опциональных подарков в корзине К.А.
     * @param catalogItem - модель IProductItemModel
     */
    public abilityToAddAnOptionalGiftToPromotionBuilderBasket(catalogItem: TProductItemModel): boolean {
        return !some<TBasketItemModel>(
            this._currentOrder.optionalGiftsBasketPromotionBuilder,
            {
                productItem: {
                    partnerProductId: catalogItem.partnerProductId,
                },
            },
        );
    }

    /**
     * Метод проверяет, все ли возможные опциональные подарки были добавлены в корзину
     * На основании этой метки показываем заглушечный компонент выбора опционального подарка
     */
    @computed
    public get isAllOptionalGiftsChoosedPromotionBuilder(): boolean {
        const {
            appliedPromotionBuilder,
            limitAddedOptionalGifts,
        } = this._currentOrder;

        if (appliedPromotionBuilder) {
            const {
                maxGiftsCount,
                presentOptionalGifts,
            } = appliedPromotionBuilder;

            if (maxGiftsCount) {
                return limitAddedOptionalGifts;
            }

            return !some<IProductItemModel>(
                presentOptionalGifts,
                (gift) => this.abilityToAddAnOptionalGiftToPromotionBuilderBasket(gift),
            );
        }

        return false;
    }

    /**
     * Метод проверяет можно ли добавить промо товар в корзину КА
     * На основании этой метки показываем заглушечный компонент выбора опционального promo
     */
    @computed
    public get canChoosePromoToPromotionBuilder(): boolean {
        const { activePromotionBuilderModelId, promotionBuilderCatalog } = this._currentOrder.additionalParamsCurrentOrder;
        const promotionBuilder = promotionBuilderCatalog.get(activePromotionBuilderModelId || '');

        const promoProducts = promotionBuilder?.promoProducts;
        if (promoProducts?.length === 0) {
            return false;
        }

        return !(this._currentOrder.promoItemsBasketPromotionBuilder.length > 0);
    }

    private _changeRemovedPropOfBasket(
        basket: TBasketItemModel,
        propName: 'hideInRow' | 'showInRow',
    ) {
        if (basket.isPromoApplied) {
            each<TBasketItemModel>(
                this._oldPromotionService.getPromoGoodsByProductId(basket.id),
                (gift) => gift[propName](),
            );
        }

        basket[propName]();
    }

    /**
     * Скрыть данный товар из корзины перед удалением.
     */
    public hideProductRow(basket: TBasketItemModel): void {
        this._changeRemovedPropOfBasket(basket, 'hideInRow');
    }

    /**
     * Вернуть товар обратно в корзину.
     */
    public showProductRow(basket: TBasketItemModel): void {
        this._changeRemovedPropOfBasket(basket, 'showInRow');
    }

    /**
     * Получить ProductItem модель по идентификатору BasketItemModel
     * @param id - ID BasketItemModel
     */
    public getProductItemModelByBasketItemModelID(id: string): IProductItemModel | null {
        const basketItem = this._currentOrder.additionalParamsCurrentOrder.basket.get(id);

        if (basketItem) {
            const productItem  = basketItem?.productItem;

            if (productItem) return productItem;
        }

        return null;
    }
}


export default ProductsService;
