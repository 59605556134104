import React from 'react';
import 'flag-icon-css/sass/flag-icon.scss';

interface IFlagProps {
    className?: string;
    squared?: boolean;
    icon: string;
}
// Используем эту библиотку совместно с react-flag-icon-css, т.к. в эту можно передавать название иконок вроде 'flag-icon-ua' (соответствует нужному флагу)
// react-flag-icon-css не удалили, т.к. она используется в других местах, где получаем сразу код страны в формате ISO

const FlagIconCss: React.FC<IFlagProps> = ({ icon, className, squared }) => {
    const additionalClass = squared && 'flag-icon-squared';
    return (<span className={`flag-icon ${icon} ${className} ${additionalClass}`} />);
};


export default FlagIconCss;
