import {
    ICountryDataBase,
    IOrderComment,
    IOrderProduct,
    ISimilarOrder,
    ISubStatusInfo,
} from '@api/order-api-service/models';
import { ICallHistory } from '@api/call-api-service/model';
import { IPartnerProduct } from '@api/partner-api-service/models';
import { IFormAttributes } from '@interfaces/form.interface';
import { ICommunicationQualityItem } from '@api/support-api-service/model';
import { ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';
import { ISimilarOrderModelSnapshotIn } from '@models/mobx-state-tree/similarOrder.model';
import { ICallHistoryModelSnapshotIn } from '@models/mobx-state-tree/callHistory.model';
import { IFormAttributesModelSnapshotIn } from '@models/mobx-state-tree/formAttributes.model';
import { IOrderCommentModelSnapshotIn } from '@models/mobx-state-tree/orderComment.model';
import { ISubStatusModelSnapshotIn } from '@models/mobx-state-tree/subStatus.model';
import { ICommunicationsQualityModelSnapshotIn } from '@models/mobx-state-tree/communicationsQuality.model';
import { IUserCountry } from '@api/user-api-service/models';
import { ISourceType } from '@api/source-api-service/models';
import { ISourceTypeModelSnapshotIn } from '@models/mobx-state-tree/sourceType';
import { ISourceModelSnapshotIn } from '@models/mobx-state-tree/source.model';
import { IPartnerModelSnapshotIn } from '@models/mobx-state-tree/partner.model';
import { ICurrencyModel, ICurrencyModelSnapshotIn } from '@models/mobx-state-tree/currency.model';
import { IBasketItemModelSnapshotIn } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';


export type TypesForCollection =
    IOrderProduct
    | ICountryDataBase
    | ISimilarOrder
    | ICallHistory
    | IOrderComment
    | ISubStatusInfo
    | IPartnerProduct
    | IFormAttributes
    | ICommunicationQualityItem
    | IUserCountry
    | ICurrencyModel
    | ICurrencyModelSnapshotIn
    | ISourceType
    | ISourceModelSnapshotIn
    | IPartnerModelSnapshotIn
    | IBasketItemModelSnapshotIn
    | { id: string | number; name: string };

export type TConvertArrayToCollection =
    ICountryModelSnapshotIn
    | ISimilarOrderModelSnapshotIn
    | ICallHistoryModelSnapshotIn
    | IFormAttributesModelSnapshotIn
    | IOrderCommentModelSnapshotIn
    | ISubStatusModelSnapshotIn
    | ICommunicationsQualityModelSnapshotIn
    | ISourceTypeModelSnapshotIn
    | ISourceModelSnapshotIn
    | ICurrencyModelSnapshotIn
    | IBasketItemModelSnapshotIn
    | { id: string; name: string };

export enum PrepareTypes {
    SIMILAR_ORDERS = 'similar_orders',
    COUNTRIES = 'countries',
    CALL_HISTORIES = 'call_histories',
    ORDER_COMMENTS = 'order_comments',
    PARTNER_PRODUCTS = 'partner_products',
    ORDER_PRODUCTS = 'order_products',
    SUB_STATUSES = 'sub_statuses',
    DYNAMIC_FORMS = 'dynamic_forms',
    COMMUNICATIONS_QUALITY = 'communications_quality',
    SOURCE_TYPES = 'source_types',
    MAIN_DATA = 'main_data',
    CURRENCIES = 'currencies',
    BASKET = 'basket',
    CATALOG = 'catalog',
    QUANTITY_IN_STOCK = 'quantity_in_stock',
}

export enum AttributeTypes {
    ORDER_ADDRESSES = 'order_addresses',
    CUSTOMER_INFO = 'customer_info',
}
