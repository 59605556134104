import get from 'lodash/get';

import ApiBase from '@core/api';
import { getEnvPromotionBuilderListEndpoint } from '@/env/requestEndpoints';
import { TPromotionBuilder, TPromotionBuilderResponse } from '@api/promotion-builder-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}


class PromotionBuilderApiService {
    @Inject apiBase: ApiBase;

    public async fetchPromotionBuilderList(orderId: number, productId: number[]): Promise<TPromotionBuilderResponse> {
        const response = await this.apiBase.get<IWithResponse<TPromotionBuilder[]>>(
            getEnvPromotionBuilderListEndpoint(),
            {
                order_id: orderId,
                product_ids: productId,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return {
            promotionBuilderList: data,
        };
    }
}


export default PromotionBuilderApiService;
