export const CURRENT_USER = 'currentUser';
export const RECALLS = 'recalls';
export const COUNTRY = 'country';
export const ACCESS_TOKEN = 'accessToken';
export const TOKEN_EXPIRE_AT = 'tokenExpireAt';
export const ADVANCED_FRONTEND = 'advanced-frontend';
export const GREETING_USER_ID = 'greetingUserId';
export const GREETING_FLAG = 'greetingFlag';
export const GREETING_TIMESTAMP = 'greetingTimestamp';
export const INTERNET_SPEED_PROBLEMS = 'internetSpeedProblems';
export const STORAGE_INACTIVITY_KEY = 'inactivity_timer';
export const STORAGE_TRANSLATION_KEY = 'languages_for_translation';

export const CALLIBRI = ['callibri', 'v1_timestamp_callibri', 'v1_callibri_operator', 'callibri_position', 'callibri_sending', 'callibri_chat_messages'];
