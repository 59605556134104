import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import ScriptDataService from '@services/script/ScriptDataService';
import SipServiceBase from '@services/sip/SipServiceBase';
import { OrderFetchService, OrderService, UserService } from '@services/index';
import DevicesService from '@core/services/DevicesService';
import { CustomerModeStore } from '@store/customerModeStore';
import ModalService from '@core/services/ModalService';
import LocalStorageService from '@core/services/LocalStorageService';
import CallHistoryApiService from '@api/call-history-api-service';


const SipServiceBaseDI = (): any => [
    SipServiceBase,
    toFactory(
        [
            Store,
            I18NService,
            UserService,
            DevicesService,
            CustomerModeStore,
            OrderFetchService,
            ModalService,
            OrderService,
            LocalStorageService,
            CallHistoryApiService,
            ScriptDataService,
        ],
        (
            store: Store,
            I18NService: I18NService,
            userService: UserService,
            devicesService: DevicesService,
            customerModeStore: CustomerModeStore,
            orderFetchService: OrderFetchService,
            modalService: ModalService,
            orderService: OrderService,
            localStorageService: LocalStorageService,
            callHistoryApiService: CallHistoryApiService,
            scriptDataService: ScriptDataService,
        ) => new SipServiceBase(
            store,
            I18NService,
            userService,
            devicesService,
            customerModeStore,
            orderFetchService,
            modalService,
            orderService,
            localStorageService,
            callHistoryApiService,
            scriptDataService,
        ),
    ),
];


export default SipServiceBaseDI;
