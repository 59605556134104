import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const CallHistoryUser = t
    .model('CallHistoryUser', {
        id: t.identifier,
        username: t.maybeNull(t.string),
    });


export interface ICallHistoryUserModel extends Instance<typeof CallHistoryUser> {}
export interface ICallHistoryUserModelSnapshotIn extends SnapshotIn<typeof CallHistoryUser> {}
export interface ICallHistoryUserModelSnapshotOut extends SnapshotOut<typeof CallHistoryUser> {}
