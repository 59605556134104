import React, { FC } from 'react';
import cn from 'classnames';


interface ICallIconSvgProps {
    className?: string;
}

const CallIconSvg: FC<ICallIconSvgProps> = ({ className }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(['CallIconSvg', className])}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.68752 7.92972H3.1438C3.40459 4.37502 6.37995 1.56251 10 1.56251C12.8647 1.56251 15.4568 3.36681 16.4503 6.05233C16.6 6.45698 17.0493 6.66366 17.4541 6.51401C17.8587 6.36428 18.0654 5.91491 17.9157 5.51022C17.3236 3.90959 16.2717 2.53989 14.8738 1.54919C13.4437 0.535705 11.7584 0 10 0C7.74632 0 5.62745 0.87766 4.03381 2.4713C2.46095 4.04416 1.58614 6.1287 1.56356 8.34953C0.629886 8.89055 0 9.90016 0 11.0547V13.711C0 15.4341 1.40188 16.836 3.12501 16.836H4.68752C5.11901 16.836 5.46877 16.4862 5.46877 16.0548V8.71097C5.46877 8.27949 5.11901 7.92972 4.68752 7.92972ZM3.90609 15.2736H3.12483C2.26327 15.2736 1.56232 14.5726 1.56232 13.711V11.0548C1.56232 10.1932 2.26327 9.49228 3.12483 9.49228H3.90609V15.2736ZM16.7709 8.33333H15.2084C14.7769 8.33333 14.4271 8.6831 14.4271 9.11459V14.4662V16.4584V17.2787C14.4271 17.7095 14.0766 18.0599 13.6459 18.0599H11.263V18.0847C10.9965 17.6041 10.4843 17.2787 9.89584 17.2787C9.03291 17.2787 8.33333 17.9783 8.33333 18.8412C8.33333 19.7041 9.03291 20.4037 9.89584 20.4037C10.4843 20.4037 10.9965 20.0783 11.263 19.5977V19.6224H13.6459C14.9382 19.6224 15.9896 18.571 15.9896 17.2787V17.2396H16.7709C18.494 17.2396 19.8959 15.8377 19.8959 14.1146V11.4583C19.8959 9.73521 18.494 8.33333 16.7709 8.33333ZM18.3335 14.1145C18.3335 14.9761 17.6325 15.6771 16.771 15.6771H15.9897V14.4661V9.89577H16.771C17.6325 9.89577 18.3335 10.5967 18.3335 11.4583V14.1145Z"
            fill="#C2C2C2"
        />
    </svg>
);

export default CallIconSvg;
