// тут ключи ошибок jssip
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { causes } from 'jssip/lib/Constants';
import { Originator, RTCSession } from 'jssip/lib/RTCSession';
import { IncomingResponse } from 'jssip/lib/SIPMessage';

export enum ReasonsForEndingConversation {
    CLIENT_SIDE = 1,
    OPERATOR_SIDE = 2,
    PAGE_REFRESH = 3,
    POOR_CONNECTION_QUALITY = 4,
    SIP_ERROR = 5,
    AUTOMATION_STATUSES = 6,
    LIMIT_PDD = 7,
    MEDIA_ACCESS_DENIED = 8,
}

export enum SipErrorHeadersEnum {
    X_ASTERISK_HANGUPCAUSE = 'X-Asterisk-Hangupcause',
    X_ASTERISK_HANGUPCAUSECODE = 'X-Asterisk-Hangupcausecode',
}

export enum EnumSipStatus {
    /**
     * внутренняя ошибка
     */
    INTERNAL_ERROR = -100,
    /**
     *  отключены от sip
     */
    DISCONNECTED = -10,
    /**
     *  процесс отключения от sip
     */
    DISCONNECTING_IN_PROGRESS = -5,
    /**
     *  подключаемся к sip
     */
    CONNECTING = -1,
    /**
     *  подключились к sip
     */
    CONNECTED = 0,
    /**
     *  регистрируемся на sip
     */
    REGISTERED = 10,
    /**
     *  Идет соединение с клиентом
     */
    DIALING = 20,
    /**
     *  У клиента начал звонить телефон, ждем пока возьмет трубку
     */
    PROGRESS = 30,
    /**
     *  Клиент ответил, идет диалог (для регулярного режима)
     */
    LIVE = 40,
    /**
     *  В прогрессивном режиме взята трубка, идет разговор
     */
    NEW_MESSAGE = 45,
    /**
     *  Разговор закончен, кто-то повешал трубки
     */
    ENDED = 50,
    /**
     *  Что-то сломалось пока звонили клиенту
     */
    FAIL = 100,
    /**
     *  Соединение установили, а вот регистрация завалилась
     */
    REGISTRATION_FAIL = 110,
}

export enum SipHttpErrorCode {
    TRYING = 100,
    RINGING = 180,
    CALL_IS_BEGIN_FORWARDED = 181,
    QUEUED = 182,
    SESSION_PROGRESS = 183,
    EARLY_DIALOG_TERMINATED = 199, // draft-ietf-sipcore-199
    OK = 200,
    ACCEPTED = 202, // RFC 3265
    NO_NOTIFICATION = 204, // RFC 5839
    MULTIPLY_CHOICES = 300,
    MOVED_PERMANENTLY = 301,
    MOVED_TEMPORARILY = 302,
    USE_PROXY = 305,
    ALTERNATIVE_SERVICE = 380,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    PROXY_AUTHENTICATION_REQUIRED = 407,
    REQUEST_TIMEOUT = 408,
    GONE = 410,
    CONDITIONAL_REQUEST_FAILED = 412, // RFC 3903
    REQUEST_ENTITY_TOO_LARGE = 413,
    REQUEST_URI_TOO_LONG = 414,
    UNSUPPORTED_MEDIA_TYPE = 415,
    UNSUPPORTED_URI_SCHEME = 416,
    UNKNOWN_RESOURCE_PRIORITY = 417, // RFC 4412
    I_AM_A_TEAPOT = 418,
    BAD_EXTENSION = 420,
    EXTENSION_REQUIRED = 421,
    SESSION_INTERVAL_TOO_SMALL = 422, // RFC 4028
    INTERVAL_TOO_BRIEF = 423,
    BAD_LOCATION_INFORMATION = 424, // RFC 6442
    USE_IDENTITY_HEADER = 428, // RFC 4474
    PROVIDE_REFERRER_IDENTITY = 429, // RFC 3892
    FLOW_FAILED = 430, // RFC 5626
    ANONYMITY_DISALLOWED = 433, // RFC 5079
    BAD_IDENTITY_INFO = 436, // RFC 4474
    UNSUPPORTED_CERTIFICATE = 437, // RFC 4744
    INVALID_IDENTITY_HEADER = 438, // RFC 4744
    FIRST_HOP_LACKS_OUTBOUND_SUPPORT = 439, // RFC 5626
    MAX_BREADTH_EXCEEDED = 440, // RFC 5393
    BAD_INFO_PACKAGE = 469, // draft-ietf-sipcore-info-events
    CONSENT_NEEDED = 470, // RFC 5360
    UNRESOLVABLE_DESTINATION = 478, // Custom code copied from Kamailio.
    TEMPORARILY_UNAVAILABLE = 480,
    CALL_TRANSACTION_DOES_NOT_EXIST = 481,
    LOOP_DETECTED = 482,
    TOO_MANY_HOPS = 483,
    ADDRESS_INCOMPLETE = 484,
    AMBIGUOUS = 485,
    BUSY_HERE = 486,
    REQUEST_TERMINATED = 487,
    NOT_ACCEPTABLE_HERE = 488,
    BAD_EVENT = 489, // RFC 3265
    REQUEST_PENDING = 491,
    UNDECIPHERABLE = 493,
    SECURITY_AGREEMENT_REQUIRED = 494, // RFC 3329
    JS_SIP_INTERNAL_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    SERVER_TIMEOUT = 504,
    VERSION_NOT_SUPPORTED = 505,
    MESSAGE_TOO_LARGE = 513,
    PRECONDITION_FAILURE = 580, // RFC 3312
    BUSY_EVERYWHERE = 600,
    DECLINE = 603,
    DOES_NOT_EXIST_ANYWHERE = 604,
    NOT_ACCEPTABLE_L = 606,
}

interface SessionEventBase {
    originator: string;
    message?: IncomingResponse;
    cause: causes;
}

export interface SipError {
    code: number | null;
    message: string | null;
    error: causes | null;
}

export interface IConnectFn {
    (sipLogin: string, sipPass: string, sipHost: string): void;
}

export interface IAsteriskHangupCause {
    code: string;
    text: string;
}

export interface ISipOptions {
    password: any;
    host: any;
    login: any;
}

export interface ISipServiceBase {
    setRecallAvailability: (recallAvailability: boolean) => void;
    recallAvailability: boolean;
    getZorraTaskId: () => number;
    zorraTaskId: number | undefined;
    setZorraTaskId: (zorraTaskId: number | undefined) => void;
    reconnectionRequired: boolean;
    getSipErrorCause(): causes | undefined;
    getSipErrorCauseOriginator(): Originator | undefined;
    setSipErrorCauseAndOriginator(cause: causes | undefined, originator: Originator | undefined): void;
    getAsteriskHangupCause: () => IAsteriskHangupCause | null;
    getMuted: () => boolean;
    setMuted: (muted: boolean) => void;
    _status: EnumSipStatus;
    getPhoneNumber: () => string;
    isCallingRightNow: boolean;
    hasSession: boolean;
    getSession: () => RTCSession | undefined;
    sipError: SipError;
    callDirection: string;
    isConnected: boolean;
    isRegistered: boolean;
    setStatus(status: EnumSipStatus): void;
    getStatus(): EnumSipStatus;
    sipErrorMessage: string | null;
    sipErrorCode: number | null;
    setCallTime(callTime: number): void;
    getCallTime(): number;
    getSipOptions: () => null | ISipOptions;
    collectDataForConnectionAndConnect: () => void;
    connectToANewHost(): Promise<void>;
    call(phone: string): void;
    disconnect: () => void;
    endCall: () => void;
    switchProgressiveToRegularMode(): void;
}

export interface SessionFailedEventCustom extends SessionEventBase {}
export interface SessionEndedEventCustom extends SessionEventBase {}
