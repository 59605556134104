import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IGiftSVGProps {
    className?: string;
    onClick?: () => void;
}


const GiftSVG: FunctionComponent<IGiftSVGProps> = ({ className, onClick, ...props }): JSX.Element => (
    <svg
        className={cn(['GiftSVG', className])}
        onClick={onClick}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.52477 4.01626C9.06311 3.64991 8.82771 3.08167 8.89514 2.49607C8.93921 2.11301 8.80815 1.73697 8.53557 1.46446C8.26289 1.19181 7.88689 1.06076 7.5039 1.1049C6.91809 1.17222 6.35009 0.936962 5.98374 0.475295C5.74411 0.173223 5.38556 0 5.00003 0C4.61447 0 4.25592 0.173223 4.01626 0.475261C3.64991 0.936894 3.08187 1.17222 2.4962 1.10493C2.11315 1.06069 1.73711 1.19181 1.46449 1.46443C1.19185 1.73697 1.06079 2.11301 1.10486 2.49614C1.17229 3.08163 0.936928 3.64988 0.475159 4.01629C0.173223 4.25596 0 4.6145 0 5C0 5.3855 0.173189 5.74404 0.475227 5.98378C0.936928 6.35015 1.17229 6.91836 1.10486 7.50397C1.06079 7.88702 1.19185 8.26306 1.46443 8.53557C1.73711 8.80822 2.11342 8.93934 2.4961 8.89514C3.08167 8.82775 3.64991 9.06307 4.01626 9.52474C4.25589 9.82678 4.61444 10 5.00003 10C5.38556 10 5.74411 9.82681 5.98378 9.52477C6.35015 9.06314 6.9182 8.82785 7.50383 8.8951C7.88692 8.93914 8.26293 8.80822 8.53554 8.53561C8.80819 8.26306 8.93924 7.88702 8.89517 7.50393C8.82775 6.9184 9.06314 6.35012 9.52487 5.98374C9.82681 5.74408 10 5.38553 10 5C10 4.61447 9.82678 4.25596 9.52477 4.01626Z"
            fill="#F0A92E"
        />
    </svg>
);


export default GiftSVG;
