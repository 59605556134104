import React from 'react';
import { render } from 'react-dom';
import '@styles/main.scss';

import App from '@/app';
import getAppVersion from '@/env/getAppVersion';
import vhCSSCustomProperty from '@/vhCSSCustomProperty';
import isFeatureFlagCustomerService from '@core/helpers/isFeatureFlagCustomerService';

import * as serviceWorker from './serviceWorker';

if (isFeatureFlagCustomerService()) {
    // eslint-disable-next-line no-console
    console.log('%c Super hero mode turned ON! ', 'background: #222; color: #bada55');
}

const container: HTMLElement | null = document.getElementById('root');

if (!container) {
    // eslint-disable-next-line no-console
    console.error('can`t find root div');
}

vhCSSCustomProperty();

// В консоле покажем версию приложения.
// eslint-disable-next-line no-console
console.info('appVersion', getAppVersion());

render(
    <App />,
    container,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if (process.env.REACT_APP_USE_SW === 'yes') {
    if ('serviceWorker' in navigator) {
        try {
            void navigator.serviceWorker.register('sw.js');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
    }
}

