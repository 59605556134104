import {
    action,
    IReactionDisposer,
    observable,
    reaction,
} from 'mobx';
import {
    addMiddleware,
    applySnapshot,
    getType,
    IDisposer,
    IMiddlewareEvent,
} from 'mobx-state-tree';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import { Store } from '@store/store';
import { ISelectedValue } from '@UIElements/Select/models';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IFormModel } from '@models/mobx-state-tree/form.model';
import DeliveriesService from '@services/DeliveriesService';
import { OrderService } from '@services/order';
import OrderApiService from '@api/order-api-service';
import SipService from '@services/sip/SipService';
import { IOperatorInactivityNotificationTimersModel } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import { DEFAULT_CURRENT_ORDER, DEFAULT_INACTIVITY_TIMER, DEFAULT_USER } from '@core/constants/defaultStoreItems';
import UserService from '@services/UserService';
import { EnumSipStatus } from '@services/sip/models';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import ModalService from '@core/services/ModalService';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import OrderCreationService from '@services/order/OrderCreationService';
import UserApiService from '@api/user-api-service';
import CustomerService from '@services/CustomerService';
import HistoryService from '@services/HistoryService';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ReplaceTransactionStatusEnum, ReplaceTransactionTypeEnum } from '@/app/models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import { ProductItemSubTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import BasketService from '@services/order/BasketService';
import ProductsService from '@services/order/ProductsService';
import { ConfirmModalType } from '@core/models/ModalWindow';
import { Locations } from '@core/models/locations';
import { ScriptDataService } from '@services/script';
import { EnumTypeOrder } from '@components/main/order-page/call-panel/call-widget/CallWidget';


type TSetOrderProductPriceDebouncedFunc = ((productId: number, price: number) => Promise<boolean>);


class OrderUpdateEventSubscriptionService {
    private _disposerOnChangesCustomerDistrict: IReactionDisposer | undefined;

    private _disposerOnChangesCustomerZip: IReactionDisposer | undefined;

    private _disposerOnChangesMainCart: IReactionDisposer | undefined;

    private _disposerOnChangesCurrentOrder: IReactionDisposer | undefined;

    private _disposerOnChangesGiftCart: IReactionDisposer | undefined;

    private _disposerOnChangesPromoCart: IReactionDisposer | undefined;

    private _disposerOnChangesTotalCostOfSelectedProduct: IReactionDisposer | undefined;

    private _disposerOnChangesBasket: IReactionDisposer | undefined;

    private _disposerOnChangesMatchingCourierList: IReactionDisposer | undefined;

    private _disposerOnChangesDefaultFirstCourier: IReactionDisposer | undefined;

    private _disposerOnChangesRemovePromoCarts: IReactionDisposer | undefined;

    private _disposerOnChangesSelectedPhoneToCallForComponent: IReactionDisposer | undefined;

    private _disposerOnChangeInactivityTimerId: IReactionDisposer | undefined;

    private _disposerOnChangeViewFormFillNotificationVisible: IReactionDisposer | undefined;

    private _disposerOnChangeSaveFormFillNotificationVisible: IReactionDisposer | undefined;

    private _disposerOnChangeSipStatus: IReactionDisposer | undefined;

    private _disposerCloseModalsOnChangeResponsiveState: IReactionDisposer | undefined;

    /**
     * реакция на смену статуса транзакции замены товара
     */
    private _disposerOnReplaceTransactionStatusChanged: IReactionDisposer | undefined;

    private _disposerOnUnlockedOrderIdChanged: IReactionDisposer | undefined;

    private _disposerMiddlewareCurrentOrder: IDisposer | undefined;

    private readonly _setOrderProductPriceDebounced: TSetOrderProductPriceDebouncedFunc;

    private readonly _updateFetchDeliveriesDebounced: (() => Promise<void>);

    private readonly _updateGetScriptDebounced: (() => Promise<void>);

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _currentUser(): IUserModel {
        return this._store.currentUser;
    }

    private get _inactivityTimer(): IOperatorInactivityNotificationTimersModel {
        return this._store.inactivityTimer;
    }

    private get _currentForm(): IFormModel {
        return this._currentOrder.form;
    }

    private get _sipStatus(): EnumSipStatus {
        return this._sipService.status;
    }

    /**
     * true, если в текущей сессии заказа был статус LIVE
     * (разговор с клиентом)
     */
    @observable
    private _statusWasLive = false;

    @action
    public setStatusWasLive(status: boolean) {
        this._statusWasLive = status;
    }

    constructor(
        private readonly _store: Store,
        private readonly _deliveriesService: DeliveriesService,
        private readonly _orderService: OrderService,
        private readonly _orderApiService: OrderApiService,
        private readonly _sipService: SipService,
        private readonly _userService: UserService,
        private readonly _modalService: ModalService,
        private readonly _orderCreationService: OrderCreationService,
        private readonly _userApiService: UserApiService,
        private readonly _customerService: CustomerService,
        private readonly _historyService: HistoryService,
        private readonly _basketService: BasketService,
        private readonly _productsService: ProductsService,
        private readonly _scriptDataService: ScriptDataService,
    ) {


        this._setOrderProductPriceDebounced = debounce<TSetOrderProductPriceDebouncedFunc>(
            async (productId: number, price: number): Promise<boolean> => this._orderService.setOrderProductPrice(productId, price),
            1000,
            {
                maxWait: 2000,
                trailing: true,
            },
        );

        this._updateFetchDeliveriesDebounced = debounce(
            async (): Promise<void> => this._deliveriesService.fetchDeliveries(),
            500,
            {
                maxWait: 2000,
                trailing: true,
            },
        );

        this._updateGetScriptDebounced = debounce(
            async (): Promise<void> => this._scriptDataService.getScript(
                String(this._store.currentOrder.countryId),
                this._store.currentOrder.id,
                this._store.currentOrder.partnerProductId,
            ),
            500,
            {
                maxWait: 2000,
                trailing: true,
            },
        );
    }

    /**
     * order-form edit
     */
    private updateDeliveries = async (): Promise<void> => {
        if (!this._currentOrder.isEmptyCurrentOrder) {
            await this._updateFetchDeliveriesDebounced();
        }
    };

    private updateScripts = async (): Promise<void> => {
        await this._updateGetScriptDebounced();
    };

    // order-form ?
    /**
     * Если нет текущего заказа то:
     * 1. очищаем стек модалок
     * 2. очищаем ошибку сип
     * 3. очищаем удаляем инфу о доставке что раньше фетчили
     * 4. очищаем начинаем полинг заказа
     * 5. очищаем начинаем отправляем событие start \
     * TODO: похоже что специфично для страницы order-form edit
     */
    private async onChangesCurrentOrder(id: number): Promise<void> {
        if (!id) {
            this._sipService.sipErrorMessage = null;

            /**
             * Если очистили заказ из-за протухшей блокировки,
             * то  покажем модалку, а потом уже дальше всё очищать будем
             */
            if (this._store.unblockedOrderId) {

                if (!this._store.notShowNotification) {
                    const text = this._userService.t(
                        'Блокировка с заказа {{unblockedOrderId}} снята. Заказ очищен.',
                        'The blockage for order {{unblockedOrderId}} was released. The order was cleared.',
                        { unblockedOrderId: this._store.unblockedOrderId },
                    );
                    await this._modalService.showConfirmModal(text, ConfirmModalType.Yes);
                }

                this._store.setUnblockedOrderId(null);

            }

            this.setStatusWasLive(false);
            this._modalService.clearModalStack();
            this._store.ui.bottomMobileTab.setActiveTab(BottomTabEnum.ORDER);
            this._store.ui.orderPage.closeCallPanel();
            this._deliveriesService.clearFetchedData();

            if (this._currentUser.selectedAuxStatus && !this._store.notificationWithFeedbackToView) {
                await this._userService.changeAuxStatus(this._currentUser.selectedAuxStatus);
            }

            // TODO: при переходе на другую страницу pollingOrder начинается. Нужно продумать когда это нужно делать, а когда нет.
            const currentPath = window.location.pathname;

            await this._sipService.showChangeCallModeModal();

            if (this._currentUser.mode === UserModeEnum.REGULAR && this._currentUser.disableProgressiveMode) {
                this._sipService.disconnect();
                this._currentOrder.setForceInactiveButtonOfCall(true);
                this._currentUser.setDisableProgressiveMode(false);
                this._sipService.sip.setZorraTaskId(undefined);
            }


            if (currentPath === Locations.MAIN && this._currentUser.mode !== UserModeEnum.PROGRESSIVE) {
                await this._orderService.startPollingOrder();
            }

        }
    }

    @action
    subscribe(): void {
        /**
         * Идемпотентность: гарантируется, что если несколько раз будет вызван subscribe(), то каждый подписчик будет в кол-ве 1 шт и не более того.
         */
        this.unsubscribe();

        this._disposerOnUnlockedOrderIdChanged = reaction<boolean>(
            () => this._store.unblockedOrderId === this._store.currentOrder.id
            && !this._store.inactivityTimer.viewFormFillNotificationVisible,
            (orderWasUnblocked) => {
                if (orderWasUnblocked) {
                    if (!this._store.notShowNotification || this._store.currentUser.unreadyAfterSave) {
                        this._currentUser.setIsReady(false);
                    }
                    this._sipService.endCall();
                    this._store.clearCurrentOrder();
                }
            },
        );

        this._disposerOnReplaceTransactionStatusChanged = reaction<boolean>( // реакция на смену статуса транзакции замены товара
            (): boolean => {
                const { replaceTransaction } = this._currentOrder;

                // Если статус = SHOULD_COMMIT -> значит заменяем товары
                return Boolean(replaceTransaction && replaceTransaction.status === ReplaceTransactionStatusEnum.SHOULD_COMMIT);
            },
            (isShouldCommit) => {
                if (isShouldCommit) {
                    const { replaceTransaction } = this._currentOrder;

                    if (!replaceTransaction) {
                        // eslint-disable-next-line no-console
                        console.warn('Nothing to return. This code should be unreachable, so you probably made a mistake');
                        return;
                    }

                    if (replaceTransaction.type === ReplaceTransactionTypeEnum.SET && replaceTransaction.to) { // Выполняем замену набора на другой
                        const catalogItemToAdd = this._currentOrder.additionalParamsCurrentOrder.catalog
                            .get(replaceTransaction.to);
                        const basketItemToRemove = this._currentOrder.additionalParamsCurrentOrder.basket
                            .get(replaceTransaction.from);
                        if (basketItemToRemove && catalogItemToAdd) {
                            this._basketService.addItemFromCatalogToBasket(catalogItemToAdd); // добавляем новый товар в корзину
                            this._currentOrder._removeBasketItem(basketItemToRemove.id); // заменяемый удаляем
                        }
                    }

                    if (replaceTransaction.type === ReplaceTransactionTypeEnum.SET_ITEM) { // выполняем замену товара, входящего в состав набора
                        if (!replaceTransaction.parentProductId) {
                            // eslint-disable-next-line no-console
                            console.warn('Nothing to return. This code should be unreachable, so you probably made a mistake');
                            return;
                        }

                        const dynamicSetItem = this._orderService.currentOrder.additionalParamsCurrentOrder.basket
                            .get(replaceTransaction.parentProductId);
                        if (!dynamicSetItem) {
                            // eslint-disable-next-line no-console
                            console.warn('Nothing to return. This code should be unreachable, so you probably made a mistake');
                            return;
                        }

                        const setComposition = dynamicSetItem.setProductCompositions;
                        if (!setComposition) {
                            // eslint-disable-next-line no-console
                            console.warn('Nothing to return. This code should be unreachable, so you probably made a mistake');
                            return;
                        }

                        const selectedItem: ISetProductComposition | undefined = setComposition.get(replaceTransaction.from);
                        if (selectedItem && replaceTransaction.to) {
                            selectedItem.changeSelectedItemToItemFromAlternativesOrRestoreToOriginal(replaceTransaction.to);
                        }
                    }

                    this._currentOrder.clearReplaceTransaction(); // удаляем модель транзакции
                }
            },
        );

        // Останавливаем таймеры не активности если это
        // Создание заказа
        // Пользователь не авторизован
        // Заказа нет
        // TODO: Не запускать таймеры для режима клиентского сервиса. Проверить где запускаются таймеры.
        this._disposerOnChangeInactivityTimerId = reaction<boolean>(
            (): boolean => {
                const { id: orderId, isACreatedOrder } = this._currentOrder;
                const { id: userId } = this._currentUser;

                return orderId === DEFAULT_CURRENT_ORDER.id || userId === DEFAULT_USER.id || isACreatedOrder;
            },
            (condition: boolean) => {
                if (condition) {
                    this._inactivityTimer.clearViewTimer();
                    this._inactivityTimer.clearSaveTimer();
                    this._inactivityTimer.clearModalTimer();
                    this._inactivityTimer.clearViewTimePassedTimer();
                    this._inactivityTimer.clearSaveTimePassedTimer();
                    this._inactivityTimer.clearModalTimePassedTimer();

                    applySnapshot(this._inactivityTimer, DEFAULT_INACTIVITY_TIMER);
                }
            },
        );


        this._disposerOnChangesCustomerDistrict = reaction<string | undefined>(
            () => this._currentForm.customerDistrictAttribute
                && this._currentForm.customerDistrictAttribute.fieldValue,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesCustomerZip = reaction<string | undefined>(
            () => this._currentForm.customerZipAttribute?.fieldValue,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesMainCart = reaction<number>(
            () => this._currentOrder.quantityOfMainCarts,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesGiftCart = reaction<number>(
            () => this._currentOrder.quantityOfGiftCarts,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesPromoCart = reaction<number>(
            () => this._currentOrder.quantityOfPromoCarts,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesTotalCostOfSelectedProduct = reaction<number>(
            () => this._currentOrder.totalCostOfSelectedProducts,
            () => {
                void this.updateDeliveries();
            },
        );

        this._disposerOnChangesBasket = reaction<any>(
            () => this._currentOrder.mainItemsOnlyInBasket[0]?.productItem.name,
            () => {
                if (this._currentOrder.orderType.id !== EnumTypeOrder.CREATED &&
                    !this._currentOrder.isFirstLoadingScripts) {
                    void this.updateScripts();
                }
            },
        );

        /**
         * Поведение реакции:
         * 0. Если созданный заказ, то не выполняем никаких действий
         *
         * 1. Если было соединение с клиентом (статус LIVE),
         * то проставляем соответствующую метку.
         * В false метка должна установиться при очистке заказа.
         *
         * 2. Если выполняется набор клиенту,
         * то очищается таймер режима limit_time_view (ПРОСМОТР)
         *
         * 3. Если дозвонились клиенту и звонок завершился,
         * то запускается таймер режима limit_time_save (СОХРАНЕНИЕ),
         * иначе таймер режима limit_time_save (СОХРАНЕНИЕ) очищается
         *
         * 4. Если были звонки в сессии заказа
         * и не дозвонились клиенту,
         * то запускаем таймер режима limit_time_view (ПРОСМОТР)
         */
        this._disposerOnChangeSipStatus = reaction<EnumSipStatus>(
            () => this._sipService.getSipStatus(),
            (sipStatus) => {
                const { isACreatedOrder, sessionCallCount, postProcessing } = this._currentOrder;

                const { mode, disableProgressiveMode, isVerificator } = this._currentUser;

                if (isACreatedOrder) {
                    return;
                }

                const callIsNotActive = ![
                    EnumSipStatus.DIALING,
                    EnumSipStatus.PROGRESS,
                    EnumSipStatus.LIVE,
                    EnumSipStatus.ENDED,
                    EnumSipStatus.FAIL].includes(sipStatus);

                const {
                    clearViewTimer,
                    clearViewTimePassedTimer,
                    setViewFormFillNotificationVisible,
                    setViewFormFillTimePassed,
                    startSaveTimer,
                    clearSaveTimer,
                    clearSaveTimePassedTimer,
                    clearModalTimePassedTimer,
                    setModalFormFillTimePassed,
                } = this._inactivityTimer;

                if (
                    sipStatus === EnumSipStatus.DIALING
                    && sessionCallCount > 0
                ) {
                    clearViewTimer();
                    clearViewTimePassedTimer();
                    setViewFormFillNotificationVisible(false);
                    setViewFormFillTimePassed(0);
                    clearModalTimePassedTimer();
                    setModalFormFillTimePassed(0);
                }

                if (sipStatus === EnumSipStatus.LIVE) {
                    this.setStatusWasLive(true);
                }

                if (
                    callIsNotActive
                    && (!postProcessing || (postProcessing && this._store.currentOrder.selectedStatus))
                    && sessionCallCount > 0
                    && mode !== UserModeEnum.PROGRESSIVE
                    && !disableProgressiveMode
                    && !isVerificator
                ) {
                    startSaveTimer();
                } else {
                    clearSaveTimer();
                    clearSaveTimePassedTimer();
                }

                // if (
                //     callIsNotActive
                //     && !this._statusWasLive
                //     && sessionCallCount > 0
                //     && !viewTimerActive
                //     && mode !== UserModeEnum.PROGRESSIVE
                //     && !disableProgressiveMode
                //     && !postProcessing
                // ) {
                //     startViewTimer();
                // }
            },
        );

        this._disposerOnChangesCurrentOrder = reaction<number>(
            () => this._currentOrder.id,
            this.onChangesCurrentOrder.bind(this),
        );

        this._disposerOnChangesSelectedPhoneToCallForComponent = reaction<ISelectedValue<string> | null>(
            () => this._currentOrder.selectedPhoneToCallForComponent(),
            (data) => {
                const { isCallingRightNow } = this._sipService;
                if (!isCallingRightNow && this._store.currentUser.mode !== UserModeEnum.PROGRESSIVE) {
                    this._currentOrder.setForceInactiveButtonOfCall(
                        (!data || !data.value || !data.value.length)
                        || (
                            this._sipStatus > EnumSipStatus.REGISTERED && !this._currentOrder.abilityToDropACall
                        ),
                    );
                }
            },
        );

        // Если ранее выбранная курьерка вне списка текущих доступных, скидываем выбор.
        this._disposerOnChangesMatchingCourierList = reaction<string | null | boolean>(
            () => this._currentOrder.selectedDeliveryId
                && (
                    this._currentOrder.deliveries.size === 0
                    || !find(
                        this._currentOrder.deliveryValues,
                        (item) => item.id === this._currentOrder.selectedDeliveryId,
                    )
                ),
            (selectedDeliveryNotInList) => {
                if (selectedDeliveryNotInList) {
                    this._currentOrder.setSelectedDeliveryId(null);
                }
            },
        );

        // Если не было выбрано курьерок, то выбираем самую первую из доступных3
        this._disposerOnChangesDefaultFirstCourier = reaction<boolean>(
            () => !this._currentOrder.selectedDeliveryId && this._currentOrder.deliveries.size > 0,
            (notSelected) => {
                if (notSelected) {
                    const willSelect = this._currentOrder.deliveryValues[0].id;
                    this._currentOrder.setSelectedDeliveryId(willSelect);
                }
            },
        );

        // Если условие для промо товаров больше не истинно, то убираем из заказа все промо товары
        this._disposerOnChangesRemovePromoCarts = reaction<number | false>(
            () => !this._productsService.isConditionForPromoCarts && this._currentOrder.quantityOfPromoCarts,
            (havePromoItemsWhenConditionFalse) => {
                if (havePromoItemsWhenConditionFalse) {
                    this._currentOrder.promoItemsInBasket.forEach((item: IBasketItemModel) => {
                        this._currentOrder._removeBasketItem(item.id);
                    });
                }
            },
        );

        /**
         * На мелком по высоте экране кнопки смены статуса показываются в модальном окне.
         * Если статус высоты изменился то закрываются все модальные окна.
         */
        this._disposerCloseModalsOnChangeResponsiveState = reaction(
            () => this._store.responsive.changeOrderButtonHeightBreakpoint,
            () => {
                this._modalService.clearModalStack();
            },
        );

        this._disposerMiddlewareCurrentOrder = addMiddleware(
            this._currentOrder,
            (
                call: IMiddlewareEvent,
                next: (value: any) => any,
            ): void => {
                const modelType = getType(call.context);

                if (modelType.name === 'FormAttributes') {
                    if (call.name === 'setValue' || call.name === 'setKladrResult') {
                        if (
                            !this._currentOrder.lastCallHistoryEvent
                            || this._currentOrder.lastCallHistoryEvent.addressActionTimeWasSent
                        ) {
                            next(call);

                            return;
                        }

                        if (this._currentOrder.lastCallHistoryEvent && this._currentOrder.lastCallHistoryEvent.id) {
                            void this._orderApiService.saveAddressActiveTime(
                                this._currentOrder.lastCallHistoryEvent.id,
                            );
                        }

                        this._currentOrder.lastCallHistoryEvent.setAddressActionTimeWasSent(
                            true,
                        );
                    }
                }

                if (modelType.name === 'CurrentOrder') {
                    if (call.name === 'setSelectedDeliveryId') {
                        const currentId = call.args[0] && Number(call.args[0]) || 0;
                        const previousId = call.context.selectedDeliveryId && Number(call.context.selectedDeliveryId) || 0;

                        if (!currentId || currentId !== previousId) {
                            // this._deliveriesService.changeDeliveryFrom(null);
                            // this._deliveriesService.changeDeliveryTo(null);
                        }
                    }
                }

                if (modelType.name === 'BasketItemModel') {
                    if (call.name === 'setPrice') {
                        if (
                            // Отсылать запрос на обновление цены продукта, если к товару не применена акция
                            !call.context.isPromoApplied
                            && (
                                call.context.productItem.subType === ProductItemSubTypeEnum.PROMO
                                || call.context.productItem.subType === ProductItemSubTypeEnum.MAIN
                            )
                        ) {
                            if (call.parentActionEvent?.name
                                && call.parentActionEvent?.name === 'setPriceAfterDeletingPromotionBuilder') {
                                next(call);

                                return;
                            }

                            const productId = call.context.existingOrderProductId;
                            const price = call.args[0];

                            void this._setOrderProductPriceDebounced(productId, price);
                        }
                    }
                }

                next(call);
            },
        );
    }

    @action
    unsubscribe(): void {
        if (this._disposerOnUnlockedOrderIdChanged) {
            this._disposerOnUnlockedOrderIdChanged();
        }

        if (this._disposerOnReplaceTransactionStatusChanged) {
            this._disposerOnReplaceTransactionStatusChanged();
        }

        if (this._disposerOnChangeViewFormFillNotificationVisible) {
            this._disposerOnChangeViewFormFillNotificationVisible();
        }

        if (this._disposerOnChangeInactivityTimerId) {
            this._disposerOnChangeInactivityTimerId();
        }

        if (this._disposerOnChangeSaveFormFillNotificationVisible) {
            this._disposerOnChangeSaveFormFillNotificationVisible();
        }

        if (this._disposerOnChangeInactivityTimerId) {
            this._disposerOnChangeInactivityTimerId();
        }

        if (this._disposerOnChangesSelectedPhoneToCallForComponent) {
            this._disposerOnChangesSelectedPhoneToCallForComponent();
        }

        if (this._disposerOnChangeSipStatus) {
            this._disposerOnChangeSipStatus();
        }

        if (this._disposerOnChangesCustomerDistrict) {
            this._disposerOnChangesCustomerDistrict();
        }

        if (this._disposerOnChangesCustomerZip) {
            this._disposerOnChangesCustomerZip();
        }

        if (this._disposerOnChangesMainCart) {
            this._disposerOnChangesMainCart();
        }

        if (this._disposerOnChangesGiftCart) {
            this._disposerOnChangesGiftCart();
        }

        if (this._disposerOnChangesTotalCostOfSelectedProduct) {
            this._disposerOnChangesTotalCostOfSelectedProduct();
        }

        if (this._disposerOnChangesBasket) {
            this._disposerOnChangesBasket();
        }

        if (this._disposerOnChangesCurrentOrder) {
            this._disposerOnChangesCurrentOrder();
        }

        if (this._disposerOnChangesMatchingCourierList) {
            this._disposerOnChangesMatchingCourierList();
        }

        if (this._disposerOnChangesDefaultFirstCourier) {
            this._disposerOnChangesDefaultFirstCourier();
        }

        if (this._disposerOnChangesRemovePromoCarts) {
            this._disposerOnChangesRemovePromoCarts();
        }

        if (this._disposerMiddlewareCurrentOrder) {
            this._disposerMiddlewareCurrentOrder();
        }

        if (this._disposerCloseModalsOnChangeResponsiveState) {
            this._disposerCloseModalsOnChangeResponsiveState();
        }

        if (this._disposerOnChangesPromoCart) {
            this._disposerOnChangesPromoCart();
        }
    }
}


export default OrderUpdateEventSubscriptionService;
