import cn from 'classnames';
import React, {
    useEffect, useState, useCallback, ChangeEvent,
} from 'react';
// Bloodhound не имеет типов для ts. в файле /src/declatation.d.ts указан модуль. Все его методы имеют тип any.
import Bloodhound from 'bloodhound-js';
import map from 'lodash/map';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import Popup from '@UIElements/Popup/Popup';
import FlagIcon from '@/plugins/FlagIcon';
import { ICountryModel, ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';

import IcDropDownSvg from './includes/IcDropDownSvg';
import './includes/CountrySelect.scss';


interface ICountrySelectProps {
    selected?: ICountryModel;
    placeholder?: string;
    width?: string;
    values?: ICountryModel[];
    value?: ICountryModel;
    onChange?: (value: string) => void;
    onSelect: (value: ICountryModel) => void;
    onOpen?: () => void;
    onClose?: () => void;
    className?: string;
    error?: string;
    displayItem?: (value: ICountryModel) => void;
    controlled?: boolean;
    label?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    disabled?: boolean;
}


const DEFAULT_WIDTH = '200px';

const empty: ICountryModelSnapshotIn = {
    id: 'none',
    name: '',
    charCode: null,
};

const CountrySelect = (props: ICountrySelectProps) => {
    const { t } = useInstance(I18NService);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [values, setValues] = useState<ICountryModel[] | undefined>(props.values);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const selectedProps = props.selected ? props.selected : empty;
    const placeholder = props.placeholder ? props.placeholder : '';
    const isPlaceholder = placeholder && selected !== null;
    const list = values && values.length ? values : props.values;
    const width = props.width ? props.width : DEFAULT_WIDTH;
    const countrySelectStyle: Record<string, string> = { '--countrySelect-width': width };

    let bloodhound: any = null;

    const getBloodhound = useCallback(() => {
        const bloodhoundInstance = new Bloodhound({
            local: props.values,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
            datumTokenizer: (datum: { [x: string]: any }) => Bloodhound.tokenizers.whitespace(datum && datum.name),
        });

        bloodhoundInstance
            .initialize()
            .then(() => setInitialized(true));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return bloodhoundInstance;
    }, [props.values]);

    useEffect(() => {
        let isMounted = false;

        if (props.value && props.value.name) {
            setSearchQuery(t(props.value.name));
        }

        if (!isMounted) {
            bloodhound = getBloodhound();
        }

        return () => {
            isMounted = true;
        };
    }, []);

    const openMenu = useCallback(() => {
        setIsMenuOpened(true);
        if (props.onOpen) {
            props.onOpen();
        }
    }, [props.onOpen]);

    const closeMenu = useCallback(() => {
        setIsMenuOpened(false);
        if (props.onClose) {
            props.onClose();
        }
    }, [props.onClose]);

    const select = useCallback((selected) => {
        props.onSelect(selected);

        closeMenu();

        setSelected(true);
        setSearchQuery(selected.name);
    }, [props.onSelect, closeMenu]);

    const search = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        openMenu();
        setSearchQuery(value);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const resSearch: Promise<ICountryModel[]> = new Promise<ICountryModel[]>((resolve) => bloodhound.search(
            value,
            (results: ICountryModel[]) => resolve(results),
            (results: ICountryModel[]) => resolve(results),
        ));

        void resSearch.then((results: ICountryModel[]) => setValues(results));

        if (props.onChange) {
            props.onChange(value);
        }
    }, [props.onChange, openMenu, bloodhound]);

    const toggleMenu = useCallback(() => {
        if (props.disabled) {
            return;
        }

        setIsMenuOpened(!isMenuOpened);
    }, [isMenuOpened]);

    if (!initialized) {
        // Если не инициализирован автокомплит, то ничего не выводим
        return null;
    }

    let value = '';
    if (props.controlled) {
        if (props.value && props.value.name !== null) {
            value = props.value.name;
        }
    } else {
        value = searchQuery;
    }

    return (
        <div
            className={cn([
                'country-select__wrapper e-searchable-select',
                props.className,
                props.disabled && 'disabled',
            ])}
            style={countrySelectStyle}
            onClick={toggleMenu}
        >
            {props.label && (
                <div className="input-label">{props.label}</div>
            )}
            <div className="input-error">{props.error}</div>
            <div className="input-flag">
                {props.value && props.value.charCode && props.value.charCode.length && (
                    <FlagIcon
                        className="langIcon"
                        code={props.value.charCode.toLowerCase()}
                        squared={true}
                    />
                )}
            </div>
            {!props.disabled && (
                <IcDropDownSvg
                    className={cn([
                        'arrow',
                        isMenuOpened && 'arrow--up',
                    ])}
                />
            )}
            <input
                type="text"
                placeholder={placeholder}
                className={cn(
                    'handler',
                    { placeholder: isPlaceholder },
                    { selected: selectedProps.name !== '' },
                    { closed: !isMenuOpened },
                    { opened: isMenuOpened },
                    { disabled: props.disabled },
                )}
                value={value}
                onChange={search}
                onClick={toggleMenu}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                autoComplete="disabled"
            />
            <Popup
                className="select-popup"
                isActive={isMenuOpened}
                width={width}
            >
                <ul className="values">
                    {
                        map(list, (value: any, index: number) => (
                            <li key={index} onClick={() => select(value)}>
                                {
                                    props.displayItem
                                        ? props.displayItem(value)
                                        : value.name
                                }
                            </li>
                        ))
                    }
                </ul>
            </Popup>
        </div>
    );
};


export default observer(CountrySelect);
