import React, { FunctionComponent, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import TextInput from '@UIElements/TextInput';
import I18NService from '@services/I18NService';
import Button from '@UIElements/Button';
import { CustomerService } from '@/app/services';
import { CustomerModeStore } from '@store/customerModeStore';

import './OrderSearch.scss';


const OrderSearch: FunctionComponent = (): JSX.Element => {
    const [
        customerService,
        { t },
        {
            store: {
                customerOrderId,
                customerPhone,
                customerName,
                setCustomerOrderId,
                setCustomerPhone,
                setCustomerName,
                clearSearchForm,
            },
            clearCustomerServiceOrderList,
        },
    ] = useInstances(
        CustomerService,
        I18NService,
        CustomerModeStore,
    );

    const onApply = useCallback(async () => {
        await customerService.getOrdersByParams();
    }, [customerService.getOrdersByParams]);

    const onReset = () => {
        clearCustomerServiceOrderList();
        clearSearchForm();
    };

    return (
        <form onSubmit={onApply} className="OrderSearch">
            <div className="OrderSearch__inputs">
                <TextInput
                    className="orderSearch__input"
                    label={t('ID', 'ID')}
                    value={customerOrderId}
                    onChangeValue={setCustomerOrderId}
                    dataCyValue="customerService__orderSearch--byId"
                />
                <TextInput
                    className="orderSearch__input"
                    label={t('Телефон', 'Phone')}
                    value={customerPhone}
                    onChangeValue={setCustomerPhone}
                    dataCyValue="customerService__orderSearch--byPhone"
                />
                <TextInput
                    className="orderSearch__input"
                    label={t('Имя клиента', 'Client name')}
                    value={customerName}
                    onChangeValue={setCustomerName}
                    dataCyValue="customerService__orderSearch--byClientName"
                />
            </div>
            <div className="OrderSearch__actions">
                <Button
                    className="applyBtn"
                    variant="7"
                    size="1"
                    text={t('Применить', 'Apply')}
                    onClick={onApply}
                    dataCyValue="customerService__orderSearch--applyBtn"
                />
                <Button
                    className="resetBtn"
                    variant="5"
                    size="1"
                    text={t('Сбросить', 'Reset')}
                    onClick={onReset}
                    dataCyValue="customerService__orderSearch--resetBtn"
                />
            </div>
        </form>
    );
};

export default observer(OrderSearch);
