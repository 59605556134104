import React, { FC } from 'react';
import { block } from 'bem-cn';

import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { ScriptDataService } from '@services/script';

import { Store } from '@store/store';

import Button from '@UIElements/Button';

import { observer } from 'mobx-react';

import './ScriptCallTabs.scss';


interface IScriptCallTabs {
    setLoading: (loading: boolean) => void;
}

const ScriptCallTabs: FC<IScriptCallTabs> = ({ setLoading }): JSX.Element => {

    const [
        { t },
        scriptDataService,
        {
            currentOrder: {
                countryId,
                showScript,
                setShowScript,
                partnerProductId,
                additionalParamsCurrentOrder: {
                    loadBasket,
                },
                id: orderId,
            },
        },
    ] = useInstances(
        I18NService,
        ScriptDataService,
        Store,
    );

    const b = block('script-call-tabs');

    const getScript = () => {
        setShowScript(true);
        setLoading(true);
        void scriptDataService.getScript(String(countryId), orderId, partnerProductId)
            .finally(() => setLoading(false));
    };

    const setCallTab = () => {
        setShowScript(false);
    };

    return (
        <div className={b()}>
            <Button
                text={t('Звонок', 'Call')}
                width="140px"
                variant="3"
                className={!showScript ? 'status script-call-tabs__btn-call' : 'script-call-tabs__btn-call'}
                onClick = {setCallTab}
            />
            <Button
                text={t('Скрипт', 'Script')}
                width="140px"
                variant="3"
                className={showScript ? 'status script-call-tabs__btn-script' : 'script-call-tabs__btn-script'}
                onClick = {getScript}
                disabled={loadBasket}
            />
        </div>
    );
};

export default observer(ScriptCallTabs);
