import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcNoAnswerStatusSvg {
    className?: string;
}


const IcNoAnswerStatusSvg: FunctionComponent<IIcNoAnswerStatusSvg> = ({ className, ...props }) => (
    <svg
        className={cn(['IcNoAnswerStatusSvg', className])}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9799 7H12.0099C12.5599 7 12.9999 6.56 12.9999 6.02V2.98C12.9999 2.44 12.5599 2 12.0199 2H11.9899C11.4399 2 10.9999 2.44 10.9999 2.98V6.01C10.9999 6.56 11.4399 7 11.9799 7ZM16.8999 9.11C17.2899 9.5 17.9099 9.5 18.2999 9.11C18.9199 8.48 19.8199 7.57 20.4499 6.94C20.8399 6.56 20.8399 5.93 20.4499 5.55C20.0699 5.17 19.4399 5.17 19.0599 5.55L16.8899 7.7C16.4999 8.08 16.4999 8.71 16.8899 9.09L16.8999 9.11ZM5.70991 9.1C6.08991 9.49 6.71991 9.49 7.10991 9.1C7.48991 8.72 7.48991 8.09 7.10991 7.71L4.95991 5.54C4.57991 5.15 3.94991 5.15 3.56991 5.54L3.54991 5.55C3.15991 5.94 3.15991 6.56 3.54991 6.94C4.17991 7.56 5.08991 8.47 5.70991 9.1ZM23.2899 16.23C16.8799 10.57 7.21991 10.57 0.809908 16.23C-0.0400923 16.98 -0.0400923 18.31 0.759908 19.11L1.97991 20.33C2.69991 21.05 3.83991 21.11 4.63991 20.48L6.63991 18.89C7.11991 18.51 7.39991 17.93 7.39991 17.32V14.72C10.4199 13.74 13.6899 13.73 16.7199 14.72V17.33C16.7199 17.94 16.9999 18.52 17.4799 18.9L19.4699 20.48C20.2699 21.11 21.4099 21.05 22.1299 20.33L23.3499 19.11C24.1399 18.31 24.1399 16.98 23.2899 16.23Z"
            fill="#1665D8"
        />
    </svg>

);


export default IcNoAnswerStatusSvg;
