import { ISelectValue } from '@UIElements/SelectDummy/SelectDummy';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import {
    Instance, types as t, SnapshotOut, SnapshotIn, IAnyModelType,
} from 'mobx-state-tree';

/**
 * Модель тем обращений для создания тикета в JIRA
 */
export const AppealSubject = t
    .model('AppealSubject', {
        id: t.identifier,
        label: t.string,
    });

export const CreateAppeal = t
    .model('CreateAppeal', {
        id: t.identifier,
        selectedAppealSubject: t.maybe(t.reference(t.late((): IAnyModelType => AppealSubject))), // выбранная тема обращения
        customSubject: t.maybeNull(t.string),
        appealSubjectList: t.array(AppealSubject),
        isSubjectListLoading: t.optional(t.boolean, false),
        shouldLoadSubjectList: t.optional(t.boolean, true),
        isAppealCreationInProgress: t.optional(t.boolean, false), // когда нажали в форме кнопку 'Создать'
        isAppealSuccessfulCreated: t.optional(t.boolean, false),
        isAppealCreationError: t.optional(t.boolean, false),
        appealCreationError: t.maybeNull(t.string),
    })
    .views((self) => ({
        get selectedAppealSubjectModel(): IAppealSubjectModel | undefined {
            if (self.selectedAppealSubject) {
                return self.selectedAppealSubject as IAppealSubjectModel;
            }

            return undefined;
        },
        get subjectsForAView(): ISelectValue[] {
            const subjectListData = self.appealSubjectList;

            const subjectList = reduce<IAppealSubjectModel, ISelectValue[]>(
                subjectListData,
                (acc, subject) => {
                    acc.push({
                        id: subject.id,
                        label: subject.label,
                    });

                    return acc;
                }, [],
            );

            return subjectList;
        },
    }))
    .actions((self) => ({
        pushAppealsSubjectList(value: IAppealSubjectSnapshotIn[]) {
            each(value, (appeal) => self.appealSubjectList.push(appeal));
        },
        setIsAppealCreationInProgress(value: boolean) {
            self.isAppealCreationInProgress = value;
        },
        setIsAppealSuccessfulCreated(value: boolean) {
            self.isAppealSuccessfulCreated = value;
        },
        setIsAppealCreationError(value: boolean) {
            self.isAppealCreationError = value;
        },
        setAppealCreationError(value: string) {
            self.appealCreationError = value;
        },
        setIsSubjectListLoading(value: boolean) {
            self.isSubjectListLoading = value;
        },
        setShouldLoadSubjectList(value: boolean) {
            self.shouldLoadSubjectList = value;
        },
        modifyDefaultItemLabel(label: string): void {
            const subjList = self.appealSubjectList;
            subjList[0].label = label;
        },
        setSelectedSubject(id: string) {
            self.selectedAppealSubject = id;
        },
        setCustomSubject(value: string) {
            self.customSubject = value;
        },
    }))
    .actions((self) => ({
        resetAppealCreationProcessFields() {
            self.setIsAppealCreationInProgress(false);
            self.setIsAppealSuccessfulCreated(false);
            self.setIsAppealCreationError(false);
            self.appealCreationError = null;
        },
        // сбрасываем состояние для повторного создания обращения
        resetAppealCreationModelAfterSuccessfulCreation() {
            self.selectedAppealSubject = undefined;
            self.customSubject = null;
        },
    }));

export interface IAppealSubjectModel extends Instance<typeof AppealSubject> {}
export interface IAppealSubjectSnapshotIn extends SnapshotIn<typeof AppealSubject> {}
export interface IAppealSubjectSnapshotOut extends SnapshotOut<typeof AppealSubject> {}
export interface ICreateAppealModel extends Instance<typeof CreateAppeal> {}
export interface ICreateAppealSnapshotIn extends SnapshotIn<typeof CreateAppeal> {}
export interface ICreateAppealSnapshotOut extends SnapshotOut<typeof CreateAppeal> {}
