import get from 'lodash/get';

import ApiBase from '@core/api';
import {
    getEnvScriptAbset,
    getEnvScriptGet,
} from '@/env/requestEndpoints';

import {
    IScriptItem,
} from './models';

import { Inject } from 'react-ioc';

interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T[];
}

interface IWithResponseWithoutData {
    status: 'success' | 'error' | 'fail';
    message?: string;
}

class ScriptApiService {
    @Inject apiBase: ApiBase;

    async getScript(countryId: string, partnerProductId?: string): Promise<IScriptItem[] | void> {

        try {
            const dataToSend = {
                country_id: countryId,
                ...{ product_ids: { partnerProductId } || {} },
            };

            const response = await this.apiBase.get<IWithResponse<IScriptItem>>(getEnvScriptGet(), dataToSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return data as IScriptItem[];
        } catch (e) {
            console.log(e);
        }
    }

    async absetScript(orderId: number, scriptId: number): Promise<boolean | void> {

        try {
            const dataToSend = {
                order_id: orderId,
                script_id: scriptId,
            };

            const response = await this.apiBase.post<IWithResponseWithoutData>(getEnvScriptAbset(), dataToSend);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return isSuccess;

        } catch (e) {
            console.log(e);
        }
    }
}


export default ScriptApiService;
