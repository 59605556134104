import { Store } from '@store/store';
import every from 'lodash/every';
import { action, computed } from 'mobx';
import filter from 'lodash/filter';
import each from 'lodash/each';
import some from 'lodash/some';
import reduce from 'lodash/reduce';

import { IBasketItemModel, TBasketOptionalParams } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IPromotionModel } from '@models/mobx-state-tree/newModels/Promotion.model';
import { OrderService } from '@services/index';
import { ConfirmModalType } from '@core/models/ModalWindow';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import { customI18NTFunction } from '@services/I18NService';
import BasketService from '@services/order/BasketService';
import { IDispatchingBonusItems } from '@services/order/OffersService';


type TBasketItemModel = IBasketItemModel;
type BasketOptional = TBasketOptionalParams;
type TProductItemModel = IProductItemModel;


/**
 * Сервис, который содержит методы и вычисляемые свойства для работы со старыми акциями.
 */
class OldPromotionService {
    private static _checkYourShoppingCartForPromotions(array: TBasketItemModel[]): boolean {
        return every<TBasketItemModel>(
            array,
            { isPromoApplied: true },
        );
    }

    /**
     * Получить доступные старые акции относительно основных товаров в корзине
     */
    @computed
    public get availablePromotionsForMainItemsInBasket(): IDispatchingBonusItems[] {
        if (this._currentOrder.additionalParamsCurrentOrder.activePromotionBuilderModelId) {
            return [];
        }

        return reduce<IBasketItemModel, IDispatchingBonusItems[]>(
            this._currentOrder.mainItemsOnlyInBasket,
            (acc, basketMainItem) => {
                // Находим доступные акции для главного товара из массива с акциями
                const oldPromosInCatalog = this._getAllAvailablePromotionsInCatalog(basketMainItem.productItem.productId);

                if (!oldPromosInCatalog.length) {
                    return acc;
                }

                each<IProductItemModel>(
                    oldPromosInCatalog,
                    (oldPromoInCatalog) => {
                        // Есть доступная акция для этого товара и главный товар не удален
                        if (oldPromoInCatalog && !basketMainItem.removed) {
                            let isYetAddedToAccumulator = false;

                            each<IDispatchingBonusItems>(
                                acc,
                                (value) => {
                                    if (value.productItem?.oldPromotionalProduct?.id === oldPromoInCatalog.oldPromotionalProduct?.id) {
                                        isYetAddedToAccumulator = true;
                                    }
                                },
                            );

                            // Если ранее не добавляли эту акции в аккумулирующий массив, то добавляем
                            if (!isYetAddedToAccumulator) {
                                acc.push({
                                    productItemFlag: true,
                                    productItem: oldPromoInCatalog,
                                    promotionBuilderFlag: false,
                                });
                            }
                        }
                    },
                );

                return acc;
            },
            [],
        );
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _I18NService: I18NService,
        private readonly _modalService: ModalService,
        private readonly _basketService: BasketService,
    ) {
    }

    private _getAllAvailablePromotionsInCatalog(productId: string): IProductItemModel[] {
        return filter<IProductItemModel>(
            this._currentOrder.oldPromotionalItemsInCatalog,
            { productId },
        );
    }

    /**
     * Возвращает главные товары из корзины, по partnerProductId из каталога со старыми
     * акциями (type/subType === OLD_PROMOTION)
     * @param catalogItem - модель IProductItemModel
     */
    private _findProductMatchesForStocksFromOffers(catalogItem: TProductItemModel): TBasketItemModel[] {
        if (
            catalogItem.type === ProductItemTypeEnum.OLD_PROMOTION
            && catalogItem.subType === ProductItemSubTypeEnum.OLD_PROMOTION
        ) {
            return filter<TBasketItemModel>(
                this._currentOrder.mainItemsOnlyInBasket,
                (basketItem) => Number(basketItem.productItem.partnerProductId) === Number(catalogItem.partnerProductId),
            );
        }

        return [];
    }

    /**
     * Функция проверяет возможность применить акцию к товару.
     * Если в массиве товаров корзины (к которым доступны акции), существует товар с примененной акцией, проверяемого
     * товара из оферов, то функция возвращает true.
     * @param catalogItem - модель IProductItemModel
     */
    public checkAvailabilityOfAction(catalogItem: TProductItemModel): boolean {
        return !some<TBasketItemModel>(
            this._findProductMatchesForStocksFromOffers(catalogItem),
            {
                promotionId: catalogItem.oldPromotionalProduct?.id,
            },
        );
    }

    /**
     * Возвращает главные товары из корзины, по partnerProductId из каталога со старыми
     * акциями (type/subType === OLD_PROMOTION) без примененной акции
     * @param catalogItem - модель IProductItemModel
     */
    private _getMainProductWithoutAPromotion(catalogItem: TProductItemModel): TBasketItemModel[] {
        return filter<IBasketItemModel>(
            this._findProductMatchesForStocksFromOffers(catalogItem),
            (basketItem) => !basketItem.isPromoApplied,
        );
    }

    /**
     * Метод применяет настройки акции к товару в корзине.
     */
    private _activatePromotionSettingsForMainItems(
        promotionItem: IPromotionModel,
        basketItem: TBasketItemModel,
    ): void {
        const {
            id: promotionId,
            giftsValues,
            paidAmount,
            price,
        } = promotionItem;

        const {
            id,
            setQuantity,
            setPrice,
            setPromotionId,
        } = basketItem;

        each<TProductItemModel>(
            giftsValues,
            (promoGiftItem) => {
                const basketParams: BasketOptional = {
                    quantity: promoGiftItem.quantityInOldPromotion || 0,
                    mandatoryGift: true,
                    promotionMasterBasketItemId: id,
                    promotionId,
                };

                promoGiftItem.setPrice(0);

                this._basketService.putAnItemInBasket(
                    promoGiftItem,
                    basketParams,
                );
            },
        );

        setPromotionId(promotionId);
        setQuantity(paidAmount);
        setPrice(price!);
    }

    /**
     * Применить выбранную акцию к главному товару в корзине
     * Метод формирует модели BasketItemModel для каждого подарка в акции и заносит их корзину.
     */
    public applyPromotionToProduct(productItemModel: TProductItemModel): void {
        if (productItemModel.oldPromotionalProduct) {
            const res = this._getMainProductWithoutAPromotion(productItemModel);

            // В массиве не может быть больше одного элемента, т.к. возможен случай только одной номенклатуры без примененной акции, при нескольких одинаковых товарах
            if (!res.length || res.length > 1) {
                // TODO Создать ошибку
                // eslint-disable-next-line no-console
                console.error('Что-то пошло не так.');

                return;
            }

            this._activatePromotionSettingsForMainItems(
                productItemModel.oldPromotionalProduct,
                res[0],
            );
        }
    }

    public checkAppliedPromotionsForOffers(catalogItem: TProductItemModel): boolean {
        return OldPromotionService._checkYourShoppingCartForPromotions(
            this._findProductMatchesForStocksFromOffers(catalogItem),
        );
    }

    /**
     * Проверяет что все ранее добавленные номенклатуры с таким-же товаром имеют примененную акцию.
     * Если все товары с примененной акцией, то возвращает true, иначе false
     * @param catalogItem - модель IProductItemModel
     */
    public checkIfAddedProductsHaveAppliedPromotions(catalogItem: TProductItemModel): boolean {
        return OldPromotionService._checkYourShoppingCartForPromotions(
            this._basketService.findSameMainItemsInBasket(catalogItem),
        );
    }

    /**
     * Запросить подтверждение на удаление акции.
     * Метод удаляет акцию у товара и также удаляет добавленные товары по акции из корзины.
     */
    @action
    public async removePromoFromProduct(basket: TBasketItemModel): Promise<void> {
        const res = await this._modalService.showConfirmModal(
            this._t(
                'Вы уверены что хотите отменить акцию?',
                'Are you sure you want to cancel the promotion?',
            ),
            ConfirmModalType.YesCancel,
        );

        if (res) {
            // Удаление подарков по акции из корзины
            this.removePromoGiftsFromBasket(basket.id);

            const checkResult = this.checkIfAddedProductsHaveAppliedPromotions(basket.productItem);

            // Если существует номенклатура с таким-же товаром и без акции, то удаляем номенклатуру с этим товаром
            if (!checkResult) {
                this._currentOrder._removeBasketItem(basket.id);

                return;
            }

            // Удаление акции с товара
            basket.removePromo();
            // Вернуть изначальную цену без акции
            basket.initiatePriceWithoutPromotion();
        }
    }

    /**
     * Метод удаляет добавленные товары по акции из корзины.
     */
    public removePromoGiftsFromBasket(iDsForPromotionalItems: string): void {
        // Удаление подарков, добавленных по акции к этому товару
        each<IBasketItemModel>(
            this.getPromoGoodsByProductId(iDsForPromotionalItems),
            (basketItem) => {
                this._currentOrder._removeBasketItem(basketItem.id);
            },
        );
    }

    /**
     * Метод удаляет у выбранного главного товара подарки предыдущей акции и применяет настройки новой акции
     */
    public async replacePromoFromProduct(
        basketItem: TBasketItemModel,
        catalogItem: TProductItemModel,
    ): Promise<void> {
        const res = await this._modalService.showConfirmModal(
            this._t(
                'Вы уверены что хотите заменить акцию?',
                'Are you sure you want to replace the promotion?',
            ),
            ConfirmModalType.YesCancel,
        );

        if (catalogItem.oldPromotionalProduct && res) {
            this.removePromoGiftsFromBasket(basketItem.id);

            this._activatePromotionSettingsForMainItems(
                catalogItem.oldPromotionalProduct,
                basketItem,
            );
        }
    }

    /**
     * Метод возвращает список доступных акций на замену к выбранному главному товару, исключая примененную акцию
     */
    public getReplacementPromotionList(selectedBasket: TBasketItemModel): TProductItemModel[] {
        return filter<IProductItemModel>(
            this._getAllAvailablePromotionsInCatalog(selectedBasket.productItem.productId),
            (catalogItem) => catalogItem.oldPromotionalProduct?.id !== selectedBasket.promotionId,
        );
    }

    /**
     * Запросить добавленные по акции подарки для конкретного товара, к которому применена акция.
     * Метод возвращает массив добавленных подарков по акции.
     */
    public getPromoGoodsByProductId(iDsForPromotionalItems: string): IBasketItemModel[] {
        return filter<IBasketItemModel>(
            this._currentOrder.giftItemsInBasket,
            (basketItem) => basketItem?.promotionMasterBasketItemId === iDsForPromotionalItems,
        );
    }

    /**
     * Метод возвращает true, если у выбранного главного товара в корзине имеются акции на замену
     */
    public canBeReplacedByAnotherPromotion(selectedBasket: TBasketItemModel): boolean {
        return !!this.getReplacementPromotionList(selectedBasket).length;
    }
}


export default OldPromotionService;
