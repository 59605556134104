import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';
import { OrderViewProductModel } from '@models/mobx-state-tree/orderViewProduct.model';
import { CallHistory, ICallHistoryModel } from '@models/mobx-state-tree/callHistory.model';

export const OrderViewModel = t
    .model('OrderViewModel', {
        id: t.maybeNull(t.integer),
        status: t.maybeNull(t.integer),
        statusName: t.maybeNull(t.string),
        customerFullName: t.maybeNull(t.string),
        createdAt: t.maybeNull(t.integer),
        orderTypeName: t.maybeNull(t.string),
        countryName: t.maybeNull(t.string),
        countryCharCode: t.maybeNull(t.string),
        sourceName: t.maybeNull(t.string),
        customerAge: t.maybeNull(t.string),
        customerEmail: t.maybeNull(t.string),
        customerMobile: t.maybeNull(t.string),
        customerPhone: t.maybeNull(t.string),
        customerApartmentNumber: t.maybeNull(t.string),
        customerCity: t.maybeNull(t.string),
        customerDistrict: t.maybeNull(t.string),
        customerHouseNumber: t.maybeNull(t.string),
        customerProvince: t.maybeNull(t.string),
        customerState: t.maybeNull(t.string),
        customerStreet: t.maybeNull(t.string),
        customerZip: t.maybeNull(t.string),
        products: t.optional(t.array(OrderViewProductModel), []),
        deliveryFrom: t.maybeNull(t.integer),
        deliveryTo: t.maybeNull(t.integer),
        shippingName: t.maybeNull(t.string),
        shippingPrice: t.maybeNull(t.number),
        finalPrice: t.maybeNull(t.number),
        callHistory: t.map(CallHistory),
    })
    .views((self) => ({
        get callHistoryValues(): ICallHistoryModel[] {
            return [...self.callHistory.values()];
        },
        get productsViews() {
            return [...self.products.values()];
        },
    }));


export interface IOrderViewModel extends Instance<typeof OrderViewModel> {}
export interface IOrderViewModelSnapshotIn extends SnapshotIn<typeof OrderViewModel> {}
export interface IOrderViewModelSnapshotOut extends SnapshotOut<typeof OrderViewModel> {}
