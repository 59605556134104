import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class PartnerProductIntegrityError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'В v2/partner-product/list нарушена целостность данных. Не нашли информацию о товаре.';
    }
}


export default PartnerProductIntegrityError;
