import React, {
    ChangeEvent,
    FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import { AddModeEnum } from '@services/order/OrderService';
import { Store } from '@store/store';
import ChevronSvgIcon from '@components/resources/SVG/Chevron/ChevronSVG';
import I18NService from '@services/I18NService';
import Chip
    from '@components/main/order-page/order-products/block-with-bonus-products/chips/chip/Chip';

import './BlockWithBonusChips.scss';
import SearchIconSvg from '@/app/components/resources/SVG/Search/SearchIconSvg';
import IconCrossSvg from '@/app/components/resources/SVG/Cross/IconCrossSvg';
import useOnClickOutside from 'use-onclickoutside';
import { Transition } from 'react-transition-group';
import OffersService from '@services/order/OffersService';
import OldPromotionService from '@services/order/OldPromotionService';
import PromotionBuilderService from '@/app/services/order/PromotionBuilderService';


const BlockWithBonusChips: FunctionComponent = () => {
    const [
        {
            currentOrder: {
                giftItemsInCatalog,
                promoItemsInCatalog,
                form: { allowGifts },
                offersBlockTabs: {
                    activeTab,
                    setDisplayStatusOfBonusItems,
                    resetDisplayStatusOfBonusItems,
                    setSearchQuery,
                    setSearchMode,
                    isCollapsed,
                    toggleCollapsed,
                },
                additionalParamsCurrentOrder: {
                    activePromotionBuilderModelId,
                },
                appliedPromotionBuilder,
            },
        },
        { t },
        {
            countAllAvailableBonusItemsInCatalog,
            allBonusItemsInCatalog,
            allBonusItemsInCatalogForPromotionBuilder,
        },
        {
            availablePromotionsForMainItemsInBasket,
        },
        {
            availablePromotionBuildersForMainItemsInBasket,
        },
    ] = useInstances(
        Store,
        I18NService,
        OffersService,
        OldPromotionService,
        PromotionBuilderService,
    );

    const handleSetDisplayStatusOfBonusItems = useCallback(
        (data: AddModeEnum) => {
            setDisplayStatusOfBonusItems(data);
            if (isCollapsed) {
                toggleCollapsed();
            }
        },
        [activeTab, isCollapsed],
    );

    const resetChipToDefault = useCallback(
        () => resetDisplayStatusOfBonusItems(),
        [],
    );

    useEffect(() => {
        resetDisplayStatusOfBonusItems();
    }, []);

    // блок хуков для поиска по offer-ам
    const [isSearchModeOn, setIsSearchModeOn] = useState<boolean>(false);

    const turnOffSearchMode = useCallback(() => {
        setIsSearchModeOn(false);
        setSearchMode(false);
    }, [setIsSearchModeOn]);

    const searchRef = useRef<HTMLInputElement>(null);
    useOnClickOutside(searchRef, turnOffSearchMode);

    const handleSwitchMode = useCallback(() => {
        setIsSearchModeOn((prevState) => {
            setSearchMode(!prevState);
            return !prevState;
        });
        if (isCollapsed) {
            toggleCollapsed();
        }
    },
    [setIsSearchModeOn, isCollapsed]);

    const onSearchInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setSearchQuery(value.toLowerCase());
    }, [setSearchQuery]);

    return (
        <div
            className={cn(
                'BlockWithBonusChips',
                {
                    active: !isCollapsed,
                },
            )}
        >
            <div className="BlockWithBonusChips__content">
                <div ref={searchRef} className={cn('products_search', isSearchModeOn && 'search_mode')}>
                    <div className="searchIcon" onClick={handleSwitchMode}>
                        <SearchIconSvg />
                    </div>
                    <input
                        id="searchOffers"
                        className="searchInput"
                        type="text"
                        placeholder={t('Найдите товар по названию или идентификатору', 'Search product by name or id')}
                        autoComplete="off"
                        onChange={onSearchInputChange}
                    />
                    <div className="closeIcon" onClick={handleSwitchMode}>
                        <IconCrossSvg />
                    </div>
                </div>
                <Transition
                    in={!isSearchModeOn}
                    timeout={100}
                    mountOnEnter
                    unmountOnExit
                >
                    {(state): JSX.Element => (
                        <div
                            className={cn([state, 'block'])}
                            data-cy="offers_block"
                        >
                            <span className="BlockWithBonusChips__title">
                                {`${t('Предложения', 'Offers')}:`}
                            </span>
                            <Chip
                                onClick={handleSetDisplayStatusOfBonusItems}
                                resetChipToDefault={resetChipToDefault}
                                itemsLengthInCatalog={
                                    appliedPromotionBuilder
                                        ? allBonusItemsInCatalogForPromotionBuilder.length : allBonusItemsInCatalog.length
                                }
                                buttonActive={activeTab === AddModeEnum.ALL}
                                stateForButton={AddModeEnum.ALL}
                                text={t('Все', 'All')}
                            />
                            {allowGifts && (
                                <Chip
                                    onClick={handleSetDisplayStatusOfBonusItems}
                                    resetChipToDefault={resetChipToDefault}
                                    itemsLengthInCatalog={
                                        appliedPromotionBuilder
                                            ? appliedPromotionBuilder.presentOptionalGifts.length : giftItemsInCatalog.length
                                    }
                                    buttonActive={activeTab === AddModeEnum.gift}
                                    stateForButton={AddModeEnum.gift}
                                    text={t('Подарки', 'Gifts')}
                                />
                            )}
                            {!allowGifts && activePromotionBuilderModelId && (
                                <Chip
                                    onClick={handleSetDisplayStatusOfBonusItems}
                                    resetChipToDefault={resetChipToDefault}
                                    itemsLengthInCatalog={giftItemsInCatalog.length}
                                    buttonActive={activeTab === AddModeEnum.gift}
                                    stateForButton={AddModeEnum.gift}
                                    text={t('Подарки', 'Gifts')}
                                />
                            )}
                            <Chip
                                onClick={handleSetDisplayStatusOfBonusItems}
                                resetChipToDefault={resetChipToDefault}
                                itemsLengthInCatalog={promoItemsInCatalog.length}
                                buttonActive={activeTab === AddModeEnum.promo}
                                stateForButton={AddModeEnum.promo}
                                text={t('Промо', 'Promo')}
                            />
                            <Chip
                                onClick={handleSetDisplayStatusOfBonusItems}
                                resetChipToDefault={resetChipToDefault}
                                itemsLengthInCatalog={
                                    availablePromotionsForMainItemsInBasket.length + availablePromotionBuildersForMainItemsInBasket.length
                                }
                                buttonActive={activeTab === AddModeEnum.PROMOTIONS}
                                stateForButton={AddModeEnum.PROMOTIONS}
                                text={t('Акции', 'promotions')}
                            />

                            <div
                                className="control-toggle"
                                onClick={toggleCollapsed}
                                data-cy="toggle_offers"
                            >
                                <span data-cy="count-available-offers">
                                    {`${countAllAvailableBonusItemsInCatalog} ${t('Доступны', 'Available')}`}
                                </span>
                                <ChevronSvgIcon
                                    className={cn(
                                        isCollapsed && 'chevronIcon__active',
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </Transition>
            </div>
        </div>
    );
};


export default observer(BlockWithBonusChips);
