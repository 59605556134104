import React, {
    FunctionComponent, useEffect, useLayoutEffect, useState,
} from 'react';
import { Router } from 'react-router-dom';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import CheckCookieEnabledService from '@core/services/CheckCookieEnabledService';
import SentryService from '@services/Logging/Sentry';
import MainRoutes from '@components/MainRoutes';
import HistoryService from '@services/HistoryService';

import { isChrome } from 'react-device-detect';

import { detectIncognito } from 'detectincognitojs';
import ErrorPage from '@components/main/error-page/error-page';
import CookieService from '@core/services/CookiesService';


const App: FunctionComponent = () => {
    const [
        Sentry,
        checkCookieEnabledService,
        {
            history,
        },
        cookieService,
    ] = useInstances(
        SentryService,
        CheckCookieEnabledService,
        HistoryService,
        CookieService,
    );

    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useEffect(
        () => {
            Sentry.init();
            checkCookieEnabledService.check();
        },
        [],
    );

    useLayoutEffect(
        () => history.listen(setState),
        [history],
    );

    const [error403, setError403] = useState<null | string>(null);
    const [loading, setLoading] = useState(true);

    const checkBrowserChrome = (): boolean => {
        if (!isChrome) {
            setError403('Error 403: Access denied. To work on 2wcall.com, you must use Google Chrome.');
            return false;
        }
        return true;
    };

    const eventHandler = (e: StorageEvent) => {
        if (e.key === 'openpages') {
            localStorage.page_available = Date.now();
        }
        if (e.key === 'page_available') {
            window.removeEventListener('storage', eventHandler, false);
            setError403('Working in two or more tabs is prohibited. Please go to the main tab to continue working.');
        }
    };

    const checkMultiTabs = () => {
        localStorage.openpages = Date.now();
        window.addEventListener('storage', eventHandler, false);
    };

    const determineIncognito = async (): Promise<void> => {
        await detectIncognito().then((result) => {
            if (result.isPrivate) {
                setError403('Working in the "Incognito" tab is prohibited. To work in 2wcall, go to the main tab.');
            }
        });
    };

    /**
     * Замедлил загрузку на пол секунды - не успевала отработать проверка на браузер и открытие дополнительных вкладок -
     * начиналась отрисовка, а вместе с ней запускались методы апи.
     */

    const slowDownLoading = () => {
        const timer = setTimeout(() => {
            setLoading(false);
            clearTimeout(timer);
        }, 500);
    };

    useEffect(() => {
        window.addEventListener('unload', () => {
            cookieService.logout();
        });
        void determineIncognito();
        if (checkBrowserChrome()) {
            checkMultiTabs();
        }
        slowDownLoading();
    }, []);


    if (error403?.length) {
        return (
            <ErrorPage
                text={error403}
            />
        );
    }

    if (!loading) {
        return (
            <Router
                location={state.location}
                navigationType={state.action}
                navigator={history}
            >
                <MainRoutes />
            </Router>
        );
    }

    return null;
};


export default observer(App);
