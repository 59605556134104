import { OrderService, SipService } from '@services/index';
import CallAutomatizationHandler from '@services/call-automatization/CallAutomatizationHandler';
import NoAnswerAfterSecHandler from '@services/orderStatusAutomatization/NoAnswerAfterSecHandler';
import { Store } from '@store/store';
import { toFactory } from 'react-ioc';


const NoAnswerAfterSecHandlerDI = (): any => [
    NoAnswerAfterSecHandler,
    toFactory(
        [
            SipService,
            Store,
            CallAutomatizationHandler,
            OrderService,
        ],
        (
            sipService: SipService,
            store: Store,
            callAutomotization: CallAutomatizationHandler,
            orderService: OrderService,
        ) => new NoAnswerAfterSecHandler(
            store,
            sipService,
            callAutomotization,
            orderService,
        ),
    ),
];


export default NoAnswerAfterSecHandlerDI;
