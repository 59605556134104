import get from 'lodash/get';
import ApiBase from '@core/api';
import { ICountryCurrencyItemWithCodeKey } from '@api/country-api-service/models';
import { getEnvGetCountryCurrency } from '@/env/requestEndpoints';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class CountryApiService {
    @Inject apiBase: ApiBase;

    async getCurrencyForCountryAndPartner(
        countryId: number,
        partnerId: number,
    ): Promise<ICountryCurrencyItemWithCodeKey[] | never> {
        const dataForSend = {
            country_id: countryId,
            partner_id: partnerId,
        };

        const response = await this.apiBase.get<IWithResponse<ICountryCurrencyItemWithCodeKey[]>>(
            getEnvGetCountryCurrency(),
            dataForSend,
        );

        const { data, message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as ICountryCurrencyItemWithCodeKey[];
    }
}


export default CountryApiService;
