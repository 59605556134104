import React, { FunctionComponent } from 'react';
import { block } from 'bem-cn';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import map from 'lodash/map';

import './billing-list-tooltip-content.scss';

export interface IDataTooltip {
    title?: string;
    value?: number;
}

interface IBillingListTooltipContent {
    data?: IDataTooltip[];
    active?: boolean;
    payload?: any;
    label?: string;
}

const BillingListTooltipContent: FunctionComponent<IBillingListTooltipContent> = ({ data, payload }) => {

    const [
        { t },
    ] = useInstances(
        I18NService,
    );

    const b = block('billing-list-tooltip-content');

    if (!data && payload) {
        data = map(payload, (month) => ({ title: month.name, value: month.value }));
    }

    return (
        <>
            {
                data && data.length > 0 &&
                <div className={b()}>
                    <div className={b('row')}>
                        {t('Одобренных', 'Approved')}
                    </div>
                    {payload &&
                    <div className={b('row')}>
                        <div className={b('key')}>
                            Date
                        </div>
                        <div className={b('value')}>
                            {payload[0].payload.date}
                        </div>
                    </div>
                    }
                    {data!.map((period, index) =>
                        <div className={b('row')} key={index}>
                            <div className={b('key')}>
                                {period.title}
                            </div>
                            <div className={b('value')}>
                                {period.value}
                            </div>
                        </div>,
                    )}
                </div>
            }
        </>
    );
};

export default BillingListTooltipContent;
