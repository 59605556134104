import React, { FunctionComponent } from 'react';

import { ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';

import './NomenclatureWrapper.scss';


type TNomenclatureWrapperProps = {
    readonly subtype: ProductItemSubTypeEnum;
};


const NomenclatureWrapper: FunctionComponent<TNomenclatureWrapperProps> = ({
    children,
    subtype,
}) => (
    <div
        key={`optional-placeholder-for-${subtype}`}
        className="currentProducts__table-row NomenclatureWrapper"
        data-cy={`optional-placeholder-for-${subtype}`}
    >
        <div
            className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
            style={{ width: '100%' }}
        >
            {children}
        </div>
    </div>
);


export default NomenclatureWrapper;
