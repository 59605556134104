import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { IVerifierProductsModel, VerifierProductsModel } from '@models/mobx-state-tree/verifierProducts.model';

export const VerifierModel = t
    .model('Verifier', {
        customerFullName: t.maybeNull(t.string),
        id: t.integer,
        phone: t.maybeNull(t.string),
        comment: t.maybeNull(t.string),
        createdDate: t.maybeNull(t.number),
        orderProducts: t.maybeNull(t.map(VerifierProductsModel)),
        foreignId: t.maybeNull(t.integer),
        partnerName: t.string,
        countryName: t.string,
        countryFlag: t.maybeNull(t.string),
        mask: t.optional(t.boolean, true), // Маскировать ли номер или нет
        maskValue: t.optional(t.integer, 4), // Количество замаскированных цыфр в номере
    })
    .views((self) => ({
        get verifierProducts(): IVerifierProductsModel[] | null {
            return self.orderProducts ? [...self.orderProducts.values()].reverse() : null;
        },
        get maskedPhoneNumber(): string {

            const phone = self.phone;

            if (self.mask) {
                if (phone) {
                    const lengthPhone = phone.length;
                    const maskValue = self.maskValue;

                    const notMaskedPhonePart = phone.split('').slice(0, lengthPhone - maskValue).join('');

                    return notMaskedPhonePart + 'X'.repeat(maskValue);
                }

                return '-';
            }

            return phone ? phone : '-';

        },
    }))
;


export interface IVerifierModel extends Instance<typeof VerifierModel> {}
export interface IVerifierModelSnapshotIn extends SnapshotIn<typeof VerifierModel> {}
export interface IVerifierModelSnapshotOut extends SnapshotOut<typeof VerifierModel> {}