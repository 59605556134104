import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';
import { ReasonsForEndingConversation } from '@services/sip/models';


export const CallHistoryEvent = t
    .model('CallHistoryEvent', {
        groupId: t.identifier,
        id: t.maybeNull(t.number),
        orderId: t.number,
        phone: t.string,
        userSip: t.string,
        startTime: t.number,
        isAbort: t.optional(t.boolean, false),
        timeAbort: t.maybeNull(t.number),
        communicationQualityWasSent: t.optional(t.boolean, false),
        addressActionTimeWasSent: t.optional(t.boolean, false),
        hangupCause: t.maybeNull(t.string),
        endCall: t.maybeNull(t.integer),
    })
    .actions((self) => ({
        setId(id: number): void {
            self.id = id;
        },
        setEndCall(value: ReasonsForEndingConversation | null): void {
            if (!self.endCall) {
                self.endCall = value;
            }
        },
        setIsAbort(isAbort: boolean): void {
            self.isAbort = isAbort;
        },
        setTimeAbort(timeAbort: number | null): void {
            self.timeAbort = timeAbort;
        },
        setCommunicationQualityWasSent(communicationQualityWasSent: boolean): void {
            self.communicationQualityWasSent = communicationQualityWasSent;
        },
        setAddressActionTimeWasSent(addressActionTimeWasSent: boolean): void {
            self.addressActionTimeWasSent = addressActionTimeWasSent;
        },
        setHangupCause(val: string | null): void {
            self.hangupCause = val;
        },
    }));


export interface ICallHistoryEventModel extends Instance<typeof CallHistoryEvent> {}
export interface ICallHistoryEventModelSnapshotIn extends SnapshotIn<typeof CallHistoryEvent> {}
export interface ICallHistoryEventModelSnapshotOut extends SnapshotOut<typeof CallHistoryEvent> {}
