import React, { FunctionComponent, useMemo } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';
import each from 'lodash/each';
import moment from 'moment';

import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@store/customerModeStore';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import GridTable from '@UIElements/GridTable';


type IColsNames = 'id' | 'phone' | 'template' | 'text' | 'status' | 'error' | 'createdAt';


const SmsMessageList: FunctionComponent = () => {
    const [
        { t },
        { textMessageHistory },
    ] = useInstances(
        I18NService,
        CustomerModeStore,
    );

    const cols = useMemo<IGridTableColumn[]>(
        () => [
            {
                id: 'id',
                label: t('Номер заказа', 'Order id'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '50px',
            },
            {
                id: 'phone',
                label: t('Телефон', 'Phone'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '68px',
            },
            {
                id: 'template',
                label: t('Шаблон', 'Template'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '156px',
            },
            {
                id: 'text',
                label: t('Текст сообщения', 'Sms text'),
                minMax_MaxValue: '2fr',
                minMax_MinValue: '156px',
            },
            {
                id: 'status',
                label: t('Статус', 'Status'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '25px',
            },
            {
                id: 'error',
                label: t('Ошибка', 'Error'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '25px',
            },
            {
                id: 'createdAt',
                label: t('Дата создания', 'Created at'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '72px',
            },
        ],
        [t],
    );

    const rows = useMemo<WithRows<IColsNames, IBaseCellData>[]>(
        () => textMessageHistory.map(
            (msg) => ({
                id: {
                    id: v4(),
                    content: String(msg.id),
                },
                phone: {
                    id: v4(),
                    content: msg.phone,
                },
                template: {
                    id: v4(),
                    content: msg.template,
                },
                text: {
                    id: v4(),
                    content: msg.text,
                },
                status: {
                    id: v4(),
                    content: msg.status,
                },
                error: {
                    id: v4(),
                    content: msg.error,
                },
                createdAt: {
                    id: v4(),
                    content: String(msg.createdAt),
                },
            }),
        ),
        [textMessageHistory],
    );

    const RenderContentWithImage = (rowsData: WithRows<IColsNames, IBaseCellData>[]) => {
        const data: JSX.Element[] = [];
        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            each(row, (value, key) => {
                rowId = value.content;

                if (key === 'createdAt') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                key={key}
                            >
                                {moment(Number(value.content)).format('L LTS')}
                            </div>
                        ),
                    );

                    return;
                }

                if (key === 'status') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                key={key}
                            >
                                <button
                                    data-cy="status_button_sms"
                                    className="smsStatus"
                                    type="button"
                                    disabled
                                >
                                    {value.content}
                                </button>
                            </div>
                        ),
                    );

                    return;
                }

                if (key === 'text' || key === 'template') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height dots_in_text"
                                key={key}
                                title={value.content}
                            >
                                {value.content}
                            </div>
                        ),
                    );

                    return;
                }

                rowCell.push(
                    (
                        <div
                            className="grid_cell grid_cell_height"
                            key={key}
                        >
                            {value.content}
                        </div>
                    ),
                );
            });

            data.push(
                (
                    <div
                        data-cy="grid_row_sms"
                        className="grid_row"
                        id={rowId}
                        key={rowId}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    return (
        <GridTable
            className="customer_order-grid-orders-table"
            columns={cols}
            rows={rows}
            rowsSize={rows.length}
            cellsRenderer={RenderContentWithImage}
            tableContentHeight={551}
        />
    );
};


export default observer(SmsMessageList);
