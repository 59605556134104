import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export enum ReplaceTransactionStatusEnum {
    START = 'START', // начали транзакцию значит показываем интерфейс замены
    SHOULD_COMMIT = 'SHOULD_COMMIT', // выбрали два элемента и нажали swap
}

export enum ReplaceTransactionTypeEnum {
    SET = 'SET',
    SET_ITEM = 'SET_ITEM',
    PROMOTIONS = 'PROMOTIONS',
}

const replaceTransactionTypeOptions = [
    ReplaceTransactionTypeEnum.SET,
    ReplaceTransactionTypeEnum.SET_ITEM,
    ReplaceTransactionTypeEnum.PROMOTIONS,
];

const replaceTransactionStatus = [
    ReplaceTransactionStatusEnum.START,
    ReplaceTransactionStatusEnum.SHOULD_COMMIT,
];


export const ReplaceTransactionModel = t
    .model('ReplaceTransactionModel', {
        from: t.string, // идентификатор чего меняем
        to: t.maybeNull(t.string), // идентификатор на что меняем
        parentProductId: t.maybeNull(t.string), // идентификатор набора, если меняем товар в составе набора
        type: t.enumeration(replaceTransactionTypeOptions),
        status: t.optional(t.enumeration(replaceTransactionStatus), ReplaceTransactionStatusEnum.START),
    })
    .views((self) => ({
        get getTo(): string | null {
            return self.to;
        },

        get isReadyToProceedReplacement(): boolean {
            return Boolean(self.to && self.from);
        },
    }))
    .actions((self) => ({
        setTo(to: string): void {
            self.to = to;
        },

        setStatus(status: ReplaceTransactionStatusEnum): void {
            if (!self.to && status === ReplaceTransactionStatusEnum.SHOULD_COMMIT) {
                throw new Error('Can`t change status if << to >> props not specified');
            }

            self.status = status;
        },
    }));


export interface IReplaceTransactionModel extends Instance<typeof ReplaceTransactionModel> {}
export interface IReplaceTransactionModelSnapshotIn extends SnapshotIn<typeof ReplaceTransactionModel> {}
export interface IReplaceTransactionModelSnapshotOut extends SnapshotOut<typeof ReplaceTransactionModel> {}
