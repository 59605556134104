import React, { FunctionComponent, useCallback } from 'react';
import { EnumCallButtonPhase, ICallButtonProps } from '@UIElements/CallButton/models';
import { EnumSipStatus } from '@services/sip/models';
import cn from 'classnames';

import CallButtonInactiveSvg from '@UIElements/CallButton/includes/CallButtonInactiveSvg';
import CallButtonEndSvg from '@UIElements/CallButton/includes/CallButtonEndSvg';
import CallButtonReadySvg from '@UIElements/CallButton/includes/CallButtonReadySvg';

import './CallButton.scss';


const CallButton: FunctionComponent<ICallButtonProps> = ({ status, onClick, forceInactive = false }) => {
    let phase = EnumCallButtonPhase.INACTIVE;

    if (
        status === EnumSipStatus.FAIL
        || status === EnumSipStatus.REGISTRATION_FAIL
        || status === EnumSipStatus.CONNECTING
        || status === EnumSipStatus.CONNECTED
        || status === EnumSipStatus.ENDED
    ) {
        phase = EnumCallButtonPhase.INACTIVE;
    }

    if (status === EnumSipStatus.REGISTERED) {
        phase = EnumCallButtonPhase.READY;
    }

    if (status === EnumSipStatus.LIVE) {
        phase = EnumCallButtonPhase.CALL;
    }

    if (status === EnumSipStatus.NEW_MESSAGE) {
        phase = EnumCallButtonPhase.CALL;
    }

    if (
        status === EnumSipStatus.DIALING
        || status === EnumSipStatus.PROGRESS
    ) {
        phase = EnumCallButtonPhase.RINGING;
    }

    const onClickCallBack = useCallback(() => {
        if (phase === EnumCallButtonPhase.INACTIVE || forceInactive) {
            return;
        }

        onClick();
    }, [phase, onClick]);

    if (forceInactive || phase === EnumCallButtonPhase.INACTIVE) {
        return (
            <div
                className={cn('CallButton', (forceInactive || phase === EnumCallButtonPhase.INACTIVE) && 'disabled')}
                onClick={onClickCallBack}
            >
                <CallButtonInactiveSvg />
            </div>
        );
    }

    return (
        <div
            className={cn('CallButton')}
            onClick={onClickCallBack}
            data-cy="call_button_click"
        >
            {phase === EnumCallButtonPhase.READY && (
                <CallButtonReadySvg />
            )}
            {(phase === EnumCallButtonPhase.RINGING || phase === EnumCallButtonPhase.CALL) && (
                <CallButtonEndSvg />
            )}
        </div>
    );
};


export default CallButton;
