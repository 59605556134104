export const SELECT_DEFAULT = { label: '', value: {} };

export interface ISelectedValue<T> {
    label: string;
    value: T;
}

export interface ISelectProps {
    selected: ISelectedValue<any> | null | undefined;
    values: ISelectedValue<any>[];
    onSelect: (value: ISelectedValue<any>) => void;
    className?: string;
    placeholder?: string;
    width?: string;
    height?: string;
    error?: string;
    label?: string;
    withoutCheckIcon?: boolean;
    disabled?: boolean;
    isAClosedList?: boolean;
    dataCyValue?: string;
    key?: string | number;
    showClearSelectedButton?: boolean;
    clearSelectedButton?: () => void;
    id?: string;
    keys?: string[];
    setKey?: (key: string) => void;
}

export interface ISelectEnhance {
    isPlaceholder: boolean;
    selectStyles: Record<string, string>;
    onSelect: (value: ISelectedValue<any>) => void;
    values: ISelectedValue<any>[];
    className: string;
    selected: ISelectedValue<any> | null | undefined;
    error: string;
    label: string;
    placeholder: string;
    width?: string;
    disabled: boolean;
    withoutCheckIcon: boolean;
}
