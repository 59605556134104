import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

import { OrderPageUIStateModel } from '@models/mobx-state-tree/ui/OrderPageUIStateModel';
import { BottomMobileMenuUIStateMode } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { SideBarDeepMenuUIStateModel } from '@models/mobx-state-tree/ui/SideBarDeepMenuUIStateModel';


export const RootUIStateModel = t
    .model('RootUIStateModel', {
        orderPage: t.optional(OrderPageUIStateModel, {}),
        bottomMobileTab: t.optional(BottomMobileMenuUIStateMode, {}),
        sidebar: t.optional(SideBarDeepMenuUIStateModel, {}),
    });


export interface IRootUIStateModel extends Instance<typeof RootUIStateModel> {}
export interface IRootUIStateModelSnapshotIn extends SnapshotIn<typeof RootUIStateModel> {}
export interface IRootUIStateModelSnapshotOut extends SnapshotOut<typeof RootUIStateModel> {}
