import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class IncorrectCombinationOfAttributesError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'В конструкторе акций получили недопустимое пересечение атрибутов (promotion_shipping_price_condition) для расчета доставки:';
    }
}


export default IncorrectCombinationOfAttributesError;
