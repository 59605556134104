import CommunicationQualityApiService from '@api/communication-quality-api-service';
import { ConfirmModalType, ConfirmModalAction } from '@core/models/ModalWindow';
import ModalService from '@core/services/ModalService';
import { ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { SipService } from '@services/index';
import { Store } from '@store/store';
import { computed, IReactionDisposer, reaction } from 'mobx';
import { EnumSipStatus, ReasonsForEndingConversation } from '../sip/models';

/**
 * [Post Dial Delay]
 *
 * **pdd_limit_end_call** - Принудительно прерывать соединение, если невозможно соединиться более - X секунд\
 * **webrtc_pdd_limit** - Лимит PDD (Post Dial Delay)\
 *
 * Если мы начали звонок, но еще не соединились и pdd_limit_end_call = true,\
 * то нужно следить сколько мы находимся в этом состоянии, запустив таймер.\
 * Если счетчик таймера >= webrtc_pdd_limit, то необходимо сбросить звонок,\
 * Проставив статус с соответствующей причиной завершения звонка
 *
 * Если работает функционал автонабора,\
 * то мы просто сбросываем звонок, автонабор сам засчитывает попытку звонка
 */
class PddLimitHandler {
    constructor(
        private readonly _sipService: SipService,
        private readonly store: Store,
        private readonly communicationQualityApiService: CommunicationQualityApiService,
        private readonly modalService: ModalService,
    ) {
    }

    private _establishingCallInterval: NodeJS.Timeout | undefined;

    private _establishingCallSeconds = 0;

    private _disposeOnOutgoingCallEstablishing: IReactionDisposer | undefined;

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get _pddLimitEndCall(): boolean {
        return this._currentOrder.country.pddLimitEndCall;
    }

    private get _pddLimit(): number | null {
        return this._currentOrder.country.pddLimit;
    }

    @computed
    private get callHistoryEvent(): ICallHistoryEventModel | null {
        return this._currentOrder.lastCallHistoryEvent;
    }

    private setEstablishingCallSeconds(value: number) {
        this._establishingCallSeconds = value;
    }

    public unsubscribe = (): void => {
        if (this._disposeOnOutgoingCallEstablishing) {
            this._disposeOnOutgoingCallEstablishing();
        }
    };

    public subscribe = (): void => {
        this.unsubscribe();
        /**
         * Если в обычном режиме И совершается исходящий вызов
         * Запускаем счетчик времени с интервалом в 1 секунду,\
         * каждый тик проверяем в каком статусе звонок
         *
         * Post Dial Delay (PDD) is the time or delay\
         * that occurs from the time a number has been dialed\
         * until the caller or called party hears ringing.
         */
        this._disposeOnOutgoingCallEstablishing = reaction(
            () => this._currentUser.mode === UserModeEnum.REGULAR
                && this._sipService.getSipStatus() === EnumSipStatus.DIALING
                && this._pddLimitEndCall,
            (isEstablishingACall) => {
                if (isEstablishingACall) {
                    this._establishingCallInterval = setInterval(() => {
                        void this.proceedPddAutomatization();
                    }, 1000);
                } else if (this._establishingCallInterval) {
                    clearInterval(this._establishingCallInterval);
                    this.setEstablishingCallSeconds(0);
                }
            },
        );
    };

    private async proceedPddAutomatization() {
        this.setEstablishingCallSeconds(this._establishingCallSeconds + 1);

        if (
            typeof this._pddLimit === 'number'
            && this._establishingCallSeconds >= this._pddLimit) {
            this.store.currentOrder.setPddModalWindowShown(true);
            await this.nativeBehavior();
            this.store.currentOrder.setPddModalWindowShown(false);
        }
    }

    public async nativeBehavior(): Promise<void> {
        if (this.callHistoryEvent) {
            this.callHistoryEvent.setEndCall(ReasonsForEndingConversation.LIMIT_PDD);
        }

        this._sipService.endCall();
        this.setEstablishingCallSeconds(0);

        await this.modalService.showConfirmModal(
            this.store.currentOrder.t(
                'Превышено время ожидания соединения (PDD)',
                'The call was automatically ended. Connection Waiting Limit Exceeded (PDD)',
            ),
            ConfirmModalType.Yes,
            5000,
            ConfirmModalAction.Yes,
        );

        // eslint-disable-next-line no-console
        console.info('The call was dropped because the Post Dial Delay (PDD) limit was reached');
        void this.communicationQualityApiService.createComplaintWhenCallDroppedByPddLimit(
            this.store.currentOrder.lastCallHistoryEvent?.id || 0,
        );
    }
}

export default PddLimitHandler;
