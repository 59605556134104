import React, { FC } from 'react';
import Countdown from 'react-countdown';
import { CountdownRendererFn } from 'react-countdown/dist/Countdown';


type TCountdownWithCallbackProps = {
    onComplete: () => void;
    seconds: number;
};

const renderer: CountdownRendererFn = ({ total }) => {
    if (total === 0) {
        return null;
    }

    return <span>{total / 1000}</span>;
};

const CountdownWithCallback: FC<TCountdownWithCallbackProps> = ({
    seconds,
    onComplete,
}) => (
    <Countdown
        onComplete={onComplete}
        renderer={renderer}
        date={Date.now() + seconds}
    />
);


export default CountdownWithCallback;
