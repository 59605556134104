import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface ICloseIconSvg {
    className?: string;
}


const CloseIconSvg: FunctionComponent<ICloseIconSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['CloseIconSvg', className])}
        {...props}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.00016 4.05687L8.52876 0.52827C8.78911 0.26792 9.21122 0.26792 9.47157 0.52827C9.73192 0.78862 9.73192 1.21073 9.47157 1.47108L5.94297 4.99967L9.47157 8.52827C9.73192 8.78862 9.73192 9.21073 9.47157 9.47108C9.21122 9.73143 8.78911 9.73143 8.52876 9.47108L5.00016 5.94248L1.47157 9.47108C1.21122 9.73143 0.789108 9.73143 0.528758 9.47108C0.268409 9.21073 0.268409 8.78862 0.528758 8.52827L4.05735 4.99967L0.528758 1.47108C0.268409 1.21073 0.268409 0.78862 0.528758 0.52827C0.789108 0.26792 1.21122 0.26792 1.47157 0.52827L5.00016 4.05687Z"
            fill="black"
        />
    </svg>
);


export default CloseIconSvg;
