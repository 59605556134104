import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const Language = t
    .model('Language', {
        id: t.number,
        name: t.string,
        locale: t.string,
        icon: t.string,
    });


export interface ILanguageModel extends Instance<typeof Language> {}
export interface ILanguageModelSnapshotIn extends SnapshotIn<typeof Language> {}
export interface ILanguageModelSnapshotOut extends SnapshotOut<typeof Language> {}
