import {
    IAnyModelType,
    Instance,
    isAlive,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';
import { IProductItemModel, ProductItemModel } from '@models/mobx-state-tree/newModels/ProductItem.model';
import each from 'lodash/each';

export const SetProductComposition = t
    .model('SetProductComposition', {
        id: t.identifier, // генерируемый uuid\v4
        index: t.number, // Порядковый номер в массиве
        alternatives: t.maybeNull(t.map(t.reference(t.late((): IAnyModelType => ProductItemModel)))), // null для статического набора, иначе, массив идентификаторов ProductItemModel
        quantityInSet: t.integer, // Кол-во единиц в наборе // todo: количество товара в наборе хранится в двух местах (еще в ProductItem.model.ts)
        selectedProductItem: t.reference(t.late((): IAnyModelType => ProductItemModel)), // Выбранный ProductItemModel в составе сета
        originalProductItem: t.reference(t.late((): IAnyModelType => ProductItemModel)), // Исходный выбранный ProductItemModel в составе сета
    }).views((self) => ({
        /**
         * Получить исходный товар в составе сета.
         */
        get getOriginalProductItem(): IProductItemModel | null {
            if (isAlive(self.originalProductItem)) {
                return self.originalProductItem as IProductItemModel;
            }

            return null;
        },
        /**
         * Получить выбранный товар в составе сета.
         */
        get getSelectedProductItem(): IProductItemModel {
            return self.selectedProductItem as IProductItemModel;
        },
        /**
         * Получить массив товаров на замену
         */
        get getAllAlternatives(): IProductItemModel[] {
            const alternatives: IProductItemModel[] = [];
            if (self.alternatives) {
                each([...self.alternatives.values()], (item: IProductItemModel) => {
                    alternatives.push(item);
                });
            }

            return alternatives;
        },
        /**
         * Какой товар сейчас выбран: дефолтный или измененный?
         */
        get isDefaultProductSelected(): boolean {
            return self.originalProductItem === self.selectedProductItem;
        },
    }))
    .actions((self) => ({
        /**
         * Заменить выбранный товар на товар из альтернатив, либо на исходный. \
         * Передается идентификатор модели, с помощью которого осуществляется поиск в массиве, состоящем из исходного товара и альтернатив для него
         * @param productItemModelId - ID IProductItemModel
         */
        changeSelectedItemToItemFromAlternativesOrRestoreToOriginal(productItemModelId: string): void {
            const itemsForReplacement = [self.getOriginalProductItem, ...self.getAllAlternatives];
            const isFound = itemsForReplacement.find((item: IProductItemModel | null) => item && item.id === productItemModelId);
            if (isFound) self.selectedProductItem = isFound.id;
        },
        /**
         * Восстановить исходный товар набора
         */
        restoreOriginalProduct(): void {
            self.selectedProductItem = self.originalProductItem;
        },
    }));


export interface ISetProductComposition extends Instance<typeof SetProductComposition> {}
export interface ISetProductCompositionSnapshotIn extends SnapshotIn<typeof SetProductComposition> {}
export interface ISetProductCompositionSnapshotOut extends SnapshotOut<typeof SetProductComposition> {}
