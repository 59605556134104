import { Store } from '@store/store';

import LocalStorageService from '@core/services/LocalStorageService';

import { POST_PROCESSING_DATA } from '@core/constants/local-storage';
import CallWidgetModal from '@components/main/call-widget-modal/call-widget-modal';
import {
    OrderFetchService,
    OrderService,
} from '@services/index';
import ModalService from '@core/services/ModalService';
import { NotificationModel } from '@models/mobx-state-tree/Notification.model';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import { IUserModel } from '@models/mobx-state-tree/user.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { ISubStatusInfo } from '@api/order-api-service/models';
import OrderApiService from '@api/order-api-service';
import { action } from 'mobx';
import { applySnapshot } from 'mobx-state-tree';
import { IVirtualButton } from '@interfaces/form.interface';

export interface IHandlePostStartTimerModalStatus {
    order_id: number;
    phone: string;
    allowed_statuses: number[];
    subStatuses: ISubStatusInfo[];
    buttons: Record<string, string>;
    limit_modal_status: number;
    message: string;
    virtualButtons: Record<string, IVirtualButton>;
}

export interface IPostStartLimitSave {
    limit_save_timer_sec: number;
    order_id: number;
    recall_date: string;
    string: number;
    sub_status: number;
}

export interface IHandlePostSetReject {
    status: number;
    sub_status: number;
}

class PostProcessingService {

    constructor(
        private readonly _store: Store,
        private readonly _localStorageService: LocalStorageService,
        private readonly _orderFetchService: OrderFetchService,
        private readonly _modalService: ModalService,
        private readonly _orderService: OrderService,
        private readonly _inactivityOperatorStorageService: InactivityOperatorStorageService,
        private readonly _orderApiService: OrderApiService,
    ) {

    }

    private get _currentUser(): IUserModel {
        return this._store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private _setInLocalStoragePostProcessingData = (data: IHandlePostStartTimerModalStatus) => {
        this._localStorageService.setItem(
            POST_PROCESSING_DATA, data);
    };

    private _removeFromLocalStoragePostProcessingData = () => {
        this._localStorageService.removeItem(POST_PROCESSING_DATA);
    };

    public handlePostStartTimerModalStatus = (data: IHandlePostStartTimerModalStatus) => {

        this._store.currentOrder.form.setDisableStatusSubStatusPhoneButtons(false);

        if (this._store.currentOrder.postProcessing) {

            this._store.currentUser.setIsReady(true);

            const { message } = data;

            this._snapshotPostProcessingData(data);

            this._setInLocalStoragePostProcessingData(data);

            this._showModalStatusWindow(message);

            this._store.inactivityTimer.startModalTimer();

        }
    };

    public handlePostStopTimerModalStatus = async (): Promise<void> => {

        this._modalService.clearModalStack();
        this._removeFromLocalStoragePostProcessingData();

        try {
            const notification = await this._orderService.getNotification();
            if (notification) {
                const notificationModel = NotificationModel.create({
                    id: String(notification.id),
                    message: notification.message,
                    isFeedback: notification.is_feedback,
                });

                const {
                    clearModalTimePassedTimer,
                    setModalFormFillTimePassed,
                } = this._store.inactivityTimer;

                clearModalTimePassedTimer();
                setModalFormFillTimePassed(0);

                this._resetModalTimerLocalStorage();

                this._store.currentOrder.form.setDisableStatusSubStatusPhoneButtons(false);
                this._store.currentOrder.setSelectedStatus(null);
                this._store.currentOrder.setSelectedSubStatus(null);
                this._store.currentUser.setIsReady(false);

                this._store.addNotification(notificationModel);

            }

        } catch (e) {
            console.log(e);
        }
    };

    public postStartLimitSave = (data: IPostStartLimitSave): void => {
        const { setSaveFormFillTimeLimit, startSaveTimer, setSaveFormFillTimePassed } = this._store.inactivityTimer;
        setSaveFormFillTimeLimit(data.limit_save_timer_sec);
        setSaveFormFillTimePassed(0);
        this._resetModalTimerLocalStorage();
        this._removeFromLocalStoragePostProcessingData();
        startSaveTimer(true);
    };

    public handlePostSetReject = async (data: IHandlePostSetReject): Promise<void> => {

        try {
            this._store.currentOrder.setSelectedStatus(data.status);
            this._store.currentOrder.setSelectedSubStatus(data.sub_status);

            const { clearOrderTimePassed } =  this._store.inactivityTimer;

            await this._orderApiService.postProcessingReject(this._store.currentOrder.id);

            this._store.currentUser.setIsReady(false);

            clearOrderTimePassed();

            this._resetModalTimerLocalStorage();
            this._removeFromLocalStoragePostProcessingData();

            this._store.clearCurrentOrder();

        } catch (e) {
            console.log(e);
        }

    };

    private _resetModalTimerLocalStorage = () => {
        this._inactivityOperatorStorageService.resetModalTimePassedInLocalStorage(
            this._currentOrder.id,
            this._currentUser.id,
        );
    };

    private _showModalStatusWindow = (message: string) => {
        if (this._modalService.isAFreeState) {
            void this._modalService.showModal(
                CallWidgetModal,
                { message },
                true,
            );
        }
    };

    @action
    private _snapshotPostProcessingData = ({ limit_modal_status, buttons, subStatuses, virtualButtons }: Partial<IHandlePostStartTimerModalStatus>, modalTimePassed?: number) => {
        const { convertSubStatusInfo, prepareChangeStatusButtons, prepareStatuses } = this._orderFetchService.orderPrepareMetadataState;
        applySnapshot(this._currentOrder.subStatuses, convertSubStatusInfo(subStatuses!));
        applySnapshot(this._currentOrder.form.changeStatusButtons, prepareChangeStatusButtons(buttons!, virtualButtons ? virtualButtons : {}));
        applySnapshot(this._currentOrder.form.statuses, prepareStatuses(buttons!, virtualButtons ? virtualButtons : {}));
        this._store.inactivityTimer.setModalFormFillTimeLimit(limit_modal_status!);
        if (modalTimePassed) {
            this._store.inactivityTimer.setModalFormFillTimePassed(modalTimePassed);
        }
    };

    public checkEndingModalTimerAfterReload = () => {
        const modalTimePassed = this._localStorageService
            .storageInactivity[`${String(this._currentOrder.id)}-${String(this._currentUser.id)}`]?.modalTimePassed;

        if (modalTimePassed) {
            const postProcessingData = this._localStorageService
                .getItem(POST_PROCESSING_DATA);
            if (postProcessingData) {
                this._snapshotPostProcessingData(postProcessingData, modalTimePassed);
                this._showModalStatusWindow(postProcessingData.message);
            }
        }
    };

}


export default PostProcessingService;
