import get from 'lodash/get';

import ApiBase from '@core/api';
import {
    getEnvAutoCallHangUpByTaskEndpoint,
    getEnvCreateCallHistoryEndpoint,
    getEnvUpdateCallHistoryEndpoint,
} from '@/env/requestEndpoints';
import { ReasonsForEndingConversation } from '@services/sip/models';
import { Inject } from 'react-ioc';

interface ICallHistoryCreate {
    id: string;
}

interface ICallHistoryUpdate {}

interface ICallHistoryResponse<T> extends IWithResponse<T> {
    data: T;
}

interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class CallHistoryApiService {
    @Inject apiBase: ApiBase;

    async onCallStart(
        orderId: number,
        groupId: string,
        phone: string,
        userSip: string,
        queueId: number | null,
        sipHost: string | null,
    ): Promise<ICallHistoryCreate> {
        const sendData = {
            order_id: orderId,
            group_id: groupId,
            user_sip: userSip,
            queue_id: queueId,
            phone,
            actual_communication_server_url: sipHost,
        };

        const response = await this.apiBase.post<ICallHistoryResponse<ICallHistoryCreate>>(
            getEnvCreateCallHistoryEndpoint(),
            sendData,
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async onCallEnd(
        callHistoryEventId: number,
        endCall: ReasonsForEndingConversation,
        hangupCause?: string,
    ): Promise<ICallHistoryUpdate> {
        const sendData = {
            id: callHistoryEventId,
        };

        const response = await this.apiBase.post<ICallHistoryResponse<ICallHistoryUpdate>>(
            getEnvUpdateCallHistoryEndpoint(),
            Object.assign(
                sendData,
                hangupCause && hangupCause.length ? { hangup_cause: hangupCause } : null,
                endCall ? { end_call: endCall } : null,
            ),
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    /**
     * При прогрессивном автодозвоне прерывает звонок если оператор нажал отмену звонка,
     * но этот метод работает только если клиеент взял трубку телефона
     */

    async hangUpAutoCallByTask(taskId: number): Promise<boolean | void> {

        const response = await this.apiBase.post<IWithResponse<[]>>(
            getEnvAutoCallHangUpByTaskEndpoint(),
            {
                task_id: taskId,
            },
        );

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return true;

    }
}


export default CallHistoryApiService;
