import React, {
    useState, useEffect, useCallback, KeyboardEvent, FunctionComponent,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { AuthService } from '@/app/services';
import { Locations } from '@core/models/locations';
import { ENTER_KEY_CODE } from '@core/constants/keyboard';
import TextInput from '@UIElements/TextInput';
import Button from '@UIElements/Button';
import LogoText from '@images/logo_text.png';
import UserService from '@services/UserService';
import { InputTypes } from '@UIElements/TextInput/models';
import getIsOldFormHybridMode from '@/env/getIsOldFormHybridMode';
import CookieService from '@core/services/CookiesService';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import { NO_INTERNET_CONNECTION } from '@core/constants/noInternetConnection';

const DISABLED_TIME = 5000;



enum InputType {
    PASSWORD = 'password',
    LOGIN = 'login',
}

const isOldFormHybridMode = getIsOldFormHybridMode();


const Login: FunctionComponent = () => {
    const [
        cookieService,
        { t },
        authService,
        userService,
        modalService,
    ] = useInstances(
        CookieService,
        I18NService,
        AuthService,
        UserService,
        ModalService,
    );
    const [loaded, setLoaded] = useState<boolean>(false);
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorRequest, setErrorRequest] = useState<string>('');

    const navigate = useNavigate();

    const { isAuthCookieValid } = userService;

    const [EMPTY_INPUTS] = useState(
        () => t('Не все поля заполнены', 'Not all fields are filled'),
    );

    let clearErrorRequestTimeout: NodeJS.Timer | null = null;

    useEffect(() => {
        // Костылек. Если существует кука ADVANCED_FRONTEND, то удаляем ее и перезагружаем страницу.
        if (
            !isAuthCookieValid
            && isOldFormHybridMode
            && cookieService.hasValueAdvancedFrontend
        ) {
            //cookieService.clearCookieAdvancedFrontend();
            //window.location.reload();
        }

        const currentModalId = modalService.currentModalId;
        if (currentModalId) {
            modalService.closeModalById(currentModalId);
        }

        if (isAuthCookieValid) {
            navigate(Locations.MAIN);
        }

        return () => {
            if (clearErrorRequestTimeout) {
                clearTimeout(clearErrorRequestTimeout);
            }
        };

    }, [isAuthCookieValid]);

    const clearErrorRequest = () => {
        setErrorRequest('');
    };

    const onSetErrorRequest = (text: string) => {
        setErrorRequest(text);
        clearErrorRequestTimeout = setTimeout(clearErrorRequest, 7000);
    };

    const [pending, setPending] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const setDisabledButton = () => {
        // Если нет интернета блокируем кнопку на 5 секунд иначе оператор может постоянно жать на кнопку
        // логина, тем самым нагружая сервер лишними запросами
        if (localStorage.getItem(NO_INTERNET_CONNECTION)) {
            setDisabled(true);
            const timer = setTimeout(() => {
                setDisabled(false);
                clearTimeout(timer);
            }, DISABLED_TIME);
        }
    };


    const onLogin = async (): Promise<void> => {

        setDisabledButton();

        if (pending) {
            return;
        }

        setPending(true);

        try {
            if (!login.trim().length || !password.trim().length) {
                onSetErrorRequest(EMPTY_INPUTS);
                return;
            }
            cookieService.clearCallibriCookies();

            userService.clearCallibriTempraryData();

            clearErrorRequest();

            setLoaded(true);

            await authService.auth(login.trim(), password.trim());

        } catch (e) {
            setLoaded(false);
            onSetErrorRequest(e);
            setPending(false);
        }
    };

    const onEnteringForm = useCallback(
        (value: string, type: InputType): void | never => {
            clearErrorRequest();
            switch (type) {
                case InputType.PASSWORD: {
                    setPassword(value);
                    break;
                }
                case InputType.LOGIN: {
                    setLogin(value);
                    break;
                }
                default: {
                    throw new Error('Not found name field.');
                }

            }
        },
        [errorRequest],
    );

    const onKeyPress = useCallback(async (event: KeyboardEvent): Promise<void> => {
        if (event.charCode === ENTER_KEY_CODE) {
            await onLogin();
        }
    }, [login, password]);

    return (
        <div className="login">
            <div className="login__page">
                <div className="login__form">
                    <form className="login__b-form">
                        <div className="logo">
                            <img className="logo-img" src={LogoText} alt="" />
                        </div>
                        <div className="row" data-cy="login-page-input-row">
                            <TextInput
                                placeholder={t('Введите ваше имя пользователя', 'Input your login')}
                                className="inputFormAuth"
                                label={t('Имя пользователя', 'Login')}
                                value={login}
                                name={InputType.LOGIN}
                                onChangeValue={(value: string) => onEnteringForm(value, InputType.LOGIN)}
                                onKeyPress={onKeyPress}
                                width="100%"
                            />
                        </div>
                        <div className="row">
                            <TextInput
                                placeholder={t('Введите ваш пароль', 'Input your password')}
                                className="inputFormAuth"
                                label={t('Пароль', 'Password')}
                                type={InputTypes.PASSWORD}
                                name={InputType.PASSWORD}
                                value={password}
                                onChangeValue={(value: string) => onEnteringForm(value, InputType.PASSWORD)}
                                onKeyPress={onKeyPress}
                                width="100%"
                            />
                        </div>
                        <div className="row">
                            <Button
                                className="submitBtn"
                                variant="7"
                                size="1"
                                text={t('Войти', 'Enter')}
                                width="192px"
                                preloaderColor="#fff"
                                onClick={onLogin}
                                disabled={pending || disabled}
                                isVisiblePreloader={loaded}
                            />
                        </div>
                        <div className="row">
                            <div className={cn(['alert alert-danger auth-error', !errorRequest && 'd-none'])}>
                                {errorRequest && t(`${errorRequest}`)}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="copyright">
                    {t('2WCALL - Панель управления © 2019 Все права защищены', '2WCALL - Control panel © 2019 All rights reserved')}
                </div>
            </div>
        </div>
    );
};


export default observer(Login);
