import { toFactory } from 'react-ioc';

import SipServiceBase from '@services/sip/SipServiceBase';
import SipServiceBaseEventSubscriptionService from '@services/sip/SipServiceBaseEventSubscriptionService';
import AudioService from '@core/services/AudioService';
import { ISipServiceBase } from '@services/sip/models';
import { Store } from '@store/store';


const SipServiceBaseEventSubscriptionServiceDI = (): any => [
    SipServiceBaseEventSubscriptionService,
    toFactory(
        [
            SipServiceBase,
            AudioService,
            Store,
        ],
        (
            sip: ISipServiceBase,
            audioService: AudioService,
            store,
        ) => new SipServiceBaseEventSubscriptionService(
            sip,
            audioService,
            store,
        ),
    ),
];


export default SipServiceBaseEventSubscriptionServiceDI;
