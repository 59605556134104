import get from 'lodash/get';
import ApiBase from '@core/api';
import { getEnvCreateCommunicationQualityResultEndpoint, getEnvSaveCommunicationQualityEndpoint } from '@/env/requestEndpoints';
import { Inject } from 'react-ioc';


interface ICommunicationQualitySaveResponse {
    status: 'success' | 'error';
    message?: string;
}


class CommunicationQualityApiService {
    @Inject apiBase: ApiBase;

    /**
     * Сохранение жалобы на качество связи
     */
    async save(
        orderId: number,
        callHistoryId: number,
        userId: number,
        isAbort: boolean,
        timeAbort: number,
        communicationQualityIds: string[],
        sipCode: string,
        comment: string,
    ): Promise<boolean | never> {
        const dataForSend = {
            order_id: orderId,
            call_history_id: callHistoryId,
            user_id: userId,
            is_abort: isAbort ? 1 : 0,
            time_abort: isAbort ? timeAbort : 0,
            data: communicationQualityIds,
            sip_code: sipCode,
            comment,
        };

        const response = await this.apiBase.post<ICommunicationQualitySaveResponse>(
            getEnvSaveCommunicationQualityEndpoint(),
            dataForSend,
        );

        const { message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }

    /**
     * Автоматическое создание жалобы на качество связи при автосбросе при лимите PDD
     */
    async createComplaintWhenCallDroppedByPddLimit(
        callHistoryId: number,
    ): Promise<boolean | never> {
        const dataForSend = {
            call_history_id: callHistoryId,
        };

        const response = await this.apiBase.post<ICommunicationQualitySaveResponse>(
            getEnvCreateCommunicationQualityResultEndpoint(),
            dataForSend,
        );

        const { message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }
}


export default CommunicationQualityApiService;
