import { toFactory } from 'react-ioc';

import GeoNegociosMapService from '@services/GeoNegociosMapService';
import { Store } from '@store/store';
import GeoNegiosMapApiService from '@api/geo-negios-map-api-service';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';
import SnackbarService from '@core/services/SnackbarService';


const GeoNegociosMapServiceDI = (): any => [
    GeoNegociosMapService,
    toFactory(
        [
            Store,
            GeoNegiosMapApiService,
            ModalService,
            I18NService,
            SnackbarService,
        ],
        (
            store: Store,
            geoNegiosMapApiService: GeoNegiosMapApiService,
            modalService: ModalService,
            i18NService: I18NService,
            snackbarService: SnackbarService,
        ) => new GeoNegociosMapService(
            store,
            geoNegiosMapApiService,
            modalService,
            i18NService,
            snackbarService,
        ),
    ),
];


export default GeoNegociosMapServiceDI;
