import { action } from 'mobx';
import { applySnapshot } from 'mobx-state-tree';
import get from 'lodash/get';

import FileStorageService from '@api/file-storage-api-service';
import UserApiService, { IAuxStatusData } from '@api/user-api-service';
import {
    IAuthData, IButtonUi,
    ICallCenter, ISip, IStatusInfo, IUserLocalStorage, IUserResponse,
} from '@api/auth-api-service/models';
import { DEFAULT_USER } from '@core/constants/defaultStoreItems';
import { getMediaProfilePath } from '@/env/mediaPaths';
import { Store } from '@store/store';
import CookieService from '@core/services/CookiesService';
import { ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';
import { ISipModelSnapshotIn } from '@models/mobx-state-tree/sip.model';
import { ICallCenterModelSnapshotIn } from '@models/mobx-state-tree/callCenter.model';
import { IStatusInfoModelSnapshotIn } from '@models/mobx-state-tree/statusInfo.model';
import { CustomerModeStore } from '@store/customerModeStore';
import { IUserModelSnapshotIn, UserModeEnum } from '@models/mobx-state-tree/user.model';
import I18NService from '@services/I18NService';
import { ConfirmModalAction, ConfirmModalType } from '@core/models/ModalWindow';
import XHR400Error from '@core/error/XHR400Error';
import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import ModalService from '@core/services/ModalService';
import AxiosRequestCancelledError from '@core/error/NoAccessTokenInRequestHeaderError';
import { customI18NTFunction } from '@services/I18NService';
import LocalStorageService from '@core/services/LocalStorageService';
import NoSipHostDetectedError from '@core/error/NoSipHostDetectedError';
import UserGreetingService from '@services/UserGreetingService';
import LogRocketService from '@services/Logging/LogRocket';
import SentryService from '@services/Logging/Sentry';
import { ILanguageModelSnapshotIn } from '@models/mobx-state-tree/language.model';
import { IAuxStatusesModel, IAuxStatusesModelSnapshotIn } from '@models/mobx-state-tree/auxStatuses.model';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { enableFetchAuxStatuses } from '@core/constants/auxStatuses';
import { PARAMS_FOR_AUX_EVENTS } from '@core/constants/local-storage';
import TimezoneService from '@services/TimezoneService';

enum UserEventActionEnum {
    START = 'start',
    STOP = 'stop',
}

class UserService {

    public get isAuthCookieValid(): boolean {
        return this.cookieService.isAuthCookieValid;
    }

    constructor(
        private readonly store: Store,
        private readonly userApiService: UserApiService,
        private readonly fileStorageService: FileStorageService,
        private readonly cookieService: CookieService,
        private readonly I18NService: I18NService,
        private readonly customerModeStore: CustomerModeStore,
        private readonly _internetSpeedMetricService: InternetSpeedMetricService,
        private readonly _modalService: ModalService,
        public readonly userGreetingService: UserGreetingService,
        private readonly _localStorageService: LocalStorageService,
        private logrocketService: LogRocketService,
        private sentryService: SentryService,
        private timezoneService: TimezoneService,
    ) {

    }

    public clearCallibriTempraryData(): void {
        this._localStorageService.removeCallibriStorageItems();
    }

    public identifyUserInLogRocket(userId: number, firstName: string, lastName: string, username: string) {
        this.logrocketService.identifyUser(userId, firstName, lastName, username);
    }

    public get t(): customI18NTFunction {
        return this.I18NService.t;
    }

    private _parseSip = (rawSip: string): ISip | null | never => {
        if (!rawSip) return null;

        let result: ISip | null = null;

        try {
            result = JSON.parse(rawSip);
        } catch (e) {
            throw new Error('Cant parse sip from JSON');
        }

        return result;
    };

    private _getButtonUi = (buttonUi: IButtonUi[]): string => {
        return Object.values(buttonUi[0])[0] as string;
    };

    private async _checkInternetSpeedForWork(): Promise<boolean> {
        const {
            setIsReady,
            needSpeedTest,
            validSpeedTest,
            sipPlatinum: { sipHost },
        } = this.store.currentUser;

        if (needSpeedTest && !sipHost) {
            throw new NoSipHostDetectedError(
                'Не обнаружен сип-хост для измерения скорости интернета-соединения.',
            );
        }

        if (!needSpeedTest && validSpeedTest === false) {
            return false;
        }

        if (sipHost && needSpeedTest && !validSpeedTest) {
            try {
                const { message, test } = await this.userApiService.speedTest(
                    await this._internetSpeedMetricService.toMeasureSpeedWithLibreSpeed(sipHost),
                );

                await this._modalService.showConfirmModal(
                    message,
                    ConfirmModalType.Yes,
                    10000,
                    ConfirmModalAction.Yes,
                );

                setIsReady(test);

                this._internetSpeedMetricService.setInternetSpeedProblems(test ? 'no' : 'yes');

                return test;
            } catch (error) {
                this._internetSpeedMetricService.setInternetSpeedProblems('yes');

                if (error instanceof XHR400Error) {
                    await this._modalService.showConfirmModal(
                        error.message,
                        ConfirmModalType.Yes,
                        10000,
                        ConfirmModalAction.Yes,
                    );
                }

                // eslint-disable-next-line no-console
                console.error(error);

                return false;
            }
        }

        return true;
    }

    public async userRequest(): Promise<IAuthData | null> {
        try {
            return await this.userApiService.getUser();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);

            return null;
        }
    }

    private _prepareAuxResult(data: IAuxStatusData[]): Record<string, IAuxStatusesModelSnapshotIn> {
        return convertArrayToCollection<IAuxStatusesModelSnapshotIn>(
            data.map((status: IAuxStatusData) => ({
                id: status.id,
                name: status.name,
                orderGet: status.order_get,
                icon: status.icon,
                limit: status.limit,
                description: status.description,
                notLimited: status.not_limited,
            })));
    }

    public async fetchAuxStatuses(): Promise<void> {
        try {
            const data = await this.userApiService.getAux();

            if (data && data?.length) {
                const statuses = this._prepareAuxResult(data);
                applySnapshot(this.store.currentUser.auxStatusesData, statuses);
            }

        } catch (error) {
            console.error(error);
        }
    }

    public async changeAuxStatus(status: IAuxStatusesModel): Promise<void> {
        this.store.inactivityTimer.clearAuxStatusTimers();
        await this.fetchAuxStatuses();
        if (status.limit > 0) {
            this.store.inactivityTimer.startAuxStatusTimer(status.limit);
        }
        await this.sendEventStart(status.name);
    }

    public mapUserLoginApiResult = (rawUser: IUserResponse, rawCallCenter: ICallCenter | null, rawPermissions: string[] = []): IUserLocalStorage => ({
        id: get(rawUser, 'id', 0),
        username: get(rawUser, 'username', ''),
        timezone_id: get(rawUser, 'timezone_id', 0),
        status: get(rawUser, 'status', 0),
        first_name: get(rawUser, 'first_name', ''),
        last_name: get(rawUser, 'last_name', ''),
        phone: get(rawUser, 'phone', ''),
        team_id: get(rawUser, 'team_id', 0),
        auto_call: get(rawUser, 'auto_call', false),
        button_ui: get(rawUser, 'button_ui'),
        is_verificator: get(rawUser, 'is_verificator'),
        webrtc: get(rawUser, 'webrtc', false),
        incoming_call: get(rawUser, 'incoming_call', false),
        last_activity: get(rawUser, 'last_activity', 0),
        ready_to_call: get(rawUser, 'ready_to_call', false),
        filename: get(rawUser, 'filename', ''),
        token: get(rawUser, 'token', ''),
        expired: get(rawUser, 'expired', 0),
        sip: this._parseSip(get(rawUser, 'sip') as string) || '',
        sip_platinum: this._parseSip(get(rawUser, 'sip_platinum') as string) || '',
        sip_predictive: this._parseSip(get(rawUser, 'sip_predictive') as string) || '',
        sip_auto_call: this._parseSip(get(rawUser, 'sip_auto_call') as string) || '',
        call_center: rawCallCenter,
        statusInfo: get(rawUser, 'statusInfo', []),
        is_ready: get(rawUser, 'is_ready', true),
        permissions: rawPermissions,
        predictive_autocall: get(rawUser, 'predictive_autocall', false),
        predictive_first_start: get(rawUser, 'predictive_first_start', false),
        predictive_pause: get(rawUser, 'predictive_pause', false),
        customer_service: get(rawUser, 'customer_service', false),
        language: get(rawUser, 'language', null),
        need_speed_test: get(rawUser, 'need_speed_test', false),
        valid_speed_test: get(rawUser, 'valid_speed_test', null),
        logrocket: get(rawUser, 'logrocket', false),
    });

    @action
    fetchUser = async (): Promise<void | never> => {
        const data = await this.userRequest();

        if (data) {
            const rawUser: IUserResponse = get(data, 'user');
            const rawPermissions: string[] = get(data, 'permissions', []);
            const rawCallCenter: ICallCenter | null = data.call_center;

            const mappedUser = this.mapUserLoginApiResult(rawUser, rawCallCenter, rawPermissions);
            if (mappedUser.id) {
                if (mappedUser.logrocket) {
                    this.logrocketService.init(mappedUser.is_verificator);
                    this.sentryService.linkLogRocketSessionIntoSentry();
                    this.logrocketService.identifyUser(
                        mappedUser.id,
                        mappedUser.first_name,
                        mappedUser.last_name,
                        mappedUser.username,
                    );
                }

                if (data.call_center?.customer_service_server) {
                    this.customerModeStore.store.setSipHost(data.call_center.customer_service_server.url);
                }

                if (this.store.hasNotificationWithFeedBack || mappedUser.need_speed_test) {
                    // Если у пользователя есть нотификации без ответа, или стоит метка для замера скорости интернета,
                    // то он не должен быть в готов.
                    mappedUser.is_ready = false;
                }

                this.snapshotUser(mappedUser);
            }
        }
    };

    @action
    unsetUser = (): void => {
        applySnapshot(this.store.currentUser, DEFAULT_USER);
    };

    @action
    initUser = async () => {
        if (this.isAuthCookieValid && this.store.currentUser.id === DEFAULT_USER.id) {
            await this.fetchUser();

            let res = true;
            try {
                res = await this._checkInternetSpeedForWork();
            } catch (error) {
                if (error instanceof NoSipHostDetectedError) {
                    // eslint-disable-next-line no-console
                    console.error(error.message);
                }
            }

            if (!res) {
                this.signOut();

                return;
            }

            // получаем список языков для переводов с бэка
            await this.I18NService.fetchLanguageList();

            if (enableFetchAuxStatuses) {
                await this.fetchAuxStatuses();
            }

            // если у пользователя с бэка пришел установленный язык, то устаналвиваем его в интерфейсе
            const { language: userLanguage, id: userId } = this.store.currentUser;

            if (userLanguage && this.I18NService.getLanguageList.length > 0) {
                const langItem = this.I18NService.getLanguageList.find((x) => x.id === userLanguage.id);
                if (langItem) {
                    void this.I18NService.changeLanguage(langItem, userLanguage);
                }
            }

            /**
             * если задетектили язык через модуль переводов,
             *    а у юзера язык не установлен (this.store.currentUser.language is null),
             *    то ищем этот язык в списке языков по locale,
             *    если нашелся, то выставляем этот язык в интерфейс,
             *    на бэк отправляем информацию
             */
            const detectedLocale = localStorage.getItem('i18nextLng');

            if (!userLanguage && detectedLocale && this.I18NService.getLanguageList.length > 0) {
                const langItem = this.I18NService.getLanguageList.find((x) => x.locale.toLocaleLowerCase === detectedLocale.toLocaleLowerCase);
                if (langItem) {
                    void this.I18NService.changeLanguage(langItem, null);
                }
            }

            if (userId !== DEFAULT_USER.id) {
                this.userGreetingService.proceedUserMetadataToLocalStorage(String(userId));
            }

            await this.timezoneService.sendTimezoneId(userId); // сопоставляем таймзону из браузера с полученным списком
            // таймзон и с влучае вопалдения отправялем на бек

        } else {
            this.unsetUser();
        }
    };

    @action
    snapshotUser = (user: IUserLocalStorage) => {
        const sip = user.sip as ISip;
        const sipPlatinum = user.sip_platinum as ISip;
        const sipPredictive = user.sip_predictive as ISip;
        const sipAutoCall = user.sip_auto_call as ISip;

        let sipModel: ISipModelSnapshotIn | undefined;
        let sipPlatinumModel: ISipModelSnapshotIn | undefined;
        let sipAutoCallModel: ISipModelSnapshotIn | undefined;
        // let sipPredictiveModel: ISipModelSnapshotIn | undefined;
        let callCenterModel: ICallCenterModelSnapshotIn | undefined;
        let statusInfoModel: IStatusInfoModelSnapshotIn[] | undefined;

        if (sip) {
            sipModel = {
                sipHost: sip.sip_host,
                sipLogin: sip.sip_login,
                sipPass: sip.sip_pass,
            };
        }

        if (sipPlatinum) {
            sipPlatinumModel = {
                sipHost: sipPlatinum.sip_host,
                sipLogin: sipPlatinum.sip_login,
                sipPass: sipPlatinum.sip_pass,
            };
        }

        if (sipPredictive) {
            // sipPredictiveModel = {
            //     sipHost: sipPredictive.sip_host,
            //     sipLogin: sipPredictive.sip_login,
            //     sipPass: sipPredictive.sip_pass,
            // }
        }

        if (sipAutoCall) {
            sipAutoCallModel = {
                sipHost: sipAutoCall.sip_host,
                sipLogin: sipAutoCall.sip_login,
                sipPass: sipAutoCall.sip_pass,
            };
        }

        if (user.call_center) {
            let callCenterCountryModel: ICountryModelSnapshotIn | undefined;

            if (user.call_center.country) {
                let callCenterCountryLanguageModel: ILanguageModelSnapshotIn | undefined;

                if (user.call_center.country.language) {
                    callCenterCountryLanguageModel = <ILanguageModelSnapshotIn> {
                        id: user.call_center.country.language.id,
                        name: user.call_center.country.language.name,
                        locale: user.call_center.country.language.locale,
                        icon: user.call_center.country.language.icon,
                    };
                }

                callCenterCountryModel = <ICountryModelSnapshotIn> {
                    id: String(user.call_center.country.id),
                    charCode: user.call_center.country.char_code,
                    name: user.call_center.country.name,
                    language: callCenterCountryLanguageModel,
                };
            }

            callCenterModel = {
                id: String(user.call_center.id),
                description: user.call_center.description,
                country: callCenterCountryModel,
                name: user.call_center.name,
                checkBrowser: user.call_center.check_browser,
                communicationServerId: user.call_center.communication_server_id,
            };
        }

        if (user.statusInfo) {
            statusInfoModel = user.statusInfo
                .map((x: IStatusInfo): IStatusInfoModelSnapshotIn => ({
                    id: x.id,
                    name: x.name,
                }));
        }

        let profileImageFile = null;
        if (user.filename) {
            profileImageFile = `${getMediaProfilePath()}${user.filename}`;
        }

        const currentUser: IUserModelSnapshotIn = {
            id: user.id,
            username: user.username,
            timezoneId: user.timezone_id,
            timezoneName: null,
            status: user.status,
            firstName: user.first_name,
            lastName: user.last_name,
            phone: user.phone,
            buttonUi: this._getButtonUi(user.button_ui),
            isVerificator: user.is_verificator,
            isReady: user.is_ready,
            teamId: user.team_id,
            autoCall: user.auto_call || null,
            webrtc: user.webrtc,
            incomingCall: user.incoming_call,
            lastActivity: user.last_activity,
            readyToCall: user.ready_to_call,
            filename: user.filename,
            // sipPredictive: sipPredictiveModel,
            sipPlatinum: sipPlatinumModel,
            sipAutoCall: sipAutoCallModel,
            sip: sipModel,
            callCenter: callCenterModel,
            statusInfo: statusInfoModel,
            profileImageFile,
            permissions: user.permissions,
            predictiveAutocall: user.predictive_autocall,
            // predictiveFirstStart: user.predictive_first_start,
            // predictivePause: user.predictive_pause,
            customerService: user.customer_service,
            language: user.language,
            needSpeedTest: user.need_speed_test,
            validSpeedTest: user.valid_speed_test,
        };

        applySnapshot(this.store.currentUser, currentUser);
        if (user.is_ready) {
            this.store.setShouldGoToReadyIfNoNotificationsWithFeedback(true);
        }
    };

    public getTimezoneName = async (): Promise<string> => {
        const { timezoneId, timezoneName } = this.store.currentUser;
        let name = 'UTC';

        if (timezoneName) {
            return timezoneName;
        }

        if (timezoneId) {
            const data = await this.userApiService.getTimezone(timezoneId);
            const timezone = data[0];

            if (data) {
                const currentName = get(timezone, 'timezone_id', 'UTC');
                if (currentName) {
                    name = currentName;
                }
            }
        }

        this.store.currentUser.setTimezoneName(name);

        return name;
    };

    @action
    public setSipHost(val: string): void {
        const {
            currentUser: { mode, setSipHost },
        } = this.store;

        const { store: { setSipHost: customerModeSetSipHost } } = this.customerModeStore;

        if (mode === UserModeEnum.CLIENT_SERVICE) {
            customerModeSetSipHost(val);

            return;
        }

        setSipHost(val);
    }

    public startUserActivityPolling = async (): Promise<void> => {

        const countryId = this.store?.currentOrder?.countryId;

        const queueId = this.store?.currentOrder?.lastQueue?.id;

        try {
            if (this.store.currentUser.isReady) {
                const timeSuccessfullyTracked = await this.userApiService.setTimeActivity(queueId, countryId);

                if (!timeSuccessfullyTracked) {
                    this.store.currentUser.setIsReady(false);
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.store.currentUser.setIsReady(false);
        }
    };

    public sendEventStart = async (eventName:  string): Promise<void> => {

        const previousOrder = this._localStorageService.getItem(PARAMS_FOR_AUX_EVENTS);

        const dataToSend = {
            action: UserEventActionEnum.START,
            event: eventName,
            order_id: previousOrder?.orderId || null,
            country_id: previousOrder?.countryId || null,
            last_queue_id: previousOrder?.lastQueueId ||  null,
            global_attempt: previousOrder?.globalAttempts || null,
            attempt: previousOrder?.attempts || null,
        };

        try {
            await this.userApiService.sendUserEvent(dataToSend);
        } catch (e) {
            if (e instanceof AxiosRequestCancelledError) {
                return;
            }

            // eslint-disable-next-line no-console
            console.warn(e);
        }
    };

    public sendEventStop = async (eventName: string | undefined = undefined): Promise<void> => {

        const {
            id, countryId, lastQueue, globalAttempts, sessionCallCount,
        } = this.store.currentOrder;

        const currentGlobalAttempts = globalAttempts + sessionCallCount;

        const dataToSend = {
            action: UserEventActionEnum.STOP,
            event: eventName,
            order_id: id || null,
            country_id: countryId || null,
            last_queue_id: lastQueue?.id || null,
            global_attempt: currentGlobalAttempts || null,
            attempt: sessionCallCount || null,
        };

        try {
            await this.userApiService.sendUserEvent(dataToSend);
        } catch (e) {
            if (e instanceof AxiosRequestCancelledError) {
                return;
            }

            // eslint-disable-next-line no-console
            console.warn(e);
        }
    };

    public signOut = (): void => {
        this.store.currentUser.setIsReady(false);
        this._localStorageService.removeItem(PARAMS_FOR_AUX_EVENTS);
        const timer = setInterval(() => {
            if (!this.store.currentUser.isReady) {
                this.cookieService.logout();
                clearInterval(timer);
            }
        }, 100);

    };
}


export default UserService;
