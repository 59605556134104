import React, { useMemo } from 'react';
import map from 'lodash/map';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import FAQ from '@components/main/customer-service-page-stage-2/FAQ';
import { CustomerModeStore } from '@store/customerModeStore';
import I18NService from '@services/I18NService';
import { IItem } from '@components/main/customer-service-page-stage-2/FAQ/FAQ';
import DivWithDangerouslySetInnerHTML from '@UIElements/DivWithDangerouslySetInnerHTML';


const FAQHOC = () => {
    const [customerModeStore, { t }] = useInstances(CustomerModeStore, I18NService);

    const pending = customerModeStore.faqPending;
    const articles = customerModeStore.faqArticlesValues;

    const title = t('Часто задаваемые вопросы', 'FAQ');
    const noResultMessage = t('Нет статей', 'No articles');

    const items: IItem[] = useMemo(() => (
        map(articles, (article) => ({
            ...article,
            payload: <DivWithDangerouslySetInnerHTML html={article.payload} />,
        }))), [articles]);

    if (pending) {
        return (
            <FAQ
                noResultMessage={noResultMessage}
                title={title}
                pending={true}
                items={articles}
            />
        );
    }

    return (
        <FAQ
            noResultMessage={noResultMessage}
            title={title}
            items={items}
        />
    );
};

export default observer(FAQHOC);
