import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';


export const CustomerServiceFAQArticleStage2 = t
    .model('CustomerServiceFAQArticleStage2', {
        id: t.identifier,
        title: t.string,
        payload: t.string,
    });


export interface ICustomerServiceFAQArticleStage2Model extends Instance<typeof CustomerServiceFAQArticleStage2> {}
export interface ICustomerServiceFAQArticleStage2SnapshotIn extends SnapshotIn<typeof CustomerServiceFAQArticleStage2> {}
export interface ICustomerServiceFAQArticleStage2SnapshotOut extends SnapshotOut<typeof CustomerServiceFAQArticleStage2> {}
