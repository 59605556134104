import { customI18NTFunction } from '@services/I18NService';
import ErrorBase from '@core/error/ErrorBase';


class DevicesNotAvailableError extends ErrorBase {
    constructor(t: customI18NTFunction) {
        const messageForTranslate = {
            key: 'Устройства не доступны.',
            defaultValue: 'Devices not available',
        };

        super(messageForTranslate, t);
    }
}


export default DevicesNotAvailableError;
