import get from 'lodash/get';
import ApiBase from '@core/api';
import { IPartnerCountry, IPartnerProduct } from '@api/partner-api-service/models';
import { getEnvGetUserPartnersListEndpoint, getEnvPartnerCountryList, getEnvPartnerProductListEndpoint } from '@/env/requestEndpoints';
import { IPartnerModelSnapshotIn } from '@models/mobx-state-tree/partner.model';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class PartnerApiService {
    @Inject apiBase: ApiBase;

    async getPartnerProductList(
        countryId: number,
        partnerId: number,
        orderId?: number,
        isProductSet?: number, // 0 - только обычные товары, 1 - только наборы товаров.
    ): Promise<IPartnerProduct[] | never> {
        const dataForSend = {
            country_id: countryId,
            partner_id: partnerId,
            order_id: orderId,
            is_product_set: isProductSet,
        };

        const response = await this.apiBase.get<IWithResponse<IPartnerProduct[]>>(
            getEnvPartnerProductListEndpoint(),
            dataForSend,
        );

        const { data, message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getPartnerList(): Promise<IPartnerModelSnapshotIn[]> {
        const response = await this.apiBase.get<IWithResponse<IPartnerModelSnapshotIn[]>>(getEnvGetUserPartnersListEndpoint());

        const { data, message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getParnterCountryList(partnerId: number): Promise<IPartnerCountry[]> {
        const ID = { partner_id: partnerId };
        const response = await this.apiBase.get<IWithResponse<IPartnerCountry[]>>(getEnvPartnerCountryList(), ID);

        const { data, message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
    }
}


export default PartnerApiService;
