import {
    useEffect, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { IPromotionBuilderModel } from '@models/mobx-state-tree/newModels/PromotionBuilder.models';
import { IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import map from 'lodash/map';
import I18NService from '@services/I18NService';
import PromotionBuilderService from '@services/order/PromotionBuilderService';
import { IPartOfCatalogItemForPromotionBuilderInCatalog, IPromotionBuilderDataForComponent, ITranslationsForPromotionBuilder } from './models';
import { ICurtainHeader } from '../PromotionBuilderToAdd';

const INITITAL_PROMOTION_BUILDER: IPromotionBuilderDataForComponent = {
    promotionBuilderName: '',
    mainProductImage: '',
    mainProductType: ProductItemTypeEnum.REGULAR,
    promotionBuilderId: 0,
    items: [],
};

const INITIAL_CURTAIN_HEADER: ICurtainHeader = {
    itemColLabel: 'Товар',
    amountColLabel: 'Количество',
    priceColLabel: 'Цена',
};

const INITITAL_BUTTON_TRANSLATIONS: ITranslationsForPromotionBuilder = {
    label: 'Акция',
    button_can_be_added: 'Применить',
    button_yet_added: 'Применено',
    button_there_is_no_possibility: 'Недоступна',
    fromAmountPhrase: 'от',
    upToAmountPhrase: 'до',
};

/**
 * Хук по управлению конструктором акций
 * @param item - модель конструктора акций
 */
export const useManageCatalogPromotionBuilder = (
    item: IPromotionBuilderModel,
) => {
    const [
        { t },
        {
            applyPromotionBuilder,
            getTextAboutAmountOfGoodsThatAreMissing,
            getAmountOfGoodsThatAreMissing,
        },
    ] = useInstances(
        I18NService,
        PromotionBuilderService,
    );

    const [preparedCatalogItemForView, setPreparedCatalogItemForView] = useState<IPromotionBuilderDataForComponent>(INITITAL_PROMOTION_BUILDER);
    const [freeLabelTranslated, setFreeLabelTranslated] = useState<string>('Бесплатно');
    const [curtainHeaderTranslations, setCurtainHeaderTranslations] = useState<ICurtainHeader>(INITIAL_CURTAIN_HEADER);
    const [buttonTranslations, setButtonTranslations] = useState<ITranslationsForPromotionBuilder>(INITITAL_BUTTON_TRANSLATIONS);

    useEffect(() => {
        setFreeLabelTranslated(
            t('Бесплатно', 'Free'),
        );
        setCurtainHeaderTranslations(
            {
                itemColLabel: t('Товар', 'Item'),
                amountColLabel: t('Количество', 'Quantity'),
                priceColLabel: t('Цена', 'Price'),
            },
        );
        setButtonTranslations(
            {
                label: t('Акция', 'Promotion'),
                button_can_be_added: t('Применить', ''), // TODO: здесь нужен перевод
                button_yet_added: t('Применено', ''), // TODO: здесь нужен перевод
                button_there_is_no_possibility: t('Недоступна', ''), // TODO: здесь нужен перевод
                fromAmountPhrase: t('от', 'from'),
                upToAmountPhrase: t('до', 'up to'),
            },
        );
    }, [t]);

    const preparePromotionBuilderForAView = (item: IPromotionBuilderModel): IPromotionBuilderDataForComponent => {
        const mainItem: IProductItemModel = item.products.get(item.mainProductModelId);

        return ({
            promotionBuilderName: item.name,
            mainProductImage: mainItem.image,
            mainProductType: mainItem.type,
            promotionBuilderId: item.promotionBuilderId,
            items: map<IProductItemModel, IPartOfCatalogItemForPromotionBuilderInCatalog>(
                [...item.products.values()],
                (promotionBuilderItem: IProductItemModel) => {
                    const isMain = promotionBuilderItem.type === ProductItemTypeEnum.REGULAR
                        && promotionBuilderItem.subType === ProductItemSubTypeEnum.MAIN;

                    /**
                     * Количество товара, для показа:
                     * 1. *IProductItemModel.amountToAdd* для MAIN товара (from = от этого количества и до лимита)
                     * 2. optionalGift = *IProductItemModel.maxAmount* (up to = от 0 до maxAmount)
                     * 3. defaultGift = *IProductItemModel.maxAmount* (fix = нельзя изментья количество)
                     * 4. promo = *IProductItemModel.maxAmount* (up to = от 0 до maxAmount)
                     */
                    let quantityPrepared: number | null = null;
                    if (isMain) {
                        quantityPrepared = promotionBuilderItem.amountToAdd;
                    } else {
                        quantityPrepared = promotionBuilderItem.maxAmount;
                    }

                    return ({
                        image: promotionBuilderItem.image,
                        type: promotionBuilderItem.type,
                        subType: promotionBuilderItem.subType,
                        discount: promotionBuilderItem.discountAmount,
                        quantity: quantityPrepared,
                        price: promotionBuilderItem.price,
                        name: promotionBuilderItem.name,
                        id: promotionBuilderItem.id,
                        mandatoryGift: promotionBuilderItem.mandatoryGift,
                        mainProductPrice: promotionBuilderItem.mainProductPrice,
                    });
                },
            ),
        });
    };
    /**
     * Подготовить нужные для отображения в компоненте атрибуты в соответствии с интерфейсом
     */
    useEffect(() => {
        setPreparedCatalogItemForView(
            preparePromotionBuilderForAView(item),
        );
    }, [item]);

    // замораживаем возвращаемые объекты (нельзя будет изменять вне хука)
    return [
        preparedCatalogItemForView,
        freeLabelTranslated,
        curtainHeaderTranslations,
        buttonTranslations,
        applyPromotionBuilder,
        getTextAboutAmountOfGoodsThatAreMissing,
        getAmountOfGoodsThatAreMissing,
    ] as const;
};
