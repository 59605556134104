import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { Locations } from '@core/models/locations';
import EmptyComponent from '@core/constants/EmptyComponent';
import { Store } from '@store/store';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';


interface ILinkRouteProps {
    textLink?: string;
    toRoute?: Locations | false;
    children?: JSX.Element | JSX.Element[] | false;
    classWrapper?: string;
    classText?: string;
    onClick?: (event: React.MouseEvent) => void;
    toURL?: string | false;
    inNewWindow?: boolean;
    iconSvg?: JSX.Element;
}


const SideElement: FunctionComponent<ILinkRouteProps> = ({
    toRoute = null,
    textLink,
    children,
    classWrapper = '',
    classText = '',
    onClick,
    toURL = null,
    iconSvg = <EmptyComponent />,
}): JSX.Element => {
    const [
        {
            ui: { bottomMobileTab: { setActiveTab } },
            currentUser: {
                isReady,
                mode,
                disableProgressiveMode,
            },
            currentOrder: {
                isEmptyCurrentOrder,
            },
            setCurrentTab,
            currentTab,
        },
    ] = useInstances(
        Store,
    );

    // TODO: Для каждой страницы свой экшог должен быть. а не общий как в orderPage.

    const excludedRoutes = toRoute === Locations.BILLING_LIST || toRoute === Locations.RECALLS ||
        toRoute === Locations.ORDER_CREATION || toRoute === Locations.FIND_ORDER;

    const disabledTab = excludedRoutes &&
        (isReady || !isEmptyCurrentOrder || mode === UserModeEnum.PROGRESSIVE || disableProgressiveMode);

    const sidePanelWrapperClasses = useMemo<string>((): string => cn(
        classWrapper || 'menu-item',
        disabledTab && 'disabledTab',
        currentTab === toRoute && 'activeTab',
    ), [classWrapper, currentTab, toRoute, isEmptyCurrentOrder, isReady]);

    const sidePanelTextClasses = useMemo<string>((): string => cn(
        'menu-item-text',
        classText,
    ), [classText]);

    // const toLocation = (): void => {
    //
    //     if (toURL && !inNewWindow) {
    //         window.location.href = toURL;
    //         return;
    //     }
    //
    //     if (toURL && inNewWindow) {
    //         window.open(toURL, '_blank');
    //         return;
    //     }
    //
    //     if (toRoute === Locations.LOGOUT) {
    //         userService.signOut();
    //         return;
    //     }
    //
    //     if (toRoute && inNewWindow) {
    //         window.open(toRoute, '_blank');
    //         return;
    //     }
    //     navigate(String(toRoute));
    // };

    const onClickCallBack = useCallback<(event: React.MouseEvent) => any | void>(
        (event: React.MouseEvent) => {

            const conditionDisabledButton = excludedRoutes &&
            (isReady || !isEmptyCurrentOrder || mode === UserModeEnum.PROGRESSIVE || disableProgressiveMode);

            if (conditionDisabledButton) {
                return;
            }
            // TODO: Для каждой страницы свой экшог должен быть.
            setActiveTab(BottomTabEnum.ORDER);
            // if (toRoute || toURL) {
            //     return toLocation();
            // }

            if (toRoute) {
                setCurrentTab(toRoute);
            }

            if (onClick) {
                return onClick(event);
            }

            return () => {
            };
        },
    [toRoute, toURL, onClick, setActiveTab, isEmptyCurrentOrder, isReady]);

    return (
        <div
            onClick={onClickCallBack}
            className={sidePanelWrapperClasses}
            title={textLink?.toUpperCase()}
        >
            <div className="link">
                {children}
            </div>
            <span className={sidePanelTextClasses}>
                {textLink}
            </span>
            {iconSvg}
        </div>
    );
};


export default observer(SideElement);
