import React, { FunctionComponent } from 'react';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import I18NService from '@services/I18NService';

import TooltipPortal from '@components/main/TooltipPortal/TooltipPortal';
import { OrderStatusesEnum } from '@api/order-api-service/models';
import { ITip } from './model';


interface ITipProps {
    tip: ITip;
}

const Tip: FunctionComponent<ITipProps> = ({
    tip: {
        startStatusName,
        endStatusName,
        endStatusId,
        updatedAt,
        username,
        userId,
        id,
    },
}): JSX.Element => {
    const { t } = useInstance(I18NService);

    const textOnBasedStatus: () => string = (): string => {
        let date = '?';
        if (updatedAt) {
            date = moment(updatedAt * 1000).tz('UTC').format('DD.MM.YYYY hh:mm');
        }

        if (endStatusId === OrderStatusesEnum.CRASH) {
            return t(
                '{{date}} Системе не удалось установить связь с клиентом.',
                '{{date}} System could not establish contact with customer',
                {
                    date,
                },
            );
        }
        if (endStatusName) {
            return t(
                '{{date}} Статус был изменен на {{orderStatusName}} оператором {{operatorName}} ID{{operatorId}}.',
                '{{date}} The status was changed to {{orderStatusName}} by operator {{operatorName}} ID{{operatorId}}',
                {
                    operatorName: username,
                    operatorId: userId,
                    date,
                    orderStatusName: t(endStatusName),
                },
            );
        }

        return t(
            '{{date}} Оператор {{operatorName}} ID{{operatorId}} принял заказ со статусом {{orderStatusName}}, совершил звонок, но не сменил статус заказа.',
            '{{date}} The operator {{operatorName}} ID {{operatorId}} accepted the order with the status {{orderStatusName}}, made a call, but did not change the order status.',
            {
                operatorName: username,
                operatorId: userId,
                date,
                orderStatusName: startStatusName ? t(startStatusName) : '?',
            },
        );
    };

    return (
        <TooltipPortal>
            <ReactTooltip id={`callHistory__${id}`}>
                <div className="history-tip">{textOnBasedStatus()}</div>
            </ReactTooltip>
        </TooltipPortal>
    );
};

export default observer(Tip);
