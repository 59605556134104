import get from 'lodash/get';
import { Inject } from 'react-ioc';

import { getEnvGetCallHistoryEndpoint } from '@/env/requestEndpoints';
import ApiBase from '@core/api';
import { ICallHistory } from './model';


interface IWithResponse<T> {
    status: 'success' | 'error';
    message?: string;
    data: T;
}


class CallApiService {
    @Inject apiBase: ApiBase;

    async getCallHistory(orderId: number): Promise<ICallHistory[]> {
        const dataForSend = {
            order_id: orderId,
            sort: 'desc',
        };

        const response = await this.apiBase.get<IWithResponse<ICallHistory[]>>(getEnvGetCallHistoryEndpoint(), dataForSend);

        const { data = [], message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as ICallHistory[];
    }
}


export default CallApiService;
