import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class DidNotFindDynamicSetMappingWithPartnerProductError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
        public problemSetId: number,
    ) {
        super();

        this.message = 'В v2/partner-product/list нарушена целостность данных. Не нашли сопоставления динамического набора с партнерским товаром.';
    }
}


export default DidNotFindDynamicSetMappingWithPartnerProductError;
