import {
    action,
    computed, IReactionDisposer, reaction,
} from 'mobx';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { SipService } from '@services/index';
import { Store } from '@store/store';
import { ICallAutomatizationModel } from '@models/mobx-state-tree/callAutomatization.model';
import { EnumSipStatus } from '../sip/models';

/**
 * ТЗ: https://2wtrade-tasks.atlassian.net/wiki/spaces/BREQ/pages/1229324513/CRM+NewOPER
 */
class CallAutomatizationHandler {
    private _pollingCallAutomatizationTimeout: number | null = null;

    constructor(
        private readonly _sipService: SipService,
        private readonly store: Store,
    ) {
    }

    private _disposeOnReadyToProceedCallAutomatization: IReactionDisposer | undefined;

    private _disposeOnSipStatusChanged: IReactionDisposer | undefined;

    private _disposeOnEndCall: IReactionDisposer | undefined;

    private _disposeOnEndCallRegularMode: IReactionDisposer | undefined;

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get _phoneNumberForACallAutomatization(): string | null {
        return this._currentOrder.phoneNumberForACallAutomatization();
    }

    private get _callAutomatizationModel(): ICallAutomatizationModel {
        return this.store.currentOrder.callAutomatization;
    }

    /**
     * Количество совершенных звонков.
     * Этот счетчик инкрементится только после завершения звонка
     */
    private get _numberOfCallsMade(): number {
        return this._callAutomatizationModel._numberOfCallsMade;
    }

    /**
     * Количество совершенных звонков для UI.
     * Этот счетчик инкрементится в момент совершения звонка
     */
    private get _numberOfCallsMadeForCounterInUI(): number {
        return this._callAutomatizationModel.numberOfCallsMadeForCounterInUI;
    }

    private get _startCallAutomatic(): boolean {
        return this._currentOrder.startCallAutomatic;
    }

    private get _automaticCallsAmount(): number {
        return this._callAutomatizationModel.automaticCallsAmount;
    }

    private get _automaticCallsInterval(): number {
        return this._currentOrder.country.automaticCallsInterval;
    }

    private get _phoneNumberNotFound(): boolean {
        return this._callAutomatizationModel.phoneNumberNotFound;
    }

    /**
     * Завершен ли процесс автонабора
     */
    @computed
    public get callAutomatizationFinished(): boolean {

        if (this.store.currentOrder.callsCount.limitCalls === 0) {
            return true;
        }

        return this._callAutomatizationModel.callAutomatizationFinished;
    }

    /**
     * Можно ли выполнять автоматизацию статуса заказа noAnswerAfterSec
     * [Назначить заказу статус NO_ANSWER, если клиент не отвечает в течение N секунд]
     * Эту автоматизацию можно выполнить только если это последняя попытка звонка
     */
    @computed
    public get isThisCallLastInCallAutomatization(): boolean {
        return this._numberOfCallsMadeForCounterInUI === this._automaticCallsAmount;
    }

    /**
     * Состояние автонабора для counter в UI
     * @returns Пара значений: количество совершенных звонков / количество доступных звонков
     */
    @computed
    public get callAutomatizationState(): readonly [number, number] {
        return [this._numberOfCallsMadeForCounterInUI, this._automaticCallsAmount] as const;
    }

    public unsubscribe = (): void => {
        if (this._disposeOnReadyToProceedCallAutomatization) {
            this._disposeOnReadyToProceedCallAutomatization();
        }

        if (this._disposeOnSipStatusChanged) {
            this._disposeOnSipStatusChanged();
        }

        if (this._disposeOnEndCall) {
            this._disposeOnEndCall();
        }

        if (this._disposeOnEndCallRegularMode) {
            this._disposeOnEndCallRegularMode();
        }

        this.stopPolling();
    };

    public subscribe = (): void => {
        this.unsubscribe();

        this._disposeOnReadyToProceedCallAutomatization = reaction(
            () => Boolean(this._currentUser.mode === UserModeEnum.REGULAR // находимся в обычном режиме
                && this._currentUser.isReady // готовы
                && this._startCallAutomatic // автонабор включен
                && this._sipService.getSipStatus() === EnumSipStatus.REGISTERED // SIP готов звонить
                && this._numberOfCallsMade === 0 // мы еще не звонили
                && !this.callAutomatizationFinished), // автонабор не закончил свою работу
            (readyToAutomateCalls) => {
                if (readyToAutomateCalls) {
                    // но нам также нужен номер телефона
                    if (this._phoneNumberForACallAutomatization) {
                        this._callAutomatizationModel.setPhoneNumberNotFound(false);
                        // вызываем функционал автонабора только 1 раз, далее функция будет вызывать сама себя
                        void this.proceedCallAutomatization(this._phoneNumberForACallAutomatization, this._currentOrder.id);
                    }

                    if (!this._phoneNumberForACallAutomatization) {
                        this._callAutomatizationModel.setPhoneNumberNotFound(true);
                        // Если нет номера телефона, то не включаем автонабор, пишем об этом в консоль
                        // eslint-disable-next-line no-console
                        console.warn('Call automatization turned off. There is no phone number to call.');
                    }
                }
            },
        );

        this._disposeOnSipStatusChanged = reaction(
            () => this._sipService.getSipStatus() === EnumSipStatus.LIVE // если дозвонились
            && this._startCallAutomatic // если нужно делать автонабор
            && !this.callAutomatizationFinished, // и функционал автонабора еще не завершен
            (isLive) => {
                if (isLive) {
                    // ставим флаг, что ответили на звонок
                    this._callAutomatizationModel.setIsCustomerAnswered(true);
                }
            },
        );

        this._disposeOnEndCall = reaction(
            () => (this._sipService.getSipStatus() === EnumSipStatus.ENDED
            || this._sipService.getSipStatus() === EnumSipStatus.FAIL
            || this._sipService.getSipStatus() === EnumSipStatus.REGISTRATION_FAIL)
            && this._startCallAutomatic // если нужно делать автонабор
            && !this.callAutomatizationFinished, // и функционал автонабора еще не завершен
            (isCallEnd) => {
                if (isCallEnd) {
                    // значит закончили разговор
                    this._callAutomatizationModel.incrementNumberOfCallsMade();
                }
            },
        );

        this._disposeOnEndCallRegularMode = reaction(
            () => (this._sipService.getSipStatus() === EnumSipStatus.ENDED
                    || this._sipService.getSipStatus() === EnumSipStatus.FAIL
                    || this._sipService.getSipStatus() === EnumSipStatus.REGISTRATION_FAIL)
                && this._currentUser.mode === UserModeEnum.REGULAR
                && this._currentOrder.callsCount.limitCalls,
            (isCallEnd) => {
                if (isCallEnd) {
                    // значит закончили разговор
                    this._currentOrder.callsCount.incrementNumberOfCallAttempts();
                }
            },
        );
    };

    private pollingTimeout = (): Promise<void> => new Promise(((resolve): void => {
        this._pollingCallAutomatizationTimeout = setTimeout(resolve, 800);
    }));

    private stopPolling = (): void => {
        if (this._pollingCallAutomatizationTimeout) {
            clearTimeout(this._pollingCallAutomatizationTimeout);
        }
    };

    /**
     * Задержка между попытками
     */
    private delayBetweenAttempts = (): Promise<void> => new Promise((resolve) => {
        setTimeout(resolve, this._automaticCallsInterval * 1000);
    });

    @action
    private proceedCallAutomatization = async (phone: string, orderId: number): Promise<void> => {
        /**
         * Если автонабор не закончил свою работу
         * и каким-то образом у нас уже другой заказ в работе,
         * то прекращаем поллинг
         */
        if (orderId !== this._currentOrder.id) {
            return;
        }

        if (!this.callAutomatizationFinished // Если автонабор не завершил свою работу (есть еще попытки, либо не дозвонились до клинта)
            && !this._sipService.isCallingRightNow // и нет активного звонка
            && this._currentOrder.callPossibility // и есть возможность позвонить
            && !this._currentOrder.pddModalWindowShown // и нет активной модалки по pdd
            && !this._currentUser.isTimeChangedSipHost // убеждаемся, что не идет переключение сип данных
        ) {
            if (this._numberOfCallsMade !== 0 && this._automaticCallsInterval > 0) {
                // если задан интервал между звонками, то делаем между попытками задержку
                await this.delayBetweenAttempts();
            }

            await this._sipService.call(phone);
            // увеличиваем количество звонков в сессии (а также благодаря этому методу скрывается таймер во время звонка)
            this._currentOrder.incrementSessionCallCount();
            // а также в модели автонабора увеличиваем счетчик звонков
            this._callAutomatizationModel.incrementNumberOfCallsMadeForUI();
        }

        if (this.callAutomatizationFinished) {
            // прекращаем вызывать метод
            return;
        }

        // задержка 800 ms, чтобы метод слишком часто не спамился
        await this.pollingTimeout();
        await this.proceedCallAutomatization(phone, orderId);
    };
}

export default CallAutomatizationHandler;
