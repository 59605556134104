import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';


export const SubStatus = t
    .model('SubStatus', {
        id: t.identifier,
        status: t.maybeNull(t.integer),
        name: t.maybeNull(t.string),
    });


export interface ISubStatusModel extends Instance<typeof SubStatus> {}
export interface ISubStatusModelSnapshotIn extends SnapshotIn<typeof SubStatus> {}
export interface ISubStatusModelSnapshotOut extends SnapshotOut<typeof SubStatus> {}
