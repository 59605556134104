import React, { FunctionComponent } from 'react';
import map from 'lodash/map';

import { IProductItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/ProductItem.model';

import './includes/DeletedOptionalGiftsFromPromotionBuilder.scss';
import I18NService from '@services/I18NService';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';


type TDeletedOptionalGiftsFromPromotionBuilderProps = {
    readonly products: IProductItemModelSnapshotIn[];
};


const DeletedOptionalGiftsFromPromotionBuilder: FunctionComponent<TDeletedOptionalGiftsFromPromotionBuilderProps> = ({
    products,
}) => {
    const { t } = useInstance(I18NService);
    return (
        <div className="DeletedOptionalGiftsFromPromotionBuilder">
            {
                map<IProductItemModelSnapshotIn, JSX.Element>(
                    products,
                    (item) => (
                        <span
                            key={item.id}
                            className="DeletedOptionalGiftsFromPromotionBuilder__nameItem"
                        >
                            {
                                t(
                                    'Был удален подарок на выбор {{productName}}',
                                    'Optional gift {{productName}} was deleted',
                                    { productName: item.name },
                                )
                            }
                        </span>
                    ),
                )
            }
        </div>
    );
};


export default observer(DeletedOptionalGiftsFromPromotionBuilder);
