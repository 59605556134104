import { SipService } from '@services/index';
import { Store } from '@store/store';
import { toFactory } from 'react-ioc';
import CallHandler from '@services/call-handler/CallHandler';

const CallHandlerDI = (): any => [
    CallHandler,
    toFactory(
        [
            SipService,
            Store,
        ],
        (
            sipService: SipService,
            store: Store,
        ) => new CallHandler(
            sipService,
            store,
        ),
    ),
];

export default CallHandlerDI;