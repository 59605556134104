import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class NoGiftForAPromotionalItemWasFoundError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'Не обнаружен подарок для акционного товара из номенклатур.';
    }
}


export default NoGiftForAPromotionalItemWasFoundError;
