import React, { useCallback } from 'react';
import cn from 'classnames';

import CrossIconSvg from '@components/main/order-page/order-products/order-table/includes/CrossIconSvg';

import './includes/Tab.scss';

interface ITabProps {
    className?: string; // для wrapper-a всего элемента
    tabs: ITabValue[]; // опции для выбора
    activeTab?: ITabValue; // активный таб
    onCloseTabClick: (id: string) => void; // нажали "закрыть" на каком-то из табов
    onChooseTab: (id: string) => void; // выбрали определенный tab
}

export interface ITabValue {
    isPossibleRemove: boolean;
    label: string; // то, что показываем в интерфейсе
    id: string; // ИД, по которому можем найти элемент в массиве
}

const Tab = (
    props: ITabProps,
): JSX.Element => {
    const {
        className,
        tabs,
        activeTab,
        onCloseTabClick,
        onChooseTab,
    } = props;

    const onCloseTabClicked = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const element = event.target as HTMLDivElement;
        let tabWrapper: HTMLElement | null | undefined = element.parentElement;

        while (!tabWrapper?.classList?.contains('TabElement')) {
            tabWrapper = tabWrapper?.parentElement;
        }

        onCloseTabClick(tabWrapper.id);
    }, [tabs, onCloseTabClick]);

    const onTabClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element = event.target as HTMLDivElement;
        if (element.classList.contains('TabElement')) {
            onChooseTab(element.id);
            return;
        }

        let tabWrapper: HTMLElement | null | undefined = element.parentElement;

        while (!tabWrapper?.classList?.contains('TabElement')) {
            tabWrapper = tabWrapper?.parentElement;
        }

        onChooseTab(tabWrapper.id);
    }, [tabs, onChooseTab]);

    return (
        <div
            className={cn([className,
                'Tab__wrapper',
                'pre_defined_scrollbar',
            ])}
        >
            {
                tabs.map((tab) => (
                    <div
                        className={cn([
                            'TabElement',
                            tab.isPossibleRemove && 'tab_with_close_button',
                            activeTab && activeTab.id === tab.id && 'active',
                        ])}
                        key={tab.id}
                        id={tab.id}
                        onClick={onTabClick}
                    >
                        <div
                            className={cn([
                                'tab_content',
                            ])}
                            title={tab.label}
                        >
                            {tab.label}
                        </div>
                        {tab.isPossibleRemove && (
                            <div
                                className={cn([
                                    'close_tab',
                                ])}
                                onClick={onCloseTabClicked}
                            >
                                <CrossIconSvg />
                            </div>
                        )}
                    </div>
                ))
            }
        </div>
    );
};


export default Tab;
