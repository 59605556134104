import { toFactory } from 'react-ioc';

import OrderFetchService from '@services/order/OrderFetchService';
import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';
import PartnerApiService from '@api/partner-api-service';
import UserApiService from '@api/user-api-service';
import SupportApiService from '@api/support-api-service';
import CallApiService from '@api/call-api-service';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import OldPromotionApiService from '@api/old-promotion-api-service';
import PromotionBuilderApiService from '@api/promotion-builder-api-service';
import ModalService from '@core/services/ModalService';


const OrderFetchServiceDI = (): any => [
    OrderFetchService,
    toFactory(
        [
            Store,
            OrderApiService,
            PartnerApiService,
            UserApiService,
            SupportApiService,
            CallApiService,
            InactivityOperatorStorageService,
            OldPromotionApiService,
            PromotionBuilderApiService,
            ModalService,
        ],
        (
            store: Store,
            orderApiService: OrderApiService,
            partnerApiService: PartnerApiService,
            userApiService: UserApiService,
            supportApiService: SupportApiService,
            callApiService: CallApiService,
            inactivityOperatorStorageService: InactivityOperatorStorageService,
            oldPromotionApiService: OldPromotionApiService,
            promotionBuilderApiService: PromotionBuilderApiService,
            modalService: ModalService,
        ) => new OrderFetchService(
            store,
            orderApiService,
            partnerApiService,
            userApiService,
            supportApiService,
            callApiService,
            inactivityOperatorStorageService,
            oldPromotionApiService,
            promotionBuilderApiService,
            modalService,
        ),
    ),
];


export default OrderFetchServiceDI;
