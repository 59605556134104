import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

export const ScriptThemeModel = t
    .model('ScriptThemeModel', {
        id: t.identifierNumber,
        name: t.string,
        key: t.string,
    });


export interface IScriptThemeModel extends Instance<typeof ScriptThemeModel> {}
export interface IScriptThemeModelSnapshotIn extends SnapshotIn<typeof ScriptThemeModel> {}
export interface IScriptThemeModelSnapshotOut extends SnapshotOut<typeof ScriptThemeModel> {}