import { IReactionDisposer, reaction } from 'mobx';
import { applySnapshot } from 'mobx-state-tree';

import { Store } from '@store/store';
import CookieService from '@core/services/CookiesService';
import UserService from '@services/UserService';
import WebSocketService from '@services/websocket/WebSocketService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { SipService } from '@services/index';
import HistoryService from '@services/HistoryService';


class CookieSubscriptionService {
    private _disposerChangeClearAccessToken: IReactionDisposer;

    constructor(
        private readonly _store: Store,
        private readonly _cookieService: CookieService,
        private readonly _userService: UserService,
        private readonly _webSocketService: WebSocketService,
        private readonly _sipService: SipService,
        private readonly _historyService: HistoryService,
    ) {}

    private async _processingEventOfAChangeInStateOfAuthorization(isAuthCookieValid: boolean): Promise<void> {
        const {
            currentUser: {
                isReady,
                setIsReady,
                mode,
            },
            setPrevSavedOrderId,
            clearOrderView,
        } = this._store;

        await this._userService.initUser();

        if (isAuthCookieValid) {
            if (mode === UserModeEnum.CLIENT_SERVICE && isReady) {
                // Первичное подключение к телефонии для режима клиентского сервиса только если пользователь в готов
                this._sipService.connect();
            } else if (
                mode === UserModeEnum.PREDICTIVE
                    || mode === UserModeEnum.REGULAR
                || mode === UserModeEnum.PROGRESSIVE
            ) {
                // Первичное подключение к телефонии в режиме предиктива и обычном совершаем сразу
                this._sipService.connect();
            }
        } else {
            if (isReady) {
                setIsReady(false);
            }

            this._sipService.disconnect();
            this._webSocketService.off();

            setPrevSavedOrderId(null);
            this._userService.unsetUser();
            clearOrderView();

            applySnapshot(this._store, {});

            this._store.responsive.init();
        }
    }

    public subscribe(): void {
        this._disposerChangeClearAccessToken = reaction<boolean>(
            () => this._cookieService.isAuthCookieValid,
            this._processingEventOfAChangeInStateOfAuthorization.bind(this),
        );
    }

    public unsubscribe(): void {
        if (this._disposerChangeClearAccessToken) {
            this._disposerChangeClearAccessToken();
        }
    }
}


export default CookieSubscriptionService;
