import React, { FunctionComponent } from 'react';
import cn from 'classnames';

interface IAlertIconSvgProps {
    className?: string;
    width?: number;
    height?: number;
    onClick?: () => void;
}

const AlertIconSvg: FunctionComponent<IAlertIconSvgProps> = ({
    className, width = 90, height = 83, onClick, ...props
}): JSX.Element => (
    <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(['AlertIconSvg', className])}
        onClick={onClick}
        {...props}
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="noun_Alert_2887642" transform="translate(0.000000, -1.000000)">
                <g id="Group">
                    <polygon id="Path" points="48.279 55.779 49.997 39.964 49.997 35.168 40.448 35.168 40.448 39.967 42.124 55.808" />
                    <path d="M45.188,61.766 C43.839,61.766 42.77,62.119 41.919,62.845 C41.15,63.503 40.791,64.362 40.791,65.549 C40.791,66.703 41.138,67.536 41.884,68.171 C42.71,68.875 43.791,69.218 45.188,69.218 C46.674,69.218 47.819,68.869 48.688,68.152 C49.457,67.517 49.815,66.69 49.815,65.55 C49.815,64.38 49.441,63.53 48.637,62.872 C47.728,62.128 46.599,61.766 45.188,61.766 Z" id="Path" />
                    <path d="M49.579,7.481 C48.636,5.803 46.924,4.801 45,4.801 C43.076,4.801 41.364,5.803 40.421,7.481 L4.516,71.373 C3.592,73.018 3.609,74.972 4.562,76.599 C5.515,78.227 7.21,79.199 9.097,79.199 L80.905,79.199 C82.792,79.199 84.487,78.227 85.439,76.599 C86.393,74.97 86.409,73.017 85.486,71.373 L49.579,7.481 Z M53.827,40.068 C53.827,40.137 53.823,40.206 53.816,40.275 L51.901,57.893 C51.816,58.674 51.269,59.303 50.55,59.519 C50.725,59.641 50.895,59.771 51.061,59.907 C52.751,61.29 53.644,63.241 53.644,65.549 C53.644,67.823 52.774,69.744 51.125,71.105 C49.563,72.394 47.565,73.047 45.187,73.047 C42.873,73.047 40.926,72.387 39.399,71.085 C37.804,69.727 36.961,67.812 36.961,65.548 C36.961,63.256 37.816,61.314 39.431,59.932 C39.579,59.807 39.728,59.687 39.883,59.573 C39.145,59.366 38.582,58.727 38.497,57.931 L36.629,40.268 C36.622,40.201 36.619,40.134 36.619,40.067 L36.619,33.253 C36.619,32.196 37.476,31.338 38.534,31.338 L51.912,31.338 C52.969,31.338 53.827,32.195 53.827,33.253 L53.827,40.068 L53.827,40.068 Z" id="Shape" />
                    <path d="M88.822,69.497 L52.917,5.604 C51.287,2.703 48.327,0.971 45,0.971 C41.673,0.971 38.712,2.703 37.083,5.604 L1.177,69.497 C-0.42,72.342 -0.39,75.72 1.256,78.533 C2.902,81.348 5.834,83.029 9.096,83.029 L80.904,83.029 C84.166,83.029 87.097,81.348 88.743,78.534 C90.391,75.718 90.421,72.34 88.822,69.497 Z M85.437,76.6 C84.485,78.228 82.79,79.2 80.903,79.2 L9.096,79.2 C7.209,79.2 5.514,78.228 4.561,76.6 C3.608,74.973 3.591,73.019 4.515,71.374 L40.42,7.481 C41.363,5.803 43.075,4.801 44.999,4.801 C46.923,4.801 48.635,5.803 49.578,7.481 L85.483,71.374 C86.408,73.017 86.391,74.971 85.437,76.6 Z" id="Shape" fill="#8E8E93" fillRule="nonzero" />
                    <path d="M51.912,31.339 L38.534,31.339 C37.477,31.339 36.619,32.196 36.619,33.254 L36.619,40.068 C36.619,40.135 36.623,40.203 36.629,40.269 L38.497,57.932 C38.581,58.728 39.145,59.367 39.883,59.574 C39.729,59.688 39.579,59.808 39.431,59.933 C37.815,61.314 36.961,63.256 36.961,65.549 C36.961,67.813 37.804,69.727 39.399,71.086 C40.926,72.387 42.873,73.048 45.187,73.048 C47.565,73.048 49.563,72.395 51.125,71.106 C52.773,69.745 53.644,67.824 53.644,65.55 C53.644,63.242 52.751,61.291 51.061,59.908 C50.895,59.772 50.724,59.643 50.55,59.52 C51.269,59.304 51.816,58.674 51.901,57.894 L53.816,40.276 C53.823,40.207 53.827,40.138 53.827,40.069 L53.827,33.255 C53.827,32.196 52.969,31.339 51.912,31.339 Z M40.448,35.168 L49.997,35.168 L49.997,39.964 L48.279,55.779 L42.124,55.808 L40.449,39.967 L40.449,35.168 L40.448,35.168 Z M48.688,68.151 C47.819,68.869 46.673,69.217 45.188,69.217 C43.791,69.217 42.71,68.875 41.884,68.17 C41.138,67.535 40.791,66.702 40.791,65.548 C40.791,64.361 41.15,63.502 41.919,62.844 C42.77,62.118 43.838,61.765 45.188,61.765 C46.6,61.765 47.728,62.127 48.637,62.87 C49.441,63.528 49.815,64.379 49.815,65.548 C49.815,66.69 49.457,67.517 48.688,68.151 Z" id="Shape" fill="#8E8E93" fillRule="nonzero" />
                </g>
            </g>
        </g>
    </svg>
);

export default AlertIconSvg;
