import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import getAppVersion from '@/env/getAppVersion';
import { getLogRocketAppId, getLogRocketVerificatorAppId } from '@/env/getLogRocketAppId';


class LogRocketService {
    private readonly LOG_ROCKET_APP_ID: string;

    private readonly LOG_ROCKET_VERIFICATOR_APP_ID: string;

    constructor() {
        this.LOG_ROCKET_APP_ID = getLogRocketAppId();
        this.LOG_ROCKET_VERIFICATOR_APP_ID = getLogRocketVerificatorAppId();
    }

    public identifyUser(userId: number, firstName: string, lastName: string, username: string): void {
        if (!this.LOG_ROCKET_APP_ID || !this.LOG_ROCKET_VERIFICATOR_APP_ID) {
            return;
        }

        LogRocket.identify(`{login: ${username}, id: ${String(userId)}}`, {
            name: `${firstName} ${lastName}`,
            username,
            domain: window.location.href,
        });
    }

    public init(isVefificator: boolean): void {

        if (!this.LOG_ROCKET_APP_ID || !this.LOG_ROCKET_VERIFICATOR_APP_ID) {
            return;
        }
        const logrocketId = isVefificator ? this.LOG_ROCKET_VERIFICATOR_APP_ID : this.LOG_ROCKET_APP_ID;

        console.info(`init LogRocket on ${logrocketId}`);
        LogRocket.init(logrocketId, {
            release: `2wcall-ui@${getAppVersion()}`,
        });
        setupLogRocketReact(LogRocket);
    }
}


export default LogRocketService;
