import React, {
    FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';
import cn from 'classnames';
import isInteger from 'lodash/isInteger';
import ReactTooltip from 'react-tooltip';

import ValidateService from '@core/services/ValidateService';
import PlusIconSvg from '@components/resources/SVG/Plus/PlusIconSvg';
import TooltipPortal from '@components/main/TooltipPortal/TooltipPortal';
import { Store } from '@store/store';

import MinusIconSvg from './includes/MinusIconSvg';
import './includes/CounterInput.scss';


interface ICounterInputProps {
    onAdd: () => void;
    onReduce: () => void;
    onInputChange: (value: number) => void;
    quantity: string;
    className?: string;
    hasError?: boolean;
    name?: string;
    id?: string;
    editable?: boolean;
}


const CounterInput: FunctionComponent<ICounterInputProps> = ({
    className = '',
    hasError,
    quantity,
    onAdd,
    onReduce,
    onInputChange,
    editable,
}) => {
    const store = useInstance(Store);

    const [focused, setFocused] = useState<boolean>(false);

    const onFocusCallback = () => setFocused(true);

    const showModal = store.currentOrder.form.showWholesaleConfirm;

    const { isFiguresValid } = useInstance(ValidateService);

    const [internalValue, setInternalValue] = useState<string>(quantity);

    /**
     * При событии onChange:
     * 1. Если ввели пустое значение, то оставляем его = позволяем стереть полностью значение в инпуте
     * 2. Выполняем проверку на соответствие регулярному выражению "\d+" \
     *  *потому что html атрибут pattern может не работать на iPhone 4*
     * 3. Если вводится не число, то ничего не делаем
     * 4. Если ввели число в интервале от 0 до 20, то вызываем setInternalValue с этим значением и ждём события onBlur
     */
    const onInputChangeCallback = useCallback((event) => {
        const { value } = event.target;
        const isValid = isFiguresValid(value);

        if (value === '') {
            setInternalValue(value);
            return;
        }

        if (!isValid) {
            return;
        }

        const _value = parseInt(value, 10);

        if (isInteger(_value) && _value > 0) {
            setInternalValue(String(_value));
        }

        if (isInteger(_value) && _value <= 0) {
            setInternalValue('0');
        }
    }, [onInputChange]);

    /**
     * При блюре инпута (противоположно событию onFocus):
     * 1. Если значение инпута пустое, то:
     *  1.1 Оставляем значение в инпуте как было до этого (модель не меняем)
     *  1.2 Если значение quantity = 0, то нужно применить его в модель, чтобы товар удалился
     * 2. Если ввели число >=0 и <=20, то изменяем значение в модели (Вызов метода onInputChange),\
     * устанавливаем внутреннее значение компонента таким же
     */
    const onBlurCallback = useCallback((event) => {
        setFocused(false);
        const { value } = event.target;

        if (value === '') {
            if (quantity === '0') {
                // если подряд несколько раз стирать 0 и блюриться с пустым значением (чтобы каждый раз товар удалялся)
                onInputChange(Number(quantity));
            }

            setInternalValue(quantity);

            return;
        }

        const _value = parseInt(value, 10);

        if (isInteger(_value) && _value >= 0) {
            onInputChange(_value);
            setInternalValue(String(_value));
        }
    }, [onInputChange, quantity]);

    useEffect(() => {
        setInternalValue(quantity);
    }, [quantity]);

    useEffect(() => {
        if (!focused && !showModal && quantity !== internalValue) {
            setInternalValue(quantity);
        }
    }, [showModal, quantity, internalValue, focused]);

    /**
     * Tooltip settings:
     */
    const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (Number(internalValue) > 99) {
            setIsShowTooltip(true);
        } else {
            setIsShowTooltip(false);
        }
    }, [internalValue]);

    const tooltipData: Record<string, string> = {
        'data-for': 'counter-input__wrapper',
        'data-tip': internalValue,
    };

    return (
        <>
            {isShowTooltip && (
                <TooltipPortal>
                    <ReactTooltip
                        id="counter-input__wrapper"
                        place="top"
                        type="dark"
                        effect="float"
                    />
                </TooltipPortal>
            )}
            <div
                className="counter-input__wrapper"
                {...isShowTooltip ? tooltipData : null}
            >
                <div
                    className={cn([
                        'e-counter-input',
                        className,
                        hasError && 'error',
                    ])}
                >
                    <div className="input__wrapper left" onClick={onReduce}>
                        <MinusIconSvg
                            className="input-plus"
                        />
                    </div>
                    <input
                        type="text"
                        pattern="\d+"
                        value={internalValue}
                        onChange={onInputChangeCallback}
                        onBlur={onBlurCallback}
                        onFocus={onFocusCallback}
                        autoComplete="disabled"
                        data-cy="counter_edit_input"
                        disabled={!editable}
                    />
                    <div className="input__wrapper right" onClick={onAdd}>
                        <PlusIconSvg
                            className="input-plus"
                        />
                    </div>
                </div>
            </div>
        </>

    );
};


export default observer(CounterInput);
