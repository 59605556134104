import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

import { ChangeStatusVirtualButton, IChangeStatusVirtualButtonModel } from '@models/mobx-state-tree/changeStatusVirtualButton.model';


export const ChangeStatusButton = t
    .model('ChangeStatusButton', {
        id: t.maybeNull(t.number),
        name: t.string,
        includeSelf: t.boolean,
        virtualStatuses: t.optional(t.array(ChangeStatusVirtualButton), []),
    })
    .views((self) => ({
        get virtualStatusesValues(): IChangeStatusVirtualButtonModel[] {
            return [...self.virtualStatuses.values()];
        },
    }));


export interface IChangeStatusButtonModel extends Instance<typeof ChangeStatusButton> {}
export interface IChangeStatusButtonSnapshotIn extends SnapshotIn<typeof ChangeStatusButton> {}
export interface IChangeStatusButtonSnapshotOut extends SnapshotOut<typeof ChangeStatusButton> {}
