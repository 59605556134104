import { Store } from '@store/store';
import {
    action, computed, IReactionDisposer, reaction,
} from 'mobx';

import { causes } from 'jssip/lib/Constants';
import { Originator } from 'jssip/lib/RTCSession';

import I18NService from '@services/I18NService';
import ErrorService from '@core/services/ErrorService';
import CallHistoryApiService from '@api/call-history-api-service';
import UserService from '@services/UserService';
import {
    EnumSipStatus,
    IAsteriskHangupCause,
    ISipServiceBase,
    ReasonsForEndingConversation,
    SipError,
} from '@services/sip/models';
import { CallHistoryEvent, ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { ICallAutomatizationModel } from '@models/mobx-state-tree/callAutomatization.model';
import OfflineError from '@core/error/OfflineError';
import { OrderService } from '@app/services';
import { autoCall } from '@core/constants/autoCall';
import { ConfirmModalType } from '@core/models/ModalWindow';
import ModalService from '@core/services/ModalService';
import CookieService from '@core/services/CookiesService';


class SipService {
    private _minTimeWaitCallCountdown = 0;

    private _callTimeTickInterval: NodeJS.Timeout | undefined;

    private _pollingMinTimeWaitCall: number | undefined;

    public get phoneNumber(): string {
        return this.sip.getPhoneNumber();
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    public get status(): EnumSipStatus {
        return this.sip.getStatus();
    }

    public get muted(): boolean {
        return this.sip.getMuted();
    }

    public set muted(value: boolean) {
        this.sip.setMuted(value);
    }

    public set sipErrorMessage(value: string | null) {
        this.sip.sipErrorMessage = value;
    }

    public get isIncomingCall(): boolean {
        return this.sip.callDirection === 'incoming';
    }

    public get isOutgoingCall(): boolean {
        return this.sip.callDirection === 'outgoing';
    }

    public get callTime(): number {
        return this.sip.getCallTime();
    }

    public get sipError(): SipError {
        return this.sip.sipError;
    }

    /**
     * Если true, значит клиенту позвонили и он разговаривает
     */
    @computed
    public get isAnIncomingCallToACustomer(): boolean {
        return this.isIncomingCall
            && this.status === EnumSipStatus.LIVE
            && this._currentUser.mode === UserModeEnum.CLIENT_SERVICE;
    }

    /**
     * Если true, значит клиент позвонил и разговаривает
     */
    @computed
    public get isAnOutgoingCallToACustomer(): boolean {
        return this.isOutgoingCall
            && this.status === EnumSipStatus.LIVE
            && this._currentUser.mode === UserModeEnum.CLIENT_SERVICE;
    }

    /**
    * Если true, значит оператор позвонил и разговаривает
    */
    @computed
    public get isAnOutgoingCallToAOperator(): boolean {
        return this.isOutgoingCall
        && this.status === EnumSipStatus.LIVE
        && this._currentUser.mode === UserModeEnum.REGULAR;
    }

    @computed
    public get isCallingRightNow(): boolean {
        if (this.sip.getStatus() === null) {
            return false;
        }
        return this.sip.getStatus() >= EnumSipStatus.DIALING && this.sip.getStatus() <= EnumSipStatus.ENDED;
    }

    @computed
    public get callHistoryEvent(): ICallHistoryEventModel | null {
        return this._currentOrder.lastCallHistoryEvent;
    }

    @computed
    public get hasSipOption(): boolean {
        return this.sip.getSipOptions() !== null;
    }

    public getPhoneNumber(): string {
        return this.sip.getPhoneNumber();
    }

    constructor(
        private readonly store: Store,
        private readonly callHistoryApiService: CallHistoryApiService,
        private readonly I18NService: I18NService,
        private readonly errorService: ErrorService,
        private readonly userService: UserService,
        public readonly sip: ISipServiceBase,
        private readonly _orderService: OrderService,
        private readonly modalService: ModalService,
        private readonly cookiesService: CookieService,
    ) {}

    private _disposeOnAsteriskHangupCauseChangeReaction: IReactionDisposer | undefined;

    private _disposeSaveCallHistoryOnEndStatusReaction: IReactionDisposer | undefined;

    private _disposeOnAppearEndStatusReaction: IReactionDisposer | undefined;

    private _disposeIncrementCallCountOnCall: IReactionDisposer | undefined;

    private _disposeRegisterredStatusChanged: IReactionDisposer | undefined;

    private _disposeOnLiveStatusChanged: IReactionDisposer | undefined;

    private _disposeOnDisconnectedStatusAppear: IReactionDisposer | undefined;

    private _disposeClearReconnectIntervalOnConnect: IReactionDisposer | undefined;

    private _reconnectAfterDisconnectInterval: NodeJS.Timeout | undefined;

    public unsubscribe = (): void => {

        if (this._disposeRegisterredStatusChanged) {
            this._disposeRegisterredStatusChanged();
        }

        if (this._disposeOnLiveStatusChanged) {
            this._disposeOnLiveStatusChanged();
        }

        if (this._disposeOnAsteriskHangupCauseChangeReaction) {
            this._disposeOnAsteriskHangupCauseChangeReaction();
        }

        if (this._disposeSaveCallHistoryOnEndStatusReaction) {
            this._disposeSaveCallHistoryOnEndStatusReaction();
        }

        if (this._disposeOnAppearEndStatusReaction) {
            this._disposeOnAppearEndStatusReaction();
        }

        if (this._disposeIncrementCallCountOnCall) {
            this._disposeIncrementCallCountOnCall();
        }

        if (this._disposeOnDisconnectedStatusAppear) {
            this._disposeOnDisconnectedStatusAppear();
        }

        if (this._disposeClearReconnectIntervalOnConnect) {
            this._disposeClearReconnectIntervalOnConnect();
        }
    };

    public getSipStatus(): EnumSipStatus {
        return this.sip.getStatus();
    }

    public getSipErrorCause(): causes | undefined {
        return this.sip.getSipErrorCause();
    }

    public getSipErrorCauseOriginator(): Originator | undefined {
        return this.sip.getSipErrorCauseOriginator();
    }

    public getAsteriskHangupCause(): IAsteriskHangupCause | null {
        return this.sip.getAsteriskHangupCause();
    }

    private get _callAutomatizationModel(): ICallAutomatizationModel {
        return this._currentOrder.callAutomatization;
    }

    private get _startCallAutomatic(): boolean {
        return this._currentOrder.startCallAutomatic;
    }

    private get _phoneNumberNotFound(): boolean {
        return this._callAutomatizationModel.phoneNumberNotFound;
    }

    /**
     * Завершен ли процесс автонабора
     */
    @computed
    public get callAutomatizationFinished(): boolean {
        return this._callAutomatizationModel.callAutomatizationFinished;
    }

    public subscribe = (): void => {
        this.unsubscribe();

        // Когда пользователь подключился все таки к телефонии, то очищаем таймер на переподключение.
        this._disposeClearReconnectIntervalOnConnect = reaction<boolean>(
            () => this.sip.getStatus() === EnumSipStatus.CONNECTED,
            (isConnected) => {
                if (isConnected && this._reconnectAfterDisconnectInterval) {
                    clearInterval(this._reconnectAfterDisconnectInterval);
                    this._reconnectAfterDisconnectInterval = undefined;
                }
            },
        );

        // Статус подключения сменился на disconnected. Надо переподключиться если пользователь готов.
        this._disposeOnDisconnectedStatusAppear = reaction<boolean>(
            () => this.sip.getStatus() === EnumSipStatus.DISCONNECTED,
            (isDisconnected) => {
                if (!isDisconnected) {
                    return;
                }

                if (this._reconnectAfterDisconnectInterval) {
                    clearInterval(this._reconnectAfterDisconnectInterval);
                    this._reconnectAfterDisconnectInterval = undefined;
                }

                if (this._currentUser.mode === UserModeEnum.PROGRESSIVE && this._currentUser.selectedAuxStatus &&
                    this._currentOrder.isEmptyCurrentOrder) {
                    return;
                }

                // Если пользователь в не готов в режиме КлС то не надо переподключать его к телефонии.
                const isUnreadyInClientService = !this.store.currentUser.isReady
                    && this.store.currentUser.mode === UserModeEnum.CLIENT_SERVICE;

                if (!isUnreadyInClientService && this.hasSipOption && this.cookiesService.isAuthCookieValid) {
                    this._reconnectAfterDisconnectInterval = setInterval(
                        () => {
                            if (!this.sip.isConnected && this.status < EnumSipStatus.DISCONNECTING_IN_PROGRESS) {
                                if (!this.cookiesService?.isAuthCookieValid && this._reconnectAfterDisconnectInterval) {
                                    return clearInterval(this._reconnectAfterDisconnectInterval);
                                }

                                console.log('Try reconnect');
                                this.connect();
                            }
                        },
                        2000,
                    );
                }
                // Сип данные сменились //
                if (this._currentUser.isTimeChangedSipHost) {
                    this._currentUser.setIsTimeChangedSipHost(false);
                }
            },
        );

        /**
         * Если звонок в финальном статусе, то через 1 секунду:
         * 1) обычный режим - переподключаемся с sip, отправляем начало события сохранения.
         * Если подключились то отмечаем что есть возможность позвонить.
         * 2) предективный - переподключаемся с sip TODO но не реализовано
         * 3) клиентский сервис - переподключаемся с sip если пользователь в готов
         * 4) прогрессивный автодозвон - переподключаемся
         */
        this._disposeOnAppearEndStatusReaction = reaction(
            () => this.sip.getStatus() === EnumSipStatus.ENDED
                || this.sip.getStatus() === EnumSipStatus.FAIL
                || this.sip.getStatus() === EnumSipStatus.REGISTRATION_FAIL,
            (isFinal) => {
                const isRegistrationFail = this.sip.getStatus() === EnumSipStatus.REGISTRATION_FAIL;
                if (isRegistrationFail) {
                    // Если регистрация провалена, то пробуем переподключиться, для этого сначала гасим подключение
                    this.disconnect();
                }

                if (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE && this._currentUser.unreadyAfterSave) {
                    this._currentUser.setIsReady(false);
                    this._currentUser.setUnreadyAfterSave(false);

                    this.sip.disconnect();
                    return;
                }

                // В режиме клиентского сервиса чтобы подключиться к сип пользователь должен быть готов
                if (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE && !this._currentUser.isReady) {
                    this.sip.disconnect();

                    return;
                }

                if (isFinal && this.hasSipOption) {
                    setTimeout(() => {
                        if (!this.hasSipOption) {
                            return;
                        }

                        this.sip.setStatus(EnumSipStatus.DISCONNECTED);
                        this.sip.sipErrorMessage = null;
                        this.sip.setSipErrorCauseAndOriginator(undefined, undefined);

                        try {
                            if (
                                this._currentUser.mode === UserModeEnum.REGULAR
                                || this._currentUser.mode === UserModeEnum.PREDICTIVE
                                || (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE && this._currentUser.isReady)
                                || this._currentUser.mode === UserModeEnum.PROGRESSIVE
                            ) {
                                if (!this.sip.isConnected) {
                                    this.sip.setStatus(EnumSipStatus.DISCONNECTED);
                                    if (!this._currentUser.disableProgressiveMode)  {
                                        if (this._currentUser.mode === UserModeEnum.PROGRESSIVE && this._currentUser.selectedAuxStatus) {
                                            return;
                                        }
                                        this.connect();
                                    }
                                }

                                if (this.sip.isConnected) {
                                    if (!this.sip.isRegistered) {
                                        this.sip.setStatus(EnumSipStatus.REGISTRATION_FAIL);
                                    }

                                    if (this.sip.isRegistered) {
                                        this.sip.setStatus(EnumSipStatus.REGISTERED);
                                    }
                                }

                                this._currentOrder.setCallPossibility(true);
                            }
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.error('connect e', e);
                        }
                    }, 1000);
                }
            },
        );

        /**
         * только для обычного режима
         * Добавляет метку что оператор звонил клиенту, это в случае если сип статус был равен или больше EnumSipStatus.ENDED
         */
        this._disposeIncrementCallCountOnCall = reaction(
            () => this.sip._status >= EnumSipStatus.ENDED,
            (isCallEndEvent) => {
                // только для обычного режима
                if (this._currentUser.mode !== UserModeEnum.REGULAR) {
                    return;
                }

                if (isCallEndEvent) {
                    this._currentOrder.setOperatorMadeACallToTheClient(true);
                }
            },
        );

        /**
         * Работает только для прогрессивного режима автодозвона.
         *
         * В случае изменения параметров isReady или isEmptyCurrentOrder и соблюдения условий - не получен заказ для обработки и статус сип соединения ЗАРЕГИСТРИРОВАН,
         * и isReady равен true, начинается автодозвон на номер 100, если isReady равен false  соединение обрывается
         */

        this._disposeRegisterredStatusChanged = reaction(
            () => this._currentUser.mode === UserModeEnum.PROGRESSIVE && this._currentUser.isReady
                 && this._currentOrder.isEmptyCurrentOrder,
            (isFinal) => {
                if (isFinal && this.sip._status === EnumSipStatus.REGISTERED && this._currentOrder.isEmptyCurrentOrder) {
                    this.sip.call('100');
                } else if (!this._currentUser.isReady) {
                    this.sip.endCall();
                }
            },
        );

        this._disposeOnLiveStatusChanged = reaction(
            () => this.sip._status === EnumSipStatus.LIVE,
            (isLive) => {
                if (isLive) {
                    if (this._currentUser.mode === UserModeEnum.REGULAR
                        || this._currentUser.mode === UserModeEnum.PREDICTIVE
                    ) {
                        // если подняли трубку, то сообщаем на бэк номер телефона как успешный
                        void this._orderService.setSuccessPhone(this.phoneNumber);
                    }

                    this._callTimeTickInterval = setInterval(() => {
                        this.store.inactivityTimer.setPreviousCallDuration(this.sip.getCallTime());
                    }, 1000);

                }

                if (!isLive && this._callTimeTickInterval) {
                    clearInterval(this._callTimeTickInterval);
                }
            },
        );
    };

    private pollTickMinTimeWaitCall = (): Promise<void> => new Promise((resolve) => {
        this._pollingMinTimeWaitCall = setTimeout(resolve, 1000);
    });

    private minTimeWaitCallStart = async (): Promise<void> => {
        // if (this.sip.getStatus() >= EnumSipStatus.ENDED) {
        //     if (this._pollingMinTimeWaitCall) {
        //         clearTimeout(this._pollingMinTimeWaitCall);
        //     }
        //
        //     if (this._minTimeWaitCallCountdown) {
        //         this._minTimeWaitCallCountdown = 0;
        //     }
        //
        //     return;
        // }


        await this.pollTickMinTimeWaitCall();

        this._minTimeWaitCallCountdown++;

        if (this._minTimeWaitCallCountdown >= this._currentOrder.country.minTimeWaitCall) {

            this._currentOrder.setAbilityToDropCall(true);

            return;
        }

        await this.minTimeWaitCallStart();
    };

    private async indicateTheBeginningOfACall(phone: string): Promise<void> {
        const startTime = new Date().getTime();

        const { id: orderId, groupId, lastQueue } = this._currentOrder;

        let userSip: string;
        if (this._currentUser.sipPlatinum) {
            userSip = this._currentUser.sipPlatinum.sipLogin || 'unknown';
        } else {
            userSip = 'unknown';
        }
        const queueId = lastQueue ? lastQueue.id : null;

        let data;

        try {
            data = await this.callHistoryApiService.onCallStart(
                orderId,
                groupId || '',
                phone || '',
                userSip,
                queueId || null,
                this._currentUser.sipPlatinum.sipHost,
            );

        } catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        }

        this._currentOrder.setLastCallHistoryEvent(CallHistoryEvent.create({
            groupId: groupId || '',
            orderId,
            phone: phone || '',
            userSip,
            startTime,
            id: Number(data.id) || 0,
        }));
    }

    /**
     * В конце звонка для обычного режима\
     * отправляются метаданные на бэк\
     * о причинах его завершения
     */
    public async indicateTheEndOfTheCall(): Promise<void> {
        if (!this.callHistoryEvent) {
            return;
        }

        try {
            const {
                id,
                hangupCause = null,
                endCall,
            } = this.callHistoryEvent;

            await this.callHistoryApiService.onCallEnd(
                Number(id),
                Number(endCall),
                hangupCause || '',
            );
        } catch (e) {
            if ('onLine' in navigator) {
                const isOffline = !navigator.onLine;
                if (isOffline) {
                    throw this.errorService.toReturnAFabricatedError(OfflineError);
                }
            } else {
                throw new Error(e instanceof Error ? e.message : e);
            }
        } finally {
            if (this.callHistoryEvent) {
                this.callHistoryEvent.setEndCall(null);
                this.callHistoryEvent.setHangupCause(null);
            }
        }
    }

    @action
    public connect = (): void => {
        this.sip.collectDataForConnectionAndConnect();
    };

    public disconnect = (): void => {
        this.sip.disconnect();
    };

    public connectionRestart = (): void => {
        this.disconnect();
        this.connect();
    };

    private async _hangUpAutoCall() {
        if (this._currentUser.mode === UserModeEnum.PROGRESSIVE) {
            const taskId = this.sip.getZorraTaskId();
            if (taskId) {
                const result = await this.callHistoryApiService.hangUpAutoCallByTask(taskId);
                if (result) {
                    this.sip.setZorraTaskId(undefined);
                }
            }
        }
    }

    @action
    public endCall = (): void => {

        void this._hangUpAutoCall();

        this.sip.endCall();

        if (!this.sip.isConnected) {
            // По окончании звонка восстанавливаем соединение
            if (
                (this._currentUser.mode === UserModeEnum.CLIENT_SERVICE && this._currentUser.isReady)
                || this._currentUser.mode === UserModeEnum.PREDICTIVE
                || (this._currentUser.mode === UserModeEnum.REGULAR && !this._currentUser.disableProgressiveMode)
            ) {
                this.connect();
            }
        }
        if (this._currentUser.mode === UserModeEnum.PROGRESSIVE && this._currentOrder.isEmptyCurrentOrder) {
            this._currentUser.setIsReady(false);
        }

    };

    public setReconnectionRequired(val: boolean): void {
        this.sip.reconnectionRequired = val;
    }

    public async showChangeCallModeModal(): Promise<void> {

        if (this._currentOrder.isEmptyCurrentOrder || !this._currentUser.haveSipPlatinumAccountCredential) {
            const autoCallValue = localStorage.getItem(autoCall);

            if (autoCallValue) {

                const text = this.I18NService.t(
                    `Вы переключены в ${autoCallValue === '1' ? 'режим автодозвона' : 'обычный режим' }`,
                    `You are switched to ${autoCallValue === '1' ? 'autocall' : 'usual' } mode`,
                );

                await this.modalService.showConfirmModal(text, ConfirmModalType.Yes);

                localStorage.removeItem(autoCall);

                window.location.reload();
            }

        }
    }

    @action
    public async call(phone: string): Promise<void> {
        const { mode, isReady } = this._currentUser;
        /**
         * Проверка на isReady нужна для ситуаций, когда данная функция (call) запущена в setTimeout и требуется
         * быстрая ее отмена с помощью кнопки "Не готов к следующему звонку", но функция уже зарегитрирована в
         * браузерном АПИ и подлежит выполнению.
         */
        if (isReady) {
            try {
                const {
                    forceInactiveButtonOfCall,
                    callPossibility,
                    setCallPossibility,
                } = this._currentOrder;

                if (mode === UserModeEnum.REGULAR) {
                    if (forceInactiveButtonOfCall) {
                        return;
                    }

                    await this.indicateTheBeginningOfACall(phone);
                }

                if ((mode === UserModeEnum.REGULAR || mode === UserModeEnum.PROGRESSIVE) && callPossibility) {
                    setCallPossibility(false);
                    this.sip.call(phone);
                }

                if (mode === UserModeEnum.CLIENT_SERVICE) {
                    this.sip.call(phone);
                }

                if (mode === UserModeEnum.REGULAR) {
                    // ограничитель который не позволяет повесить трубку раньше времени при наборе клиенту
                    clearTimeout(this._pollingMinTimeWaitCall);
                    this._minTimeWaitCallCountdown = 0;
                    void this.minTimeWaitCallStart();
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);

                if (mode === UserModeEnum.REGULAR || mode === UserModeEnum.PROGRESSIVE) {
                    if (this.callHistoryEvent) {
                        this.callHistoryEvent.setEndCall(ReasonsForEndingConversation.SIP_ERROR);
                    }

                    await this.indicateTheEndOfTheCall();
                }
            }
        }
    }
}


export default SipService;
