export enum Locations {
    MAIN = '/',
    LOGOUT = 'logout',
    LOGIN = '/auth/login',
    PROFILE = '/profile/control',

    RECALLS = '/order/recalls',

    FIND_ORDER = '/order/find-order',

    TRAINING_ROOM = '/training-room',
    CATEGORIES = '/training-room/categories',
    CATEGORY_VIEW = '/training-room/category',
    ARTICLE_VIEW = '/training-room/article',
    ARTICLE_TEST = '/training-room/test/article',
    CATEGORY_TEST = '/training-room/test/category',

    AUDIT = '/audit',
    ABOUT = '/about',
    FINANCE = '/finance',
    AUDIT_ORDERS = '/audit-orders',
    SUPPORT = '/support',
    PROGRESS = '/progress',
    BI_REPORT = '/activity',
    ORDER_CREATION = '/order/change/create-form',
    CUSTOMER_SERVICE = '/',
    ORDER_VIEW = '/order/index/view',
    BILLING_LIST = '/billing-list',
}
