import React, {
    ChangeEvent, FormEvent, FunctionComponent,
} from 'react';
import cn from 'classnames';

import { useInputFocus } from '@/utils/useFocus';

import './includes/InputDummy.scss';

interface IInputDummyProps {
    className?: string;
    children?: JSX.Element;
    value: string;
    inputId: string; // Unique ID through out the whole document for the internal input field
    type: 'text' | 'number' | 'tel' | 'email';
    disabled: boolean; // Indicates that the control is not available for interaction
    invalid: boolean; // A boolean flag indicating an error
    validationMessage: string; // An error message to be displayed below the form field
    label: string; // Text to be displayed as the form label
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: FormEvent<HTMLInputElement>) => void;
    onFocus?: (event: FormEvent<HTMLInputElement>) => void;
    autoComplete?: string; // Specifies whether autocomplete is applied to an editable input.
    hasIcon: boolean;
}


const InputDummy: FunctionComponent<IInputDummyProps> = ({
    className,
    children,
    value,
    inputId,
    type = 'text',
    disabled = false,
    invalid = false,
    validationMessage,
    label,
    onChange,
    onBlur,
    onFocus,
    autoComplete = 'disabled',
    hasIcon = false,
}) => {
    const [focusRef, isFocused] = useInputFocus();

    return (
        <div
            className={cn([className,
                'DummyInput__wrapper',
                disabled && 'disabled',
                isFocused && !disabled && 'focused',
            ])}
        >
            <div className="textfield">
                {hasIcon && children && (
                    <div
                        className={cn(['icon',
                            isFocused && !disabled && 'icon_focused', // если в input-e не пусто или мы прям встали курсором в input
                            invalid && 'icon_invalid',
                        ])}
                    >
                        {children}
                    </div>
                )}
                <input
                    className={cn([
                        'input',
                        disabled && 'input_disabled',
                        !disabled && invalid && 'input_invalid',
                        hasIcon && children && 'has_icon_input',
                    ])}
                    id={inputId}
                    value={value}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete={autoComplete}
                    ref={focusRef}
                    disabled={disabled}
                    title={value}
                />
                <div
                    className={cn([
                        'label',
                        disabled && 'label_disabled',
                        (isFocused || value.length) && !disabled && 'label_focused', // если в input-e не пусто или мы прям встали курсором в input
                        value.length && !isFocused && !disabled && 'label_has_value_not_focused', // если есть значение, не disabled и input не focused
                        value.length && !isFocused && disabled && 'label_has_value_not_focused_disabled', // есть значение, инпут disabled
                        !disabled && invalid && 'label_invalid',
                        hasIcon && children && 'has_icon_label',
                    ])}
                    title={label}
                >
                    {label}
                </div>
                <div
                    className={cn([
                        'error_field',
                        !disabled && invalid && 'error_visible',
                    ])}
                    title={validationMessage}
                >
                    {validationMessage}
                </div>
            </div>
        </div>
    );
};


export default InputDummy;
