import forEach from 'lodash/forEach';
import LocalStorageService, { TInactivityDataItem } from '@core/services/LocalStorageService';


const ITEM_MAX_LIFETIME = 1000 * 60 * 60; // 1 час


class InactivityOperatorStorageService {
    constructor(
        private readonly _localStorageService: LocalStorageService,
    ) {
        this._removeOldRecords();
    }

    private _removeOldRecords(): void {
        const data = this._localStorageService.storageInactivity;
        const now = new Date().getTime();

        forEach(data, (item) => {
            if (item) {
                if (!item.timestamp || item.timestamp + ITEM_MAX_LIFETIME >= now) {
                    item = null;
                }
            }
        });
    }

    public getStoredViewTimePassed(orderId: number, userId: number): number | null {
        const currentData: TInactivityDataItem | null = this._localStorageService.storageInactivity[`${String(orderId)}-${String(userId)}`];

        if (!currentData) {
            return null;
        }

        return currentData.viewTimePassed || null;
    }

    public getStoredSaveTimePassed(orderId: number, userId: number): number | null {
        const currentData: TInactivityDataItem | null = this._localStorageService.storageInactivity[`${String(orderId)}-${String(userId)}`];

        if (!currentData) {
            return null;
        }

        return currentData.saveTimePassed || null;
    }

    public getStoredCallDuration(orderId: number, userId: number): number | null {
        const currentData: TInactivityDataItem | null = this._localStorageService.storageInactivity[`${String(orderId)}-${String(userId)}`];

        if (!currentData) {
            return null;
        }

        return currentData.callDuration || null;
    }

    public storeViewTimePassed(viewTimePassed: number, orderId: number, userId: number): void {
        const dataInStorage = this._localStorageService.storageInactivity;

        const currentData: TInactivityDataItem | null = dataInStorage[`${String(orderId)}-${String(userId)}`];
        const callDuration = currentData?.callDuration || 0;
        const saveTimePassed = currentData?.saveTimePassed || 0;
        const modalTimePassed = currentData?.modalTimePassed || 0;

        dataInStorage[`${String(orderId)}-${String(userId)}`] = {
            callDuration,
            viewTimePassed,
            modalTimePassed,
            saveTimePassed,
            orderId,
            userId,
            timestamp: new Date().getTime(),
        };

        this._localStorageService.storageInactivity = dataInStorage;
    }

    public storeModalTimePassed(modalTimePassed: number, orderId: number, userId: number): void {
        const dataInStorage = this._localStorageService.storageInactivity;

        const currentData: TInactivityDataItem | null = dataInStorage[`${String(orderId)}-${String(userId)}`];
        const callDuration = currentData?.callDuration || 0;
        const saveTimePassed = currentData?.saveTimePassed || 0;
        const viewTimePassed = currentData?.viewTimePassed || 0;

        dataInStorage[`${String(orderId)}-${String(userId)}`] = {
            callDuration,
            viewTimePassed,
            modalTimePassed,
            saveTimePassed,
            orderId,
            userId,
            timestamp: new Date().getTime(),
        };

        this._localStorageService.storageInactivity = dataInStorage;
    }

    public storeSaveTimePassed(saveTimePassed: number, orderId: number, userId: number): void {
        const dataInStorage = this._localStorageService.storageInactivity;

        const currentData: TInactivityDataItem | null = dataInStorage[`${String(orderId)}-${String(userId)}`];
        const callDuration = currentData?.callDuration || 0;
        const viewTimePassed = currentData?.viewTimePassed || 0;
        const modalTimePassed = currentData?.modalTimePassed || 0;

        dataInStorage[`${String(orderId)}-${String(userId)}`] = {
            callDuration,
            viewTimePassed,
            modalTimePassed,
            saveTimePassed,
            orderId,
            userId,
            timestamp: new Date().getTime(),
        };

        this._localStorageService.storageInactivity = dataInStorage;
    }

    public storeCallDuration(callDuration: number, orderId: number, userId: number): void {
        const dataInStorage = this._localStorageService.storageInactivity;

        const currentData: TInactivityDataItem | null = dataInStorage[`${String(orderId)}-${String(userId)}`];
        const saveTimePassed = currentData?.saveTimePassed || 0;
        const viewTimePassed = currentData?.viewTimePassed || 0;
        const modalTimePassed = currentData?.modalTimePassed || 0;

        dataInStorage[`${String(orderId)}-${String(userId)}`] = {
            callDuration,
            viewTimePassed,
            modalTimePassed,
            saveTimePassed,
            orderId,
            userId,
            timestamp: new Date().getTime(),
        };

        this._localStorageService.storageInactivity = dataInStorage;
    }


    /**
     * Очищяем в localStorage таймер после сохранения заказа на случай, если заказ снова попадет к оператору.
     * Это для того, чтобы у повторно попавшего оператору заказа таймер всегда начинался с 0
     */
    public storeClearTimePassedForOrderAfterSave(orderId: number, userId: number): void {
        this.storeSaveTimePassed(0, orderId, userId);
        this.storeViewTimePassed(0, orderId, userId);
        this.storeCallDuration(0, orderId, userId);
    }

    public resetModalTimePassedInLocalStorage(orderId: number, userId: number): void {
        this.storeModalTimePassed(0, orderId, userId);
    }
}


export default InactivityOperatorStorageService;
