import { createBrowserHistory, History } from 'history';
import { observable } from 'mobx';


type THistory = History;


class HistoryService {
    @observable
    public readonly history: THistory;

    constructor() {
        this.history = createBrowserHistory();
    }
}


export default HistoryService;
