import { ICallCenterCountry, ICountry } from '@interfaces/country.interface';
import { IRecall } from '@interfaces/recalls.interface';
import { IQueue } from '@interfaces/queue.interface';


export type TStatusInfo = 'Default' | 'Blocked' | 'Deleted' | 'New' | 'Applicant' | 'Unknown';

export interface ICustomerServiceServer {
    url: string;
}

export interface ICallCenter {
    customer_service_server: ICustomerServiceServer | null;
    id: number;
    name: string;
    description: string;
    country: null | ICallCenterCountry;
    communication_server_id: number;
    check_browser: boolean;
}

export interface IStatusInfo {
    id: number;
    name: TStatusInfo;
}

export interface ISip {
    sip_host: string;
    sip_login: string;
    sip_pass: string;
}

export interface IUserLanguageData {
    id: number;
    name: string;
    locale: string;
    icon: string;
}

export type ButtonKey = 'create' | 'find';

export interface IButtonUi {
    ButtonKey: string;
}

export interface IUserResponse {
    id: number;
    username: string;
    timezone_id: number;
    status: number;
    statusInfo: IStatusInfo[];
    first_name: string;
    last_name: string;
    phone: string;
    is_ready: boolean;
    button_ui: IButtonUi[];
    is_verificator: boolean;
    team_id: number | null;
    auto_call: boolean;
    webrtc: boolean;
    incoming_call: boolean;
    last_activity: number | null;
    ready_to_call: boolean;
    sip: string | ISip; // TODO Приходит объект в строке
    sip_platinum: string | ISip; // TODO Приходит объект в строке
    sip_predictive: string | ISip; // TODO Приходит объект в строке
    sip_auto_call: string | ISip; // TODO Приходит объект в строке
    filename: string | null;
    token: string;
    expired: number;
    predictive_autocall: boolean;
    predictive_pause: boolean;
    predictive_first_start: boolean;
    customer_service: boolean;
    language: IUserLanguageData | null;
    need_speed_test: boolean;
    valid_speed_test: boolean | null;
    /**
     * Включать сервис метрик Logrocket
     */
    logrocket: boolean;
}

export interface IAuthDataPartner {
    partner: {
        id: number;
        name: string;
    };
}

export enum IS_READY_STATUS_ENUM {
    READY = 1,
    UN_READY = 0,
}

export interface IAuthDataProduct {
    id: number;
    name: string;
}

export interface IAuthData {
    call_center: ICallCenter | null;
    user: IUserResponse;
    countries: ICountry[];
    recalls: IRecall[];
    products: IAuthDataProduct[];
    partners: [IAuthDataPartner][];
    queues: [IQueue][];
    roles: [string][];
}

export interface IRefteshTokenData {
    token: string;
    expired: number;
}

export interface IUserLocalStorage extends IUserResponse {
    call_center: ICallCenter | null;
    permissions: string[];
}
