import React, {
    FunctionComponent,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import EnoughQuantityImg from '@images/products/enough_quantity.png';
import FewQuantityImg from '@images/products/few_quantity.png';
import LittleQuantityImg from '@images/products/little_quantity.png';
import I18NService from '@services/I18NService';
import OrderService from '@/app/services/order/OrderService';
import { IProductItemModel } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import BasketService from '@services/order/BasketService';


interface IOrderTableQuantityRecordProps {
    readonly quantityInStock: number;
    readonly item?: IProductItemModel;
    readonly isSomeOfMyChildrenItemsOutOfStock?: boolean;
}

const OrderTableQuantityInStock: FunctionComponent<IOrderTableQuantityRecordProps> = ({
    quantityInStock,
    item,
    isSomeOfMyChildrenItemsOutOfStock = false,
}) => {
    const [
        { t },
        {
            currentOrder: {
                country: {
                    stockAlertingEnabled,
                },
            },
        },
        basketService,
    ] = useInstances(
        I18NService,
        OrderService,
        BasketService,
    );

    const title = () => `${t('Количество на складе', 'Quantity in stock')}: ${quantityInStock}`;

    // Если сам товар не закончился (item), опция stock_alerting_enable = true, какой-то из моих дочерних товаров закончился, то:
    if (item && isSomeOfMyChildrenItemsOutOfStock && stockAlertingEnabled && !basketService.isItemOutOfStock(item)) {
        return (
            <div className="currentProducts__table-product--quantity">
                <div
                    className="flex-inner product_quantity_spec out_of_stock"
                    data-cy="out_of_stock_message"
                    title={t('Товар в составе набора закончился', 'The item in the set is out of stock')}
                >
                    {t('Товар в составе набора закончился', 'The item in the set is out of stock')}
                </div>
            </div>
        );
    }

    // Если товар закончился (item) и опция stock_alerting_enable = true
    if (item && stockAlertingEnabled && basketService.isItemOutOfStock(item)) {
        return (
            <div className="currentProducts__table-product--quantity">
                <div
                    className="flex-inner product_quantity_spec out_of_stock"
                    data-cy="out_of_stock_message"
                    title={t('Товара нет на складе', 'Product is out of stock')}
                >
                    {t('Товара нет на складе', 'Product is out of stock')}
                </div>
            </div>
        );
    }

    return (
        <div className="currentProducts__table-product--quantity">
            {quantityInStock > 0 && quantityInStock < 50 && (
                <div className="flex-inner product_quantity_image" title={title()}>
                    <img className="quantity__img" src={LittleQuantityImg} alt="" />
                </div>
            )}
            {quantityInStock >= 50 && quantityInStock <= 200 && (
                <div className="flex-inner product_quantity_image" title={title()}>
                    <img className="quantity__img" src={FewQuantityImg} alt="" />
                </div>
            )}
            {quantityInStock > 200 && (
                <div className="flex-inner product_quantity_image" title={String(quantityInStock)}>
                    <img className="quantity__img" src={EnoughQuantityImg} alt="" />
                </div>
            )}

            {quantityInStock === 0 && (
                <div
                    className="flex-inner product_quantity_spec out_of_stock"
                    data-cy="out_of_stock_message"
                >
                    {t('Не доступно на складе', 'Not available in stock')}
                </div>
            )}
            {quantityInStock > 0 && quantityInStock < 50 && (
                <div
                    className="flex-inner product_quantity_spec"
                    title={title()}
                    data-cy="out_of_stock_message"
                >
                    {t('Почти закончился', 'Almost end')}
                </div>
            )}
            {quantityInStock >= 50 && quantityInStock <= 200 && (
                <div
                    className="flex-inner product_quantity_spec"
                    title={title()}
                    data-cy="out_of_stock_message"
                >
                    {t('Достаточно', 'Few')}
                </div>
            )}
            {quantityInStock > 200 && (
                <div
                    className="flex-inner product_quantity_spec"
                    title={title()}
                    data-cy="out_of_stock_message"
                    data-cy-2="out_of_stock_enough"
                >
                    {t('Много', 'Enough')}
                </div>
            )}
        </div>
    );
};


export default observer(OrderTableQuantityInStock);
