import { toFactory } from 'react-ioc';
import VerifierApiService from '@api/verifier-api-service';
import VerifierService from '@services/VerifierService';
import RecallsService from '@services/RecallsService';
import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';


const VerifierServiceDI = (): any => [
    VerifierService,
    toFactory([
        VerifierApiService,
        RecallsService,
        Store,
        OrderApiService,
    ],
    (
        verifierApiService: VerifierApiService,
        recallsService: RecallsService,
        store: Store,
        orderApiService: OrderApiService,
    ) => new VerifierService(
        verifierApiService,
        recallsService,
        store,
        orderApiService,
    )),
];


export default VerifierServiceDI;