import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import { OrderService } from '@services/index';
import ProductsService from '@services/order/ProductsService';
import OldPromotionService from '@services/order/OldPromotionService';
import BasketService from '@services/order/BasketService';


const ProductsServiceDI = (): any => [
    ProductsService,
    toFactory(
        [
            Store,
            OrderService,
            OldPromotionService,
            BasketService,
        ],
        (
            store: Store,
            orderService: OrderService,
            oldPromotionService: OldPromotionService,
            basketService: BasketService,
        ) => new ProductsService(
            store,
            orderService,
            oldPromotionService,
            basketService,
        ),
    ),
];


export default ProductsServiceDI;
