import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import MenuIconSvg from '@components/main/bottom-mobile-menu/icons/MenuIconSvg/MenuIconSvg';
import OrderIconSvg from '@components/main/bottom-mobile-menu/icons/OrderIconSvg/OrderIconSvg';
import CallIconSvg from '@components/main/bottom-mobile-menu/icons/CallIconSvg/CallIconSvg';
// import ProfileIconSvg from '@components/main/bottom-mobile-menu/icons/ProfileIconSvg/ProfileIconSvg';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import cn from 'classnames';
import TimeFromSeconds from '@UIElements/TimeFromSeconds';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import './BottomMobileTabs.scss';


export enum BottomMobileTabsPageEnum {
    ORDER = 'ORDER',
    TRAINING_ROOM = 'TRAINING_ROOM',
    TRAINING_ROOM_LESSON_ITEM = 'TRAINING_ROOM_LESSON_ITEM',
    ORDER_CREATION = 'ORDER_CREATION',
    PROFILE = 'PROFILE',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    BI_REPORT = 'BI_REPORT',
    BILLING_LIST = 'BILLING_LIST',
    RECALLS = 'RECALLS',
}

interface IIBottomMobileTabsProps {
    page: BottomMobileTabsPageEnum;
}

const BottomMobileTabs: FC<IIBottomMobileTabsProps> = ({ page }) => {
    const [
        { t },
        store,
    ] = useInstances(
        I18NService,
        Store,
    );

    const bottomMobileTabUIState = store.ui.bottomMobileTab;
    const { setLanguagesVisible, setStatusVisible, setCommunicationQualityVisible } = store.ui.sidebar;
    const { inactivityTimer } = store;
    const { isReady } = store.currentUser;
    const { activeBottomTab, setActiveTab } = bottomMobileTabUIState;

    const onClick = useCallback((tab: BottomTabEnum | null) => () => {
        if (tab) {
            setActiveTab(tab);
        }
        setLanguagesVisible(false);
        setStatusVisible(false);
        setCommunicationQualityVisible(false);
    }, [setActiveTab]);

    const {
        viewFormFillTimePassed,
        saveFormFillTimePassed,
        viewFormFillTimeLimit,
        saveFormFillTimeLimit,
        viewTimerActive,
        saveTimerActive,
    } = inactivityTimer;

    let time = null;
    let isExpired = false;
    let type = '';
    if (viewTimerActive && viewFormFillTimeLimit !== null) {
        time = viewFormFillTimePassed;
        if (viewFormFillTimeLimit * 1000 <= viewFormFillTimePassed) {
            isExpired = true;
        }
        type = 'view';
    } else if (saveTimerActive && saveFormFillTimeLimit !== null) {
        time = saveFormFillTimePassed;
        if (saveFormFillTimeLimit * 1000 <= saveFormFillTimePassed) {
            isExpired = true;
        }
        type = 'save';
    }

    const showTimer = !!time;

    return (
        <div className="BottomMobileMenu">
            <div
                className={cn(
                    'item',
                    'menu',
                    type,
                    activeBottomTab === BottomTabEnum.MENU && 'active',
                    page === BottomMobileTabsPageEnum.ORDER && isReady && 'ready',
                    page === BottomMobileTabsPageEnum.ORDER && !isReady && 'un-ready',
                )}
                onClick={onClick(activeBottomTab === BottomTabEnum.MENU ? null : BottomTabEnum.MENU)}
            >
                <div className="icon">
                    <MenuIconSvg />
                </div>
                <span>{t('Меню', 'Menu')}</span>
            </div>

            {(
                page === BottomMobileTabsPageEnum.ORDER
                || (
                    store.currentUser.mode === UserModeEnum.CLIENT_SERVICE
                    && (
                        page === BottomMobileTabsPageEnum.ORDER_CREATION
                        || page === BottomMobileTabsPageEnum.TRAINING_ROOM
                        || page === BottomMobileTabsPageEnum.TRAINING_ROOM_LESSON_ITEM
                        || page === BottomMobileTabsPageEnum.BI_REPORT
                        || page === BottomMobileTabsPageEnum.PROFILE
                    )
                )
            ) && (
                <>
                    <div
                        className={cn(
                            'item',
                            'order', activeBottomTab === BottomTabEnum.ORDER && 'active',
                            showTimer && 'with-duration',
                        )}
                        onClick={onClick(BottomTabEnum.ORDER)}
                    >
                        <div className="icon">
                            <OrderIconSvg />
                        </div>
                        {!showTimer && page === BottomMobileTabsPageEnum.ORDER && (
                            <span>{t('Заказ', 'Order')}</span>
                        )}
                        {!showTimer && page === BottomMobileTabsPageEnum.BI_REPORT && (
                            <span>{t('Активность', 'Activity')}</span>
                        )}
                        {!showTimer && page === BottomMobileTabsPageEnum.PROFILE && (
                            <span>{t('Профиль', 'Profile')}</span>
                        )}
                        {!showTimer && (
                            page === BottomMobileTabsPageEnum.TRAINING_ROOM
                            || page === BottomMobileTabsPageEnum.TRAINING_ROOM_LESSON_ITEM
                        ) && (
                            <span>{t('Комната для тренировок', 'Training room')}</span>
                        )}
                        {!showTimer && page === BottomMobileTabsPageEnum.ORDER_CREATION && (
                            <span>{t('Создание заказа', 'Order creation')}</span>
                        )}
                        {showTimer && time && (
                            <div className={cn('duration', isExpired && 'expired')}>
                                <TimeFromSeconds duration={Math.ceil(time / 1000)} />
                            </div>
                        )}
                    </div>

                    <div
                        className={cn(
                            'item',
                            'call',
                            activeBottomTab === BottomTabEnum.CALL && 'active',
                        )}
                        onClick={onClick(BottomTabEnum.CALL)}
                    >
                        <div className="icon">
                            <CallIconSvg />
                        </div>
                        <span>{t('Звонок', 'Call')}</span>
                    </div>

                    {/* <div className="item profile" onClick={onClick(BottomTabEnum.PROFILE)}> */}
                    {/*    <div className="icon"> */}
                    {/*        <ProfileIconSvg /> */}
                    {/*    </div> */}
                    {/*    <span>{t('Профиль', 'Profile')}</span> */}
                    {/* </div> */}
                </>
            )}
        </div>
    );
};

export default observer(BottomMobileTabs);
