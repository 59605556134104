import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export const AuxStatusesModel = t
    .model('AuxStatusesModel', {
        id: t.integer,
        name: t.string,
        orderGet: t.boolean,
        icon: t.maybeNull(t.string),
        limit: t.number,
        description: t.string,
        notLimited: t.boolean,
    });

export interface IAuxStatusesModel extends Instance<typeof AuxStatusesModel> {}
export interface IAuxStatusesModelSnapshotIn extends SnapshotIn<typeof AuxStatusesModel> {}
export interface IAuxStatusesModelSnapshotOut extends SnapshotOut<typeof AuxStatusesModel> {}