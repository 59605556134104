import each from 'lodash/each';
import find from 'lodash/find';
import every from 'lodash/every';
import values from 'lodash/values';

import { IBasketItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IProductItemModelSnapshotIn, ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import DeletedPromotionsAndProducts from '@/app/order-prepare/DeletedPromotionsAndProducts';
import IntermediateStateCatalogsAndBaskets from '@/app/order-prepare/IntermediateStateCatalogsAndBaskets';


/**
 * Сервис выполняет перебор элементов корзины и каталога.\
 * Удаляет подарки, которых нет на складе,\
 * а также старые акции, в состав которых входят такие подарки\
 * Сервис зависит от опции form.consider_remains
 */
class ConsiderRemains {
    /**
     * **Алгоритм для consider_remains = true:**
     * 1. Если в заказе есть подарок и этот подарок отсутствует на складе,\
     * то такой подарок не отображаем в каталоге и в заказе.
     *
     * 2. Если в заказе есть старая акция,\
     * у основного товара акции признак main = false (не является товаром лида)\
     * и какой-либо из подарков акции отсутствует на складе,\
     * то мы удаляем все товары по акции.\
     * Сохраняем в модель текущего заказа информацию об удаленной акции.
     *
     * 3. Если в заказе есть старая акция,\
     * у основного товара акции признак main = true (не является товаром лида)\
     * и какой-либо из подарков акции отсутствует на складе,\
     * то мы удаляем все товары по акции\
     * и вместо акции добавляем основной товар (расформировываем акцию)\
     * Сохраняем в модель текущего заказа информацию о расформированной акции.
     */

    constructor(
        private readonly _intermediateStateCatalogsAndBaskets: IntermediateStateCatalogsAndBaskets,
        private readonly _deletedPromotionsAndProducts: DeletedPromotionsAndProducts,
    ) {}

    /**
     * Удаляем старые акции из каталога и корзины
     */
    public removeOldPromotion(): void {
        const {
            catalogValues,
            basketValues,
            deleteItemFromBasketByKey,
            deleteItemFromCatalogByKey,
            pushToBasket,
        } = this._intermediateStateCatalogsAndBaskets;

        each<IProductItemModelSnapshotIn>(
            catalogValues,
            (catalogItem) => {
                const { oldPromotionalProduct } = catalogItem;

                if (
                    catalogItem.subType === ProductItemSubTypeEnum.OLD_PROMOTION
                    && oldPromotionalProduct
                ) {
                    const itAOldPromotionWithCorrectGifts = every<IProductItemModelSnapshotIn>(
                        values<IProductItemModelSnapshotIn>(oldPromotionalProduct.gifts),
                        (gift) => gift.quantityInStock > 0,
                    );

                    if (!itAOldPromotionWithCorrectGifts) {
                        deleteItemFromCatalogByKey(catalogItem.id);

                        each<IBasketItemModelSnapshotIn>(
                            basketValues,
                            (basketItem) => {
                                if (basketItem.promotionId === oldPromotionalProduct.id) {
                                    if (!basketItem.promotionMasterBasketItemId) {
                                        if (basketItem.main) {
                                            const mainItemIdInCatalogWithoutOldPromotion = find<IProductItemModelSnapshotIn>(
                                                catalogValues,
                                                {
                                                    partnerProductId: catalogItem.partnerProductId,
                                                    subType: ProductItemSubTypeEnum.MAIN,
                                                },
                                            );

                                            if (mainItemIdInCatalogWithoutOldPromotion?.price) {
                                                pushToBasket({
                                                    ...basketItem,
                                                    productItem: mainItemIdInCatalogWithoutOldPromotion.id,
                                                    promotionId: null,
                                                    promotionName: null,
                                                    price: mainItemIdInCatalogWithoutOldPromotion.price,
                                                });

                                                // инфу про расформировывание запоминаем для нотификейшна
                                                this._deletedPromotionsAndProducts.pushToDisbandedOldPromotions(basketItem);
                                            }
                                        } else {
                                            deleteItemFromBasketByKey(basketItem.id);

                                            // если удалили, то просто запишем об этом инфу
                                            this._deletedPromotionsAndProducts.pushToDeletedOldPromotions(basketItem);
                                        }
                                    } else {
                                        // удаляем подарки и промо из корзины, которые шли по акции
                                        deleteItemFromBasketByKey(basketItem.id);
                                    }
                                }
                            },
                        );
                    }
                }
            },
        );
    }

    /**
     * Удаляем подарки из каталога и корзины
     */
    public removeSimpleGiftsFromCatalogAndBasket(): void {
        const {
            catalogValues,
            basketValues,
            deleteItemFromBasketByKey,
            deleteItemFromCatalogByKey,
        } = this._intermediateStateCatalogsAndBaskets;

        const deletedCatalogKeys: string[] = [];

        each<IProductItemModelSnapshotIn>(
            catalogValues,
            (catalogItem) => {
                if (
                    catalogItem.quantityInStock <= 0
                    && catalogItem.subType === ProductItemSubTypeEnum.GIFT
                    && (
                        catalogItem.isFromPromotionBuilder && catalogItem.mandatoryGift
                        || !catalogItem.isFromPromotionBuilder && !catalogItem.mandatoryGift
                    )
                ) {
                    deletedCatalogKeys.push(catalogItem.id);
                    deleteItemFromCatalogByKey(catalogItem.id);
                }
            },
        );

        each<string>(
            deletedCatalogKeys,
            (key) => {
                const basket = find<IBasketItemModelSnapshotIn>(
                    basketValues,
                    {
                        productItem: key,
                    },
                );

                if (basket) {
                    deleteItemFromBasketByKey(basket.id);
                }
            },
        );
    }
}


export default ConsiderRemains;
