import React from 'react';

import { observer } from 'mobx-react';

import FlagIconCss from '@/plugins/FlagIconCss';
import { block } from 'bem-cn';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';

import './billing-list-info.scss';

const BillingListInfo = () => {

    const [
        store,
        { t },

    ] = useInstances(
        Store,
        I18NService,
    );

    const b = block('billing-list-info');

    const { currentUser } = store;

    const { callCenter, firstName, lastName, username } = currentUser;
    const icon = callCenter?.country?.language?.icon;

    return (
        <div className={b()}>
            <div className={b('avatar')}></div>
            <div className={b('info')}>
                <div className={b('name')}>
                    {firstName}
                </div>
                <div className={b('name')}>
                    {lastName}
                </div>
                <div className={b('id-name')}>
                    <FlagIconCss
                        className={b('id-country')}
                        icon={icon ? icon : 'flag-icon-ru'}
                        squared
                    />
                    {username}
                </div>
                <div className={b('call-center')}>
                    {t('Call центр', 'Call-center')}: <span className={b('call-center-name')}>
                        {callCenter?.name ? callCenter.name : t('Не определено', 'Undefined')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default observer(BillingListInfo);