import { toFactory } from 'react-ioc';
import BillingListService from '@components/main/billing-list-page/BillingListService';
import UserService from '@services/UserService';
import { Store } from '@store/store';
import OperatorStatisticApiService from '../core/api/operator-statistic-api-service';
import { BillingListStore } from '@store/billingListStore';

const BillingListServiceDI = (): any => [
    BillingListService,
    toFactory([
        OperatorStatisticApiService,
        UserService,
        Store,
        BillingListStore,
    ],
    (
        operatorStatisticApiService: OperatorStatisticApiService,
        userService: UserService,
        store: Store,
        billingListStore: BillingListStore,
    ) => new BillingListService(
        operatorStatisticApiService,
        userService,
        store,
        billingListStore,
    )),
];

export default BillingListServiceDI;
