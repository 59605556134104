import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import map from 'lodash/map';
import { observer } from 'mobx-react';
import cn from 'classnames';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import OrderTableQuantityInStock
    from '@components/main/order-page/order-products/order-table/OrderTableQuantityInStock';
import Counter from '@UIElements/Counter';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import LockSVG from '@/app/components/resources/SVG/Lock/LockSVG';
import CounterInput from '@/app/ui-elements/CounterInput';
import BasketService from '@services/order/BasketService';
import { Store } from '@store/store';
import NomenclatureNavigationBar from '@UIElements/nomenclature-navigation-bar/NomenclatureNavigationBar';


type TDropDownListWithContentsOfSetProps = {
    readonly basketItem: IBasketItemModel[];
    readonly dataCy: string;
};


const ListOfNomenclatures: FunctionComponent<TDropDownListWithContentsOfSetProps> = ({
    basketItem,
    dataCy,
}) => {
    const [
        { currentOrder: { prepareLabelTranslation } },
        basketService,
    ] = useInstances(
        Store,
        BasketService,
    );

    const eventHandlerFromNomenclatureNavigationBar = (event: string, product: IBasketItemModel) => {
        if (event === 'delete') {
            basketService.removeItemsFromProductOfPromotionBuilder(product);
        }
    };

    return (
        <>
            {map<IBasketItemModel, JSX.Element>(
                basketItem,
                (product) => (
                    <div
                        className={cn([
                            'currentProducts__table-row',
                            'composition-set',
                            // подсветка добавленного в корзину обязательного промо товара
                            product.highlightOnAddToPromotionBuilderBasketPromo && 'mandatory-promo-added',
                        ])}
                        key={product.id}
                        data-cy={`${dataCy}-${product.productItem.nameWithoutSpaces}`}
                    >
                        <div
                            className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
                        >
                            <div className="currentProducts__table-restore" />

                            <div className="currentProducts__table-avatar">
                                <ProductImage
                                    className="currentProducts__table-avatar-image"
                                    productName={product.productItem.name}
                                    imageLink={product.productItem.image}
                                    productType={product.productItem.type}
                                />
                            </div>
                            <OrderTableQuantityInStock
                                item={product.productItem}
                                quantityInStock={product.productItem.quantityInStock}
                            />
                            <div
                                className="currentProducts__table-product--name-flex"
                                title={product.productItem.name}
                                data-cy="product-name"
                            >
                                <div
                                    className="product-name"
                                >
                                    {product.productItem.name}
                                </div>
                                {product.productItem.type === ProductItemTypeEnum.REGULAR
                                && product.productItem.subType === ProductItemSubTypeEnum.GIFT
                                && product.mandatoryGift && (
                                    <div
                                        className="mandatoryGift"
                                    >
                                        <LockSVG />
                                    </div>
                                )}
                                {product.productItem.type === ProductItemTypeEnum.REGULAR
                                && product.productItem.subType === ProductItemSubTypeEnum.PROMO
                                && product.mandatoryPromo && (
                                    <div
                                        className="mandatoryPromo"
                                    >
                                        <LockSVG />
                                    </div>
                                )}
                            </div>

                            <span
                                className={cn(
                                    'currentProducts__table-text-label--webkit',
                                )}
                            >
                                <ProductLabel
                                    productType={product.productItem.type}
                                    productSubType={product.productItem.subType}
                                    isPromotion={false}
                                    text={prepareLabelTranslation(
                                        product.productItem.type,
                                        product.productItem.subType,
                                        false,
                                    )}
                                />
                            </span>
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-2"
                        >
                            {/**
                             * Изменять количество можно только у промо и необязательных подарков
                             */}
                            {product.productItem.type === ProductItemTypeEnum.REGULAR
                            && product.productItem.subType === ProductItemSubTypeEnum.GIFT
                            && product.productItem.mandatoryGift && (
                                <Counter
                                    value={String(product.quantity)}
                                />
                            )}
                            {product.productItem.type === ProductItemTypeEnum.REGULAR
                            && (product.productItem.subType === ProductItemSubTypeEnum.GIFT || product.productItem.subType === ProductItemSubTypeEnum.PROMO)
                            && !product.productItem.mandatoryGift && (
                                <CounterInput
                                    onAdd={product.addAmountPromotionBuilder}
                                    onReduce={product.reduceAmountPromotionBuilder}
                                    onInputChange={product.setAmountPromotionBuilder}
                                    quantity={String(product.quantity)}
                                />
                            )}
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                        >
                            <div className="currentProducts__table-text">
                                {product.productItem.type === ProductItemTypeEnum.REGULAR
                                && product.productItem.subType !== ProductItemSubTypeEnum.GIFT
                                && product.productItem.price}
                            </div>
                        </div>
                        <div className="currentProducts__table-col currentProducts__table-col-1">
                            <div
                                data-cy="total-cost"
                                className="currentProducts__table-text currentProducts__table-text--bold"
                            >
                                {product.productItem.type === ProductItemTypeEnum.REGULAR
                                && product.productItem.subType !== ProductItemSubTypeEnum.GIFT
                                && product.totalPrice}
                            </div>
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2"
                        >
                            <div className="currentProducts__actions">
                                {product.productItem.type === ProductItemTypeEnum.REGULAR
                                && (product.productItem.subType === ProductItemSubTypeEnum.GIFT && !product.productItem.mandatoryGift
                                    || product.productItem.subType === ProductItemSubTypeEnum.PROMO && !product.mandatoryPromo)
                                && (
                                    <NomenclatureNavigationBar
                                        isDelete
                                        onChange={(event) => eventHandlerFromNomenclatureNavigationBar(
                                            event,
                                            product,
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </>
    );
};


export default observer(ListOfNomenclatures);
