import React, {
    FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import FlagIconCss from '@/plugins/FlagIconCss';
import LogoutIcon from '@images/sidebar/Logout.svg';
import LogoIcon from '@images/logo_brown.png';
import SideElement from '@components/main/sidebar/side-element/SideElement';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import LanguageList from '@components/main/sidebar/lang-list/langList';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import SidebarService, { IDataLink } from '@core/services/SidebarService';
import SideBarDeepStatusScreenMenu
    from '@components/main/sidebar/includes/SideBarDeepStatusScreenMenu/SideBarDeepStatusScreenMenu';
import SideBarDeepLanguageChangeScreenMenu
    from '@components/main/sidebar/includes/SideBarDeepLanguageChangeScreenMenu/SideBarDeepLanguageChangeScreenMenu';
import StatusList from '@components/main/sidebar/status-list/StatusList';
import ModalService from '@core/services/ModalService';
import { ConfirmModalType } from '@core/models/ModalWindow';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { UserService } from '@services/index';

import ArrowRightSvg from './includes/icons/ArrowRightSvg';
import CookieService from '@/app/core/services/CookiesService';

import './includes/Sidebar.scss';


interface ISidebarProps {
    children?: JSX.Element[];
    show: boolean;
}

const DEFAULT_LANGUAGE = {
    icon: 'flag-icon-gb',
    name: 'English',
};

const Sidebar: FC<ISidebarProps> = ({
    children,
    show = false,
}): JSX.Element => {
    const [
        {
            currentUser: {
                isReady: isReadyToAcceptOrder,
                unreadyAfterSave,
                customerService,
                mode,
                selectedAuxStatus,
            },
            ui: {
                bottomMobileTab: {
                    activeBottomTab,
                },
                sidebar: {
                    setStatusVisible,
                    setLanguagesVisible,
                },
            },
            responsive: {
                mobile,
            },
        },
        {
            t,
            selectedLanguage,
        },
        {
            dataLink,
        },
        modalService,
        userService,
        cookieService,
    ] = useInstances(
        Store,
        I18NService,
        SidebarService,
        ModalService,
        UserService,
        CookieService,
    );

    const [selectedLangCode, setSelectedLangCode] = useState<string>(() => DEFAULT_LANGUAGE.icon);
    const [iconTitle, setIconTitle] = useState<string>(() => DEFAULT_LANGUAGE.name);

    // const [isOldFormHybridMode] = useState<boolean>(
    //     () => getIsOldFormHybridMode(),
    // );

    // const avatarImage = useMemo<string>(
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    //     () => (profileImageFile as string) || AvatarIcon,
    //     [profileImageFile],
    // );

    const visibleOnMobile = useMemo<boolean>(
        () => activeBottomTab === BottomTabEnum.MENU,
        [activeBottomTab],
    );

    useEffect(() => {
        if (selectedLanguage) {
            const { icon = DEFAULT_LANGUAGE.icon, name = DEFAULT_LANGUAGE.name } = selectedLanguage;

            setSelectedLangCode(icon);
            setIconTitle(name);
        }
    }, [selectedLanguage]);

    const getStatusText: () => string = () => {

        if (selectedAuxStatus) {
            return selectedAuxStatus.name;
        }

        if (isReadyToAcceptOrder && unreadyAfterSave) {
            if (mode === UserModeEnum.CLIENT_SERVICE) {
                return t('Не готов после звонка', 'Unready after call');
            }

            return t('Не готов после сохранения', 'Unready after saving');
        }

        if (isReadyToAcceptOrder) {
            return t('Готов для звонка', 'Active to call');
        }

        return t('Не готов для звонка', 'Unready to call');
    };

    const showLanguages = useCallback((event: React.MouseEvent) => {
        if (mobile) {
            event.preventDefault();
            event.stopPropagation();
            setLanguagesVisible(true);
        }
    }, [setLanguagesVisible, mobile]);

    const showStatus = useCallback((event: React.MouseEvent) => {
        if (mobile) {
            event.preventDefault();
            event.stopPropagation();
            setStatusVisible(true);
        }
    }, [setStatusVisible, mobile]);

    const onLogoutClick = async () => {
        const text = t('Вы действительно хотите выйти?', 'Are you sure you want to logout?');
        const confirm = await modalService.showConfirmModal(text, ConfirmModalType.YesCancel);

        if (confirm) {
            cookieService.clearCallibriCookies();
            userService.clearCallibriTempraryData();
            userService.signOut();
        }
    };

    return (
        <>
            {show && (
                <>
                    <div className={cn('sidebar__wrapper', visibleOnMobile && 'visible-on-mobile')}>
                        <div className="b-side-panel">
                            <div className="panel-menu">
                                <div className="logo-wrap">
                                    <div className="logo">
                                        <img src={LogoIcon} className="logo-image" alt={t('ЛОГОТИП', 'LOGO')} />
                                    </div>
                                </div>
                                <div className="main-links">
                                    {
                                        dataLink.map((link: IDataLink) => {
                                            if (
                                                (link.onlyCustomerService && !customerService)
                                                || (link.hideOnCustomerService && customerService)
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <SideElement
                                                    key={link.textLink}
                                                    toRoute={link.toRoute}
                                                    textLink={link.textLink}
                                                    toURL={link.toURL}
                                                    inNewWindow={customerService && link.inNewWindow ? false : link.inNewWindow}
                                                >
                                                    <img src={link.icon} alt={link.textLink} className="menu-item-icon" />
                                                </SideElement>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className="panel-menu-bottom">
                                <SideElement
                                    classWrapper="menu-item smartReadySwitcher"
                                    textLink={getStatusText()}
                                    iconSvg={<ArrowRightSvg />}
                                    onClick={showStatus}
                                >
                                    <>
                                        {selectedAuxStatus?.icon ?
                                            <img src={selectedAuxStatus.icon} alt="aux status" className="status-icon"/> :
                                            <div
                                                className={cn(
                                                    'status-icon',
                                                    !isReadyToAcceptOrder && 'un-ready',
                                                    isReadyToAcceptOrder && unreadyAfterSave && 'unready-after-save',
                                                    isReadyToAcceptOrder && !unreadyAfterSave && 'ready',
                                                )}
                                            />
                                        }
                                        <StatusList />
                                    </>
                                </SideElement>
                                <SideElement
                                    classWrapper="sideElement__langList"
                                    textLink={iconTitle}
                                    iconSvg={<ArrowRightSvg />}
                                    onClick={showLanguages}
                                >
                                    {selectedLangCode.length > 0 && (
                                        <>
                                            <FlagIconCss
                                                className="langIcon"
                                                icon={selectedLangCode}
                                                squared={true}
                                            />
                                            <LanguageList />
                                        </>
                                    )}
                                </SideElement>
                                {/*<SideElement*/}
                                {/*    toRoute={!isOldFormHybridMode && Locations.PROFILE}*/}
                                {/*    toURL={isOldFormHybridMode && '/profile/control'}*/}
                                {/*    inNewWindow={isOldFormHybridMode}*/}
                                {/*    textLink={t('ПРОФИЛЬ', 'PROFILE')}*/}
                                {/*    classWrapper="menu-item menu-item-avatar"*/}
                                {/*>*/}
                                {/*    <div className="avatar">*/}
                                {/*        <img src={avatarImage} alt={t('Аватар', 'Avatar')} className="avatar-image" />*/}
                                {/*    </div>*/}
                                {/*</SideElement>*/}
                                <SideElement
                                    textLink={t('ВЫЙТИ', 'LOGOUT')}
                                    classWrapper="menu-item menu-item-logout"
                                    onClick={onLogoutClick}
                                >
                                    <img src={LogoutIcon} alt={t('ВЫЙТИ', 'LOGOUT')} className="menu-item-icon" />
                                </SideElement>
                            </div>
                        </div>
                    </div>
                    <SideBarDeepStatusScreenMenu />
                    <SideBarDeepLanguageChangeScreenMenu />
                </>
            )}
            {children}
        </>
    );
};


export default observer(Sidebar);
