import React, { FC, useCallback, useState } from 'react';

import './CallPanelCommunicationQualityScreen.scss';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { OrderService, SipService } from '@/app/services';
import { ReasonsForEndingConversation } from '@services/sip/models';
import { ICommunicationsQualityModel } from '@models/mobx-state-tree/communicationsQuality.model';
import Button from '@UIElements/Button';


const CallPanelCommunicationQualityScreen: FC = () => {
    const [
        { t },
        store,
        sipService,
        orderService,
    ] = useInstances(
        I18NService,
        Store,
        SipService,
        OrderService,
    );

    const [selectedCQ, setSelectedCQ] = useState<string[]>([]);

    const {
        communicationsQualityValues,
    } = store.currentOrder;

    const {
        setCommunicationQualityVisible,
        communicationQualityVisible,
    } = store.ui.sidebar;

    const continuE = (): void => {
        if (!selectedCQ.length) {
            return;
        }

        setCommunicationQualityVisible(false);
        void orderService.saveCommunicationQuality(selectedCQ);
    };

    const abort = (): void => {
        if (!selectedCQ.length) {
            return;
        }

        const { lastCallHistoryEvent } = store.currentOrder;

        const now = new Date();
        if (lastCallHistoryEvent) {
            const timeAbort = Math.floor((now.getTime() - lastCallHistoryEvent.startTime) / 1000);

            lastCallHistoryEvent.setIsAbort(true);
            lastCallHistoryEvent.setTimeAbort(timeAbort);
            lastCallHistoryEvent.setEndCall(ReasonsForEndingConversation.POOR_CONNECTION_QUALITY);
        }

        setCommunicationQualityVisible(false);
        sipService.endCall();
        void orderService.saveCommunicationQuality(selectedCQ);
    };

    const toggleCQ = (cqId: string): void => {
        const alreadySelected = selectedCQ.includes(cqId);

        if (alreadySelected) {
            setSelectedCQ([]);
        } else {
            setSelectedCQ([cqId]);
        }
    };

    const onContinue = useCallback(
        () => continuE(),
        [selectedCQ],
    );

    const onAbort = useCallback(
        () => abort(),
        [selectedCQ],
    );

    const onToggleCQ = useCallback(
        (cqId: string) => toggleCQ(cqId),
        [selectedCQ],
    );

    const onBackButtonClick = useCallback(() => {
        setCommunicationQualityVisible(false);
    }, [setCommunicationQualityVisible]);

    return (
        <div className={cn('CallPanelCommunicationQualityScreen', communicationQualityVisible && 'visible')}>
            <div className="header">
                <div className="arrow-back" onClick={onBackButtonClick} />
                <div className="text">
                    {t('Проблема с CRM', 'Problem with CRM')}
                </div>
            </div>
            <div className="CallPanelCommunicationQualityScreen__block">
                <div className="CallPanelCommunicationQualityScreen__desc">
                    {t(
                        'Пожалуйста, оцените нашу поддержку о проблемах',
                        'Please rate talk our support about problems',
                    )}
                </div>
                <div className="CallPanelCommunicationQualityScreen__items">
                    {communicationsQualityValues.map((item: ICommunicationsQualityModel) => (
                        <div key={item.id} className="CallPanelCommunicationQualityScreen__item">
                            <label htmlFor={`cq-${item.id}`}>
                                <div className="cq_checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedCQ.includes(item.id)}
                                        value={item.id}
                                        id={`cq-${item.id}`}
                                        onChange={() => onToggleCQ(item.id)}
                                    />
                                </div>
                                <div className="cq_name">{t(item.question || '')}</div>
                            </label>
                        </div>
                    ))}
                </div>
                <div className="CallPanelCommunicationQualityScreen__bottom">
                    <Button
                        variant="7"
                        size="1"
                        text={t('Продолжить', 'Continue')}
                        width="100px"
                        onClick={onContinue}
                    />
                    <Button
                        variant="5"
                        size="1"
                        text={t('Прервать', 'Abort')}
                        width="100px"
                        onClick={onAbort}
                    />
                    <Button
                        variant="2"
                        size="1"
                        text={t('Отмена', 'Cancel')}
                        width="100px"
                        onClick={onBackButtonClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(CallPanelCommunicationQualityScreen);
