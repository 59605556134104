class XHR400Error extends Error {
    constructor(message?: string) {
        super();

        this.message = message || '';
    }
}


export default XHR400Error;
