import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import concat from 'lodash/concat';
import {
    Instance, types as t, SnapshotOut, SnapshotIn, IAnyModelType, getEnv,
} from 'mobx-state-tree';
import { ChangeEvent } from 'react';

import { ISelectValueExtended } from '@UIElements/SelectDummy/SelectDummy';

import { IEnvCustomerStore } from '@store/customerModeStore';
import { CustomerServiceProductModel, ICustomerServiceProductModel } from '../customerServiceProductModel.model';
import { ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';


export interface IWithPossibleImageAndType {
    image: string | null;
    type: ProductItemTypeEnum;
}

const ALL_FILTER = 'ALL_FILTER';

export const OrderSearchFilter = t
    .model('OrderSearchFilter', {
        _phoneNumber: t.optional(t.string, ''),
        _clientName: t.optional(t.string, ''),
        _orderId: t.optional(t.string, ''),
        _products: t.map(t.reference(t.late((): IAnyModelType => CustomerServiceProductModel))), // значения для фильтра
        _selectedProduct: t.maybe(t.reference(t.late((): IAnyModelType => CustomerServiceProductModel))), // ID модели выбранного товара
    })
    .views((self) => ({
        listForView(array: ICustomerServiceProductModel[]): ISelectValueExtended<IWithPossibleImageAndType>[] {
            // Товары унифицируем по имени
            const uniqArray = uniqBy<ICustomerServiceProductModel>(
                array,
                (item) => item.name,
            );

            return map<ICustomerServiceProductModel, ISelectValueExtended<IWithPossibleImageAndType>>(
                uniqArray,
                (item) => ({
                    label: item.name,
                    id: item.id,
                    image: item.image,
                    type: item.type,
                    identifyProp: item.id === ALL_FILTER ? '1' : item.name,
                }),
            );
        },

        getAllFilterObj() {
            const { I18NService: { t } } = getEnv<IEnvCustomerStore>(self);

            return {
                label: ALL_FILTER,
                id: ALL_FILTER,
                type: ALL_FILTER,
                name: t('Все товары', 'All products'),
                image: null,
            };
        },
    }))
    .views((self) => ({
        get productListForView(): ISelectValueExtended<IWithPossibleImageAndType>[] {
            const {
                _products,
                getAllFilterObj,
                listForView,
            } = self;

            return listForView(
                concat([..._products.values()], [getAllFilterObj()]),
            );
        },

        get selectedProduct(): string {
            return self._selectedProduct?.name as string || '';
        },
    }))
    .actions((self) => ({
        /**
         * @param value - id товара
         */
        setSelectedProduct(value: string | null): void {
            if (value === ALL_FILTER || !value) {
                self._selectedProduct = undefined;

                return;
            }

            self._selectedProduct = value;
        },

        onChangePhoneNumber(event: ChangeEvent<HTMLInputElement>): void {
            self._phoneNumber = event.target.value;
        },

        onChangeClientName(event: ChangeEvent<HTMLInputElement>): void {
            self._clientName = event.target.value;
        },

        onChangeOrderId(event: ChangeEvent<HTMLInputElement>): void {
            self._orderId = event.target.value;
        },

        clearOrdersFilter(): void {
            self._selectedProduct = undefined;
            self._clientName = '';
            self._orderId = '';
            self._phoneNumber = '';
        },
    }));


export interface IOrderSearchFilterModel extends Instance<typeof OrderSearchFilter> {}
export interface IOrderSearchFilterSnapshotIn extends SnapshotIn<typeof OrderSearchFilter> {}
export interface IOrderSearchFilterSnapshotOut extends SnapshotOut<typeof OrderSearchFilter> {}
