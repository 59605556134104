import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class MaxGiftsCountError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'Количество позиций подарков на выбор в заказе превышает лимит, который задан в настройках акции.';
    }
}


export default MaxGiftsCountError;
