import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export const VerifierProductsModel = t
    .model('VerifierProducts', {
        id: t.identifier,
        gift: t.maybeNull(t.boolean),
        promo: t.maybeNull(t.boolean),
        name: t.maybeNull(t.string),
    });


export interface IVerifierProductsModel extends Instance<typeof VerifierProductsModel> {}
export interface IVerifierProductsModelSnapshotIn extends SnapshotIn<typeof VerifierProductsModel> {}
export interface IVerifierProductsModelSnapshotOut extends SnapshotOut<typeof VerifierProductsModel> {}