import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import cn from 'classnames';
import './CreateClientCardForm.scss';
import { CustomerModeStore } from '@/app/store/customerModeStore';
import InputDummy from '@/app/ui-elements/InputDummy';
import PersonSVG from '@/app/components/resources/SVG/Person/PersonSVG';
import PhoneSVG from '@/app/components/resources/SVG/Phone/PhoneSVG';
import I18NService from '@services/I18NService';
import Button from '@/app/ui-elements/Button';
import { Transition } from 'react-transition-group';
import { useCreateClientCard } from './helpers/useCreateClientCard';

const CreateClientCardForm: FunctionComponent = (): JSX.Element => {
    const [customerMode, { t }] = useInstances(CustomerModeStore, I18NService);
    const {
        store,
        errorMessageWhileCreatingClientCard,
        isErrorWhileCreatingClientCard,
    } = customerMode;
    const { customerPhone } = store;
    const [onChangeName, name, setName, isFormReadyToBeSent, createClientCard, setPhone] = useCreateClientCard();

    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        setPhone(customerPhone);
    }, [customerPhone]);

    useEffect(() => {
        if (isErrorWhileCreatingClientCard) {
            // сбрасываем инпут с именем, показываем ошибку
            setIsError(true);
            setName('');

            setTimeout(() => {
                setIsError(false); // через 5 сек ошибку прячем
            }, 5000);
        }
    }, [isErrorWhileCreatingClientCard]);

    return (
        <div
            className="customer_info_wrapper"
        >
            <div className="headline">
                {t('Заполните информацию о клиенте для создания клиентской карточки', 'Fill in customer information to create client card')}
            </div>
            <form className="client_card_form">
                <div className="customer_info_block">
                    <div className="customer_info_item">
                        <InputDummy
                            disabled={false}
                            inputId="CreateClientCard_customer-name"
                            type="text"
                            invalid={false}
                            validationMessage=""
                            label={t('Имя киента', 'Customer name')}
                            hasIcon={true}
                            value={name}
                            onChange={onChangeName}
                        >
                            <PersonSVG />
                        </InputDummy>
                    </div>
                    <div className="customer_info_item">
                        <InputDummy
                            disabled={true}
                            inputId="CreateClientCard_customer-phone"
                            type="tel"
                            invalid={false}
                            validationMessage=""
                            label={t('Телефон клиента', 'Customer phone')}
                            hasIcon={true}
                            value={customerPhone}
                        >
                            <PhoneSVG />
                        </InputDummy>
                    </div>
                </div>
                <div className="customer_info_block">
                    <Button
                        className="button__submit"
                        variant="7"
                        size="1"
                        width="150px"
                        text={t('Создать', 'Create')}
                        onClick={createClientCard}
                        disabled={!isFormReadyToBeSent}
                    />
                </div>
                <Transition
                    in={isError}
                    timeout={100}
                    mountOnEnter
                    unmountOnExit
                >
                    {(state): JSX.Element => (
                        <div
                            className={cn([state, 'errorMessage'])}
                        >
                            <p>
                                {errorMessageWhileCreatingClientCard}
                            </p>
                            <p>
                                {t('Пожалуйста, попробуйте еще раз или обратитесь в поддержку', 'Please try again or contact support')}
                            </p>
                        </div>
                    )}
                </Transition>
            </form>
        </div>
    );
};

export default observer(CreateClientCardForm);
