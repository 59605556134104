import { IOperatorInactivityNotificationTimersModelSnapshotIn } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import { ICurrentOrderSnapshotIn } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModelSnapshotIn } from '@models/mobx-state-tree/user.model';
import { IRootUIStateModelSnapshotIn } from '@models/mobx-state-tree/ui/RootUIStateModel';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';
import { IFormModelSnapshotIn } from '@models/mobx-state-tree/form.model';
import { ICustomerServiceStoreSnapshotIn } from '@models/mobx-state-tree/customer-mode/customerService.model';
import { ICustomerServiceClientCardSnapshotIn } from '@models/mobx-state-tree/customer-mode/customerServiceClientCard.model';
import { IOrderSearchFilterSnapshotIn } from '@models/mobx-state-tree/customer-mode/orderSearchFilter.model';
import { CustomerServiceOrderInformationTabs, IOrderInformationTabsSnapshotIn } from '@models/mobx-state-tree/customer-mode/orderInformationTabs.model';
import { ICurrentCustomerTabsSnapshotIn, ITabModelSnapshotIn } from '@models/mobx-state-tree/customer-mode/currentCustomerTabs.model';
import { IAppealSubjectSnapshotIn, ICreateAppealSnapshotIn } from '@models/mobx-state-tree/customer-mode/createAppeal.model';
import { IOrderCommentsAndSmsTabsSnapshotIn, EnumOrderCommentsAndSmsTabs } from '@models/mobx-state-tree/ui/orderCommentsAndSmsTabsModel';
import { IOffersBlockTabsSnapshotIn } from '@models/mobx-state-tree/ui/offersBlockTabsModel';
import { AddModeEnum } from '@services/order/OrderService';
import { ICallAutomatizationModelSnapshotIn } from '@models/mobx-state-tree/callAutomatization.model';
import { ComparisonOperatorsOfDeliveryEnum, IDeliveryConditionsModelSnapshotIn } from '@models/mobx-state-tree/newModels/promotionBuilderDeliveryConditions.model';

export const DEFAULT_ORDER_FORM: IFormModelSnapshotIn = {
    id: 'form-0',
};

export const DEFAULT_CURRENT_ORDER: ICurrentOrderSnapshotIn = {
    id: 0,
    form: DEFAULT_ORDER_FORM,
    countryId: 0,
    partnerId: 0,
};

export const DEFAULT_INACTIVITY_TIMER: IOperatorInactivityNotificationTimersModelSnapshotIn = {
    orderId: 0,
    userId: 0,
};

export const DEFAULT_USER: IUserModelSnapshotIn = {
    id: 0,
    username: 'Guest',
};

export const DEFAULT_UI_STATE: IRootUIStateModelSnapshotIn = {
    orderPage: {
        fabVisible: true,
        callPanelVisible: false,
        notificationHiddenOnScroll: false,
    },
    bottomMobileTab: {
        activeBottomTab: BottomTabEnum.ORDER,
    },
    sidebar: {
        languagesVisible: false,
        statusVisible: false,
        communicationQualityVisible: false,
    },
};

export const DEFAULT_CUSTOMER_SERVICE_STORE: ICustomerServiceStoreSnapshotIn = {
    customerOrderId: '',
    customerName: '',
    customerPhone: '',
    depth: 31,
    limit: 15,
    isLoading: false,
    sipHost: null,
};

export const DEFAULT_CUSTOMER_SERVICE_CLIENT_CARD: ICustomerServiceClientCardSnapshotIn = {
    customerPhone: '',
    customerAddress: '',
    customerName: '',
    createIfNotExist: false,
    isLoading: false,
    appeals: [],
};

export const DEFAULT_CUSTOMER_SERVICE_ORDER_FILTER: IOrderSearchFilterSnapshotIn = {
    _clientName: '',
    _orderId: '',
    _phoneNumber: '',
    _selectedProduct: undefined,
    _products: {},
};

export const DEFAULT_CLIENT_TABS: IOrderInformationTabsSnapshotIn = {
    activeTab: CustomerServiceOrderInformationTabs.ORDERS,
};

export const DEFAULT_COMMENT_AND_SMS_TAB: IOrderCommentsAndSmsTabsSnapshotIn = {
    activeTab: EnumOrderCommentsAndSmsTabs.COMMENTS,
};

export const DEFAULT_OFFERS_BLOCK_TAB: IOffersBlockTabsSnapshotIn = {
    activeTab: AddModeEnum.ALL,
};

export const DEFAULT_TAB: ITabModelSnapshotIn = {
    isPossibleRemove: false,
    label: '',
    id: 'CURRENT_CUSTOMER',
};

export const DEFAULT_CLIENT_SERVICE_CURRENT_CUSTOMER_TABS: ICurrentCustomerTabsSnapshotIn = {
    tabs: [DEFAULT_TAB],
};

const CLIENT_SERVICE_SUBJECT_OPTION_OTHER: IAppealSubjectSnapshotIn = {
    label: '',
    id: 'OTHER',
};

export const DEFAULT_CUSTOMER_SERVICE_CREATE_APPEAL: ICreateAppealSnapshotIn = {
    id: 'CreateAppeal',
    appealSubjectList: [CLIENT_SERVICE_SUBJECT_OPTION_OTHER],
};

export const DEFAULT_AUTOCALL_STATE: ICallAutomatizationModelSnapshotIn = {
    numberOfCallsMade: 0,
    automaticCallsAmount: 1,
    numberOfCallsMadeForCounterInUI: 0,
    isCustomerAnswered: false,
    phoneNumberNotFound: false,
};

export const DEFAULT_DELIVERY_CONDITIONS_PROMOTION_BUILDER: IDeliveryConditionsModelSnapshotIn = {
    id: 'DeliveryConditionsPromotionBuilder',
    useDefaultShippingPrice: true,
    shippingPrice: 0,
    countWithPromo: false,
    amountOfProducts: 0,
    condition: ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY,
};
