import React, { FunctionComponent, useState } from 'react';
import { block } from 'bem-cn';

import './tooltip.scss';

interface ITooltipProps {
    children?: any;
    style?: any;
    tooltipContent?: any;
}

const Tooltip: FunctionComponent<ITooltipProps> = (props) => {

    const { children, style, tooltipContent } = props;

    const [showTooltip, setShowTooltip] = useState(false);

    const b = block('tooltip');

    const tooltipStyle = {
        ...style,
    };

    return (
        <div className={b()}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {
                showTooltip
                    &&
                    <div
                        className={b('wrapper')}
                        style={tooltipStyle}
                    >
                        {tooltipContent}
                    </div>
            }
        </div>
    );
};

export default Tooltip;