import { AddModeEnum } from '@services/order/OrderService';
import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';

// enum AddModeEnum {
//     main = 'main',
//     gift = 'gift',
//     promo = 'promo',
//     sets = 'sets',
//     OLD_PROMOTIONS = 'OLD_PROMOTIONS',
//     ALL = 'ALL',
// }

export const tabOptions: AddModeEnum[] = [
    AddModeEnum.ALL,
    AddModeEnum.PROMOTIONS,
    AddModeEnum.gift,
    AddModeEnum.promo,
];

export const OffersBlockTabs = t
    .model('OffersBlockTabs', {
        activeTab: t.optional(t.enumeration(tabOptions), AddModeEnum.ALL),
        isSearchModeOn: t.optional(t.boolean, false),
        searchQuery: t.maybeNull(t.string),
        isCollapsed: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get searchQueryForAFilter() {
            return self.searchQuery;
        },
    }))
    .actions((self) => ({
        toggleCollapsed(): void {
            self.isCollapsed = !self.isCollapsed;
        },
        setIsCollapsed(value: boolean): void {
            self.isCollapsed = value;
        },
        resetDisplayStatusOfBonusItems(): void {
            self.activeTab = AddModeEnum.ALL;
        },
        setDisplayStatusOfBonusItems(data: AddModeEnum): void {
            self.activeTab = data;
        },
        setSearchQuery(value: string) {
            self.searchQuery = value;
        },
        setSearchMode(value: boolean) {
            self.isSearchModeOn = value;
        },
    }));


export interface IOffersBlockTabsModel extends Instance<typeof OffersBlockTabs> {}
export interface IOffersBlockTabsSnapshotIn extends SnapshotIn<typeof OffersBlockTabs> {}
export interface IOffersBlockTabsSnapshotOut extends SnapshotOut<typeof OffersBlockTabs> {}
