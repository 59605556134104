import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { IRecallProductsModel, RecallProductsModel } from '@models/mobx-state-tree/recallsProducts.model';

export const RecallModel = t
    .model('Recall', {
        customerFullName: t.maybeNull(t.string),
        id: t.integer,
        recallDate: t.maybeNull(t.string),
        recallDateByCallCenter: t.maybeNull(t.string),
        recallDateUpdate: t.maybeNull(t.string),
        phone: t.maybeNull(t.string),
        comment: t.maybeNull(t.string),
        createdDate: t.maybeNull(t.number),
        orderProducts: t.maybeNull(t.map(RecallProductsModel)),
        foreignId: t.maybeNull(t.integer),
        partnerName: t.string,
        countryName: t.string,
        countryFlag: t.maybeNull(t.string),
        maskPhones: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get recallProducts(): IRecallProductsModel[] | null {
            return self.orderProducts ? [...self.orderProducts.values()].reverse() : null;
        },
    }))
;


export interface IRecallModel extends Instance<typeof RecallModel> {}
export interface IRecallModelSnapshotIn extends SnapshotIn<typeof RecallModel> {}
export interface IRecallModelSnapshotOut extends SnapshotOut<typeof RecallModel> {}