import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface ICheckSucceedIcon {
    className?: string;
}


const CheckSucceedIcon: FunctionComponent<ICheckSucceedIcon> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['CheckSucceedIcon', className])}
        {...props}
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            y="0.25"
            width="28"
            height="28"
            rx="14"
            fill="#34C759"
        />
        <path
            d="M9.13807 13.4453C8.87772 13.1849 8.45561 13.1849 8.19526 13.4453C7.93491 13.7056 7.93491 14.1277 8.19526 14.3881L11.5286 17.7214C11.7889 17.9818 12.2111 17.9818 12.4714 17.7214L19.8047 10.3881C20.0651 10.1277 20.0651 9.70561 19.8047 9.44526C19.5444 9.18491 19.1223 9.18491 18.8619 9.44526L12 16.3072L9.13807 13.4453Z"
            fill="white"
        />
    </svg>

);


export default CheckSucceedIcon;
