import React, { FunctionComponent, useCallback } from 'react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { observer } from 'mobx-react';

import SwapArrowsIcon from '@components/resources/SVG/Swap/SwapArrowsIcon';
import I18NService from '@services/I18NService';
import { ReplaceTransactionTypeEnum } from '@/app/models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import OrderService from '@services/order/OrderService';


interface ISwapItemProps {
    readonly className?: string;
    readonly id: string;
    readonly type: ReplaceTransactionTypeEnum;
    readonly parentProductId?: string; // передаем в случае, если заменяем товар в составе набора
}


const SwapItemButton: FunctionComponent<ISwapItemProps> = ({
    className,
    id,
    type,
    parentProductId,
}) => {
    const [
        orderService,
        { t },
    ] = useInstances(
        OrderService,
        I18NService,
    );

    const handleCreateReplaceTransaction = useCallback(
        () => {
            if (parentProductId) {
                orderService.createReplaceTransaction(id, type, parentProductId);
            } else {
                orderService.createReplaceTransaction(id, type);
            }
        },
        [id, type, parentProductId],
    );

    return (
        <div
            className={cn('swapItemButton', className)}
            title={t('Заменить', 'Swap')}
            onClick={handleCreateReplaceTransaction}
            data-cy="swap_item_button"
        >
            <SwapArrowsIcon />
        </div>
    );
};


export default observer(SwapItemButton);
