import get from 'lodash/get';

import ApiBase from '@core/api';
import { getEnvAppointmentOperator, getEnvGetRecallsEndpoint } from '@/env/requestEndpoints';
import { TRecall, TRecallAppointment } from '@/app/core/api/recalls-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}

class RecallsApiService {
    @Inject apiBase: ApiBase;

    public async getRecalls(): Promise<TRecall[] | never> {

        const response = await this.apiBase.get<IWithResponse<TRecall[]>>(getEnvGetRecallsEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as TRecall[] | never;
    }

    public async appointmentOperator(orderId: number | string, userId: number): Promise<boolean> {
        const dataToSend = {
            order_id: orderId,
            user_id: userId,
        };

        const response = await this.apiBase.post<IWithResponse<TRecallAppointment>>(getEnvAppointmentOperator(), dataToSend);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }
}


export default RecallsApiService;
