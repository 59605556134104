class NoAccessTokenInRequestHeaderError extends Error {
    constructor(url: string) {
        super();

        this.message = `The request is stopped. There is no access token in the header. Url: ${url}`;
    }
}


export default NoAccessTokenInRequestHeaderError;
