import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import './includes/Recalls.scss';
import { Locations } from '@/app/core/models/locations';
import TranslationService from '@services/I18NService';
import RecallsService, { FetchStatusEnum } from '@services/RecallsService';
import Spinner from '@/app/ui-elements/Spinner';
import { IRecallModel } from '@/app/models/mobx-state-tree/recalls.model';
import { Store } from '@/app/store/store';
import { OrderFetchService } from '@/app/services';


const RecallsMobileComponent: FunctionComponent = (): JSX.Element => {
    const [
        { t },
        {
            fetchRecallsList,
            fetchStatus,
            recallsList,
            editOrder,
        },
        { currentUser : { id : userId } },
        orderFetchService,
    ] = useInstances(
        TranslationService,
        RecallsService,
        Store,
        OrderFetchService,
    );

    const [isLoader, setIsLoader] = useState<boolean>(false);

    useEffect(() => {
        if (fetchStatus === FetchStatusEnum.NONE || fetchStatus === FetchStatusEnum.PENDING) {
            setIsLoader(true);
        }

        if (fetchStatus === FetchStatusEnum.SUCCESS) {
            setIsLoader(false);
        }

        if (fetchStatus === FetchStatusEnum.NONE) {
            void fetchRecallsList();
        }
    }, [isLoader, setIsLoader, fetchStatus]);

    let mobileCols: JSXElement[] = [];
    const navigate = useNavigate();

    const [actionsModalVisible, setActionsModalVisible] = useState<boolean>(false);
    const [currentOrderId, setCurrentOrderId] = useState<number>(NaN);
    const [fetchResult, setFetchResult] = useState<boolean>(false);


    const handleViewClick = (orderId: number | string) => {
        navigate(`${Locations.ORDER_VIEW}/${orderId}`);
    };

    const handleEditClick = (orderId: number | string) => {console.log('edit order ', orderId);};

    const handleSelectClick = (orderId: number) => {
        setCurrentOrderId(orderId);
        setActionsModalVisible(true);
    };
    useEffect(() => {
        if (currentOrderId){
            void editOrder(currentOrderId, userId)
                .then(data => setFetchResult(data));

        } if (fetchResult) {
            navigate(Locations.MAIN);
            void orderFetchService.fetchOrderView(currentOrderId);
            setFetchResult(false);
            setCurrentOrderId(NaN);
        }
        return;
    }, [currentOrderId, fetchResult]);

    const handleCancelClick = () => {
        setCurrentOrderId(NaN);
        setActionsModalVisible(false);
    };

    if (recallsList.length > 0) {
        mobileCols = recallsList.map((item: IRecallModel) => (
            <div className="recall-col">
                <div className="row-id">
                    {item.id ? item.id.toString() : '-'}
                </div>
                <div className="row-partner">
                    {item.partnerName ? item.partnerName : '-'}
                </div>
                <div className="row-id-by-partner">
                    {item.foreignId ? item.foreignId.toString() : '-'}
                </div>
                <div className="row-country">
                    {item.countryName ? (
                        <div className="recall-item-country-name">
                            {item.countryName}
                        </div>
                    ) : '-'}
                </div>
                <div className="row-customer-name">
                    {item.customerFullName ? item.customerFullName : '-'}
                </div>
                <div className="row-action">
                    <button className="action-button" onClick={() => handleSelectClick(item.id)}>
                        {t('Выбрать', 'Choose')}
                    </button>
                </div>
            </div>
        ));
    }

    interface IActionsModalProps {
        visible: boolean;
        orderId: string | number;
    }
    const ActionsModal: FunctionComponent<IActionsModalProps> = ({ visible, orderId }) =>
        <div
            className={cn([
                'actions-modal',
                visible && 'visible'])}
            onClick={() => {handleCancelClick();}}
        >
            <div className="actions-container">
                <div className="actions-list">
                    <button className="action-button" onClick={() => handleViewClick(orderId)}>
                        {t('Просмотреть', 'View')}
                    </button>
                    <button className="action-button" onClick={() => handleEditClick(orderId)}>
                        {t('Редактирование', 'Edit')}
                    </button>
                </div>
                <div className="cancel-button" onClick={() => handleCancelClick()}>
                    {t('Отмена', 'Cancel')}
                </div>
            </div>
        </div>;

    return (
        <div>
            {isLoader && (
                <Spinner className="preloader" text={t('Загрузка...', 'Loading...')} />
            )}
            {!isLoader && (
                <>
                    <div className="mobile-recalls-table">
                        <div className="header-col">
                            <div className="row-id">
                                ID
                            </div>
                            <div className="row-partner">
                                {t('Партнер', 'Partner')}
                            </div>
                            <div className="row-id-by-partner">
                                {t('ID партнера', 'ID by partner')}
                            </div>
                            <div className="row-country">
                                {t('Страна', 'Country')}
                            </div>
                            <div className="row-customer-name">
                                {t('Имя покупателя', 'Customer name')}
                            </div>
                            <div className="row-action">
                                {t('Действия', 'Actions')}
                            </div>
                        </div>
                        {mobileCols ? mobileCols : ''}
                        <ActionsModal visible={actionsModalVisible} orderId={currentOrderId} />
                    </div>
                    {recallsList.length === 0 && (
                        <div className="no-orders-message">
                            {t('Нет заказов', 'No orders')}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default observer(RecallsMobileComponent);
