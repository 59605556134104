import React, {
    FunctionComponent, useCallback,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';

import ProductsService from '@services/order/ProductsService';
import OldPromotionService from '@services/order/OldPromotionService';
import NomenclatureNavigationBar from '@UIElements/nomenclature-navigation-bar/NomenclatureNavigationBar';


type TProductRowNavPanel = {
    readonly basket: IBasketItemModel;
    readonly isShownForPreview: boolean;
    handleRemoveBasketItem(id?: string): void;
};


const ProductRowNavPanel: FunctionComponent<TProductRowNavPanel> = ({
    basket,
    isShownForPreview,
    handleRemoveBasketItem,
}) => {
    const [
        { t },
        productsService,
        oldPromotionService,
    ] = useInstances(
        I18NService,
        ProductsService,
        OldPromotionService,
    );

    const onDeleteBasket = useCallback(
        () => {
            if (basket.productItem.isFromPromotionBuilder
                && (basket.productItem.subType === ProductItemSubTypeEnum.PROMO || basket.productItem.subType === ProductItemSubTypeEnum.GIFT)
            ) {
                handleRemoveBasketItem(basket.id);
                return;
            }

            if (!isShownForPreview) {
                if (basket.productItem.subType === ProductItemSubTypeEnum.PROMO) {
                    handleRemoveBasketItem();
                } else {
                    productsService.hideProductRow(basket);
                }
            }
        },
        [basket.removed, isShownForPreview, handleRemoveBasketItem],
    );

    const onCancelPromo = useCallback(
        () => oldPromotionService.removePromoFromProduct(basket),
        [basket.isPromoApplied],
    );

    const handlerChangeNavBar = (type: string) => {
        switch (type) {
            case 'delete':
                onDeleteBasket();
                break;
            case 'cancel':
                void onCancelPromo();
                break;
            default:
                throw new Error('Type undefined');
        }
    };

    return (
        <NomenclatureNavigationBar
            onChange={handlerChangeNavBar}
            isCancel={basket.isPromoApplied}
            isDelete
            rollUpEvent={basket.removed}
            text={{
                cancel: t('Отменить', 'Cancel'),
                delete: t('Удалить', 'Delete'),
            }}
        />
    );
};


export default observer(ProductRowNavPanel);
