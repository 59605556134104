import { toFactory } from 'react-ioc';

import KibanaService from '@services/KibanaService';
import SupportApiService from '@api/support-api-service';


const KibanaServiceDI = (): any => [
    KibanaService,
    toFactory(
        [
            SupportApiService,
        ],
        (
            supportApiService: SupportApiService,
        ) => new KibanaService(
            supportApiService,
        ),
    ),
];


export default KibanaServiceDI;
