import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export const SideBarDeepMenuUIStateModel = t
    .model('SideBarDeepMenuUIStateModel', {
        languagesVisible: t.optional(t.boolean, false),
        statusVisible: t.optional(t.boolean, false),
        communicationQualityVisible: t.optional(t.boolean, false),
    })
    .actions((self) => ({
        setLanguagesVisible: (value: boolean): void => {
            self.languagesVisible = value;
        },
        setStatusVisible: (value: boolean): void => {
            self.statusVisible = value;
        },
        setCommunicationQualityVisible: (value: boolean): void => {
            self.communicationQualityVisible = value;
        },
    }));


export interface ISideBarDeepMenuUIStateModel extends Instance<typeof SideBarDeepMenuUIStateModel> {}
export interface ISideBarDeepMenuUIStateModelSnapshotIn extends SnapshotIn<typeof SideBarDeepMenuUIStateModel> {}
export interface ISideBarDeepMenuUIStateModelSnapshotOut extends SnapshotOut<typeof SideBarDeepMenuUIStateModel> {}
