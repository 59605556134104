import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';
import currency from 'currency.js';

import { ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';


export const ProductSetView = t
    .model('ProductSetView', {
        id: t.identifier,
        isPacking: t.boolean,
        name: t.string,
        productId: t.string,
        quantity: t.integer,
    });

export const OrderViewProductModel = t
    .model('OrderViewProductModel', {
        id: t.string,
        image: t.string,
        name: t.string,
        price: t.number,
        quantity: t.number,
        gift: t.optional(t.boolean, false),
        promo: t.optional(t.boolean, false),
        isDynamicSet: t.boolean,
        isProductSet: t.boolean,
        products: t.optional(t.array(ProductSetView), []),
    })
    .views((self) => ({
        get cost(): number {
            if (self.gift) {
                return 0;
            }

            return currency(self.price).multiply(self.quantity).value;
        },

        get type(): ProductItemTypeEnum {
            const { isDynamicSet, isProductSet } = self;

            if (!isDynamicSet && isProductSet) {
                return ProductItemTypeEnum.STATIC_SET;
            }

            if (isProductSet && isProductSet) {
                return ProductItemTypeEnum.DYNAMIC_SET;
            }

            return ProductItemTypeEnum.REGULAR;
        },
    }));


export interface IProductSetViewModel extends Instance<typeof ProductSetView> {}
export interface IProductSetViewModelSnapshotIn extends SnapshotIn<typeof ProductSetView> {}
export interface IProductSetViewModelSnapshotOut extends SnapshotOut<typeof ProductSetView> {}

export interface IOrderViewProductModel extends Instance<typeof OrderViewProductModel> {}
export interface IOrderViewProductModelSnapshotIn extends SnapshotIn<typeof OrderViewProductModel> {}
export interface IOrderViewProductModelSnapshotOut extends SnapshotOut<typeof OrderViewProductModel> {}
