import get from 'lodash/get';
import ApiBase from '@core/api';
import { getEnvGetCommunicationQualityListEndpoint, getEnvSendLogToKibana } from '@/env/requestEndpoints';
import { ICommunicationQualityItem } from './model';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error';
    message?: string;
    data: T;
}

export enum ErrorLevelEnum {
    LOG = 'log',
    WARNING = 'warning',
    ERROR = 'error',
    CRITICAL = 'critical',
}

class SupportApiService {
    @Inject apiBase: ApiBase;

    async getCommunicationQualityList(partnerId: number, countryId: number): Promise<ICommunicationQualityItem[] | never> {
        const dataForSend = {
            partner_id: partnerId,
            country_id: countryId,
        };

        const response = await this.apiBase.get<IWithResponse<ICommunicationQualityItem[]>>(
            getEnvGetCommunicationQualityListEndpoint(),
            dataForSend,
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async sendLogToKibana(type: string, error: string, level: ErrorLevelEnum, trace?: string, dataToSend?: Array<any>): Promise<[]> {
        const dataForSend = {
            type,
            error,
            trace,
            level,
            data: dataToSend,
        };

        const response = await this.apiBase.post<IWithResponse<[]>>(getEnvSendLogToKibana(), dataForSend);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }
}


export default SupportApiService;
