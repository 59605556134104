import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import some from 'lodash/some';

import { IBasketItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IPromotionBuilderSnapshotIn } from '@models/mobx-state-tree/newModels/PromotionBuilder.models';
import { IProductItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/ProductItem.model';


export interface IDeletedProductFromPromotion {
    products: Map<string, IProductItemModelSnapshotIn>;
    promotion: IPromotionBuilderSnapshotIn;
}


class DeletedPromotionsAndProducts {
    private readonly _deletedOldPromotions = new Map<string, IBasketItemModelSnapshotIn>();

    private readonly _deletedPromotionBuilder = new Map<string, IPromotionBuilderSnapshotIn>();

    private readonly _disbandedOldPromotions = new Map<string, IBasketItemModelSnapshotIn>();

    private readonly _deletedProductsFromPromotion = new Map<string, IDeletedProductFromPromotion>();

    private readonly _disbandedPromotionBuilder = new Map<string, IPromotionBuilderSnapshotIn>();

    public get isRemoteProductsAndPromotions(): boolean {
        return Boolean(
            this._disbandedPromotionBuilder.size
            + this._deletedPromotionBuilder.size
            + this._deletedOldPromotions.size
            + this._disbandedOldPromotions.size
            + this._deletedProductsFromPromotion.size,
        );
    }

    public get namesOfDeletedPromotionBuildersAndOldPromotions(): string[] {
        return this.namesOfDeletedPromotionBuilders.concat(this.namesOfDeletedOldPromotions);
    }

    public get namesOfDeletedPromotionBuilders(): string[] {
        const deletedPromotionNames: string[] = [];

        this._deletedPromotionBuilder.forEach(
            (item) => {
                deletedPromotionNames.push(item.name);
            },
        );

        return [...new Set(deletedPromotionNames)];
    }

    public get namesOfDeletedOldPromotions(): string[] {
        const deletedOldPromotionsNames: string[] = [];

        this._deletedOldPromotions.forEach(
            (item) => {
                deletedOldPromotionsNames.push(item.promotionName || '');
            },
        );

        return [...new Set(deletedOldPromotionsNames)];
    }

    public get namesOfDisbandedOldPromotions(): string[] {
        const disbandedOldPromotionsNames: string[] = [];

        this._disbandedOldPromotions.forEach(
            (item) => {
                disbandedOldPromotionsNames.push(item.promotionName || '');
            },
        );

        return [...new Set(disbandedOldPromotionsNames)];
    }

    /**
     * Удаленные товары из КА,
     * отфильтрованные по условию:
     * КА должен быть не удален
     */
    public get allDeletedProductsFromPromotion(): IDeletedProductFromPromotion[] {
        const uniqDeletedProducts = uniqBy<IDeletedProductFromPromotion>(
            [...this._deletedProductsFromPromotion.values()],
            'promotion.promotionBuilderId',
        );

        return filter<IDeletedProductFromPromotion>(
            uniqDeletedProducts,
            (deletedItem) => !some(
                [...this.deletedPromotionBuilder.values()],
                (deletedPromotionBuilder) => deletedPromotionBuilder.promotionBuilderId === deletedItem.promotion.promotionBuilderId,
            ),
        );
    }

    public get deletedProductsFromPromotion(): Map<string, IDeletedProductFromPromotion> {
        return this._deletedProductsFromPromotion;
    }

    public get deletedPromotionBuilder(): Map<string, IPromotionBuilderSnapshotIn> {
        return this._deletedPromotionBuilder;
    }

    public get deletedPromotionBuilderItems(): IPromotionBuilderSnapshotIn[] {
        return [...this._deletedPromotionBuilder.values()];
    }

    public get deletedOldPromotions(): Map<string, IBasketItemModelSnapshotIn> {
        return this._deletedOldPromotions;
    }

    public get disbandedOldPromotions(): Map<string, IBasketItemModelSnapshotIn> {
        return this._disbandedOldPromotions;
    }

    public pushToDeletedOldPromotions = (deletedOldPromotion: IBasketItemModelSnapshotIn): void => {
        this._deletedOldPromotions.set(deletedOldPromotion.id, deletedOldPromotion);
    };

    public pushToDisbandedOldPromotions = (disbandedOldPromotion: IBasketItemModelSnapshotIn): void => {
        this._disbandedOldPromotions.set(disbandedOldPromotion.id, disbandedOldPromotion);
    };

    public pushToDeletedPromotionBuilder = (deletedPromotionBuilder: IPromotionBuilderSnapshotIn): void => {
        this._deletedPromotionBuilder.set(deletedPromotionBuilder.id, deletedPromotionBuilder);
    };

    public pushToDisbandedPromotionBuilder = (disbandedPromotionBuilder: IPromotionBuilderSnapshotIn): void => {
        this._disbandedPromotionBuilder.set(disbandedPromotionBuilder.id, disbandedPromotionBuilder);
    };

    public pushDeletedProductsFromPromotion = (
        product: IProductItemModelSnapshotIn,
        promotion: IPromotionBuilderSnapshotIn,
    ): void => {
        const item = this._deletedProductsFromPromotion.get(promotion.id);

        if (item) {
            item.products.set(product.id, product);

            this._deletedProductsFromPromotion.set(item.promotion.id, item);
        } else {
            const products = new Map<string, IProductItemModelSnapshotIn>();

            products.set(product.id, product);

            this._deletedProductsFromPromotion.set(
                promotion.id,
                {
                    products,
                    promotion,
                },
            );
        }
    };
}


export default DeletedPromotionsAndProducts;
