import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import EnoughQuantityImg from '@images/products/enough_quantity.png';
import FewQuantityImg from '@images/products/few_quantity.png';
import LittleQuantityImg from '@images/products/little_quantity.png';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import { IProductItemModel, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import Popup from '@UIElements/Popup';
import I18NService from '@services/I18NService';
import Button from '@UIElements/Button';
import { OrderService } from '@services/index';

import './ProductSetToAdd.scss';
import ProductsService from '@services/order/ProductsService';
import BasketService from '@services/order/BasketService';


interface IProductImageProps {
    className?: string;
    catalogItem: IProductItemModel;
    disabled?: boolean;
    isShownForReplacement?: boolean; // флаг того, что товар отображается и его можно выбрать в качестве замены
    isChoosedForReplacement?: boolean; // флаг того, что товар отображается, но он выбран в качестве товара, который хотят заменить
}


const ProductSetToAdd = ({
    className, catalogItem, disabled = false, isShownForReplacement, isChoosedForReplacement,
}: IProductImageProps): JSX.Element => {
    const [
        orderService,
        { t },
        productsService,
        basketService,
    ] = useInstances(
        OrderService,
        I18NService,
        ProductsService,
        BasketService,
    );
    const catalogItemSetComposition = productsService.catalogItemOriginalSetProductCompositionsValues(catalogItem.id);
    const [choosed, setChoosed] = useState<boolean>(false);

    const { replaceTransactionTo } = orderService.currentOrder;
    useEffect(() => {
        if (replaceTransactionTo) {
            if (replaceTransactionTo === catalogItem.id) {
                setChoosed(true);
                return;
            }
            setChoosed(false);
        }
    }, [replaceTransactionTo]);

    const onClick = useCallback(() => {
        const { replaceTransaction } = orderService.currentOrder;
        if (!isChoosedForReplacement) { // если это НЕ уже выбранный для замены товар
            if (isShownForReplacement) { // если это товар, который можно выбрать в качестве замены
                if (replaceTransaction) {
                    replaceTransaction.setTo(catalogItem.id);
                }

                return;
            }

            if (!disabled) {
                basketService.addItemFromCatalogToBasket(catalogItem);
            }
        }
    }, [disabled, isShownForReplacement, isChoosedForReplacement]);

    const createPreviewModel = useCallback(() => {
        if (!isChoosedForReplacement) { // если это НЕ уже выбранный для замены товар
            if (!disabled) {
                basketService.createBasketItemModelPreview(catalogItem);
            }
        }
    }, [disabled, isShownForReplacement, isChoosedForReplacement]);

    if (!catalogItemSetComposition) {
        return <></>;
    }

    return (
        <div
            className={cn([className,
                'ProductSetToAdd__wrapper',
                disabled && 'disabled',
                choosed && isShownForReplacement && 'choosed'])}
            id={catalogItem.id}
        >
            <div className="ProductSetToAdd__product_image__wrapper">
                <ProductImage
                    className="ProductSetToAdd__product_image"
                    productName={catalogItem.name}
                    imageLink={catalogItem.image}
                    productType={catalogItem.type}
                />
                <div className="ProductSetToAdd__product_gallery__wrapper">
                    {catalogItemSetComposition
                    && catalogItemSetComposition.length <= 4
                    && catalogItemSetComposition.map((item: ISetProductComposition) => item.getOriginalProductItem && (
                        <ProductImage
                            className="ProductSetToAdd__product_image"
                            productName={item.getOriginalProductItem.name}
                            imageLink={item.getOriginalProductItem.image}
                            productType={item.getOriginalProductItem.type}
                            key={`${item.id}-${item.getOriginalProductItem.id}`}
                        />
                    ))}
                    {catalogItemSetComposition
                    && catalogItemSetComposition.length > 4
                    && catalogItemSetComposition
                        .filter((item: ISetProductComposition, index: number) => index <= 2)
                        .map((item: ISetProductComposition) => item.getOriginalProductItem && (
                            <ProductImage
                                className="ProductSetToAdd__product_image"
                                productName={item.getOriginalProductItem.name}
                                imageLink={item.getOriginalProductItem.image}
                                productType={item.getOriginalProductItem.type}
                                key={`${item.id}-${item.getOriginalProductItem.id}`}
                            />
                        ))}
                    {catalogItemSetComposition
                    && catalogItemSetComposition.length > 4
                    && catalogItemSetComposition[3].getOriginalProductItem && (
                        <ProductImage
                            className="ProductSetToAdd__product_image"
                            productName={catalogItemSetComposition[3].getOriginalProductItem.name}
                            imageLink={catalogItemSetComposition[3].getOriginalProductItem.image}
                            productType={catalogItemSetComposition[3].getOriginalProductItem.type}
                            key={`${catalogItemSetComposition[3].id}-${catalogItemSetComposition[3].getOriginalProductItem.id}`}
                        >
                            <div className="blur">
                                <div className="remaining_number">{catalogItemSetComposition.length - 3}</div>
                            </div>
                        </ProductImage>
                    )}
                </div>
            </div>
            <div className="ProductSetToAdd_controls">
                {isChoosedForReplacement && (
                    <Button
                        size="0"
                        variant={1}
                        width="auto"
                        disabled={true}
                        text={t('Выбрано', 'Choosed')}
                        dataCyValue={`chosen_set_${catalogItem.partnerProductId}`}
                    />
                )}
                {!isChoosedForReplacement && (
                    <Button
                        className="button_add"
                        size="0"
                        variant={3}
                        width="auto"
                        text={isShownForReplacement ? t('Выбрать', 'Choose') : t('Добавить', 'Add')}
                        onClick={onClick}
                        dataCyValue={`set_to_add_${catalogItem.partnerProductId}`}
                    />
                )}
                {!isChoosedForReplacement && !isShownForReplacement && (
                    <Button
                        size="0"
                        variant={0}
                        width="auto"
                        text={t('Подробно', 'Detail')}
                        onClick={createPreviewModel}
                        dataCyValue={`set_detail_${catalogItem.partnerProductId}`}
                    />
                )}
            </div>
            <span className="ProductSetToAdd__product_type">
                {catalogItem.type === ProductItemTypeEnum.DYNAMIC_SET && t('Динамический набор', 'Dynamic set').toUpperCase()}
                {catalogItem.type === ProductItemTypeEnum.STATIC_SET && t('Статический набор', 'Static set').toUpperCase()}
            </span>
            <span className="ProductSetToAdd__product_name" title={catalogItem.name}>
                {catalogItem.name}
                <div className="ProductSetToAdd__product_contains">
                    <div className="ProductSetToAdd__product_price">
                        {`${t('Цена набора', 'Product set price')}:`}
                        {` ${catalogItem.price}`}
                    </div>
                </div>
            </span>
            <Popup
                className="ProductSetToAdd__popup"
                autoHeightMax={100}
            >
                <ul>
                    {catalogItemSetComposition && catalogItemSetComposition.map((item: ISetProductComposition) => {
                        if (!item.getOriginalProductItem) {
                            return <></>;
                        }

                        const title = `${t('Количество на складе', 'Quantity in stock')}: ${item.getOriginalProductItem.quantityInStock}`;
                        return (
                            <li
                                key={item.id}
                            >
                                <div className="flex-inner product_name">
                                    <div
                                        className="product_name--container"
                                        title={item.getOriginalProductItem.name}
                                    >
                                        {item.getOriginalProductItem.name}
                                    </div>
                                </div>
                                {item.getOriginalProductItem.quantityInStock >= 0
                                && item.getOriginalProductItem.quantityInStock < 50 && (
                                    <div className="flex-inner product_quantity_image" title={title}>
                                        <img className="quantity__img" src={LittleQuantityImg} alt="" />
                                    </div>
                                )}
                                {item.getOriginalProductItem.quantityInStock >= 50
                                && item.getOriginalProductItem.quantityInStock <= 200 && (
                                    <div className="flex-inner product_quantity_image" title={title}>
                                        <img className="quantity__img" src={FewQuantityImg} alt="" />
                                    </div>
                                )}
                                {item.getOriginalProductItem.quantityInStock > 200 && (
                                    <div className="flex-inner product_quantity_image" title={title}>
                                        <img className="quantity__img" src={EnoughQuantityImg} alt="" />
                                    </div>
                                )}
                                <div className="flex-inner product_quantity_spec">{item.getOriginalProductItem.quantityInSet}</div>
                                {/**
                                 * Ранее, у товара показывали словесное описание количества товара на складе.
                                 * В UI-219 появилось требование отображать количество товара в наборе. Этот код пока что оставил, чтобы не повторять :)
                                 */}
                                {/* {item.getOriginalProductItem.quantityInStock === 0 && (
                                    <div className="flex-inner product_quantity_spec">{t('Нет', 'No')}</div>
                                )}
                                {item.getOriginalProductItem.quantityInStock > 0
                                && item.getOriginalProductItem.quantityInStock < 50 && (
                                    <div className="flex-inner product_quantity_spec">{t('Почти закончился', 'Almost end')}</div>
                                )}
                                {item.getOriginalProductItem.quantityInStock >= 50
                                && item.getOriginalProductItem.quantityInStock <= 200 && (
                                    <div className="flex-inner product_quantity_spec">{t('Достаточно', 'Few')}</div>
                                )}
                                {item.getOriginalProductItem.quantityInStock > 200 && (
                                    <div className="flex-inner product_quantity_spec">{t('Много', 'Enough')}</div>
                                )} */}
                            </li>
                        );
                    })}
                </ul>
            </Popup>
        </div>
    );
};


export default observer(ProductSetToAdd);
