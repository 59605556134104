import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import OldPromotionService from '@services/order/OldPromotionService';
import { OrderService } from '@services/index';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';
import BasketService from '@services/order/BasketService';


const OldPromotionServiceDI = (): any => [
    OldPromotionService,
    toFactory(
        [
            Store,
            OrderService,
            I18NService,
            ModalService,
            BasketService,
        ],
        (
            store: Store,
            orderService: OrderService,
            I18NService: I18NService,
            modalService: ModalService,
            basketService: BasketService,
        ) => new OldPromotionService(
            store,
            orderService,
            I18NService,
            modalService,
            basketService,
        ),
    ),
];


export default OldPromotionServiceDI;
