import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import { observer } from 'mobx-react';

import TextInput from '@UIElements/TextInput';
import { InputTypes } from '@UIElements/TextInput/models';
import I18NService from '@services/I18NService';

import AddPhoneRoundIconSvg from './includes/AddPhoneRoundIconSvg';
import RemovePhoneCrossIconSvg from './includes/RemovePhoneCrossIconSvg';


interface IAddedPhoneProps {
    id: string;
    onRemove: () => void;
    onAdd: () => void;
    onChangeNumber: (val: string) => void;
    value: string;
    label?: string;
    placeholder?: string;
    index: number;
    isValid: boolean;
    onClick: () => void;
    className?: string;
}


const AddedPhone: FunctionComponent<IAddedPhoneProps> = ({
    id,
    onChangeNumber,
    onRemove,
    onAdd,
    label,
    placeholder,
    value,
    index,
    isValid,
    onClick,
    className = '',
}) => {
    const [
        { t },
    ] = useInstances(
        I18NService,
    );

    return (
        <div className="orderCustomerInfo__form--newNumber">
            <TextInput
                id={id}
                className={cn([className && className, 'order-input with-add-button'])}
                width="296px"
                height="40px"
                error={t('Поле не валидно', 'The field is not valid.')}
                placeholder={placeholder}
                label={label}
                value={value}
                onChangeValue={onChangeNumber}
                isValid={isValid}
                onClick={onClick}
                type={InputTypes.TEL}
                dataCyValue={`general_data_attribute_additional_phone_${index + 1}`}
            />
            {index === 0 && (
                <div onClick={onAdd} className="input-right-icon">
                    <AddPhoneRoundIconSvg />
                </div>
            )}
            {index > 0 && (
                <div onClick={onRemove} className="input-right-icon">
                    <RemovePhoneCrossIconSvg />
                </div>
            )}
        </div>
    );
};


export default observer(AddedPhone);
