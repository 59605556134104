import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface ISwapIconSvg {
    className?: string;
}


const SwapArrowsIcon: FunctionComponent<ISwapIconSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['SwapArrowsIconSvg', className])}
        {...props}
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2456 0.516055C12.4897 0.271992 12.8853 0.271992 13.1294 0.516055L15.3169 2.70356C15.5609 2.94762 15.5609 3.34325 15.3169 3.58731L13.1294 5.77482C13.0072 5.897 12.8475 5.95794 12.6875 5.95794C12.5275 5.95794 12.3678 5.897 12.2456 5.77482C12.0016 5.53075 12.0016 5.13513 12.2456 4.89106L13.3662 3.77044H1.125C0.78 3.77044 0.5 3.49044 0.5 3.14544C0.5 2.80043 0.78 2.52043 1.125 2.52043H13.3662L12.2456 1.39981C12.0016 1.15574 12.0016 0.760118 12.2456 0.516055ZM2.63372 10.0204H14.875C15.22 10.0204 15.5 10.3004 15.5 10.6454C15.5 10.9904 15.22 11.2704 14.875 11.2704H2.63372L3.75435 12.3911C3.99841 12.6351 3.99841 13.0308 3.75435 13.2748C3.63216 13.397 3.47247 13.4579 3.31247 13.4579C3.15247 13.4579 2.99279 13.397 2.8706 13.2748L0.683098 11.0873C0.439035 10.8432 0.439035 10.4476 0.683098 10.2036L2.8706 8.01606C3.11466 7.77199 3.51028 7.77199 3.75435 8.01606C3.99841 8.26012 3.99841 8.65574 3.75435 8.89981L2.63372 10.0204Z"
            fill="#8E8E93"
        />
    </svg>
);


export default SwapArrowsIcon;
