import {
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';
import {
    ProductItemSubTypeEnum, ProductItemTypeEnum, subTypeOptions, typeOptions,
} from './newModels/ProductItem.model';

export const CustomerServiceProductModel = t
    .model('CustomerServiceProductModel', {
        id: t.identifier, // генерируемый идентификатор uuid\v4
        type: t.optional(t.enumeration(typeOptions), ProductItemTypeEnum.REGULAR),
        subType: t.optional(t.enumeration(subTypeOptions), ProductItemSubTypeEnum.MAIN),
        isPromotion: t.optional(t.boolean, false),
        isGeneral: t.boolean, // является ли главным товаром (что-то вроде товара лида)
        partnerProductId: t.string, // идентификатор товара в таблице partner_product на бэке
        productId: t.string, // идентификатор товара в таблице product на бэке
        name: t.string,
        price: t.maybeNull(t.number), // Стоимость 1 ед товара
        quantityInSet: t.maybeNull(t.number),
        image: t.maybeNull(t.string),
        // setProductCompositions: t.maybeNull(t.map(CustomerServiceSetProductComposition)), // cостав сета, если товар типа статического или динамического сета, иначе null
    })
    .views(() => ({
    }))
    .actions(() => ({
    }));


export interface ICustomerServiceProductModel extends Instance<typeof CustomerServiceProductModel> {}
export interface ICustomerServiceProductModelSnapshotIn extends SnapshotIn<typeof CustomerServiceProductModel> {}
export interface ICustomerServiceProductModelSnapshotOut extends SnapshotOut<typeof CustomerServiceProductModel> {}
