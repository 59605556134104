import { toFactory } from 'react-ioc';

import OrderUpdateEventSubscriptionService from '@services/subscription/OrderUpdateEventSubscriptionService';
import { Store } from '@store/store';
import {
    DeliveriesService, OrderService, SipService, UserService,
} from '@services/index';
import OrderApiService from '@api/order-api-service';
import UserApiService from '@api/user-api-service';
import ModalService from '@core/services/ModalService';
import { OrderCreationService } from '@services/order';
import CustomerService from '@services/CustomerService';
import HistoryService from '@services/HistoryService';
import BasketService from '@services/order/BasketService';
import ProductsService from '@services/order/ProductsService';
import ScriptDataService from '@services/script/ScriptDataService';

const OrderUpdateEventSubscriptionServiceDI = (): any => [
    OrderUpdateEventSubscriptionService,
    toFactory(
        [
            Store,
            OrderService,
            DeliveriesService,
            OrderApiService,
            SipService,
            UserService,
            ModalService,
            OrderCreationService,
            UserApiService,
            CustomerService,
            HistoryService,
            BasketService,
            ProductsService,
            ScriptDataService,
        ],
        (
            store: Store,
            orderService: OrderService,
            deliveriesService: DeliveriesService,
            orderApiService: OrderApiService,
            sipService: SipService,
            userService: UserService,
            modalService: ModalService,
            orderCreationService: OrderCreationService,
            userApiService: UserApiService,
            customerService: CustomerService,
            historyService: HistoryService,
            basketService: BasketService,
            productsService: ProductsService,
            scriptDataService: ScriptDataService,
        ) => new OrderUpdateEventSubscriptionService(
            store,
            deliveriesService,
            orderService,
            orderApiService,
            sipService,
            userService,
            modalService,
            orderCreationService,
            userApiService,
            customerService,
            historyService,
            basketService,
            productsService,
            scriptDataService,
        ),
    ),
];


export default OrderUpdateEventSubscriptionServiceDI;
