import get from 'lodash/get';
import isArray from 'lodash/isArray';

import {
    getEnvCreateNotificationEndpoint,
    getEnvGetNotificationEndpoint,
    getEnvSetNotificationSuccessEndpoint,
} from '@/env/requestEndpoints';

import ApiBase from '@core/api';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}

interface ICreateNotificationArgs {
    trigger: string;
    orderId: number | null;
    wastedCall?: boolean;
}

interface IGetNotificationArgs {
    userId: number;
}

export interface INotificationData {
    id: number;
    message: string;
    is_feedback: boolean;
}

export interface IGetNotificationData {
    id: number;
    notification_id: number;
    user_id: number;
    feedback: string | null;
    sent_at: number;
    feedback_at: number | null;
    teamlead_read: boolean;
    created_at: number;
    updated_at: number | null;
    message: string;
    status: number;
    parent_id: number | null;
    is_feedback: boolean;
}

export interface ISetNotificationSuccessArgs {
    id: number;
    feedback?: string;
}

class NotificationApiService {
    @Inject apiBase: ApiBase;

    async create(params: ICreateNotificationArgs): Promise<INotificationData> {
        const dataToSent = {
            order_id: params.orderId,
            trigger: params.trigger,
            wasted_call: params?.wastedCall,
        };

        const response = await this.apiBase.post<IWithResponse<INotificationData>>(getEnvCreateNotificationEndpoint(), dataToSent);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async get(params: IGetNotificationArgs): Promise<IGetNotificationData | false> {
        const dataToSent = {
            user_id: params.userId,
        };

        const response = await this.apiBase.get<IWithResponse<IGetNotificationData | []>>(getEnvGetNotificationEndpoint(), dataToSent);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        if (isArray(data)) {
            return false;
        }

        return data;
    }

    async setSuccess(params: ISetNotificationSuccessArgs): Promise<boolean> {
        const dataToSent = {
            id: params.id,
            feedback: params.feedback,
        };

        const response = await this.apiBase.post<IWithResponse<any>>(getEnvSetNotificationSuccessEndpoint(), dataToSent);

        const { message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }
}


export default NotificationApiService;
