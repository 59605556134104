import { toFactory } from 'react-ioc';

import { CustomerModeStore } from '@store/customerModeStore';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';


const CustomerModeStoreDI = (): any => [
    CustomerModeStore,
    toFactory(
        [
            Store,
            I18NService,
        ],
        (
            rootStore: Store,
            I18NService: I18NService,
        ) => CustomerModeStore.create(
            rootStore,
            I18NService,
        ),
    ),
];


export default CustomerModeStoreDI;
