import React, { FC } from 'react';

import { observer } from 'mobx-react';
import CallButtonAndPhone
    from '@components/main/order-page/call-panel/call-widget/call-button-and-phone/CallButtonAndPhone';
import ChangeOrderButtons
    from '@components/main/order-page/call-panel/call-widget/ChangeOrderButtons/ChangeOrderButtons';

import { useInstances } from 'react-ioc';
import { Store } from '@store/store';

import './call-widget-modal.scss';

interface ICallWidgetModal {
    message: string;
}

const CallWidgetModal: FC<ICallWidgetModal> = ({ message }) => {

    const [
        {
            inactivityTimer: {
                modalFormFillTimePassed,
                modalFormFillTimeLimit,
            },
        },
    ] = useInstances(
        Store,
    );

    let restSeconds = 0;

    if (modalFormFillTimeLimit && modalFormFillTimeLimit > modalFormFillTimePassed / 1000) {
        restSeconds = modalFormFillTimeLimit - modalFormFillTimePassed / 1000;
    }

    return (
        <div className="call-widget-modal">
            <div className="call-widget__message">{message.replace(/\{(.*?)\}/, restSeconds.toString())}</div>
            <div className="call-widget__wrapper">
                <CallButtonAndPhone />
                <div className="b-call-widget__block__padding">
                    <div className="call-footer">
                        <div className="change-order-buttons">
                            <ChangeOrderButtons />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CallWidgetModal);
