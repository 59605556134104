import get from 'lodash/get';
import { ITimezone, IUserCountry } from '@api/user-api-service/models';
import { IAuthData, IS_READY_STATUS_ENUM } from '@api/auth-api-service/models';

import {
    getEnvChangeUserReadyEndpoint,
    getEnvGetUserCountriesEndpoint,
    getEnvGetUserEndpoint,
    getEnvSetTimeActivityEndpoint,
    getEnvGetArticlesList,
    getEnvUserEventEndpoint,
    getEnvTimezoneEndpoint, getEnvGetSpeedTestEndpoint, getEnvGetAuxEndpoit, getEnvChangeTimezoneEndpoint,
} from '@/env/requestEndpoints';

import ApiBase from '@core/api';
import XHR401Error from '@core/error/XHR401Error';
import { IArticle } from '@interfaces/article.interface';
import OfflineError from '@core/error/OfflineError';
import { TProvideInternetConnectivity } from '@core/services/InternetSpeedMetricService';
import { Inject } from 'react-ioc';
import ErrorService from '@core/services/ErrorService';
import KibanaService from '@services/KibanaService';
import { ErrorLevelEnum } from '@api/support-api-service';


interface IUserReadyStatusFormData {
    is_ready: IS_READY_STATUS_ENUM;
}

interface ISetTimeActivityFormData {
    inner_ip?: string;
    queue_id?: number;
    country_id?: number;
}

interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}

interface IUserDataResponse<T> extends IWithResponse<T> {
    data: T;
}

interface IUserEvent {
    action: string;
    event?: string;
    order_id?: number | null;
    country_id?: number | null;
    last_queue_id?: number | null;
    global_attempt?: number | null;
    attempt?: number | null;
}

interface IFAQArticlesList {
    countryIds: number[];
    productIds: number[];
}

interface IFAQArticlesListRequestArgs {
    country_ids: number[];
    product_ids: number[];
    is_customer_service: 1;
}

export interface IAuxStatusData {
    id: number;
    name: string;
    order_get: boolean;
    icon: string;
    limit: number;
    description: string;
    not_limited: boolean;
}

class UserApiService {
    @Inject apiBase: ApiBase;

    @Inject errorService: ErrorService;

    @Inject kibanaService: KibanaService;

    getUserCountries = async (): Promise<IUserCountry[] | never> => {
        const response = await this.apiBase.get<IUserDataResponse<IUserCountry[]>>(getEnvGetUserCountriesEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    getTimezone = async (timezoneId?: number): Promise<ITimezone[]> => {

        const dataToSend = timezoneId ? { id: timezoneId } : {};

        const response = await this.apiBase.get<IWithResponse<ITimezone[]>>(getEnvTimezoneEndpoint(), dataToSend);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    changeUserTimezone = async (timezoneId: number): Promise<ITimezone[] | boolean> => {

        try {
            const dataToSend = {
                timezone_id: timezoneId,
            };
            const response = await this.apiBase.post<IWithResponse<ITimezone[]>>(getEnvChangeTimezoneEndpoint(), dataToSend);

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return data;
        } catch (e) {
            if (e instanceof Error) {
                console.error('Error occurred while changing timezone:', e.message);
            } else {
                console.error('Unknown error occurred:', e);
            }
            return false;
        }
    };

    changeUserReady = async (isReady: IS_READY_STATUS_ENUM): Promise<[] | never> => {
        const formData: IUserReadyStatusFormData = {
            is_ready: isReady,
        };

        const response = await this.apiBase.post<IUserDataResponse<[]>>(getEnvChangeUserReadyEndpoint(), formData);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    setTimeActivity = async (queueId?: number, countryId?: number): Promise<boolean | never> => {

        try {

            const innerIp = require('ip').address();

            const formData: ISetTimeActivityFormData = {
                ...(innerIp ? { inner_ip: innerIp } : {}),
                ...(queueId ? { queue_id: queueId } : {}),
                ...(countryId ? { country_id: countryId } : {}),
            };

            const response = await this.apiBase.post<IUserDataResponse<[]>>(getEnvSetTimeActivityEndpoint(), formData);

            const { message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }
        } catch (e) {
            const Ru = 'Запись времени онлайн не установлена: пользователь не готов';
            const En = 'User ready set activity is fail: user is not ready';

            const tryRecordTimeWhenUnreadyRu = e[0] === Ru;
            const tryRecordTimeWhenUnreadyEn = e[0] === En;
            if (tryRecordTimeWhenUnreadyEn || tryRecordTimeWhenUnreadyRu) {
                // eslint-disable-next-line no-console
                console.log(e[0]);
                return false;
            }

            if ('onLine' in navigator) {
                const isOffline = !navigator.onLine;
                if (isOffline) {
                    throw this.errorService.toReturnAFabricatedError(OfflineError);
                }
            }

            if (!(e instanceof XHR401Error)) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            throw !(e instanceof Error) ? new Error(e) : e;
        }

        return true;
    };

    getUser = async (): Promise<IAuthData> => {
        const response = await this.apiBase.get<IWithResponse<IAuthData>>(getEnvGetUserEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    getArticlesList = async (): Promise<IArticle[] | never> => {
        const response = await this.apiBase.get<IWithResponse<IArticle[]>>(getEnvGetArticlesList());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    getFAQArticlesList = async (dataToSend: IFAQArticlesList): Promise<IArticle[] | never> => {
        const args: IFAQArticlesListRequestArgs = {
            country_ids: dataToSend.countryIds,
            product_ids: dataToSend.productIds,
            is_customer_service: 1,
        };

        const response = await this.apiBase.get<IWithResponse<IArticle[]>>(getEnvGetArticlesList(), args);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };

    sendUserEvent = async (dataToSend: IUserEvent): Promise<boolean> => {
        try {

            if (navigator.onLine) {
                const response = await this.apiBase.post<IUserDataResponse<[]>>(getEnvUserEventEndpoint(), dataToSend);

                const { message = 'Unknown error' } = response;

                const isSuccess = get(response, 'status') === 'success';

                if (!isSuccess) {
                    throw new Error(message);
                }
            }

        } catch (e) {
            await this.kibanaService.sendLog(e, dataToSend.order_id || -2, [dataToSend], ErrorLevelEnum.ERROR);
            if ('onLine' in navigator) {
                const isOffline = !navigator.onLine;
                if (isOffline) {
                    throw this.errorService.toReturnAFabricatedError(OfflineError);
                }
            }

            if (!(e instanceof XHR401Error)) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            throw !(e instanceof Error) ? new Error(e) : e;
        }

        return true;
    };

    public async speedTest({
        uploadSpeed,
        downloadSpeed,
        jitter,
        ping,
    }: TProvideInternetConnectivity): Promise<{ test: boolean; message: string }> {
        const response = await this.apiBase.post<IWithResponse<{ test: boolean; message: string }>>(
            getEnvGetSpeedTestEndpoint(),
            {
                speed_up: uploadSpeed,
                speed_down: downloadSpeed,
                jitter,
                ping,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    public async getAux(): Promise<IAuxStatusData[] | void> {

        try {
            const response = await this.apiBase.get<IWithResponse<IAuxStatusData[]>>(getEnvGetAuxEndpoit());

            const { data, message = 'Unknown error' } = response;

            const isSuccess = get(response, 'status') === 'success';

            if (!isSuccess) {
                throw new Error(message);
            }

            return data;

        } catch (e) {
            console.log(e);
        }
    }
}


export default UserApiService;
