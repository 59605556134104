class NoSipHostDetectedError extends Error {
    constructor(msg: string) {
        super();

        this.message = msg;
    }
}


export default NoSipHostDetectedError;
