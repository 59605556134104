import React, { FunctionComponent } from 'react';
import cn from 'classnames';


type IChevronSvgProps = {
    className?: string;
};


const ChevronSvgIcon: FunctionComponent<IChevronSvgProps> = ({
    className,
    ...props
}) => (
    <svg
        className={cn(['ChevronIconSvg', className])}
        {...props}
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.293 0.685547L13.7072 2.09976L7.00008 8.80687L0.292969 2.09976L1.70718 0.685547L7.00008 5.97844L12.293 0.685547Z"
            fill="#1665D8"
        />
    </svg>
);


export default ChevronSvgIcon;
