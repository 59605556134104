import { action, computed } from 'mobx';
import { applySnapshot } from 'mobx-state-tree';
import moment from 'moment';
import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import { v4 } from 'uuid';

import CustomerApiService from '@api/customer-api-service';
import { Store } from '@store/store';
import { IOrderViewProductModelSnapshotIn } from '@models/mobx-state-tree/orderViewProduct.model';
import { CustomerModeStore } from '@store/customerModeStore';
import {
    IOrderViewProduct, ISmsFromErp, OrderStatusesERPEnum, TSmsListFromErp,
} from '@api/order-api-service/models';
import { CustomerServiceOrder } from '@models/mobx-state-tree/customer-mode/customerServiceOrder.model';
import {
    IClientCardData, ICustomerAppeal, ICustomerAppealSubjectData, ICustomerCreateAppealResponseData, IGetSearchOrdersParam, IOrderProductResourceCS, IOrderSearchDataCS, ISearchClientCardRequestData,
} from '@api/customer-api-service/models';
import { ICustomerServiceOrderStage2SnapshotIn } from '@models/mobx-state-tree/customerServiceOrderStage2.model';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import { ICustomerServiceProductModelSnapshotIn } from '@models/mobx-state-tree/customerServiceProductModel.model';
import { ProductItemTypeEnum, ProductItemSubTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import UserApiService from '@api/user-api-service';
import {
    CustomerServiceFAQArticleStage2,
    ICustomerServiceFAQArticleStage2Model,
} from '@models/mobx-state-tree/customerServiceFAQArticleStage2.model';
import OrderApiService from '@api/order-api-service';
import { IOrderSearchFilterModel } from '@models/mobx-state-tree/customer-mode/orderSearchFilter.model';
import { ISmsFromErpModelSnapshotIn } from '@models/mobx-state-tree/customer-mode/SmsFromErp.model';
import { ICustomerAppealModel } from '@models/mobx-state-tree/customer-mode/customerAppeals.model';
import { AppealSubject, IAppealSubjectSnapshotIn } from '@models/mobx-state-tree/customer-mode/createAppeal.model';


type TDataForErp = { orderId: number; foreignId: number };


class CustomerService {
    constructor(
        private readonly _customerApiService: CustomerApiService,
        private readonly _store: Store,
        private readonly _customerModeStore: CustomerModeStore,
        private readonly _userApiService: UserApiService,
        private readonly _orderApiService: OrderApiService,
    ) {
    }

    @computed
    get ordersFilter(): IOrderSearchFilterModel {
        return this._customerModeStore.ordersFilter;
    }

    @action createClientCard = async (phone: string, name: string): Promise<any> => {
        try {
            await this._customerApiService.createClientCard(phone, name);
            void this.getClientCard();
        } catch (e) {
            const { message } = e as Error;
            this._customerModeStore.resetCreateClientCardError(); // сбрасываем ошибку в mobx, если вдруг была
            // ошибку показываем в UI -> просим попробовать еще раз
            this._customerModeStore.setShouldCreateClientCard(true);
            this._customerModeStore.fillCreateClientCardError(message);
        }
    };

    private static _convertTextMessagesForModel(array: ISmsFromErp[] | null): ISmsFromErpModelSnapshotIn[] | [] {
        if (!array) {
            return [];
        }

        return map<ISmsFromErp, ISmsFromErpModelSnapshotIn>(
            array,
            (sms) => ({
                id: sms.id,
                template: sms.template,
                text: sms.sms_text,
                status: sms.status,
                error: sms.error || '',
                phone: sms.phone,
                createdAt: sms.created_at && String(sms.created_at) || '',
            }),
        );
    }

    @action getSubjectListForAppeal = async (): Promise<any> => {
        try {
            this._customerModeStore.appealCreationModel.setIsSubjectListLoading(true);
            this._customerModeStore.resetAppealCreationModel();
            const data: ICustomerAppealSubjectData[] = await this._customerApiService.getSubjectList();
            const appealModel = this._customerModeStore.appealCreationModel;


            appealModel.pushAppealsSubjectList(
                reduce<ICustomerAppealSubjectData, IAppealSubjectSnapshotIn[]>(
                    data,
                    (acc, appeal) => {
                        acc.push(AppealSubject.create({
                            id: String(appeal.id),
                            label: appeal.name,
                        }));

                        return acc;
                    },
                    [],
                ),
            );

            this._customerModeStore.appealCreationModel.setIsSubjectListLoading(false);
        } catch (e) {
            this._customerModeStore.appealCreationModel.setIsSubjectListLoading(false);
            throw e instanceof Error ? e.message : e;
        }
    };

    @action createAppealInFinalState = async (appealId: number, text: string, subjectId?: number, customSubject?: string, commentId?: number): Promise<void> => {
        const isFinal = true;
        const {
            setIsAppealCreationInProgress, setIsAppealSuccessfulCreated, setIsAppealCreationError, setAppealCreationError, resetAppealCreationProcessFields,
        } = this._customerModeStore.appealCreationModel;

        resetAppealCreationProcessFields();

        try {
            setIsAppealCreationInProgress(true);
            const appeal: ICustomerCreateAppealResponseData = await this._customerApiService.createAppeal(appealId, text, isFinal, subjectId, customSubject, commentId);
            let subject = '—';
            let operatorUserName = '—';

            if (appeal.custom_subject) {
                // менее приоритетно
                subject = appeal.custom_subject;
            }

            if (appeal.customer_service_appeal_subject) {
                // более приоритетно
                subject = appeal.customer_service_appeal_subject.name;
            }

            if (appeal.user) {
                operatorUserName = appeal.user.username;
            }

            this._customerModeStore.clientCard.addAppeal({
                id: String(appeal.id),
                status: '—', // TODO: Со статусом пока что непонятки. Ждём задачку от бэка https://2wtrade-tasks.atlassian.net/browse/WCALL-4016
                createdAt: appeal.customerServiceAppeal.created_at ? moment(appeal.customerServiceAppeal.created_at * 1000).format('DD.MM.YYYY') : '—',
                jiraUrl: appeal.customerServiceAppeal.url_jira,
                appealSubject: subject,
                operatorUserName,
                clientName: appeal.customerServiceAppeal.customerServiceClientCard.full_name_client || '—',
                appealText: appeal.text,
            });

            setIsAppealCreationInProgress(false);
            setIsAppealSuccessfulCreated(true);
        } catch (e) {
            const { message } = e as Error;
            setIsAppealCreationInProgress(false);
            setIsAppealSuccessfulCreated(false);
            setIsAppealCreationError(true);
            setAppealCreationError(message);
        }
    };

    @action getClientCard = async (): Promise<any> => {
        const params: ISearchClientCardRequestData = {
            phone: this._customerModeStore.store.customerPhone,
            create_if_not_exists: this._customerModeStore.clientCard.createIfNotExist,
        };

        try {
            this._customerModeStore.clientCard.setIsLoading(true);
            const data: IClientCardData | [] = await this._customerApiService.searchClientCard(params);

            if (isArray(data)) { // карточка клиента не найдена
                this._customerModeStore.setShouldCreateClientCard(true);
                this._customerModeStore.clientCard.setIsLoading(false);
                return;
            }

            const customerCard: IClientCardData = data;
            // наполяем модель клиентской карточки
            const phone = find(customerCard.contacts, (contact) => contact.type.type === 'mobile');
            const appealsData = customerCard.customer_service_appeals;

            this._customerModeStore.createClientCard(
                ({
                    customerPhone: phone?.contact || '',
                    customerAddress: customerCard.address || '',
                    customerName: customerCard.full_name_client || '',
                    appeals: map<ICustomerAppeal, ICustomerAppealModel>(
                        appealsData,
                        (appeal) => this._prepareAppeal(appeal, customerCard.full_name_client),
                    ),
                }),
            );

            this._customerModeStore.clientCard.setIsLoading(false);
        } catch (e) {
            this._customerModeStore.clientCard.setIsLoading(false);
            throw e instanceof Error ? e.message : e;
        }
    };

    private _prepareAppeal(appeal: ICustomerAppeal, fullNameClient: string | null): ICustomerAppealModel {
        let subject = '—';
        let operatorUserName = '—';
        let appealText = '';

        const appealComments = appeal.customer_service_appeal_comments;

        if (appealComments.length) {
            const commentsSortedByCommentID = sortBy(appealComments, 'id');
            const commentsLength = commentsSortedByCommentID.length;
            const comment = commentsSortedByCommentID[commentsLength - 1]; // берем последний комментарий

            if (comment.custom_subject) {
                // менее приоритетно
                subject = comment.custom_subject;
            }

            if (comment.customer_service_appeal_subject) {
                // более приоритетно
                subject = comment.customer_service_appeal_subject.name;
            }

            if (comment.user) {
                operatorUserName = comment.user.username;
            }

            appealText = comment.text;
        }

        return ({
            id: String(appeal.id),
            status: '—', // TODO: Со статусом пока что непонятки. Ждём задачку от бэка https://2wtrade-tasks.atlassian.net/browse/WCALL-4016
            createdAt: appeal.created_at ? moment(appeal.created_at * 1000).format('DD.MM.YYYY') : '—',
            jiraUrl: appeal.url_jira,
            appealSubject: subject,
            operatorUserName,
            clientName: fullNameClient || '—',
            appealText,
        });
    }

    private _prepareCustomerOrdersForModel(orders: IOrderSearchDataCS[]): { customerOrders: ICustomerServiceOrderStage2SnapshotIn[]; productModelIds: Record<string, string> } {
        const customerOrders: ICustomerServiceOrderStage2SnapshotIn[] = [];

        // Идентификаторы моделей товаров в заказах КЛС. На них будем ссылаться в другой модели.
        const productModelIds: Record<string, string> = {};

        each<IOrderSearchDataCS>(
            orders,
            (orderData) => {
                const orderProducts: ICustomerServiceProductModelSnapshotIn[] = [];

                each<IOrderProductResourceCS>(
                    orderData.orderProducts,
                    (orderProduct) => {
                        const isGeneral = orderProduct.main; // является ли главным товаром в заказе (что-то вроде товара лида)

                        let orderProductToAdd: ICustomerServiceProductModelSnapshotIn = {
                            id: v4(),
                            name: orderProduct.product.name,
                            isGeneral,
                            partnerProductId: String(orderProduct.product.partnerProduct?.id),
                            productId: String(orderProduct.product.id),
                            price: orderProduct.price,
                            image: orderProduct.product.partnerProduct?.image,
                        };

                        const isAppliedPromo = !!(orderProduct.package && orderProduct.package_id);

                        const isGift = !isAppliedPromo
                            && orderProduct.gift
                            && !orderProduct.product.partnerProduct?.is_dynamic_set
                            && !orderProduct.product.partnerProduct?.is_product_set;

                        const isPromo = orderProduct.promo
                            && !orderProduct.product.partnerProduct?.is_dynamic_set
                            && !orderProduct.product.partnerProduct?.is_product_set;

                        const isMain = !isAppliedPromo
                            && !orderProduct.gift
                            && !orderProduct.promo
                            && !orderProduct.product.partnerProduct?.is_dynamic_set
                            && !orderProduct.product.partnerProduct?.is_product_set;

                        const isStaticSet = orderProduct.product.partnerProduct?.is_product_set
                            && !orderProduct.gift
                            && !orderProduct.promo
                            && !orderProduct.product.partnerProduct?.is_dynamic_set;

                        const isDynamicSet = orderProduct.product.partnerProduct?.is_dynamic_set
                            && orderProduct.product.partnerProduct?.is_product_set
                            && !orderProduct.gift
                            && !orderProduct.promo;

                        if (isGift) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.REGULAR,
                                subType: ProductItemSubTypeEnum.GIFT,
                            };
                        }

                        if (isPromo) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.REGULAR,
                                subType: ProductItemSubTypeEnum.PROMO,
                            };
                        }

                        if (isMain) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.REGULAR,
                                subType: ProductItemSubTypeEnum.MAIN,
                            };
                        }

                        if (isDynamicSet) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.DYNAMIC_SET,
                                subType: ProductItemSubTypeEnum.MAIN,
                            };
                        }

                        if (isStaticSet) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.DYNAMIC_SET,
                                subType: ProductItemSubTypeEnum.MAIN,
                            };
                        }

                        if (isAppliedPromo) {
                            orderProductToAdd = {
                                ...orderProductToAdd,
                                type: ProductItemTypeEnum.OLD_PROMOTION,
                                subType: ProductItemSubTypeEnum.OLD_PROMOTION,
                                isPromotion: true,
                                name: orderProduct.package!.name,
                            };
                        }

                        // собираем ID-шники моделей товаров КЛС
                        productModelIds[orderProductToAdd.id] = orderProductToAdd.id;

                        orderProducts.push(orderProductToAdd);
                    },
                );

                const order: ICustomerServiceOrderStage2SnapshotIn = {
                    id: v4(),
                    orderId: orderData.id.toString(),
                    final_price: orderData.final_price,
                    customerFullName: orderData.customer_full_name,
                    customer_phone: orderData.customer_phone,
                    customer_mobile: orderData.customer_mobile,
                    order_phones: orderData.order_phones,
                    createdAt: orderData.created_at ? moment(orderData.created_at * 1000).format('DD.MM.YYYY') : '—',
                    countryId: orderData.country_id,
                    countryFlag: orderData.country.language.icon,
                    shippingPrice: orderData.shipping_price,
                    shippingName: orderData.shipping?.name,
                    deliveryFrom: orderData.delivery_from ? moment(orderData.delivery_from * 1000).format('hh:mm') : '—',
                    deliveryTo: orderData.delivery_to ? moment(orderData.delivery_to * 1000).format('hh:mm') : '—',
                    crmStatus: orderData.statusInfo.id || null,
                    erpStatus: orderData.erpStatus?.name || null,
                    erpStatusId: orderData.erpStatus?.id.toString() || OrderStatusesERPEnum.ORDER_IS_MISSING_FROM_ERP.toString(),
                    products: convertArrayToCollection(orderProducts),
                    smsListFromErp: CustomerService._convertTextMessagesForModel(orderData.smsListFromErp),
                };

                customerOrders.push(order);
            },
        );

        return { customerOrders, productModelIds };
    }

    private async _requestATextMessageForEachOrderFromErp(
        _orders: IOrderSearchDataCS[],
        foreignIds: number[],
    ): Promise<void> {
        const promisesOfSmsFromErp: Promise<TSmsListFromErp>[] = [];

        each<number>(
            foreignIds,
            (id) => promisesOfSmsFromErp.push(
                this._orderApiService.getSms(id),
            ),
        );

        for await (const { smsList, foreignId } of promisesOfSmsFromErp) {
            const idx = findIndex<IOrderSearchDataCS>(
                _orders,
                (order) => order.foreign_id === foreignId,
            );

            if (idx !== -1) {
                Object.assign(
                    _orders[idx],
                    { smsListFromErp: smsList },
                );
            }
        }
    }

    private async _requestStatusesFromErp(
        _orders: IOrderSearchDataCS[],
        orderIds: number[],
    ): Promise<void> {
        const erpStatusesResult = await this._orderApiService.getOrderStatusFromERP(orderIds);

        if (!isEmpty(erpStatusesResult)) {
            for (const [key, value] of Object.entries(erpStatusesResult)) {
                const idx = findIndex<IOrderSearchDataCS>(
                    _orders,
                    (order) => order.id.toString() === key,
                );

                if (idx !== -1) {
                    Object.assign(
                        _orders[idx],
                        { erpStatus: value },
                    );
                }
            }
        }
    }

    private async _checkAndPullDataFromErpSystem(orders: IOrderSearchDataCS[]): Promise<IOrderSearchDataCS[]> {
        const _orders = orders;

        const orderIds = reduce<IOrderSearchDataCS, TDataForErp[]>(
            _orders,
            (acc, order) => {
                if (typeof order.foreign_id === 'number') {
                    acc.push({
                        orderId: order.id,
                        foreignId: order.foreign_id,
                    });
                }

                return acc;
            },
            [],
        );

        if (orderIds.length) {
            await this._requestStatusesFromErp(
                _orders,
                map<TDataForErp, number>(orderIds, (x) => x.orderId),
            );

            await this._requestATextMessageForEachOrderFromErp(
                _orders,
                map<TDataForErp, number>(orderIds, (x) => x.foreignId),
            );
        }

        return _orders;
    }

    /**
     * Этап 2 Клиентский сервис
     * Наполнение модели заказами
     */
    @action getOrdersByParamsStage2 = async (): Promise<any> => {
        const params: IGetSearchOrdersParam = {
            customer_phone: this._customerModeStore.store.customerPhone,
            depth: this._customerModeStore.store.depth,
            limit: this._customerModeStore.store.limit,
        };

        try {
            this._customerModeStore.store.setIsLoading(true);
            const ordersList: IOrderSearchDataCS[] = await this._customerApiService.searchOrders(params);
            const verifiedOrdersInERP: IOrderSearchDataCS[] = await this._checkAndPullDataFromErpSystem(ordersList);

            const { customerOrders, productModelIds } = this._prepareCustomerOrdersForModel(verifiedOrdersInERP);

            applySnapshot(
                this._customerModeStore.orders,
                convertArrayToCollection(customerOrders) || {},
            );

            applySnapshot(this._customerModeStore.ordersFilter, {
                _clientName: '',
                _orderId: '',
                _phoneNumber: '',
                _products: productModelIds,
            });

            this._customerModeStore.store.setIsLoading(false);
        } catch (e) {
            this._customerModeStore.store.setIsLoading(false);
            throw e instanceof Error ? e.message : e;
        }
    };

    /**
     * Этап 2 Клиентский сервис
     * Наполнение модели статьями FAQ
     */
    @action getFAQArticlesByParamsStage2 = async (): Promise<any> => {
        this._customerModeStore.setFaqPending(true);
        this._customerModeStore.clearFaqArticles();
        const ordersValues = [...this._customerModeStore.orders.values()];

        if (ordersValues.length === 0) {
            this._customerModeStore.setFaqPending(false);

            return;
        }

        const uniqCountryIds = ordersValues.reduce(
            (ids: number[], item) => {
                const { countryId } = item;
                if (!ids.includes(countryId)) {
                    ids.push(countryId);
                }

                return ids;
            },
            [],
        );

        const uniqProductIds = ordersValues.reduce(
            (ids: number[], item) => {
                item.products.forEach((pr) => {
                    if (!ids.includes(Number(pr.productId))) {
                        ids.push(Number(pr.productId));
                    }
                });

                return ids;
            },
            [],
        );

        const args = {
            countryIds: uniqCountryIds,
            productIds: uniqProductIds,
        };

        const itemsRaw = await this._userApiService.getFAQArticlesList(args);

        if (itemsRaw.length > 0) {
            const articles = map(itemsRaw, (item): ICustomerServiceFAQArticleStage2Model => (
                CustomerServiceFAQArticleStage2.create({
                    id: String(item.id),
                    title: item.title,
                    payload: item.text,
                })));

            this._customerModeStore.setFaqArticles(articles);
        }

        this._customerModeStore.setFaqPending(false);
    };

    @action getOrdersByParams = async (): Promise<any> => {
        const params: IGetSearchOrdersParam = {
            customer_phone: this._customerModeStore.store.customerPhone,
            order_id: this._customerModeStore.store.customerOrderId,
            customer_full_name: this._customerModeStore.store.customerName, // TODO: проверить параметры snake или camel
            depth: this._customerModeStore.store.depth,
            limit: this._customerModeStore.store.limit,
        };

        try {
            this._customerModeStore.store.setIsLoading(true);
            const ordersList = await this._customerApiService.searchOrders(params);

            this._customerModeStore.clearCustomerServiceOrderList();

            each(
                ordersList || [],
                (order) => {
                    const products = map<IOrderViewProduct, IOrderViewProductModelSnapshotIn>(
                        order.orderProducts,
                        (item) => ({
                            id: String(item.product.id),
                            image: item.product.partnerProduct?.image || '',
                            name: item.product.name,
                            price: item.price,
                            quantity: item.quantity,
                            cost: item.cost, // TODO: Убрать cost
                            gift: item.gift || false,
                            promo: item.promo || false,
                            isProductSet: item.product.partnerProduct?.is_product_set || false,
                            isDynamicSet: item.product.partnerProduct?.is_dynamic_set || false,
                        }),
                    );

                    const deliveryFrom = order.delivery_from ? moment(order.delivery_from * 1000).format('hh:mm') : '—';
                    const deliveryTo = order.delivery_to ? moment(order.delivery_to * 1000).format('hh:mm') : '—';
                    const createdAt = moment(order.created_at * 1000).format('DD.MM.YYYY');

                    const customerServiceOrderModel = CustomerServiceOrder.create({
                        id: order.id,
                        customer_address: order.customer_address || '—',
                        final_price: order.final_price,
                        customerFullName: order.customer_full_name || '—',
                        customer_phone: order.customer_phone || '—',
                        customer_mobile: order.customer_mobile || '—',
                        createdAt,
                        currency: order.currency ? order.currency.char_code : null,
                        country: {
                            charCode: order.country.char_code,
                            name: order.country.name,
                        },
                        orderType: order.orderType,
                        source: order.source,
                        shippingPrice: order.shipping_price,
                        products,
                        deliveryFrom,
                        deliveryTo,
                        shippingName: order.shipping?.name || '—',
                    });

                    this._customerModeStore.setCustomerServiceOrderList(customerServiceOrderModel);
                },
            );

            this._customerModeStore.store.setIsLoading(false);
        } catch (e) {
            throw e instanceof Error ? e.message : e;
        } finally {
            this._customerModeStore.store.setIsLoading(false);
        }
    };
}

export default CustomerService;
