import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { MatchMediaHOC } from 'react-match-media';

import './includes/Recalls.scss';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '../bottom-mobile-menu/BottomMobileTabs';
import RecallsDesktopComponent from './RecallsDesktopComponent';
import RecallsMobileComponent from './RecallsMobileComponent';


const RecallsPage: FunctionComponent = (): JSX.Element => {
    const RecallsMobile = MatchMediaHOC(RecallsMobileComponent, '(max-width: 480px)');
    const RecallsDesktop = MatchMediaHOC(RecallsDesktopComponent, '(min-width: 481px)');

    return (
        <>
            <div className="recalls-page">
                <RecallsMobile />
                <RecallsDesktop />
            </div>
            <BottomMobileTabs page={BottomMobileTabsPageEnum.RECALLS} />
        </>
    );
};


export default observer(RecallsPage);
