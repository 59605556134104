import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';


export const LENGTH_OF_MASKED_PHONE_END = 4;


export const CustomerPhone = t
    .model('CustomerPhone', {
        id: t.identifier,
        originalValue: t.optional(t.string, ''), // исходное значение (не меняется)
        _variablePhonePart: t.optional(t.string, ''), // изменяемая часть телефона
        maskPhone: t.optional(t.boolean, false),
        phoneEditingRangeInfo: t.optional(t.integer, 0),
        phoneForUI: t.optional(t.string, ''),
        isACreatedOrder: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get originalPhoneNumberLength(): number {
            return self.originalValue.length;
        },
    }))
    .views((self) => ({
        /**
         * Фактически изменяемая часть телефона - вызываем 1 раз после создания модели customerPhone
         */
        get variablePhonePart(): string {
            if (self.maskPhone) {
                if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что можно номер нельзя редактировать, а в изменяемой части пусто (можем вбить туда 3 символа)
                    return '';
                }

                // если интервал редактирования телефона >= длине номера телефона МИНУС 4 символа (длина маскируемой части), то возвращаем всё кроме маски
                if (self.phoneEditingRangeInfo >= self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    return self.originalValue.slice(0, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);
                }

                // если интервал редактирования < длины номера телефона МИНУС 4 символа (длина маски)
                if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    // берем кусок номера, который между маской и редактируемой частью
                    return self.originalValue.slice(0, self.phoneEditingRangeInfo);
                }
            }

            if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                return '';
            }

            if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength) {
                return self.originalValue.slice(0, self.phoneEditingRangeInfo);
            }

            // если phoneEditingRangeInfo больше или равен длине номера телефона => можно редактировать весь номер целиком
            return self.originalValue;
        },
        /**
         * Неизменяемая часть телефона
         */
        get invariablePhonePart(): string {
            if (self.maskPhone) {
                const lastFourDigitsMasked = 'XXXX';

                if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                    const unmaskedPart = self.originalValue.slice(0, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);

                    return unmaskedPart.concat(lastFourDigitsMasked);
                }

                // если интервал редактирования телефона >= длине номера телефона МИНУС 4 символа (длина маскируемой части), то у нас останется только маска
                if (self.phoneEditingRangeInfo >= self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    return lastFourDigitsMasked;
                }

                // если интервал редактирования < длины номера телефона МИНУС 4 символа
                if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    // берем кусок номера, который между маской и редактируемой частью
                    const unmaskedPart = self.originalValue.slice(self.phoneEditingRangeInfo, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);

                    return unmaskedPart.concat(lastFourDigitsMasked);
                }
            }

            if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                return self.originalValue;
            }

            if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength) {
                // берем кусок номера, который остается после редактируемой части
                const unmaskedPart = self.originalValue.slice(self.phoneEditingRangeInfo, self.originalPhoneNumberLength);

                return unmaskedPart;
            }

            // если phoneEditingRangeInfo больше или равен длине номера телефона => можно редактировать весь номер целиком
            return '';
        },
        get endPhoneNumber() {
            return this.invariablePhonePart.slice(-LENGTH_OF_MASKED_PHONE_END);
        },
    }))
    .views((self) => ({
        /**
         * Длина по факту изменяемой части телефона
         */
        get phoneEditingRange(): number {
            return self.variablePhonePart.length;
        },
        /**
         * Используется в коде. Итоговый, возможно отредактированный номер телефона
         */
        get phoneForCode(): string {
            const { invariablePhonePart, phoneForUI, maskPhone, originalValue, isACreatedOrder } = self;

            // если тип заказа созданный, то берем смело
            // весь телефон для UI - он точно не закодирован и его не надо сращивать с нередактируемой частью

            const firstPartNumber = isACreatedOrder ? phoneForUI : phoneForUI.slice(0, -invariablePhonePart.length);

            if (!maskPhone) {
                return firstPartNumber.concat(invariablePhonePart);
            }
            // отрезаем маску и вместо нее цепляем последние 4 символа из исходного номера
            const unMaskedValue = invariablePhonePart
                .slice(0, -LENGTH_OF_MASKED_PHONE_END)
                .concat(originalValue
                    .slice(originalValue.length - LENGTH_OF_MASKED_PHONE_END));

            return firstPartNumber.concat(unMaskedValue);
        },
    }))
    .actions((self) => ({
        setPhoneForUI(value: string): void {
            self.phoneForUI = value;
        },
    }))
    .actions(((self) => ({
        afterCreate() {
            self.setPhoneForUI(self.variablePhonePart + self.invariablePhonePart);
        },
        setValue(value: string): void {

            // Если интервал редактирования равен нулю и это не созданный заказ запрещаемм ввод
            // Если вводимое значение ме6ньше нередактируемой части запрещаем ввод
            if (self.phoneEditingRangeInfo === 0 && !self.isACreatedOrder || value.length < self.invariablePhonePart?.length) {
                return;
            }

            // При пустой строке
            if (value.length === 0) {
                self.setPhoneForUI(value);
            }

            // Запрещаем вводить больше цыфр чем длина у пришедшего телефонного номера + 1 цифра
            if (self.originalValue.length && value.length > self.originalValue.length + 1) {
                return;
            }

            // Не даем стирать нередактируюемую часть
            if (!value.endsWith(self.invariablePhonePart)) {
                value = value.slice(0, self.phoneEditingRange) + self.invariablePhonePart;
            }

            // В регулярном выражении учитывается два условия -
            // 1) номер состояит полностью из цыфр
            // 2) номер закнчивается шифрованием XXXX

            const isNum = /^\d*X{0,4}$/.test(value);

            if (isNum) {
                self.setPhoneForUI(value);
            }
        },
        // Ограничиваем редактирование только до части, которая задана переменной rangePhoneLimit
        onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {

            const { selectionStart } = e.currentTarget!;
            const { phoneEditingRange, phoneForUI, invariablePhonePart, originalPhoneNumberLength } = self;

            // Если осталась только нередактируемая часть при нажатии на клавишу переводим фокус в начало номера;
            if (phoneForUI.length === invariablePhonePart.length) {
                return e.currentTarget!.setSelectionRange(0, 0);
            }

            // Проверяем, находится ли курсор за пределами редактируемой части
            if (phoneEditingRange && selectionStart! >= phoneEditingRange + 1) {
                e.preventDefault();

                /*
                Если интервал редактирования больше чем длина всего номера минус 4 (длина шифровки) и номер зашифрован, то переносим фокус
                на длина всего номера минус 4 (длина шифровки)
                 */
                const lengthUnMaskPhoneNumber = self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END;
                const isMaskedEnd = phoneForUI.endsWith('XXXX');
                const isFullNumber = phoneForUI.length === originalPhoneNumberLength + 1;

                // Определяем новую позицию фокуса в зависимости от условий
                const range = phoneEditingRange > lengthUnMaskPhoneNumber && isMaskedEnd
                    ? lengthUnMaskPhoneNumber
                    : isFullNumber
                        ? phoneEditingRange + 1
                        : phoneEditingRange;

                e.currentTarget!.setSelectionRange(0, range);
            }
        },
    })));


export interface ICustomerPhoneModel extends Instance<typeof CustomerPhone> {}
export interface ICustomerPhoneSnapshotIn extends SnapshotIn<typeof CustomerPhone> {}
export interface ICustomerPhoneSnapshotOut extends SnapshotOut<typeof CustomerPhone> {}
