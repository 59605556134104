import { toFactory } from 'react-ioc';

import SipService from '@services/sip/SipService';
import { Store } from '@store/store';
import CallHistoryApiService from '@api/call-history-api-service';
import I18NService from '@services/I18NService';
import ErrorService from '@core/services/ErrorService';
import UserService from '@services/UserService';
import SipServiceBase from '@services/sip/SipServiceBase';
import { ISipServiceBase } from '@services/sip/models';
import { OrderService } from '@app/services';
import ModalService from '@core/services/ModalService';
import CookieService from '@core/services/CookiesService';


const SipServiceDI = (): any => [
    SipService,
    toFactory(
        [
            Store,
            CallHistoryApiService,
            I18NService,
            ErrorService,
            UserService,
            SipServiceBase,
            OrderService,
            ModalService,
            CookieService,
        ],
        (
            store: Store,
            callHistoryApiService: CallHistoryApiService,
            I18NService: I18NService,
            errorService: ErrorService,
            userService: UserService,
            sip: ISipServiceBase,
            orderService: OrderService,
            modalService: ModalService,
            cookieService: CookieService,
        ) => new SipService(
            store,
            callHistoryApiService,
            I18NService,
            errorService,
            userService,
            sip,
            orderService,
            modalService,
            cookieService,
        ),
    ),
];


export default SipServiceDI;
