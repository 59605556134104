import { IProductItemModel } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import { observer } from 'mobx-react';
import React from 'react';
import { useManageCatalogPromoItem } from './helpers/useManageCatalogPromoItem';
import PromoItemToAdd from './PromoItemToAdd';

interface IPromoItemToAddSmartProps {
    catalogItem: IProductItemModel;
    readonly dataCyValue: number;
}

/**
 * Родитель для dummy компонента промо товара PromoItemToAdd
 * Использует хук, который передает внутрь компонента всю нужную информацию
 */
const PromoItemToAddSmart = ({
    catalogItem,
    dataCyValue,
}: IPromoItemToAddSmartProps): JSX.Element => {
    const [
        onClick, isYetInBasket, preparedCatalogItemForView, disabled, preparedTranslations, isConditionForAPromo, discountAmount,
    ] = useManageCatalogPromoItem(catalogItem);

    return (
        <PromoItemToAdd
            onClick={onClick}
            isYetInBasket={isYetInBasket}
            preparedTranslations={preparedTranslations}
            disabled={disabled}
            catalogItem={preparedCatalogItemForView}
            isConditionForAPromo={isConditionForAPromo}
            discountAmount={discountAmount}
            dataCyValue={dataCyValue}
        />
    );
};


export default observer(PromoItemToAddSmart);
