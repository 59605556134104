import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';


export const KladrResult = t
    .model('KladrResult', {
        id: t.identifier,
        text: t.maybeNull(t.string),
        path: t.maybeNull(t.string),
        depth: t.maybeNull(t.number),
        sort: t.maybeNull(t.number),
        name: t.maybeNull(t.string),
        formAttributeName: t.maybeNull(t.string),
        partnerFormId: t.maybeNull(t.number),
        partnerFormAttributeId: t.maybeNull(t.number),
        num: t.maybeNull(t.number),
        countryId: t.maybeNull(t.string),
        confirmData: t.optional(t.array(t.string), []),
    })
    .views(((self) => ({
        get hasValueInTheConfirmData(): boolean {
            if (!self.name) {
                return false;
            }

            return self.confirmData.includes(self.name);
        },
    })));


export interface IKladrResultModel extends Instance<typeof KladrResult> {}
export interface IKladrResultModelSnapshotIn extends SnapshotIn<typeof KladrResult> {}
export interface IKladrResultModelSnapshotOut extends SnapshotOut<typeof KladrResult> {}
