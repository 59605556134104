import VerifierApiService from '@api/verifier-api-service';
import RecallsService from '@services/RecallsService';
import { applySnapshot } from 'mobx-state-tree';
import { Store } from '@store/store';
import { TRecall } from '@api/recalls-api-service/models';
import { IOrder } from '@api/order-api-service/models';
import OrderApiService from '@api/order-api-service';


class VerifierService {
    constructor(
        private readonly _verifierApiService: VerifierApiService,
        private readonly _recallsService: RecallsService,
        private readonly _store: Store,
        private readonly _orderApiService: OrderApiService,
    ) {}

    public applySnapshotVerifierResult = (result: TRecall[] | IOrder[] | void) => {
        if (result) {
            const verifierSnapshotIn = this._recallsService.prepareRecalls(result);
            if (verifierSnapshotIn) {
                applySnapshot(this._store.verifiers, verifierSnapshotIn);
            }
        }
    };

    public fetchVerifierList = async (id: number): Promise <void> => {

        try {
            const result = await this._verifierApiService.getByForeignId(id);
            this.applySnapshotVerifierResult(result);
        } catch (e) {
            console.log(e);
        }
    };

    public fetchGetOrderViewById = async (id: number): Promise<void> => {
        try {
            const result = await this._orderApiService.getOrderViewById(id);
            this.applySnapshotVerifierResult([result?.order]);
        } catch (e) {
            console.log(e);
        }
    };

    public unblockVerifier = async (id: number): Promise <boolean | void> => {

        try {
            const result = await this._verifierApiService.unblockVerificatedOrder(id);
            return result;
        } catch (e) {
            console.log(e);
        }
    };
}

export default VerifierService;