import get from 'lodash/get';
import ApiBase from '@core/api';
import { getEnvGetLanguages, getEnvSetUserLocale } from '@/env/requestEndpoints';
import { ILanguageData, ISetUserLocaleResponse } from './model';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error';
    message?: string;
    data: T;
}


class TranslationApiService {
    @Inject apiBase: ApiBase;

    /**
     * Получить список языков для смены в интерфейсе
     */
    async getLanguages(): Promise<ILanguageData[] | never> {
        const response = await this.apiBase.get<IWithResponse<ILanguageData[]>>(getEnvGetLanguages());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as ILanguageData[] | never;
    }

    /**
     * Установить пользователю язык интерфейса
     */
    async setUserLocale(languageId: number): Promise<boolean | never> {
        const response = await this.apiBase.post<IWithResponse<ISetUserLocaleResponse>>(
            getEnvSetUserLocale(),
            {
                locale_id: languageId,
            },
        );

        const { message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return isSuccess;
    }
}


export default TranslationApiService;
