import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IArrowRightSvg {
    className?: string;
}


const ArrowRightSvg: FunctionComponent<IArrowRightSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['ArrowRightSvg', className])}
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.87636 13.1245C5.1549 13.4031 5.60396 13.4031 5.88249 13.1245L10.6062 8.40083C10.8279 8.17914 10.8279 7.82103 10.6062 7.59934L5.88249 2.87565C5.60396 2.59711 5.1549 2.59711 4.87636 2.87565C4.59783 3.15418 4.59783 3.60324 4.87636 3.88178L8.99183 8.00292L4.87068 12.1241C4.59783 12.3969 4.59783 12.8517 4.87636 13.1245Z"
            fill="#8E8E93"
        />
    </svg>
);


export default ArrowRightSvg;
