import { action, computed, observable } from 'mobx';

import { Store } from '@store/store';
import OrderApiService from '@api/order-api-service';
import PartnerApiService from '@api/partner-api-service';
import SourceApiService from '@api/source-api-service';
import { IOrderCreationModel, IOrderCreationModelSnapshotIn } from '@models/mobx-state-tree/orderCreation.model';
import { IPartnerModelSnapshotIn } from '@models/mobx-state-tree/partner.model';
import { ISourceModelSnapshotIn } from '@models/mobx-state-tree/source.model';
import OrderFetchService from '@services/order/OrderFetchService';
import UserApiService from '@api/user-api-service';
import { PrepareTypes } from '@services/order/models';
import { ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';
import { ISourceType } from '@api/source-api-service/models';
import CountryApiService from '@core/api/country-api-service';
import { ICountryCurrencyItemWithCodeKey } from '@core/api/country-api-service/models';
import { ICurrencyModelSnapshotIn } from '@models/mobx-state-tree/currency.model';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { SipService } from '@services/index';
import { IFormModel } from '@models/mobx-state-tree/form.model';
import ModalService from '@core/services/ModalService';
import XHR404Error from '@core/error/XHR404Error';
import { ConfirmModalAction, ConfirmModalType } from '@core/models/ModalWindow';
import isShouldSendLogToKibana from '@/env/isShouldSendLogToKibana';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
import KibanaService from '@services/KibanaService';
import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';
import OrderBase from '@services/order/OrderBase';
import OrderPrepareMetadata from '@/app/order-prepare/OrderPrepareMetadata';
import { IPartnerCountry } from '@/app/core/api/partner-api-service/models';


class OrderCreationService extends OrderBase {
    @observable
    private _waitingCreatedOrder = false;

    @observable
    private _waitingDataDownload = false;

    private get mode(): UserModeEnum {
        return this._store.currentUser.mode;
    }

    private get currentForm(): IFormModel {
        return this._store.currentOrder.form;
    }

    private get _orderCreationModel(): IOrderCreationModel {
        return this._store.orderCreation;
    }

    private get _selectedCountryId(): string | null {
        return this._orderCreationModel.selectedCountryId;
    }

    private get _selectedSourceId(): string | null {
        return this._orderCreationModel.selectedSourceId;
    }

    private get _selectedCurrencyId(): string | null {
        return this._orderCreationModel.selectedCurrencyId;
    }

    private get _selectedPartnerId(): string | null {
        return this._orderCreationModel.selectedPartnerId;
    }

    private get _selectedSourceTypeId(): string | null {
        return this._orderCreationModel.selectedSourceTypeId;
    }

    public set waitingCreatedOrder(val: boolean) {
        this._waitingCreatedOrder = val;
    }

    @computed
    public get waitingCreatedOrder(): boolean {
        return this._waitingCreatedOrder;
    }

    public set waitingDataDownload(val: boolean) {
        this._waitingDataDownload = val;
    }

    @computed
    public get waitingDataDownload(): boolean {
        return this._waitingDataDownload;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderApiService: OrderApiService,
        private readonly _partnerApiService: PartnerApiService,
        private readonly _sourceApiService: SourceApiService,
        private readonly _userApiService: UserApiService,
        private readonly _orderFetchService: OrderFetchService,
        private readonly _countryApiService: CountryApiService,
        private readonly _sipService: SipService,
        private readonly _modalService: ModalService,
        private readonly _I18NService: I18NService,
        private readonly _kibanaService: KibanaService,
    ) {
        super();
    }

    private get _t(): customI18NTFunction {
        return this._I18NService.t as customI18NTFunction;
    }

    private async fetchDataForFirstStep(): Promise<(ISourceModelSnapshotIn[] | IPartnerModelSnapshotIn[])[]> {
        this.waitingDataDownload = true;
        try {
            return await Promise.all([
                this._sourceApiService.getSourceList(),
                this._partnerApiService.getPartnerList(),
            ]);
        } catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        } finally {
            this.waitingDataDownload = false;
        }
    }

    private fetchPartnerCountries(): Promise<IPartnerCountry[]> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return this._partnerApiService.getParnterCountryList(Number(this._selectedPartnerId));
        }  catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        }
    }

    @action public async initializationCountries(): Promise<void>{
        const orderPrepareMetadataState = new OrderPrepareMetadata();
        const countriesList = await this.fetchPartnerCountries();

        const countriesSnapshotIn = orderPrepareMetadataState.prepareForCollection(
            countriesList,
            PrepareTypes.COUNTRIES,
        ) as Record<string, ICountryModelSnapshotIn>;

        this._orderCreationModel.setCountries(countriesSnapshotIn);
    }

    private async fetchSourcesTypes(): Promise<ISourceType[]> {
        try {
            return await this._sourceApiService.getSourceTypeList(
                Number(this._selectedCountryId),
                Number(this._selectedPartnerId),
                Number(this._selectedSourceId),
            );
        } catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        }
    }

    @action
    public async initializationSourceTypes(): Promise<void> {
        const orderPrepareMetadataState = new OrderPrepareMetadata();
        const sourceTypes = await this.fetchSourcesTypes();

        const sourceTypesSnapshotIn = orderPrepareMetadataState.prepareForCollection(
            sourceTypes,
            PrepareTypes.SOURCE_TYPES,
        ) as Record<string, ISourceModelSnapshotIn>;
        this._orderCreationModel.setSourceTypes(sourceTypesSnapshotIn);
    }

    private async fetchCurrencyForCountryAndPartner(): Promise<ICountryCurrencyItemWithCodeKey[]> {
        try {
            return await this._countryApiService.getCurrencyForCountryAndPartner(
                Number(this._selectedCountryId),
                Number(this._selectedPartnerId),
            );
        } catch (e) {
            throw new Error(e instanceof Error ? e.message : e);
        }
    }

    @action
    public async initializationCurrency(): Promise<void> {
        const orderPrepareMetadataState = new OrderPrepareMetadata();
        const currencies = await this.fetchCurrencyForCountryAndPartner();

        const currenciesSnapshotIn = orderPrepareMetadataState.prepareForCollection(
            currencies,
            PrepareTypes.CURRENCIES,
        ) as Record<string, ICurrencyModelSnapshotIn>;

        this._orderCreationModel.setCurrency(currenciesSnapshotIn);
    }

    @action
    public async initializationOfTheOrderCreationModel(): Promise<void> {
        const orderPrepareMetadataState = new OrderPrepareMetadata();

        const [sourcesList, partnerList] = await this.fetchDataForFirstStep();

        const sources = orderPrepareMetadataState.prepareForCollection(
            sourcesList,
            PrepareTypes.MAIN_DATA,
        ) as Record<string, ISourceModelSnapshotIn>;
        const partners = orderPrepareMetadataState.prepareForCollection(
            partnerList,
            PrepareTypes.MAIN_DATA,
        ) as Record<string, IPartnerModelSnapshotIn>;

        const orderCreationModelSnapshotIn: IOrderCreationModelSnapshotIn = {
            sources,
            partners,
        };

        this._store.setOrderCreationModel(orderCreationModelSnapshotIn);
    }

    private _enterClientPhone(): void {
        const { phoneNumber, isCallingRightNow } = this._sipService;
        const { customerPhone } = this.currentForm;

        if (isCallingRightNow && customerPhone) {
            customerPhone.setValue(phoneNumber);
        }
    }

    @action
    public async createOrder(): Promise<void> {
        this.waitingCreatedOrder = true;

        let createdOrderId: null | number = null;

        try {
            const { order } = await this._orderApiService.createOrder(
                Number(this._selectedCountryId),
                Number(this._selectedPartnerId),
                Number(this._selectedCurrencyId),
                Number(this._selectedSourceId),
                Number(this._selectedSourceTypeId),
            );

            createdOrderId = order.id;

            await this._orderFetchService.downloadAdditionalOrderingDataAfterFirstStepCreation(order, true);

            if (this.mode === UserModeEnum.CLIENT_SERVICE) {
                this._enterClientPhone();
            }
        } catch (e) {
            if (e instanceof XHR404Error) {
                throw await this._modalService.showConfirmModal(e.message, ConfirmModalType.Yes);
            }

            if (OrderCreationService.theseAreErrorsFromCatalogAndBasketPrepareService(e)) {

                const { problemItems, message, problemSetId } = e as IBasicOrderPrepareCatalogAndBasketError;

                if (problemSetId) {

                    e.message = OrderCreationService.addProblematicProductsAndSetIdToErrorMessage(message, problemItems, problemSetId);

                    // eslint-disable-next-line no-console
                    console.warn(e);
                } else {

                    e.message = OrderCreationService.addProblematicProductsToErrorMessage(message, problemItems);

                    // eslint-disable-next-line no-console
                    console.warn(e);
                }

                if (isShouldSendLogToKibana()) {
                    const dataToSend = [{
                        countryId: this._selectedCountryId,
                        partnerId: this._selectedPartnerId,
                        currencyId: this._selectedCurrencyId,
                        sourceId: this._selectedSourceId,
                        sourceTypeId: this._selectedSourceTypeId,
                    }];

                    if (createdOrderId) {
                        await this._kibanaService.sendLog(e, createdOrderId, dataToSend);
                    } else {
                        await this._kibanaService.sendLog(e, -1, dataToSend);
                    }
                }

                await this._modalService.showConfirmModal(this._t(
                    'Настройки страны, партнера, источника имеют неправильную конфигурацию в части настройки товаров. Работа с созданным заказом невозможна. Обратитесь в поддержку.',
                    'Setting of country, partner, source has an incorrect configuration in terms of product settings. It is not possible to work with the created order. Please contact support.',
                ), ConfirmModalType.Yes, 10000, ConfirmModalAction.Yes);

                if (createdOrderId) {
                    try {
                        await this._orderApiService.releaseOrder({ orderId: createdOrderId });
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.warn(e);
                    }
                }

                this._store.clearCurrentOrder();
                return;
            }

            throw new Error(e instanceof Error ? e.message : e);
        } finally {
            this.waitingCreatedOrder = false;
        }
    }

    @action
    public clearOrderCreationModel(): void {
        this._store.clearOrderCreationModel();
    }
}


export default OrderCreationService;
