import get from 'lodash/get';

const UNKNOWN_CONNECTION_TYPE = 'unknown connection type';

const getConnectionType: () => string = () => {
    const _navigator: Navigator | any = navigator;

    if (!_navigator) {
        return UNKNOWN_CONNECTION_TYPE;
    }

    const connection = _navigator.connection || _navigator.mozConnection || _navigator.webkitConnection;
    let connectionType = UNKNOWN_CONNECTION_TYPE;
    if (connection) {
        connectionType = get(connection, 'effectiveType', UNKNOWN_CONNECTION_TYPE);
    }

    return connectionType;
};

export default getConnectionType;
