import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import ContentBlock from '@UIElements/ContentBlock';
import OrderTableMobile from '@components/main/order-page/order-products/order-table-mobile/OrderTableMobile';
import OrderTableDesktop from '@components/main/order-page/order-products/order-table-desktop/OrderTableDesktop';
import { MatchMediaHOC } from 'react-match-media';

const Small = MatchMediaHOC(OrderTableMobile, '(max-width: 700px)');
const Large = MatchMediaHOC(OrderTableDesktop, '(min-width: 701px)');


const OrderProducts: FunctionComponent = (): JSX.Element => (
    <ContentBlock
        id="order-items"
        isWithoutHeader={true}
    >
        <>
            <Small />
            <Large />
        </>
    </ContentBlock>
);


export default observer(OrderProducts);
