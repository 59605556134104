import React, { FunctionComponent } from 'react';
import IconCrossSvg from '@components/resources/SVG/Cross/IconCrossSvg';


interface IClearSelectionButtonProps {
    className?: string;
    onClick: () => void;
}

const ClearSelectionButton: FunctionComponent<IClearSelectionButtonProps> = ({ className, onClick, ...props }) => (
    <div
        className={className}
        onClick={onClick}
        {...props}
    >
        <IconCrossSvg />
    </div>
);


export default ClearSelectionButton;
