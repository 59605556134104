import { FunctionComponent } from 'react';
import { action, computed, observable } from 'mobx';

import EmptyComponent from '@core/constants/EmptyComponent';
import ConfirmModal from '@UIElements/ConfirmModal';
import ErrorNotifyModal, { IErrorNotifyModal } from '@UIElements/ErrorNotifyModal/ErrorNotifyModal';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
import ModalWindow, {
    ConfirmModalAction,
    ConfirmModalType,
    DEFAULT_MODAL_ID,
} from '@core/models/ModalWindow';
import { IConfirmModalProps } from '@UIElements/ConfirmModal/ConfirmModal';


class ModalService {
    @observable
    private _openModalStack: ModalWindow<any, any>[] = [];

    private _temporaryModalStack: ModalWindow<any, any>[] = [];

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    @computed
    private get _currentModalWindow(): ModalWindow<any, any> {
        return this._openModalStack[this._openModalStack.length - 1];
    }

    private get _temporaryModalWindow(): ModalWindow<any, any> {
        return this._temporaryModalStack[0];
    }

    @computed
    public get visible(): boolean {
        return Boolean(this._currentModalWindow.visible);
    }

    private _isEmptyOpenModalStack(): boolean {
        return this._openModalStack.length === 1;
    }

    private _isEmptyTemporaryModalStack(): boolean {
        return this._temporaryModalStack.length === 0;
    }

    @computed
    public get hideModalHeader(): boolean {
        return this._currentModalWindow.hideModalHeader;
    }

    @computed
    public get template(): FunctionComponent | null {
        return this._currentModalWindow.template;
    }

    @computed
    public get currentModalId(): number | null {
        return this._currentModalWindow.id;
    }

    @computed
    public get isAFreeState(): boolean {
        return !this._currentModalWindow.modalEvent && !this.visible;
    }

    constructor(private readonly _I18NService: I18NService) {

        this._openModalStack.push(
            new ModalWindow<null, any>(
                false,
                EmptyComponent,
                null,
                false,
            ),
        );
    }

    private _removeModalFromStack(modalId: number): void {
        this._openModalStack = this._openModalStack.filter(
            (item: ModalWindow<any, any>) => item.id !== modalId,
        );
    }

    private _removeModalFromStackById(id: number): void {
        this._removeModalFromStack(id);
    }

    private _removeModalFromStackByCurrentModalId(): void {
        if (this.currentModalId !== null) {
            this._removeModalFromStack(this.currentModalId);
        }
    }

    private _eventSubscription<T1, TResult>(template: FunctionComponent<T1>, hideModalHeader?: boolean | undefined): Promise<TResult> {

        return new Promise((resolve, reject) => {
            const modalStack = this._isEmptyOpenModalStack() ? this._openModalStack :
                this._temporaryModalStack;

            modalStack.push(
                new ModalWindow<T1, TResult>(
                    true,
                    template,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    { resolve, reject },
                    hideModalHeader,
                ),
            );

        });
    }

    private _addTemporaryModalWindow() {
        if (!this._isEmptyTemporaryModalStack()) {
            this._openModalStack.push(this._temporaryModalWindow);
            this._clearTemporaryModalStack();
        }
    }

    @action
    public showModal = <TProps = unknown, TResult = boolean>(
        template: FunctionComponent<TProps>,
        props?: TProps,
        hideModalHeader?: boolean | undefined,
    ): Promise<TResult> => {
        template.defaultProps = props || {};
        Object.assign(
            template.defaultProps,
            {
                cancel: this.cancel,
                success: this.success,
                t: this._t,
            },
        );

        return this._eventSubscription<TProps, TResult>(template, hideModalHeader);
    };

    @action
    public showConfirmModal = (
        text: string,
        type: ConfirmModalType,
        timeout?: number,
        defaultAction?: ConfirmModalAction,
    ): Promise<boolean> => {
        ConfirmModal.defaultProps = {
            confirmationText: text,
            confirmationType: type,
            confirmationTimeout: timeout,
            confirmationDefaultAction: defaultAction,
            success: this.success,
            cancel: this.cancel,
            t: this._t,
        };

        return this._eventSubscription<IConfirmModalProps, boolean>(ConfirmModal);
    };

    @action
    public showErrorNotificationModal = (text: string): Promise<boolean> => {
        ErrorNotifyModal.defaultProps = {
            confirmationText: text,
            success: this.success,
            cancel: this.cancel,
            t: this._t,
        };

        return this._eventSubscription<IErrorNotifyModal, boolean>(ErrorNotifyModal);
    };

    @action
    public closeModalById = (id: number): void => {
        const modal = this._openModalStack.find(
            (modal: ModalWindow<any, any>) => modal.id === id,
        );

        if (modal) {
            modal.cancel();
            this._removeModalFromStackById(id);
        } else {
            throw new Error(
                this._t(
                    'Невозможно закрыть неопределенное модальное окно.',
                    'Unable to close undefined modal.',
                ),
            );
        }
    };

    @action
    public closeCurrentModal = (): void => {
        const currentModalId = this.currentModalId;
        if (currentModalId) {
            this.closeModalById(currentModalId);
        }
    };

    @action
    public cancel = <T>(data: T | any = null): void => {
        this._currentModalWindow.cancel(data);
        this._removeModalFromStackByCurrentModalId();
        this._addTemporaryModalWindow();
    };

    @action
    public success = <T>(data: boolean | T = true): void => {
        this._currentModalWindow.success(data);
        this._removeModalFromStackByCurrentModalId();
        this._addTemporaryModalWindow();
    };

    @action
    public clearModalStack = (): void => {
        this._openModalStack = this._openModalStack.filter(
            (modal: ModalWindow<any, any>) => modal.id === DEFAULT_MODAL_ID,
        );
    };

    private _clearTemporaryModalStack = (): void => {
        this._temporaryModalStack = this._temporaryModalStack.filter(
            (modal: ModalWindow<any, any>, index) => index !== 0,
        );
    };
}


export default ModalService;
