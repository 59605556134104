import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Store } from '@store/store';
import { Locations } from '@core/models/locations';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import FAQHOC from '@components/main/customer-service-page-stage-2/FAQHOC';

import './CustomerServicePageStage2.scss';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '@components/main/bottom-mobile-menu/BottomMobileTabs';
import { Scrollbars } from 'react-custom-scrollbars';
import Spinner from '@/app/ui-elements/Spinner';
import cn from 'classnames';
import { CustomerModeStore } from '@/app/store/customerModeStore';
import Tab from '@/app/ui-elements/Tab';
import { DEFAULT_TAB } from '@/app/core/constants/defaultStoreItems';
import CustomerInfo from './customer-info';
import OrderInformation from './order-information-block';
import { useTab } from './helpers/useCurrentCustomerTabs';
import OrderViewComponent from '../order-view-page/OrderViewComponent';
import CreateClientCardForm from './create-client-card-form';

const scrollBarsStyle = { height: '100%' };

const CustomerServicePageStage2: FunctionComponent = (): JSX.Element => {
    const navigate = useNavigate();
    const [customerMode, store] = useInstances(CustomerModeStore, Store);
    const { currentUser } = store;
    const { clientCard, shouldCreateClientCard } = customerMode;
    const { isClientCardCanBeShown, isLoading } = clientCard;

    const [tabs, activeTab, onCloseTab, onChooseTab] = useTab();

    if (currentUser.mode !== UserModeEnum.CLIENT_SERVICE) {
        // Если пытаемся отрисовать компонент для пользователя не в режиме клиентского серса то шлем его лесом на корень.
        navigate(Locations.MAIN);
    }

    return (
        <div className="CustomerServicePage2">
            <Scrollbars
                className="customer-service-scroll-container"
                style={scrollBarsStyle}
                hideTracksWhenNotNeeded={true}
                universal={false}
            >
                <>
                    <div className="CustomerServicePage2__wrapper">
                        <div className="adaptive_alert">
                            Адаптивная верстка пока что недопступна. Реализация ожидается в других задачах.
                        </div>
                        {!isLoading && !isClientCardCanBeShown && !shouldCreateClientCard && (
                            <>
                                <div
                                    className="waiting_alert"
                                    title="Дизайна для этого состояния пока что нет"
                                    // TODO: https://2wtrade-tasks.atlassian.net/browse/UI-413
                                >
                                    Ждём звонок / Waiting for a call
                                </div>
                                <Spinner className={cn('e-button__Spinner')} />
                            </>
                        )}
                        {isLoading && <Spinner className={cn('e-button__Spinner')} />}
                        {shouldCreateClientCard && !isLoading && !isClientCardCanBeShown && (
                            <CreateClientCardForm />
                        )}
                        {isClientCardCanBeShown && (
                            <>
                                {tabs && (
                                    <div
                                        className="customer_service_tabs_wrapper"
                                    >
                                        <Tab
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            onChooseTab={onChooseTab}
                                            onCloseTabClick={onCloseTab}
                                        />
                                    </div>
                                )}
                                {activeTab && activeTab.id === DEFAULT_TAB.id && (
                                    <>
                                        <CustomerInfo />
                                        <OrderInformation />
                                        <FAQHOC />
                                    </>
                                )}
                                {activeTab && activeTab.id !== DEFAULT_TAB.id && (
                                    <OrderViewComponent
                                        orderIdToOpen={activeTab.id}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <BottomMobileTabs page={BottomMobileTabsPageEnum.ORDER} />
                </>
            </Scrollbars>
        </div>
    );
};

export default observer(CustomerServicePageStage2);
