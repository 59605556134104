import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import each from 'lodash/each';
import { v4 } from 'uuid';
import { useInstances } from 'react-ioc';

import { Locations } from '@/app/core/models/locations';
import TranslationService from '@services/I18NService';
import RecallsService, { FetchStatusEnum } from '@services/RecallsService';
import Spinner from '@/app/ui-elements/Spinner';
import GridTableRecalls  from '@UIElements/GridTable/GridTableRecalls';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import { IRecallModel } from '@/app/models/mobx-state-tree/recalls.model';
import { Store } from '@/app/store/store';
import { OrderFetchService } from '@/app/services';
import moment from 'moment-timezone';

import OrderApiService from '@api/order-api-service';
import CommonStorageEventSubscriptionService from '@services/subscription/CommonStorageEventSubscriptionService';

import PhoneIcon from './includes/PhoneIcon';

import Button from '@UIElements/Button';

import './includes/Recalls.scss';
import { getRemainingTime } from '@core/helpers/getRemainingTime';
import { ConfirmModalType } from '@core/models/ModalWindow';
import ModalService from '@core/services/ModalService';

const RecallsDesktopComponent: FunctionComponent = (): JSX.Element => {
    const [
        { t },
        {
            fetchRecallsList,
            fetchStatus,
            recallsList,
            editOrder,
        },
        { currentUser : { id : userId, setIsReady }, setCurrentTab },
        orderFetchService,
        orderApiService,
        commonStorageEventSubscriptionService,
        modalService,
    ] = useInstances(
        TranslationService,
        RecallsService,
        Store,
        OrderFetchService,
        OrderApiService,
        CommonStorageEventSubscriptionService,
        ModalService,
    );

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [currentOrderId, setCurrentOrderId] = useState<number>(NaN);
    const [fetchResult, setFetchResult] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        void fetchRecallsList();
        commonStorageEventSubscriptionService.subscribe();
        return () => {
            commonStorageEventSubscriptionService.unsubscribe();
        };
    }, []);

    useEffect(() => {

        if (fetchStatus === FetchStatusEnum.NONE || fetchStatus === FetchStatusEnum.PENDING) {
            setIsLoader(true);
        }

        if (fetchStatus === FetchStatusEnum.SUCCESS) {
            setIsLoader(false);
        }

    }, [isLoader, setIsLoader, fetchStatus]);

    type IColsNames = 'id' | 'customerName' | 'recallDate' | 'recallDateByCallCenter'  | 'comments' | 'remainingTime' ;

    const cols: IGridTableColumn[] = [
        {
            id: 'customerName',
            label: t('Имя покупателя', 'Customer name'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'id',
            label: t('Номер заказа', 'Order number'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '65px',
        },
        {
            id: 'recallDate',
            label: t('Дата перезвона (Клиент)', 'Recall date (Customer)'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '65px',
        },
        {
            id: 'recallDateByCallCenter',
            label: t('Дата перезвона (КЦ)', 'Recall date (СС)'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'country',
            label: t('Страна', 'Country'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'products',
            label: t('Товары', 'Products'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'comments',
            label: t('Комментарии', 'Comments'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'recallDateUpdate',
            label: t('Дата назначения перезвона', 'Appointment date'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '60px',
        },
        {
            id: 'remainingTime',
            label: t('Осталось времени', 'Remaining time'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '60px',
        },
        {
            id: 'actions',
            label: '',
            minMax_MaxValue: '0.5fr',
            minMax_MinValue: '60px',
        },
    ];

    let rows: WithRows<IColsNames, IBaseCellData>[] = [];

    const handleClickPhone = async (orderId: string, showNotification: boolean) => {

        let result = true;

        if (showNotification) {
            result = await modalService.showConfirmModal(
                `${t('До перезвона осталось более 30 минут. Вы уверены, что хотите позвонить клиенту по заказу')} ${orderId}?`,
                ConfirmModalType.YesCancel,
            );
        }

        if (!result) {
            return;
        }

        setDisabled(true);

        if (!disabled) {
            const result = await orderApiService.blockOrder(Number(orderId));

            if (result) {
                setIsReady(true);
                return setCurrentTab(Locations.MAIN);
            }

            setDisabled(false);
        }
    };

    useEffect(() => {
        if (currentOrderId){
            void editOrder(currentOrderId, userId)
                .then(data => setFetchResult(data));
        } if (fetchResult) {
            setCurrentTab(Locations.MAIN);
            void orderFetchService.fetchOrderView(currentOrderId);
            setFetchResult(false);
            setCurrentOrderId(NaN);
        }
        return;
    }, [currentOrderId, fetchResult]);

    const RenderCustomContent = (rowsData: WithRows<IColsNames, IBaseCellData>[], gridTableStyle: React.CSSProperties) => {
        const data: JSX.Element[] = [];
        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            let showNotification = false;
            let gridRowCellsRedClass = '';

            each(row, (value, key) => {

                if (key === 'remainingTime' && value?.content !== '-') {

                    const { time, totalMinutes } = getRemainingTime(value.content);

                    if (totalMinutes) {
                        gridRowCellsRedClass = totalMinutes < 10 ? 'grid_row__recalls_red' : '';
                        showNotification = totalMinutes > 30;
                    }

                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height recalls-table-cells"
                                key={v4()}
                                data-name={key}
                            >
                                {time}
                            </div>
                        ),
                    );

                    return;

                }

                if (key === 'action') {
                    rowCell.push(
                        (
                            <div
                                className="recall-item-action"
                                key={key}
                                data-name={key}
                            >
                                <Button
                                    key={'3'}
                                    disabled={disabled}
                                    variant={'7'}
                                    size={'1' }
                                    width="60px"
                                    onClick={() => handleClickPhone(row.id.content, showNotification)}
                                >
                                    <PhoneIcon/>
                                </Button>
                            </div>
                        ),
                    );

                    return;
                }

                rowCell.push(
                    (
                        <div
                            className="grid_cell grid_cell_height recalls-table-cells"
                            key={v4()}
                            data-name={key}
                        >
                            {value.content}
                        </div>
                    ),
                );
            });

            data.push(
                (
                    <div
                        className={`grid_row grid_row__recalls ${gridRowCellsRedClass}`}
                        id={rowId}
                        key={v4()}
                        style={gridTableStyle}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    if (recallsList.length > 0) {
        rows = recallsList.map((item: IRecallModel) => ({
            customerName: {
                id: v4(),
                content: item.customerFullName ? item.customerFullName : '-',
            },
            id: {
                id: v4(),
                content: item.id ? item.id.toString() : '-',
            },
            recallDate: {
                id: v4(),
                content: item.recallDate ? moment(item.recallDate).format('DD.MM | HH:mm') : '-',
            },
            recallDateByCallCenter: {
                id: v4(),
                content: item.recallDateByCallCenter ? moment(item.recallDateByCallCenter).format('DD.MM | HH:mm') : '-',
            },
            countryName: {
                id: v4(),
                content: item.countryName || '-',
            },
            goods: {
                id: v4(),
                content: item.recallProducts ?
                    item.recallProducts.filter((product) => !product.gift && !product.promo)[0].name : '-',
            },
            comments: {
                id: v4(),
                content:  item.comment ? item.comment : '',
            },
            recallDateUpdate: {
                id: v4(),
                content: item.recallDateUpdate ? moment(item.recallDateUpdate).format('DD.MM | HH:mm') : '-',
            },
            remainingTime: {
                id: v4(),
                content: item.recallDateByCallCenter || '-',
            },
            action: {
                id: v4(),
                content: '...',
            },
        }));
    }

    // Сортируем строки по принципу сначало показываем заказы с меньшим оставшимся временем до перезвона

    const sortedRows = useMemo(() => {
        return rows.sort((a: WithRows<IColsNames, IBaseCellData>,
            b: WithRows<IColsNames, IBaseCellData>) => getRemainingTime(a.remainingTime.content).totalMinutes -
            getRemainingTime(b.remainingTime.content).totalMinutes);
    }, [rows]);

    return (
        <div className="recalls-table-container">
            <div className="recalls-header">
                {t('Заказы на перезвон', 'Orders for recall')}
            </div>
            {isLoader && (
                <Spinner className="preloader" text={t('Загрузка...', 'Loading...')} />
            )}
            {!isLoader && (
                <>
                    {recallsList.length > 0 && (
                        <GridTableRecalls
                            className="recalls-table"
                            columns={cols}
                            rows={sortedRows}
                            rowsSize={rows.length}
                            renderHeaderSeparately={false}
                            cellsRenderer={RenderCustomContent}
                            tableContentHeight={700}
                        />
                    )}
                    {recallsList.length === 0 && (
                        <div className="no-orders-message">
                            {t('Нет заказов', 'No orders')}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default observer(RecallsDesktopComponent);
