import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import { ScriptBaseDataModel } from '@models/mobx-state-tree/scripts/scriptBaseDataModel';
import { ScriptThemeModel } from '@models/mobx-state-tree/scripts/scriptThemeModel';

export const ScriptDataModel = ScriptBaseDataModel
    .props({
        // nodes: t.maybeNull(t.map(t.late((): IAnyModelType => ScriptDataModel))),
        scriptTheme: ScriptThemeModel,
    });


export interface IScriptDataModel extends Instance<typeof ScriptDataModel> {}
export interface IScriptDataModelSnapshotIn extends SnapshotIn<typeof ScriptDataModel> {}
export interface IScriptDataModelSnapshotOut extends SnapshotOut<typeof ScriptDataModel> {}