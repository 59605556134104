import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import { Store } from '@store/store';
import Button from '@UIElements/Button';
import I18NService from '@services/I18NService';
import { ISelectedValue } from '@UIElements/Select/models';

import './includes/FirstStepCreation.scss';
import sortBy from 'lodash/sortBy';
import InputAutocomplete from '@/app/ui-elements/InputAutocomplete';


interface IFirstStepCreationProps {
    readonly preloader: boolean;
    onSubmit(): void;
}


const FirstStepCreation: FunctionComponent<IFirstStepCreationProps> = ({
    onSubmit,
    preloader,
}): JSX.Element => {
    const [
        { orderCreation },
        { t },
    ] = useInstances(
        Store,
        I18NService,
    );

    const {
        defaultSelectedSourceIdProvided,
        defaultSelectedSource,
        sourceListForView,
        selectedSourceForView,
        partnerListForView,
        selectedPartnerForView,
        selectedCountryForView,
        selectedSourceTypesForView,
        sourceTypesListForView,
        countryListForView,
        isDataReadyToBeSent,
        isPartnerIdAndCountryIdSelected,
        isPartnerIdSelected,
        currencyListForView,
        selectedCurrenciesForView,
    } = orderCreation;

    const onSelectedSourceId = (selected: ISelectedValue<string> | null) => orderCreation.setSelectedSourceId(selected ? selected.value : null);
    const onSelectedPartnerId = (selected: ISelectedValue<string> | null) => orderCreation.setSelectedPartnerId(selected ? selected.value : null);
    const onSelectedCountryId = (selected: ISelectedValue<string> | null) => orderCreation.setSelectedCountryId(selected ? selected.value : null);
    const onSelectedSourceTypeId = (selected: ISelectedValue<string> | null) => orderCreation.setSelectedSourceTypeId(selected ? selected.value : null);
    const onSelectedCurrencyId = (selected: ISelectedValue<string> | null) => orderCreation.setSelectedCurrencyId(selected ? selected.value : null);

    const currencyListForViewSorted = useMemo<ISelectedValue<string>[]>((): ISelectedValue<string>[] => sortBy(currencyListForView, 'label'), [currencyListForView]);
    const sourceListForViewSorted = useMemo<ISelectedValue<string>[]>((): ISelectedValue<string>[] => sortBy(sourceListForView, 'label'), [sourceListForView]);
    const partnerListForViewSorted = useMemo<ISelectedValue<string>[]>((): ISelectedValue<string>[] => sortBy(partnerListForView, 'label'), [partnerListForView]);
    const countryListForViewSorted = useMemo<ISelectedValue<string>[]>((): ISelectedValue<string>[] => sortBy(countryListForView, 'label'), [countryListForView]);
    const sourceTypesListForViewSorted = useMemo<ISelectedValue<string>[]>((): ISelectedValue<string>[] => sortBy(sourceTypesListForView, 'label'), [sourceTypesListForView]);

    const [partner, setPartner] = useState<boolean>(false);

    useEffect(() => {
        if (partner) {
            onSelectedCountryId(null);
            onSelectedSourceId(null);
            onSelectedSourceTypeId(null);
            onSelectedCurrencyId(null);
            setPartner(false);
        }
    }, [partner]);

    return (
        <div className="firstStep">
            <div className="firstStep__content">
                <div className="firstStep__block">
                    <InputAutocomplete
                        className="firstStep__selected"
                        onChange={onSelectedPartnerId}
                        selected={selectedPartnerForView}
                        values={partnerListForViewSorted}
                        divPlaceholder={t('Выберите партнера', 'Select a partner')}
                        inputPlaceholder={t('Впишите или выберите партнера', 'Type or choose a partner')}
                        label={t('Партнер', 'Partner')}
                        dataCyValue="partner"
                        clearValueEvent={setPartner}
                    />

                    <InputAutocomplete
                        className="firstStep__selected"
                        onChange={onSelectedCountryId}
                        selected={selectedCountryForView}
                        values={countryListForViewSorted}
                        divPlaceholder={t('Выберите страну', 'Select a country')}
                        inputPlaceholder={t('Впишите или выберите страну', 'Type or choose a country')}
                        label={t('Страна', 'Country')}
                        disabled={!isPartnerIdSelected}
                        dataCyValue="country"
                    />

                    {defaultSelectedSourceIdProvided && (
                        <InputAutocomplete
                            className="firstStep__selected"
                            onChange={onSelectedSourceId}
                            selected={selectedSourceForView}
                            values={sourceListForViewSorted}
                            divPlaceholder={t('Выберите источник заказа', 'Select order source')}
                            inputPlaceholder={t('Впишите или выберите источник заказа', 'Type or select order source')}
                            label={t('Источник заказа', 'Order source')}
                            disabled={!isPartnerIdAndCountryIdSelected}
                            defaultSelectedValue={defaultSelectedSource}
                            dataCyValue="order_source"
                        />
                    )}

                    <InputAutocomplete
                        className="firstStep__selected"
                        onChange={onSelectedSourceTypeId}
                        selected={selectedSourceTypesForView}
                        values={sourceTypesListForViewSorted}
                        divPlaceholder={t('Выберите тип источника', 'Select a source type')}
                        inputPlaceholder={t('Впишите или выберите тип источника', 'Type or choose a source type')}
                        label={t('Тип источника', 'Source type')}
                        disabled={!isPartnerIdAndCountryIdSelected}
                        dataCyValue="source_type"
                    />
                    <InputAutocomplete
                        className="firstStep__selected"
                        onChange={onSelectedCurrencyId}
                        selected={selectedCurrenciesForView}
                        values={currencyListForViewSorted}
                        divPlaceholder={t('Выберите валюту', 'Select a currency')}
                        inputPlaceholder={t('Впишите или выберите валюту', 'Type or choose a currency')}
                        label={t('Валюта', 'Currency')}
                        disabled={!isPartnerIdAndCountryIdSelected}
                        dataCyValue="currency"
                    />
                </div>
                <div className="firstStep__block button_block">
                    <Button
                        className="button__submit"
                        variant="7"
                        size="1"
                        text={t('Создать', 'Create')}
                        onClick={onSubmit}
                        disabled={!isDataReadyToBeSent || preloader}
                        isVisiblePreloader={preloader}
                        preloaderColor="#fff"
                    />
                </div>
            </div>
        </div>
    );
};


export default observer(FirstStepCreation);
