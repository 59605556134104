import { LatLng, LeafletMouseEvent, Map } from 'leaflet';
import { action, computed, observable } from 'mobx';
import { IModelType, IMSTMap, ModelProperties } from 'mobx-state-tree';
import debounce from 'lodash/debounce';

import { Store } from '@store/store';
import GeoNegiosMapApiService from '@api/geo-negios-map-api-service';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { AttrsKey } from '@interfaces/form.interface';
import XHR404Error from '@core/error/XHR404Error';
import XHR400Error from '@core/error/XHR400Error';
import { IGeocode } from '@api/geo-negios-map-api-service/models';
import ModalService from '@core/services/ModalService';
import { MEXICO_CHAR_CODE, PERU_CHAR_CODE } from '@core/constants/charCodes';
import { IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import GeocodeModel from '@models/geocode.model';
import I18NService from '@services/I18NService';
import SnackbarService from '@core/services/SnackbarService';
import OrderBase from '@services/order/OrderBase';

type _IGeocode = IGeocode;


class GeoNegociosMapService extends OrderBase {
    private _currentInstanceMap: Map;

    @observable
    private _selectedPosition: LatLng | undefined;

    @observable
    public geocode: GeocodeModel | null;

    private _isNeedToQueryGeocodeData = true;

    constructor(
        private readonly _store: Store,
        private readonly _geoNegiosMapApiService: GeoNegiosMapApiService,
        private readonly _modalService: ModalService,
        private readonly _i18NService: I18NService,
        private readonly _snackbarService: SnackbarService,
    ) {
        super();
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _addressAttributes(): IMSTMap<IModelType<ModelProperties, IFormAttributesModel>> {
        return this._currentOrder.form.addressAttributes;
    }

    private get _countryName(): string {
        return this._currentOrder.country.name;
    }

    @observable
    public errorMessage: string | null;

    private _initEventHandlerFnMap(map: Map): void {
        map.on(
            'click',
            (e: LeafletMouseEvent) => {
                const {
                    offersBlockTabs: {
                        toggleCollapsed,
                        isCollapsed,
                    },
                } = this._currentOrder;

                if (!isCollapsed) {
                    toggleCollapsed();
                }

                this._selectedPosition = e.latlng;
            },
        );

        map.on(
            'popupopen',
            () => {
                if (this._isNeedToQueryGeocodeData) {
                    void this.getByGeocode();
                }
            },
        );

        map.on(
            'popupclose',
            () => {
                this.errorMessage = null;
                this.geocode = null;
                this._isNeedToQueryGeocodeData = true;
            },
        );
    }

    @action
    public mapUpdate = (instance: Map): void => {
        this._initEventHandlerFnMap(instance);
        this._currentInstanceMap = instance;
    };

    @action
    private _enterGeocodeData(data: _IGeocode): void {
        this.geocode = GeocodeModel.create(data);
    }

    @action
    public getByGeocode = debounce(
        async (): Promise<void> => {
            try {
                const response = await this._geoNegiosMapApiService.getByGeocode(
                    String(this.position.lat),
                    String(this.position.lng),
                    this._countryName.toUpperCase().slice(0, 3),
                );

                this._enterGeocodeData(response);
            } catch (error) {
                if (error instanceof XHR400Error) {
                    this.errorMessage = this._i18NService.t(
                        'По данным координатам нет данных',
                        'There is no data on these coordinates',
                    );

                    return;
                }

                if (error instanceof XHR404Error) {
                    this.errorMessage = error.message;

                    return;
                }

                console.error(error);
            }
        },
        500,
        {
            maxWait: 2000,
            trailing: true,
        },
    );

    @computed
    public get zoom(): number {
        const { isCountryMexico } = this._currentOrder;

        return isCountryMexico ? 8 : 13;
    }

    private _getDefaultPosition(): LatLng {
        const { isCountryMexico, isCountryPeru } = this._currentOrder;

        let latitude = 51.505;
        let longitude = -0.09;

        if (isCountryMexico) {
            latitude = 19.400000;
            longitude = -98.988892;
        }

        if (isCountryPeru) {
            latitude = -12.126720186840569;
            longitude = -76.99857018667645;
        }

        return new LatLng(latitude, longitude);
    }

    @computed
    public get position(): LatLng {
        return this._selectedPosition || this._getDefaultPosition();
    }

    private get _addressForGeo(): string {
        const streetAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_STREET);
        const houseNumberAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_HOUSE_NUMBER);

        return [streetAttr?.value || '', houseNumberAttr?.value || '']
            .filter((item) => item.trim().length > 0)
            .join(', ');
    }

    @computed
    public get abilityToSearchForAddressesOnMap(): boolean {
        return !!this._addressForGeo.length;
    }

    @computed
    private get _argsToGeonegociosUbigeo(): string {
        const {
            country: {
                charCode,
            },
        } = this._currentOrder;

        const cityAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_CITY);
        const districtAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_DISTRICT);

        let ubigeo = '';

        if (charCode === PERU_CHAR_CODE) {
            const provinceAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_PROVINCE);

            ubigeo = [cityAttr?.value || '', districtAttr?.value || '', provinceAttr?.value || '']
                .filter((item) => item.trim().length > 0)
                .join(', ');
        }

        if (charCode === MEXICO_CHAR_CODE) {
            const stateAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_STATE);

            ubigeo = [cityAttr?.value || '', districtAttr?.value || '', stateAttr?.value || '']
                .filter((item) => item.trim().length > 0)
                .join(', ');
        }

        return ubigeo;
    }

    public fetchMapDataGeonegocios = async (): Promise<IGeocode> => {
        return this._geoNegiosMapApiService.getAddressFromGeonegocios(
            this._addressForGeo,
            this._argsToGeonegociosUbigeo,
            this._countryName.toUpperCase().slice(0, 3),
        );
    };

    @action
    public getAddressFromGeonegocios = async (): Promise<void> => {
        if (!this.abilityToSearchForAddressesOnMap) {
            void this._modalService.showErrorNotificationModal(
                this._i18NService.t(
                    'Не указаны дом или улица для поиска',
                    'No house or street to search for',
                ),
            );

            return;
        }

        try {
            this._enterGeocodeData(await this.fetchMapDataGeonegocios());
        } catch (e) {
            if (e instanceof XHR404Error) {
                this._snackbarService.showSnackbar(e.message);
                return;
            }

            if (e instanceof XHR400Error) {
                this._snackbarService.showSnackbar(
                    `${this._i18NService.t('Предоставлены неверные данные', 'Incorrect data provided')}: ${e}`,
                );
                return;
            }

            console.error(e);
        }
    };

    @action
    public showAddressOnMap = async (): Promise<void> => {
        await this.getAddressFromGeonegocios();

        if (this.geocode) {
            const { latitude, longitude } = this.geocode;

            const latLng = new LatLng(latitude, longitude);

            this._isNeedToQueryGeocodeData = false;

            this._selectedPosition = latLng;

            GeoNegociosMapService.scrollToDOMElementById('LeafletMap');

            this._currentInstanceMap.flyTo(
                latLng,
                this._currentInstanceMap.getZoom(),
            );
        }
    };

    @computed
    public get zipCodeFromForm(): string {
        const attr = this._addressAttributes.get(AttrsKey.CUSTOMER_ZIP);

        return attr?.value || '';
    }

    @computed
    public get zipCodeFromGeo(): string {
        return this.geocode?.postalCode || '';
    }

    @action
    public copyPostalCode = (): void => {
        if (this.geocode) {
            const zipCodeAttr = this._addressAttributes.get(AttrsKey.CUSTOMER_ZIP);

            zipCodeAttr?.setValue(String(this.geocode.postalCode));

            this._snackbarService.showSnackbar(
                this._i18NService.t(
                    'Зип-код добавлен в форму',
                    'Zip code added to the form',
                ),
            );
        }
    };
}


export default GeoNegociosMapService;
