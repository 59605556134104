import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import FlagIcon from '@/plugins/FlagIcon';
import I18NService from '@services/I18NService';
import { ICountryModel } from '@models/mobx-state-tree/country.model';
import { Store } from '@store/store';


interface IOrderInfoProps {
    country?: ICountryModel;
    callHistory: JSX.Element;
}


const OrderInfo: FunctionComponent<IOrderInfoProps> = ({
    country,
    callHistory,
}) => {
    const [
        { t },
        {
            currentOrder: {
                source,
                orderType,
                lastQueue,
                id,
                createdAt,
            },
        },
    ] = useInstances(
        I18NService,
        Store,
    );

    const { showQueueInOrder, hideOrderCreatedAt } = source;

    return (
        <div className="order-head">
            <div className="order-info">
                <div className="order-tag">
                    {`${t('Тип', 'Type')}: ${orderType.name ? t(orderType.name) : ''}`}
                </div>
                {showQueueInOrder && lastQueue && (
                    <div className="order-tag">
                        {`${t('Очередь', 'Queue')}: ${lastQueue.name ? t(lastQueue.name) : ''}`}
                    </div>
                )}
                <div className="order-name">
                    {country && country.charCode && (
                        <div className="order-flag" title={country.name ? t(country.name) : ''}>
                            <FlagIcon
                                className="langIcon"
                                code={country.charCode.toLowerCase()}
                                squared={true}
                            />
                        </div>
                    )}
                    <div className="name">
                        {`${t('Заказ', 'Order')} ${id}`}
                    </div>
                </div>
                <div className="order-date">
                    {!hideOrderCreatedAt && (
                        <div className="item">
                            {t('Дата создания', 'Date created')}
                            :
                            {' '}
                            <span className="font-weight-bold value">
                                {
                                    createdAt && moment(createdAt * 1000).format(
                                        'HH:mm | DD.MM.YYYY',
                                    )
                                }
                                {!createdAt && '?'}
                            </span>
                        </div>
                    )}
                    <div className="item">
                        {t('Источник', 'Source')}
                        :
                        {' '}
                        {source && (
                            <span>{source.name}</span>
                        )}
                        {!source && '?'}
                    </div>
                </div>
            </div>
            {callHistory}
        </div>
    );

};


export default observer(OrderInfo);
