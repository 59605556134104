import React from 'react';

interface IcDropDownSvgProps {
    className?: string;
    onClick?: () => void;
}

const IcDropDownSvg = ({ ...props }: IcDropDownSvgProps): JSX.Element => (
    <svg height="5" viewBox="0 0 10 5" width="10" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(-7 -10)">
            <path d="m7 10 5 5 5-5z" fill="#000" fillRule="nonzero" />
        </g>
    </svg>
);

export default IcDropDownSvg;
