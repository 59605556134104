import { toFactory } from 'react-ioc';

import InternetSpeedMetricService from '@core/services/InternetSpeedMetricService';
import { Store } from '@store/store';


const InternetSpeedMetricServiceDI = (): any => [
    InternetSpeedMetricService,
    toFactory(
        [
            Store,
        ],
        (
            store: Store,
        ) => new InternetSpeedMetricService(
            store,
        ),
    ),
];


export default InternetSpeedMetricServiceDI;
