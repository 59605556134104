import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IPersonSVGProps {
    className?: string;
    onClick?: () => void;
}


const PersonSVG: FunctionComponent<IPersonSVGProps> = ({ className, onClick, ...props }): JSX.Element => (
    <svg
        className={cn(['PersonSVG', className])}
        onClick={onClick}
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM12 5.5C13.66 5.5 15 6.84 15 8.5C15 10.16 13.66 11.5 12 11.5C10.34 11.5 9 10.16 9 8.5C9 6.84 10.34 5.5 12 5.5ZM12 19.7C9.5 19.7 7.29 18.42 6 16.48C6.03 14.49 10 13.4 12 13.4C13.99 13.4 17.97 14.49 18 16.48C16.71 18.42 14.5 19.7 12 19.7Z"
            fill="#8E8E93"
        />
    </svg>
);


export default PersonSVG;
