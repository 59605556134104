import UserApiService from '@api/user-api-service';
import { ITimezone } from '@api/user-api-service/models';
import SupportApiService, { ErrorLevelEnum } from '@api/support-api-service';
import moment from 'moment-timezone';

class TimeZoneService {

    constructor(
        private readonly _userApiService: UserApiService,
        private readonly _supportApiService: SupportApiService,
    ){
    }

    getTimezoneFromBrowser() {
        return moment.tz.guess();
    }

    async sendTimezoneId(userId: number) {

        const browserTimezone = this.getTimezoneFromBrowser(); // получаем таймзону из браузераж

        const data = await this._userApiService.getTimezone(); // получаем список часовых поясов

        const id = this.getUserTimezoneId(data, browserTimezone); // проверяем наличие таймзоны браузера в списке

        if (id) {
            await this._userApiService.changeUserTimezone(id); // отправляем  информацию о таймзоне на бек
        } else {
            await this._supportApiService.sendLogToKibana(
                'NEW UI',
                `operator ${userId} timezone ${browserTimezone} not found in the list`,
                ErrorLevelEnum.LOG,
                undefined,
            ); // логируем в кибану при отсутсвии совпадения таймзоны
            console.log(`operator ${userId} timezone ${browserTimezone} not found in the list`);
        }

    }

    getUserTimezoneId(data: ITimezone[], browserTimezone: string): number | false {

        for (let i = 0; i < data.length; i++) {
            if (data[i].timezone_id === browserTimezone) {
                return data[i].id;
            }
        }

        return false;
    }

}

export default TimeZoneService;