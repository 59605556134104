import * as React from 'react';

import { FunctionComponent } from 'react';

import './includes/Counter.scss';


interface ICounterProps {
    value: string;
}


const Counter: FunctionComponent<ICounterProps> = ({
    value,
}) => (
    <div
        className="Counter__wrapper"
        data-cy="product_quantity"
    >
        {value}
    </div>
);


export default Counter;
