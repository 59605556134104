import {
    useEffect, useState, useCallback,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Кастомный хук, который возвращает height и width атрибуты HTML элемента, за которым установлена слежка. \
 * Можно передать опциональный callback
 * @param element - HTMLElement, за которым следим
 * @param callback - опциональный callback, в который будет передан contentRect
 */
export const useResizeObserver = (
    element: HTMLElement | null,
    callback?: (entry: DOMRectReadOnly) => void,
): number[] => {
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    const handleResize = useCallback(
        (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame(() => { // https://stackoverflow.com/a/58701523
                if (!Array.isArray(entries)) {
                    return;
                }

                const entry = entries[0];
                setWidth(entry.contentRect.width);
                setHeight(entry.contentRect.height);

                if (callback) {
                    if (entry.contentRect) {
                        /**
                         * Нативный интерфейс TypeScript DOMRectReadOnly имеет свойство toJSON.
                         * В resize-observer-polyfill это свойство отсутствует.
                         * Возможно, когда-то будет обновление и можно будет убрать ts-ignore
                         * Штука не критичная, .toJSON() вызывать, вроде, не собираемся.
                         * https://github.com/que-etc/resize-observer-polyfill/issues/53
                         */
                        callback(entry.contentRect);
                    }
                }
            });
        },
        [callback],
    );

    useEffect(() => {
        const RO = new ResizeObserver(
            (entries: ResizeObserverEntry[]) => {
                handleResize(entries);
            },
        );

        if (element) {
            RO.observe(element);
        }

        return () => {
            if (RO) {
                RO.disconnect();
            }
        };
    }, [element]);

    return [width, height];
};
