import React, { FunctionComponent } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import CurrentDateTime from '@components/main/order-page/CurrentDateTime/CurrentDateTime';
import { Store } from '@store/store';
import getAppVersion from '@/env/getAppVersion';

import './PanelUserLabel.scss';


const PanelUserLabel: FunctionComponent = () => {
    const { currentUser: { username } } = useInstance(Store);

    return (
        <div className="PanelUserLabel">
            <div
                className="label"
                title={username}
            >
                {username}
            </div>
            <div className="label">
                <CurrentDateTime />
            </div>
            <div
                className="label"
                title={getAppVersion()}
            >
                {getAppVersion()}
            </div>
        </div>
    );
};


export default observer(PanelUserLabel);
