import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface INavigationSvgProps {
    className?: string;
}


const NavigationSvg: FunctionComponent<INavigationSvgProps> = ({
    className,
    ...props
}) => (
    <svg
        className={cn(['NavigationSvgIcon', className])}
        width="4"
        height="17"
        viewBox="0 0 4 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4.73516C3.1 4.73516 4 3.84641 4 2.76016C4 1.67391 3.1 0.785156 2 0.785156C0.9 0.785156 0 1.67391 0 2.76016C0 3.84641 0.9 4.73516 2 4.73516ZM2 6.71015C0.9 6.71015 0 7.5989 0 8.68515C0 9.7714 0.9 10.6602 2 10.6602C3.1 10.6602 4 9.7714 4 8.68515C4 7.5989 3.1 6.71015 2 6.71015ZM0 14.6102C0 13.5239 0.9 12.6352 2 12.6352C3.1 12.6352 4 13.5239 4 14.6102C4 15.6964 3.1 16.5852 2 16.5852C0.9 16.5852 0 15.6964 0 14.6102Z"
            fill="#C2C2C2"
        />
    </svg>
);


export default NavigationSvg;
