import React, { FC, useCallback } from 'react';
import cn from 'classnames';
import CallButtonSvg from '@UIElements/CallButtonSvg/CallButtonSvg';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import { Store } from '@store/store';

import './FloatingCallButton.scss';

const FloatingCallButton: FC = () => {
    const {
        ui: { orderPage: { fabVisible, toggleCallPanel } },
        currentOrder: {
            offersBlockTabs: { setIsCollapsed },
        },
    } = useInstance(Store);

    const onClick = useCallback(() => {
        /**
         * Закрываем блок офферов,
         * чтобы не работали элементы анимации при маленьком разрешении
         * с открытой SIP панелью
         */
        setIsCollapsed(true);
        toggleCallPanel();
    }, [toggleCallPanel]);

    return (
        <div
            className={cn('FloatingCallButton', !fabVisible && 'hidden')}
            onClick={onClick}
        >
            <CallButtonSvg />
        </div>
    );
};


export default observer(FloatingCallButton);
