import { useState, useCallback } from 'react';


export const useToggle = (initialValue = false): readonly [boolean, (() => void)] => {
    const [value, setValue] = useState(initialValue);

    const toggle = useCallback(() => {
        setValue((v) => !v);
    }, []);

    return [value, toggle] as const;
};
