import React, { FunctionComponent } from 'react';
import map from 'lodash/map';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import cn from 'classnames';
import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import OrderTableQuantityInStock
    from '@components/main/order-page/order-products/order-table/OrderTableQuantityInStock';
import Counter from '@UIElements/Counter';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import ProductLabel from '@UIElements/ProductLabel';
import { Store } from '@store/store';
import OldPromotionService from '@services/order/OldPromotionService';


type TDropDownListOfGiftsByActionProps = {
    readonly basketItem: IBasketItemModel;
};


const DropDownListOfGiftsByAction: FunctionComponent<TDropDownListOfGiftsByActionProps> = ({
    basketItem: {
        id: idForBasket,
    },
}) => {
    const [
        {
            currentOrder: {
                prepareLabelTranslation,
            },
        },
        oldPromotionService,
    ] = useInstances(
        Store,
        OldPromotionService,
    );

    return (
        <>
            {map<IBasketItemModel, JSX.Element>(
                oldPromotionService.getPromoGoodsByProductId(idForBasket),
                ({
                    quantity,
                    price,
                    productItem,
                }) => (
                    <div
                        key={`${idForBasket}`}
                        className="currentProducts__table-row composition-set"
                        data-cy="promotional_gift_nomenclature"
                    >
                        <div
                            className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
                        >
                            <div className="currentProducts__table-restore" />
                            <div className="currentProducts__table-avatar">
                                <ProductImage
                                    className="currentProducts__table-avatar-image"
                                    productName={productItem.name}
                                    imageLink={productItem.image}
                                    productType={productItem.type}
                                />
                            </div>
                            <OrderTableQuantityInStock
                                item={productItem}
                                quantityInStock={productItem.quantityInStock}
                            />
                            <div
                                className="currentProducts__table-product--name"
                                title={productItem.name}
                                data-cy="product-name"
                            >
                                {productItem.name}
                            </div>
                            <span
                                className={cn(
                                    'currentProducts__table-text-label--webkit',
                                )}
                            >
                                <ProductLabel
                                    productType={productItem.type}
                                    productSubType={productItem.subType}
                                    isPromotion={false}
                                    text={prepareLabelTranslation(
                                        productItem.type,
                                        productItem.subType,
                                        false,
                                    )}
                                />
                            </span>
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-2"
                            data-cy="item_counter"
                        >
                            <Counter
                                value={quantity.toString()}
                            />
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                        >
                            <div
                                className="currentProducts__table-text"
                                data-cy="price-per-one"
                            >
                                {price}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </>
    );
};


export default observer(DropDownListOfGiftsByAction);
