import React, { FunctionComponent } from 'react';
import NoImage from '@images/box.png';
import NoImageSet from '@images/box_carton.png';
import WhiteAndBlackBox from '@images/box-white-and-black.png';
import cn from 'classnames';
import { ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import './ProductImage.scss';

const Img = React.lazy(() => import('./Img'));

interface IProductImageProps {
    children?: JSX.Element;
    className?: string;
    productName: string;
    imageLink?: string | null;
    productType?: ProductItemTypeEnum;
    forPlaceholderPromotionBuilder?: boolean;
}
/**
 * !IMPORTANT:
 * Если не передать тип товара и ссылка на картинку будет (и она даже загрузится), то вернется пустой фрагмент
 */
const ProductImage: FunctionComponent<IProductImageProps> = ({
    children,
    className,
    productName,
    imageLink,
    productType,
    forPlaceholderPromotionBuilder,
}): JSX.Element => {
    const src = productType === ProductItemTypeEnum.DYNAMIC_SET || productType === ProductItemTypeEnum.STATIC_SET ? NoImageSet : NoImage;

    if (forPlaceholderPromotionBuilder) {
        return (
            <div className={cn('ProductImage', 'no-image')}>
                {children && children}
                <img
                    className={cn(className, 'no-image')}
                    src={WhiteAndBlackBox}
                    alt={productName}
                />
            </div>
        );
    }

    if (!imageLink) {
        return (
            <div className={cn('ProductImage', 'no-image')}>
                {children && children}
                <img
                    className={cn(className, 'no-image')}
                    src={src}
                    alt={productName}
                />
                <div className="ProductImage__name">
                    {productName}
                </div>
            </div>
        );
    }

    return (
        <React.Suspense fallback={(
            <div className="ProductImage">
                {children && children}
                <img
                    className={cn(className)}
                    src={src}
                    alt={productName}
                />
                <div className="ProductImage__name">
                    {productName}
                </div>
            </div>
        )}
        >
            <div className="ProductImage">
                {children && children}
                <Img
                    className={cn(className)}
                    src={imageLink}
                    productName={productName}
                    productType={productType}
                />
            </div>
        </React.Suspense>
    );
};


export default ProductImage;
