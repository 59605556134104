import React, { FunctionComponent } from 'react';
import cn from 'classnames';

type TEllipseSVGProps = {
    readonly className?: string;
    readonly color?: string;
    readonly width?: number;
    readonly height?: number;
};

const EllipseSVG: FunctionComponent<TEllipseSVGProps> = ({
    className,
    color = '#99A2AD',
    width = 12,
    height = 12,
    ...props
}) => (
    <svg
        className={cn(['EllipseSVG', className])}
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            cx="6"
            cy="6"
            r="6"
            fill={color}
        />
    </svg>
);

export default EllipseSVG;
