import React from 'react';

import './includes/Snackbar.scss';
import cn from 'classnames';

export interface ISnackbarProps {
    text: string;
    visible: boolean;
    actionText?: string;
    onActionClick?: () => void;
}

const Snackbar = ({
    text,
    visible,
    actionText,
    onActionClick,
}: ISnackbarProps): JSX.Element => (
    <div
        className={cn([
            'snackbar__wrapper',
            visible && 'visible',
        ])}
    >
        <div className="snackbar__content">
            <p className="snackbar__message">
                {text}
            </p>
            {onActionClick && (
                <div
                    className="snackbar__action"
                    onClick={onActionClick}
                >
                    {actionText}
                </div>
            )}
        </div>
    </div>
);

export default Snackbar;
