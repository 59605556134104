import { toFactory } from 'react-ioc';

import TranslationApiService from '@core/api/translation-api-service';
import LocalStorageService from '@core/services/LocalStorageService';
import I18NService from '@app/services/I18NService';


// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const I18NServiceDI = (): any => [
    I18NService,
    toFactory(
        [
            TranslationApiService,
            LocalStorageService,
        ],
        (
            translationApiService: TranslationApiService,
            localStorageService: LocalStorageService,
        ) => {
            const i18NService = new I18NService(
                translationApiService,
                localStorageService,
            );

            void i18NService.register(true);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return i18NService;
        },
    ),
];


export default I18NServiceDI;
