import React, { FunctionComponent } from 'react';
import Spinner from '@UIElements/Spinner';

import './FullscreenLoading.scss';


interface IFullScreenLoadingProps {
    text: string;
}


const FullscreenLoading: FunctionComponent<IFullScreenLoadingProps> = ({
    text,
}): JSX.Element => (
    <div className="FullscreenLoading__root">
        <Spinner
            className="preloader"
            text={text}
        />
    </div>
);


export default FullscreenLoading;
