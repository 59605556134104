import React, {
    FunctionComponent, useCallback, useMemo, useRef,
} from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import ContentBlock from '@UIElements/ContentBlock';
import Select from '@UIElements/Select';
import DatepickerInput from '@UIElements/DatepickerInput';
import { IDeliveryModel } from '@models/mobx-state-tree/delivery.model';
import { ISelectedValue } from '@UIElements/Select/models';
import { DeliveriesService } from '@services/index';
import { StatusOfCourierEnum } from '@models/mobx-state-tree/currentOrder.model';
import { OrderStatusesEnum } from '@api/order-api-service/models';

import CalendarContainer from './includes/CalendarContainer';
import './includes/OrderShipping.scss';


const OrderShipping: FunctionComponent = (): JSX.Element => {
    const [
        {
            currentOrder: {
                deliveryFrom,
                deliveryTo,
                showCardPayment,
                statusOfCouriers,
                setSelectedDeliveryId,
                setPaymentCard,
                paymentCard,
                showApproveValidationErrors,
                animationOnOrErrorFieldsActive,
                form: {
                    approveWithoutShipping,
                },
                selectedStatus,
            },
        },
        {
            t,
            dateFnsLocale,
        },
        {
            deliveryValuesForComponent,
            selectedDeliveryForComponent,
            minDeliveryDate,
            maxDeliveryDate,
            workDays,
            daysOff,
            changeDeliveryFrom,
            changeDeliveryTo,
        },
    ] = useInstances(
        Store,
        I18NService,
        DeliveriesService,
    );

    const startRef = useRef<ReactDatePicker>();
    const endRef = useRef<ReactDatePicker>();

    const onChangeStartDate = (val: Date) => {
        changeDeliveryFrom(val);
        startRef?.current?.setOpen(false);
    };

    const onChangeEndDate = (val: Date) => {
        changeDeliveryTo(val);
        endRef?.current?.setOpen(false);

    };

    const onSelectedDelivery = useCallback((selectedValue: ISelectedValue<IDeliveryModel>) => {
        setSelectedDeliveryId(selectedValue.value.id);
    }, [selectedDeliveryForComponent]);

    const onChangePaymentCard = useCallback(({ target: { checked } }) => {
        setPaymentCard(checked);
    }, [paymentCard]);

    const mainSettingsDatepicker = useMemo<ReactDatePickerProps>(
        () => ({
            dateFormat: 'dd.MM.yyyy',
            locale: dateFnsLocale,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            popperContainer: CalendarContainer,
        }),
        [dateFnsLocale, CalendarContainer, t],
    );

    const isWorkDay = (date: Date) => {
        if (workDays) {
            const day = date.getDay();
            if (workDays.length > 7) return true;
            workDays?.filter((day: number) => day > 6);

            return workDays.includes(day);
        }

        return true;
    };

    const noWorkDayClassToggle = (date: Date) => {
        if (maxDeliveryDate) {
            if (!isWorkDay(date) && date >= minDeliveryDate && date <= maxDeliveryDate) {
                return 'no-work-day';
            }

            return null;
        }

        if (!isWorkDay(date) && date >= minDeliveryDate) {
            return 'no-work-day';
        }

        return null;
    };

    const handlerOpenToDate = (): Date => {
        if (maxDeliveryDate){
            const dateList = [];
            const firstWorkDay = new Date(minDeliveryDate);
            for (; firstWorkDay <= maxDeliveryDate; firstWorkDay.setDate(firstWorkDay.getDate() + 1)) {
                if (isWorkDay(new Date(firstWorkDay))){
                    dateList.push(new Date(firstWorkDay));
                }
            }
            return dateList[0];
        }
        return new Date();
    };

    const getClassForDatePicker = (date: Date | null) => {
        if (selectedStatus !== OrderStatusesEnum.APPROVE) {
            return 'orderShipping__datepicker';
        }
        /**
         * При animationOnOrErrorFieldsActive === true включается анимация на полторы секундыи подсвечиваются красным
         * цветом не заполненые поля даты. Данный эффект возникает при нажатии кнопки approve - в случае если какое то поле
         * не заполнено устанвливается animationOnOrErrorFieldsActive равным true
         */
        if (animationOnOrErrorFieldsActive && !date) {
            return 'orderShipping__datepicker orderShipping__datepicker_empty animate-me bounce';
        }
        /**
         * Если поле с датой заполнено или поле с датой не заполнено, но мы еще не нажали на кнопку approve
         * (showApproveValidationErrors === false), то поле даты НЕ подсвечивается красным цветом как не прошедвее
         * валидацию
         */
        return date || !date && !showApproveValidationErrors ?
            'orderShipping__datepicker'
            :
            'orderShipping__datepicker orderShipping__datepicker_empty';
    };

    const classDatePickerForDeliveryFrom = getClassForDatePicker(deliveryFrom);
    const classDatePickerForDeliveryTo = getClassForDatePicker(deliveryTo);

    return (
        <ContentBlock title={t('Доставка', 'Shipping')}>
            <div className="orderShipping__wrapper">
                {!approveWithoutShipping && !selectedDeliveryForComponent && (
                    <div className="orderShipping__content">
                        <span
                            className="empty-delivery-list"
                            id="approveWithoutShipping"
                            data-cy="approveWithoutShipping"
                        >
                            {t(
                                'Данный заказ не может быть одобрен, поскольку отсутствует курьерская служба',
                                'This order could not be approved because there is no selected courier service',
                            )}
                        </span>
                    </div>
                )}
                <div className="orderShipping__content">
                    {selectedDeliveryForComponent?.value?.id && (
                        <>
                            <div id = 'orderShippingDatePicker'
                                className={classDatePickerForDeliveryFrom}
                            >
                                <ReactDatePicker
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    ref={startRef}
                                    wrapperClassName="date_from"
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    onChange={onChangeStartDate}
                                    selected={deliveryFrom}
                                    minDate={minDeliveryDate}
                                    maxDate={maxDeliveryDate}
                                    openToDate={handlerOpenToDate()}
                                    filterDate={isWorkDay}
                                    dayClassName={noWorkDayClassToggle}
                                    excludeDates={daysOff}
                                    customInput={<DatepickerInput placeholderText={t('Время от', 'time from')} />}
                                    {...mainSettingsDatepicker}
                                />
                            </div>
                            <div id = 'orderShippingDatePicker'
                                className={classDatePickerForDeliveryTo}
                            >
                                <ReactDatePicker
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    ref={endRef}
                                    wrapperClassName="date_to"
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    onChange={onChangeEndDate}
                                    selected={deliveryTo}
                                    disabled={!deliveryFrom}
                                    minDate={deliveryFrom}
                                    maxDate={maxDeliveryDate}
                                    excludeDates={daysOff}
                                    filterDate={isWorkDay}
                                    dayClassName={noWorkDayClassToggle}
                                    customInput={<DatepickerInput placeholderText={t('Время до', 'time to')} />}
                                    {...mainSettingsDatepicker}
                                />
                            </div>
                        </>
                    )}
                    {statusOfCouriers === StatusOfCourierEnum.COURIERS_ARE_NOT_SUITABLE && (
                        <span className="empty-delivery-list">
                            {t(
                                'Ни одна из курьерских служб не подходит для данного зип кода',
                                'None of the courier services is suitable for this zip code',
                            )}
                        </span>
                    )}
                    {statusOfCouriers === StatusOfCourierEnum.NOT_FOUND && (
                        <span className="empty-delivery-list">
                            {t(
                                'Не найдено служб доставки по этой стране',
                                'No delivery services found for this country.',
                            )}
                        </span>
                    )}
                    {deliveryValuesForComponent.length > 0 && statusOfCouriers === StatusOfCourierEnum.UPLOADED_BY && (
                        <div className="delivery-select-wrapper">
                            <Select
                                width="100%"
                                height="40px"
                                placeholder={t('Сменить курьерскую компанию', 'Change courier company')}
                                values={deliveryValuesForComponent}
                                onSelect={onSelectedDelivery}
                                selected={selectedDeliveryForComponent}
                            />
                        </div>
                    )}
                    {selectedDeliveryForComponent && selectedDeliveryForComponent.value.id && (
                        <div className="delivery-time-hint">
                            {t(
                                'Время доставки вводится по часовому поясу страны доставки',
                                'Delivery time is entered according to the time zone of the country of delivery',
                            )}
                        </div>
                    )}
                </div>
                {showCardPayment && (
                    <div className="orderShipping__content card-payment">
                        <input
                            className="cardPayment__checkbox"
                            type="checkbox"
                            id="card_payment"
                            checked={!!paymentCard}
                            onChange={onChangePaymentCard}
                        />
                        <label htmlFor="card_payment" className="cardPayment__label">
                            {t(
                                'Оплата картой',
                                'Payment by card',
                            )}
                        </label>
                    </div>
                )}
            </div>
        </ContentBlock>
    );
};


export default observer(OrderShipping);
