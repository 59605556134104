import React, {
    ChangeEvent, FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';

import Select from '@UIElements/Select';
import { ISelectedValue } from '@UIElements/Select/models';
import Button from '@UIElements/Button';
import TextArea from '@UIElements/TextArea';
import { ISubStatusModel } from '@models/mobx-state-tree/subStatus.model';
import { Store } from '@store/store';
import I18NService from '@services/I18NService';
import ModalService from '@core/services/ModalService';

import './includes/SubStatuses.scss';
import { OrderStatusesEnum } from '@api/order-api-service/models';


const SubStatuses: FunctionComponent = (): JSX.Element => {
    const [modalService, store, { t }] = useInstances(ModalService, Store, I18NService);

    const [statusesForSelect, setStatusesForSelect] = useState<ISelectedValue<ISubStatusModel>[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<ISelectedValue<ISubStatusModel>>();

    const { currentOrder, inactivityTimer } = store;

    const { form: { disableStatusSubStatusPhoneButtons: disabled } } = currentOrder;

    useEffect(() => {
        if (currentOrder.selectedStatus) {
            const subStatuses: ISubStatusModel[] = currentOrder.subStatusesValuesByStatus(currentOrder.selectedStatus);

            setStatusesForSelect(subStatuses.map((x: ISubStatusModel): ISelectedValue<ISubStatusModel> => ({
                label: x.name ? t(x.name) : x.id,
                value: x,
            })));
        }
    }, []);

    const onSelectedStatus = useCallback((val: ISelectedValue<ISubStatusModel>) => {
        setSelectedStatus(val);
    }, [selectedStatus]);

    const onChangeComment = useCallback((val: ChangeEvent<HTMLInputElement>) => {
        currentOrder.setOrderCommentDraft(val.target.value);
    }, [currentOrder.orderCommentDraft]);

    const submit = useCallback(() => {
        if (!selectedStatus) {
            return;
        }

        currentOrder.setSelectedSubStatus(Number(selectedStatus.value.id));
        modalService.success();
        if (currentOrder.postProcessing && selectedStatus.value.status !== OrderStatusesEnum.RECALL) {
            inactivityTimer.stopAllTimers();
            modalService.clearModalStack();
        }
    }, [selectedStatus]);

    return (
        <div className="rejectSubStatus">
            <div className="rejectSubStatus__content">
                <Select
                    placeholder={t('Под-статус', 'Sub-status')}
                    label={t('Под-статус', 'Sub-status')}
                    width="300px"
                    values={statusesForSelect}
                    onSelect={onSelectedStatus}
                    selected={selectedStatus}
                    disabled={disabled}
                />
                <TextArea
                    placeholder={t('Комментарий', 'Comment')}
                    label={t('Комментарий', 'Comment')}
                    width="400px"
                    value={currentOrder.orderCommentDraft}
                    onChange={onChangeComment}
                    disabled={disabled}
                />
                <Button
                    variant="7"
                    size="1"
                    text="Save"
                    width="192px"
                    className="send-button"
                    onClick={submit}
                    disabled={!selectedStatus || disabled}
                />
            </div>
        </div>
    );
};


export default observer(SubStatuses);
