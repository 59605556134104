import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const CommunicationsQuality = t
    .model('CommunicationsQuality', {
        id: t.identifier,
        question: t.maybeNull(t.string),
    });


export interface ICommunicationsQualityModel extends Instance<typeof CommunicationsQuality> {}
export interface ICommunicationsQualityModelSnapshotIn extends SnapshotIn<typeof CommunicationsQuality> {}
export interface ICommunicationsQualityModelSnapshotOut extends SnapshotOut<typeof CommunicationsQuality> {}
