import React, { FC } from 'react';
import { block } from 'bem-cn';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import { observer } from 'mobx-react';

import map from 'lodash/map';

import ScriptItem from '@components/main/order-page/order-scripts/script-item/script-item';
import Spinner from '@UIElements/Spinner';

import './scripts-view-component.scss';

interface IScriptViewComponent {
    loading: boolean;
}

const ScriptsViewComponent: FC<IScriptViewComponent> = ({ loading }) => {

    const [
        { t },
        {
            currentOrder: {
                scriptItems,
                isEmptyScriptItems,
            },
        },
    ] = useInstances(
        I18NService,
        Store,
    );

    const b = block('scripts-view-component');

    const emptyText = t('Информация по скриптам для данного товара отсутствует',
        'There is no information on scripts for this product');

    return (
        <div className={b()}>
            <div className={b('content')}>

                {isEmptyScriptItems ?

                    loading ?

                        <Spinner className="preloader"/>

                        :

                        <div className={b('empty')}>{emptyText}</div>

                    :

                    map(scriptItems, (item, index) =>
                        <ScriptItem
                            item={item}
                            key={item.id}
                            index={index}
                        />)
                }
            </div>
        </div>
    );
};

export default observer(ScriptsViewComponent);