import UserAgentParser from 'ua-parser-js';

const getBrowserName = (): string => {
    const parser = new UserAgentParser();
    const browser = parser.getBrowser();

    return browser.name || 'Unknown';
};

export default getBrowserName;
