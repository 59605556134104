import React, { FC } from 'react';
import { block } from 'bem-cn';

import map from 'lodash/map';

import { Store } from '@store/store';

import { IScriptItemModel } from '@models/mobx-state-tree/scripts/scriptItemModel';
import { IScriptDataModel } from '@models/mobx-state-tree/scripts/scriptDataModel';

import { observer } from 'mobx-react';

import ScriptItemTheme from '@components/main/order-page/order-scripts/script-item-theme/script-item-theme';
import ArrowNavSvg from '@components/resources/SVG/ArrowNav/ArrowNav';

import './script-item.scss';
import { useInstances } from 'react-ioc';

interface IScriptItemProps {
    item: IScriptItemModel;
    key: string;
    index: number;
}

const ScriptItem: FC<IScriptItemProps> = (
    {
        item: {
            name,
            scriptDataInfo,
            isEmptyScriptThemes,
        },
        index,
    }) => {

    const [
        {
            currentOrder: {
                setOpenedScriptItem,
                openedScriptItem,
                clearOpenedScriptThemes,
                isUseLeadProductScript,
                isFirstLoadingScripts,
            },
        },
    ] = useInstances(
        Store,
    );

    const isCurrentScriptOpened = (isFirstLoadingScripts && isUseLeadProductScript && index === 0) || openedScriptItem === index;

    const b = block('script-item');

    const onClick = () => {
        clearOpenedScriptThemes();
        if (isCurrentScriptOpened) {
            return setOpenedScriptItem(-1);
        }
        setOpenedScriptItem(index);
    };

    return (
        <div className={b()}>
            <div
                className={b('title', { 'reverse': isCurrentScriptOpened ? true : false })}
                onClick={onClick}
            >
                <div className={b('name')}>
                    {name}
                </div>

                {!isEmptyScriptThemes &&
                    <ArrowNavSvg
                        className={isCurrentScriptOpened ? 'arrowNav_prev' : 'arrowNav_next'}
                        width={10}
                        height={15}
                    />
                }

            </div>
            {isCurrentScriptOpened && map(scriptDataInfo, (data: IScriptDataModel, index: number) =>
                <ScriptItemTheme
                    data={data}
                    key={data.id}
                    index={index}
                />)}
        </div>
    );
};

export default observer(ScriptItem);