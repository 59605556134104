import { toFactory } from 'react-ioc';

import { ScriptDataService } from '@services/script';
import ScriptApiService from '@api/script-api-service';
import { Store } from '@store/store';


const ScriptDataServiceDI = (): any => [
    ScriptDataService,
    toFactory(
        [
            ScriptApiService,
            Store,
        ],
        (
            scriptApiService: ScriptApiService,
            store: Store,
        ) => new ScriptDataService(
            scriptApiService,
            store,
        ),
    ),
];


export default ScriptDataServiceDI;