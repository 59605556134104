import React, { FunctionComponent } from 'react';
import { useRoutes } from 'react-router-dom';
import { Locations } from '@core/models/locations';
import Login from '@components/login/Login';
import Main from '@components/main/Main';

const MainRoutes: FunctionComponent = () => {
    return useRoutes([
        { path: Locations.LOGIN, element: <Login /> },
        { path: Locations.MAIN, element: <Main /> },
    ]);
};


export default MainRoutes;
