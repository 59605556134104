import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './includes/Popup.scss';

interface IPopupProps {
    isActive?: boolean;
    className?: string;
    children?: any;
    width?: string;
    autoHeightMax?: number;
}

const Popup: FunctionComponent<IPopupProps> = (props) => (
    <div className="popup__wrapper">
        <div className={cn('e-popup', props.className, { active: props.isActive })}>
            <Scrollbars
                style={{ width: props.width || '100%' }}
                hideTracksWhenNotNeeded={true}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={props.autoHeightMax || 200}
                universal={true}
            >
                {
                    props.children
                }
            </Scrollbars>
        </div>
    </div>
);

export default Popup;
