import {
    types as t,
    Instance,
    SnapshotIn,
    SnapshotOut,
} from 'mobx-state-tree';


export const CallAutomatization = t
    .model('CallAutomatization', {
        /**
         * Количество совершенных звонков
         * Инкрементится только после завершения звонка
         */
        numberOfCallsMade: t.optional(t.number, 0),
        /**
         * Количество попыток звонков, которые нужно/можно совершить
         */
        automaticCallsAmount: t.optional(t.integer, 1),
        /**
         * Количество совершенных звонков для отображения в UI
         * Инкрементится в момент совершения звонка
         */
        numberOfCallsMadeForCounterInUI: t.optional(t.number, 0),
        /**
         * Ответил ли клиент
         */
        isCustomerAnswered: t.optional(t.boolean, false),
        /**
         * Признак того, что при запуске автонабора не был найден номер телефона
         */
        phoneNumberNotFound: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get _numberOfCallsMade(): number {
            return self.numberOfCallsMade;
        },
    }))
    .views((self) => ({
        get callAutomatizationFinished(): boolean {
            if (self.phoneNumberNotFound) {
                return true;
            }

            if (self.isCustomerAnswered) {
                return true;
            }

            return self._numberOfCallsMade >= self.automaticCallsAmount;
        },
    }))
    .actions((self) => ({
        setPhoneNumberNotFound(value: boolean) {
            self.phoneNumberNotFound = value;
        },
        setIsCustomerAnswered(value: boolean) {
            self.isCustomerAnswered = value;
        },
        /**
         * Инкрементится только после завершения звонка
         */
        incrementNumberOfCallsMade() {
            self.numberOfCallsMade++;
        },
        /**
         * Инкрементится в момент совершения звонка
         */
        incrementNumberOfCallsMadeForUI() {
            self.numberOfCallsMadeForCounterInUI++;
        },
    }));


export interface ICallAutomatizationModel extends Instance<typeof CallAutomatization> {}
export interface ICallAutomatizationModelSnapshotIn extends SnapshotIn<typeof CallAutomatization> {}
export interface ICallAutomatizationModelSnapshotOut extends SnapshotOut<typeof CallAutomatization> {}
