import { toFactory } from 'react-ioc';

import AudioService from '@core/services/AudioService';
import ErrorService from '@core/services/ErrorService';
import I18NService from '@services/I18NService';


const AudioServiceDI = (): any => [
    AudioService,
    toFactory(
        [
            ErrorService,
            I18NService,
        ],
        (
            errorService: ErrorService,
            I18NService: I18NService,
        ) => new AudioService(
            errorService,
            I18NService,
        ),
    ),
];


export default AudioServiceDI;
