import React, {
    FunctionComponent, useEffect, useState,
} from 'react';
import moment from 'moment-timezone';


const PERIOD = 1000;
const FORMAT = 'DD MMM hh:mm:ss A [UTC]';


const getCurrentDate = (): string => moment().tz('UTC').format(FORMAT);


const CurrentDateTime: FunctionComponent = (): JSX.Element => {
    const [dateTime, setDateTime] = useState<string>(
        () => getCurrentDate(),
    );

    useEffect(() => {
        const interval = setInterval(
            (): void => setDateTime(getCurrentDate()),
            PERIOD,
        );

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [setDateTime]);

    return <span title={dateTime}>{dateTime}</span>;
};


export default CurrentDateTime;
