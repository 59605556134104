import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import EmptyComponent from '@core/constants/EmptyComponent';

import IcApproveStatusSvg from '@components/resources/SVG/CallWidget/IcApproveStatusSvg';
import IcNoAnswerStatusSvg from '@components/resources/SVG/CallWidget/IcNoAnswerStatusSvg';
import IcRecallStatusSvg from '@components/resources/SVG/CallWidget/IcRecallStatusSvg';
import IcRejectStatusSvg from '@components/resources/SVG/CallWidget/IcRejectStatusSvg';
import IcTrashStatusSvg from '@components/resources/SVG/CallWidget/IcTrashStatusSvg';
import IconCrossSvg from '@components/resources/SVG/Cross/IconCrossSvg';
import { OrderStatusesEnum } from '@api/order-api-service/models';

import { ISlide } from './model';


interface ISlideProps {
    slide: ISlide;
}


const Slide: FunctionComponent<ISlideProps> = ({ slide }): JSX.Element => {
    const status = useMemo<OrderStatusesEnum | null>(
        () => slide.endStatusId || slide.startStatusId,
        [slide],
    );

    const iconBasedOnStatus = useMemo<JSX.Element>(
        () => {
            let SvgComponent: FunctionComponent<any>;

            switch (status) {
                case OrderStatusesEnum.APPROVE:
                    SvgComponent = IcApproveStatusSvg;
                    break;
                case OrderStatusesEnum.NO_ANSWER:
                    SvgComponent = IcNoAnswerStatusSvg;
                    break;
                case OrderStatusesEnum.RECALL:
                    SvgComponent = IcRecallStatusSvg;
                    break;
                case OrderStatusesEnum.REJECT:
                    SvgComponent = IcRejectStatusSvg;
                    break;
                case OrderStatusesEnum.TRASH:
                    SvgComponent = IcTrashStatusSvg;
                    break;
                case OrderStatusesEnum.CRASH:
                    SvgComponent = IconCrossSvg;
                    break;
                default:
                    SvgComponent = EmptyComponent;
            }

            return <SvgComponent className="image-icon" />;
        },
        [status],
    );

    return (
        <div className="slide">
            <div
                className={cn(['item', 'recall'])}
                data-for={`callHistory__${slide.id}`}
                data-tip=""
            >
                <div className={cn('image', status && `status__${status}`)}>
                    {iconBasedOnStatus}
                </div>
                {slide.createdAt && (
                    <div className="date">
                        {moment(slide.createdAt * 1000).tz('UTC').format('DD.MM | HH:mm')}
                    </div>
                )}
            </div>
        </div>
    );
};


export default Slide;
