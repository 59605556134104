import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';
import each from 'lodash/each';
import moment from 'moment';

import I18NService from '@services/I18NService';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import GridTable from '@UIElements/GridTable';
import { Store } from '@/app/store/store';
import { OrderFetchService } from '@app/services';

type IColsNames = 'id' | 'phone' | 'template' | 'text' | 'status' | 'error' | 'createdAt';


const SmsMessagesTable: FunctionComponent = () => {
    const [
        { t },
        { currentOrder },
        orderFetchService,
    ] = useInstances(
        I18NService,
        Store,
        OrderFetchService,
    );

    useEffect(() => {
        void orderFetchService.getSms();
    }, []);

    const { textMessageHistory } = currentOrder;

    const cols = useMemo<IGridTableColumn[]>(
        () => [
            {
                id: 'id',
                label: t('Номер заказа', 'Order id'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '50px',
            },
            {
                id: 'phone',
                label: t('Телефон', 'Phone'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '68px',
            },
            {
                id: 'template',
                label: t('Шаблон', 'Template'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '156px',
            },
            {
                id: 'text',
                label: t('Текст сообщения', 'Sms text'),
                minMax_MaxValue: '2fr',
                minMax_MinValue: '156px',
            },
            {
                id: 'status',
                label: t('Статус', 'Status'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '25px',
            },
            {
                id: 'error',
                label: t('Ошибка', 'Error'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '25px',
            },
            {
                id: 'createdAt',
                label: t('Дата создания', 'Created at'),
                minMax_MaxValue: '1fr',
                minMax_MinValue: '72px',
            },
        ],
        [t],
    );

    const rows = useMemo<WithRows<IColsNames, IBaseCellData>[]>(
        () => textMessageHistory.map(
            (msg) => ({
                id: {
                    id: v4(),
                    content: String(msg.id),
                },
                phone: {
                    id: v4(),
                    content: msg.phone,
                },
                template: {
                    id: v4(),
                    content: msg.template,
                },
                text: {
                    id: v4(),
                    content: msg.text,
                },
                status: {
                    id: v4(),
                    content: msg.status,
                },
                error: {
                    id: v4(),
                    content: msg.error,
                },
                createdAt: {
                    id: v4(),
                    content: String(msg.createdAt),
                },
            }),
        ),
        [textMessageHistory],
    );

    const RenderContentWithImage = (rowsData: WithRows<IColsNames, IBaseCellData>[]) => {
        const data: JSX.Element[] = [];
        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            each(row, (value, key) => {
                if (key === 'id') {
                    rowId = value.id;
                }

                if (key === 'phone') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height rgt-text-truncate"
                                key={key}
                                title={value.content}
                                data-name={key}
                            >
                                <div
                                    className="rgt-text-truncate"
                                >
                                    {value.content}
                                </div>
                            </div>
                        ),
                    );

                    return;
                }

                if (key === 'createdAt') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                key={key}
                                data-name={key}
                            >
                                {moment(Number(value.content) * 1000).format('L LTS')}
                            </div>
                        ),
                    );

                    return;
                }

                if (key === 'status') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height"
                                key={key}
                                data-name={key}
                            >
                                <button
                                    data-cy="status_button_sms"
                                    className="smsStatus"
                                    type="button"
                                    disabled
                                >
                                    {value.content}
                                </button>
                            </div>
                        ),
                    );

                    return;
                }

                if (key === 'text' || key === 'template') {
                    rowCell.push(
                        (
                            <div
                                className="grid_cell grid_cell_height dots_in_text"
                                key={key}
                                title={value.content}
                                data-name={key}
                            >
                                {value.content}
                            </div>
                        ),
                    );

                    return;
                }

                rowCell.push(
                    (
                        <div
                            className="grid_cell grid_cell_height"
                            key={key}
                            data-name={key}
                        >
                            {value.content}
                        </div>
                    ),
                );
            });

            data.push(
                (
                    <div
                        data-cy="grid_row_sms"
                        className="grid_row"
                        id={rowId}
                        key={rowId}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    return (
        <>
            {rows.length > 0 && (
                <GridTable
                    className="grid-sms-table"
                    columns={cols}
                    rows={rows}
                    rowsSize={rows.length}
                    cellsRenderer={RenderContentWithImage}
                    tableContentHeight={551}
                />
            )}
            {rows.length === 0 && (
                <div
                    className="result-alert"
                >
                    <div className="message_header">
                        {t('Нет результатов', 'No results')}
                    </div>
                    <div className="message_content">
                        {t('Не найдено смс для заказа', 'No sms found for this order')}
                    </div>
                </div>
            )}
        </>

    );
};


export default observer(SmsMessagesTable);
