import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import { Transition } from 'react-transition-group';

import RemovePhoneCrossIconSvg
    from '@components/main/order-page/order-customer-info/added-phone/AddPhone/includes/RemovePhoneCrossIconSvg';
import ModalService from '@core/services/ModalService';

import './includes/ModalContainer.scss';


const ModalContainer: FunctionComponent = (): JSX.Element => {
    const {
        visible,
        template: ModalTemplate,
        success,
        hideModalHeader,
    } = useInstance(
        ModalService,
    );

    const wrapperStyles = useMemo<Record<string, string>>(
        () => ({ '--visibleContent': visible ? 'block' : 'none' }),
        [visible],
    );

    return (
        <div
            className={cn(
                'modalContainer__wrapper',
                visible && 'modalVisible',
            )}
            style={wrapperStyles}
        >
            <Transition
                in={visible}
                timeout={300}
            >
                {(state): JSX.Element => (
                    <div
                        className={cn(
                            'modalContainer__content',
                            state,
                        )}
                    >

                        {!hideModalHeader &&
                        <div className="modalContainer__header">
                            <RemovePhoneCrossIconSvg
                                width={20}
                                height={20}
                                onClick={() => success(false)}
                                data-cy="close-modal"
                            />
                        </div>}

                        <div className="modalContainer__body">
                            {ModalTemplate && <ModalTemplate />}
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    );
};


export default observer(ModalContainer);
