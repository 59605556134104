/**
 * vhCSSCustomProperty
 *
 * Используется для определения величины юнита vh.
 * Используется как хак на мобиле для случая когда панель навигации то увеличивается то уменьшается.
 *
 * Значение заноситься в css custom properties.
 *
 * Пример использования
 *
 * height: 100vh; <- на случай если нет custom properties в браузере.
 * height: calc(var(--vh, 1vh) * 100);
 *
 * todo: Событие resize стоит оптимизировать https://developer.mozilla.org/ru/docs/Web/API/Window/resize_event
 *
 * @return {void}
 */
const vhCSSCustomProperty = (): void => {
    const initialVh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${initialVh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
};

export default vhCSSCustomProperty;
