import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';


export const CustomerAppeal = t
    .model('CustomerAppeal', {
        id: t.identifier,
        jiraUrl: t.maybeNull(t.string),
        createdAt: t.string,
        appealSubject: t.string,
        status: t.string,
        operatorUserName: t.string,
        clientName: t.string,
        appealText: t.string,
    });


export interface ICustomerAppealModel extends Instance<typeof CustomerAppeal> {}
export interface ICustomerAppealSnapshotIn extends SnapshotIn<typeof CustomerAppeal> {}
export interface ICustomerAppealSnapshotOut extends SnapshotOut<typeof CustomerAppeal> {}
