import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import CookieService from '@core/services/CookiesService';
import UserService from '@services/UserService';
import WebSocketService from '@services/websocket/WebSocketService';
import CookieSubscriptionService from '@services/subscription/CookieSubscriptionService';
import { SipService } from '@services/index';
import HistoryService from '@services/HistoryService';


const CookieSubscriptionServiceDI = (): any => [
    CookieSubscriptionService,
    toFactory(
        [
            Store,
            CookieService,
            UserService,
            WebSocketService,
            SipService,
            HistoryService,
        ],
        (
            store: Store,
            cookieService: CookieService,
            userService: UserService,
            webSocketService: WebSocketService,
            sipService: SipService,
            historyService: HistoryService,
        ) => new CookieSubscriptionService(
            store,
            cookieService,
            userService,
            webSocketService,
            sipService,
            historyService,
        ),
    ),
];


export default CookieSubscriptionServiceDI;
