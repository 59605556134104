import ErrorBase from '@core/error/ErrorBase';
import { customI18NTFunction } from '@services/I18NService';

class ConnectMicrophoneToDeviceError extends ErrorBase {
    constructor(t: customI18NTFunction) {
        const dataForMessage = {
            key: 'На вашем устройстве нет микрофона. Работа с заказами без микрофона невозможна. Подключите его и обновите страницу.',
            defaultValue: 'Your device does not have a microphone. It is not possible to work with orders without a microphone. Plug it in and refresh the page.',
        };

        super(dataForMessage, t);
    }
}


export default ConnectMicrophoneToDeviceError;
