import { IWithPossibleImageAndType } from '@/app/models/mobx-state-tree/customer-mode/orderSearchFilter.model';
import { CustomerService } from '@/app/services';
import { ISelectValueExtended } from '@/app/ui-elements/SelectDummy/SelectDummy';
import sortBy from 'lodash/sortBy';
import {
    useEffect, useState, useCallback, useMemo,
} from 'react';
import { useInstance } from 'react-ioc';

/**
 * Хук по управлению Select-ом фильтрации заказов по главным товарам товарам
 */
export const useProductSelect = () => {
    const customerService = useInstance(CustomerService);

    const {
        ordersFilter: {
            setSelectedProduct,
            selectedProduct,
            productListForView,
        },
    } = customerService;

    const [values, setValues] = useState<ISelectValueExtended<IWithPossibleImageAndType>[]>();
    const [selectedValue, setSelectedValue] = useState<string>('');

    /**
     * Выбрать товар из списка
     */
    const onChange = useCallback(
        (id: string) => {
            // setSelectedValue(findValueById(id));
            setSelectedProduct(id);
        },
        [values],
    );

    useEffect(
        () => {
            setSelectedValue(selectedProduct);
        },
        [selectedProduct],
    );

    useEffect(
        () => {
            setValues(productListForView);
        },
        [productListForView],
    );

    const valuesSorted = useMemo<ISelectValueExtended<IWithPossibleImageAndType>[]>(
        () => sortBy<ISelectValueExtended<IWithPossibleImageAndType>>(
            values,
            'identifyProp',
        ),
        [values],
    );

    // замораживаем возвращаемые объекты (нельзя будет изменять вне хука)
    return [onChange, valuesSorted, selectedValue] as const;
};
