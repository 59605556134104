import React, {
    ChangeEvent,
    FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useInstances } from 'react-ioc';
import OrderService, { AddModeEnum } from '@services/order/OrderService';
import I18NService from '@services/I18NService';
import Button from '@UIElements/Button';
import SearchIconSvg from '@components/resources/SVG/Search/SearchIconSvg';
import useOnClickOutside from 'use-onclickoutside';
import IconCrossSvg from '@components/resources/SVG/Cross/IconCrossSvg';
import { IProductItemModel, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import some from 'lodash/some';
import BasketService from '@services/order/BasketService';
import ProductItemToAdd from '../order-table/includes/ProductItemToAdd';
import ProductSetToAdd from '../order-table/includes/ProductSetToAdd';
import OrderTableProductRow from '../order-table/OrderTableProductRow';
import CrossIconSvg from '../order-table/includes/CrossIconSvg';
import EmptyMainProductsNotification from '../includes/EmptyMainProductsNotification';


interface IOrderTableProps {
    readonly haveItemsInBasket: boolean;
    readonly mainRegularItems: IProductItemModel[];
    readonly dynamicItems: IProductItemModel[];
    readonly staticItems: IProductItemModel[];
    readonly isShow: boolean;
    readonly allProducts: IProductItemModel[];
    readonly mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved: IBasketItemModel[];
    readonly animationOnOrErrorFieldsActive: boolean;
    readonly isPromotionBuilderActive: boolean;
}


const ProductTable: FunctionComponent<IOrderTableProps> = ({
    haveItemsInBasket = false,
    mainRegularItems = [],
    dynamicItems = [],
    staticItems = [],
    isShow = false,
    allProducts,
    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved = [],
    animationOnOrErrorFieldsActive = false,
    isPromotionBuilderActive,
}) => {
    const [
        orderService,
        { t },
        basketService,
    ] = useInstances(
        OrderService,
        I18NService,
        BasketService,
    );
    const { basketItemPreview } = orderService.currentOrder;

    const productTypes = {
        [AddModeEnum.sets]: t('Наборы товаров', 'Sets'),
        [AddModeEnum.main]: t('Товары', 'Products'),
    };
    const [isSearchModeOn, setIsSearchModeOn] = useState<boolean>(false);
    // isSearchModeOnForButtons устанавливается в false с задержкой в 500 мс, т.к. иначе происходит "тряска" верстки
    const [isSearchModeOnForAnimation, setIsSearchModeOnForAnimation] = useState<boolean>(false);
    const [found, setFound] = useState<IProductItemModel[] | null>(null);
    const [activeAddMode, setActiveFilterMode] = useState<AddModeEnum>(AddModeEnum.main);
    /**
     * По умолчанию выведем все продукты при нажатии на поиск
     */
    useEffect(() => {
        setFound(allProducts);
    }, [allProducts]);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        /**
         * Ищем по name или id товара
         * А также, если это набор, то и по товарам, входящим в состав набора
         * Товары для замены (alternatives) тоже участвуют в поиске
         */
        setFound(allProducts.filter((item) => {
            const [names, partnerProductIds] = item.dataOfSetIncludingProductNamesAndPartnerProductIds();

            return some(names, (nameInfo: string) => nameInfo.toLowerCase().includes(value.toLowerCase()))
                || some(partnerProductIds, (partnerProductId: string) => partnerProductId.toLowerCase().includes(value.toLowerCase()));
        }));
    }, [allProducts]);

    /**
     * Переключаемся из режима поиска в обычный если кликаем мимо инпута
     */
    const switchMode = useCallback((e) => {
        const itemsDiv = document.getElementById('products_found');
        if (itemsDiv && itemsDiv.contains(e.target)) {
            // если жмякаем на товары, то ничего не делаем
            return;
        }
        setIsSearchModeOn(false);
    }, [setIsSearchModeOn]);

    const handleSwitchMode = useCallback(() => setIsSearchModeOn((prevState) => !prevState), [setIsSearchModeOn]);

    const handleSwitchMenuToSets = useCallback(() => setActiveFilterMode(AddModeEnum.sets), [setActiveFilterMode]);
    const handleSwitchMenuToMain = useCallback(() => setActiveFilterMode(AddModeEnum.main), [setActiveFilterMode]);

    const searchRef = useRef<HTMLInputElement>(null);
    useOnClickOutside(searchRef, switchMode);

    const input = document.getElementById('searchProducts');

    useEffect(() => {
        if (isSearchModeOn) {
            setIsSearchModeOnForAnimation(true);
            if (input) {
                input.focus();
            }
            return;
        }

        setTimeout(() => {
            setIsSearchModeOnForAnimation(false);
        }, 400);
    }, [isSearchModeOn]);

    const pushToBasket = useCallback(() => {
        if (basketItemPreview) {
            basketService.pushPreviewModelToBasket();
            void orderService.currentOrder.showAnimationOnAddToBasket();
        }
    }, [basketItemPreview]);

    const clearPreview = useCallback(() => {
        if (basketItemPreview) {
            orderService.currentOrder._deleteBasketItemPreview();
        }
    }, [basketItemPreview]);

    if (basketItemPreview) {
        // Если в модель basketItemPreview добавили товар, значит нажали на кнопку 'Detail' и нужно показать подробную информацию о товаре
        return (
            <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
                <div className="preview_table__controls">
                    <div className="preview_table__controls--details">
                        {t('Подробная информация', 'Detail info')}
                        <span className="product_name">{`: ${basketItemPreview.productItem.name}`}</span>
                    </div>
                    <Button
                        size="0"
                        variant={7}
                        width="auto"
                        text={t('Добавить набор', 'Add set')}
                        onClick={pushToBasket}
                    />
                    <div
                        className="cancel_detail"
                        title={t('Отменить', 'Cancel')}
                        onClick={clearPreview}
                    >
                        <CrossIconSvg />
                    </div>
                </div>
                <div className="currentProducts__table-head">
                    <div className="currentProducts__table-col currentProducts__table-col-4">
                        {t('Товар', 'Product')}
                    </div>
                    <div className="currentProducts__table-col currentProducts__table-col-2">
                        {t('Количество', 'Quantity')}
                    </div>
                    <div
                        className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                    >
                        {t('Цена', 'Price')}
                    </div>
                    <div className="currentProducts__table-col currentProducts__table-col-1">
                        {t('Итого', 'Total')}
                    </div>
                </div>
                <div className="currentProducts__table-container">
                    <div className="currentProducts__table-container-rows">
                        <OrderTableProductRow
                            item={basketItemPreview}
                            canEditProductPrice={orderService.currentUser.canEditProductPrice}
                            key={basketItemPreview.id}
                            isShownForPreview={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
            {
                !mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved.length
                && !isPromotionBuilderActive
                && (
                    <div className="currentProducts__table-container">
                        <div className="currentProducts__table-container-rows">
                            <EmptyMainProductsNotification
                                className="currentProducts__table-row EmptyValueForMainProducts"
                                animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                            />
                        </div>
                    </div>
                )
            }
            {
                haveItemsInBasket && (
                    <>
                        <div className="currentProducts_items_to_add">
                            <div
                                data-cy="products_categories"
                                className={cn('currentProducts__action_buttons', isSearchModeOn && 'search_mode')}
                            >
                                <div ref={searchRef} className={cn('products_search', isSearchModeOn && 'search_mode')}>
                                    <div className="searchIcon" onClick={handleSwitchMode}><SearchIconSvg /></div>
                                    <input
                                        id="searchProducts"
                                        className="searchInput"
                                        type="text"
                                        placeholder={t('Найдите товар по названию или идентификатору', 'Search product by name or id')}
                                        onChange={onChange}
                                    />
                                    <div className="closeIcon" onClick={handleSwitchMode}><IconCrossSvg /></div>
                                </div>
                                {!isSearchModeOnForAnimation && (
                                    <>
                                        {dynamicItems.concat(staticItems).length > 0 && (
                                            <Button
                                                size="0"
                                                variant={activeAddMode === AddModeEnum.sets ? 7 : 0}
                                                onClick={handleSwitchMenuToSets}
                                                width="auto"
                                                text={productTypes[AddModeEnum.sets]}
                                                dataCyValue="category_sets"
                                            />
                                        )}

                                        <Button
                                            size="0"
                                            variant={activeAddMode === AddModeEnum.main ? 7 : 0}
                                            onClick={handleSwitchMenuToMain}
                                            width="auto"
                                            text={productTypes[AddModeEnum.main]}
                                            dataCyValue="category_main"
                                        />
                                    </>
                                )}
                            </div>
                            {isSearchModeOn && (
                                <div id="products_found" className="items">
                                    {found && found.length > 0 && found.length !== allProducts.length
                                        && (
                                            <span className="search-helper">
                                                {t('Нашлось {{foundLength}} товаров из {{allProductLength}}', 'Found {{foundLength}} products from {{allProductLength}}',
                                                    { foundLength: found.length, allProductLength: allProducts.length })}
                                            </span>
                                        )}
                                    {found && found.length > 0 && found.length === allProducts.length
                                        && (
                                            <span className="search-helper">
                                                {found.length === 1 && t('Показан {{foundLength}} товар', 'Showing {{foundLength}} product', { foundLength: found.length })}
                                                {found.length >= 2 && found.length < 5 && t('Показаны все {{foundLength}} товара', 'Showing all {{foundLength}} products', { foundLength: found.length })}
                                                {found.length >= 5 && t('Показаны все {{foundLength}} товаров', 'Showing all {{foundLength}} products', { foundLength: found.length })}
                                                {/* TODO: если нужно, то можно учесть исчесления */}
                                            </span>
                                        )}
                                    {found && found.length ? (
                                        found.map((item) => {
                                            if (item.type === ProductItemTypeEnum.DYNAMIC_SET || item.type === ProductItemTypeEnum.STATIC_SET) {
                                                return (
                                                    <ProductSetToAdd
                                                        key={item.id}
                                                        catalogItem={item}
                                                    />
                                                );
                                            }

                                            return (
                                                <ProductItemToAdd
                                                    key={item.id}
                                                    catalogItem={item}
                                                />
                                            );
                                        })
                                    ) : (
                                        <span className="no-items">{t('Ничего не нашлось', 'No items found')}</span>
                                    )}
                                </div>
                            )}
                            {!isSearchModeOnForAnimation && activeAddMode === AddModeEnum.sets && (
                                <div className="items">
                                    {dynamicItems.concat(staticItems).map((item) => (
                                        <ProductSetToAdd
                                            key={item.id}
                                            catalogItem={item}
                                        />
                                    ))}
                                    {dynamicItems.concat(staticItems).length === 0 && (
                                        <span className="no-items">{t('Нет наборов товаров', 'No sets')}</span>
                                    )}
                                </div>
                            )}
                            {!isSearchModeOnForAnimation && activeAddMode === AddModeEnum.main && (
                                <div className="items">
                                    {mainRegularItems.map((item, index) => {
                                        // TODO: во вкладке main не нужно выводить сэты
                                        if (item.type === ProductItemTypeEnum.DYNAMIC_SET || item.type === ProductItemTypeEnum.STATIC_SET) {
                                            return (
                                                <ProductSetToAdd
                                                    key={item.id}
                                                    catalogItem={item}
                                                />
                                            );
                                        }

                                        return (
                                            <ProductItemToAdd
                                                key={item.id}
                                                catalogItem={item}
                                                dataCyValue={index}
                                            />
                                        );
                                    })}
                                    {mainRegularItems.length === 0 && (
                                        <span className="no-items">{t('Нет основных товаров', 'No main items')}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )
            }
            {
                !haveItemsInBasket && (
                    <strong className="currentProducts__table-noProducts">
                        {t('Нет продуктов', 'No products')}
                    </strong>
                )
            }
        </div>
    );
};


export default observer(ProductTable);
