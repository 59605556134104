import React from 'react';
import cn from 'classnames';


interface IProblemSvgProps {
    className?: string;
}

const ProblemSvg = ({ className, ...props }: IProblemSvgProps): JSX.Element => (
    <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(['problemSvg', className])}
        {...props}
    >
        <circle cx="22" cy="22" r="21.5" stroke="#FF3B30" />
        <path
            d="M21.9997 10.3333C15.5597 10.3333 10.333 15.5599 10.333 21.9999C10.333 28.4399 15.5597 33.6666 21.9997 33.6666C28.4397 33.6666 33.6663 28.4399 33.6663 21.9999C33.6663 15.5599 28.4397 10.3333 21.9997 10.3333ZM21.9997 23.1666C21.358 23.1666 20.833 22.6416 20.833 21.9999V17.3333C20.833 16.6916 21.358 16.1666 21.9997 16.1666C22.6413 16.1666 23.1663 16.6916 23.1663 17.3333V21.9999C23.1663 22.6416 22.6413 23.1666 21.9997 23.1666ZM23.1663 27.8333H20.833V25.4999H23.1663V27.8333Z"
            fill="#FF3B30"
        />
    </svg>

);

export default ProblemSvg;
