import { OrderStatusesEnum, OrderStatusesERPEnum, OrderStatusesTypeERPEnum } from '@api/order-api-service/models';


class OrderStatusesBase {
    public getCrmStatusText(statusID: OrderStatusesEnum | null): string {
        switch (statusID) {
            case OrderStatusesEnum.CREATED: return 'New';
            case OrderStatusesEnum.APPROVE: return 'Approve';
            case OrderStatusesEnum.NO_ANSWER: return 'No answer';
            case OrderStatusesEnum.RECALL: return 'Recall';
            case OrderStatusesEnum.REJECT: return 'Reject';
            case OrderStatusesEnum.TRASH: return 'Trash';
            case OrderStatusesEnum.CRASH: return 'Crash';
            default: return '—';
        }
    }

    public getTypeStatusErp(statusErp: OrderStatusesERPEnum): OrderStatusesTypeERPEnum {
        switch (statusErp) {
            case OrderStatusesERPEnum.ORDER_CREATED_WITH_A_LONG_FORM:
            case OrderStatusesERPEnum.ORDER_IS_CREATED_WITH_A_SHORT_FORM:
            case OrderStatusesERPEnum.ORDER_IS_CREATED_IN_CALL_CENTER_FROM_INCOMING_QUEUE:
                return OrderStatusesTypeERPEnum.INITIAL_STATUS;
            case OrderStatusesERPEnum.SENT_TO_CALL_CENTER_FOR_A_CALL:
            case OrderStatusesERPEnum.CHIME_CALL_CENTER:
            case OrderStatusesERPEnum.NO_ANSWER_CALL_CENTER:
            case OrderStatusesERPEnum.APPROVED_CALL_CENTER:
            case OrderStatusesERPEnum.ACCEPTED_PENDING_SHEET_GENERATION:
            case OrderStatusesERPEnum.SHEET_GENERATED_LABELS_PENDING:
            case OrderStatusesERPEnum.LABELS_HAVE_BEEN_CREATED:
            case OrderStatusesERPEnum.LABELS_ARE_AFFIXED:
            case OrderStatusesERPEnum.APPROVED_COURIER:
            case OrderStatusesERPEnum.LEFT_WAREHOUSE:
            case OrderStatusesERPEnum.PAID_FOR_DELIVERED:
            case OrderStatusesERPEnum.REDEMPTION_COURIER:
            case OrderStatusesERPEnum.REJECTION_COURIER:
            case OrderStatusesERPEnum.CANCEL_COURIER:
            case OrderStatusesERPEnum.LEFT_OUR_WAREHOUSE_ONCE_UPON_A_TIME:
            case OrderStatusesERPEnum.FORWARDED_COURIER:
            case OrderStatusesERPEnum.LEFT_THEIR_WAREHOUSE:
            case OrderStatusesERPEnum.REJECTED_LOGISTICS:
            case OrderStatusesERPEnum.DELAYED_COURIER:
            case OrderStatusesERPEnum.TRASH_CALL_CENTER:
            case OrderStatusesERPEnum.FORWARDED_FOR_RETURN_COURIER:
            case OrderStatusesERPEnum.ACCEPTED_LOGISTICS:
            case OrderStatusesERPEnum.SENT_FOR_DELIVERY_COURIER:
            case OrderStatusesERPEnum.POSTPONED_LOGISTICS:
            case OrderStatusesERPEnum.RECLAMATION:
            case OrderStatusesERPEnum.SHEET_ACCEPTED_COURIER:
            case OrderStatusesERPEnum.ON_REVIEW_CALL_CENTER:
            case OrderStatusesERPEnum.SHEET_SENT_COURIER:
                return OrderStatusesTypeERPEnum.INTERMEDIATE_STATUS;
            case OrderStatusesERPEnum.DIVERTED_CALL_CENTER:
            case OrderStatusesERPEnum.RETURNED_TO_SENDER_COURIER:
            case OrderStatusesERPEnum.MONEY_RECEIVED_FINANCE:
            case OrderStatusesERPEnum.DOUBLE_CALL_CENTER:
            case OrderStatusesERPEnum.LOST_COURIER:
            case OrderStatusesERPEnum.AUTOTRASH_ORDERING:
            case OrderStatusesERPEnum.AUTOTRASH_ORDER_BY_DUPLICATE:
            case OrderStatusesERPEnum.CUSTOMER_REFUSED:
            case OrderStatusesERPEnum.WRITTEN_OF_FINANCE:
                return OrderStatusesTypeERPEnum.FINAL_STATUS;
            default:
                return OrderStatusesTypeERPEnum.UNDEFINED;
        }
    }
}


export default OrderStatusesBase;
