import ApiBase from '@core/api';
import { Inject } from 'react-ioc';


interface PropertySelector<TPayload, T> {
    (payload: TPayload): T;
}


class FileStorageService {
    @Inject apiBase: ApiBase;

    async doRequest<TPayload, T>(method: string, fd: FormData, propertySelector: PropertySelector<TPayload, T>): Promise<T> {
        const result = await this.apiBase.post<T>(method, fd);

        const { data, success, message }: any = result;

        if (!success) {
            throw typeof message.message === 'string' ? message.message : 'Unknown error';
        }

        return propertySelector(data);
    }
}


export default FileStorageService;
