import { toFactory } from 'react-ioc';


import KladrService from '@services/KladrService';
import { Store } from '@store/store';
import KladrApiService from '@api/kladr-api-service';


const KladrServiceDI = (): any => [
    KladrService,
    toFactory(
        [
            Store,
            KladrApiService,
        ],
        (
            rootStore: Store,
            kladrApiService: KladrApiService,
        ) => new KladrService(
            rootStore,
            kladrApiService,
        ),
    ),
];


export default KladrServiceDI;