import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export enum ComparisonOperatorsOfDeliveryEnum {
    /**
     * не зависит от кол-ва
     */
    NOT_DEPENDS_ON_QUANTITY = 'DO_WHATEVER',
    EQUAL = '=',
    MORE = '>',
    MORE_OR_EQUAL = '>=',
}

export const conditionOptions: ComparisonOperatorsOfDeliveryEnum[] = [
    ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY,
    ComparisonOperatorsOfDeliveryEnum.EQUAL,
    ComparisonOperatorsOfDeliveryEnum.MORE,
    ComparisonOperatorsOfDeliveryEnum.MORE_OR_EQUAL,
];

export const DeliveryConditions = t
    .model('DeliveryConditions', {
        id: t.identifier,
        /**
         * Использовать стоимость доставки КС из брокера
         */
        useDefaultShippingPrice: t.boolean,
        /**
         * Стоимость доставки
         */
        shippingPrice: t.number,
        /**
         * Учитывать количество промо товаров
         */
        countWithPromo: t.boolean,
        /**
         * Количество товаров в заказе
         */
        amountOfProducts: t.number,
        /**
         * Необходимо выполнение условия
         */
        condition: t.optional(t.enumeration(conditionOptions), ComparisonOperatorsOfDeliveryEnum.NOT_DEPENDS_ON_QUANTITY),
    });


export interface IDeliveryConditionsModel extends Instance<typeof DeliveryConditions> {}
export interface IDeliveryConditionsModelSnapshotIn extends SnapshotIn<typeof DeliveryConditions> {}
export interface IDeliveryConditionsModelSnapshotOut extends SnapshotOut<typeof DeliveryConditions> {}
