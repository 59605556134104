import ApiBase from '@core/api';
import get from 'lodash/get';
import {
    getEnvCustomerServiceSaveComment, getEnvGetCustomerOrderSearchEndpoint, getEnvGetCustomerServiceCreateOrEditClientCardEndpoint, getEnvGetCustomerServiceGetSubjectList, getEnvGetCustomerServiceSearchClientCardEndpoint,
} from '@/env/requestEndpoints';
import {
    IClientCardData, ICreateAppealParam, ICreateClientCardParam, ICustomerAppealSubjectData, IGetSearchOrdersParam, IOrderSearchDataCS, ISearchClientCardRequestData,
} from './models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class CustomerApiService {
    @Inject apiBase: ApiBase;

    async searchOrders(params: IGetSearchOrdersParam): Promise<any> {
        const response = await this.apiBase.get<IWithResponse<IOrderSearchDataCS[]>>(getEnvGetCustomerOrderSearchEndpoint(), params);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async searchClientCard(params: ISearchClientCardRequestData): Promise<IClientCardData | []> {
        const response = await this.apiBase.get<IWithResponse<IClientCardData | []>>(getEnvGetCustomerServiceSearchClientCardEndpoint(), params);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async createClientCard(phone: string, clientName: string): Promise<IClientCardData> {
        const params: ICreateClientCardParam = {
            full_name_client: clientName,
            contacts: [
                {
                    value: phone,
                    type_code: 1,
                },
            ],
        };
        const response = await this.apiBase.post<IWithResponse<IClientCardData>>(getEnvGetCustomerServiceCreateOrEditClientCardEndpoint(), params);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    /**
     * Получить список тем обращений для создания тикета в JIRA
     */
    async getSubjectList(): Promise<ICustomerAppealSubjectData[]> {
        const response = await this.apiBase.get<IWithResponse<ICustomerAppealSubjectData[]>>(getEnvGetCustomerServiceGetSubjectList());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async createAppeal(appealId: number, text: string, isFinal: boolean, subjectId?: number, customSubject?: string, commentId?: number): Promise<any> {
        const params: ICreateAppealParam = {
            appeal_id: appealId,
            comment: text,
            id: commentId,
            appeal_subject_id: subjectId,
            custom_subject: customSubject,
            is_final: isFinal,
        };

        const response = await this.apiBase.post<IWithResponse<IClientCardData>>(getEnvCustomerServiceSaveComment(), params);

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data as any;
    }
}

export default CustomerApiService;
