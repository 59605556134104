import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import '@styles/components/ui-elements/Spinner.scss';


interface ISpinnerProps {
    className?: string;
    text?: string;
    spinnerColor?: string;
}


const Spinner: FunctionComponent<ISpinnerProps> = ({
    className = '',
    text = '',
    spinnerColor = '#9EA0A5',
}) => (
    <div
        className={cn('spinner__wrapper e-spinner', className)}
        style={{ '--spinner-color': spinnerColor } as Record<string, string>}
    >
        <div className="lds-spinner">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
        <div className="text">{text}</div>
    </div>
);


export default Spinner;
