import { customI18NTFunction } from '@services/I18NService';
import ErrorBase from '@core/error/ErrorBase';


class UserDeniedMediaAccessError extends ErrorBase {
    constructor(t: customI18NTFunction) {
        const message = {
            key: 'Пользователь запретил доступ к медиа.',
            defaultValue: 'User Denied Media Access.',
        };

        super(message, t);
    }
}


export default UserDeniedMediaAccessError;
