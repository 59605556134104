import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class UnknownShippingPriceConditionError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
    ) {
        super();

        this.message = 'В конструкторе акций получили неизвестный тип условия (promotion_shipping_price_condition) для расчета доставки. Допустимые значения: 0, 1, 3, 5, null';
    }
}


export default UnknownShippingPriceConditionError;
