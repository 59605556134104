import { IReactionDisposer, reaction } from 'mobx';
import { LIMIT_TIME_SAVE_TRIGGER, LIMIT_TIME_VIEW_TRIGGER } from '@core/constants/notificationTriggers';
import { NotificationModel } from '@models/mobx-state-tree/Notification.model';
import { IOperatorInactivityNotificationTimersModel } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import { Store } from '@store/store';
import OrderService from '@services/order/OrderService';
import SipService from '@services/sip/SipService';


class NotificationSubscriptionService {
    private _disposerOnChangeViewFormFillNotificationVisible: IReactionDisposer | undefined;

    private _disposerOnChangeSaveFormFillNotificationVisible: IReactionDisposer | undefined;

    private _disposerOnChangeModalFormFillNotificationVisible: IReactionDisposer | undefined;

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _sipService: SipService,
    ) {}

    private get _inactivityTimer(): IOperatorInactivityNotificationTimersModel {
        return this._store.inactivityTimer;
    }

    public subscribe = (): void => {
        /**
         * Если таймер просмотра формы дошел до лимита:
         * 1. создаём уведомление
         * 2. очищаем заказ
         * 3. переводим оператора в не готов
         */
        this._disposerOnChangeViewFormFillNotificationVisible = reaction<boolean>(
            () => this._inactivityTimer.viewFormFillNotificationVisible,
            (isViewFormFillNotificationVisible) => {
                const callback = async (isViewFormFillNotificationVisible: boolean): Promise<void> => {
                    if (!isViewFormFillNotificationVisible) {
                        return;
                    }

                    const notification = await this._orderService.createNotification(LIMIT_TIME_VIEW_TRIGGER);
                    if (notification) {
                        const notificationModel = NotificationModel.create({
                            id: String(notification.id),
                            message: notification.message,
                            isFeedback: notification.is_feedback,
                        });
                        this._store.addNotification(notificationModel);
                    }

                    await this._orderService.releaseCurrentOrder();
                    this._inactivityTimer.setViewFormFillTimePassed(0);
                    this._store.currentUser.setIsReady(false);
                    this._sipService.endCall();
                    this._store.clearCurrentOrder();
                };

                void callback(isViewFormFillNotificationVisible);
            },
        );

        /**
         * Если в режиме limit_time_save
         * дотикали до лимита, то:
         * 1. заказ с оператора не снимаем! (если звонок был длинным[продуктивным])
         * 2. создаем уведомление
         * 3. переводим оператора в не готов
         */
        this._disposerOnChangeSaveFormFillNotificationVisible = reaction<boolean>(
            () => this._inactivityTimer.saveFormFillNotificationVisible,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            async (isSaveFormFillNotificationVisible): Promise<void> => {
                if (isSaveFormFillNotificationVisible) {
                    const { wasLastCallShort } = this._inactivityTimer;

                    const notification = await this._orderService.createNotification(LIMIT_TIME_SAVE_TRIGGER, wasLastCallShort);
                    if (notification) {
                        const notificationModel = NotificationModel.create({
                            id: String(notification.id),
                            message: notification.message,
                            isFeedback: notification.is_feedback,
                        });
                        this._store.addNotification(notificationModel);
                    }

                    this._store.currentUser.setIsReady(false);

                    /**
                     * Новые требования https://2wtrade-tasks.atlassian.net/browse/UI-821
                     * Если дозвонились, но звонок был короткий по настройкам,
                     * то заказ снимаем с оператора
                     */
                    if (wasLastCallShort && !this._store.currentOrder.postProcessing) {
                        this._inactivityTimer.setSaveFormFillTimePassed(0);
                        await this._orderService.releaseCurrentOrder();
                        this._sipService.endCall();
                        this._store.clearCurrentOrder();
                    }
                }
            },
        );

        /**
         * Если в режиме limit_time_modal
         * дотикали до лимита, то:
         * 1. Принудительно делаем неактивными кнопки статусов, субстатусов и кнопку позвонить. Уведомление об просрочке статуса мы получаем
         * на вебсокете при event post_stop_timer_modal_status. На всякий случай (если оно придет не вовремя - с отсрочкой) отключаем кнопки,
         * чтобы оператор не мог провалиться в заказ нажав на них и тем самым закрыв модальные окна или не мог позвонить;
         * 2. Устанвливаем свойство отвечающее modalFormFillNotificationVisible на случай просрочки второго таймера для
         * модального окна и возможности отключения кнопок;
         */

        this._disposerOnChangeModalFormFillNotificationVisible = reaction<boolean>(
            () => this._inactivityTimer.modalFormFillNotificationVisible,

            (isModalFormFillNotificationVisible): void => {
                if (isModalFormFillNotificationVisible && this._inactivityTimer.modalTimerActive) {
                    this._store.currentOrder.form.setDisableStatusSubStatusPhoneButtons(true);
                    this._store.inactivityTimer.setModalFormFillNotificationVisible(false);
                }
            },
        );
    };

    public unsubscribe = (): void => {
        if (this._disposerOnChangeViewFormFillNotificationVisible) {
            this._disposerOnChangeViewFormFillNotificationVisible();
        }

        if (this._disposerOnChangeSaveFormFillNotificationVisible) {
            this._disposerOnChangeSaveFormFillNotificationVisible();
        }

        if (this._disposerOnChangeModalFormFillNotificationVisible) {
            this._disposerOnChangeModalFormFillNotificationVisible();
        }
    };
}

export default NotificationSubscriptionService;
