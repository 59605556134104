import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const OrderStatus = t
    .model('OrderStatus', {
        id: t.maybeNull(t.number),
        name: t.string,
    });


export interface IOrderStatusModel extends Instance<typeof OrderStatus> {}
export interface IOrderStatusModelSnapshotIn extends SnapshotIn<typeof OrderStatus> {}
export interface IOrderStatusModelSnapshotOut extends SnapshotOut<typeof OrderStatus> {}
