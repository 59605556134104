import Cookie from 'mobx-cookie';
import { observable, action, computed } from 'mobx';

import { ACCESS_TOKEN, TOKEN_EXPIRE_AT, ADVANCED_FRONTEND } from '@core/constants/local';
import { CALLIBRI_BOT_CURRENT_QUESTION, CALLIBRI_START_DATE, CLBVID, V1_SESSIONS_CALLIBRI } from '@core/constants/cookies';


class CookieService {
    @observable private readonly _accessToken: Cookie = new Cookie(ACCESS_TOKEN);

    @observable private readonly _tokenExpireAt: Cookie = new Cookie(TOKEN_EXPIRE_AT);

    @observable private readonly _advancedFrontend: Cookie = new Cookie(ADVANCED_FRONTEND);

    @observable private readonly _clbvid: Cookie = new Cookie(CLBVID);

    @observable private readonly _callibriBotCurrentQuestion: Cookie = new Cookie(CALLIBRI_BOT_CURRENT_QUESTION);

    @observable private readonly _callibriStartDate: Cookie = new Cookie(CALLIBRI_START_DATE);

    @observable private readonly _v1SessionCallibri: Cookie = new Cookie(V1_SESSIONS_CALLIBRI);

    @computed
    public get tokenExpireAtValue(): number {
        return Number(this._tokenExpireAt.value);
    }

    @computed
    private get _isNotEmptyAccessToken(): boolean {
        return Boolean(this.accessTokenValue) && Boolean(this.tokenExpireAtValue);
    }

    @computed
    private get advancedFrontendValue(): string {
        return this._advancedFrontend.value || '';
    }

    @computed
    public get accessTokenValue(): string {
        return this._accessToken.value || '';
    }

    @computed
    public get hasValueAdvancedFrontend(): boolean {
        return Boolean(this.advancedFrontendValue);
    }

    @computed
    public get isAuthCookieValid(): boolean {
        let result = false;

        if (this._isNotEmptyAccessToken) {
            const currentTimeStamp = Math.ceil(new Date().getTime() / 1000);

            result = this.tokenExpireAtValue >= currentTimeStamp;

            if (!result) {
                this.logout();
            }
        }

        return result;
    }

    @action
    public setAccessToken = (value: string, expired: number): void => {
        this._accessToken.set(value, { expires: new Date(expired * 1000) });
    };

    @action
    public setTokenExpireAt = (value: number): void => {
        this._tokenExpireAt.set(String(value), { expires: new Date(value * 1000) });
    };

    @action
    public clearCookieAdvancedFrontend(): void {
        this._advancedFrontend.set('', { expires: new Date(0), domain: `.${window.location.host}` });
    }

    @action
    public clearCallibriCookies(): void {
        this._clbvid.remove();
        this._callibriBotCurrentQuestion.remove();
        this._callibriStartDate.remove();
        this._v1SessionCallibri.remove();
    }

    @action
    public logout(): void {
        this._tokenExpireAt.remove();
        this._accessToken.remove();
    }
}


export default CookieService;
