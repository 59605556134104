import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const SourceModel = t
    .model('SourceModel', {
        id: t.identifier,
        name: t.maybeNull(t.string),
    });


export interface ISourceModel extends Instance<typeof SourceModel> {}
export interface ISourceModelSnapshotIn extends SnapshotIn<typeof SourceModel> {}
export interface ISourceModelSnapshotOut extends SnapshotOut<typeof SourceModel> {}
