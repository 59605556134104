import React from 'react';

interface IcDropDownSvgProps {
    className?: string;
    onClick?: () => void;
}

const IcCheckSvg = ({ ...props }: IcDropDownSvgProps): JSX.Element => (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1.35355 3.14645C1.15829 2.95118 0.841709 2.95118 0.646447 3.14645C0.451184 3.34171 0.451184 3.65829 0.646447 3.85355L3.14645 6.35355C3.34171 6.54882 3.65829 6.54882 3.85355 6.35355L9.35355 0.853553C9.54882 0.658291 9.54882 0.341709 9.35355 0.146447C9.15829 -0.0488155 8.84171 -0.0488155 8.64645 0.146447L3.5 5.29289L1.35355 3.14645Z" fill="white" />
    </svg>
);

export default IcCheckSvg;
