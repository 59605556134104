import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';
import Button from '@UIElements/Button';

import './includes/ErrorNotifyModal.scss';


export interface IErrorNotifyModal extends IModalFunctionComponentPropsBase {
    readonly confirmationText: string;
}


const ErrorNotifyModal: FunctionComponent<IErrorNotifyModal> = ({
    confirmationText,
    success,
    t,
}) => (
    <div className="ErrorNotifyModal">
        <div className="ErrorNotifyModal__content">
            <div className="ErrorNotifyModal__field">
                <strong>{confirmationText}</strong>
            </div>
            <div className="ErrorNotifyModal__field">
                <Button
                    variant="5"
                    size="1"
                    text={t('Хорошо', 'OK')}
                    width="auto"
                    className="ErrorNotifyModal__success-button"
                    onClick={success}
                />
            </div>
        </div>
    </div>
);


export default observer(ErrorNotifyModal);
