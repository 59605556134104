import React, {
    FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { useCombobox } from 'downshift';
import reactStringReplace from 'react-string-replace';
import cn from 'classnames';
import Popup from '../Popup';
import './InputAutocomplete.scss';
import IcDropDownSvg from '../CountrySelect/includes/IcDropDownSvg';
import IcCheckSvg from '../CladAutofill/includes/IcCheckSvg';
import { IDropdownProps, ISelectedValue } from './models';
import ClearSelectionButton from './ClearSelectionButton';

/**
 * В реализации компоненты использована библиотека downshift
 * https://github.com/downshift-js/downshift
 * Библиотека предоставляет несколько решений:
 * 1. Хуки useSelect, useCombobox и useMultipleSelection
 * 2. Компонент Downshift
 *
 * Для реализации функционала выбран п.1 - хук useCombobox
 *
 * Основным является описание конструкции <input> и <popup> элемента, внутри попапа список <ul><li>...</ul>
 * Далее, пробрасываются propsGetters в каждую комоненту в соответствии с:
 * https://www.downshift-js.com/use-combobox#this-solution
 *
 * Библиотека хорошо кастомизируется:
 * Например, можно вместо дефолтного action описать свою логику поведения компонента:
 * https://www.downshift-js.com/use-combobox#state-reducer
 *
 */

const DEFAULT_VALUE: ISelectedValue<string>[] = [{
    label: '',
    value: '',
}];

const SpanFragment = (i: number, match: string): JSX.Element => <span key={i} className="highlight-search">{ match }</span>;

const InputAutocomplete: FunctionComponent<IDropdownProps> = ({
    values,
    onChange,
    selected,
    label,
    inputPlaceholder,
    divPlaceholder,
    className,
    disabled,
    defaultSelectedValue,
    dataCyValue,
    clearValueEvent,
}): JSX.Element => {
    const highlightSearchEntry = (inputValue: string | null, item: string): React.ReactNodeArray | string => {
        if (inputValue) {
            return reactStringReplace(item, inputValue, (match, i) => (SpanFragment(i, match)));
        }
        return item;
    };

    const onChangedSelectedItem = useCallback<(selectedItem: ISelectedValue<string> | null) => void>(
        (selectedItem: ISelectedValue<string> | null) => onChange(selectedItem),
    [onChange]);

    const [inputItems, setInputItems] = useState<ISelectedValue<string>[]>(DEFAULT_VALUE);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setInputItems(values);
    }, [values]);

    const {
        isOpen,
        selectedItem,
        highlightedIndex,
        inputValue,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        reset,
        getItemProps,
    } = useCombobox({
        initialHighlightedIndex: 0,
        defaultHighlightedIndex: 0,
        initialSelectedItem: defaultSelectedValue,
        itemToString: (item) => (item ? item.label : ''),
        onSelectedItemChange: (changes) => {
            if (changes.selectedItem) {
                onChangedSelectedItem(changes.selectedItem);
            }
        },
        items: inputItems,
        onInputValueChange: ({ inputValue }) => {
            setInputItems(
                values.filter((item: { label: string }) => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase())),
            );
        },
    });

    useEffect(() => {
        if (selected) {
            if (selected.value !== selectedItem?.value) {
                reset();
                onChangedSelectedItem(null);
            }
        }
    }, [selected]);

    const clearSelection = () => {
        if (!disabled && clearValueEvent){
            reset();
            onChangedSelectedItem(null);
            clearValueEvent(true);
        } else if (!disabled) {
            reset();
            onChangedSelectedItem(null);
        }
    };

    return (
        <div
            {...getComboboxProps()}
            className={cn([
                'input-autocomplete-select__wrapper searchable',
                selectedItem ? 'has-value' : '',
                isOpen ? 'focus' : '',
                disabled ? 'disabled' : '',
                className,
            ])}
        >
            {label && (
                <div className="input-label">
                    <div className="input-label-text">{label}</div>
                </div>
            )}
            {
                selectedItem
                    ? (
                        <IcCheckSvg
                            className={cn([
                                'checkIcon',
                                isOpen && 'arrow--up',
                            ])}
                        />
                    )
                    : (
                        <div {...getToggleButtonProps({
                            disabled,
                            onClick: () => {
                                if (inputValue) {
                                    if (inputRef.current) {
                                        inputRef.current.select();
                                    }
                                }
                            },
                        })}
                        >
                            <IcDropDownSvg
                                className={cn([
                                    'arrow',
                                    isOpen && 'arrow--up',
                                ])}
                            />
                        </div>
                    )
            }
            {selectedItem && !disabled && (
                <ClearSelectionButton className="clear-selection" onClick={clearSelection} />
            )}
            <div
                className={cn([
                    'handler',
                    isOpen && 'opened',
                    !isOpen && 'closed',
                    selectedItem && 'selected',
                    divPlaceholder && 'placeholder',
                ])}
                {...getToggleButtonProps({
                    disabled,
                    onClick: () => {
                        if (inputValue) {
                            if (inputRef.current) {
                                inputRef.current.select();
                            }
                        }
                    },
                })}
                {...(dataCyValue ? { 'data-cy': `downshift-toggle-button_${dataCyValue}` } : null)}
            >
                {selectedItem ? selectedItem.label : divPlaceholder}
            </div>
            <input
                className={cn([
                    isOpen && 'opened',
                    !isOpen && 'closed',
                ])}
                autoComplete="disabled"
                {...getInputProps({
                    ref: inputRef,
                    disabled,
                    placeholder: inputPlaceholder,
                })}
                {...(dataCyValue ? { 'data-cy': `downshift-input_${dataCyValue}` } : null)}
            />
            <Popup
                className="select-popup"
                isActive={isOpen}
            >
                <ul
                    className="values"
                    {...getMenuProps()}
                    {...(dataCyValue ? { 'data-cy': `downshift-list-values_${dataCyValue}` } : null)}
                >
                    {
                        isOpen && inputItems
                            .map((item: { value: any; label: string }, index: number) => (
                                <li
                                    className="result"
                                    {...getItemProps({
                                        disabled,
                                        style: {
                                            backgroundColor:
                                                index === highlightedIndex ? 'lightgray' : undefined,
                                        },
                                        key: item.value,
                                        item,
                                        index,
                                    })}
                                    {...(dataCyValue ? { 'data-cy': `downshift-item_${dataCyValue}_${index}` } : null)}
                                >
                                    { highlightSearchEntry(inputValue, item.label) }
                                </li>
                            ))
                    }
                </ul>
            </Popup>
        </div>
    );
};


export default InputAutocomplete;
