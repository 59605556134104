import * as Sentry from '@sentry/browser';
import isString from 'lodash/isString';
import LogRocket from 'logrocket';

import getAppVersion from '@/env/getAppVersion';
import getConnectionType from '@/logUtils/getConnectionType';
import { getLogRocketAppId, getLogRocketVerificatorAppId } from '@/env/getLogRocketAppId';
import getSentryDSN from '@/env/getSentryDSN';
import XHR401Error from '@core/error/XHR401Error';
import OfflineError from '@core/error/OfflineError';


class SentryService {
    private readonly SENTRY_DSN: string;

    private readonly LOG_ROCKET_APP_ID: string;

    private readonly LOG_ROCKET_VERIFICATOR_APP_ID: string;

    constructor() {
        this.SENTRY_DSN = getSentryDSN();
        this.LOG_ROCKET_APP_ID = getLogRocketAppId();
        this.LOG_ROCKET_VERIFICATOR_APP_ID = getLogRocketVerificatorAppId();
    }

    /**
     * Линкуем сессию LogRocket в Sentry
     * В ошибках sentry можно будет посмотреть ссылку на сеанс
     */
    public linkLogRocketSessionIntoSentry = (): void => {
        if (this.LOG_ROCKET_APP_ID || this.LOG_ROCKET_VERIFICATOR_APP_ID) {
            LogRocket.getSessionURL((sessionURL) => {
                Sentry.configureScope((scope) => {
                    scope.setExtra('sessionURL', sessionURL);
                });
            });
        }
    };

    public init(): void {
        if (!this.SENTRY_DSN) {
            return;
        }

        // eslint-disable-next-line no-console
        console.info('init Sentry');

        const appVersion = getAppVersion();

        Sentry.init({
            dsn: this.SENTRY_DSN,
            release: `2wcall-ui@${getAppVersion()}`,
        });

        Sentry.configureScope((scope) => {
            scope.setTag('version', appVersion);
            scope.setTag('domain', window.location.host);
        });

        const vanillaConsoleError = window.console.error;

        function onConsoleError(err?: any) {
            vanillaConsoleError(err);

            Sentry.withScope((scope) => {
                const connectionType = getConnectionType();
                if (connectionType) {
                    // Если узнали тип интернет подключения, то запишем его
                    scope.setTag('connectionType', connectionType);
                }

                if (err instanceof XHR401Error) {
                    // Ошибки 401 не логируем

                    return;
                }

                if (err instanceof OfflineError) {
                    Sentry.setTag('No internet connection', 'true');
                    // eslint-disable-next-line
                    console.warn(err);
                    return;
                }

                if (err instanceof DOMException) {
                    // Если получаем DOMException.NotAllowedError не логируем
                    // eslint-disable-next-line
                    console.warn(err);
                    return;
                }

                if (isString(err)) {
                    Sentry.captureMessage(err);
                } else {
                    Sentry.captureException(err);
                }
            });
        }

        window.console.error = onConsoleError;
    }
}


export default SentryService;
