import React, { FC, useCallback } from 'react';

import './SideBarDeepStatusScreenMenu.scss';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import cn from 'classnames';
import { observer } from 'mobx-react';
import ModalService from '@core/services/ModalService';
import { ConfirmModalType } from '@core/models/ModalWindow';
import { SipService } from '@services/index';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';


const SideBarDeepStatusScreenMenu: FC = () => {
    const [
        { isCallingRightNow },
        { t },
        {
            hasNotificationWithFeedBack,
            ui: {
                sidebar: {
                    setStatusVisible, statusVisible,
                },
            },
            currentOrder,
            currentUser: {
                mode,
                setIsReady,
                unreadyAfterSave,
                setUnreadyAfterSave,
                haveSipPlatinumAccountCredential,
            },
        },
        modalService,
    ] = useInstances(
        SipService,
        I18NService,
        Store,
        ModalService,
    );

    const { isEmptyCurrentOrder } = currentOrder;

    const onReadyStatusClick = useCallback(
        () => {
            setStatusVisible(false);

            if (unreadyAfterSave) {
                setUnreadyAfterSave(false);
            }

            if (!haveSipPlatinumAccountCredential && (
                mode === UserModeEnum.REGULAR
                || mode === UserModeEnum.CLIENT_SERVICE
            )) {
                void modalService.showConfirmModal(
                    t(
                        'Чтобы перейти в готов для звонка, необходимо указать корректные SIP данные. Свяжитесь с поддержкой.',
                        'To go ready to call, you need to provide correct SIP data. Please contact support.',
                    ),
                    ConfirmModalType.Yes,
                );
                return;
            }

            if (mode === UserModeEnum.PREDICTIVE) {
                // TODO: Добавить проверку наличия PREDICTIVE данных для звонка
                void modalService.showConfirmModal(
                    t(
                        'Чтобы перейти в готов для звонка, необходимо сменить режим SIP с предиктивного на обычный. Свяжитесь с поддержкой.',
                        'To go ready to call, you need to ask to change SIP mode from predictive to usual. Please contact support.',
                    ),
                    ConfirmModalType.Yes,
                );
                return;
            }

            if (mode === UserModeEnum.CLIENT_SERVICE) {
                setIsReady(true);
            }

            if (mode === UserModeEnum.REGULAR) {
                if (!hasNotificationWithFeedBack) {
                    setIsReady(true);
                } else {
                    void modalService.showConfirmModal(
                        t(
                            'Чтобы перейти в готов для звонка, необходимо ответить на все уведомления требующие обратную связь.',
                            'To go ready to call, you need to answer all notifications that require feedback.',
                        ),
                        ConfirmModalType.Yes,
                    );
                }
            }
        },
        [unreadyAfterSave, setUnreadyAfterSave, hasNotificationWithFeedBack, mode],
    );

    const onNotReadyStatusClick = useCallback(
        () => {
            setStatusVisible(false);

            if (mode === UserModeEnum.REGULAR && !isEmptyCurrentOrder) {
                setUnreadyAfterSave(true);
                return;
            }

            if (mode === UserModeEnum.CLIENT_SERVICE && isCallingRightNow) {
                setUnreadyAfterSave(true);
                return;
            }

            setIsReady(false);
        },
        [isEmptyCurrentOrder, setStatusVisible, setUnreadyAfterSave, mode, isCallingRightNow],
    );

    const onBackButtonClick = useCallback(() => {
        setStatusVisible(false);
    }, [setStatusVisible]);

    return (
        <div className={cn('SideBarDeepStatusScreenMenu', statusVisible && 'visible')}>
            <div className="header">
                <div className="arrow-back" onClick={onBackButtonClick} />
                <div className="text">
                    {t('Статус', 'Status')}
                </div>
            </div>
            <div className="items">
                <div className="item ready" onClick={onReadyStatusClick}>
                    <div className="icon" />
                    <div className="title">
                        {t('Готов к следующему звонку', 'Ready to next call')}
                    </div>
                </div>
                <div className="item un-ready" onClick={onNotReadyStatusClick}>
                    <div className="icon" />
                    <div className="title">
                        {t('Не готов к следующему звонку', 'Unready to next call')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(SideBarDeepStatusScreenMenu);
