import React, { FunctionComponent, useEffect } from 'react';

import { block } from 'bem-cn';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { v4 } from 'uuid';

import { BillingListStore } from '@store/billingListStore';
import BillingListService from '@components/main/billing-list-page/BillingListService';

import Tooltip from '@UIElements/Tooltip/tooltip';
import BillingListTooltipContent, { IDataTooltip }
    from '@components/main/billing-list-page/billing-list-tooltip-content/billing-list-tooltip-content';
import { IDates } from '@components/main/billing-list-page/billing-list-nav/billing-list-nav';

import map from 'lodash/map';

import './billing-list-weeks.scss';

export interface IBillingListWeeks {
    weekDates: string[];
    dates?: IDates[];
}

const BillingListWeeks: FunctionComponent<IBillingListWeeks> = ({ weekDates, dates }) => {

    const [
        billingListStore,
        {
            fetchOperatorStatisticList,
        },
    ] = useInstances(
        BillingListStore,
        BillingListService,
    );

    const b = block('billing-list-weeks');

    const dateFrom = moment().subtract(20, 'days').format('YYYY-MM-DD');

    const { weeks, maxApprovement, weeksApproves } = billingListStore;

    useEffect(() => {
        void fetchOperatorStatisticList(dateFrom);
    }, []);

    const styleColumn = (approvedCount: number) => {
        const height = (approvedCount * 72) / maxApprovement;
        return { height: `${height}px` };
    };

    const data: IDataTooltip[] = map(dates, (day, index) => {
        return {
            title: `${day.begin} - ${day.end}`,
            value: weeksApproves[index],
        };
    });

    return (
        <div className={b()}>
            <div className={b('charts')}>
                {weeks.length > 0 && weeks.map((week, index) => (
                    <Tooltip
                        style={{
                            padding: '5px 0 0 0',
                            right: 0,
                        }}
                        tooltipContent={<BillingListTooltipContent data={data}/>}
                        key={v4()}
                    >
                        <div className={b('chart')} >
                            <div className={b('columns')}>
                                {week.length && week.map((day, i) => {
                                    return day ?
                                        <div
                                            key={day.id}
                                            className={b(i === 0 ? 'first-week' : i === 1 ? 'second-week' : 'third-week')}
                                            style={styleColumn(day.approvedCount)}
                                        >
                                        </div>
                                        :
                                        null;
                                })}
                            </div>
                            <div className={b('date')}>{weekDates[index]}</div>

                        </div>
                    </Tooltip>
                ))}
            </div>
            <div className={b('line')}></div>
        </div>
    );
};

export default observer(BillingListWeeks);
