import { IReactionDisposer, reaction } from 'mobx';

import { EnumSipStatus, ISipServiceBase } from '@services/sip/models';
import AudioService from '@core/services/AudioService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import { Store } from '@store/store';

class SipServiceBaseEventSubscriptionService {
    private _callTimeTickInterval: NodeJS.Timeout | undefined;

    private _beepInterval: NodeJS.Timeout | undefined;

    private disposeCallTime: IReactionDisposer | undefined;

    private disposeBeepSound: IReactionDisposer | undefined;

    private disposeOnMuteChange: IReactionDisposer | undefined;

    constructor(
        private readonly _sipBase: ISipServiceBase,
        private readonly _audioService: AudioService,
        private readonly _store: Store,
    ) {}

    unsubscribe = (): void => {
        if (this._callTimeTickInterval) {
            clearInterval(this._callTimeTickInterval);
        }

        if (this._beepInterval) {
            clearInterval(this._beepInterval);
        }

        if (this.disposeCallTime) {
            this.disposeCallTime();
        }

        if (this.disposeBeepSound) {
            this.disposeBeepSound();
        }

        if (this.disposeOnMuteChange) {
            this.disposeOnMuteChange();
        }
    };

    subscribe = (): void => {
        /**
         * Счетчик времени звонка, работает во всех режимах
         */
        this.disposeCallTime = reaction<boolean>(
            () => ((this._sipBase.getStatus() === EnumSipStatus.LIVE && this._store.currentUser.mode === UserModeEnum.REGULAR) ||
                (this._sipBase.getStatus() === EnumSipStatus.NEW_MESSAGE)),
            (isLive) => {
                if (isLive) {
                    this._sipBase.setCallTime(0);
                    this._callTimeTickInterval = setInterval(() => {
                        this._sipBase.setCallTime(this._sipBase.getCallTime() + 1);
                    }, 1000);
                }

                if (!isLive && this._callTimeTickInterval) {
                    if (this._sipBase.getCallTime() >= Number(this._store.currentOrder.form.enableRecallAfterCallSeconds)) {
                        this._sipBase.setRecallAvailability(true);
                    }
                    this._sipBase.setCallTime(0);
                    clearInterval(this._callTimeTickInterval);
                }
            },
        );

        /**
         * Запускает звук гудка пока не установилось соединение с клиентом.
         */
        this.disposeBeepSound = reaction<boolean>(
            () => this._sipBase.getStatus() === EnumSipStatus.DIALING,
            (isDialing) => {
                if (isDialing) {
                    this._audioService.startBeepSound();
                    this._beepInterval = setInterval(() => {
                        this._audioService.startBeepSound();
                        // гудок раздается раз в 5 секунд
                    }, 5000);
                } else if (this._beepInterval) {
                    clearInterval(this._beepInterval);
                }
            },
        );

        /**
         * Если изменилось свойство mute то включает\отключает mute у текущей сессии
         */
        this.disposeOnMuteChange = reaction<boolean>(
            () => this._sipBase.getMuted(),
            (_muted) => {
                const session = this._sipBase.getSession();
                if (session) {
                    if (_muted) {
                        session.mute();
                    } else {
                        session.unmute();
                    }
                }
            },
        );
    };
}


export default SipServiceBaseEventSubscriptionService;
