import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const StatusInfo = t
    .model('StatusInfo', {
        id: t.integer,
        name: t.maybeNull(t.string),
    });


export interface IStatusInfoModel extends Instance<typeof StatusInfo> {}
export interface IStatusInfoModelSnapshotIn extends SnapshotIn<typeof StatusInfo> {}
export interface IStatusInfoModelSnapshotOut extends SnapshotOut<typeof StatusInfo> {}
