import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';

import './TooltipPortal.scss';


interface IProps {
    children: JSX.Element | JSX.Element[] | false;
}

const TooltipPortal: FC<IProps> = ({ children }) => {
    const modalRoot = document.getElementById('tooltip-portal');
    const el = document.createElement('div');

    useEffect(() => {
        if (modalRoot) {
            el.classList.add('item');
            modalRoot.appendChild(el);
        } else {
            // eslint-disable-next-line no-console
            console.error('cant find div #tooltip-portal');
        }

        return () => {
            if (modalRoot) {
                modalRoot.removeChild(el);
            }
        };
    }, [children]);

    return ReactDOM.createPortal(
        children,
        el,
    );
};

export default observer(TooltipPortal);
