import React, { FunctionComponent } from 'react';


const IcCalendar: FunctionComponent<{ className?: string }> = ({ ...props }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M13.3333 2.00002H12.6666V0.666687H11.3333V2.00002H4.66659V0.666687H3.33325V2.00002H2.66659C1.93325 2.00002 1.33325 2.60002 1.33325 3.33335V14C1.33325 14.7334 1.93325 15.3334 2.66659 15.3334H13.3333C14.0666 15.3334 14.6666 14.7334 14.6666 14V3.33335C14.6666 2.60002 14.0666 2.00002 13.3333 2.00002ZM13.3333 14H2.66659V6.66669H13.3333V14ZM13.3333 5.33335H2.66659V3.33335H13.3333V5.33335Z" fill="#8E8E93" />
    </svg>
);


export default IcCalendar;
