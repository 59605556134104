import React, { useEffect, useState } from 'react';
import { useInstances } from 'react-ioc';
import TranslationService from '@services/I18NService';

import '../recalls-page/includes/Recalls.scss';
import './includes/FindOrder.scss';
import { IBaseCellData, IGridTableColumn, WithRows } from '@UIElements/GridTable/GridTable';
import each from 'lodash/each';
import PhoneIcon from '@components/main/recalls-page/includes/PhoneIcon';
import { v4 } from 'uuid';

import { useNavigate } from 'react-router-dom';

import Spinner from '@UIElements/Spinner';
import GridTableRecalls from '@UIElements/GridTable/GridTableRecalls';
import TextInput from '@UIElements/TextInput';
import Button from '@UIElements/Button';
import VerifierService from '@services/VerifierService';
import { InputTypes } from '@UIElements/TextInput/models';
import { Store } from '@store/store';
import { IVerifierModel } from '@models/mobx-state-tree/verifier.model';
import moment from 'moment';
import { Locations } from '@core/models/locations';
import OrderApiService  from '@api/order-api-service';
import CommonStorageEventSubscriptionService from '@services/subscription/CommonStorageEventSubscriptionService';
import { UNEDITABLE } from '@core/constants/urlSearchParams';


const FindOrder = () => {

    const [
        { t },
        {
            fetchVerifierList,
            fetchGetOrderViewById,
        },
        {
            verifiersList,
            setCurrentTab,
            currentUser: {
                setIsReady,
            },
            clearVerifiers,
        },
        orderApiService,
        commonStorageEventSubscriptionService,
    ] = useInstances(
        TranslationService,
        VerifierService,
        Store,
        OrderApiService,
        CommonStorageEventSubscriptionService,
    );

    const [foreignId, setForeignId] = useState<string>('');
    const [idCrm, setIdCrm] = useState<string>('');

    const [isLoader, setIsLoader] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        commonStorageEventSubscriptionService.subscribe();
        return () => {
            commonStorageEventSubscriptionService.unsubscribe();
            clearVerifiers();
        };
    }, []);

    const handleClickPhone = async (orderId: string) => {

        const result = await orderApiService.blockOrder(Number(orderId));

        if (result) {
            setIsReady(true);
            setCurrentTab(Locations.MAIN);
            navigate(`${Locations.MAIN}?${UNEDITABLE}`);
        }
    };

    type IColsNames = 'id' | 'customerName' | 'phone'  | 'comments' ;

    const cols: IGridTableColumn[] = [
        {
            id: 'customerName',
            label: t('Имя покупателя', 'Customer name'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'id',
            label: t('Номер заказа', 'Order number'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '65px',
        },
        {
            id: 'phone',
            label: t('Телефон', 'Phone'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'products',
            label: t('Товары', 'Products'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'comments',
            label: t('Комментарии', 'Comments'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '80px',
        },
        {
            id: 'createdDate',
            label: t('Дата создания', 'Created date'),
            minMax_MaxValue: '1fr',
            minMax_MinValue: '60px',
        },
        {
            id: 'actions',
            label: '',
            minMax_MaxValue: '35px',
            minMax_MinValue: '20px',
        },
    ];

    let rows: WithRows<IColsNames, IBaseCellData>[] = [];

    const RenderCustomContent = (rowsData: WithRows<IColsNames, IBaseCellData>[], gridTableStyle: React.CSSProperties) => {
        const data: JSX.Element[] = [];
        let rowId: string | undefined;

        each(rowsData, (row) => {
            const rowCell: JSX.Element[] = [];

            each(row, (value, key) => {
                if (key === 'action') {
                    rowCell.push(
                        (
                            <div
                                className="recall-item-action"
                                key={key}
                                data-name={key}
                            >
                                <PhoneIcon onClick={() => handleClickPhone(row.id.content)}/>
                            </div>
                        ),
                    );

                    return;
                }

                rowCell.push(
                    (
                        <div
                            className="grid_cell grid_cell_height recalls-table-cells"
                            key={v4()}
                            data-name={key}
                        >
                            {value.content}
                        </div>
                    ),
                );
            });

            data.push(
                (
                    <div
                        className="grid_row grid_row__recalls"
                        id={rowId}
                        key={v4()}
                        style={gridTableStyle}
                    >
                        {rowCell}
                    </div>
                ),
            );
        });

        return data;
    };

    if (verifiersList.length > 0) {
        rows = verifiersList.map((item: IVerifierModel) => ({
            customerName: {
                id: v4(),
                content: item.customerFullName ? item.customerFullName : '-',
            },
            id: {
                id: v4(),
                content: item.id ? item.id.toString() : '-',
            },
            phone: {
                id: v4(),
                content: item.maskedPhoneNumber,
            },
            goods: {
                id: v4(),
                content: item.verifierProducts ?
                    item.verifierProducts.filter((product) => !product.gift && !product.promo)[0].name : '-',
            },
            comments: {
                id: v4(),
                content:  item.comment ? item.comment : '',
            },
            createdData: {
                id: v4(),
                content: item.createdDate ? moment(item.createdDate * 1000).format('DD.MM | HH:mm') : '-',
            },
            action: {
                id: v4(),
                content: '...',
            },
        }));
    }

    const handleSubmit = (id: string, setId: (id: string) => void, fetchList: (id: number) => Promise<void>) => {
        if (id) {
            setIsLoader(true);
            setId('');
            clearVerifiers();
            void fetchList(Number(id))
                .then(() => setIsLoader(false));
        }
    };

    const handleSubmitForeignId = () => handleSubmit(foreignId, setIdCrm, fetchVerifierList);

    const handleSubmitIdCrm = () => handleSubmit(idCrm, setForeignId, fetchGetOrderViewById);

    return (
        <div className="recalls-page">
            <div className="recalls-header">
                {t('Найти заказ', 'Find order')}
            </div>
            <div className="find-order">
                <div className="recalls-table-container">
                    <TextInput
                        className="inputFormAuth"
                        label={t('Заказ ID ERP', 'Order ID ERP')}
                        value={foreignId}
                        onChangeValue={setForeignId}
                        width="296px"
                        type={InputTypes.NUMBER}
                    />
                    <div className="find-order__button">
                        <Button
                            size="0"
                            variant="1"
                            width="172px"
                            text={t('Поиск', 'Search')}
                            disabled={isLoader}
                            onClick={handleSubmitForeignId}
                        />
                    </div>
                </div>
            </div>
            <div className="find-order">
                <div className="recalls-table-container">
                    <TextInput
                        className="inputFormAuth"
                        label={t('Заказ ID CRM', 'Order ID CRM')}
                        value={idCrm}
                        onChangeValue={setIdCrm}
                        width="296px"
                        type={InputTypes.NUMBER}
                    />
                    <div className="find-order__button">
                        <Button
                            size="0"
                            variant="1"
                            width="172px"
                            text={t('Поиск', 'Search')}
                            disabled={isLoader}
                            onClick={handleSubmitIdCrm}
                        />
                    </div>
                </div>
            </div>
            <div className="recalls-table-container">
                {isLoader && (
                    <Spinner className="preloader" text={t('Загрузка...', 'Loading...')}/>
                )}
                {!isLoader && (
                    <>
                        {
                            verifiersList.length !== 0
                            && <GridTableRecalls
                                className="recalls-table"
                                columns={cols}
                                rows={rows}
                                rowsSize={rows.length}
                                renderHeaderSeparately={false}
                                cellsRenderer={RenderCustomContent}
                                tableContentHeight={700}
                            />
                        }
                        {verifiersList.length === 0 && (
                            <div className="no-orders-message">
                                {t('Нет заказов', 'No orders')}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default FindOrder;