import React from 'react';
import cn from 'classnames';

import './CallButtonSvg.scss';


interface ICallButtonSvgProps {
    className?: string;
}

const CallButtonSvg = ({ className, ...props }: ICallButtonSvgProps): JSX.Element => (
    <svg
        className={cn(['callButtonSvg', className])}
        width="122"
        height="122"
        viewBox="0 0 122 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#filter0_d)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61 96C83.0914 96 101 78.0914 101 56C101 33.9086 83.0914 16 61 16C38.9086 16 21 33.9086 21 56C21 78.0914 38.9086 96 61 96Z"
                fill="white"
                className="callButtonSvg__shadow"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61 96C83.0914 96 101 78.0914 101 56C101 33.9086 83.0914 16 61 16C38.9086 16 21 33.9086 21 56C21 78.0914 38.9086 96 61 96Z"
                stroke="#E5E5EA"
                strokeWidth="2"
            />
        </g>
        <path
            className="callButtonSvg__phone__simple"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.2216 69.5184C75.0515 68.757 75.522 67.681 75.5174 66.5547L75.5174 62.575C75.5586 60.5524 74.0821 58.8161 72.077 58.5332C70.8847 58.3759 69.7257 58.0872 68.6102 57.6709C67.1515 57.1226 65.5036 57.474 64.3932 58.5721L63.4318 59.5335C60.9723 57.9656 58.8852 55.8786 57.3174 53.4191L58.2736 52.4629C59.3769 51.3473 59.7283 49.6994 59.1788 48.2377C58.7637 47.1252 58.475 45.9662 58.3193 44.7858C58.0366 42.7855 56.3254 41.3138 54.3176 41.3335L50.3308 41.3335C49.2067 41.3345 48.136 41.8074 47.379 42.6367C46.622 43.466 46.2484 44.5752 46.3495 45.6935C46.8113 50.0438 48.2845 54.2042 50.6517 57.8584C52.7975 61.2353 55.6689 64.1067 59.0557 66.2588C62.6829 68.6111 66.8232 70.084 71.1334 70.5523C72.2791 70.6561 73.3916 70.2799 74.2216 69.5184ZM71.7164 61.1753C72.3749 61.2683 72.868 61.8502 72.8508 62.5601V66.5601C72.8523 66.941 72.6955 67.2996 72.4189 67.5534C72.1422 67.8072 71.7714 67.9327 71.3975 67.8989C67.5259 67.478 63.784 66.1468 60.4963 64.0148C57.4295 62.066 54.8383 59.4747 52.8961 56.4184C50.7503 53.1059 49.4189 49.3457 49.0033 45.4326C48.9717 45.0807 49.0962 44.7109 49.3486 44.4345C49.6009 44.1581 49.9578 44.0005 50.332 44.0001L54.3308 44.0001C55.0132 43.9935 55.5836 44.484 55.6772 45.1467C55.8576 46.5142 56.195 47.869 56.6816 49.1729C56.8659 49.6632 56.7487 50.2125 56.3827 50.5826L54.6947 52.2706C54.2702 52.6951 54.1817 53.3507 54.4784 53.8725C56.4951 57.4192 59.4317 60.3558 62.9784 62.3725C63.5002 62.6692 64.1559 62.5807 64.5803 62.1563L66.2736 60.4629C66.6384 60.1022 67.1877 59.985 67.675 60.1682C68.9819 60.6559 70.3367 60.9934 71.7164 61.1753Z"
            fill="#8E8E93"
        />
        <path
            className="callButtonSvg__phone__dial"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.9926 9.80428C30.4334 4.82335 26.503 0.893956 21.5264 0.340536C20.5193 0.22854 19.9338 1.4489 20.6508 2.1657L29.1703 10.6831C29.8873 11.3999 31.1057 10.8123 30.9926 9.80428ZM25.6439 10.9952C25.1203 8.31137 23.0219 6.21294 20.3381 5.68931C19.2537 5.47775 18.551 6.79829 19.3322 7.57951L23.7537 12.0011C24.535 12.7823 25.8555 12.0796 25.6439 10.9952ZM26.8508 22.8941C26.868 22.1842 26.3749 21.6023 25.7164 21.5093C24.3367 21.3274 22.9819 20.9899 21.675 20.5022C21.1877 20.319 20.6384 20.4362 20.2736 20.7969L18.5803 22.4903C18.1559 22.9147 17.5002 23.0032 16.9784 22.7065C13.4317 20.6898 10.4951 17.7532 8.4784 14.2065C8.18172 13.6847 8.27023 13.0291 8.69465 12.6046L10.3827 10.9166C10.7487 10.5465 10.8659 9.99718 10.6816 9.50692C10.195 8.20301 9.85755 6.84824 9.67725 5.48072C9.58358 4.81803 9.01316 4.32746 8.33079 4.33411L4.33205 4.33411C3.95778 4.33447 3.60089 4.49211 3.34856 4.76853C3.09623 5.04495 2.9717 5.41469 3.00334 5.76665C3.41888 9.67972 4.75033 13.4399 6.89614 16.7524C8.83828 19.8087 11.4295 22.4 14.4963 24.3488C17.784 26.4808 21.5259 27.812 25.3975 28.2329C25.7714 28.2667 26.1422 28.1412 26.4189 27.8874C26.6955 27.6336 26.8523 27.275 26.8508 26.8941V22.8941ZM29.5174 26.8887L29.5174 22.909C29.5586 20.8864 28.0821 19.1501 26.077 18.8672C24.8847 18.7099 23.7257 18.4212 22.6102 18.0049C21.1515 17.4566 19.5036 17.808 18.3932 18.9061L17.4318 19.8674C14.9723 18.2996 12.8852 16.2126 11.3174 13.7531L12.2736 12.7969C13.3769 11.6813 13.7283 10.0334 13.1788 8.57165C12.7637 7.45917 12.475 6.30018 12.3193 5.11979C12.0366 3.11946 10.3254 1.64777 8.31763 1.66753L4.33075 1.66746C3.20669 1.66852 2.13602 2.14144 1.37903 2.97071C0.622044 3.79997 0.248446 4.90921 0.3495 6.02746C0.811316 10.3778 2.28449 14.5382 4.65171 18.1924C6.7975 21.5693 9.66889 24.4407 13.0557 26.5928C16.6829 28.9451 20.8232 30.418 25.1334 30.8863C26.2791 30.9901 27.3916 30.6139 28.2216 29.8524C29.0515 29.091 29.522 28.015 29.5174 26.8887Z"
            fill="white"
        />
        <path
            className="callButtonSvg__phone__endCall"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.20492 21.0404C2.15654 22.164 2.5841 23.256 3.38257 24.048L6.1925 26.8579C7.59159 28.3151 9.86001 28.4986 11.4755 27.2826C12.4284 26.5518 13.4506 25.9373 14.5321 25.4436C15.9493 24.8008 16.8646 23.3892 16.8734 21.8299L16.8734 20.4722C19.717 19.8426 22.6642 19.8426 25.5077 20.4722V21.8224C25.5165 23.3892 26.4319 24.8008 27.8519 25.4449C28.9305 25.9373 29.9527 26.5518 30.8961 27.2753C32.508 28.4881 34.7554 28.319 36.1591 26.8874L38.9741 24.0724C39.7671 23.278 40.1891 22.1881 40.1381 21.0681C40.0871 19.9481 39.5676 18.9011 38.7067 18.1829C35.309 15.4373 31.3313 13.5399 27.0797 12.6313C23.1803 11.762 19.1255 11.762 15.2147 12.6338C10.9926 13.534 7.0294 15.4173 3.6554 18.13C2.77316 18.8657 2.25331 19.9168 2.20492 21.0404ZM9.86471 25.1619C9.33416 25.5612 8.57511 25.4985 8.08603 24.9851L5.26175 22.1608C4.99176 21.893 4.84924 21.529 4.86537 21.1545C4.8815 20.7799 5.05478 20.4296 5.34265 20.1894C8.37342 17.753 11.9554 16.0508 15.7821 15.2348C19.3234 14.4455 22.9827 14.4455 26.512 15.2322C30.3659 16.056 33.9609 17.7708 37.0172 20.2403C37.2881 20.4665 37.4612 20.8155 37.4782 21.1888C37.4952 21.5621 37.3546 21.9254 37.0905 22.1899L34.2671 25.0133C33.79 25.4998 33.0409 25.5562 32.5069 25.1544C31.414 24.3162 30.2191 23.5979 28.9549 23.0208C28.4787 22.8048 28.1735 22.3342 28.1706 21.8144L28.1707 19.4307C28.1707 18.8313 27.7702 18.3059 27.1923 18.1469C23.2642 17.0667 19.1173 17.0667 15.1891 18.1469C14.6112 18.3059 14.2108 18.8313 14.2108 19.4307L14.2108 21.8219C14.2079 22.3342 13.9028 22.8048 13.4294 23.0195C12.1623 23.5979 10.9674 24.3162 9.86471 25.1619Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="0"
                width="122"
                height="122"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="5" />
                <feGaussianBlur stdDeviation="10" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default CallButtonSvg;
