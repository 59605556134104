import {
    action,
    computed,
    IReactionDisposer,
    observable,
    reaction,
} from 'mobx';
import { causes } from 'jssip/lib/Constants';

import CallAutomatizationHandler from '@services/call-automatization/CallAutomatizationHandler';
import { OrderService, SipService } from '@/app/services';
import { Store } from '@store/store';

import { OrderStatusesEnum } from '@api/order-api-service/models';
import { ICallHistoryEventModel } from '@models/mobx-state-tree/callHistoryEvent.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { IUserModel, UserModeEnum } from '@models/mobx-state-tree/user.model';
import { EnumSipStatus, ReasonsForEndingConversation, SipHttpErrorCode } from '../sip/models';


/**
 * Сервис выполняет следующие действия:
 *
 * **Автоматизация статусов заказа:**
 *
 * 1. В случае если во время звонка клиент сбросил,\
 * автоматически проставляется статус заказу RECALL\
 * и выводится сообщение оператору о том,\
 * что клиент сбросил звонок и был проставлен статус recall.
 * 2. Если активна настройка "Назначить статус "Недозвон" при сбросе",\
 * и если во время звонка клиент сбросил,\
 * тогда будет присвоен статус NOANSWER заказу.
 *
 * Если включен автонабор, то автоматизация статусов\
 * будет выполнена только после завершения последней попытки звонка
 *
 * **Установка причины завершения звонка**
 * 1. Проставляется asteriskHangupCause в callHistoryEvent
 * 2. Проставляется setEndCall в callHistoryEvent
 * 3. По завершении звонка отправляется информация на бэк (indicateTheEndOfTheCall)
 */
class SipErrorCauseHandler {
    constructor(
        private readonly store: Store,
        private readonly _sipService: SipService,
        private readonly _callAutomatization: CallAutomatizationHandler,
        private readonly _orderService: OrderService,
    ) {}

    private _disposeOnSipStatusChangedReaction: IReactionDisposer | undefined;

    private get _currentUser(): IUserModel {
        return this.store.currentUser;
    }

    private get _currentOrder(): ICurrentOrderModel {
        return this.store.currentOrder;
    }

    @computed
    private get callHistoryEvent(): ICallHistoryEventModel | null {
        return this._currentOrder.lastCallHistoryEvent;
    }

    public unsubscribe = (): void => {
        if (this._disposeOnSipStatusChangedReaction) {
            this._disposeOnSipStatusChangedReaction();
        }
    };

    /**
     * true, если в текущем звонке был статус LIVE
     * (разговор с клиентом)
     */
    @observable
    private _statusWasLive = false;

    @action
    public setStatusWasLive(status: boolean) {
        this._statusWasLive = status;
    }

    public subscribe = (): void => {
        this.unsubscribe();
        this._disposeOnSipStatusChangedReaction = reaction(
            () => ({
                sipStatus: this._sipService.getSipStatus(),
                sipErrorCause: this._sipService.getSipErrorCause(),
                sipErrorCauseOriginator: this._sipService.getSipErrorCauseOriginator(),
            }),
            (reactionValue) => {
                const { sipStatus, sipErrorCause: cause, sipErrorCauseOriginator: originator } = reactionValue;

                const { isThisCallLastInCallAutomatization } = this._callAutomatization;
                const { startCallAutomatic } = this._currentOrder;
                const { statusAutomatizationActive, noAnswerIfBusy } = this._currentOrder.country;

                // только для обычного режима
                if (this._currentUser.mode !== UserModeEnum.REGULAR) {
                    return;
                }

                if (sipStatus === EnumSipStatus.LIVE) {
                    this.setStatusWasLive(true);
                }

                if (!cause || !this.callHistoryEvent) {
                    return;
                }

                const { setEndCall, endCall } = this.callHistoryEvent;

                if (
                    /**
                     * Если закончился звонок (событие ended / failed JSSIP)
                     * И с клиентом не было разговора (EnumSipStatus.LIVE)
                     * ====>
                     * И если нужно выполнять автоматизацию статусов заказа, то назначить нужный статус заказу
                     */
                    (sipStatus === EnumSipStatus.ENDED && cause !== causes.CANCELED
                        || sipStatus === EnumSipStatus.FAIL)
                    && !this._statusWasLive
                ) {
                    if (startCallAutomatic ? isThisCallLastInCallAutomatization : true) {
                        /**
                         * Если автоматизация статусов активна
                         * и звонок завершен не по причине того,
                         * что автоматизировали статус в NoAnswerAfterSecHandler.
                         */
                        if (statusAutomatizationActive && endCall !== ReasonsForEndingConversation.AUTOMATION_STATUSES) {
                            setEndCall(ReasonsForEndingConversation.AUTOMATION_STATUSES);

                            if (noAnswerIfBusy) {
                                /**
                                 * если no_answer_if_busy = true - то заказу,
                                 * в случае сброса звонка
                                 * (пока что пофиг какой статус JSSIP - все завершения звонка сюда пока подходят),
                                 * выставляется не RECALL, а NOANSWER
                                 */
                                // eslint-disable-next-line no-console
                                console.info('Status automatization has been triggered by parameter: no_answer_if_busy = ', true);
                                void this._orderService.automaticallyAssignStatus(OrderStatusesEnum.NO_ANSWER);
                            } else {
                                /**
                                 * В случае если во время звонка клиент сбросил,
                                 * автоматически проставляется статус recall звонку
                                 * и выводится сообщение оператору о том,
                                 * что клиент сбросил звонок и был проставлен статус recall
                                 */
                                // eslint-disable-next-line no-console
                                console.info('Status automatization has been triggered by parameter: no_answer_if_busy = ', false);
                                void this._orderService.automaticallyAssignStatus(OrderStatusesEnum.RECALL);
                            }
                        }
                    }
                }

                if (cause === causes.NOT_FOUND) {
                    setEndCall(ReasonsForEndingConversation.SIP_ERROR);
                }

                if (cause === causes.SIP_FAILURE_CODE) {
                    const asterCause = this._sipService.getAsteriskHangupCause();

                    if (asterCause && asterCause.code === String(SipHttpErrorCode.SERVICE_UNAVAILABLE)) {
                        setEndCall(ReasonsForEndingConversation.SIP_ERROR);
                    }
                }

                if (cause === causes.USER_DENIED_MEDIA_ACCESS) {
                    setEndCall(ReasonsForEndingConversation.MEDIA_ACCESS_DENIED);
                }

                if (cause === causes.CANCELED) {
                    if (originator === 'local') {
                        setEndCall(ReasonsForEndingConversation.OPERATOR_SIDE);
                    }
                    if (originator === 'remote') {
                        setEndCall(ReasonsForEndingConversation.CLIENT_SIDE);
                    }
                }

                if (
                    (cause === causes.BUSY
                        || cause === causes.REJECTED
                        || cause === causes.BYE)
                    && originator === 'remote'
                ) {
                    setEndCall(ReasonsForEndingConversation.CLIENT_SIDE);
                }

                if (
                    sipStatus === EnumSipStatus.ENDED
                    || sipStatus === EnumSipStatus.FAIL
                    || sipStatus === EnumSipStatus.REGISTRATION_FAIL
                ) {
                    /**
                     * сбрасываем метку, что говорили с клиентом в конечном состоянии звонка
                     */
                    this.setStatusWasLive(false);
                }

                const asteriskHangupCause = this._sipService.getAsteriskHangupCause();

                if (asteriskHangupCause) {
                    this.callHistoryEvent.setHangupCause(asteriskHangupCause.code);
                }

                if (
                    sipStatus === EnumSipStatus.ENDED
                    || sipStatus === EnumSipStatus.FAIL
                ) {
                    void this._sipService.indicateTheEndOfTheCall();
                }
            },
        );
    };
}

export default SipErrorCauseHandler;
