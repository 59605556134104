import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcRecallStatusSvg {
    className?: string;
}


const IcRecallStatusSvg: FunctionComponent<IIcRecallStatusSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['IcRecallStatusSvg', className])}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.65 5.65003L18.86 1.86003C18.54 1.54003 18 1.76003 18 2.21003V4.00003H14.5C14.22 4.00003 14 4.22003 14 4.50003V7.50003C14 7.78003 14.22 8.00003 14.5 8.00003H18V9.79003C18 10.24 18.54 10.46 18.85 10.14L22.64 6.35003C22.84 6.16003 22.84 5.84003 22.65 5.65003ZM19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94003C8.90004 8.51003 9.11004 7.91003 9.04004 7.30003L8.75004 4.78003C8.63004 3.77003 7.78004 3.01003 6.76004 3.01003H5.03004C3.90004 3.01003 2.96004 3.95003 3.03004 5.08003C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
            fill="#8E8E93"
        />
    </svg>

);


export default IcRecallStatusSvg;
