import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import './InputCharacterCounter.scss';


interface IInputCharacterCounterProps {
    count: number;
    length: number;
}

const InputCharacterCounter: FC<IInputCharacterCounterProps> = ({
    count,
    length,
}) => {
    const [value, setValue] = useState(count);
    const [overflow, setOverflow] = useState(false);

    useEffect(() => {
        setValue(count);
        setOverflow(length ? count > length : false);
    }, [count]);

    return (
        <div
            className={cn('InputCharacterCounter', overflow && 'overflow')}
        >
            {`${value} / ${length}`}
        </div>
    );
};

export default InputCharacterCounter;
