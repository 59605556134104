import { IProductItemModel } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import { IPartOfCatalogItemForPromoInCatalog } from './models';

/**
 * Метод возвращает нужные для отображения в компоненте атрибуты в соответствии с интерфейсом
 * Не передаем саму модель, т.к. в сторибуке должна быть возможность вывести компонент, не создавая модель mobx
 * @param item - модель товара в каталоге
 */
export const prepareCatalogItemForView = (item: IProductItemModel): IPartOfCatalogItemForPromoInCatalog => ({
    name: item.name,
    image: item.image,
    type: item.type,
    subType: item.subType,
    price: item.price,
});
