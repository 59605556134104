import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { AttrsKey } from '@interfaces/form.interface';

export const KladrResultsForAllForms = t
    .model('KladrResultsForAllForms', {
        [AttrsKey.CUSTOMER_DISTRICT]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_STATE]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_CITY]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_REGION]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_STREET]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_PROVINCE]: t.maybeNull(t.string),
        [AttrsKey.CUSTOMER_ZIP]: t.maybeNull(t.string),
    });


export interface KladrResultsForAllFormsModel extends Instance<typeof KladrResultsForAllForms> {}
export interface KladrResultsForAllFormsModelSnapshotIn extends SnapshotIn<typeof KladrResultsForAllForms> {}
export interface KladrResultsForAllFormsModelSnapshotOut extends SnapshotOut<typeof KladrResultsForAllForms> {}