import { OrderService, SipService } from '@services/index';
import CallAutomatizationHandler from '@services/call-automatization/CallAutomatizationHandler';
import SipErrorCauseHandler from '@services/orderStatusAutomatization/SipErrorCauseHandler';
import { Store } from '@store/store';
import { toFactory } from 'react-ioc';


const SipErrorCauseHandlerDI = (): any => [
    SipErrorCauseHandler,
    toFactory(
        [
            SipService,
            Store,
            CallAutomatizationHandler,
            OrderService,
        ],
        (
            sipService: SipService,
            store: Store,
            callAutomotization: CallAutomatizationHandler,
            orderService: OrderService,
        ) => new SipErrorCauseHandler(
            store,
            sipService,
            callAutomotization,
            orderService,
        ),
    ),
];


export default SipErrorCauseHandlerDI;
