import filter from 'lodash/filter';
import each from 'lodash/each';
import concat from 'lodash/concat';
import { computed } from 'mobx';
import some from 'lodash/some';
import reduce from 'lodash/reduce';
import map from 'lodash/map';

import { Store } from '@store/store';
import { OrderService } from '@services/index';
import OldPromotionService from '@services/order/OldPromotionService';
import { IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import ProductsService from '@services/order/ProductsService';
import { AddModeEnum } from '@services/order/OrderService';
import BasketService from '@services/order/BasketService';
import { IPromotionBuilderModel } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';
import PromotionBuilderService from '@services/order/PromotionBuilderService';

export interface IDispatchingBonusItems {
    productItemFlag: boolean;
    promotionBuilderFlag: boolean;
    productItem?: IProductItemModel;
    promotionBuilderItem?: IPromotionBuilderModel;
}
/**
 * Сервис, который содержит методы и вычисляемые свойства для работы с блоком офферов.
 */
class OffersService {
    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    /**
     * Подарки в формате для компонента офферов
     */
    private get _giftItemsInCatalogPrepared(): IDispatchingBonusItems[] {
        return map<IProductItemModel, IDispatchingBonusItems>(
            this._currentOrder.giftItemsInCatalog,
            (item) => ({
                productItemFlag: true,
                productItem: item,
                promotionBuilderFlag: false,
            }),
        );
    }

    /**
     * Подарки в формате для компонента офферов при примененном К.А. в корзину
     */
    private get _giftItemsInCatalogPromotionBuilderActivePrepared(): IDispatchingBonusItems[] {
        return map<IProductItemModel, IDispatchingBonusItems>(
            this._currentOrder.appliedPromotionBuilder?.presentOptionalGifts,
            (item) => ({
                productItemFlag: true,
                productItem: item,
                promotionBuilderFlag: false,
            }),
        );
    }

    /**
     * Подарки в формате для компонента офферов
     */
    private get _promoItemsInCatalogPrepared(): IDispatchingBonusItems[] {
        return map<IProductItemModel, IDispatchingBonusItems>(
            this._currentOrder.promoItemsInCatalog,
            (item) => ({
                productItemFlag: true,
                productItem: item,
                promotionBuilderFlag: false,
            }),
        );
    }

    /**
     * Промо в формате для компонента офферов при примененном К.А. в корзину
     */
    private get _promoItemsInCatalogPromotionBuilderActivePrepared(): IDispatchingBonusItems[] {
        return reduce<IProductItemModel, IDispatchingBonusItems[]>(
            this._currentOrder.appliedPromotionBuilder?._products,
            (acc, item) => {
                if (
                    item.type === ProductItemTypeEnum.REGULAR
                    && item.subType === ProductItemSubTypeEnum.PROMO
                ) {
                    acc.push({
                        productItemFlag: true,
                        productItem: item,
                        promotionBuilderFlag: false,
                    });
                }

                return acc;
            },
            [],
        );
    }

    /**
     * все бонусные товары из каталога
     */
    @computed
    public get allBonusItemsInCatalog(): IDispatchingBonusItems[] {
        const {
            form: { allowGifts },
        } = this._currentOrder;

        if (!allowGifts) {
            return concat<IDispatchingBonusItems>(
                this._promotionBuilderService.availablePromotionBuildersForMainItemsInBasket,
                this._oldPromotionsService.availablePromotionsForMainItemsInBasket,
                this._promoItemsInCatalogPrepared,
            );
        }

        return concat<IDispatchingBonusItems>(
            this._promotionBuilderService.availablePromotionBuildersForMainItemsInBasket,
            this._giftItemsInCatalogPrepared,
            this._oldPromotionsService.availablePromotionsForMainItemsInBasket,
            this._promoItemsInCatalogPrepared,
        );
    }

    /**
     * все бонусные товары из каталога для К.А.
     */
    @computed
    public get allBonusItemsInCatalogForPromotionBuilder(): IDispatchingBonusItems[] {
        return concat<IDispatchingBonusItems>(
            this._giftItemsInCatalogPromotionBuilderActivePrepared,
            this._promoItemsInCatalogPromotionBuilderActivePrepared,
        );
    }

    @computed
    get dispatchingBonusGoodsForAppliedPromotionBuilder(): IDispatchingBonusItems[] {
        const {
            offersBlockTabs,
        } = this._currentOrder;

        const { activeTab, isSearchModeOn, searchQueryForAFilter } = offersBlockTabs;

        if (isSearchModeOn) {
            if (searchQueryForAFilter) {
                return this.allBonusItemsInCatalogForPromotionBuilder.filter((item) => {
                    // должны быть только ProductItem!
                    const [names, partnerProductIds] = item.productItem!.dataOfPromotionInclucingProductNameAndPartnerProductIds();

                    return some(names,
                        (nameInfo: string) => nameInfo.toLowerCase()
                            .includes(searchQueryForAFilter.toLowerCase()))
                        || some(partnerProductIds,
                            (partnerProductId: string) => partnerProductId.toLowerCase()
                                .includes(searchQueryForAFilter.toLowerCase()));
                });
            }

            return this.allBonusItemsInCatalogForPromotionBuilder;
        }

        switch (activeTab) {
            case AddModeEnum.gift: {
                return this._giftItemsInCatalogPromotionBuilderActivePrepared;
            }

            case AddModeEnum.promo: {
                return this._promoItemsInCatalogPromotionBuilderActivePrepared;
            }

            case AddModeEnum.PROMOTIONS: {
                return concat<IDispatchingBonusItems>(
                    this._promotionBuilderService.availablePromotionBuildersForMainItemsInBasket,
                    this._oldPromotionsService.availablePromotionsForMainItemsInBasket,
                );
            }

            case AddModeEnum.ALL: {
                return this.allBonusItemsInCatalogForPromotionBuilder;
            }

            default: {
                return [];
            }
        }
    }

    @computed
    get dispatchingBonusGoods(): IDispatchingBonusItems[] {
        const {
            offersBlockTabs,
        } = this._currentOrder;

        const { activeTab, isSearchModeOn, searchQueryForAFilter } = offersBlockTabs;

        if (isSearchModeOn) {
            if (searchQueryForAFilter) {
                return this.allBonusItemsInCatalog.filter((item) => {
                    if (item.productItemFlag) {
                        const [names, partnerProductIds] = item.productItem!.dataOfPromotionInclucingProductNameAndPartnerProductIds();

                        return some(names,
                            (nameInfo: string) => nameInfo.toLowerCase()
                                .includes(searchQueryForAFilter.toLowerCase()))
                            || some(partnerProductIds,
                                (partnerProductId: string) => partnerProductId.toLowerCase()
                                    .includes(searchQueryForAFilter.toLowerCase()));
                    }

                    const promotionBuilderProducts = item.promotionBuilderItem!._products;
                    const promotionBuilder = item.promotionBuilderItem!;

                    return some<IProductItemModel>(
                        promotionBuilderProducts,
                        (item) => item.name.toLowerCase().includes(searchQueryForAFilter.toLowerCase())
                        || item.partnerProductId.includes(searchQueryForAFilter.toLowerCase()),
                    ) || promotionBuilder.name.toLowerCase().includes(searchQueryForAFilter.toLowerCase());
                });
            }

            return this.allBonusItemsInCatalog;
        }

        switch (activeTab) {
            case AddModeEnum.gift: {
                return this._giftItemsInCatalogPrepared;
            }

            case AddModeEnum.promo: {
                return this._promoItemsInCatalogPrepared;
            }

            case AddModeEnum.PROMOTIONS: {
                return concat<IDispatchingBonusItems>(
                    this._promotionBuilderService.availablePromotionBuildersForMainItemsInBasket,
                    this._oldPromotionsService.availablePromotionsForMainItemsInBasket,
                );
            }

            case AddModeEnum.ALL: {
                return this.allBonusItemsInCatalog;
            }

            default: {
                return [];
            }
        }
    }

    /**
     * Для компонента Chips\
     * Считаем количество доступных старых акций и конструкторов акций в каталоге
     */
    @computed
    public get countAllAvailableOldPromoBonusItemsInCatalog(): number {
        let count = 0;

        const oldPromoBonusItemsInCatalog = filter(
            this.allBonusItemsInCatalog,
            (item) => {
                if (item.productItemFlag) {
                    return item.productItem!.subType === ProductItemSubTypeEnum.OLD_PROMOTION;
                }

                return false;
            },
        );

        const promotionBuildersInCatalog = filter(
            this.allBonusItemsInCatalog,
            (item) => !!item.promotionBuilderFlag,
        );

        each(oldPromoBonusItemsInCatalog, (item) => {
            if (this.isBonusItemInCatalogAvailable(item.productItem!)) {
                count++;
            }
        });

        each(promotionBuildersInCatalog, () => {
            // TODO: тут нужно будет проверять доступен ли К.А. для применения
            count++;
        });

        return count;
    }

    /**
     * Для компонента Chips\
     * Считаем количество доступных подарков в каталоге
     */
    @computed
    public get countAllAvailableGiftBonusItemsInCatalog(): number {
        let count = 0;

        const oldPromoBonusItemsInCatalog = filter(
            this.allBonusItemsInCatalog,
            (item) => {
                if (item.productItemFlag) {
                    return item.productItem!.subType === ProductItemSubTypeEnum.GIFT;
                }

                return false;
            },
        );

        each(oldPromoBonusItemsInCatalog, (item) => {
            if (this.isBonusItemInCatalogAvailable(item.productItem!)) {
                count++;
            }
        });

        return count;
    }

    /**
     * Для компонента Chips\
     * Считаем количество доступных простых промо в каталоге
     */
    @computed
    public get countAllAvailablePromoBonusItemsInCatalog(): number {
        let count = 0;

        const oldPromoBonusItemsInCatalog = filter(
            this.allBonusItemsInCatalog,
            (item) => {
                if (item.productItemFlag) {
                    return item.productItem!.subType === ProductItemSubTypeEnum.PROMO;
                }

                return false;
            },
        );

        each(oldPromoBonusItemsInCatalog, (item) => {
            if (this.isBonusItemInCatalogAvailable(item.productItem!)) {
                count++;
            }
        });

        return count;
    }

    @computed
    public get countAllAvailableBonusItemsInCatalog(): number {
        if (this._currentOrder.additionalParamsCurrentOrder.activePromotionBuilderModelId) {
            return this.countAllAvailableGiftBonusItemsForPromotionBuilder
            + this.countAllAvailablePromosBonusItemsForPromotionBuilder;
        }

        return this.countAllAvailableOldPromoBonusItemsInCatalog
            + this.countAllAvailableGiftBonusItemsInCatalog
            + this.countAllAvailablePromoBonusItemsInCatalog;
    }

    /**
     * Для компонента Chips\
     * Считаем количество доступных подарков в блоке офферов для КА
     */
    @computed
    public get countAllAvailableGiftBonusItemsForPromotionBuilder(): number {
        let count = 0;

        const gifts = filter(
            this._giftItemsInCatalogPromotionBuilderActivePrepared,
            (item) => {
                if (item.productItemFlag) {
                    return item.productItem!.subType === ProductItemSubTypeEnum.GIFT;
                }

                return false;
            },
        );

        each(gifts, (item) => {
            if (this._productsService.abilityToAddAnOptionalGiftToPromotionBuilderBasket(item.productItem!)) {
                count++;
            }
        });

        return count;
    }

    /**
     * Для компонента Chips\
     * Считаем количество доступных промо в блоке офферов для КА
     */
    @computed
    public get countAllAvailablePromosBonusItemsForPromotionBuilder(): number {
        let count = 0;

        each(this._promoItemsInCatalogPromotionBuilderActivePrepared, (item) => {
            if (!this._basketService.isThisSimplePromoExistsInBasketPromotionBuilder(item.productItem!.partnerProductId)
                && !(this._currentOrder.promoItemsBasketPromotionBuilder.length > 0)
                && this._productsService.isConditionForPromoCartsPromotionBuilder
            ) {
                count++;
            }
        });

        return count;
    }

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _oldPromotionsService: OldPromotionService,
        private readonly _productsService: ProductsService,
        private readonly _basketService: BasketService,
        private readonly _promotionBuilderService: PromotionBuilderService,
    ) {
    }

    public isBonusItemInCatalogAvailable = (bonusItem: IProductItemModel): boolean => {
        switch (bonusItem.subType) {
            case ProductItemSubTypeEnum.OLD_PROMOTION:
                return !this._oldPromotionsService.checkAppliedPromotionsForOffers(bonusItem);
            case ProductItemSubTypeEnum.GIFT:
            case ProductItemSubTypeEnum.MAIN:
                return this._productsService.isPossibleToAddToBasket(bonusItem);
            case ProductItemSubTypeEnum.PROMO:
                return (!this._basketService.isThisSimplePromoExistsInBasket(bonusItem.partnerProductId)
                    && !this._productsService.hasPromoItemsInCart
                    && this._productsService.isConditionForPromoCarts);
            default:
                throw new Error('Unknown subType');
        }
    };

    public isBonusItemForPromotionBuilderAvailable = (bonusItem: IProductItemModel): boolean => {
        switch (bonusItem.subType) {
            case ProductItemSubTypeEnum.GIFT:
                return this._productsService.abilityToAddAnOptionalGiftToPromotionBuilderBasket(bonusItem);
            case ProductItemSubTypeEnum.PROMO:
                // TODO: проверка доступности PROMO
                return false;
            default:
                throw new Error('Unknown subType');
        }
    };
}


export default OffersService;
