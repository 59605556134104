import { CustomerService } from '@/app/services';
import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useInstance } from 'react-ioc';

export const useCreateClientCard = (): readonly [((event: ChangeEvent<HTMLInputElement>) => void), string, ((value: (((prevState: string) => string) | string)) => void), boolean, (() => void), ((value: (((prevState: (string | null)) => (string | null)) | string | null)) => void)] => {
    const customerService = useInstance(CustomerService);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string | null>(null);
    const [isFormReadyToBeSent, setFromReady] = useState<boolean>(false);

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);

    useEffect(() => {
        if (name.length >= 2 && phone) {
            setFromReady(true); // если телефон заполнен и поле "Имя" содержит хотя бы 2 символа
        } else {
            setFromReady(false);
        }
    }, [name]);

    const createClientCard = useCallback(() => {
        if (isFormReadyToBeSent && phone) {
            void customerService.createClientCard(phone, name);
        }
    },
    [phone, name, isFormReadyToBeSent]);

    return [onChangeName, name, setName, isFormReadyToBeSent, createClientCard, setPhone] as const;
};
