import { ComparisonOperatorsOfDeliveryEnum } from '@/app/models/mobx-state-tree/newModels/promotionBuilderDeliveryConditions.model';
import { ConditionsForFreeShipping } from '@interfaces/form.interface';


class BonusOrderConditionsBase {
    public switchConditionForAFreeShipping(
        whatWeWantToCompare: number,
        condition: ConditionsForFreeShipping | null,
        quantityToCompareWith: number,
    ): boolean {
        switch (condition) {
            case ConditionsForFreeShipping.LESS: return whatWeWantToCompare < quantityToCompareWith;
            case ConditionsForFreeShipping.LESS_OR_EQUAL: return whatWeWantToCompare <= quantityToCompareWith;
            case ConditionsForFreeShipping.MORE: return whatWeWantToCompare > quantityToCompareWith;
            case ConditionsForFreeShipping.MORE_OR_EQUAL: return whatWeWantToCompare >= quantityToCompareWith;
            case ConditionsForFreeShipping.EQUALLY: return whatWeWantToCompare === quantityToCompareWith;
            case ConditionsForFreeShipping.NOT_EQUAL: return whatWeWantToCompare !== quantityToCompareWith;
            default: return false;
        }
    }

    public switchConditionForAShippingPromotionBuilder(
        whatWeWantToCompare: number,
        condition: ComparisonOperatorsOfDeliveryEnum,
        quantityToCompareWith: number,
    ): boolean {
        switch (condition) {
            case ComparisonOperatorsOfDeliveryEnum.MORE: return whatWeWantToCompare > quantityToCompareWith;
            case ComparisonOperatorsOfDeliveryEnum.MORE_OR_EQUAL: return whatWeWantToCompare >= quantityToCompareWith;
            case ComparisonOperatorsOfDeliveryEnum.EQUAL: return whatWeWantToCompare === quantityToCompareWith;
            default: return false;
        }
    }
}


export default BonusOrderConditionsBase;
