import { action, observable } from 'mobx';
import { v4 } from 'uuid';
import moment from 'moment';

import OperatorStatisticApiService from '@api/operator-statistic-api-service';
import UserService from '@services/UserService';
import { IOperatorStatisticModelSnapshotIn } from '@models/mobx-state-tree/operatorStatistic.model';
import { IOperatorStatistic } from '@interfaces/operatorStatistic.interface';
import { Store } from '@store/store';
import { BillingListStore } from '@store/billingListStore';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';

export enum FetchStatusEnum {
    NONE = 1,
    PENDING = 2,
    SUCCESS = 3,
    FAIL = 4,
}

class BillingListService {
    constructor(
        private readonly _operatorStatisticApiService: OperatorStatisticApiService,
        private readonly _userService: UserService,
        private readonly _store: Store,
        private readonly _billingListStore: BillingListStore,
    ) {
    }

    @observable
    public fetchStatus: FetchStatusEnum = FetchStatusEnum.NONE;

    @action
    private _setFetchStatus = (fetchStatus: FetchStatusEnum): void => {
        this.fetchStatus = fetchStatus;
    };

    private static prepareStatistics(data: IOperatorStatistic[]): Record<string, IOperatorStatisticModelSnapshotIn> {
        return convertArrayToCollection<IOperatorStatisticModelSnapshotIn>(
            (data || []).map((operatorStatistic: IOperatorStatistic): IOperatorStatisticModelSnapshotIn => ({
                id: v4(),
                date: operatorStatistic.date,
                onlineTime: operatorStatistic.online_time,
                ordersCount: operatorStatistic.orders_count,
                avgCheckUSD: operatorStatistic.avg_check_usd,
                callsCount: operatorStatistic.calls_count,
                approvedCount: operatorStatistic.approved_count,
            })),
        );
    }

    public fetchOperatorStatisticList = async (dateFrom: string): Promise<void | never> => {
        try {
            this._setFetchStatus(FetchStatusEnum.PENDING);
            const timezone = await this._userService.getTimezoneName();
            const dateTo = moment().format('YYYY-MM-DD'); // Конечная дата в формате YYYY-MM-DD ('2021-01-02')
            const { currentUser } = this._store;
            const { id } = currentUser;
            const result = await this._operatorStatisticApiService.getOperatorStatistic(dateFrom, dateTo, id, timezone);

            const operatorStatisticIn = BillingListService.prepareStatistics(result);
            if (operatorStatisticIn) {
                this._billingListStore.setBillingList(operatorStatisticIn);
            }
            this._setFetchStatus(FetchStatusEnum.SUCCESS);
        } catch (error) {
            this._setFetchStatus(FetchStatusEnum.FAIL);
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };
}

export default BillingListService;
