import { action } from 'mobx';
import get from 'lodash/get';

import AuthApiService from '@api/auth-api-service';
import { IUserResponse, ICallCenter } from '@api/auth-api-service/models';
import { Store } from '@store/store';
import CookieService from '@core/services/CookiesService';
import UserService from '@services/UserService';


class AuthService {
    constructor(
        private readonly store: Store,
        private readonly cookieService: CookieService,
        private readonly userService: UserService,
        private readonly authApiService: AuthApiService,
    ) {
    }

    @action
    async auth(username: string, password: string): Promise<void | never> {
        try {
            const data = await this.authApiService.auth(username, password);

            const rawUser: IUserResponse = get(data, 'user');
            const rawCallCenter: ICallCenter | null = data.call_center;

            const mappedUser = this.userService.mapUserLoginApiResult(rawUser, rawCallCenter);

            if (mappedUser.id) {
                this.userService.identifyUserInLogRocket(
                    mappedUser.id,
                    mappedUser.first_name,
                    mappedUser.last_name,
                    mappedUser.username,
                );
                this.store.currentUser.setCustomerUser(rawUser.customer_service);

                this.cookieService.setAccessToken(mappedUser.token, mappedUser.expired);
                this.cookieService.setTokenExpireAt(mappedUser.expired);

                this.store.setShouldGoToReadyIfNoNotificationsWithFeedback(true);
            }
        } catch (e) {
            throw e instanceof Error ? e.message : e;
        }
    }
}


export default AuthService;
