import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import map from 'lodash/map';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import RestoreIcon from '@components/resources/SVG/Restore/RestoreIcon';
import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import OrderTableQuantityInStock
    from '@components/main/order-page/order-products/order-table/OrderTableQuantityInStock';
import Counter from '@UIElements/Counter';
import SwapItemButton from '@components/main/order-page/order-products/order-table/includes/SwapItemButton';
import { ReplaceTransactionTypeEnum } from '@models/mobx-state-tree/newModels/ReplaceTransactionModel.model';
import I18NService from '@services/I18NService';
import { IBasketItemModel } from '@models/mobx-state-tree/newModels/BasketItem.model';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import { OrderService } from '@/app/services';


type TDropDownListWithContentsOfSetProps = {
    readonly basketItem: IBasketItemModel;
    readonly isShownForPreview: boolean;
};


const DropDownListWithContentsOfSet: FunctionComponent<TDropDownListWithContentsOfSetProps> = ({
    basketItem,
    isShownForPreview,
}) => {
    const [
        {
            currentOrder: {
                prepareLabelTranslation,
            },
        },
        { t },
    ] = useInstances(OrderService, I18NService);

    return (
        <>
            {map<ISetProductComposition, JSX.Element>(
                basketItem.getSetProductCompositions() || [],
                (product) => (
                    <div
                        className="currentProducts__table-row composition-set"
                        key={`${product.id}-${product.getSelectedProductItem.id}`}
                        data-cy="set_nomenclature"
                    >
                        <div
                            className="currentProducts__table-col currentProducts__table-col-first currentProducts__table-col-4"
                        >
                            {!product.isDefaultProductSelected && (
                                <div
                                    className="currentProducts__table-restore-active"
                                    title={t('Восстановить', 'Restore')}
                                    onClick={product.restoreOriginalProduct}
                                    data-cy="restore_set_item"
                                >
                                    <RestoreIcon />
                                </div>
                            )}
                            {product.isDefaultProductSelected && (<div className="currentProducts__table-restore" />)}

                            <div className="currentProducts__table-avatar">
                                <ProductImage
                                    className="currentProducts__table-avatar-image"
                                    productName={product.getSelectedProductItem.name}
                                    imageLink={product.getSelectedProductItem.image}
                                    productType={product.getSelectedProductItem.type}
                                />
                            </div>
                            {basketItem.productItem.type !== ProductItemTypeEnum.STATIC_SET && (
                                <OrderTableQuantityInStock
                                    item={basketItem.productItem.type === ProductItemTypeEnum.DYNAMIC_SET && product.getSelectedProductItem || undefined}
                                    quantityInStock={product.getSelectedProductItem.quantityInStock}
                                />
                            )}
                            <div
                                className="currentProducts__table-product--name"
                                title={product.getSelectedProductItem.name}
                                data-cy="product-name"
                            >
                                {product.getSelectedProductItem.name}
                            </div>
                            <span
                                className={cn(
                                    'currentProducts__table-text-label--webkit',
                                )}
                            >
                                {/* В составе набора по дизайну могут быть только обычные товары (да и по логике тоже так) */}
                                <ProductLabel
                                    productType={ProductItemTypeEnum.REGULAR}
                                    productSubType={ProductItemSubTypeEnum.MAIN}
                                    isPromotion={false}
                                    text={prepareLabelTranslation(
                                        ProductItemTypeEnum.REGULAR,
                                        ProductItemSubTypeEnum.MAIN,
                                        false,
                                    )}
                                />
                            </span>
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-2"
                        >
                            <Counter
                                value={String(product.getSelectedProductItem.quantityInSet)}
                            />
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                        >
                            <div className="currentProducts__table-text">
                                {product.getSelectedProductItem.price}
                            </div>
                        </div>
                        <div
                            className="currentProducts__table-col currentProducts__table-col-last currentProducts__table-col-2"
                        >
                            <div className="currentProducts__actions">
                                {product.getAllAlternatives.length > 0 && !isShownForPreview && (
                                    <SwapItemButton id={product.id} parentProductId={basketItem.id} type={ReplaceTransactionTypeEnum.SET_ITEM} />
                                )}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </>
    );
};


export default observer(DropDownListWithContentsOfSet);
