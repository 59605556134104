import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IPhoneSVGProps {
    className?: string;
    onClick?: () => void;
}


const PhoneSVG: FunctionComponent<IPhoneSVGProps> = ({ className, onClick, ...props }): JSX.Element => (
    <svg
        className={cn(['PhoneSVG', className])}
        onClick={onClick}
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.2217 15.7681L16.6817 15.4781C16.0717 15.4081 15.4717 15.6181 15.0417 16.0481L13.2017 17.8881C10.3717 16.4481 8.05174 14.1381 6.61174 11.2981L8.46174 9.44807C8.89174 9.01807 9.10174 8.41807 9.03174 7.80807L8.74174 5.28807C8.62174 4.27807 7.77174 3.51807 6.75174 3.51807H5.02174C3.89174 3.51807 2.95174 4.45807 3.02174 5.58807C3.55174 14.1281 10.3817 20.9481 18.9117 21.4781C20.0417 21.5481 20.9817 20.6081 20.9817 19.4781V17.7481C20.9917 16.7381 20.2317 15.8881 19.2217 15.7681Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>
);


export default PhoneSVG;
