import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { TypesOfConditionsForPromoCarts } from '@interfaces/form.interface';

const additionalTypeOptions = [
    TypesOfConditionsForPromoCarts.PRODUCT_PRICE,
    TypesOfConditionsForPromoCarts.PRODUCT_QUANTITY,
];


export const ConditionForFreeShipping = t
    .model('ConditionForFreeShipping', {
        id: t.identifier,
        additionalType: t.enumeration(additionalTypeOptions),
        condition: t.maybeNull(t.string),
        quantity: t.maybeNull(t.integer), // либо цена, либо количество
        usePromo: t.optional(t.boolean, false),
        useGift: t.optional(t.boolean, false),
    });


export interface IConditionForFreeShippingModel extends Instance<typeof ConditionForFreeShipping> {}
export interface IConditionForFreeShippingModelSnapshotIn extends SnapshotIn<typeof ConditionForFreeShipping> {}
export interface IConditionForFreeShippingModelSnapshotOut extends SnapshotOut<typeof ConditionForFreeShipping> {}
