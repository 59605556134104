import React from 'react';
import cn from 'classnames';

interface IProps {
    className?: string;
}

const CallButtonInactiveSvg = ({ className, ...props }: IProps): JSX.Element => (
    <svg
        className={cn(['CallButtonInactiveSvg', className])}
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="28" cy="28" r="27.5" fill="#F8F8F8" stroke="#D4D4D8" />
        <path
            d="M36.4346 31.8034L33.4712 31.4651C32.7596 31.3834 32.0596 31.6284 31.5579 32.1301L29.4112 34.2767C26.1096 32.5967 23.4029 29.9017 21.7229 26.5884L23.8812 24.4301C24.3829 23.9284 24.6279 23.2284 24.5462 22.5167L24.2079 19.5767C24.0679 18.3984 23.0762 17.5117 21.8862 17.5117H19.8679C18.5496 17.5117 17.4529 18.6084 17.5346 19.9267C18.1529 29.8901 26.1212 37.8467 36.0729 38.465C37.3912 38.5467 38.4879 37.4501 38.4879 36.1317V34.1134C38.4996 32.9351 37.6129 31.9434 36.4346 31.8034Z"
            fill="#8E8E93"
        />
    </svg>
);

export default CallButtonInactiveSvg;
