import React, { PureComponent } from 'react';
import cn from 'classnames';

import IcCalendar from './includes/IcCalendar';

import './includes/DatepickerInput.scss';


class DatepickerInput extends PureComponent<{ value?: string; onClick?: () => void; placeholderText?: string }> {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    render() {
        const {
            value, onClick, placeholderText,
        } = this.props;

        return (
            <div
                className={cn([
                    'shipping-datepicker__wrapper',
                    value && 'has-value',
                ])}
                onClick={onClick}
            >
                <div className="shipping-datepicker__content">
                    <IcCalendar
                        className={cn([
                            'calendarIcon',
                        ])}
                    />
                    <span>
                        {value || placeholderText}
                    </span>
                </div>
            </div>
        );
    }
}


export default DatepickerInput;
