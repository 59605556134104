import { AxiosError } from 'axios';
import qs from 'qs';
import { inject } from 'react-ioc';
import isFinite from 'lodash/isFinite';
import isNaN from 'lodash/isNaN';

import { AXIOS_NETWORK_ERROR } from '@core/constants/req-errors';
import AxiosBase  from '@core/axios-base';
import NoAccessTokenInRequestHeaderError from '@core/error/NoAccessTokenInRequestHeaderError';
import OfflineError from '@core/error/OfflineError';
import XHR401Error from '@core/error/XHR401Error';
import { AxiosRequestConfigCustom } from './models';
import CookieService from '@core/services/CookiesService';
import ModalService from '@core/services/ModalService';
import ErrorService from '@core/services/ErrorService';
import { reaction } from 'mobx';
import { getEnvRefreshTokenEndpoint } from '@/env/requestEndpoints';
import { IRefteshTokenData } from '@api/auth-api-service/models';
import NoInternetModal from '@UIElements/NoInternetModal';
import { NO_INTERNET_CONNECTION } from '@core/constants/noInternetConnection';

const DEFAULT_TOKEN_LIFE_TIME = 60000;

const DELAY_INTERVAL = 1000;


class ApiBase {
    @inject cookieService: CookieService;

    @inject errorService: ErrorService;

    @inject modalService: ModalService;

    private _delayAccessToMethodsTimeout: number | null = null;

    refreshTokenLoading: boolean;

    constructor() {
        if (this.cookieService.accessTokenValue.length) {
            AxiosBase.initToken(this.cookieService.accessTokenValue);
        }

        reaction<string>(
            () => this.cookieService.accessTokenValue,
            AxiosBase.initToken,
        );

        this.refreshTokenLoading = false;
    }

    private static _paramsSerializer: (params: any) => string = (params) => qs.stringify(params);

    private static _createError(error: string | Error): Error {
        if (typeof error === 'string') {
            return new Error(error);
        }

        return error;
    }

    private static _getTokenLifeTime: () => number = () => {
        const tokenLifeTimeEnvValue = process.env.REACT_APP_TOKEN_LIFE_TIME;

        if (!tokenLifeTimeEnvValue) {
            return DEFAULT_TOKEN_LIFE_TIME;
        }

        const parsedInt = parseInt(tokenLifeTimeEnvValue, 10);

        if (parsedInt > 0 && isFinite(parsedInt)) {
            return parsedInt;
        }

        return DEFAULT_TOKEN_LIFE_TIME;
    };

    private static _isPassHalfOfTokenLife = (expiredIn: number): boolean => {
        const tokenLifeTime = ApiBase._getTokenLifeTime();

        if (isNaN(expiredIn) || isNaN(tokenLifeTime)) {
            return false;
        }

        const now = new Date();
        const currentTimeStamp = Math.ceil(now.getTime() / 1000);

        return (expiredIn - currentTimeStamp) <= (tokenLifeTime / 2);
    };

    private async _refreshToken(): Promise<{ readonly token: string; readonly expired: number } | never> {
        const res = await this.post<{ status: 'success' | 'error'; data: IRefteshTokenData; message?: string }>(
            getEnvRefreshTokenEndpoint(),
            {},
            true,
        );

        const { status, data: { token, expired }, message = 'Unknown authentication error.' } = res;

        if (status !== 'success') {
            throw new Error(message);
        }

        return { token, expired };
    }

    public async updateTokenData(): Promise<void> {
        try {
            const { token, expired } = await this._refreshToken();

            this.cookieService.setAccessToken(token, expired);
            this.cookieService.setTokenExpireAt(expired);

            AxiosBase.initToken(token);
        } catch {
            this.cookieService.logout();
        }
    }

    private startDelayTimeout = (): Promise<void> => new Promise(((resolve): void => {
        this._delayAccessToMethodsTimeout = setTimeout(resolve, DELAY_INTERVAL);
    }));

    private stopDelayTimeout = (): void => {
        if (this._delayAccessToMethodsTimeout) {
            clearTimeout(this._delayAccessToMethodsTimeout);
        }
    };

    private async _requestWith<T>(_endpoint: AxiosRequestConfigCustom): Promise<T | never> {
        try {
            // const passHalf = ApiBase._isPassHalfOfTokenLife(this.cookieService.tokenExpireAtValue);
            //
            // if (!_endpoint.skipTokenRefresh && passHalf) {
            //     await this._updateTokenData();
            // }

            if (this.refreshTokenLoading && !_endpoint.skipTokenRefresh) {
                await this.startDelayTimeout();
                await this._requestWith(_endpoint);
            } else {
                this.stopDelayTimeout();
            }

            const { data } = await AxiosBase.fetch<T>(_endpoint);

            return data as T;
        } catch (error) {
            throw this._ensureResponse<T>(error);
        }
    }

    private _ensureResponse<T extends { message?: string }>(error: AxiosError<T> | NoAccessTokenInRequestHeaderError): never | void {
        if (error instanceof NoAccessTokenInRequestHeaderError) {
            console.warn(error.message);

            return;
        }

        if (error.message === AXIOS_NETWORK_ERROR) {
            const { message } = this.errorService.toReturnAFabricatedError(OfflineError);

            localStorage.setItem(NO_INTERNET_CONNECTION, String(new Date()));

            void this.modalService.showModal(
                NoInternetModal,
                undefined,
                true);

            console.warn(message);

            return;
        }

        if (error.response) {
            const {
                data, statusText, status, config,
            } = error.response;

            if (status >= 400) {
                const errorMessage = data?.message || statusText;

                if (status === 401) {
                    this.cookieService.logout();

                    const e: Error = this.errorService.toReturnAFabricatedError(XHR401Error);

                    throw ApiBase._createError(e);
                }

                if (status === 400) {
                    const e = this.errorService.XHR400Error(errorMessage);

                    throw ApiBase._createError(e);
                }

                if (status === 404) {
                    const e = this.errorService.XHR404Error(errorMessage);

                    throw ApiBase._createError(e);
                }

                throw ApiBase._createError(errorMessage);
            }

            if (data === null || data === undefined) {
                throw ApiBase._createError(`Response is empty. Url: ${config.url}`);
            }
        }

        throw ApiBase._createError(`Error.Response is empty. ${error}`);
    }

    async post<T>(url: string, data: Record<string, any>, skipTokenRefresh = false, noTokenRequired = false): Promise<T> {
        return this._requestWith({
            method: 'POST',
            url,
            data,
            skipTokenRefresh,
            noTokenRequired,
        });
    }

    async get<T>(url: string, params?: Record<string, any>, skipTokenRefresh = false, noTokenRequired = false): Promise<T> {
        return this._requestWith({
            method: 'GET',
            url,
            params,
            paramsSerializer: ApiBase._paramsSerializer,
            skipTokenRefresh,
            noTokenRequired,
        });
    }

    async customRequest<T>(endpoint: AxiosRequestConfigCustom): Promise<T> {
        try {
            const res = await AxiosBase.fetch<T>(endpoint);

            return res.data as T;
        } catch (e) {
            throw this._ensureResponse<T>(e);
        }
    }
}


export default ApiBase;
