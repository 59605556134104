import {
    SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { useInstance } from 'react-ioc';
import ValidateService, { InputValidations } from '@core/services/ValidateService';
import clearOfNonNumbers from '@core/helpers/clearOfNonNumbers';
import { InputInsertEvents, InputTypes } from '@UIElements/TextInput/models';


interface IUseInputValue {
    bind: {
        value: string;
        onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
    };
    clear: () => void;
    value: string;
    valid: boolean;
}


function useInputValue(
    defaultValue = '',
    externalValid?: boolean,
    typeOfValidation?: InputValidations,
    fieldType?: InputTypes,
): IUseInputValue {
    const validateService = useInstance(ValidateService);

    const [value, setValue] = useState<string>(defaultValue);
    const [validationResult, setValidationResult] = useState<boolean>(true);

    useEffect(() => {
        if (typeof externalValid === 'boolean') {
            setValidationResult(externalValid);
        }
    }, [externalValid, defaultValue]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const onChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
        event.persist();
        let { value } = event.target as HTMLInputElement;
        const { inputType } = event.nativeEvent as InputEvent;

        if (
            inputType === InputInsertEvents.INSERT_FROM_PASTE
            && fieldType === InputTypes.TEL
        ) {
            value = clearOfNonNumbers(value);
        }

        switch (typeOfValidation) {
            case InputValidations.AGE:
                setValidationResult(validateService.isAgeValid(value));
                break;
            case InputValidations.PHONE:
                setValidationResult(validateService.isPhoneValid(value));
                break;
            case InputValidations.LATIN:
                setValidationResult(validateService.isLatinValid(value));
                break;
            case InputValidations.EMAIL:
                setValidationResult(validateService.isEmailValid(value));
                break;
            default:
                setValidationResult(true);
        }

        setValue(value);
    }, []);

    return {
        bind: {
            value,
            onChange,
        },
        clear: () => setValue(''),
        get value() { return value; },
        get valid() { return validationResult; },
    };
}


export default useInputValue;
