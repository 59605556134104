import {
    Instance, types as t, SnapshotOut, SnapshotIn, getEnv, IAnyModelType,
} from 'mobx-state-tree';
import each from 'lodash/each';

import { IEnv } from '@store/store';
import ValidateService from '@core/services/ValidateService';
import { CustomerAppeal, ICustomerAppealModel } from './customerAppeals.model';


export const CustomerServiceClientCard = t
    .model('CustomerServiceClientCard', {
        customerName: t.string,
        customerPhone: t.string,
        customerAddress: t.string,
        isLoading: t.optional(t.boolean, false),
        createIfNotExist: t.optional(t.boolean, false), // Метка для бэкенда. Если true, то бэкенд создаст нам новую карточку клиента, если не найдет такую.
        appeals: t.array(CustomerAppeal),
        appealForAView: t.maybe(t.reference(t.late((): IAnyModelType => CustomerAppeal))),
    })
    .views((self) => ({
        get lastAppealId(): number {
            const { appeals } = self;
            let maxId = Number(appeals[0].id);
            each(appeals, (appeal) => {
                if (Number(appeal.id) > maxId) {
                    maxId = Number(appeal.id);
                }
            });

            return maxId;
        },
        get selectedAppealForAView(): ICustomerAppealModel | undefined {
            if (self.appealForAView) {
                return <ICustomerAppealModel>self.appealForAView;
            }

            return undefined;
        },
        get customerAppeals(): ICustomerAppealModel[] {
            return self.appeals;
        },

        get validateService(): ValidateService {
            const { validateService } = getEnv<IEnv>(self);

            return validateService;
        },

        get isClientCardCanBeShown(): boolean {
            // если какой-то из атрибутов заполнен, то показываем карточку клиента
            return self.customerAddress.length > 0
                || self.customerName.length > 0
                || self.customerPhone.length > 0;
        },
    }))
    .actions((self) => ({
        setAppealForAView(appealId: string) {
            if (appealId !== '') {
                self.appealForAView = appealId;
            }
        },

        clearAppealForAView() {
            self.appealForAView = undefined;
        },

        setCustomerPhone(value: string): void {
            if (!value.length) {
                self.customerPhone = '';

                return;
            }

            if (self.validateService.isFiguresValid(value)) {
                self.customerPhone = value;
            }
        },

        setCustomerName(value: string): void {
            self.customerName = value;
        },

        setCustomerAddress(value: string): void {
            self.customerAddress = value;
        },

        setIsLoading(value: boolean): void {
            self.isLoading = value;
        },

        clearClientCard(): void {
            self.customerName = '';
            self.customerPhone = '';
            self.customerAddress = '';
            self.isLoading = false;
        },

        addAppeal(appeal: ICustomerAppealModel): void {
            self.appeals.push(appeal);
        },
    }));


export interface ICustomerServiceClientCardModel extends Instance<typeof CustomerServiceClientCard> {}
export interface ICustomerServiceClientCardSnapshotIn extends SnapshotIn<typeof CustomerServiceClientCard> {}
export interface ICustomerServiceClientCardSnapshotOut extends SnapshotOut<typeof CustomerServiceClientCard> {}
