import { IReactionDisposer, reaction } from 'mobx';

import { OrderService, SipService } from '@services/index';
import InternetSpeedMetricService, { TInternetSpeedProblems } from '@core/services/InternetSpeedMetricService';
import { Store } from '@store/store';
import LocalStorageService from '@core/services/LocalStorageService';


class InternetSpeedMetricSubscriptionService {
    private _disposerChangeIsInternetSpeedProblems: IReactionDisposer;

    constructor(
        private readonly _store: Store,
        private readonly _orderService: OrderService,
        private readonly _internetSpeedMetricService: InternetSpeedMetricService,
        private readonly _sipService: SipService,
        private readonly _localStorageService: LocalStorageService,
    ) {}

    public subscribe(): void {
        this._disposerChangeIsInternetSpeedProblems = reaction<TInternetSpeedProblems>(
            () => {
                if (this._store.currentUser.needSpeedTest) {
                    return this._internetSpeedMetricService.internetSpeedProblems;
                }

                return 'unclear';
            },
            (isInternetSpeedProblems) => {
                if (isInternetSpeedProblems === 'yes') {
                    this._orderService.stopPollingOrder();
                    this._sipService.endCall();
                }

                if (isInternetSpeedProblems === 'no') {
                    void this._orderService.startPollingOrder();
                    this._sipService.connect();
                }
            },
        );
    }

    public unsubscribe(): void {
        if (this._disposerChangeIsInternetSpeedProblems) {
            this._disposerChangeIsInternetSpeedProblems();
        }
    }
}


export default InternetSpeedMetricSubscriptionService;
