import React, { FunctionComponent, useCallback, useEffect } from 'react';
import cn from 'classnames';

import Button from '@UIElements/Button';
import { AddModeEnum } from '@services/order/OrderService';


type TChipButtonProps = {
    readonly stateForButton: AddModeEnum;
    readonly buttonActive: boolean;
    readonly itemsLengthInCatalog: number;
    readonly text: string;
    onClick(data: AddModeEnum): void;
    resetChipToDefault(): void;
};


const Chip: FunctionComponent<TChipButtonProps> = ({
    buttonActive,
    onClick,
    resetChipToDefault,
    itemsLengthInCatalog,
    stateForButton,
    text,
}) => {
    const onClickButton = useCallback(
        () => onClick(stateForButton),
        [onClick, stateForButton],
    );

    // Если стало 0 элементов, то скидываем chip на 'все', т.к. этот чип может оставаться активным, не имея в себе содержимого
    useEffect(() => {
        if (itemsLengthInCatalog === 0) {
            resetChipToDefault();
        }
    }, [itemsLengthInCatalog]);

    return (
        <Button
            className={cn(
                'categorySelectionButton',
                buttonActive && 'categorySelectionButton__active',
                !itemsLengthInCatalog && 'categorySelectionButton__noGoods',
            )}
            size="0"
            variant="0"
            onClick={onClickButton}
            width="auto"
            disabled={!itemsLengthInCatalog}
            dataCyValue={`button_to_select_bonus_items_${stateForButton}`}
        >
            <span>{text}</span>
            {!!itemsLengthInCatalog && (
                <div className="circle">
                    {itemsLengthInCatalog}
                </div>
            )}
        </Button>
    );
};


export default Chip;
