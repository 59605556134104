/* eslint-disable @typescript-eslint/comma-dangle */
import React, {
    FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import cn from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import useOnClickOutside from 'use-onclickoutside';

import { useInputFocus } from '@/utils/useFocus';
import ChevronSvgIcon from '@components/resources/SVG/Chevron/ChevronSVG';

import './includes/SelectDummy.scss';


interface ISelectDummyProps<P = Record<string, any>> {
    className?: string; // для wrapper-a всего элемента
    children?: JSX.Element; // икнока для отображения в select-e. отображается только если flag hasIcon = true
    hasIcon: boolean;
    value: string; // выбранное значение
    values: ISelectValueExtended<P>[]; // опции для выбора
    inputId: string; // Unique ID through out the whole document for the internal input field
    disabled: boolean; // Indicates that the control is not available for interaction
    invalid: boolean; // A boolean flag indicating an error
    validationMessage: string; // An error message to be displayed below the form field
    label: string; // Text to be displayed as the label
    onChange: (id: string) => void; // Логика выбора значения должна быть вне компонента. Передаём в функцию идентификатор выбранного элемента.
    onBlur?: (event: FormEvent<HTMLInputElement>) => void; // позволяет добавить кастомную логику при событии onBlur
    onFocus?: (event: FormEvent<HTMLInputElement>) => void; // позволяет добавить кастомную логику при событии onFocus
    autoComplete?: string; // Specifies whether autocomplete is applied to an editable select(input).
    customLiRenderer?: (props: CustomLiRendererProps<P>) => JSX.Element; // Custom li element, returns generic props, depending on [values] data type received
    popupHeightMax?: number; // максимальная высота контейнера с контентом списка
    popupViewClassName?: string; // имя класса для контента выпадашки select-a
    popupVerticalThumbClassName?: string; // имя класса вертикального скролл элемента
}

/**
 * Интерфейс пропсов функции, которая будет отрисовывать кастомное наполнение <li> тэга выпадашки
 * Типизация интерфейса динамическая и зависит от типа переданных в компонент данных в массиве values
 */
export interface CustomLiRendererProps<P> {
    value: ISelectValueExtended<P>;
    onSelect: (event: FormEvent<HTMLLIElement>) => void;
    key: string;
    id: string;
}

/**
 * Базовый обязательный интерфейс для значений, которые можно выбрать в Select компоненте
 */
export interface ISelectValue {
    label: string; // то, что показываем в интерфейсе
    id: string; // ИД, по которому можем найти элемент в массиве
}

/**
 * Расширение для базового обязательного интерфейса.
 * Может быть дополнено любыми атрибутами,
 * необходимыми для отрисовки дополнительного контента в списке
 */
export type ISelectValueExtended<P> = P & ISelectValue;


/**
 * @param props - изучаем в комментариях к типизации интерфейса
 */
const SelectDummy = <P, >(
    props: ISelectDummyProps<P>,
): JSX.Element => {
    const {
        className,
        children,
        value,
        values,
        inputId,
        disabled = false,
        invalid = false,
        validationMessage,
        label,
        onChange,
        onBlur,
        onFocus,
        autoComplete = 'off',
        hasIcon = false,
        customLiRenderer,
        popupHeightMax,
        popupViewClassName,
        popupVerticalThumbClassName,
    } = props;

    const [focusRef, isFocused] = useInputFocus();
    const [isSelectMenuOpened, setIsSelectMenuOpened] = useState<boolean>(false);
    const [popupWidth, setPopupWidth] = useState<number>(0);

    const onClickOutside = (): void => {
        setIsSelectMenuOpened(false);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, onClickOutside);

    const toggleSelect = useCallback<() => void>((): void => {
        if (disabled) {
            return;
        }

        setIsSelectMenuOpened((prev: boolean) => !prev);
    }, [isSelectMenuOpened, disabled]);

    /**
     * При выборе элемента из выпадашки вызываем функцию onChange
     * И закрываем popup
     */
    const onSelect = useCallback<(event: FormEvent<HTMLLIElement>) => void>((event) => {
        const element = event.target as HTMLLIElement;
        onChange(element.id);
        setIsSelectMenuOpened(false);
    }, [values]);

    /**
     * Устанавливаем ширину PopUp выпадашки в зависимости от ширины родителя
     */
    useEffect(() => {
        if (wrapperRef.current) {
            const wrapperWidth = wrapperRef.current.offsetWidth;
            setPopupWidth(wrapperWidth);
        }
    }, [wrapperRef.current?.offsetWidth]);

    return (
        <div
            className={cn([className,
                'DummySelect__wrapper',
                disabled && 'disabled',
                isFocused && !disabled && 'focused',
            ])}
            ref={wrapperRef}
        >
            <div className="textfield">
                {/* Иконка для самого select-a (дополнительный, опциональный элемент для отображения) */}
                {hasIcon && children && (
                    <div
                        className={cn(['icon',
                            isFocused && !disabled && 'icon_focused', // если в select-e не пусто или мы прям встали курсором в select
                            invalid && 'icon_invalid',
                        ])}
                    >
                        {children}
                    </div>
                )}

                <div
                    className={cn([
                        'label',
                        disabled && 'label_disabled',
                        (isFocused || value.length) && !disabled && 'label_focused', // если в select-e не пусто или мы прям встали курсором в select
                        value.length && !isFocused && !disabled && 'label_has_value_not_focused', // если есть значение, не disabled и select не focused
                        value.length && !isFocused && disabled && 'label_has_value_not_focused_disabled', // есть значение, select disabled
                        !disabled && invalid && 'label_invalid',
                        hasIcon && children && 'has_icon_label',
                    ])}
                    title={label}
                >
                    {label}
                </div>
                <input
                    className={cn([
                        'select',
                        disabled && 'select_disabled',
                        !disabled && invalid && 'select_invalid',
                        hasIcon && children && 'has_icon_select',
                    ])}
                    id={inputId}
                    ref={focusRef}

                    onBlur={onBlur}
                    onFocus={onFocus}
                    onClick={toggleSelect}

                    autoComplete={autoComplete}
                    disabled={disabled}
                    title={value}
                    value={value}

                    readOnly={true}
                    type="text"
                />
                {/* Иконка, отображающая состояние select-а: раскрыто / нет */}
                <div className={cn([
                    'select_chevron',
                    (isFocused) && !disabled && 'select_chevron_focused',
                    isSelectMenuOpened && !disabled && 'select_chevron_opened',
                    !disabled && invalid && 'select_chevron_invalid',
                ])}
                >
                    <ChevronSvgIcon />
                </div>
                {/* Блок с сообщением об ошибке */}
                <div
                    className={cn([
                        'error_field',
                        !disabled && invalid && 'error_visible',
                    ])}
                    title={validationMessage}
                >
                    {validationMessage}
                </div>
            </div>
            {/* Выпадающий Popup с опциями для выбора */}
            <div className="popup__wrapper">
                <div className={cn('select-popup', { active: isSelectMenuOpened })}>
                    <Scrollbars
                        style={{ width: `${popupWidth - 1}px` }}
                        hideTracksWhenNotNeeded={true}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={popupHeightMax || 160}
                        universal={true}
                        renderView={(props: any) => (
                            <div
                                {...props}
                                className={cn([popupViewClassName || 'select-popup_renderView'])}
                                data-cy="select-popup"
                            />
                        )}
                        renderTrackVertical={(props: any) => (
                            <div
                                {...props}
                                className={cn([popupVerticalThumbClassName || 'select-popup_renderVertical'])}
                                data-cy="select-popup-track-horizontal"
                            />
                        )}
                    >
                        <ul className="values">
                            {
                                values.map((value) => {
                                    const key = value.id;
                                    const { id } = value;

                                    // Если функции нет, то рисуем список стандартным способ для компонента
                                    if (!customLiRenderer) {
                                        return (
                                            <li
                                                key={key}
                                                onClick={onSelect}
                                                id={id}
                                                title={value.label}
                                            >
                                                {value.label}
                                            </li>
                                        );
                                    }

                                    // Иначе вызываем функцию рендер, передавая в неё нужные данные для отрисовки
                                    return (
                                        customLiRenderer({
                                            value, onSelect, key, id,
                                        })
                                    );
                                })
                            }
                        </ul>
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};


export default SelectDummy;
