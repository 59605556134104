import React, {
    FunctionComponent, useCallback, useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import { useInstances } from 'react-ioc';
import cn from 'classnames';

import { Store } from '@store/store';
import { OrderCreationService } from '@services/order';
import FirstStepCreation from '@components/main/order-creation-page/first-step-creation/FirstStepCreation';
import FullscreenLoading from '@components/main/fullscreen-loading/FullscreenLoading';
import I18NService from '@services/I18NService';
import { Locations } from '@core/models/locations';
import BottomMobileTabs, { BottomMobileTabsPageEnum } from '@components/main/bottom-mobile-menu/BottomMobileTabs';
import OrderCreationEventSubscriptionService from '@services/subscription/OrderCreationEventSubscriptionService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';

import './includes/OrderCreationPage.scss';


const OrderCreationPage: FunctionComponent = (): JSX.Element => {
    const [
        {
            orderCreation: {
                isDataReadyToBeSent,
            },
            currentUser: {
                mode,
            },
            setCurrentTab,
        },
        orderCreationService,
        { t },
        orderCreationEventSubscriptionService,
    ] = useInstances(
        Store,
        OrderCreationService,
        I18NService,
        OrderCreationEventSubscriptionService,
    );

    const { waitingDataDownload, waitingCreatedOrder } = orderCreationService;

    const createOrder = async (): Promise<void> => {
        await orderCreationService.createOrder();
        setCurrentTab(Locations.MAIN);
    };

    useEffect(() => {
        void orderCreationService.initializationOfTheOrderCreationModel();
        orderCreationEventSubscriptionService.subscribe();

        return () => {
            orderCreationEventSubscriptionService.unsubscribe();
            orderCreationService.clearOrderCreationModel();
        };
    }, []);

    const onCreateOrder = useCallback(createOrder, [isDataReadyToBeSent]);

    return (
        <div className={cn(
            'OrderCreationPage',
            mode === UserModeEnum.CLIENT_SERVICE && 'customer-service',
        )}
        >
            <div className="OrderCreationPage__wrapper">
                <div className="OrderCreationPage__pageTitle">
                    {t('Создание заказа', 'Created order')}
                </div>
                <div className="OrderCreationPage__content">
                    {
                        waitingDataDownload && <FullscreenLoading text="Loading" />
                    }
                    {
                        !waitingDataDownload && (
                            <FirstStepCreation
                                preloader={waitingCreatedOrder}
                                onSubmit={onCreateOrder}
                            />
                        )
                    }
                </div>
            </div>
            <BottomMobileTabs page={BottomMobileTabsPageEnum.ORDER_CREATION} />
        </div>
    );
};


export default observer<IReactComponent>(OrderCreationPage);
