import React, { FunctionComponent } from 'react';
import TrashIcon from '@components/resources/SVG/Trash/TrashIcon';
import CloseIconSvg from '@UIElements/Notify/includes/CloseIconSvg';

import './RemoveItemButton.scss';

interface IRemoveItemButtonProps {
    onClick?(): void;
    readonly typeIcon: 'trash' | 'close';
}

const RemoveItemButton: FunctionComponent<IRemoveItemButtonProps> = ({
    onClick,
    typeIcon,
}): JSX.Element => (
    <div
        className="removeItemButton"
        onClick={onClick}
    >
        {typeIcon === 'trash' && <TrashIcon />}
        {typeIcon === 'close' && <CloseIconSvg />}
    </div>
);


export default RemoveItemButton;
