import React, { FC, useCallback } from 'react';
import ChangeOrderButtons
    from '@components/main/order-page/call-panel/call-widget/ChangeOrderButtons/ChangeOrderButtons';
import { useInstance } from 'react-ioc';
import ModalService from '@core/services/ModalService';

import './ChangeOrderButtonsInModal.scss';

const ChangeOrderButtonsInModal: FC = () => {
    const modalService = useInstance(ModalService);
    const { cancel } = modalService;

    const onAnyStatusSelected = useCallback(() => {
        cancel();
    }, [cancel]);

    return (
        <div className="ChangeOrderButtonsInModal">
            <ChangeOrderButtons
                onAnyStatusSelected={onAnyStatusSelected}
            />
        </div>
    );
};

export default ChangeOrderButtonsInModal;
