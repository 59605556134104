import React, { FC } from 'react';


interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    html: string;
}

const DivWithDangerouslySetInnerHTML: FC<IProps> = ({ html, ...rest }) => (
    <div dangerouslySetInnerHTML={{ __html: html }} {...rest} />
);

export default DivWithDangerouslySetInnerHTML;
