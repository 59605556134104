import get from 'lodash/get';

import ApiBase from '@core/api';
import { getEnvGetSourceListEndpoint, getEnvGetSourceTypeListEndpoint } from '@/env/requestEndpoints';
import { ISourceModelSnapshotIn } from '@models/mobx-state-tree/source.model';
import { ISourceType } from '@api/source-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class SourceApiService {
    @Inject apiBase: ApiBase;

    async getSourceList(): Promise<ISourceModelSnapshotIn[]> {
        const response = await this.apiBase.get<IWithResponse<ISourceModelSnapshotIn[]>>(getEnvGetSourceListEndpoint());

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getSourceTypeList(countryId: number, partnerId: number, sourceId: number): Promise<ISourceType[]> {
        const dataForSend = {
            partner_id: partnerId,
            country_id: countryId,
            source_id: sourceId,
        };

        const response = await this.apiBase.get<IWithResponse<ISourceType[]>>(
            getEnvGetSourceTypeListEndpoint(),
            dataForSend,
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as ISourceType[];
    }
}


export default SourceApiService;
