import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


interface IGetBreakpoints {
    (): {
        tablet: number;
        mobile: number;
        orderBlockBreakPoint: number;
        changeOrderButtonHeightBreakpoint: number;
    };
}

interface IGetBreakpointsState {
    (): {
        tablet: boolean;
        mobile: boolean;
        orderBlockBreakPoint: boolean;
        changeOrderButtonHeightBreakpoint: boolean;
    };
}

/**
 * Функция возвращает значение точек слома адаптивного дизайна получая значения из css custom properties
 */
const getBreakpoints: IGetBreakpoints = () => {
    const style = getComputedStyle(document.documentElement);

    const tablet = parseInt(style.getPropertyValue('--tablet'), 10);
    const mobile = parseInt(style.getPropertyValue('--mobile'), 10);
    const orderBlockBreakPoint = parseInt(style.getPropertyValue('--orderBlockBreakPoint'), 10);
    const changeOrderButtonHeightBreakpoint = parseInt(style.getPropertyValue('--changeOrderButtonHeightBreakpoint'), 10);

    return {
        tablet,
        mobile,
        orderBlockBreakPoint,
        changeOrderButtonHeightBreakpoint,
    };
};

/**
 * Функция возвращает статус точек слома дизайна, достигнуто условие или нет.
 * Например tablet true говорит что разрешение экрана подходит под точку слома tablet
 */
const getBreakpointsState: IGetBreakpointsState = () => {
    const state = {
        tablet: false,
        mobile: false,
        orderBlockBreakPoint: false,
        changeOrderButtonHeightBreakpoint: false,
    };

    const breakpoints = getBreakpoints();

    const w = window.innerWidth;
    const h = window.innerHeight;

    if (w <= breakpoints.tablet) {
        if (w <= breakpoints.mobile) {
            state.mobile = true;
        } else {
            state.tablet = true;
        }
    }

    if (h <= breakpoints.changeOrderButtonHeightBreakpoint) {
        state.changeOrderButtonHeightBreakpoint = true;
    }

    return state;
};

export const ResponsiveModel = t
    .model('ResponsiveModel', {
        tablet: t.optional(t.boolean, false),
        mobile: t.optional(t.boolean, false),
        orderBlockBreakPoint: t.optional(t.boolean, false),
        changeOrderButtonHeightBreakpoint: t.optional(t.boolean, false),
    })
    .views((self) => ({
        /**
         * Хеш состояния, используется для реакции, по ней скидывается состояние ui к дефолтному значению
         */
        get hash(): string {
            return `${String(self.tablet)}-${String(self.mobile)}`;
        },
    }))
    .actions((self) => ({
        update(): void {
            const state = getBreakpointsState();

            self.tablet = state.tablet;
            self.mobile = state.mobile;
            self.orderBlockBreakPoint = state.orderBlockBreakPoint;
            self.changeOrderButtonHeightBreakpoint = state.changeOrderButtonHeightBreakpoint;
        },
    }))
    .actions((self) => ({
        init(): void {
            /**
             * Создаем оптимизированное событие ресайза, чтобы сократить кол-во событий и не вешать интерфейс
             */
            // https://developer.mozilla.org/ru/docs/Web/API/Window/resize_event
            // eslint-disable-next-line func-names
            (function () {
                // eslint-disable-next-line func-names
                const throttle = function (type: any, name: any, obj: typeof globalThis) {
                    obj = obj || window;
                    let running = false;
                    // eslint-disable-next-line func-names
                    const func = function () {
                        if (running) {
                            return;
                        }
                        running = true;
                        requestAnimationFrame(() => {
                            obj.dispatchEvent(new CustomEvent(name));
                            running = false;
                        });
                    };
                    obj.addEventListener(type, func);
                };

                /* init - you can init any event */
                throttle('resize', 'optimizedResize', window);
            }());

            // Когда дом загружен начинаем слушать событие
            document.addEventListener('DOMContentLoaded', () => {
                window.addEventListener('optimizedResize', self.update);
            });

            self.update();
        },
    }));


export interface IResponsiveModel extends Instance<typeof ResponsiveModel> {}
export interface IResponsiveModelSnapshotIn extends SnapshotIn<typeof ResponsiveModel> {}
export interface IResponsiveModelSnapshotOut extends SnapshotOut<typeof ResponsiveModel> {}
