import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';

import './includes/GridTable.scss';
import each from 'lodash/each';

export interface IBaseCellData {
    content: string;
    id: string;
}

export type IBaseCellDataExtended<E> = E & IBaseCellData;

export type WithRows<K extends keyof any, T> = {
    [P in K]: IBaseCellDataExtended<T>;
};

export interface IGridTableColumn {
    id: string;
    label: string;
    minMax_MinValue: string; // see css grid minmax property
    minMax_MaxValue: string; // see css grid minmax property
    render?: () => JSX.Element; // Uses render function to render table header cell if render fn was provided
    // I have not figured out whether to pass props on this render fn. If required during use - feel free to add.
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IGridTableProps<K extends keyof any, Z = {}> {
    className?: string;
    columns: IGridTableColumn[];
    rowsSize: number;
    rows: WithRows<K, Z>[]; // By default should have IBaseCellData fields provided
    cellsRenderer?: (rows: WithRows<K, Z>[], gridTableStyle: React.CSSProperties) => JSX.Element[]; // custom cells renderer function -> see storybook to usage examples
    onRowClick?: (rowId: string | undefined) => void; // callback with the row clicked identifier returned
    tableContentHeight?: number; // to override default 556px table height
    customScrollBarStyle?: string; // to define your custom scrollbar styling
    renderHeaderSeparately?: boolean; // flag to render header separately from content
    headerClassname?: string; // to define your custom classname to header
    gridRowClassname?: string; // to define your custom classname to gridRow
    gridCellClassname?: string; // to define your custom classname to gridCell
}


const GridTableRecalls = <P extends keyof any, T>(
    props: IGridTableProps<P, T>,
): JSX.Element => {
    const {
        className,
        columns,
        rows,
        rowsSize,
        tableContentHeight,
        customScrollBarStyle,
        headerClassname,
        gridRowClassname,
        gridCellClassname,
        renderHeaderSeparately = false,
        cellsRenderer,
        onRowClick,
    } = props;
    const [defaultRowsOutput, setDefaultRowsOutput] = useState<JSX.Element[]>([]);

    const gridTableStyle = useMemo<React.CSSProperties>(() => (
        {
            display: 'grid',
            // overflow: 'auto',
            flex: 1,
            gridTemplateColumns: (columns.map((column) => `minmax(${column.minMax_MinValue}, ${column.minMax_MaxValue})`)).join(' '),
            gridTemplateRows: `repeat(${rowsSize + 1}, max-content)`,
        }
    ), [columns, rowsSize]);

    /**
     * Calls props callback onRowClick with the row clicked identifier
     */
    const onRowClickCallBack = useCallback<React.MouseEventHandler<HTMLDivElement>>((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element = event.target as HTMLDivElement;
        const gridRow = element.parentElement;
        const rowId = gridRow?.id;

        if (rowId && onRowClick) {
            onRowClick(rowId);
        }
    }, [onRowClick]);

    useEffect(() => {
        if (!cellsRenderer) {
            const data: JSX.Element[] = [];
            let rowId: string | undefined;

            each(rows, (row) => {
                const rowCell: JSX.Element[] = [];
                each(row, (value, key) => {
                    rowId = value.id;

                    rowCell.push(
                        (
                            <div
                                key={key}
                                className={gridCellClassname || 'grid_cell'}
                            >
                                <div
                                    className="rgt-text-truncate"
                                >
                                    {value.content}
                                </div>
                            </div>
                        ),
                    );
                });
                data.push(
                    (
                        <div
                            className={gridRowClassname || 'grid_row'}
                            id={rowId}
                            key={rowId}
                            onClick={onRowClickCallBack}
                        >
                            {rowCell}
                        </div>
                    ),
                );
            });

            setDefaultRowsOutput(data);
        }
    }, [rows, cellsRenderer]);

    return (
        <div
            className={cn([className,
                'GridTable__wrapper',
                customScrollBarStyle || 'pre_defined_scrollbar',
            ])}
        >
            { // Renders header separately. But will have different overflow-x, if table will collapse.
                renderHeaderSeparately && (
                    <div
                        className={gridRowClassname || 'grid_row'}
                        style={gridTableStyle}
                    >
                        {
                            columns.map((column) => (
                                <div
                                    key={column.id}
                                    className={headerClassname || 'grid_header_cell'}
                                >
                                    {column.render ? column.render() : column.label}
                                </div>
                            ))
                        }
                    </div>
                )
            }
            <div
                className={cn([className,
                    'GridTable__wrapper',
                    customScrollBarStyle || 'pre_defined_scrollbar',
                ])}
            >
                {!renderHeaderSeparately &&
                    <div
                        className='grid_header'
                        style={gridTableStyle}
                    >
                        {columns.map((column) => (
                            <div
                                key={column.id}
                                className={headerClassname || 'grid_header_cell grid_header_cell__recalls'}
                            >
                                {column.render ? column.render() : column.label}
                            </div>
                        ))}
                    </div>}
                <div
                    className={'recalls-table_content'}
                    style={{
                        height: tableContentHeight || '580px',
                    }}
                >
                    {
                        cellsRenderer ? cellsRenderer(rows, gridTableStyle) : (
                            defaultRowsOutput
                        )
                    }
                </div>
            </div>
        </div>
    );
};


export default GridTableRecalls;