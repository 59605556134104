import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcTrashStatusSvg {
    className?: string;
}


const IcTrashStatusSvg: FunctionComponent<IIcTrashStatusSvg> = ({ className, ...props }): JSX.Element => (

    <svg
        className={cn(['IcTrashStatusSvg', className])}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
            fill="#F0A92E"
        />
        <path
            d="M15.4997 8.91667C15.4997 9.375 15.8747 9.75 16.333 9.75H19.6663C20.1247 9.75 20.4997 9.375 20.4997 8.91667V4.75C20.4997 4.29167 20.1247 3.91667 19.6663 3.91667H16.333C15.8747 3.91667 15.4997 4.29167 15.4997 4.75V8.91667ZM20.4997 2.66667H19.458L19.1622 2.37083C19.0872 2.29583 18.9788 2.25 18.8705 2.25H17.1288C17.0205 2.25 16.9122 2.29583 16.8372 2.37083L16.5413 2.66667H15.4997C15.2705 2.66667 15.083 2.85417 15.083 3.08333C15.083 3.3125 15.2705 3.5 15.4997 3.5H20.4997C20.7288 3.5 20.9163 3.3125 20.9163 3.08333C20.9163 2.85417 20.7288 2.66667 20.4997 2.66667Z"
            fill="#F0A92E"
        />
    </svg>

);


export default IcTrashStatusSvg;
