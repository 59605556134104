import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Transition } from 'react-transition-group';

import ProductImage from '@components/main/order-page/order-products/order-table/includes/ProductImage';
import PopupWithoutAnimations from '@/app/ui-elements/Popup/PopupWithoutAnimations';
import GiftSVG from '@/app/components/resources/SVG/ProductLabels/GiftSVG';
import ProductSVG from '@/app/components/resources/SVG/ProductLabels/ProductSVG';
import PromoSVG from '@/app/components/resources/SVG/ProductLabels/PromoSVG';
import SetSVG from '@/app/components/resources/SVG/ProductLabels/SetSVG';
import Button from '@/app/ui-elements/Button';
import ProductLabel from '@/app/ui-elements/ProductLabel';
import { useHover } from '@/utils/useHover';
import { ProductItemSubTypeEnum, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import LockSVG from '@/app/components/resources/SVG/Lock/LockSVG';
import { IPromotionBuilderDataForComponent, ITranslationsForPromotionBuilder } from './helpers/models';
import './PromotionBuilder.scss';


export interface ICurtainHeader {
    itemColLabel: string;
    amountColLabel: string;
    priceColLabel: string;
}

interface IPromotionBuilderToAddProps {
    className?: string;
    /**
     * Применен ли К.А.
     */
    isApplied: boolean;
    promotionIsNotAvailable: boolean;
    promotionBuilder: IPromotionBuilderDataForComponent;
    translations: ITranslationsForPromotionBuilder;
    /**
     * Переведенные лейблы для таблицы товаров в шторке карточки К.А.:\
     * Товар, количество, цена
     */
    curtainHeader: ICurtainHeader;
    /**
     * Переведенный лейбл для цены подарка (вместо 0 пишем Free / бесплатно)
     */
    translatedLabelForAGiftPrice: string;
    disabled?: boolean;
    onApplied(): void;
    readonly dataCyValue?: number;
    quantityOfGoodsBeforeApplication?: string;
    amountOfGoogdsThatAreMissing?: number;
}


const PromotionBuilderToAdd: FunctionComponent<IPromotionBuilderToAddProps> = ({
    className,
    isApplied,
    promotionBuilder,
    translations,
    curtainHeader,
    disabled = false,
    onApplied,
    promotionIsNotAvailable,
    translatedLabelForAGiftPrice,
    quantityOfGoodsBeforeApplication,
    amountOfGoogdsThatAreMissing,
    // dataCyValue = 0,
}) => {
    const [hoverRef, isHovered] = useHover();

    const switchSvgByProductType = (productType: ProductItemTypeEnum | null, productSubType: ProductItemSubTypeEnum | null) => {
        if (productType === ProductItemTypeEnum.DYNAMIC_SET || productType === ProductItemTypeEnum.STATIC_SET) {
            return (
                <SetSVG />
            );
        }

        if (productSubType === ProductItemSubTypeEnum.MAIN) {
            return (
                <ProductSVG />
            );
        }

        if (productSubType === ProductItemSubTypeEnum.GIFT) {
            return (
                <GiftSVG />
            );
        }

        if (productSubType === ProductItemSubTypeEnum.PROMO) {
            return (
                <PromoSVG />
            );
        }

        return <></>;
    };

    return (
        <div
            ref={hoverRef}
            className={cn([
                className,
                'PromotionBuilderToApply__wrapper',
                disabled && 'disabled',
                isHovered && 'hovered',
            ])}
            data-cy={`PromotionBuilderToApply__wrapper${promotionBuilder.promotionBuilderId}`}
        >
            <div className="PromotionBuilderToApply__product_image__wrapper">
                {/* Картинка основного товара, к которому применим К.А. */}
                <ProductImage
                    className="PromotionBuilderToApply__product_image"
                    productName={promotionBuilder.promotionBuilderName}
                    imageLink={promotionBuilder.mainProductImage}
                    productType={promotionBuilder.mainProductType}
                />
                <div className="PromotionBuilderToApply__product_gallery__wrapper">
                    {/* Галлерея картинок, которые идут в комплекте к акции. Показываем до 4 картинок */}
                    {promotionBuilder.items
                    && promotionBuilder.items.length <= 4
                    && promotionBuilder.items.map((item, index) => (
                        <ProductImage
                            className="PromotionBuilderToApply__product_image"
                            productName=""
                            imageLink={item.image}
                            productType={item.type}
                            key={`${index}-${item.name}`}
                        />
                    ))}
                    {promotionBuilder.items
                    && promotionBuilder.items.length > 4
                    && promotionBuilder.items
                        .filter((_, index: number) => index <= 2)
                        .map((item, index) => (
                            <ProductImage
                                className="PromotionBuilderToApply__product_image"
                                productName=""
                                imageLink={item.image}
                                productType={item.type}
                                key={`${index}-${item.name}`}
                            />
                        ))}
                    {promotionBuilder.items
                    && promotionBuilder.items.length > 4
                    && promotionBuilder.items[3] && (
                        <ProductImage
                            className="PromotionBuilderToApply__product_image"
                            productName=""
                            imageLink={promotionBuilder.items[3].image}
                            productType={promotionBuilder.items[3].type}
                            key={`${3}-${promotionBuilder.items[3].name}`}
                        >
                            <div className="blur">
                                <div className="remaining_number">{promotionBuilder.items.length - 3}</div>
                            </div>
                        </ProductImage>
                    )}
                </div>
            </div>
            <div className="PromotionBuilderToApply__product_name" title={promotionBuilder.promotionBuilderName}>
                {promotionBuilder.promotionBuilderName}
            </div>
            {isApplied && promotionIsNotAvailable && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={translations.button_there_is_no_possibility}
                    dataCyValue={`promotion_builder_is_applied_${promotionBuilder.promotionBuilderId}`}
                />
            )}
            {Boolean(quantityOfGoodsBeforeApplication?.length) && !isApplied && (
                <div
                    className="promo_alert"
                    title={quantityOfGoodsBeforeApplication}
                    data-cy={`quantity_notification_is_applied_${promotionBuilder.promotionBuilderId}-${amountOfGoogdsThatAreMissing}`}
                >
                    {quantityOfGoodsBeforeApplication}
                </div>
            )}
            {!quantityOfGoodsBeforeApplication && !isApplied && (
                <Button
                    variant="3"
                    size="0"
                    text={translations.button_can_be_added}
                    width="128px"
                    onClick={onApplied}
                    dataCyValue={`promotion_builder_not_applied_${promotionBuilder.promotionBuilderId}`}
                />
            )}
            {isApplied && !promotionIsNotAvailable && (
                <Button
                    size="0"
                    variant="1"
                    width="128px"
                    disabled
                    text={translations.button_yet_added}
                    dataCyValue={`promotion_builder_is_applied_${promotionBuilder.promotionBuilderId}`}
                />
            )}
            <span className="PromotionBuilderToApply__product_type">
                <ProductLabel
                    productType={null}
                    productSubType={null}
                    isPromotion={true}
                    text={translations.label}
                />
            </span>
            <div className="PromotionBuilderToApply_curtain">
                <Transition
                    in={isHovered}
                    timeout={200}
                    mountOnEnter
                    unmountOnExit
                >
                    {(state): JSX.Element => (
                        <>
                            <div
                                className={cn([state, 'curtainHeader'])}
                                key="curtainHeader"
                                style={{ transitionDelay: '0.01s' }}
                            >
                                <div
                                    className={cn(['firstCol'])}
                                >
                                    {curtainHeader.itemColLabel}
                                </div>
                                <div
                                    className={cn(['secondCol'])}
                                >
                                    {curtainHeader.amountColLabel}
                                </div>
                                <div
                                    className={cn(['thirdCol'])}
                                />
                                <div
                                    className={cn(['fourthCol'])}
                                >
                                    {curtainHeader.priceColLabel}
                                </div>
                            </div>
                            <PopupWithoutAnimations
                                className="PromotionBuilderToApply__popup"
                                autoHeightMax={169}
                            >
                                <ul>
                                    {/* Товары, которые входят в состав акции: */}
                                    {promotionBuilder.items.map((item, index) => (
                                        <li
                                            className={state}
                                            key={item.id}
                                        >
                                            <div className="li_row">
                                                <div
                                                    className={cn([state, 'firstCol'])}
                                                    style={{ transitionDelay: `${(10 + index) / 100}s` }}
                                                    title={item.name}
                                                >
                                                    <div
                                                        className={cn(['product_icon'])}
                                                    >
                                                        {switchSvgByProductType(item.type, item.subType)}
                                                    </div>
                                                    <div
                                                        className={cn(['product_name'])}
                                                    >
                                                        {item.name}
                                                    </div>
                                                    {item.mandatoryGift && (
                                                        <div
                                                            className={cn(['mandatoryGift'])}
                                                        >
                                                            <LockSVG />
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={cn([state, 'secondCol'])}
                                                    style={{ transitionDelay: `${(10 + index) / 100}s` }}
                                                >
                                                    <div
                                                        className="amount"
                                                        title={item.quantity && String(item.quantity) || undefined}
                                                    >
                                                        {/**
                                                         * Количество товара, для показа:
                                                         * 1. *IProductItemModel.amountToAdd* для MAIN товара (from = от этого количества и до лимита)
                                                         * 2. optionalGift = *IProductItemModel.maxAmount* (up to = от 0 до maxAmount)
                                                         * 3. defaultGift = *IProductItemModel.maxAmount* (fix = нельзя изментья количество)
                                                         * 4. promo = *IProductItemModel.maxAmount* (up to = от 0 до maxAmount)
                                                         */}
                                                        {item.type === ProductItemTypeEnum.REGULAR
                                                        && item.subType === ProductItemSubTypeEnum.MAIN
                                                        && item.quantity && (
                                                            `${translations.fromAmountPhrase} ${item.quantity}`
                                                        )}
                                                        {item.type === ProductItemTypeEnum.REGULAR
                                                        && item.subType === ProductItemSubTypeEnum.GIFT
                                                        && item.mandatoryGift
                                                        && item.quantity && (
                                                            item.quantity
                                                        )}
                                                        {item.type === ProductItemTypeEnum.REGULAR
                                                        && (item.subType === ProductItemSubTypeEnum.GIFT
                                                        && !item.mandatoryGift)
                                                        && item.quantity && (
                                                            `${translations.upToAmountPhrase} ${item.quantity}`
                                                        )}
                                                        {item.type === ProductItemTypeEnum.REGULAR
                                                        && item.subType === ProductItemSubTypeEnum.PROMO
                                                        && item.quantity && (
                                                            `${translations.upToAmountPhrase} ${item.quantity}`
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={cn([state, 'thirdCol'])}
                                                    style={{ transitionDelay: `${(10 + index) / 100}s` }}
                                                >
                                                    {item.discount && (
                                                        <div
                                                            className="product_discount"
                                                            title={String(Math.round(item.discount))}
                                                        >
                                                            {`-${Math.round(item.discount)}%`}
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={cn([state, 'fourthCol'])}
                                                    style={{ transitionDelay: `${(10 + index) / 100}s` }}
                                                >
                                                    {item.price === 0 && (
                                                        <div
                                                            className="product_price"
                                                            title={translatedLabelForAGiftPrice}
                                                        >
                                                            <span>
                                                                {translatedLabelForAGiftPrice}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {item.price !== null && item.price > 0 && (
                                                        <div
                                                            className="product_price"
                                                            title={String(item.mainProductPrice ? item.mainProductPrice : item.price)}
                                                        >
                                                            {item.mainProductPrice ? item.mainProductPrice : item.price}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {/* TODO: блок с инфой по доставке */}
                                </ul>
                            </PopupWithoutAnimations>
                        </>
                    )}
                </Transition>
            </div>
        </div>
    );
};


export default observer(PromotionBuilderToAdd);
