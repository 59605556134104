import { computed, observable } from 'mobx';

import SnackbarModel from '../models/SnackbarModel';

const CLOSE_TIMEOUT = 3000;

class SnackbarService {
    @observable
    private _openSnackbarStack: SnackbarModel[] = [];

    @observable
    public visible = false;

    private get _currentSnackbar(): SnackbarModel {
        if (this._openSnackbarStack.length === 1) {
            return this._openSnackbarStack[0];
        }
        return this._openSnackbarStack[1];
    }

    @computed
    public get text(): string {
        return String(this._currentSnackbar.text);
    }

    @computed
    public get actionText(): string | undefined {
        return this._currentSnackbar.actionText;
    }

    @computed
    public get onActionClick(): (() => void) | undefined {
        return this._currentSnackbar.onActionClick;
    }

    /**
     * ! ! ! ! ! ! !
     * 1. Не рекомендуется использовать Snackbar, если размер сообщения заранее не известен.
     * 2. Оптимальная длина сообщения до 60 символов.
     * 3. Для остального рекомендуется использовать showModal().
     */
    public showSnackbar = (
        text: string,
        actionText?: string,
        onActionClick?: (() => void),
    ) => {
        this.setVisible(true);
        if (!onActionClick) {
            this._openSnackbarStack.push(
                new SnackbarModel(
                    text,
                ),
            );
            if (this._openSnackbarStack.length === 2) {
                this.closeOnTimeout();
            }
        } else {
            const onAction = () => {
                if (onActionClick) {
                    onActionClick();
                }
                this.closeSnackbar();
            };
            this._openSnackbarStack.push(
                new SnackbarModel(
                    text,
                    actionText,
                    onAction,
                ),
            );
        }
    };

    constructor() {
        this._openSnackbarStack.push(
            new SnackbarModel(
                '',
            ),
        );
    }

    public setVisible = (value: boolean): void => {
        this.visible = value;
    };

    public closeSnackbar = () => {
        this._openSnackbarStack.splice(1, 1);
        if (this._openSnackbarStack.length <= 1) {
            this.setVisible(false);
        } else if (!this._currentSnackbar.onActionClick) {
            this.closeOnTimeout();
        }
    };

    public closeOnTimeout = () => {
        setTimeout(() => {
            this.closeSnackbar();
        }, CLOSE_TIMEOUT);
    };
}


export default SnackbarService;
