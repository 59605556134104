import React, { FC, useEffect } from 'react';

import { Store } from '@store/store';

import { observer } from 'mobx-react';

import { IScriptDataModel } from '@models/mobx-state-tree/scripts/scriptDataModel';

import { block } from 'bem-cn';

import ArrowNavSvg from '@components/resources/SVG/ArrowNav/ArrowNav';
import DivWithDangerouslySetInnerHTML from '@UIElements/DivWithDangerouslySetInnerHTML';

import './script-item-theme.scss';
import { useInstances } from 'react-ioc';
import { ScriptDataService } from '@services/script';



interface IScriptItemThemeProps {
    data: IScriptDataModel;
    key: string;
    index: number;
}

const ScriptItemTheme: FC<IScriptItemThemeProps> = ({
    data: {
        color,
        text,
        scriptTheme: {
            name,
        },
        key,
    },
    index,
}) => {

    const [
        scriptDataService,
        {
            currentOrder: {
                setOpenedScriptThemes,
                isOpenedScriptTheme,
                isFirstLoadingScripts,
                setIsFirstLoadingScripts,
                isUseLeadProductScript,
                clearOpenedScriptThemes,
                setOpenedScriptItem,
            },
        },
    ] = useInstances(
        ScriptDataService,
        Store,
    );

    useEffect(() => {
        if (isFirstLoadingScripts && index === 0 && isUseLeadProductScript) {
            setOpenedScriptThemes(key);
            setIsFirstLoadingScripts();
            setOpenedScriptItem(0);
        }
    }, []);

    const b = block('script-item-theme');

    const idx = index + 1;

    const white = '#FFFFFF';

    const styleBackgroundText = {
        background: color ? color : white,
    };

    const onClick = () => {
        setOpenedScriptThemes(key);
    };

    const getIdFromHost = (host: string): string => host.split('.').slice(-1).join();


    const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if ((event.target as any).localName === 'a') {
            const id = getIdFromHost((event.target as any).host);
            scriptDataService.setScriptItem(Number(id));
            clearOpenedScriptThemes();
            setOpenedScriptItem(0);
            if (index === 0) {
                setOpenedScriptThemes(key);
            }
        }
    };

    const openScriptTheme = isOpenedScriptTheme(key);

    return (
        <div className={ b() }>
            <div
                className={b('title', { 'reverse': openScriptTheme ? true : false })}
                onClick={onClick}
            >
                <div
                    className={b('name')}
                >
                    {`${idx}. ${name}`}
                </div>
                <ArrowNavSvg
                    className={openScriptTheme ? 'arrowNav_prev' : 'arrowNav_next'}
                    width={10}
                    height={15}
                />
            </div>
            {openScriptTheme && <div
                className={b('text', { 'color': color ? true : false })}
                style={styleBackgroundText}
                onClick={handleOnClick}
            >
                <DivWithDangerouslySetInnerHTML
                    html={text}
                />
            </div>}

        </div>
    );
};

export default observer(ScriptItemTheme);