import get from 'lodash/get';

import ApiBase from '@core/api';
import { getEnvGetGeonegociosAddressEndpoint, getEnvGetGeonegociosGeocodeEndpoint } from '@/env/requestEndpoints';
import { IGeocode } from '@api/geo-negios-map-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T = any> {
    status: 'success' | 'error' | 'fail';
    message?: string;
    data: T;
}


class GeoNegiosMapApiService {
    @Inject apiBase: ApiBase;

    public async getByGeocode(latitude: string, longitude: string, country: string): Promise<IGeocode> {
        const response = await this.apiBase.post<IWithResponse<IGeocode>>(
            getEnvGetGeonegociosGeocodeEndpoint(),
            {
                latitude,
                longitude,
                country,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    }

    async getAddressFromGeonegocios(address: string, ubigeo: string, country: string): Promise<IGeocode> {
        const response = await this.apiBase.post<IWithResponse<IGeocode>>(
            getEnvGetGeonegociosAddressEndpoint(),
            {
                address,
                ubigeo,
                country,
            },
        );

        const { data, message = 'Unknown error' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data as IGeocode;
    }
}


export default GeoNegiosMapApiService;
