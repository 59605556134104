import React, { FunctionComponent, useMemo } from 'react';

import Button from '@UIElements/Button';
import EmptyComponent from '@core/constants/EmptyComponent';
import { ConfirmModalAction, ConfirmModalType, IModalFunctionComponentPropsBase } from '@core/models/ModalWindow';
import CountdownWithCallback from '@components/main/Countdown/CountdownWithCallback';

import './includes/ConfirmModal.scss';
import { observer } from 'mobx-react';


export interface IConfirmModalProps extends IModalFunctionComponentPropsBase {
    readonly confirmationText: string;
    readonly confirmationType: ConfirmModalType;
    readonly confirmationTimeout: number | undefined;
    readonly confirmationDefaultAction: ConfirmModalAction | undefined;
}


const ConfirmModal: FunctionComponent<IConfirmModalProps> = ({
    confirmationText,
    confirmationType,
    confirmationTimeout,
    confirmationDefaultAction,
    success,
    t,
}) => {
    const confirmButtons = useMemo<JSX.Element>(
        () => {
            switch (confirmationType) {
                case ConfirmModalType.Yes: {
                    return (
                        <Button
                            variant="4"
                            size="1"
                            width="auto"
                            className="confirmModal__success-button"
                            onClick={success}
                            dataCyValue="confirmModal__success-button"
                        >
                            <div>
                                {t('Хорошо', 'OK')}
                                {!!confirmationTimeout && confirmationDefaultAction === ConfirmModalAction.Yes && (
                                    <>
                                        &nbsp;
                                        (
                                        <CountdownWithCallback
                                            seconds={confirmationTimeout}
                                            onComplete={success}
                                        />
                                        )
                                    </>
                                )}
                            </div>
                        </Button>
                    );
                }

                case ConfirmModalType.YesCancel: {
                    return (
                        <>
                            <Button
                                variant="4"
                                size="1"
                                width="auto"
                                className="confirmModal__success-button"
                                onClick={success}
                                dataCyValue="confirmModal__success-button"
                            >
                                <div>
                                    {t('Хорошо', 'OK')}
                                    {!!confirmationTimeout && confirmationDefaultAction === ConfirmModalAction.Yes && (
                                        <>
                                            &nbsp;
                                            (
                                            <CountdownWithCallback
                                                seconds={confirmationTimeout}
                                                onComplete={success}
                                            />
                                            )
                                        </>
                                    )}
                                </div>
                            </Button>
                            <Button
                                variant="1"
                                size="1"
                                width="auto"
                                className="confirmModal__cancel-button"
                                onClick={() => success(false)}
                                dataCyValue="confirmModal__cancel-button"
                            >
                                <div>
                                    {t('Отмена', 'Cancel')}
                                    {!!confirmationTimeout && confirmationDefaultAction === ConfirmModalAction.Cancel && (
                                        <>
                                            &nbsp;
                                            (
                                            <CountdownWithCallback
                                                seconds={confirmationTimeout}
                                                onComplete={() => success(false)}
                                            />
                                            )
                                        </>
                                    )}
                                </div>
                            </Button>
                        </>
                    );
                }

                default: {
                    return <EmptyComponent />;
                }
            }
        },
        [confirmationType, confirmationTimeout, confirmationDefaultAction],
    );

    return (
        <div className="confirmModal">
            <div className="confirmModal__content">
                <div className="confirmModal__field">
                    <strong>{confirmationText}</strong>
                </div>
                <div className="confirmModal__field">
                    {confirmButtons}
                </div>
            </div>
        </div>
    );
};


export default observer(ConfirmModal);
