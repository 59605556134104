import {
    Instance, types as t, SnapshotOut, SnapshotIn, getEnv,
} from 'mobx-state-tree';
import isNan from 'lodash/isNaN';
import { CustomerServiceProductModel } from '@models/mobx-state-tree/customerServiceProductModel.model';
import { IEnvCustomerStore } from '@store/customerModeStore';
import { OrderStatusesERPEnum, OrderStatusesTypeERPEnum } from '@api/order-api-service/models';
import OrderStatusesBase from '@core/models/OrderStatusesBase';
import { SmsFromErpModel } from '@models/mobx-state-tree/customer-mode/SmsFromErp.model';


const orderStatusesERP = Object
    .keys(OrderStatusesERPEnum)
    .filter((key) => !isNan(Number(key)));

export const CustomerServiceOrderStage2 = t
    .model('CustomerServiceOrderStage2', {
        id: t.identifier, // генерируемый v4() id
        orderId: t.string,
        final_price: t.maybeNull(t.number),
        customerFullName: t.maybeNull(t.string),
        customer_phone: t.string,
        customer_mobile: t.maybeNull(t.string),
        order_phones: t.maybeNull(t.string),
        createdAt: t.maybeNull(t.string),
        currency: t.maybeNull(t.string),
        countryId: t.number,
        countryFlag: t.string,
        products: t.map(CustomerServiceProductModel),
        shippingPrice: t.maybeNull(t.number),
        shippingName: t.maybeNull(t.string),
        deliveryFrom: t.maybeNull(t.string),
        deliveryTo: t.maybeNull(t.string),
        crmStatus: t.maybeNull(t.number),
        erpStatus: t.maybeNull(t.string),
        erpStatusId: t.enumeration(orderStatusesERP),
        smsListFromErp: t.maybeNull(t.array(SmsFromErpModel)),
    })
    .views((self) => ({
        get orderStatusesBase(): OrderStatusesBase {
            const { orderStatusesBase } = getEnv<IEnvCustomerStore>(self);

            return orderStatusesBase;
        },
    }))
    .views((self) => ({
        get allPhones(): string {
            return `${self.customer_mobile}${self.customer_phone}${self.order_phones}`;
        },

        get allProducts() {
            return [...self.products.values()];
        },

        get status(): string {
            const { crmStatus, erpStatus, orderStatusesBase } = self;

            return erpStatus || orderStatusesBase.getCrmStatusText(crmStatus);
        },

        get erpStatusType(): OrderStatusesTypeERPEnum {
            const { orderStatusesBase, erpStatusId } = self;

            return orderStatusesBase.getTypeStatusErp(Number(erpStatusId));
        },
    }));


export interface ICustomerServiceOrderStage2Model extends Instance<typeof CustomerServiceOrderStage2> {}
export interface ICustomerServiceOrderStage2SnapshotIn extends SnapshotIn<typeof CustomerServiceOrderStage2> {}
export interface ICustomerServiceOrderStage2SnapshotOut extends SnapshotOut<typeof CustomerServiceOrderStage2> {}
