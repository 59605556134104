import {
    types as t,
    Instance,
    SnapshotIn,
    SnapshotOut,
} from 'mobx-state-tree';

interface IVolatile {
    timerLiveConversation: null | NodeJS.Timeout;
}
export const BlockButtonNoAnswer = t
    .model('BlockButtonNoAnswer', {
        /**
         * Время на разгоров с клиентом после которого кнопка no answer блокируется
         */
        acceptTimeLimitForBlockNoAnswer: t.maybeNull(t.integer),
        /**
         * Блокировка кнопки No Answered
         */
        blockedNoAnswer: t.optional(t.boolean, false),
        /**
         * Время прошедщее с начала разговора
         */
        timeLivePassed: t.optional(t.number, 0),
    })
    .volatile(() => <IVolatile> {
        timerLiveConversation: null,
    })
    .actions((self) => ({

        setBlockNoAnswerTrue(): void {
            self.blockedNoAnswer = true;
        },

        incrementTimeLivePassed(): void {
            self.timeLivePassed++;
        },

        startTimerBlockNoAnswer(): void {
            if (self.acceptTimeLimitForBlockNoAnswer) {
                self.timerLiveConversation = setInterval(() => {
                    this.incrementTimeLivePassed();
                }, 1000);
            }
        },

        stopTimerBlockNoAnswer(): void {
            if (self.timerLiveConversation) {
                clearInterval(self.timerLiveConversation);
            }
            if (self.acceptTimeLimitForBlockNoAnswer && self.timeLivePassed >= self.acceptTimeLimitForBlockNoAnswer) {
                this.setBlockNoAnswerTrue();
            }
            self.timeLivePassed = 0;
        },

    }));


export interface IBlockButtonNoAnswerModel extends Instance<typeof BlockButtonNoAnswer> {}
export interface IBlockButtonNoAnswerModelSnapshotIn extends SnapshotIn<typeof BlockButtonNoAnswer> {}
export interface IBlockButtonNoAnswerModelSnapshotOut extends SnapshotOut<typeof BlockButtonNoAnswer> {}