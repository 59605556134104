import React, { FunctionComponent } from 'react';
import { useInstances } from 'react-ioc';
import { Store } from '@store/store';
import { observer } from 'mobx-react';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import CustomerServicePage from '@components/main/customer-service-page/CustomerServicePage';
import OrderPage from '@components/main/order-page/OrderPage';
import { DEFAULT_CURRENT_ORDER } from '@core/constants/defaultStoreItems';
import CustomerServicePageStage2 from '@components/main/customer-service-page-stage-2/CustomerServicePageStage2';
import isFeatureFlagCustomerService from '@core/helpers/isFeatureFlagCustomerService';


const IndexPage: FunctionComponent = () => {
    const [
        {
            currentUser: { mode },
            currentOrder: { id },
        },
    ] = useInstances(
        Store,
    );

    if (mode === UserModeEnum.CLIENT_SERVICE) {
        if (id !== DEFAULT_CURRENT_ORDER.id) {
            return <OrderPage />;
        }

        if (isFeatureFlagCustomerService()) {
            return <CustomerServicePageStage2 />;
        }

        return <CustomerServicePage />;
    }

    return <OrderPage />;
};


export default observer(IndexPage);
