import { IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import {
    useEffect, useState, useCallback,
} from 'react';
import { useInstances } from 'react-ioc';
import ProductsService from '@services/order/ProductsService';
import { Store } from '@store/store';
import BasketService from '@services/order/BasketService';
import { prepareCatalogItemForView } from './prepareCatalogItemForView';
import { IPartOfCatalogItemForPromoInCatalog, ITranslationsForPromoItem } from './models';

const INITIAL_PROMO_CATALOG_ITEM: IPartOfCatalogItemForPromoInCatalog = {
    name: '',
    image: '',
    type: ProductItemTypeEnum.REGULAR,
    subType: ProductItemSubTypeEnum.PROMO,
    price: 0,
};

const INITIAL_TRANSLATIONS: ITranslationsForPromoItem = {
    label: '',
    button_yet_added: '',
    button_can_be_added: '',
    basket_have_promo_item_alert: '',
    should_add_more_main_products_alert: '',
};

/**
 * Хук по управлению простым промо в конструкторе акций
 * @param item - модель товара
 */
export const useManagePromoForPromotionBuilder = (
    item: IProductItemModel,
) => {
    const [productsService, store, basketService] = useInstances(ProductsService, Store, BasketService);
    const [isYetInBasket, setIsYetInBasket] = useState<boolean>(false);
    const [preparedCatalogItemForView, setPreparedCatalogItemForView] = useState<IPartOfCatalogItemForPromoInCatalog>(INITIAL_PROMO_CATALOG_ITEM);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [preparedTranslations, setPreparedTranslations] = useState<ITranslationsForPromoItem>(INITIAL_TRANSLATIONS);
    const [isConditionForAPromo, setIsConditionForAPromo] = useState<boolean>(false);
    const [discountAmount, setDiscountAmount] = useState<number | null>(null);

    /**
     * Добавить в каталог
     */
    const onClick = useCallback(() => {
        basketService.addOptionalGiftOrPromoFromPromotionBuilderToBasket(item);
    }, [item]);

    /**
     * Если в корзине есть промо товар, то в каталоге все остальные становятся disabled
     */
    useEffect(() => {
        setDisabled(store.currentOrder.promoItemsBasketPromotionBuilder.length > 0);
    }, [store.currentOrder.promoItemsBasketPromotionBuilder]);

    /**
     * Если количество товаров в корзине изменилось, \
     * то проверяем нет ли этого промо уже в корзине, \
     * чтобы отметить его как уже добавленный
     */
    useEffect(() => {
        setIsYetInBasket(
            basketService.isThisSimplePromoExistsInBasketPromotionBuilder(item.partnerProductId),
        );
    }, [store.currentOrder.promoItemsBasketPromotionBuilder]);

    /**
     * Подготовить нужные для отображения в компоненте атрибуты в соответствии с интерфейсом
     */
    useEffect(() => {
        setPreparedCatalogItemForView(
            prepareCatalogItemForView(item),
        );
    }, [item]);

    /**
     * Подготовить список переводов для компонента и компонентов внутри него
     */
    useEffect(() => {
        setPreparedTranslations(
            store.currentOrder.preparedTranslationsForPromoItemInOffersForPromotionBuilder(item),
        );
    }, [store.currentOrder.t, store.currentOrder.quantityOfMainCartsInPromotionBuilderBasket]);

    /**
     * Устанавливаем можно ли добавлять промо товары в К.А.
     */
    useEffect(() => {
        setIsConditionForAPromo(
            productsService.isConditionForPromoCartsPromotionBuilder,
        );
    }, [productsService.isConditionForPromoCartsPromotionBuilder]);

    /**
     * Трекаем размер скидки
     */
    useEffect(() => {
        setDiscountAmount(
            item.discountAmount,
        );
    }, [item.discountAmount]);

    // замораживаем возвращаемые объекты (нельзя будет изменять вне хука)
    return [onClick, isYetInBasket, preparedCatalogItemForView, disabled, preparedTranslations, isConditionForAPromo, discountAmount] as const;
};
