import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { block } from 'bem-cn';

import BillingListWeeks
    from '@components/main/billing-list-page/billing-list-weeks/billing-list-weeks';

import './billing-list-chart.scss';

import { useSwitchWeeks } from '@components/main/billing-list-page/billing-list-hooks/useSwitchWeeks';
import BillingListNav from '@components/main/billing-list-page/billing-list-nav/billing-list-nav';
import BillingListMonths from '@components/main/billing-list-page/billing-list-months/billing-list-months';
import useSwitchMonths from '@components/main/billing-list-page/billing-list-hooks/useSwitchMonths';

const BillingListChart: FunctionComponent = () => {

    const [period, setPeriod] = useState<string>('weeks');
    const [periodNumber, setPeriodNumber] = useState<number>(2);

    const b = block('billing-list-chart');

    const { dates, weekDates } = useSwitchWeeks(periodNumber);
    const { monthsNames, allDatesMonths } = useSwitchMonths();

    return (
        <div className={b()}>

            <BillingListNav
                period={period}
                setPeriod={setPeriod}
                dates={dates}
                setPeriodNumber={setPeriodNumber}
                monthsNames={monthsNames}
            />
            {
                period === 'weeks'
                &&
                <BillingListWeeks
                    weekDates={weekDates}
                    dates={dates}
                />
            }
            {
                period === 'months'
                &&
                <BillingListMonths
                    allDatesMonths={allDatesMonths}
                    monthsNames={monthsNames}
                />
            }

        </div>

    );
};

export default observer(BillingListChart);
