import {
    types as t,
    Instance,
    SnapshotIn,
    SnapshotOut,
} from 'mobx-state-tree';
import { Language } from './language.model';


export const Country = t
    .model('Country', {
        id: t.identifier,
        name: t.string,
        charCode: t.maybeNull(t.string),
        phoneEditingRange: t.maybeNull(t.integer),
        minTimeWaitCall: t.optional(t.integer, 0),
        /**
         * Метка, что нужно сбросить звонок по достижению лимита PDD
         */
        pddLimitEndCall: t.optional(t.boolean, false),
        /**
         * Лимит Post Dial Delay
         */
        pddLimit: t.maybeNull(t.integer),
        statusAutomatizationActive: t.optional(t.boolean, false),
        /**
         * присвоить заказу статус 'NO_ANSWER', если при звонке у клиента BUSY
         */
        noAnswerIfBusy: t.optional(t.boolean, false),
        noAnswerAfterSec: t.optional(t.integer, 0),
        /**
         * Назначить повторный звонок через N мин.
         * Бэк сам регулирует эту настройку и назначит перезвон через N мин сам.\
         * Нам нужно только назначить заказу recall
         */
        recallToMin: t.maybeNull(t.integer),
        recallLimitSeconds: t.maybeNull(t.integer),
        stockAlertingEnabled: t.optional(t.boolean, false), // Уведомлять оператора об отсутствии товара на складе
        /**
         * Автоматически инициировать следующий звонок если оператор "готов"\
         * Геттер для свойства вынесен в currentOrder.startCallAutomatic\
         * _NOTE!_ **К этому property не рекомендуется обращаться на прямую**
         */
        startCallAutomatic: t.optional(t.boolean, false),
        notUseCallAutomatization: t.optional(t.boolean, false),
        /**
         * Количество звонков автонабора (должно быть >= 1)
         */
        automaticCallsAmount: t.optional(t.integer, 1),
        /**
         * Интервал между попытками звонков (0 = мгновенно)
         */
        automaticCallsInterval: t.optional(t.integer, 0),
        language: t.maybeNull(Language),
    });


export interface ICountryModel extends Instance<typeof Country> {}
export interface ICountryModelSnapshotIn extends SnapshotIn<typeof Country> {}
export interface ICountryModelSnapshotOut extends SnapshotOut<typeof Country> {}
