import React, {
    FunctionComponent,
} from 'react';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import I18NService from '@services/I18NService';
import { CustomerModeStore } from '@/app/store/customerModeStore';
import './Appeal.scss';
import CrossIconSvg from '../../../order-page/order-products/order-table/includes/CrossIconSvg';
import { jiraLinkRenderer } from './jiraLinkRenderer';


const Appeal: FunctionComponent = (): JSX.Element => {
    const [{ t }, customerMode] = useInstances(I18NService, CustomerModeStore);

    const { clientCard } = customerMode;
    const { selectedAppealForAView, clearAppealForAView } = clientCard;

    return (
        <div
            className="appeal__wrapper"
        >
            <div
                className="appeal__wrapper-close-button"
                onClick={clearAppealForAView}
            >
                <CrossIconSvg />
            </div>
            <div
                className="appeal__wrapper-content"
            >
                <div
                    className="appeal-header"
                >
                    <div
                        className="appeal-header-label"
                    >
                        {`${t('Обращение', 'Appeal')}: #`}
                    </div>
                    <div
                        className="appeal-header-content"
                    >
                        {selectedAppealForAView && selectedAppealForAView.id}
                    </div>
                </div>
                <div
                    className="appeal-title__table"
                >
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Ссылка Jira', 'Jira link')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        {selectedAppealForAView && selectedAppealForAView.jiraUrl && jiraLinkRenderer(selectedAppealForAView.jiraUrl)}
                        {selectedAppealForAView && !selectedAppealForAView.jiraUrl && '—'}
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Статус', 'Status')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        —
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Дата создания', 'Date of creation')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        {selectedAppealForAView && selectedAppealForAView.createdAt}
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Оператор', 'Operator')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        {selectedAppealForAView && selectedAppealForAView.operatorUserName}
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Имя клиента', 'Client name')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        {selectedAppealForAView && selectedAppealForAView.clientName}
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Тема обращения', 'Subject of the appeal')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        <div
                            className="appealSubject"
                        >
                            {selectedAppealForAView && selectedAppealForAView.appealSubject}
                        </div>
                    </div>
                    <div
                        className="appeal-title__table-label grid-item"
                    >
                        {`${t('Описание', 'Description')}:`}
                    </div>
                    <div
                        className="appeal-title__table-content grid-item"
                    >
                        <div
                            className="appealText"
                        >
                            {selectedAppealForAView && selectedAppealForAView.appealText.length > 0 && selectedAppealForAView.appealText}
                            {selectedAppealForAView && selectedAppealForAView.appealText.length === 0 && '—'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Appeal);
