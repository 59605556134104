import { IBasicOrderPrepareCatalogAndBasketError } from '@core/error/order-prepare-errors/BasicOrderPrepareCatalogAndBasketError';


class QuantityInSetIsNullError extends Error implements IBasicOrderPrepareCatalogAndBasketError {
    constructor(
        public orderId: number,
        public problemItems: any,
        public setId: number,
    ) {
        super();

        this.message = 'Количество товара в наборе не может быть null.';
    }
}


export default QuantityInSetIsNullError;
