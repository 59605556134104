import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './includes/Popup.scss';

interface IPopupWithoutAnimationsProps {
    isActive?: boolean;
    className?: string;
    children?: any;
    width?: string;
    autoHeightMax?: number;
}

const PopupWithoutAnimations: FunctionComponent<IPopupWithoutAnimationsProps> = (props) => (
    <div className="PopupWithoutAnimations__wrapper">
        <div className={cn('e-PopupWithoutAnimations', props.className, { active: props.isActive })}>
            <Scrollbars
                style={{ width: props.width || '100%' }}
                hideTracksWhenNotNeeded={true}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={props.autoHeightMax || 200}
                renderThumbVertical={(props) => <div {...props} className="thumb-vertical-promotions" />}
                renderThumbHorizontal={(props) => <div {...props} className="thumb-horizotnal-promotions" />}
            >
                {
                    props.children
                }
            </Scrollbars>
        </div>
    </div>
);

export default PopupWithoutAnimations;
