import { toFactory } from 'react-ioc';

import { Store } from '@store/store';
import PostProcessingService from '@services/PostProcessingService';
import LocalStorageService from '@core/services/LocalStorageService';
import { OrderFetchService, OrderService } from '@app/services';
import ModalService from '@core/services/ModalService';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import OrderApiService from '@api/order-api-service';


const PostProcessingServiceDI = (): any => [
    PostProcessingService,
    toFactory(
        [
            Store,
            LocalStorageService,
            OrderFetchService,
            ModalService,
            OrderService,
            InactivityOperatorStorageService,
            OrderApiService,
        ],
        (
            store: Store,
            localStorageService: LocalStorageService,
            orderFetchService: OrderFetchService,
            modalService: ModalService,
            orderService: OrderService,
            inactivityOperatorStorageService: InactivityOperatorStorageService,
            orderApiService: OrderApiService,
        ) => new PostProcessingService(
            store,
            localStorageService,
            orderFetchService,
            modalService,
            orderService,
            inactivityOperatorStorageService,
            orderApiService,
        ),
    ),
];


export default PostProcessingServiceDI;