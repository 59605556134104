import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { ProductItemTypeEnum, ProductItemSubTypeEnum } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import GiftSVG from '@components/resources/SVG/ProductLabels/GiftSVG';
import ProductSVG from '@components/resources/SVG/ProductLabels/ProductSVG';
import PromoSVG from '@components/resources/SVG/ProductLabels/PromoSVG';
import PromotionSVG from '@components/resources/SVG/ProductLabels/PromotionSVG';
import SetSVG from '@components/resources/SVG/ProductLabels/SetSVG';

import './includes/ProductLabel.scss';

export interface IProductLabelProps {
    productType: ProductItemTypeEnum | null;
    productSubType: ProductItemSubTypeEnum | null;
    isPromotion: boolean;
    text: string;
}

const ProductLabel: FunctionComponent<IProductLabelProps> = ({
    productType,
    productSubType,
    isPromotion,
    text,
}) => {
    if (isPromotion) {
        return (
            <div className={cn('product-label-wrapper', 'promotion')}>
                <div className="product-label__icon"><PromotionSVG /></div>
                <div className="product-label__name">{text}</div>
            </div>
        );
    }

    if (productType === ProductItemTypeEnum.DYNAMIC_SET || productType === ProductItemTypeEnum.STATIC_SET) {
        return (
            <div className={cn('product-label-wrapper', 'set')}>
                <div className="product-label__icon"><SetSVG /></div>
                <div className="product-label__name">{text}</div>
            </div>
        );
    }

    if (productSubType === ProductItemSubTypeEnum.MAIN) {
        return (
            <div className={cn('product-label-wrapper', 'product')}>
                <div className="product-label__icon"><ProductSVG /></div>
                <div className="product-label__name">{text}</div>
            </div>
        );
    }

    if (productSubType === ProductItemSubTypeEnum.GIFT) {
        return (
            <div className={cn('product-label-wrapper', 'gift')}>
                <div className="product-label__icon"><GiftSVG /></div>
                <div className="product-label__name">{text}</div>
            </div>
        );
    }

    if (productSubType === ProductItemSubTypeEnum.PROMO) {
        return (
            <div className={cn('product-label-wrapper', 'promo')}>
                <div className="product-label__icon"><PromoSVG /></div>
                <div className="product-label__name">{text}</div>
            </div>
        );
    }

    return <></>;
};

export default ProductLabel;
