import {
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';


export const Sip = t
    .model('Sip', {
        sipHost: t.maybeNull(t.string),
        sipPass: t.maybeNull(t.string),
        sipLogin: t.maybeNull(t.string),
    })
    .actions(((self) => ({
        setSipHost(value: string | null): void {
            self.sipHost = value;
        },
        setSipPass(value: string | null): void {
            self.sipPass = value;
        },
        setSipLogin(value: string | null): void {
            self.sipLogin = value;
        },
    })));


export interface ISipModel extends Instance<typeof Sip> {}
export interface ISipModelSnapshotIn extends SnapshotIn<typeof Sip> {}
export interface ISipModelSnapshotOut extends SnapshotOut<typeof Sip> {}
