import { Instance, SnapshotIn, SnapshotOut, types as t } from 'mobx-state-tree';

export const OptionFormAttribute = t
    .model('OptionFormAttribute', {
        label: t.string || t.integer,
        value: t.string || t.integer,
    });

export interface IOptionFormAttributeModel extends Instance<typeof OptionFormAttribute> {}
export interface IOptionFormAttributeSnapshotIn extends SnapshotIn<typeof OptionFormAttribute> {}
export interface IOptionFormAttributeSnapshotOut extends SnapshotOut<typeof OptionFormAttribute> {}
