import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';
import { CustomerServiceOrderCountry } from '@models/mobx-state-tree/CustomerServiceOrderCountry.model';
import { OrderType } from '@models/mobx-state-tree/orderType.model';
import { OrderSource } from '@models/mobx-state-tree/orderSource.model';
import {
    IOrderViewProductModel,
    OrderViewProductModel,
} from '@models/mobx-state-tree/orderViewProduct.model';

export const CustomerServiceOrder = t
    .model('CustomerServiceOrder', {
        id: t.identifierNumber,
        status: t.maybeNull(t.integer),
        statusName: t.maybeNull(t.string),
        customer_address: t.maybeNull(t.string),
        final_price: t.maybeNull(t.number),
        customerFullName: t.maybeNull(t.string),
        customer_phone: t.string,
        customer_mobile: t.maybeNull(t.string),
        order_phones: t.maybeNull(t.string),
        createdAt: t.maybeNull(t.string),
        currency: t.maybeNull(t.string),
        country: CustomerServiceOrderCountry,
        orderType: t.optional(OrderType, { id: 0, name: 'Unknown' }),
        source: t.optional(OrderSource, { id: 0 }),
        shippingPrice: t.maybeNull(t.number),
        products: t.optional(t.array(OrderViewProductModel), []),
        deliveryFrom: t.maybeNull(t.string),
        deliveryTo: t.maybeNull(t.string),
        shippingName: t.maybeNull(t.string),
    })
    .views((self) => ({
        get productsValues(): IOrderViewProductModel[] {
            return [...self.products.values()];
        },
    }));


export interface ICustomerServiceOrderModel extends Instance<typeof CustomerServiceOrder> {}
export interface ICustomerServiceOrderSnapshotIn extends SnapshotIn<typeof CustomerServiceOrder> {}
export interface ICustomerServiceOrderSnapshotOut extends SnapshotOut<typeof CustomerServiceOrder> {}
