import { action, transaction } from 'mobx';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import { v4 } from 'uuid';
import { applySnapshot } from 'mobx-state-tree';

import OrderApiService from '@api/order-api-service';
import PartnerApiService from '@api/partner-api-service';
import UserApiService from '@api/user-api-service';
import SupportApiService from '@api/support-api-service';
import CallApiService from '@api/call-api-service';

import { DEFAULT_CURRENT_ORDER } from '@core/constants/defaultStoreItems';

import { Store } from '@store/store';

import { ICurrentOrderSnapshotIn } from '@models/mobx-state-tree/currentOrder.model';
import { ICommunicationsQualityModelSnapshotIn } from '@models/mobx-state-tree/communicationsQuality.model';
import { ICallHistoryModelSnapshotIn } from '@models/mobx-state-tree/callHistory.model';
import { ISimilarOrderModelSnapshotIn } from '@models/mobx-state-tree/similarOrder.model';
import { IOrderCommentModelSnapshotIn } from '@models/mobx-state-tree/orderComment.model';
import { IOperatorInactivityNotificationTimersModelSnapshotIn } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import { PrepareTypes } from '@services/order/models';
import {
    IOrderForService,
    IOrder,
    IOrderProduct,
    IPartnerProductDynamicSetData,
    IPartnerProductSetAlternativesData,
    TSmsListFromErp, ISecureData,
} from '@api/order-api-service/models';
import { ICurrencyModelSnapshotIn } from '@models/mobx-state-tree/currency.model';
import { IOrderViewModelSnapshotIn } from '@models/mobx-state-tree/orderView.model';
import { IOrderViewProductModelSnapshotIn, IProductSetViewModelSnapshotIn } from '@models/mobx-state-tree/orderViewProduct.model';
import { IS_READY_STATUS_ENUM } from '@api/auth-api-service/models';
import { ICallHistory } from '@api/call-api-service/model';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import OrderPrepareCatalogAndBasket from '@/app/order-prepare/OrderPrepareCatalogAndBasket';
import OldPromotionApiService from '@api/old-promotion-api-service';
import PromotionBuilderApiService from '@api/promotion-builder-api-service';
import OrderPrepareMetadata from '@/app/order-prepare/OrderPrepareMetadata';
import IntermediateStateCatalogsAndBaskets from '@/app/order-prepare/IntermediateStateCatalogsAndBaskets';
import DeletedPromotionsAndProducts from '@/app/order-prepare/DeletedPromotionsAndProducts';
import ModalService from '@core/services/ModalService';
import DeletedPromotionsAndProductsInfo
    from '@components/main/order-page/modal-windows/deleted-promotions-and-products-info';
import { IAdditionalParamsCurrentOrderModelSnapshotIn } from '@models/mobx-state-tree/ui/additionalParamsCurrentOrder.model';
import { getDecription } from '@core/helpers/getDecription';

type TOrder = IOrder;
type TOrderForService = IOrderForService;
interface IFetchOrder {
    wasFetched: boolean;
    orderRes?: TOrder;
}

enum OrderType {
    Created = 2,
}


class OrderFetchService {
    orderPrepareMetadataState: OrderPrepareMetadata;

    constructor(
        private readonly store: Store,
        private readonly orderApiService: OrderApiService,
        private readonly partnerApiService: PartnerApiService,
        private readonly userApiService: UserApiService,
        private readonly supportApiService: SupportApiService,
        private readonly callApiService: CallApiService,
        private readonly inactivityOperatorStorageService: InactivityOperatorStorageService,
        private readonly _oldPromotionApiService: OldPromotionApiService,
        private readonly _promotionBuilderApiService: PromotionBuilderApiService,
        private readonly _modalService: ModalService,
    ) {
        this.orderPrepareMetadataState = new OrderPrepareMetadata();
    }

    private _prepareSecureData(secure: ISecureData): ISecureData {
        return Object.keys(secure).reduce((acc, key) =>
            ({ ...acc, [key]: getDecription(secure[key as keyof typeof secure]) }), {} as ISecureData);
    }

    @action
    private snapshotCurrentOrder(order: TOrderForService): void {

        const orderPrepareMetadataState = new OrderPrepareMetadata();

        orderPrepareMetadataState.maskPhones = order.form?.mask_phones || null;
        orderPrepareMetadataState.currentCountry = order.country;
        orderPrepareMetadataState.customerMainPhone = order.customer_phone;

        orderPrepareMetadataState.customerData = order.customer_components;

        const mainFields = orderPrepareMetadataState.prepareOrder(order);

        let currency: ICurrencyModelSnapshotIn | null = null;
        if (order?.currency?.char_code && order.currency.id) {
            currency = {
                id: order.currency.id,
                code: order.currency.char_code,
            };
        }

        let disableChangeOrderStatusButtonsBeforeCall = order.form?.disabled_buttons;
        if (this.store.currentUser.mode === UserModeEnum.CLIENT_SERVICE) {
            disableChangeOrderStatusButtonsBeforeCall = false;
        }

        const convertMsToDate = (ms: number | null): Date | null => {
            return ms ? new Date(ms * 1000) : null;
        };

        const currentOrder: ICurrentOrderSnapshotIn = {
            ...DEFAULT_CURRENT_ORDER,
            ...mainFields,
            currency,
            isACreatedOrder: order.isACreatedOrder,
            disableChangeOrderStatusButtonsBeforeCall,
            groupId: v4(),
            form: orderPrepareMetadataState.prepareForm(order),
            country: orderPrepareMetadataState.prepareCurrentCountry(order?.type?.id),
            subStatuses: orderPrepareMetadataState.convertSubStatusInfo(
                order.subStatusInfo,
            ),
            similarCount: order.similar_count,
            successPhone: order.success_phone,
            callAutomatization: orderPrepareMetadataState.prepareCallAutomatization(),
            callsCount: orderPrepareMetadataState.prepareCallsCount(order.limit_calls),
            blockButtonNoAnswer: orderPrepareMetadataState.prepareBlockButtonNoAnswer(order.country.accept_time_limit_for_block_noanswer),
            foreignId: order.foreign_id,
            postProcessing: order.post_processing,
            deliveryFrom: convertMsToDate(order.delivery_from),
            deliveryTo: convertMsToDate(order.delivery_to),
        };

        applySnapshot(this.store.currentOrder, currentOrder);
    }

    @action
    public async fetchSimilarOrders(orderId: number, maskPhones: boolean) {

        const similarOrders = await this.orderApiService.getSimilarOrders(orderId);

        const transformedSimilarOrders = this.orderPrepareMetadataState.prepareForCollection(
            map(similarOrders, (item) => ({
                ...item,
                mask_phone: maskPhones || false,
                customerComponents: item.secure.customer_components,
            })),
            PrepareTypes.SIMILAR_ORDERS,
        ) as Record<string, ISimilarOrderModelSnapshotIn>;

        applySnapshot(this.store.currentOrder.similarOrders, transformedSimilarOrders);

    }

    public async getSms(): Promise<void> {
        const { foreignId } = this.store.currentOrder;

        if (typeof foreignId === 'number') {
            try {
                // Получаем список СМС по заказу.
                // Заказа в ERP может не быть -> ошибку console.warn-им
                const smsListFromErp: TSmsListFromErp | null = await this.orderApiService.getSms(foreignId);
                const orderPrepareMetadataState = new OrderPrepareMetadata();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const smsList = smsListFromErp ? orderPrepareMetadataState._convertTextMessagesForModel(...Object.values(smsListFromErp.smsList), foreignId) : [];
                if (smsList?.length) {
                    applySnapshot(this.store.currentOrder.smsListFromErp, smsList);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn(e);
            }
        }
    }

    @action
    private snapshotInactivityTimer(order: TOrderForService): void {
        const userId = this.store.currentUser.id;
        const orderId = order.id;
        const viewFormFillTimePassed = this.inactivityOperatorStorageService.getStoredViewTimePassed(orderId, userId) || 0;
        const saveFormFillTimePassed = this.inactivityOperatorStorageService.getStoredSaveTimePassed(orderId, userId) || 0;
        const callDuration = this.inactivityOperatorStorageService.getStoredCallDuration(orderId, userId) || 0;

        const inactivityTimer: IOperatorInactivityNotificationTimersModelSnapshotIn = {
            orderId,
            userId,
            viewFormFillTimePassed,
            saveFormFillTimePassed,
            previousCallDuration: callDuration,
            viewFormFillTimeLimit: order.country.view_form_fill_timer || null,
            saveFormFillTimeLimit: null,
            saveFormFilterShortCall: order.country.save_form_filter_short_call || 60,
            saveFormFilterLongCall: order.country.save_form_filter_long_call || 480,
            saveFormFillDurationCall: order.country.save_form_filter_duration_call || 40,
        };

        applySnapshot(this.store.inactivityTimer, inactivityTimer);
    }

    @action
    public async downloadAdditionalOrderingDataAfterFirstStepCreation(orderRes: TOrder, created = false): Promise<void> {
        const orderId = orderRes.id;
        const countryId = orderRes.country_id;
        const partnerId = orderRes.partner_id;

        if (orderId && countryId && partnerId) {

            const secureData = this._prepareSecureData(orderRes.secure);
            const customerPhone = secureData.customer_phone;

            const orderForService = <TOrderForService> {
                ...orderRes,
                ...secureData,
                // created работает если заказ только создан - если заказ перезагружен created становится равной false,
                // orderRes.type?.id === OrderType.Created && customerPhone !== null - если заказ созданный, и был
                // получен после перезагрузки страницы и телефонный номер пустой, значит это созданный заказ,
                // котрый не успели заполнить
                isACreatedOrder: created || orderRes.type?.id === OrderType.Created && customerPhone === null,
            };

            transaction(() => {
                this.snapshotCurrentOrder(orderForService);
                this.snapshotInactivityTimer(orderForService);
            });

            await this.snapshotAdditionalParametersCurrentOrder(orderRes);

            /**
             * Если пользователь после смены статуса заказа
             * очень быстро переведет себя в unready,
             * иногда может быть так, что заказ успеет подргузиться,
             * а следом пользователь станет unready.
             * Для этого кейса меняем пользователя в UnreadyAfterSave.
             */
            const {
                isReady,
                setUnreadyAfterSave,
                setIsReady,
            } = this.store.currentUser;

            if (!isReady) {
                if (!created) {
                    setUnreadyAfterSave(true);
                }
                setIsReady(true);
                await this.userApiService.changeUserReady(IS_READY_STATUS_ENUM.READY);
            }
        }
    }

    @action async snapshotAdditionalParametersCurrentOrder(order: TOrder) {

        const { setLoadBasket, setLoadCallHistory } = this.store.currentOrder?.additionalParamsCurrentOrder;

        setLoadBasket(true);
        setLoadCallHistory(true);

        const orderId = order.id;
        const countryId = order.country_id;
        const partnerId = order.partner_id;

        const [
            partnerProducts,
            callHistory,
            comments,
            communicationQualityList,
        ] = await Promise.all([
            await this.partnerApiService.getPartnerProductList(countryId, partnerId, orderId),
            this.callApiService.getCallHistory(orderId),
            this.orderApiService.getOrderComments(orderId),
            this.supportApiService.getCommunicationQualityList(partnerId, countryId),
        ]);

        /**
             * Создаем временное состояние с промежуточными каталогами и корзинами
             */
        const intermediateStateCatalogsAndBaskets = new IntermediateStateCatalogsAndBaskets();

        /**
             * Создаем состояние с удаленными акциями и товарами из заказа, для дальнейшего оповещения об этом оператору
             */
        const deletedPromotionsAndProducts = new DeletedPromotionsAndProducts();

        const orderPrepareCatalogAndBasketState = new OrderPrepareCatalogAndBasket(
            intermediateStateCatalogsAndBaskets,
            deletedPromotionsAndProducts,
            this._oldPromotionApiService,
            this._promotionBuilderApiService,
        );

        await orderPrepareCatalogAndBasketState.prepareCatalogAndBasket(
            order.id,
            order.country_id,
            order.partner_id,
            order.type?.id,
            order.orderProducts,
            partnerProducts,
            order.form?.promoProducts || [],
            order.form?.use_promotion_builder || false,
            order.form?.consider_remains || false,
        );

        const currentOrder: IAdditionalParamsCurrentOrderModelSnapshotIn = {

            ...intermediateStateCatalogsAndBaskets.returnCatalogsAndBasketsToCurrentOrderModelFormat(),
            callHistory: this.orderPrepareMetadataState.prepareForCollection(
                callHistory,
                PrepareTypes.CALL_HISTORIES,
            ) as Record<string, ICallHistoryModelSnapshotIn>,
            comments: this.orderPrepareMetadataState.prepareForCollection(
                comments,
                PrepareTypes.ORDER_COMMENTS,
            ) as Record<string, IOrderCommentModelSnapshotIn>,
            communicationsQuality: this.orderPrepareMetadataState.prepareForCollection(
                communicationQualityList,
                PrepareTypes.COMMUNICATIONS_QUALITY,
            ) as Record<string, ICommunicationsQualityModelSnapshotIn>,
        };

        if (deletedPromotionsAndProducts.isRemoteProductsAndPromotions) {
            void this._modalService.showModal<any>(
                DeletedPromotionsAndProductsInfo,
                { model: deletedPromotionsAndProducts },
            );
        }

        applySnapshot(this.store.currentOrder.additionalParamsCurrentOrder, currentOrder);

        setLoadBasket(false);
        setLoadCallHistory(false);

    }


    @action
    async fetchOrderFromAutoCall(task: number): Promise<boolean> {
        const orderData = await this.orderApiService.getOrderFromAutoCall(task);

        const orderRes = orderData.order;

        if (!orderRes) {
            return false;
        }

        await this.downloadAdditionalOrderingDataAfterFirstStepCreation(orderRes);
        return true;
    }

    @action
    async fetchOrder(): Promise<IFetchOrder> {
        const orderData = await this.orderApiService.getOrder();

        const orderRes = orderData.order;

        if (!orderRes) {
            return { wasFetched: false };
        }

        await this.downloadAdditionalOrderingDataAfterFirstStepCreation(orderRes);

        return { wasFetched: true, orderRes };
    }

    @action
    private snapshotOrderView(orderRes: TOrder, callHistoryRes: ICallHistory[]): void {
        const orderPrepareMetadataState = new OrderPrepareMetadata();

        const customerComponents = orderPrepareMetadataState.prepareCustomerData(orderRes.customer_components);

        const products = reduce<IOrderProduct, IOrderViewProductModelSnapshotIn[]>(
            orderRes.orderProducts,
            (
                acc,
                {
                    product,
                    price,
                    promo,
                    gift,
                    quantity,
                    set,
                },
            ) => {
                let orderViewProduct: IOrderViewProductModelSnapshotIn = {
                    id: String(product.id),
                    image: product.partnerProduct?.image || '',
                    name: product.name || '',
                    price,
                    quantity,
                    gift: gift || false,
                    promo: promo || false,
                    isProductSet: product.partnerProduct?.is_product_set || false,
                    isDynamicSet: product.partnerProduct?.is_dynamic_set || false,
                };

                // Проверяем что это динамический набор
                const isDynamicSet = orderViewProduct.isDynamicSet && orderViewProduct.isProductSet;

                // Проверяем количество товаров в наборе для замены, и самих товаров
                const isNumberOfItemsMatchedSetsAndProducts = product.partnerProduct?.products?.length === set?.length;

                if (isDynamicSet && isNumberOfItemsMatchedSetsAndProducts) {
                    orderViewProduct = {
                        ...orderViewProduct,
                        products: map<any, IProductSetViewModelSnapshotIn>(
                            product.partnerProduct?.products || [],
                            (productFromPartnerProduct: IPartnerProductDynamicSetData, key: number) => {
                                // Товар из сетов, относительно индексов массивов с товарами и сетами
                                const productFromSet = set[key];

                                // Если product_id из сета совпадает с product_id из продукта партнеров, значит замены нет
                                if (productFromSet.product_id === productFromPartnerProduct.product_id) {
                                    return {
                                        id: String(productFromPartnerProduct.id),
                                        isPacking: productFromPartnerProduct.is_packing,
                                        productId: String(productFromPartnerProduct.product_id),
                                        quantity: Number(productFromPartnerProduct.quantity),
                                        name: productFromPartnerProduct.name,
                                    };
                                }

                                // Иначе ищем товар, на который заменили из альтернативных товаров
                                const productFromAlternatives = find<IPartnerProductSetAlternativesData>(
                                    productFromPartnerProduct.alternatives,
                                    (alternativesProductItem) => alternativesProductItem.product_id === productFromSet.product_id,
                                );

                                if (productFromAlternatives) {
                                    return {
                                        id: productFromAlternatives.id.toString(),
                                        name: productFromAlternatives.name,
                                        productId: productFromAlternatives.product_id.toString(),
                                        isPacking: productFromPartnerProduct.is_packing,
                                        quantity: productFromSet.quantity,
                                    };
                                }

                                // Выкидываем ошибку, что альтернативный товар не найден
                                throw new Error('No alternative product detected');
                            },
                        ),
                    };

                    acc.push(orderViewProduct);

                    return acc;
                }

                orderViewProduct = {
                    ...orderViewProduct,
                    products: map<any, IProductSetViewModelSnapshotIn>(
                        product.partnerProduct?.products || [],
                        (productItem) => ({
                            id: String(productItem.id),
                            isPacking: productItem.is_packing,
                            productId: String(productItem.product_id),
                            quantity: Number(productItem.quantity),
                            name: productItem.name,
                        }),
                    ),
                };

                acc.push(orderViewProduct);

                return acc;
            },
            [],
        );

        const callHistory = orderPrepareMetadataState.prepareForCollection(
            callHistoryRes,
            PrepareTypes.CALL_HISTORIES,
        ) as Record<string, ICallHistoryModelSnapshotIn>;

        const orderView: IOrderViewModelSnapshotIn = {
            id: orderRes.id,
            createdAt: orderRes.created_at,
            orderTypeName: orderRes.type?.name,
            countryName: orderRes.country.name,
            status: Number(orderRes.statusInfo?.id) || null,
            statusName: orderRes.statusInfo?.name || null,
            countryCharCode: orderRes.country.char_code,
            sourceName: orderRes.source?.name || null,
            customerFullName: customerComponents.customer_full_name || '—',
            customerAge: customerComponents.customer_age || '—',
            customerEmail: customerComponents.customer_email || '—',
            customerMobile: customerComponents.customer_mobile || '—',
            customerPhone: customerComponents.customer_phone || '—',
            customerApartmentNumber: customerComponents?.customer_apartment_number || '—',
            customerCity: customerComponents?.customer_city || '—',
            customerDistrict: customerComponents?.customer_district || '—',
            customerHouseNumber: customerComponents?.customer_house_number || '—',
            customerProvince: customerComponents?.customer_province || '—',
            customerState: customerComponents?.customer_state || '—',
            customerStreet: customerComponents?.customer_street || '—',
            customerZip: customerComponents?.customer_zip || '—',
            deliveryFrom: orderRes.delivery_from,
            deliveryTo: orderRes.delivery_to,
            shippingName: orderRes.shipping?.name || '—',
            shippingPrice: orderRes.shipping_price || 0,
            finalPrice: orderRes.final_price || 0,
            products,
            callHistory,
        };

        this.store.addOrderForAView(orderView);
    }

    @action
    public fetchOrderView = async (id: number): Promise<void> => {
        const [
            orderData,
            callHistoryData,
        ] = await Promise.all([
            this.orderApiService.getOrderViewById(id),
            this.callApiService.getCallHistory(id),
        ]);

        const orderRes = orderData.order;
        const callHistoryRes = callHistoryData;

        if (!orderRes) {
            return;
        }

        this.snapshotOrderView(orderRes, callHistoryRes);
    };
}


export default OrderFetchService;
