import CommunicationQualityApiService from '@core/api/communication-quality-api-service';
import ModalService from '@core/services/ModalService';
import { SipService } from '@services/index';
import PddLimitHandler from '@services/orderStatusAutomatization/PddLimitHandler';
import { Store } from '@store/store';
import { toFactory } from 'react-ioc';

const PddLimitHandlerDI = (): any => [
    PddLimitHandler,
    toFactory(
        [
            SipService,
            Store,
            CommunicationQualityApiService,
            ModalService,
        ],
        (
            sipService: SipService,
            store: Store,
            communicationQualityApiService: CommunicationQualityApiService,
            modalService: ModalService,
        ) => new PddLimitHandler(
            sipService,
            store,
            communicationQualityApiService,
            modalService,
        ),
    ),
];


export default PddLimitHandlerDI;
