import { Instance } from 'mobx-state-tree';
import { IRootStoreModel, RootStore } from '@models/mobx-state-tree/RootStore';
import ValidateService from '@core/services/ValidateService';
import I18NService from '@services/I18NService';
import InactivityOperatorStorageService from '@services/InactivityOperatorStorageService';
import BonusOrderConditionsBase from '@core/helpers/BonusOrderConditionsBase';


export interface IEnv {
    validateService: ValidateService;
    I18NService: I18NService;
    inactivityOperatorStorageService: InactivityOperatorStorageService;
    bonusOrderConditionsBase: BonusOrderConditionsBase;
}

export class Store {
    static create(
        validateService: ValidateService,
        I18NService: I18NService,
        inactivityOperatorStorageService: InactivityOperatorStorageService,
    ): IRootStoreModel {
        const env: IEnv = {
            validateService,
            I18NService,
            inactivityOperatorStorageService,
            bonusOrderConditionsBase: new BonusOrderConditionsBase(),
        };

        const StoreInstance = RootStore.create({}, env);

        StoreInstance.responsive.init();

        return StoreInstance;
    }
}


export interface Store extends Instance<typeof RootStore> {}
