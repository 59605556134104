import io from 'socket.io-client';

import { Store } from '@store/store';
import { IUserModel } from '@models/mobx-state-tree/user.model';
import { getEnvGetNodejsWebSocketEndpoint, getReactAppDevUrlApiHost } from '@/env/requestEndpoints';
import {
    getNodejsWsIsDev,
    getNodejsWsOn,
} from '@/env/getNodejsWsSettings';
import {
    PostProcessingService,
    SipService,
    UserService,
} from '@services/index';
import { DEFAULT_USER } from '@core/constants/defaultStoreItems';
import { autoCall } from '@core/constants/autoCall';
import {
    IHandlePostSetReject,
    IHandlePostStartTimerModalStatus,
    IPostStartLimitSave,
} from '@services/PostProcessingService';
import { Inject } from 'react-ioc';
import ApiBase from '@core/api';
import { ISip } from '@api/auth-api-service/models';
import NotificationApiService from '@api/notification-api-service';
import { NotificationModel } from '@models/mobx-state-tree/Notification.model';
import { NEED_RELOAD_PAGE } from '@core/constants/reloadPage';

interface IUnblockedOrderData {
    order_id: number;
    skip_notification: number | null;
}

interface IHandleChangeSipHostEvent {
    sip_platinum: ISip;
    sip_predictive: string | ISip;
}

/**
 * Сервис соединяется с node бэкенда \
 * \
 * **Node работает в 1 экземпляре для всех сред разработки.** \
 * \
 * Node предусматривает разделение только на PROD / DEV использование. \
 *
 * * Для DEV использования необходимо, чтобы в .ENV.DEVELOPMENT.LOCAL было прописано: \
 * REACT_APP_NODEJS_WS_ON=yes\
 * REACT_APP_NODEJS_WS_IS_DEV=yes
 *
 * * Для PROD использования необходимо, чтобы в .ENV.PRODUCTION было прописано: \
 * REACT_APP_NODEJS_WS_ON=yes\
 * REACT_APP_NODEJS_WS_IS_DEV=no
 */
class WebSocketService {
    @Inject _apiBase: ApiBase;

    private _socket: SocketIOClient.Socket | null;

    private _isDev: boolean;

    private get _currentUser(): IUserModel {
        return this._store.currentUser;
    }

    constructor(
        private readonly _store: Store,
        private readonly _sipService: SipService,
        private readonly _userService: UserService,
        private readonly _notificationApiService: NotificationApiService,
        private readonly _postProcessingService: PostProcessingService,
    ) {
        this._socket = null;
        // eslint-disable-next-line no-self-assign
        this._apiBase = this._apiBase;
        this._isDev = window.location.href === getReactAppDevUrlApiHost() || getNodejsWsIsDev(); // либо режим разработки
        // develop либо режим продакшен, но стенд develop;
    }

    private _handleChangePredictiveAutocallState = async (): Promise<void> => {
        const userData = await this._userService.userRequest();

        if (userData) {
            const predictiveAutocall = userData.user.predictive_autocall;

            this._currentUser.setChangeToPredictiveAutoCall(predictiveAutocall);
        }
    };

    private _handleChangeSipHost = async (e: IHandleChangeSipHostEvent): Promise<void> => {
        const {
            isAnIncomingCallToACustomer,
            isAnOutgoingCallToACustomer,
            isAnOutgoingCallToAOperator,
        } = this._sipService;

        this._currentUser.setSipHost(e?.sip_platinum?.sip_host);
        this._currentUser.setSipLogin(e?.sip_platinum?.sip_login);
        this._currentUser.setSipPass(e?.sip_platinum?.sip_pass);

        this._currentUser.setIsTimeChangedSipHost(true);
        console.log('change-sip-host');

        /*
         Если идет сессия звонка, то сообщаем сип-сервису, что по окончанию звонка нужно пересоздать соединение
        */
        if (isAnIncomingCallToACustomer || isAnOutgoingCallToACustomer || isAnOutgoingCallToAOperator) {
            this._sipService.setReconnectionRequired(true);

            return;
        }

        /*
         Создаем соединение с обновленным сип-хостом
        */
        await this._sipService.sip.connectToANewHost();

    };

    private _handleForcedPageReloadEvent = (): void => {
        if (this._store.currentOrder.isEmptyCurrentOrder){
            document.location.reload();
        } else {
            localStorage.setItem(NEED_RELOAD_PAGE, '1');
        }
    };

    private _handleOrderBlockFinishEvent = (value: IUnblockedOrderData): void => {
        // eslint-disable-next-line no-console
        console.info('An event \'order-block-finish\' has been caught: ', value);
        const autoCallValue = localStorage.getItem(autoCall);

        if (value?.skip_notification === 1) {
            this._store.setNotShowNotification(true);
        }

        if (value.order_id && !autoCallValue) {
            this._store.setUnblockedOrderId(value.order_id);
        }
        this._store.setUnblockedOrderId(null);
        this._store.setNotShowNotification(false);
    };

    private _handleSetTimeActivity = (): void => {
        console.log('EVENT ACTIVITY TIME FROM NODE: ', (new Date()).toString());
        void this._userService.startUserActivityPolling();
    };

    private _handleChangePracState = (value: {
        auto_call: number;
        predictive_autocall: number;
    }): void => {
        localStorage.setItem(autoCall, String(value.auto_call));
        void this._sipService.showChangeCallModeModal();
    };

    private _handlePostStartTimerModalStatus = (data: IHandlePostStartTimerModalStatus) => {
        this._postProcessingService.handlePostStartTimerModalStatus(data);
    };

    private _handlePostStopTimerModalStatus = async (): Promise<void> => {
        await this._postProcessingService.handlePostStopTimerModalStatus();
    };

    private _postStartLimitSave = (data: IPostStartLimitSave): void => {
        this._postProcessingService.postStartLimitSave(data);
    };

    private _handlePostSetReject = async (data: IHandlePostSetReject): Promise<void> => {
        await this._postProcessingService.handlePostSetReject(data);
    };

    private _getAux = async (): Promise<void> => {
        await this._userService.fetchAuxStatuses();
    };

    private _changeReadyState = async (): Promise<void> => {
        this._store.inactivityTimer.clearAuxStatusTimers();
        this._currentUser.setSelectedAuxStatus(null);
        await this._getAux();
        this._currentUser.setIsReady(false);
    };

    private _handleTokenExpired = async (): Promise<void> => {
        this._apiBase.refreshTokenLoading = true;
        await this._apiBase.updateTokenData();
        this._apiBase.refreshTokenLoading = false;
    };

    private _handleNewNotification = async (): Promise<void> => {
        const notification = await this._notificationApiService.get({ userId: this._store.currentUser.id });
        if (notification) {
            const notificationModel = NotificationModel.create({
                id: String(notification.id),
                message: notification.message,
                isFeedback: notification.is_feedback,
            });
            this._store.addNotification(notificationModel);
        }
    };

    private _init(): void {
        if (this._currentUser.id === DEFAULT_USER.id) {
            // eslint-disable-next-line no-console
            console.warn('Trying to connect to a websocket with default user id');
        }

        if (this._socket === null && getNodejsWsOn()) {
            this._socket = io.connect(getEnvGetNodejsWebSocketEndpoint(),
                {
                    transports: ['websocket'],
                    query: { user_id: this._isDev ? `dev_${this._currentUser.id}` : this._currentUser.id.toString() },
                });

            this._socket
                .emit('userdata', {
                    user_id: this._isDev ? `dev_${this._currentUser.id}` : this._currentUser.id.toString(),
                    is_dev: getNodejsWsIsDev() ? '1' : '0',
                })
                /*
                    Событие change-sip-host наступает тогда, когда поменялся host SIP.
                    Оно может наступить в любое время, например если в кол центре сервер изменили.
                    Но, например, при выключении предиктива, это событие не произойдет.
                */
                .on('change-sip-host', this._handleChangeSipHost)
                /*
                    Событие change-prac-state происходит в тот момент, когда на бэке запускается cron и выполяет job по добавлению/удалению пользователя в предиктив
                    По факту выполнения cron job на ноду падает уведомление о завершении и мы ловим событие change-prac-state
                    После совершения события нужно запросить информацию о юзере и проверить какое значение у него в predictive_autocall
                */
                /**
                     * Событие означает, что с заказа снята блокировка.
                     * Если orderId === номеру заказа,\
                     * с которым работает в UI оператор,\
                     * то мы должны закрыть заказ и сказать оператору,\
                     * что блокировка с заказа снята.\
                     * Пользователя переводим в unReady
                     * EVENT: order-block-finish
                 */
                // .on('change-prac-state', this._handleChangePredictiveAutocallState)
                .on('order-block-finish', this._handleOrderBlockFinishEvent)
                .on('ui-app-urgent-update', this._handleForcedPageReloadEvent)
                .on('ping-time-activity', this._handleSetTimeActivity)
                .on('change-prac-state', this._handleChangePracState)
                .on('post_start_timer_modal_status', this._handlePostStartTimerModalStatus)
                .on('post_stop_timer_modal_status', this._handlePostStopTimerModalStatus)
                .on('post_start_limit_save', this._postStartLimitSave)
                .on('post_set_reject', this._handlePostSetReject)
                .on('change-ready-state', this._changeReadyState)
                .on('get-aux', this._getAux)
                .on('new-notification', this._handleNewNotification)
                .on('token-expired', this._handleTokenExpired);
        }
    }

    public on(): void {
        this.off();
        this._init();
    }

    public off(): void {
        if (this._socket !== null) {
            this._socket
                .off('order-block-finish', this._handleOrderBlockFinishEvent)
                .off('ui-app-urgent-update', this._handleForcedPageReloadEvent)
                .off('ping-time-activity', this._handleSetTimeActivity)
                .off('change-prac-state', this._handleChangePracState)
                .off('post_start_timer_modal_status', this._handlePostStartTimerModalStatus)
                .off('post_stop_timer_modal_status', this._handlePostStopTimerModalStatus)
                .off('post_start_limit_save', this._postStartLimitSave)
                .off('post_set_reject', this._handlePostSetReject)
                .off('change-ready-state', this._changeReadyState)
                .off('get-aux', this._getAux)
                .off('new-notification', this._handleNewNotification)
                .off('token-expired', this._handleTokenExpired);
            this._socket = null;
        }
    }
}


export default WebSocketService;
