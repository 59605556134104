import ApiBase from '@core/api';
import get from 'lodash/get';
import { getEnvGetOldPromotionList } from '@/env/requestEndpoints';
import {
    IGetOldPromotionListByProduct,
    IOldPromotionData,
    IOldPromotionListApiArgs,
} from '@api/old-promotion-api-service/models';
import { Inject } from 'react-ioc';


interface IWithResponse<T> {
    status: 'success' | 'error' | 'fail';
    data: T;
    message?: string;
}


class OldPromotionApiService {
    @Inject apiBase: ApiBase;

    public getOldPromotionListByProducts: IGetOldPromotionListByProduct = async (
        partnerId,
        countryId,
        typeId,
        productIds,
    ) => {
        if (productIds.length === 0) {
            return [];
        }

        const dataForSend: IOldPromotionListApiArgs = {
            partner_id: partnerId,
            country_id: countryId,
            type_id: typeId,
            product_id: productIds,
        };

        const response = await this.apiBase.get<IWithResponse<IOldPromotionData[]>>(
            getEnvGetOldPromotionList(),
            dataForSend,
        );

        const { data, message = 'Unknown error.' } = response;

        const isSuccess = get(response, 'status') === 'success';

        if (!isSuccess) {
            throw new Error(message);
        }

        return data;
    };
}


export default OldPromotionApiService;
