import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import each from 'lodash/each';
import { useInstances } from 'react-ioc';
import I18NService from '@services/I18NService';
import ProductsService from '@/app/services/order/ProductsService';
import BasketService from '@/app/services/order/BasketService';
import { Store } from '@store/store';

import PromotionBuilderNomenclatures from './PromotionBuilderNomenclatures';

interface IOrderTablePBProps {
    readonly isShow: boolean;
}


const OrderTablePromotionBuilder: FunctionComponent<IOrderTablePBProps> = ({
    isShow = false,
}) => {
    const [
        {
            currentUser: {
                canEditProductPrice,
            },
            currentOrder: {
                appliedPromotionBuilder,
                mainItemBasketPromotionBuilder,
                mandatoryGiftsBasketPromotionBuilder,
                optionalGiftsBasketPromotionBuilder,
                promoItemsBasketPromotionBuilder,
                _removePromotionBuilderItem,
            },
        },
        { t },
        {
            isAllOptionalGiftsChoosedPromotionBuilder,
            canChoosePromoToPromotionBuilder,
            isConditionForPromoCartsPromotionBuilder,
        },
        {
            addOptionalGiftOrPromoFromPromotionBuilderToBasket,
        },
    ] = useInstances(
        Store,
        I18NService,
        ProductsService,
        BasketService,
    );

    useEffect(() => {
        // если есть промо-товар в корзине К.А. и условие для добавление промо более не актуально, то удаляем промо-товар из корзины
        if (promoItemsBasketPromotionBuilder.length > 0 && !isConditionForPromoCartsPromotionBuilder) {
            // промо-товар должен быть всего 1, но т.к. массив и могут измениться условия в К.А., сделано через each
            each(
                promoItemsBasketPromotionBuilder,
                (promoItem) => {
                    _removePromotionBuilderItem(promoItem.id);
                },
            );
        }
    }, [isConditionForPromoCartsPromotionBuilder, promoItemsBasketPromotionBuilder.length]);

    useEffect(() => {
        /**
         * Если в К.А. all_promo_mandatory = true и в массиве с промо товарами всего 1 промо
         * и условия для добавление промо товаров = true
         * То мы должны добавить такой промо товар в корзину и сделать акцент на этом событии
         * При добавлении такого промо-товара нужно поставить ему метку mandatoryPromo
         */
        if (appliedPromotionBuilder
            && appliedPromotionBuilder.allPromoMandatory
            && appliedPromotionBuilder.promoProducts.length === 1
            && promoItemsBasketPromotionBuilder.length === 0
            && isConditionForPromoCartsPromotionBuilder
        ) {
            addOptionalGiftOrPromoFromPromotionBuilderToBasket(
                appliedPromotionBuilder.promoProducts[0],
                { mandatoryPromo: true },
            );
        }
    }, [
        appliedPromotionBuilder,
        isConditionForPromoCartsPromotionBuilder,
        promoItemsBasketPromotionBuilder.length,
    ]);

    useEffect(() => {
        const [promoItem] = promoItemsBasketPromotionBuilder;

        if (promoItem?.mandatoryPromo) {
            void promoItem.highlightOnAddMandatoryPromoToPromotionBuilder();
        }
    }, [promoItemsBasketPromotionBuilder.length]);

    return (
        <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
            <div className="currentProducts__table-head">
                <div className="currentProducts__table-col currentProducts__table-col-4">
                    {t('Товар', 'Product')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Количество', 'Quantity')}
                </div>
                <div
                    className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                >
                    {t('Цена', 'Price')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Итого', 'Total')}
                </div>
            </div>
            <div className="currentProducts__table-container">
                <div
                    data-cy="promotion_builder_item"
                    className="currentProducts__table-container-rows"
                >
                    {mainItemBasketPromotionBuilder && (
                        <PromotionBuilderNomenclatures
                            mainItem={mainItemBasketPromotionBuilder}
                            mandatoryGifts={mandatoryGiftsBasketPromotionBuilder}
                            optionalGifts={optionalGiftsBasketPromotionBuilder}
                            promoProduct={promoItemsBasketPromotionBuilder}
                            canEditProductPrice={canEditProductPrice}
                            canAddOptionalPromo={canChoosePromoToPromotionBuilder}
                            canAddOptionalGift={!isAllOptionalGiftsChoosedPromotionBuilder}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(OrderTablePromotionBuilder);
