import { useResizeObserver } from '@/utils/useResizeObserver';
import {
    CSSProperties,
    useState,
} from 'react';

export interface ITopProperty extends CSSProperties {
    top: number;
}

export const useTopFloatingNotificationPortalHeight = (): ITopProperty => {
    const TopFloatingNotificationPortal = document.getElementById('top-floating-notification-portal');
    const [h, setH] = useState<number>(0);

    const handleSetNotificationBlockHeight = (entry: DOMRectReadOnly) => {
        setH(entry.height);
    };

    // Access the width and the height returned from the observed element.
    useResizeObserver(TopFloatingNotificationPortal, handleSetNotificationBlockHeight);

    // -8 делаем из-за padding-a в классе .orderPage__wrapper .order-page {...
    return ({ top: h - 8 });
};
