import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcRejectStatusSvg {
    className?: string;
}


const IcRejectStatusSvg: FunctionComponent<IIcRejectStatusSvg> = ({ className, ...props }): JSX.Element => (
    <svg
        className={cn(['IcRejectStatusSvg', className])}
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.45988 17.37C12.0899 19.45 15.3499 20.76 18.9099 20.98C20.0399 21.05 20.9799 20.11 20.9799 18.98V17.25C20.9899 16.24 20.2299 15.39 19.2199 15.27L16.6799 14.98C16.0699 14.91 15.4699 15.12 15.0399 15.55L13.1999 17.39C12.3899 16.98 11.6099 16.49 10.8899 15.94L21.8999 4.93002C22.2899 4.54002 22.2899 3.91002 21.8999 3.52002C21.5099 3.13002 20.8699 3.13002 20.4799 3.52002L3.50988 20.5C3.11988 20.89 3.11988 21.52 3.50988 21.91C3.89988 22.3 4.52988 22.3 4.91988 21.91L9.45988 17.37ZM6.60988 10.8L8.45988 8.95002C8.88988 8.52002 9.09988 7.92002 9.02988 7.31002L8.73988 4.79002C8.61988 3.78002 7.76988 3.02002 6.74988 3.02002H5.01988C3.88988 3.02002 2.94988 3.96002 3.01988 5.09002C3.23988 8.66002 4.55988 11.92 6.63988 14.56L8.06988 13.13C7.51988 12.4 7.02988 11.62 6.60988 10.8Z"
            fill="#FF3B30"
        />
    </svg>

);


export default IcRejectStatusSvg;
