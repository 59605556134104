import { toFactory } from 'react-ioc';

import I18NService from '@services/I18NService';
import DevicesService from '@core/services/DevicesService';
import ErrorService from '@core/services/ErrorService';


const DevicesServiceDI = (): any => [
    DevicesService,
    toFactory(
        [
            I18NService,
            ErrorService,
        ],
        (
            I18NService: I18NService,
            errorService: ErrorService,
        ) => new DevicesService(
            I18NService,
            errorService,
        ),
    ),
];


export default DevicesServiceDI;
