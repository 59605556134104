import {
    flow,
    getEnv,
    getParentOfType,
    Instance,
    SnapshotIn,
    SnapshotOut,
    types as t,
} from 'mobx-state-tree';
import currency from 'currency.js';

import {
    ProductItemModel,
    ProductItemSubTypeEnum, ProductItemTypeEnum,
} from '@models/mobx-state-tree/newModels/ProductItem.model';
import {
    ISetProductComposition,
    ISetProductCompositionSnapshotIn,
    SetProductComposition,
} from '@models/mobx-state-tree/newModels/SetProductComposition';
import { customI18NTFunction } from '@services/I18NService';
import { IEnv } from '@store/store';
import { MAX_ITEMS_COUNT } from '@core/constants/limits';
import { CurrentOrder } from '@models/mobx-state-tree/currentOrder.model';


export type TBasketOptionalParams = {
    quantity?: number;
    replacedBy?: string | null;
    replaces?: string | null;
    setProductCompositions?: Record<string, ISetProductCompositionSnapshotIn> | null;
    promotionId?: string | null;
    promotionBuilderId?: number | null;
    promotionMasterBasketItemId?: string | null;
    mandatoryGift?: boolean;
    mandatoryPromo?: boolean;
    price?: number;
};


export const BasketItemModel = t
    .model('BasketItemModel', {
        id: t.identifier, // генерируемый идентификатор uuid\v4
        existingOrderProductId: t.maybeNull(t.number), // Идентификатор товара в заказе, т.е. он уже был в заказе и мы его редактируем
        main: t.optional(t.boolean, false), // является ли товаром лида (Товар является основным в заказе)
        withLead: t.optional(t.boolean, false),
        editable: t.optional(t.boolean, true),
        price: t.number, // Стоимость 1 ед товара
        quantity: t.number, // Количество товара в корзине
        productItem: t.reference(ProductItemModel),
        editablePrice: t.optional(t.boolean, false), // Можно ли редактировать цену этого товара
        removed: t.optional(t.boolean, false),
        replacedBy: t.maybeNull(t.string), // заменен на BasketItemModel.id
        replaces: t.maybeNull(t.string), // заменяет BasketItemModel.id
        setProductCompositions: t.maybeNull(t.map(SetProductComposition)), // Возможно измененный состав сета, если товар типа статического или динамического сета, иначе null
        promotionName: t.maybeNull(t.string), // имя акции
        promotionId: t.maybeNull(t.string), // Акция по которой добавлен этот товар
        promotionMasterBasketItemId: t.maybeNull(t.string), // Продукт к которому была применена акция и на основании чего этот товар появился в корзине. Указывается для подарков и промо товаров.
        /**
         * Блок специфичный для promotionBuilder
         */
        /**
         * Является ли подарок обязательным
         */
        mandatoryGift: t.optional(t.boolean, false),
        /**
         * Является ли промо обязательным
         */
        mandatoryPromo: t.optional(t.boolean, false),
        /**
         * Конструктор акций по которому добавлен этот товар
         */
        promotionBuilderId: t.maybeNull(t.number),
        highlightOnAddToPromotionBuilderBasketPromo: t.optional(t.boolean, false),
    })
    .views(((self) => ({
        getSetProductCompositions(): ISetProductComposition[] | null {
            if (!self.setProductCompositions) {
                return null;
            }

            return [...self.setProductCompositions.values()];
        },
    })))
    .views(((self) => ({
        get t(): customI18NTFunction {
            const { I18NService: { t } } = getEnv<IEnv>(self);

            return t;
        },

        get totalPrice(): number {
            if (self.productItem.subType === ProductItemSubTypeEnum.GIFT) {
                return 0; // но и так, по идее, в моделе ставим 0
            }

            return currency(this.promotionBuilderPrice).multiply(self.quantity).value;
        },

        get isPromoApplied(): boolean {
            return !!self.promotionId;
        },

        get promotionBuilderPrice(): number {
            return self.productItem.mainProductPrice ?
                self.productItem.mainProductPrice : self.price;
        },
    })))
    .views((self) => ({
        get abilityToAddAPromo(): boolean {
            const {
                isPromoApplied,
                productItem: {
                    type,
                },
            } = self;

            // недопустимый тип
            const impermissibleType = type === ProductItemTypeEnum.DYNAMIC_SET
                || type === ProductItemTypeEnum.STATIC_SET
                || type === ProductItemTypeEnum.SET_COMPOSITION_ITEM;

            return !isPromoApplied && !impermissibleType;
        },
    }))
    .actions((self) => ({
        setPromotionBuilderId(value: null | number): void {
            self.promotionBuilderId = value;
        },

        removePromo(): void {
            self.promotionId = null;
        },

        hideInRow(): void {
            self.removed = true;
        },

        showInRow(): void {
            self.removed = false;
        },

        setPromotionId(promotionId: string): void {
            self.promotionId = promotionId;
        },

        reduceAmount(): void {
            let temporaryQuantity = self.quantity;

            if (temporaryQuantity === 1 && !self.editable) {
                return;
            }

            if (!self.removed) {
                if (
                    self.productItem.subType === ProductItemSubTypeEnum.PROMO
                    && self.quantity === 1
                ) {
                    self.quantity = 0;
                    return;
                }

                temporaryQuantity--;

                if (temporaryQuantity <= 0) {
                    this.hideInRow();
                    return;
                }

                self.quantity--;
            }
        },

        setAmount(value: number): void {
            if (!self.removed) {
                if (value > 1 && self.productItem.subType === ProductItemSubTypeEnum.PROMO) {
                    self.quantity = 1;
                    return;
                }
                const currentOrder = getParentOfType(self, CurrentOrder);
                const { form } = currentOrder;

                /**
                 * Опция по ОПТ-у касается только основных подарков (subType === ProductItemSubTypeEnum.MAIN)
                 *
                 * Лимит задается либо из формы, после которого считать опт или по умолчанию из MAX_ITEMS_COUNT
                 * по идее можно просто брать form.wholesaleLimit т.к. у него дефолт MAX_ITEMS_COUNT, но может не быть формы.
                 */
                const limit = form && form.wholesaleLimit ? form.wholesaleLimit : MAX_ITEMS_COUNT;


                if (self.productItem.subType === ProductItemSubTypeEnum.MAIN) {
                    if (value >= limit) {
                        if (form && form.wholesale) {
                            // Если формой предусмотрено включение продажи оптом
                            if (!form.wholesaleEnabled) {
                                // Опт предусмотрен, но не включен, спросим о включении опта оператора
                                form.needShowWholesaleConfirm(self.id, value);
                                // Значение не меняем
                                return;
                            }

                            // Опт включен, ограничений нет
                            self.quantity = value;
                            return;
                        }
                    }

                    self.quantity = value >= limit ? limit : value;

                    if (self.quantity === 0) {
                        this.hideInRow();
                    }

                    return;
                }

                // для остальных товаров количество товара в номенклатуре берется не из опта
                self.quantity = value >= MAX_ITEMS_COUNT ? MAX_ITEMS_COUNT : value;

                if (self.quantity === 0) {
                    this.hideInRow();
                }
            }
        },

        setPrice(price: number): void {
            if (!self.removed) {
                if (price < 0) {
                    throw new Error(self.t(
                        'Цена товара не может быть отрицательной',
                        'Product price can`t be negative',
                    ));
                }

                self.price = price;
            }
        },

        setQuantity(value: number): void {
            self.quantity = value;
        },

        initiatePriceWithoutPromotion(): void {
            if (self.productItem.price) {
                self.price = self.productItem.price;
            }
        },
    }))
    .actions((self) => ({
        setPriceAfterDeletingPromotionBuilder(price: number) {
            self.setPrice(price);
        },
    }))
    .actions((self) => ({
        addAmount(): void {
            if (!self.removed) {
                if (
                    self.productItem.subType === ProductItemSubTypeEnum.PROMO
                    && self.quantity >= 1
                ) { // todo: для динамических и статических приходит promo = true => более одного товара не добавить :)
                    return;
                }

                const newQuantity = self.quantity + 1;
                self.setAmount(newQuantity);
            }
        },
    }))
    /**
     * Блок экшенов для конструктора акций
     */
    .actions((self) => ({
        setAmountPromotionBuilder(value: number): void {
            if (self.productItem.type === ProductItemTypeEnum.REGULAR
                    && self.productItem.subType === ProductItemSubTypeEnum.MAIN
                    && self.productItem.amountToAdd
                    && value < self.productItem.amountToAdd
            ) {
                return;
            }

            if (!self.removed) {
                const currentOrder = getParentOfType(self, CurrentOrder);
                const { form } = currentOrder;

                /**
                     * Опция по ОПТ-у касается только основных подарков (subType === ProductItemSubTypeEnum.MAIN)
                     *
                     * Лимит задается либо из формы, после которого считать опт или по умолчанию из MAX_ITEMS_COUNT
                     * по идее можно просто брать form.wholesaleLimit т.к. у него дефолт MAX_ITEMS_COUNT, но может не быть формы.
                     */
                const limit = form && form.wholesaleLimit ? form.wholesaleLimit : MAX_ITEMS_COUNT;


                if (self.productItem.subType === ProductItemSubTypeEnum.MAIN) {
                    if (value >= limit) {
                        if (form && form.wholesale) {
                            // Если формой предусмотрено включение продажи оптом
                            if (!form.wholesaleEnabled) {
                                // Опт предусмотрен, но не включен, спросим о включении опта оператора
                                form.needShowWholesaleConfirm(self.id, value);
                                // Значение не меняем
                                return;
                            }

                            // Опт включен, ограничений нет
                            self.quantity = value;
                            return;
                        }
                    }

                    self.quantity = value >= limit ? limit : value;

                    if (self.quantity === 0) {
                        self.quantity = 1;
                    }

                    return;
                }

                if (self.productItem.maxAmount) {
                // для остальных товаров количество товара в номенклатуре берется из maxAmount
                    self.quantity = value >= self.productItem.maxAmount ? self.productItem.maxAmount : value;
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(`В конструкторе акций для ${self.productItem.subType} нет атрибута maxAmount.`);
                }


                if (self.quantity === 0) {
                    self.quantity = 1;
                }
            }
        },
        reduceAmountPromotionBuilder(): void {
            if (self.productItem.type === ProductItemTypeEnum.REGULAR
                && self.productItem.subType === ProductItemSubTypeEnum.MAIN
                && self.productItem.amountToAdd
                && self.quantity === self.productItem.amountToAdd
            ) {
                return;
            }

            let temporaryQuantity = self.quantity;

            temporaryQuantity--;

            if (temporaryQuantity <= 0) {
                return;
            }

            self.quantity--;
        },
    }))
    .actions((self) => ({
        addAmountPromotionBuilder(): void {
            const newQuantity = self.quantity + 1;
            self.setAmountPromotionBuilder(newQuantity);
        },
        setHighlightOnAddToPromotionBuilderBasketPromo(value: boolean): void {
            self.highlightOnAddToPromotionBuilderBasketPromo = value;
        },
    }))
    .actions((self) => ({
        highlightOnAddMandatoryPromoToPromotionBuilder: flow(function* (): Generator<Promise<void>> {
            self.setHighlightOnAddToPromotionBuilderBasketPromo(true);

            yield new Promise(() => setTimeout(
                () => self.setHighlightOnAddToPromotionBuilderBasketPromo(false), 200,
            ));
        }),
    }));


export interface IBasketItemModel extends Instance<typeof BasketItemModel> {}
export interface IBasketItemModelSnapshotIn extends SnapshotIn<typeof BasketItemModel> {}
export interface IBasketItemModelSnapshotOut extends SnapshotOut<typeof BasketItemModel> {}
