import React, { forwardRef } from 'react';
import { Portal } from 'react-overlays';


// From https://github.com/Hacker0x01/react-datepicker/issues/969
const CalendarContainer = forwardRef(({ children }, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Portal
        ref={ref}
    >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {children}
    </Portal>
));


export default CalendarContainer;
