import { action, computed, observable } from 'mobx';

import { Store } from '@store/store';
import LibreSpeed from '@/speedtest/LibreSpeed';
import SpeedTestServer from '@/speedtest/SpeedTestServer';


export type TProvideInternetConnectivity = {
    downloadSpeed: number;
    ping: number;
    uploadSpeed: number;
    jitter: number;
};

export enum InternetSpeedTestStatusEnum {
    SUCCESS,
    MEASUREMENT_PROCESS,
    FAILED,
}

export type TInternetSpeedProblems = 'yes' | 'no' | 'unclear';


class InternetSpeedMetricService {
    @observable
    public isNetworkDown: boolean;

    @observable
    public internetSpeedProblems: TInternetSpeedProblems;

    @observable
    public internetSpeedTestStatus: InternetSpeedTestStatusEnum;

    @computed
    public get isProcessOfMeasuringSpeed(): boolean {
        return this.internetSpeedTestStatus === InternetSpeedTestStatusEnum.MEASUREMENT_PROCESS;
    }

    @computed
    public get isInternetSpeedIsUnsatisfactory(): boolean {
        return this.internetSpeedProblems === 'yes' || this.internetSpeedProblems === 'unclear';
    }

    constructor(
        private readonly _store: Store,
    ) {

        window.addEventListener(
            'offline',
            () => {
                this.isNetworkDown = true;
            },
        );

        window.addEventListener(
            'online',
            () => {
                this.isNetworkDown = false;
            },
        );

        this.internetSpeedProblems = 'unclear';
    }

    @action
    public setInternetSpeedProblems(val: TInternetSpeedProblems): void {
        this.internetSpeedProblems = val;
    }

    @action
    public toMeasureSpeedWithLibreSpeed(serverName: string): Promise<TProvideInternetConnectivity> {
        return new Promise(
            (resolve, reject) => {
                const server = SpeedTestServer.create(serverName);

                const libreSpeed = new LibreSpeed();

                const report: TProvideInternetConnectivity = {
                    downloadSpeed: 0,
                    uploadSpeed: 0,
                    ping: 0,
                    jitter: 0,
                };

                libreSpeed.addTestPoint(server);
                libreSpeed.setSelectedServer(server);

                libreSpeed.onUpdate = ({
                    jitterStatus,
                    ulStatus,
                    dlStatus,
                    pingStatus,
                }) => {
                    if (libreSpeed.state === 3) {
                        this.internetSpeedTestStatus = InternetSpeedTestStatusEnum.MEASUREMENT_PROCESS;
                    } else {
                        this.internetSpeedTestStatus = InternetSpeedTestStatusEnum.FAILED;
                    }

                    report.jitter = Number(jitterStatus);
                    report.ping = Number(pingStatus);
                    report.downloadSpeed = Number(dlStatus);
                    report.uploadSpeed = Number(ulStatus);
                };

                libreSpeed.onEnd = (aborted) => {
                    if (!aborted) {
                        this.internetSpeedTestStatus = InternetSpeedTestStatusEnum.SUCCESS;

                        resolve(report);
                    } else {
                        this.internetSpeedTestStatus = InternetSpeedTestStatusEnum.FAILED;

                        reject();
                    }
                };

                if (libreSpeed.state === 3) {
                    libreSpeed.abort();
                } else {
                    this.internetSpeedProblems = 'unclear';

                    libreSpeed.start();
                }
            },
        );
    }
}


export default InternetSpeedMetricService;
