import {
    applySnapshot,
    IAnyModelType,
    IAnyType,
    IMSTMap,
    Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

import { IPartnerModel, PartnerModel } from '@models/mobx-state-tree/partner.model';
import { ISourceModel, SourceModel } from '@models/mobx-state-tree/source.model';
import { Country, ICountryModel, ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';
import { ISelectedValue } from '@UIElements/Select/models';
import { ISourceTypeModel, ISourceTypeModelSnapshotIn, SourceTypeModel } from '@models/mobx-state-tree/sourceType';
import { CurrencyModel, ICurrencyModel, ICurrencyModelSnapshotIn } from './currency.model';
import find from 'lodash/find';


export const OrderCreation = t
    .model('OrderCreation', {
        sources: t.map(SourceModel),
        partners: t.map(PartnerModel),
        countries: t.map(Country),
        sourceTypes: t.map(SourceTypeModel),
        currencies: t.map(CurrencyModel),
        selectedSourceId: t.maybeNull(t.string),
        selectedCountryId: t.maybeNull(t.string),
        selectedPartnerId: t.maybeNull(t.string),
        selectedSourceTypeId: t.maybeNull(t.string),
        selectedCurrencyId: t.maybeNull(t.string),
        defaultSelectedSourceId: t.maybeNull(t.reference(t.late((): IAnyModelType => SourceModel))),
        defaultSelectedSourceIdProvided: t.optional(t.boolean, false),
    })
    .views((self) => ({
        objValues<T>(obj: IMSTMap<IAnyType>): T[] {
            return [...obj.values()] as T[];
        },

        selectedDataForView(obj: IMSTMap<IAnyType>, id: string | null): ISelectedValue<string> {
            if (id && id.length) {
                return {
                    label: obj.get(id).name,
                    value: id,
                };
            }

            return {
                label: '',
                value: '',
            };
        },

        selectedCurrencyDataForView(obj: IMSTMap<IAnyType>, id: string | null): ISelectedValue<string> {
            if (id && id.length) {
                return {
                    label: obj.get(id).code,
                    value: id,
                };
            }

            return {
                label: '',
                value: '',
            };
        },

        listForViewCurrency(array: (ICurrencyModel)[]): ISelectedValue<string>[] {
            return (array || []).map((item: (ICurrencyModel)): ISelectedValue<string> => ({
                label: item.code,
                value: String(item.id),
            }));
        },

        listForView(array: (ICountryModel | IPartnerModel | ISourceModel | ISourceTypeModel)[]): ISelectedValue<string>[] {
            return (array || []).map((item: (ICountryModel | IPartnerModel | ISourceModel | ISourceTypeModel)): ISelectedValue<string> => ({
                label: item.name || '',
                value: item.id,
            }));
        },

        get isDataReadyToBeSent(): boolean {
            const {
                selectedCountryId,
                selectedPartnerId,
                selectedSourceId,
                selectedCurrencyId,
            } = self;

            return Boolean(selectedPartnerId)
                && Boolean(selectedCountryId)
                && Boolean(selectedSourceId)
                && Boolean(selectedCurrencyId);
        },
        get isPartnerIdAndCountryIdAndSourceIdSelected() {
            const {
                selectedCountryId,
                selectedPartnerId,
                selectedSourceId,
            } = self;

            return Boolean(selectedPartnerId)
            && Boolean(selectedCountryId)
            && Boolean(selectedSourceId);
        },

        get isPartnerIdAndCountryIdSelected(): boolean {
            const {
                selectedCountryId,
                selectedPartnerId,
            } = self;

            return Boolean(selectedPartnerId)
            && Boolean(selectedCountryId);
        },
        get isPartnerIdSelected(): boolean {
            const { selectedPartnerId } = self;

            return Boolean(selectedPartnerId);
        },
    }))
    .views((self) => ({
        get sourceList(): ISourceModel[] {
            return self.objValues<ISourceModel>(self.sources);
        },

        get partnerList(): IPartnerModel[] {
            return self.objValues<IPartnerModel>(self.partners);
        },

        get countryList(): ICountryModel[] {
            return self.objValues<ICountryModel>(self.countries);
        },

        get sourceTypesList(): ISourceTypeModel[] {
            return self.objValues<ISourceTypeModel>(self.sourceTypes);
        },

        get currencyList(): ICurrencyModel[] {
            return self.objValues<ICurrencyModel>(self.currencies);
        },

        get selectedCurrenciesForView(): ISelectedValue<string> {
            return self.selectedCurrencyDataForView(
                self.currencies,
                self.selectedCurrencyId,
            );
        },

        get selectedSourceForView(): ISelectedValue<string> {
            return self.selectedDataForView(
                self.sources,
                self.selectedSourceId,
            );
        },

        get defaultSelectedSource(): ISelectedValue<string> | undefined {
            if (self.defaultSelectedSourceId) {
                const defaultValue: ISourceModel = self.defaultSelectedSourceId;
                if (defaultValue.id && defaultValue.id.length) {
                    return {
                        label: defaultValue.name || '',
                        value: defaultValue.id,
                    };
                }

                return {
                    label: '',
                    value: '',
                };
            }

            return undefined;
        },

        get selectedPartnerForView(): ISelectedValue<string> {
            return self.selectedDataForView(
                self.partners,
                self.selectedPartnerId,
            );
        },

        get selectedCountryForView(): ISelectedValue<string> {
            return self.selectedDataForView(
                self.countries,
                self.selectedCountryId,
            );
        },

        get selectedSourceTypesForView(): ISelectedValue<string> {
            return self.selectedDataForView(
                self.sourceTypes,
                self.selectedSourceTypeId,
            );
        },
    }))
    .views((self) => ({
        get sourceListForView(): ISelectedValue<string>[] {
            return self.listForView(self.sourceList);
        },

        get partnerListForView(): ISelectedValue<string>[] {
            return self.listForView(self.partnerList);
        },

        get countryListForView(): ISelectedValue<string>[] {
            return self.listForView(self.countryList);
        },

        get sourceTypesListForView(): ISelectedValue<string>[] {
            return self.listForView(self.sourceTypesList);
        },

        get currencyListForView(): ISelectedValue<string>[] {
            return self.listForViewCurrency(self.currencyList);
        },
    }))
    .actions((self) => ({
        setSelectedCurrencyId(value: string | null): void {
            self.selectedCurrencyId = value;
        },
        setDefaultSelectedSourceId(value: string): void {
            self.defaultSelectedSourceId = value;
            self.selectedSourceTypeId = null;
        },
        setSelectedSourceId(value: string | null): void {
            self.selectedSourceId = value;
            self.selectedSourceTypeId = null;
        },

        setSelectedCountryId(value: string | null): void {
            self.selectedCountryId = value;
            self.selectedSourceTypeId = null;
            self.selectedCurrencyId = null;
        },

        setSelectedPartnerId(value: string | null): void {
            self.selectedPartnerId = value;
            self.selectedSourceTypeId = null;
            self.selectedCurrencyId = null;
        },

        setSelectedSourceTypeId(value: string | null): void {
            self.selectedSourceTypeId = value;
        },

        setSourceTypes(obj: Record<string, ISourceTypeModelSnapshotIn>): void {
            applySnapshot(self.sourceTypes, obj);
        },

        setCurrency(obj: Record<string, ICurrencyModelSnapshotIn>): void {
            applySnapshot(self.currencies, obj);
        },
        setCountries(obj: Record<string, ICountryModelSnapshotIn>): void {
            applySnapshot(self.countries, obj);
        },
    }))
    .actions((self) => ({
        setDefaultSelectedSourceIdAfterCreate() {
            const is2wcallSourceExist = find(self.sourceListForView, (item) => item.label === '2wcall');

            if (is2wcallSourceExist) {
                self.setSelectedSourceId(is2wcallSourceExist.value);
                self.setDefaultSelectedSourceId(is2wcallSourceExist.value);
            }

            self.defaultSelectedSourceIdProvided = true;
        },
    }));


export interface IOrderCreationModel extends Instance<typeof OrderCreation> {}
export interface IOrderCreationModelSnapshotIn extends SnapshotIn<typeof OrderCreation> {}
export interface IOrderCreationModelSnapshotOut extends SnapshotOut<typeof OrderCreation> {}
