import React, { FunctionComponent, useMemo } from 'react';
import { useInstances } from 'react-ioc';
import { sprintf } from 'sprintf-js';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { Store } from '@store/store';
import { SipService } from '@/app/services';
import { EnumSipStatus } from '@services/sip/models';
import TimeFromSeconds from '@UIElements/TimeFromSeconds';
import I18NService from '@services/I18NService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';

import './includes/SipMessageBoard.scss';


const SipMessageBoard: FunctionComponent = (): JSX.Element => {
    const [
        {
            currentOrder: {
                selectedPhoneToCallForComponent,
            },
            currentUser: {
                mode,
                isReady,
            },
        },
        {
            status: sipStatus,
            muted,
            callTime,
            sipError: { message: sipErrorMessage },
            phoneNumber,
        },
        { t },
    ] = useInstances(
        Store,
        SipService,
        I18NService,
    );

    const errorMessage = useMemo<string>(
        (): string => sipErrorMessage || '',
        [sipErrorMessage],
    );

    const isCustomerServiceUserUnready = useMemo<boolean>(
        () => mode === UserModeEnum.CLIENT_SERVICE && !isReady,
        [mode, isReady],
    );

    const messageTemplate = useMemo<JSX.Element>(
        (): JSX.Element => {
            const template = (
                className: string,
                message: string,
                temp?: JSX.Element,
            ): JSX.Element => (
                <div className={cn(`sipMessageBoard__content ${className}`)}>
                    { temp || <></> }
                    { message }
                </div>
            );

            switch (sipStatus) {
                case EnumSipStatus.REGISTERED: {
                    const message = `${t('SIP готов', 'SIP ready')} ${errorMessage}`;

                    return template('ok', message);
                }

                case EnumSipStatus.CONNECTED: {
                    const message = `${t(
                        'SIP регистрация ...',
                        'SIP registration ...',
                    )} ${errorMessage}`;

                    return template('pending', message);
                }

                case EnumSipStatus.CONNECTING: {
                    const message = `${t(
                        'SIP выполняется соединение ...',
                        'SIP connecting ...',
                    )} ${errorMessage}`;

                    return template('pending', message);
                }

                case EnumSipStatus.DISCONNECTED: {
                    if (isCustomerServiceUserUnready) {
                        const message = `${t(
                            'Не готов к следующему звонку',
                            'Unready to next call',
                        )}`;

                        return template('error', message);
                    }

                    const message = `${t(
                        'ОШИБКА: SIP соединение разъединено',
                        'ERROR: SIP disconnected',
                    )} ${errorMessage}`;

                    return template('error', message);
                }

                case EnumSipStatus.DISCONNECTING_IN_PROGRESS: {
                    const message = `${t(
                        'SIP завершение отключения ...',
                        'SIP completion of disconnect ...',
                    )}`;

                    return template('pending', message);
                }

                case EnumSipStatus.REGISTRATION_FAIL: {
                    const message = `${t(
                        'ОШИБКА: SIP регистрация провалена',
                        'ERROR: SIP registration failed',
                    )} ${errorMessage}`;

                    return template('error', message);
                }

                case EnumSipStatus.ENDED: {
                    const message = `${t(
                        'Звонок окончен',
                        'The call is over.',
                    )} ${errorMessage}`;

                    return template('info', message);
                }

                case EnumSipStatus.DIALING:
                case EnumSipStatus.PROGRESS: {
                    const message = `${sprintf(t(
                        'Звонок на номер %s - Соединение ...',
                        'Call to %s - Connecting ...',
                    ), selectedPhoneToCallForComponent(true)?.label)} ${errorMessage}`;

                    return template('ok', message);
                }

                case EnumSipStatus.FAIL: {
                    const message = errorMessage || t('Неизвестная ошибка', 'Unknown error');

                    return template('error', message);
                }

                case EnumSipStatus.INTERNAL_ERROR: {
                    const message = `${t(
                        'Внутренняя ошибка',
                        'Internal error',
                    )}: ${errorMessage
                    || t(
                        'Неизвестная ошибка',
                        'Unknown error',
                    )}`;

                    return template('error', message);
                }

                case EnumSipStatus.LIVE: {
                    const className = muted ? 'error' : 'ok';

                    let message = errorMessage;

                    if (mode === UserModeEnum.PROGRESSIVE) {
                        message = errorMessage || t(
                            'Ожидайте. Выполняется соединение.',
                            'Wait. Connection in progress.',
                        );
                    }

                    const secondsBoard = (
                        <>
                            {mode === UserModeEnum.CLIENT_SERVICE && (
                                <span className="incomingCallNumber">
                                    {`${t('Входящий вызов', 'Incoming call')} ${phoneNumber}:`}
                                    { /* TODO: нужно ли в КлС когда-то маскировать номер телефона ? */ }
                                </span>
                            )}
                            {mode !== UserModeEnum.PROGRESSIVE && <TimeFromSeconds duration={callTime} />}
                            {muted && (
                                <>
                                    {'\u00A0'}
                                    (
                                    {t('микрофон отключен', 'muted')}
                                    )
                                </>
                            )}
                        </>
                    );

                    return template(className, message, secondsBoard);
                }

                case EnumSipStatus.NEW_MESSAGE: {
                    const className = muted ? 'error' : 'ok';

                    const secondsBoard = (
                        <>
                            {mode === UserModeEnum.CLIENT_SERVICE && (
                                <span className="incomingCallNumber">
                                    {`${t('Входящий вызов', 'Incoming call')} ${phoneNumber}:`}
                                    { /* TODO: нужно ли в КлС когда-то маскировать номер телефона ? */ }
                                </span>
                            )}
                            <TimeFromSeconds duration={callTime} />
                            {muted && (
                                <>
                                    {'\u00A0'}
                                    (
                                    {t('микрофон отключен', 'muted')}
                                    )
                                </>
                            )}
                        </>
                    );

                    return template(className, errorMessage, secondsBoard);
                }

                default: {
                    return <></>;
                }
            }
        },
        [
            sipStatus,
            errorMessage,
            muted,
            callTime,
            isCustomerServiceUserUnready,
            phoneNumber,
            mode,
            t,
        ],
    );

    return (
        <div className="sipMessageBoard">
            { messageTemplate }
        </div>
    );
};


export default observer(SipMessageBoard);
