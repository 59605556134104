import { computed, observable } from 'mobx';

import I18NService from '@services/I18NService';
import ErrorService from '@core/services/ErrorService';
import ConnectMicrophoneToDeviceError from '@core/error/ConnectMicrophoneToDeviceError';
import HaveNotAccessToMicrophoneError from '@core/error/HaveNotAccessToMicrophoneError';
import UserDeniedMediaAccessError from '@core/error/UserDeniedMediaAccessError';
import DevicesNotAvailableError from '@core/error/DevicesNotAvailableError';
import SafariEditAccessError from '@core/error/SafariEditAccessError';


type THardwareErrorState = ConnectMicrophoneToDeviceError
| DevicesNotAvailableError
| UserDeniedMediaAccessError
| HaveNotAccessToMicrophoneError;

type TPermissionStateTargetHandler = { target: { state: PermissionState } };

export enum EnumPermissionState {
    DENIED = 'denied',
    PROMPT = 'prompt',
    GRANTED = 'granted',
}


class DevicesService {
    private _permissionStatusForMicrophone: PermissionStatus;

    @observable
    private _hardwareErrorState: THardwareErrorState | null = null;

    @observable
    private _permissionState: PermissionState;

    private static get _mediaDevices(): MediaDevices {
        return navigator.mediaDevices;
    }

    private static get _permissions(): Permissions {
        return navigator.permissions;
    }

    private static get _isSafariBrowser(): boolean {
        return navigator
            .userAgent
            .toLowerCase()
            .includes('safari');
    }

    public set hardwareErrorState(state: THardwareErrorState | null) {
        this._hardwareErrorState = state;
    }

    @computed
    public get isGiveBrowserAccessToYourMicroError(): boolean {
        return this._hardwareErrorState instanceof HaveNotAccessToMicrophoneError;
    }

    @computed
    public get isConnectMicrophoneToDeviceError(): boolean {
        return this._hardwareErrorState instanceof ConnectMicrophoneToDeviceError;
    }

    @computed
    public get hardwareErrorMessage(): string | null {
        return this._hardwareErrorState?.message || null;
    }

    @computed
    public get isPermissionStatePrompt(): boolean {
        return this._permissionState === EnumPermissionState.PROMPT;
    }

    @computed
    public get isPermissionStateGranted(): boolean {
        return this._permissionState === EnumPermissionState.GRANTED;
    }

    @computed
    public get isPermissionStateDenied(): boolean {
        return this._permissionState === EnumPermissionState.DENIED;
    }

    constructor(
        private readonly _I18NService: I18NService,
        private readonly _errorService: ErrorService,
    ) {
        this._initPermissionStatusForMicrophone();
    }

    private static async _getEnumerateDevices(): Promise<MediaDeviceInfo[]> {
        if (DevicesService._mediaDevices?.enumerateDevices) {
            return DevicesService._mediaDevices.enumerateDevices();
        }

        return [];
    }

    private static async _getPermissionStatus(permission: string): Promise<PermissionStatus> {
        return DevicesService?._permissions?.query(
            <PermissionDescriptor>{ name: permission },
        );
    }

    private static async _checkConnectedDevices(mediaDeviceKind: MediaDeviceKind): Promise<boolean> {
        const enumDevicesRes = await DevicesService._getEnumerateDevices();

        return enumDevicesRes.some(
            (device: MediaDeviceInfo): boolean => device.kind === mediaDeviceKind,
        );
    }

    private _permissionStatusChangeHandler({ target: { state } }: TPermissionStateTargetHandler): void {
        this._permissionState = state;
    }

    private _initPermissionStatusForMicrophone(): void {
        DevicesService._getPermissionStatus('microphone')
            .then((status) => {
                this._permissionStatusForMicrophone = status;
                this._permissionState = status.state;

                this._permissionStatusForMicrophone.addEventListener(
                    'change',
                    this._permissionStatusChangeHandler.bind(this),
                );
            })
            .catch(() => {
                if (DevicesService._isSafariBrowser) {
                    const { message } = this._errorService.toReturnAFabricatedError<SafariEditAccessError>(SafariEditAccessError);

                    // eslint-disable-next-line no-console
                    console.warn(message);
                }
            });
    }

    private _checkEditingCapabilitiesOfAccesses(): void {
        // если нет состояния доступа и это Safari, значит данный функционал не поддерживается
        if (!this._permissionStatusForMicrophone && DevicesService._isSafariBrowser) {
            throw this._errorService.toReturnAFabricatedError(SafariEditAccessError);
        }
    }

    public deviceVerification(): never | boolean {
        if (!DevicesService._mediaDevices || !DevicesService._mediaDevices.enumerateDevices) {
            throw this._errorService.toReturnAFabricatedError(DevicesNotAvailableError);
        }

        return true;
    }

    public checkingMicrophoneAccess(): never | boolean {
        if (typeof DevicesService._permissions !== 'object') {
            throw this._errorService.toReturnAFabricatedError(UserDeniedMediaAccessError);
        }

        return true;
    }

    public async checkingConnectedMicrophone(): Promise<never | boolean> {
        const res = await DevicesService._checkConnectedDevices('audioinput');

        if (!res) {
            throw this._errorService.toReturnAFabricatedError(ConnectMicrophoneToDeviceError);
        }

        return true;
    }

    public checkingBrowserMicrophoneAccess(): never | boolean {
        if (this.isPermissionStateDenied) {
            throw this._errorService.toReturnAFabricatedError(HaveNotAccessToMicrophoneError);
        }

        return true;
    }

    public async requestPermissionForAMicrophone(): Promise<void> {
        try {
            await DevicesService._mediaDevices.getUserMedia({ audio: true });
        } catch {
            throw this._errorService.toReturnAFabricatedError(HaveNotAccessToMicrophoneError);
        }
    }

    // Проверка основных девайсов и доступов для работы
    public async checkEquipmentForWork(): Promise<void | never> {
        try {
            this._checkEditingCapabilitiesOfAccesses();

            if (this.isPermissionStatePrompt) {
                await this.requestPermissionForAMicrophone();
            }

            this.checkingMicrophoneAccess();
            await this.checkingConnectedMicrophone();
            this.checkingBrowserMicrophoneAccess();

            this._hardwareErrorState = null;
        } catch (error) {
            switch (typeof error === 'object') {
                case error instanceof SafariEditAccessError: {
                    throw error;
                }
                case error instanceof UserDeniedMediaAccessError:
                case error instanceof ConnectMicrophoneToDeviceError:
                case error instanceof HaveNotAccessToMicrophoneError: {
                    this._hardwareErrorState = error;
                    throw error;
                }
                default: {
                    // eslint-disable-next-line no-console
                    console.error(error);

                    throw error;
                }
            }
        }
    }
}


export default DevicesService;
