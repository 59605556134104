import { IProductItemModel } from '@/app/models/mobx-state-tree/newModels/ProductItem.model';
import { observer } from 'mobx-react';
import React from 'react';
import { useManagePromoForPromotionBuilder } from './helpers/useManagePromoForPromotionBuilder';
import PromoItemToAdd from './PromoItemToAdd';

interface IPromoItemToAddPromotionBuilderProps {
    catalogItem: IProductItemModel;
    readonly dataCyValue: number;
}

/**
 * Родитель для dummy компонента промо товара PromoItemToAdd
 * Компонент используется для показа в блоке офферов при примененном конструкторе акций
 * Использует хук, который передает внутрь компонента всю нужную информацию
 */
const PromoItemToAddPromotionBuilder = ({
    catalogItem,
    dataCyValue,
}: IPromoItemToAddPromotionBuilderProps): JSX.Element => {
    const [
        onClick, isYetInBasket, preparedCatalogItemForView, disabled, preparedTranslations, isConditionForAPromo, discountAmount,
    ] = useManagePromoForPromotionBuilder(catalogItem);

    return (
        <PromoItemToAdd
            onClick={onClick}
            isYetInBasket={isYetInBasket}
            preparedTranslations={preparedTranslations}
            disabled={disabled}
            catalogItem={preparedCatalogItemForView}
            isConditionForAPromo={isConditionForAPromo}
            discountAmount={discountAmount}
            dataCyValue={dataCyValue}
        />
    );
};


export default observer(PromoItemToAddPromotionBuilder);
