import React, { FunctionComponent } from 'react';
import cn from 'classnames';


interface IIcAccordionChevronUpSvg {
    className?: string;
}


const IcAccordionChevronUpSvg: FunctionComponent<IIcAccordionChevronUpSvg> = ({ className, ...props }) => (
    <svg
        className={cn(['IcAccordionChevronUpSvg', className])}
        {...props}
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00008 3.41424L1.70718 8.70713L0.292969 7.29292L7.00008 0.585815L13.7072 7.29292L12.293 8.70713L7.00008 3.41424Z"
            fill="#1665D8"
        />
    </svg>

);


export default IcAccordionChevronUpSvg;
