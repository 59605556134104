import map from 'lodash/map';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import values from 'lodash/values';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import some from 'lodash/some';
import { v4 } from 'uuid';

import {
    IOrderPartnerProductData,
    IOrderProduct,
    IPartnerProductDynamicSetData,
    IPartnerProductSetAlternativesData,
} from '@api/order-api-service/models';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import {
    IPartnerProduct,
    IPartnerProductDynamicSetAPIData,
    IPartnerProductStaticSetAPIData,
} from '@api/partner-api-service/models';
import { IPromoProducts } from '@interfaces/form.interface';
import { IBasketItemModelSnapshotIn } from '@models/mobx-state-tree/newModels/BasketItem.model';
import {
    IProductItemModelSnapshotIn,
    ProductItemSubTypeEnum,
    ProductItemTypeEnum,
} from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ISetProductCompositionSnapshotIn } from '@models/mobx-state-tree/newModels/SetProductComposition';

import { IPromotionModelSnapshotIn } from '@models/mobx-state-tree/newModels/Promotion.model';
import { IOldPromoGiftData, IOldPromotionData } from '@api/old-promotion-api-service/models';
import OldPromotionApiService from '@api/old-promotion-api-service';
import IntermediateStateCatalogsAndBaskets, {
    TCatalogIdsForPromoRequest,
} from '@/app/order-prepare/IntermediateStateCatalogsAndBaskets';
import NoGiftForAPromotionalItemWasFoundError from '@core/error/order-prepare-errors/NoGiftForAPromotionalItemWasFoundError';
import InGiftBalancesError from '@core/error/order-prepare-errors/InGiftBalancesError';
import CatalogDoesNotContainAnItemForAPromotionalGiftError
    from '@core/error/order-prepare-errors/CatalogDoesNotContainAnItemForAPromotionalGiftError';
import NoItemForPromotionWasFoundInCatalogError from '@core/error/order-prepare-errors/NoItemForPromotionWasFoundInCatalogError';
import PartnerProductIntegrityError from '@core/error/order-prepare-errors/PartnerProductIntegrityError';
import QuantityInSetIsNullError from '@core/error/order-prepare-errors/QuantityInSetIsNullError';
import SetWithEmptyProductsError from '@core/error/order-prepare-errors/SetWithEmptyProductsError';
import DynamicSetBadConfigurationError from '@core/error/order-prepare-errors/DynamicSetBadConfigurationError';
import ProductInOrderWithoutPartnerProductError from '@core/error/order-prepare-errors/ProductInOrderWithoutPartnerProductError';
import DidNotFindAComparisonOfAlternativeProductWithPartnerProductError
    from '@core/error/order-prepare-errors/DidNotFindAComparisonOfAlternativeProductWithPartnerProductError';
import DidNotFindDynamicSetMappingWithPartnerProductError
    from '@core/error/order-prepare-errors/DidNotFindDynamicSetMappingWithPartnerProductError';
import ItemWasNotFoundInCatalogError from '@core/error/order-prepare-errors/ItemWasNotFoundInCatalogError';
import DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError
    from '@core/error/order-prepare-errors/DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError';
import DidFindAStaticSetMappingToAnAffiliateProductError
    from '@core/error/order-prepare-errors/DidFindAStaticSetMappingToAnAffiliateProductError';
import PromotionBuilderFetchAndFill from '@/app/order-prepare/PromotionBuilderFetchAndFill';
import PromotionBuilderApiService from '@api/promotion-builder-api-service';
import OrderPrepareWithPromotionBuilderApplied from '@/app/order-prepare/OrderPrepareWithPromotionBuilderApplied';
import ConsiderRemains from '@/app/order-prepare/ConsiderRemains';
import DeletedPromotionsAndProducts from '@/app/order-prepare/DeletedPromotionsAndProducts';


type TDefaultDataForPreparePromo = Record<number, IOrderProduct>;

type IProductToAddRecord = {
    partnerProduct: IPartnerProduct;
    orderProduct: IOrderProduct | null;
};

type TItemsPreparedIntoNomenclatures<T> = {
    nomenclaturesOfGiftItems: T;
    nomenclaturesOfMainItems: T;
    nomenclaturesOfPromoItems: T;
    nomenclaturesOfStaticSets: T;
    nomenclaturesOfDynamicSets: T;
};

export type TProductWithAlternativesModel = {
    product: IProductItemModelSnapshotIn;
    alternatives: IProductItemModelSnapshotIn[];
};


class OrderPrepareCatalogAndBasket {
    /**
     * Проверяет объект на добавленные ранее товары заказа. Если товара в объекте нет, то создает его с ключом partnerProductId.
     * Если товар уже был ранее добавлен, то суммирует количества этих двух товаров.
     * @param obj - аккумулирующий объект
     * @param key - partnerProductId
     * @param value - объект товара, пришедшего с заказом
     */
    private static _turnIdenticalItemsIntoNomenclature(
        obj: Record<number, IOrderProduct>,
        key: number,
        value: IOrderProduct,
    ): Record<number, IOrderProduct> {
        if (!obj[key]) {
            obj[key] = value;
        } else {
            Object.assign(
                obj[key],
                { quantity: value.quantity + obj[key].quantity },
            );
        }

        return obj;
    }

    private static _checkPromotionBuilderInOrder(orderProducts: IOrderProduct[]): boolean {
        const mainProduct = find<IOrderProduct>(
            orderProducts,
            {
                gift: false,
                promo: false,
            },
        );

        const promotionBuilderId = mainProduct?.promotion_builder_id;

        if (promotionBuilderId) {
            return some<IOrderProduct>(
                orderProducts,
                {
                    promotion_builder_id: promotionBuilderId,
                },
            );
        }

        return false;
    }

    constructor(
        private readonly _intermediateStateCatalogsAndBaskets: IntermediateStateCatalogsAndBaskets,
        private readonly _deletedPromotionsAndProducts: DeletedPromotionsAndProducts,
        private readonly _oldPromotionApiService: OldPromotionApiService,
        private readonly _promotionBuilderApiService: PromotionBuilderApiService,
    ) {}

    /**
     * Добывает из заказа акционные товары и подарки.
     * Возвращает объект ключ-значение, в котором ключ - id товара к которому применена акция, и значение - сам товар.
     * Также добавляет в package массив productGifts, в который помещаем номенклатуры подарков для товара по акции.
     * @param orderId
     * @param data - товары из пришедшего заказа
     */
    private _bringProductsWithPromotionsIntoRightFormat(
        orderId: number,
        data: IOrderProduct[],
    ): Record<number, IOrderProduct> {
        // Добываем главные товары, к которым применены акции
        const promotionalItemsMain = data.filter(
            (item) => item.package_id && item.package && !item.promo && !item.gift,
        );

        // Добываем подарки, которые идут по акциям к главным товарам
        const giftsForMainProducts = data.filter(
            (item) => item.package_id && item.package && !item.promo && item.gift,
        );

        const result = reduce<IOrderProduct, TDefaultDataForPreparePromo>(
            promotionalItemsMain,
            (acc, item) => {
                // Если это товар, к которому применена акция, и его нет в аккумулирующем объекте, то создаем объект с этим товаром
                if (!item.gift && !acc[item.id]) {
                    // Перебираем подарки этого товара, чтобы найти совпадения в массиве giftsForMainProducts (подарки для акционных товаров)
                    const giftsForPromotionalItems = reduce<IOldPromoGiftData, IOrderProduct[]>(
                        item.package?.gifts,
                        (_acc, itemGift) => {
                            const selectedGift = find<IOrderProduct>(
                                giftsForMainProducts,
                                // Если совпадает product_id из товаров по акции в главном товаре с подарками для акционных товаров и их
                                // идентификаторы акций, то заносим этот подарок в массив
                                (x) => x.product.id === itemGift.product_id && x.package?.id === item.package?.id,
                            );

                            if (selectedGift) {
                                const idx = findIndex<IOrderProduct>(
                                    giftsForMainProducts,
                                    (x) => x.id === selectedGift.id,
                                );

                                if (idx !== -1) {
                                    // Удаляем этот подарок из массива, чтобы при последующих итерациях его ни к какому товару
                                    // больше не запихали
                                    giftsForMainProducts.splice(idx, 1);
                                }

                                // Заносим подарок в массив
                                _acc.push(selectedGift);
                            } else {
                                throw new NoGiftForAPromotionalItemWasFoundError(
                                    orderId,
                                    itemGift,
                                );
                            }

                            return _acc;
                        },
                        [],
                    );

                    if (!item.package?.productGifts) {
                        // Создаем массив в объекте товара (у которого акция) с полной информацией по каждому подарку
                        item.package!.productGifts = giftsForPromotionalItems;
                    }

                    acc[item.id] = item;
                }

                return acc;
            },
            {},
        );

        if (giftsForMainProducts.length) {
            throw new InGiftBalancesError(
                orderId,
                giftsForMainProducts,
            );
        }

        return result;
    }

    private async _requestPromotionsForEachMainProduct(
        countryId: number,
        partnerId: number,
        typeId: number | undefined,
    ): Promise<void> {
        const {
            uniqIdsMainProduct,
            catalogIdsForPromoRequest,
            giftItemsInCatalog,
            catalog,
            pushToCatalog,
        } = this._intermediateStateCatalogsAndBaskets;

        // Проверка главных товаров в каталоге
        if (uniqIdsMainProduct.length) {
            const promotions = await this._oldPromotionApiService.getOldPromotionListByProducts(
                partnerId,
                countryId,
                typeId,
                uniqIdsMainProduct,
            );

            each<IOldPromotionData>(
                promotions,
                (promo) => {
                    const catalogIdsInfo = find<TCatalogIdsForPromoRequest>(
                        catalogIdsForPromoRequest,
                        (item) => item.productId === promo.product.id,
                    );

                    if (catalogIdsInfo) {
                        const promoPrepareItem: IPromotionModelSnapshotIn = {
                            id: promo.id.toString(),
                            name: promo.name,
                            paidAmount: promo.paid_amount,
                            specialPrice: promo.product_price,
                            productItem: catalogIdsInfo.id,
                            gifts: reduce<IOldPromoGiftData, Record<string, IProductItemModelSnapshotIn>>(
                                promo?.gifts,
                                (acc, gift) => {
                                    const productFromCatalogForAPromotionalGift = find<IProductItemModelSnapshotIn>(
                                        giftItemsInCatalog,
                                        (item) => item.productId === gift.product_id.toString(),
                                    );

                                    if (productFromCatalogForAPromotionalGift) {
                                        const idxV4 = v4();

                                        acc[idxV4] = {
                                            ...productFromCatalogForAPromotionalGift,
                                            id: idxV4,
                                            quantityInOldPromotion: gift.free_amount,
                                        };
                                    }

                                    return acc;
                                },
                                {},
                            ),
                        };

                        const itemFromCatalog = catalog.get(catalogIdsInfo.id);

                        if (itemFromCatalog) {
                            const promotionalProduct: IProductItemModelSnapshotIn = {
                                id: v4(),
                                type: ProductItemTypeEnum.OLD_PROMOTION,
                                subType: ProductItemSubTypeEnum.OLD_PROMOTION,
                                partnerProductId: String(itemFromCatalog.partnerProductId),
                                productId: String(itemFromCatalog.productId),
                                mandatoryGift: false,
                                name: itemFromCatalog.name,
                                price: itemFromCatalog.price,
                                quantityInStock: itemFromCatalog.quantityInStock, // Кол-во товара на складе
                                image: itemFromCatalog.image,
                                isFromPromotionBuilder: false,
                                originalSetProductCompositions: null,
                                oldPromotionalProduct: promoPrepareItem,
                            };

                            pushToCatalog(promotionalProduct);
                        }
                    }
                },
            );
        }
    }

    private _findProductsWithPromotionsApplied(
        orderId: number,
        orderProducts: IOrderProduct[],
    ): void {
        const collectedPromotionalGoods = this._bringProductsWithPromotionsIntoRightFormat(orderId, orderProducts);

        // Если объект пустой, значит нет акционных товаров.
        if (isEmpty(collectedPromotionalGoods)) {
            return;
        }

        each<IOrderProduct>(
            values(collectedPromotionalGoods),
            (value) => {
                const promotionalGoodFromCatalog = find<IProductItemModelSnapshotIn>(
                    this._intermediateStateCatalogsAndBaskets.mainItemsInCatalog,
                    (x) => x.partnerProductId === value.product.partnerProduct?.id.toString(),
                );

                if (promotionalGoodFromCatalog) {
                    const promotionalBasketItemId = v4();
                    const productGifts = value.package?.productGifts || [];
                    const promotionId = value.package?.id.toString();
                    const price = value.package?.product_price || value.price;

                    const promotionalBasketItem: IBasketItemModelSnapshotIn = {
                        id: promotionalBasketItemId,
                        existingOrderProductId: value.id,
                        main: value.main,
                        promotionName: value.package?.name,
                        price,
                        quantity: value.quantity,
                        productItem: promotionalGoodFromCatalog.id,
                        editablePrice: false, // TODO: редактировать цену
                        removed: false,
                        promotionId,
                    };

                    // Добавляем товары в корзину, к которому применена акция2
                    this._intermediateStateCatalogsAndBaskets.pushToBasket(promotionalBasketItem);

                    each<IOrderProduct>(
                        productGifts,
                        (productGift) => {
                            const selectedGiftFromCatalog = find<IProductItemModelSnapshotIn>(
                                this._intermediateStateCatalogsAndBaskets.giftItemsInCatalog,
                                (x) => x.partnerProductId === productGift.product.partnerProduct?.id.toString(),
                            );

                            if (selectedGiftFromCatalog) {
                                const giftBasketItem: IBasketItemModelSnapshotIn = {
                                    id: v4(),
                                    existingOrderProductId: productGift.id,
                                    price: 0,
                                    quantity: productGift.quantity,
                                    productItem: selectedGiftFromCatalog.id,
                                    mandatoryGift: true,
                                    promotionMasterBasketItemId: `${promotionalBasketItemId}`,
                                    promotionId,
                                };

                                // Добавляем подарок в корзину, который пришел с примененной акцией
                                this._intermediateStateCatalogsAndBaskets.pushToBasket(giftBasketItem);
                            } else {
                                throw new CatalogDoesNotContainAnItemForAPromotionalGiftError(
                                    orderId,
                                    productGift,
                                );
                            }
                        },
                    );
                } else {
                    throw new NoItemForPromotionWasFoundInCatalogError(
                        orderId,
                        value,
                    );
                }
            },
        );
    }

    /**
     * Все простые продукты с in_currency true становятся main
     * Все простые продукты с in_currency false/true становятся gift
     *
     * Находим сеты c in_currency true их состав и альтернативы.
     * Сеты могут быть только main
     *
     * @param orderId - id заказа
     * @param rawPartnerProducts - сырые партнерские товары (c валютой и без)
     * @param orderProducts
     */
    private _fillCatalogFromPartnerProducts(
        orderId: number,
        rawPartnerProducts: IPartnerProduct[],
        orderProducts: IOrderProduct[],
    ): void {
        const productsWithoutCurrency = filter<IPartnerProduct>(
            rawPartnerProducts,
            (product) => !product.in_currency,
        );

        const productsWithCurrency = filter<IPartnerProduct>(
            rawPartnerProducts,
            (product) => product.in_currency && product.price !== null,
        );

        const orderProductsThatHaveNotCurrencyInPartnerProducts = reduce<IOrderProduct, IProductToAddRecord[]>(
            orderProducts,
            (acc, orderProduct) => {
                const isAGift = orderProduct.gift;

                // Если это подарок, то не добавляем его
                if (!isAGift) {
                    // ищем товар из заказа в партнерских товарах, у которого in_currency = false
                    // мы должны добавить такой товар в каталог, указав цену товара из заказа
                    const isFound = find<IPartnerProduct>(
                        rawPartnerProducts,
                        (partnerProduct) => Number(partnerProduct.id) === Number(orderProduct.product.partnerProduct?.id)
                            && !partnerProduct.in_currency,
                    );

                    if (isFound) {
                        acc.push({
                            partnerProduct: isFound,
                            orderProduct,
                        });

                        return acc;
                    }
                }

                return acc;
            },
            [],
        );

        // Приводим товары ПП с валютой к формату интерфейса IProductToAddRecord
        const productsWithCurrencyInFormat = map<IPartnerProduct, IProductToAddRecord>(
            productsWithCurrency,
            (partnerProduct) => ({
                partnerProduct,
                orderProduct: null,
            }),
        );

        const partnerProductsPrepared = concat<IProductToAddRecord>(
            orderProductsThatHaveNotCurrencyInPartnerProducts,
            productsWithCurrencyInFormat,
        );

        /**
         * Добавляем в каталог партнерские товары, у которых признак in_currency = true.
         * Также, если у объекта есть orderProducts, то берем цену товара оттуда (это будут товары, у которых в ПП in_currency = false)
         */
        each<IProductToAddRecord>(
            partnerProductsPrepared,
            ({ partnerProduct, orderProduct }) => {
                // добавляем в каталог основные товары и подарки:
                if (!partnerProduct.is_product_set && !partnerProduct.is_dynamic_set) {
                    const giftProduct: IProductItemModelSnapshotIn = {
                        id: v4(),
                        type: ProductItemTypeEnum.REGULAR,
                        subType: ProductItemSubTypeEnum.GIFT,
                        partnerProductId: String(partnerProduct.id),
                        productId: String(partnerProduct.product.id),
                        mandatoryGift: false,
                        name: partnerProduct.product.name,
                        price: 0,
                        quantityInStock: partnerProduct.quantity, // Кол-во товара на складе
                        image: partnerProduct.image,
                        isFromPromotionBuilder: false,
                        originalSetProductCompositions: null,
                    };

                    this._intermediateStateCatalogsAndBaskets.pushToCatalog(giftProduct);

                    if (!partnerProduct.price && !orderProduct) {
                        // Выше фильтровали массив партнерских товаров на предмет пустой цены. Здесь такое точно не должны поймать.
                        return;
                    }

                    const price = orderProduct ? orderProduct.price : Number(partnerProduct.price);

                    const mainProduct: IProductItemModelSnapshotIn = {
                        id: v4(),
                        type: ProductItemTypeEnum.REGULAR,
                        subType: ProductItemSubTypeEnum.MAIN,
                        partnerProductId: String(partnerProduct.id),
                        productId: String(partnerProduct.product.id),
                        mandatoryGift: false,
                        name: partnerProduct.product.name,
                        price,
                        quantityInStock: partnerProduct.quantity, // Кол-во товара на складе
                        image: partnerProduct.image,
                        isFromPromotionBuilder: false,
                        originalSetProductCompositions: null,
                    };

                    this._intermediateStateCatalogsAndBaskets.pushToCatalog(mainProduct);
                }

                // добавляем в каталог статические наборы
                if (partnerProduct.is_product_set && !partnerProduct.is_dynamic_set) {
                    // проваливаемся в состав набора
                    if (!partnerProduct.products?.length) {
                        throw new PartnerProductIntegrityError(
                            orderId,
                            partnerProduct,
                        );
                    }

                    const productModelsInSet: IProductItemModelSnapshotIn[] = [];

                    each<IPartnerProductStaticSetAPIData>(
                        partnerProduct.products,
                        (product) => {
                            // ищем в партнерских товарах (корневой уровень) полную информацию о товаре, входящем в состав сета
                            const setItemFoundInPartnerProducts = find<IPartnerProduct>(
                                rawPartnerProducts,
                                (partnerProduct) => partnerProduct.id === product.id,
                            );

                            if (!setItemFoundInPartnerProducts) {
                                throw new DidFindAStaticSetMappingToAnAffiliateProductError(
                                    orderId,
                                    product,
                                    partnerProduct.id,
                                );
                            }

                            // создаем модель ProductItemModel c типом SET_COMPOSITION_ITEM (т.е. элемент, входящий в состав статического набора)
                            const productItem: IProductItemModelSnapshotIn = {
                                id: v4(),
                                type: ProductItemTypeEnum.SET_COMPOSITION_ITEM,
                                subType: ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
                                partnerProductId: String(setItemFoundInPartnerProducts.id),
                                productId: String(setItemFoundInPartnerProducts.product.id),
                                mandatoryGift: false,
                                name: setItemFoundInPartnerProducts.product.name,
                                price: null,
                                quantityInStock: setItemFoundInPartnerProducts.quantity,
                                quantityInSet: product.quantity, // количество в наборе
                                image: setItemFoundInPartnerProducts.image,
                                isFromPromotionBuilder: false,
                                originalSetProductCompositions: null,
                            };

                            this._intermediateStateCatalogsAndBaskets.pushToCatalog(productItem);

                            productModelsInSet.push(productItem);
                        },
                    );

                    const originalProductSetComposition: ISetProductCompositionSnapshotIn[] = [];

                    each<IProductItemModelSnapshotIn>(
                        productModelsInSet,
                        (productModel, index) => {
                            if (productModel.quantityInSet === null) {
                                throw new QuantityInSetIsNullError(
                                    orderId,
                                    productModel,
                                    partnerProduct.id,
                                );
                            }

                            const setCompositionItem: ISetProductCompositionSnapshotIn = {
                                id: v4(),
                                index,
                                alternatives: null,
                                quantityInSet: productModel.quantityInSet!,
                                selectedProductItem: productModel.id,
                                originalProductItem: productModel.id,
                            };

                            originalProductSetComposition.push(setCompositionItem);
                        },
                    );

                    if (!partnerProduct.price && !orderProduct) {
                        return;
                    }

                    const price = orderProduct ? orderProduct.price : Number(partnerProduct.price);

                    const staticSetItem: IProductItemModelSnapshotIn = {
                        id: v4(),
                        type: ProductItemTypeEnum.STATIC_SET,
                        subType: ProductItemSubTypeEnum.MAIN,
                        partnerProductId: String(partnerProduct.id),
                        productId: String(partnerProduct.product.id),
                        mandatoryGift: false,
                        name: partnerProduct.product.name,
                        price,
                        quantityInStock: partnerProduct.quantity,
                        image: partnerProduct.image,
                        isFromPromotionBuilder: false,
                        originalSetProductCompositions: convertArrayToCollection(originalProductSetComposition),
                    };

                    this._intermediateStateCatalogsAndBaskets.pushToCatalog(staticSetItem);
                }

                // добавляем в каталог динамические наборы
                if (partnerProduct.is_product_set && partnerProduct.is_dynamic_set) {
                    if (!partnerProduct.products?.length) {
                        throw new PartnerProductIntegrityError(
                            orderId,
                            partnerProduct,
                        );
                    }

                    // сюда будем класть данные о составе набора и альтернативах для каждого из них, если таковые есть
                    const productWithAlternativesModel: TProductWithAlternativesModel[] = [];

                    const rawDynamicSetFromOrder: IPartnerProductDynamicSetAPIData[] = <IPartnerProductDynamicSetAPIData[]>partnerProduct.products;

                    // проваливаемся в состав набора
                    each<IPartnerProductDynamicSetAPIData>(
                        rawDynamicSetFromOrder,
                        (product, productIndex) => {
                            // ищем в партнерских товарах такой продукт
                            const setItemFoundInPartnerProducts = find<IPartnerProduct>(
                                rawPartnerProducts,
                                (partnerProduct) => partnerProduct.id === product.id,
                            );

                            if (!setItemFoundInPartnerProducts) {
                                throw new DidNotFindDynamicSetMappingWithPartnerProductError(
                                    orderId,
                                    product,
                                    partnerProduct.id,
                                );
                            }

                            const productItem: IProductItemModelSnapshotIn = {
                                id: v4(),
                                type: ProductItemTypeEnum.SET_COMPOSITION_ITEM,
                                subType: ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
                                partnerProductId: String(setItemFoundInPartnerProducts.id),
                                productId: String(setItemFoundInPartnerProducts.product.id),
                                mandatoryGift: false,
                                name: setItemFoundInPartnerProducts.product.name,
                                price: null,
                                quantityInStock: setItemFoundInPartnerProducts.quantity,
                                quantityInSet: product.quantity, // количество в наборе
                                image: setItemFoundInPartnerProducts.image,
                                isFromPromotionBuilder: false,
                                originalSetProductCompositions: null,
                            };

                            this._intermediateStateCatalogsAndBaskets.pushToCatalog(productItem);

                            productWithAlternativesModel.push({ product: productItem, alternatives: [] });

                            // закидываем в модель alternatives для товара в составе набора
                            each<IPartnerProductSetAlternativesData>(
                                product.alternatives,
                                (alternativeItem) => {
                                    // ищем в партнерских товарах инфу по каждой альтернативе
                                    const alternativeItemFoundInPartnerProducts = find<IPartnerProduct>(
                                        rawPartnerProducts,
                                        (partnerProduct) => partnerProduct.id === alternativeItem.id,
                                    );

                                    if (!alternativeItemFoundInPartnerProducts) {
                                        throw new DidNotFindAComparisonOfAlternativeProductWithPartnerProductError(
                                            orderId,
                                            alternativeItem,
                                            partnerProduct.id,
                                        );
                                    }

                                    const alternativeProductItem: IProductItemModelSnapshotIn = {
                                        id: v4(),
                                        type: ProductItemTypeEnum.SET_COMPOSITION_ITEM,
                                        subType: ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
                                        partnerProductId: String(alternativeItemFoundInPartnerProducts.id),
                                        productId: String(alternativeItemFoundInPartnerProducts.product.id),
                                        mandatoryGift: false,
                                        name: alternativeItemFoundInPartnerProducts.product.name,
                                        price: null,
                                        quantityInStock: alternativeItemFoundInPartnerProducts.quantity,
                                        quantityInSet: product.quantity, // количество в наборе
                                        image: alternativeItemFoundInPartnerProducts.image,
                                        isFromPromotionBuilder: false,
                                        originalSetProductCompositions: null,
                                    };

                                    this._intermediateStateCatalogsAndBaskets.pushToCatalog(alternativeProductItem);

                                    productWithAlternativesModel[productIndex].alternatives.push(alternativeProductItem);
                                },
                            );
                        },
                    );

                    const originalProductSetComposition: ISetProductCompositionSnapshotIn[] = [];

                    // создаем originalSetProductCompositions
                    each<TProductWithAlternativesModel>(
                        productWithAlternativesModel,
                        ({ product, alternatives }, index) => {
                            if (product.quantityInSet === null) {
                                throw new QuantityInSetIsNullError(
                                    orderId,
                                    product,
                                    partnerProduct.id,
                                );
                            }
                            // кладём идентификаторы моделей товаров на замену
                            const preparedAlternatives: string[] = [];

                            each<IProductItemModelSnapshotIn>(
                                alternatives,
                                (alternativeItem) => preparedAlternatives.push(alternativeItem.id),
                            );

                            const preparedAlternativesRecord: Record<string, string> = {};

                            each(preparedAlternatives, (preparedAlternativeItem: string) => {
                                preparedAlternativesRecord[preparedAlternativeItem] = preparedAlternativeItem;
                            });

                            const originalSetCompositionItem: ISetProductCompositionSnapshotIn = {
                                id: v4(),
                                index,
                                alternatives: preparedAlternativesRecord,
                                quantityInSet: product.quantityInSet!,
                                selectedProductItem: product.id,
                                originalProductItem: product.id,
                            };

                            originalProductSetComposition.push(originalSetCompositionItem);
                        },
                    );

                    if (!partnerProduct.price && !orderProduct) {
                        return;
                    }

                    const price = orderProduct ? orderProduct.price : Number(partnerProduct.price);

                    const dynamicSetItem: IProductItemModelSnapshotIn = {
                        id: v4(),
                        type: ProductItemTypeEnum.DYNAMIC_SET,
                        subType: ProductItemSubTypeEnum.MAIN,
                        partnerProductId: String(partnerProduct.id),
                        productId: String(partnerProduct.product.id),
                        mandatoryGift: false,
                        name: partnerProduct.product.name,
                        price,
                        quantityInStock: partnerProduct.quantity,
                        image: partnerProduct.image,
                        isFromPromotionBuilder: false,
                        originalSetProductCompositions: convertArrayToCollection(originalProductSetComposition),
                    };

                    this._intermediateStateCatalogsAndBaskets.pushToCatalog(dynamicSetItem);
                }
            },
        );

        /**
         * Добавляем в каталог партнерские товары, у которых признак in_currency = false. Ставим таким товарам тип - подарок.
         * Потому что у подарка нет цены
         */
        each<IPartnerProduct>(
            productsWithoutCurrency,
            (partnerProduct) => {
                if (!partnerProduct.is_product_set && !partnerProduct.is_dynamic_set) {
                    const isYetAdded = this._intermediateStateCatalogsAndBaskets.isThisGiftExistInCatalog(partnerProduct.id.toString());
                    // может быть уже добавлен, если такой товар есть в заказе
                    if (!isYetAdded) {
                        const giftProductWithoutCurrency: IProductItemModelSnapshotIn = {
                            id: v4(),
                            type: ProductItemTypeEnum.REGULAR,
                            subType: ProductItemSubTypeEnum.GIFT,
                            partnerProductId: String(partnerProduct.id),
                            productId: String(partnerProduct.product.id),
                            mandatoryGift: false,
                            name: partnerProduct.product.name,
                            price: 0,
                            quantityInStock: partnerProduct.quantity, // Кол-во товара на складе
                            image: partnerProduct.image,
                            isFromPromotionBuilder: false,
                            originalSetProductCompositions: null,
                        };

                        this._intermediateStateCatalogsAndBaskets.pushToCatalog(giftProductWithoutCurrency);
                    }
                }
            },
        );
    }

    /**
     * Функция создает определяет и формирует динамические наборы для каталога.
     *
     * @param dynamicSetItem
     * @param rawPartnerProducts
     * @param orderId - id заказа
     */
    private _addingDynamicalSetsToCatalog(
        dynamicSetItem: IOrderProduct,
        rawPartnerProducts: IPartnerProduct[],
        orderId: number,
    ): ISetProductCompositionSnapshotIn[] {
        const partnerProductInRawOrderProduct = dynamicSetItem.product.partnerProduct;

        if (!partnerProductInRawOrderProduct?.products?.length) {
            throw new SetWithEmptyProductsError(
                orderId,
                dynamicSetItem,
            );
        }

        // сюда будем класть данные о составе набора и альтернативах для каждого из них, если таковые есть
        const productWithAlternativesModel: TProductWithAlternativesModel[] = [];

        const rawDynamicSetFromOrder: IPartnerProductDynamicSetData[] = <IPartnerProductDynamicSetData[]>partnerProductInRawOrderProduct.products;

        // проваливаемся в состав набора
        each<IPartnerProductDynamicSetData>(
            rawDynamicSetFromOrder,
            (product, productIndex) => {
                // ищем в партнерских товарах такой продукт
                const setItemFoundInPartnerProducts = find<IPartnerProduct>(
                    rawPartnerProducts,
                    (partnerProduct) => partnerProduct.id === product.id,
                );

                if (!setItemFoundInPartnerProducts) {
                    throw new DidNotFindDynamicSetMappingWithPartnerProductError(
                        orderId,
                        product,
                        dynamicSetItem.id,
                    );
                }

                const productItem: IProductItemModelSnapshotIn = {
                    id: v4(),
                    type: ProductItemTypeEnum.SET_COMPOSITION_ITEM,
                    subType: ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
                    partnerProductId: String(setItemFoundInPartnerProducts.id),
                    productId: String(setItemFoundInPartnerProducts.product.id),
                    mandatoryGift: false,
                    name: setItemFoundInPartnerProducts.product.name,
                    price: null,
                    quantityInStock: setItemFoundInPartnerProducts.quantity,
                    quantityInSet: product.quantity, // количество в наборе
                    image: setItemFoundInPartnerProducts.image,
                    isFromPromotionBuilder: false,
                    originalSetProductCompositions: null,
                };

                this._intermediateStateCatalogsAndBaskets.pushToCatalog(productItem);

                productWithAlternativesModel.push({ product: productItem, alternatives: [] });

                // закидываем в модель alternatives для товара в составе набора
                each<IPartnerProductSetAlternativesData>(
                    product.alternatives,
                    (alternativeItem) => {
                        // ищем в партнерских товарах инфу по каждой альтернативе
                        const alternativeItemFoundInPartnerProducts = find<IPartnerProduct>(
                            rawPartnerProducts,
                            (partnerProduct) => partnerProduct.id === alternativeItem.id,
                        );

                        if (!alternativeItemFoundInPartnerProducts) {
                            throw new DidNotFindAComparisonOfAlternativeProductWithPartnerProductError(
                                orderId,
                                alternativeItem,
                                dynamicSetItem.id,
                            );
                        }

                        const alternativeProductItem: IProductItemModelSnapshotIn = {
                            id: v4(),
                            type: ProductItemTypeEnum.SET_COMPOSITION_ITEM,
                            subType: ProductItemSubTypeEnum.SET_COMPOSITION_ITEM,
                            partnerProductId: String(alternativeItemFoundInPartnerProducts.id),
                            productId: String(alternativeItemFoundInPartnerProducts.product.id),
                            mandatoryGift: false,
                            name: alternativeItemFoundInPartnerProducts.product.name,
                            price: null,
                            quantityInStock: alternativeItemFoundInPartnerProducts.quantity,
                            quantityInSet: product.quantity, // количество в наборе
                            image: alternativeItemFoundInPartnerProducts.image,
                            isFromPromotionBuilder: false,
                            originalSetProductCompositions: null,
                        };

                        this._intermediateStateCatalogsAndBaskets.pushToCatalog(alternativeProductItem);

                        productWithAlternativesModel[productIndex].alternatives.push(alternativeProductItem);
                    },
                );
            },
        );

        // если есть массив Set (возможно измененные товары набора), то формируем possiblyChangedProductSetComposition, который положим в BasketItemModel
        const possiblyChangedProductSetComposition: ISetProductCompositionSnapshotIn[] = [];
        const setReplaceConfiguration = dynamicSetItem.set;
        if (setReplaceConfiguration.length !== partnerProductInRawOrderProduct.products.length) {
            throw new DynamicSetBadConfigurationError(
                orderId,
                dynamicSetItem,
            );
        }

        each<TProductWithAlternativesModel>(
            productWithAlternativesModel,
            ({ product, alternatives }, index) => {
                if (product.quantityInSet === null) {
                    throw new QuantityInSetIsNullError(
                        orderId,
                        product,
                        dynamicSetItem.id,
                    );
                }

                // кладём идентификаторы моделей товаров на замену
                const preparedAlternatives: string[] = [];

                each<IProductItemModelSnapshotIn>(
                    alternatives,
                    (alternativeItem) => preparedAlternatives.push(alternativeItem.id),
                );

                const preparedAlternativesRecord: Record<string, string> = {};

                each<string>(
                    preparedAlternatives,
                    (preparedAlternativeItem) => {
                        preparedAlternativesRecord[preparedAlternativeItem] = preparedAlternativeItem;
                    },
                );

                // собираем возможно измененный setComposition, если таковой имеется
                if (setReplaceConfiguration && setReplaceConfiguration.length) {
                    const isFoundReplace = find<IProductItemModelSnapshotIn>(
                        alternatives,
                        (alternativeItem) => Number(alternativeItem.partnerProductId) === Number(setReplaceConfiguration[index].id),
                    );

                    if (!isFoundReplace) { // оставляем неизмененным
                        const possiblyChangedSetCompositionItem: ISetProductCompositionSnapshotIn = {
                            id: v4(),
                            index,
                            alternatives: preparedAlternativesRecord,
                            quantityInSet: product.quantityInSet!,
                            selectedProductItem: product.id,
                            originalProductItem: product.id,
                        };

                        possiblyChangedProductSetComposition.push(possiblyChangedSetCompositionItem);
                    } else {
                        const preparedAlternativesAfterChange: string[] = [];
                        // кладём всё из альтернатив
                        each<IProductItemModelSnapshotIn>(
                            alternatives,
                            (alternativeItem) => {
                                preparedAlternativesAfterChange.push(alternativeItem.id);
                            },
                        );

                        const preparedAlternativesAfterChangeRecord: Record<string, string> = {};

                        each<string>(
                            preparedAlternativesAfterChange,
                            (preparedAlternativeItem) => {
                                preparedAlternativesAfterChangeRecord[preparedAlternativeItem] = preparedAlternativeItem;
                            },
                        );

                        const possiblyChangedSetCompositionItem: ISetProductCompositionSnapshotIn = {
                            id: v4(),
                            index,
                            alternatives: preparedAlternativesAfterChangeRecord,
                            quantityInSet: Number(setReplaceConfiguration[index].quantity),
                            selectedProductItem: isFoundReplace.id,
                            originalProductItem: product.id,
                        };

                        possiblyChangedProductSetComposition.push(possiblyChangedSetCompositionItem);
                    }
                }
            },
        );

        return possiblyChangedProductSetComposition;
    }

    /**
     * Функция создает корзину из номенклатур, пришедших в заказе.
     *
     * @param nomenclatures - Номенклатуры (подарки, главные товары, промо-товары, статические наборы, динамические наборы).
     * @param productItemTypeEnum
     * @param productItemSubTypeEnum
     * @param orderId - id заказа
     * @param rawPartnerProducts - список партнерских товаров для текущего заказа.
     */
    private _creatingAndCapturingASnapshotOfBasketItem(
        nomenclatures: Record<number, IOrderProduct>,
        productItemTypeEnum: ProductItemTypeEnum,
        productItemSubTypeEnum: ProductItemSubTypeEnum,
        orderId: number,
        rawPartnerProducts: IPartnerProduct[] = [],
    ): void {
        each<IOrderProduct>(
            values(nomenclatures),
            (nomenclatureItem) => {
                // Проверка на наличие partnerProductId уже была выше
                const partnerProductId = nomenclatureItem.product.partnerProduct!.id;

                const isGift = productItemSubTypeEnum === ProductItemSubTypeEnum.GIFT;
                const isStaticSet = productItemTypeEnum === ProductItemTypeEnum.STATIC_SET;
                const isDynamicSet = productItemTypeEnum === ProductItemTypeEnum.DYNAMIC_SET;

                let productItemModel = this._intermediateStateCatalogsAndBaskets.getProductItemModelFromCatalogByPartnerProductIdAndProductTypeAndProductSubType(
                    partnerProductId.toString(),
                    productItemTypeEnum,
                    productItemSubTypeEnum,
                );

                if (!productItemModel) {
                    throw new ItemWasNotFoundInCatalogError(
                        orderId,
                        nomenclatureItem,
                    );
                }

                if (!isGift && productItemModel?.price !== nomenclatureItem.price) {
                    // Если цена в заказе отличается от цены в каталоге, то нужно применить в каталог цену из заказа
                    productItemModel = {
                        ...productItemModel,
                        price: nomenclatureItem.price,
                    };
                }

                let setProductCompositions: Record<string, ISetProductCompositionSnapshotIn> | null = null;
                if (isStaticSet) {
                    setProductCompositions = {
                        ...productItemModel.originalSetProductCompositions,
                    };
                }

                if (isDynamicSet) {
                    const possiblyChangedProductSetComposition = this._addingDynamicalSetsToCatalog(
                        nomenclatureItem,
                        rawPartnerProducts,
                        orderId,
                    );

                    setProductCompositions = possiblyChangedProductSetComposition.length // если есть изменения - то берем их, иначе исходный состав наборы
                        ? convertArrayToCollection(possiblyChangedProductSetComposition)
                        : { ...productItemModel.originalSetProductCompositions };
                }

                const basketItem: IBasketItemModelSnapshotIn = {
                    id: v4(),
                    existingOrderProductId: nomenclatureItem.id,
                    price: isGift ? 0 : nomenclatureItem.price,
                    quantity: nomenclatureItem.quantity,
                    productItem: productItemModel.id,
                    editablePrice: false, // TODO: редактировать цену
                    removed: false,
                    setProductCompositions,
                    main: nomenclatureItem.main,
                    withLead: nomenclatureItem.with_lead,
                    editable: nomenclatureItem.editable,
                };

                this._intermediateStateCatalogsAndBaskets.pushToBasket(basketItem);
            },
        );
    }

    /**
     * В форме заказа может прийти информация о товарах, для которых действует промо-цена
     * Такие товары нужно сформировать и добавить в каталог
     * @param orderId - id заказа
     * @param rawPartnerProducts - сырые партнерские товары (c валютой и без)
     * @param promoProducts - промо товары из currentOrder.form.promoProducts
     */
    private _fillCatalogWithPromoFromOrderForm(
        orderId: number,
        rawPartnerProducts: IPartnerProduct[],
        promoProducts: IPromoProducts[],
    ): void {
        each<IPromoProducts>(
            promoProducts,
            (rawPromoProduct) => {
                // TODO: Уточнить у бека, нормально ли искать партнерский продукт по продукту, а не партнерскому продукту.
                const partnerProduct = find<IPartnerProduct>(
                    rawPartnerProducts,
                    (partnerProduct) => rawPromoProduct.product_id === partnerProduct.product.id,
                );

                if (!partnerProduct) {
                    throw new DidNotFindComparisonOfPromotionalGoodsWithPartnerProductsError(
                        orderId,
                        rawPromoProduct,
                    );
                }

                const promoProduct: IProductItemModelSnapshotIn = {
                    id: v4(),
                    type: ProductItemTypeEnum.REGULAR,
                    subType: ProductItemSubTypeEnum.PROMO,
                    partnerProductId: String(partnerProduct.id),
                    productId: String(partnerProduct.product.id),
                    mandatoryGift: false,
                    name: partnerProduct.product.name,
                    price: rawPromoProduct.price,
                    originalPrice: partnerProduct.in_currency && partnerProduct.price ? Number(partnerProduct.price) : null, // от этой цены будем считать скидку
                    quantityInStock: partnerProduct.quantity, // Кол-во товара на складе
                    image: partnerProduct.image,
                    isFromPromotionBuilder: false,
                    originalSetProductCompositions: null,
                };

                this._intermediateStateCatalogsAndBaskets.pushToCatalog(promoProduct);
            },
        );
    }

    /**
     * Функция создает номенклатуры для главных товаров, подарков, промо-товаров, статических и динамических наборов.
     *
     * @param orderId - id заказа
     * @param rawPartnerProducts
     * @param orderProducts
     */
    private _fillBasketWithOrderProducts(
        orderId: number,
        rawPartnerProducts: IPartnerProduct[],
        orderProducts: IOrderProduct[],
    ): void {
        const {
            nomenclaturesOfMainItems,
            nomenclaturesOfPromoItems,
            nomenclaturesOfGiftItems,
            nomenclaturesOfStaticSets,
            nomenclaturesOfDynamicSets,
        } = reduce<IOrderProduct, TItemsPreparedIntoNomenclatures<Record<number, IOrderProduct>>>(
            orderProducts,
            (acc, orderProductItem) => {
                const isAppliedPromo = !!(orderProductItem.package && orderProductItem.package_id);

                const partnerProductInRawOrderProduct: IOrderPartnerProductData | null = orderProductItem.product.partnerProduct;

                if (!partnerProductInRawOrderProduct) {
                    throw new ProductInOrderWithoutPartnerProductError(
                        orderId,
                        orderProductItem,
                    );
                }

                const isGift = !isAppliedPromo
                        && orderProductItem.gift
                        && !partnerProductInRawOrderProduct.is_dynamic_set
                        && !partnerProductInRawOrderProduct.is_product_set;

                const isPromo = orderProductItem.promo
                        && !partnerProductInRawOrderProduct.is_dynamic_set
                        && !partnerProductInRawOrderProduct.is_product_set;

                const isMain = !isAppliedPromo
                        && !orderProductItem.gift
                        && !orderProductItem.promo
                        && !partnerProductInRawOrderProduct.is_dynamic_set
                        && !partnerProductInRawOrderProduct.is_product_set;

                const isStaticSet = partnerProductInRawOrderProduct.is_product_set
                    && !orderProductItem.gift
                    && !orderProductItem.promo
                    && !partnerProductInRawOrderProduct.is_dynamic_set;

                const isDynamicSet = partnerProductInRawOrderProduct.is_dynamic_set
                    && partnerProductInRawOrderProduct.is_product_set
                    && !orderProductItem.gift
                    && !orderProductItem.promo;


                if (isMain) {
                    acc.nomenclaturesOfMainItems = OrderPrepareCatalogAndBasket._turnIdenticalItemsIntoNomenclature(
                        acc.nomenclaturesOfMainItems,
                        partnerProductInRawOrderProduct.id,
                        orderProductItem,
                    );
                }

                if (isGift) {
                    acc.nomenclaturesOfGiftItems = OrderPrepareCatalogAndBasket._turnIdenticalItemsIntoNomenclature(
                        acc.nomenclaturesOfGiftItems,
                        partnerProductInRawOrderProduct.id,
                        orderProductItem,
                    );
                }

                if (isPromo) {
                    acc.nomenclaturesOfPromoItems = OrderPrepareCatalogAndBasket._turnIdenticalItemsIntoNomenclature(
                        acc.nomenclaturesOfPromoItems,
                        partnerProductInRawOrderProduct.id,
                        orderProductItem,
                    );
                }

                if (isStaticSet) {
                    acc.nomenclaturesOfStaticSets = OrderPrepareCatalogAndBasket._turnIdenticalItemsIntoNomenclature(
                        acc.nomenclaturesOfStaticSets,
                        /**
                         * Передаем уникальные ключи, т.к. нет требований от бизнеса для номенклатур статических наборов
                         */
                        orderProductItem.id,
                        orderProductItem,
                    );
                }

                if (isDynamicSet) {
                    acc.nomenclaturesOfDynamicSets = OrderPrepareCatalogAndBasket._turnIdenticalItemsIntoNomenclature(
                        acc.nomenclaturesOfDynamicSets,
                        /**
                         * Передаем уникальные ключи, т.к. нет требований от бизнеса для номенклатур динамических наборов
                         */
                        orderProductItem.id,
                        orderProductItem,
                    );
                }

                return acc;
            },
            {
                nomenclaturesOfMainItems: {},
                nomenclaturesOfPromoItems: {},
                nomenclaturesOfGiftItems: {},
                nomenclaturesOfStaticSets: {},
                nomenclaturesOfDynamicSets: {},
            },
        );

        /**
         * Перебираем и добавляем подарки без акций
         * Сразу ищем такой в партнерских товарах и добавляем его как основной товар с ценой
         */
        this._creatingAndCapturingASnapshotOfBasketItem(
            nomenclaturesOfGiftItems,
            ProductItemTypeEnum.REGULAR,
            ProductItemSubTypeEnum.GIFT,
            orderId,
        );

        /**
         * Перебираем и добавляем основные (MAIN) товары без примененных акций
         */
        this._creatingAndCapturingASnapshotOfBasketItem(
            nomenclaturesOfMainItems,
            ProductItemTypeEnum.REGULAR,
            ProductItemSubTypeEnum.MAIN,
            orderId,
        );

        /**
         * Перебираем и добавляем промо товары
         */
        this._creatingAndCapturingASnapshotOfBasketItem(
            nomenclaturesOfPromoItems,
            ProductItemTypeEnum.REGULAR,
            ProductItemSubTypeEnum.PROMO,
            orderId,
        );

        /**
         * Перебираем и добавляем статические наборы
         */
        this._creatingAndCapturingASnapshotOfBasketItem(
            nomenclaturesOfStaticSets,
            ProductItemTypeEnum.STATIC_SET,
            ProductItemSubTypeEnum.MAIN,
            orderId,
        );

        /**
         * Перебираем и добавляем динамические наборы
         */
        this._creatingAndCapturingASnapshotOfBasketItem(
            nomenclaturesOfDynamicSets,
            ProductItemTypeEnum.DYNAMIC_SET,
            ProductItemSubTypeEnum.MAIN,
            orderId,
            rawPartnerProducts,
        );
    }

    /**
     * Документация по методу расположена в ./readme.md
     * @param orderId - id заказа
     * @param countryId - к какой стране относится заказ
     * @param partnerId - к какому партнеру относится заказ
     * @param orderProducts - данные с бэка о продуктах, которые были добавлены к покупке в заказе
     * @param rawPartnerProducts - сырые данные с бэка о партнерских товарах (c валютой и без)
     * @param promoProducts - данные с бэка form.promoProducts о промо товарах
     * @param usePromotionBuilder - возможно ли применять конструктор акций в данном заказе
     * @param considerRemains - возможно ли отображать подарки которых нет на складе
     */
    public async prepareCatalogAndBasket(
        orderId: number,
        countryId: number,
        partnerId: number,
        typeId: number | undefined,
        orderProducts: IOrderProduct[],
        rawPartnerProducts: IPartnerProduct[],
        promoProducts: IPromoProducts[],
        usePromotionBuilder: boolean,
        considerRemains: boolean,
    ): Promise<void> {
        /**
         * Убираем из партнерских продуктов товары, у которых цена null и нет валюты
         */
        const partnerProducts = filter<IPartnerProduct>(
            rawPartnerProducts,
            (partnerProduct) => partnerProduct.in_currency && partnerProduct.price !== null,
        );

        /**
         * На всякий случай проверяем бэк на предмет наборов без товаров внутри. Бэк гарантирует, что такого быть не должно, но вдруг снова появится
         */
        each<IPartnerProduct>(
            partnerProducts,
            (product) => {
                if (
                    (!product.is_dynamic_set && product.is_product_set && !product.products?.length)
                    || (product.is_dynamic_set && product.is_product_set && !product.products?.length)
                ) {
                    throw new SetWithEmptyProductsError(
                        orderId,
                        product,
                    );
                }
            },
        );

        /**
         * Если у всех товаров есть promotion_builder_id и он одинаковый, значит к заказу применен КА
         */
        const isAnOrderWithPromotionBuilderApplied = OrderPrepareCatalogAndBasket._checkPromotionBuilderInOrder(
            orderProducts,
        );

        /**
         * Получаем партнерские продукты и наполняем полностью каталог
         */
        this._fillCatalogFromPartnerProducts(
            orderId,
            rawPartnerProducts,
            orderProducts,
        );

        /**
         * Перебираем промо-товары из формы заказа и добавляем их в каталог
         */
        this._fillCatalogWithPromoFromOrderForm(
            orderId,
            rawPartnerProducts,
            promoProducts,
        );

        if (!isAnOrderWithPromotionBuilderApplied) {
            /**
             * Линкуем товары из каталога в корзину в соответствии с информацией о товарах в заказе
             */
            this._fillBasketWithOrderProducts(
                orderId,
                rawPartnerProducts,
                orderProducts,
            );
        }

        /**
         * Перебираем товары и ищем примененные к ним акции
         */
        this._findProductsWithPromotionsApplied(
            orderId,
            orderProducts,
        );

        /**
         * Перебираем главные товары в каталоге и запрашиваем для каждого доступные акции
         */
        await this._requestPromotionsForEachMainProduct(
            countryId,
            partnerId,
            typeId,
        );

        /**
         * Если в заказе включена настройка применения конструктора акций, то запрашиваем список доступных КА
         */
        if (usePromotionBuilder) {
            const promotionBuilderFetchAndFillState = new PromotionBuilderFetchAndFill(
                this._promotionBuilderApiService,
                this._intermediateStateCatalogsAndBaskets,
                this._deletedPromotionsAndProducts,
            );

            const orderPrepareWithPromotionBuilderAppliedState = new OrderPrepareWithPromotionBuilderApplied(
                this._intermediateStateCatalogsAndBaskets,
                this._deletedPromotionsAndProducts,
            );

            /**
             * Для главных товаров в каталоге запрашиваем доступные конструкторы акций
             * Наполняем каталог товарами из конструкторов акций и создаем модели конструкторов акций
             */
            await promotionBuilderFetchAndFillState.fillInPromotionBuilders(
                orderId,
                rawPartnerProducts,
            );

            if (isAnOrderWithPromotionBuilderApplied) {
                /**
                 * Проверяем заказ на наличие примененного конструктора акций. Если есть, то собираем номенклатуры.
                 */
                orderPrepareWithPromotionBuilderAppliedState.searchForProductsAddedWithPromotionBuilder(
                    orderId,
                    orderProducts,
                    rawPartnerProducts,
                );
            }
        }

        if (considerRemains) {
            const considerRemainsState = new ConsiderRemains(
                this._intermediateStateCatalogsAndBaskets,
                this._deletedPromotionsAndProducts,
            );

            considerRemainsState.removeOldPromotion();
            considerRemainsState.removeSimpleGiftsFromCatalogAndBasket();
        }
    }
}


export default OrderPrepareCatalogAndBasket;
