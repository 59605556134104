import React, {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useInstances } from 'react-ioc';
import { observer } from 'mobx-react';
import cn from 'classnames';
import './CreateAppealForm.scss';
import { CustomerModeStore } from '@store/customerModeStore';
import I18NService from '@services/I18NService';
import Button from '@/app/ui-elements/Button';
import { Transition } from 'react-transition-group';
import SelectDummy from '@/app/ui-elements/SelectDummy';
import Spinner from '@/app/ui-elements/Spinner';
import TextArea from '@/app/ui-elements/TextArea';
import { useCreateAppeal } from './helpers/useCreateAppeal';

const CreateAppealForm: FunctionComponent = (): JSX.Element => {
    const [customerMode, { t }] = useInstances(CustomerModeStore, I18NService);
    const { appealCreationModel } = customerMode;

    const {
        isSubjectListLoading,
        shouldLoadSubjectList,
        setShouldLoadSubjectList,
        selectedAppealSubjectModel,
        isAppealCreationInProgress,
        isAppealSuccessfulCreated,
        isAppealCreationError,
        appealCreationError,
        resetAppealCreationModelAfterSuccessfulCreation,
    } = appealCreationModel;

    const [
        selectedSubject,
        subjectList,
        fetchSubjectList,
        onSelectSubject,
        appealText,
        onChangeAppealText,
        isFormReadyToBeSent,
        customSubject,
        onChangeCustomSubject,
        createAppeal,
    ] = useCreateAppeal();
    const [isOtherOptionSelected, setIsOtherOptionSelected] = useState<boolean>(false);

    /**
     * После создания обращения очищяем все поля
     */
    useEffect(() => {
        if (isAppealSuccessfulCreated) {
            resetAppealCreationModelAfterSuccessfulCreation();
        }
    }, [isAppealSuccessfulCreated]);

    useEffect(() => {
        if (shouldLoadSubjectList) {
            fetchSubjectList();
            setShouldLoadSubjectList(false); // один раз подгрузили и хватит
        }
    }, [shouldLoadSubjectList]);

    useEffect(() => {
        setShouldLoadSubjectList(true); // если изменили язык UI, то выполним повторный запрос тем обращений
    }, [t]);

    useEffect(() => {
        if (selectedAppealSubjectModel && selectedAppealSubjectModel.id === 'OTHER') {
            setIsOtherOptionSelected(true);
            return;
        }

        setIsOtherOptionSelected(false);
    }, [selectedAppealSubjectModel]);

    const isAppealDescriptionTextfieldDisabled = useMemo<boolean>(() => {
        const notSelectedSubject = selectedSubject.length === 0;
        let customSubjectSelectedButEmpty = false;
        if (selectedAppealSubjectModel && selectedAppealSubjectModel.id === 'OTHER' && customSubject.length === 0) {
            customSubjectSelectedButEmpty = true;
        }

        return notSelectedSubject || customSubjectSelectedButEmpty;
    }, [selectedAppealSubjectModel, selectedSubject, customSubject]);

    return (
        <div
            className="create_appeal_form_wrapper"
        >
            <div>
                {!isAppealSuccessfulCreated && (
                    <div className="headline">
                        {t('Заполните информационные поля ниже, чтобы создать обращение', 'Fill in information fields below to create appeal')}
                    </div>
                )}
                <form className="create_appeal_form">
                    <div className="customer_info_block">
                        <div className="customer_info_item">
                            <SelectDummy
                                disabled={isSubjectListLoading}
                                onChange={onSelectSubject}
                                inputId="AppealSubjectSelect"
                                invalid={false}
                                validationMessage=""
                                label={t('Выберите тему обращения', 'Choose appeal subject')}
                                hasIcon={isSubjectListLoading}
                                value={selectedSubject}
                                values={subjectList}
                            >
                                {isSubjectListLoading ? (
                                    <div
                                        className="rotating_spinner"
                                    >
                                        <Spinner />
                                    </div>
                                )
                                    : <></>}
                            </SelectDummy>
                        </div>
                    </div>
                    <Transition
                        in={isOtherOptionSelected}
                        timeout={100}
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state): JSX.Element => (
                            <div className={cn([state, 'customer_info_block'])}>
                                <div className="customer_info_item">
                                    <TextArea
                                        placeholder={t('Произвольная тема', 'Custom subject')}
                                        label={t('Заполните тему обращения', 'Fill in the subject of the appeal')}
                                        width="100%"
                                        height="64px"
                                        value={customSubject}
                                        onChange={onChangeCustomSubject}
                                    />
                                </div>
                            </div>
                        )}
                    </Transition>
                    <div className={cn([
                        isAppealDescriptionTextfieldDisabled && 'disabled',
                        'customer_info_block',
                    ])}
                    >
                        <div className="customer_info_item">
                            <TextArea
                                placeholder={t('Описание', 'Description')}
                                label={t('Описание обращения', 'Appeal description')}
                                width="100%"
                                height="110px"
                                value={appealText}
                                onChange={onChangeAppealText}
                                disabled={isAppealDescriptionTextfieldDisabled}
                            />
                        </div>
                    </div>
                    <div className="customer_info_block">
                        <Button
                            className="button__submit"
                            variant="7"
                            size="1"
                            width="150px"
                            text={t('Создать', 'Create')}
                            onClick={createAppeal}
                            disabled={!isFormReadyToBeSent || isAppealCreationInProgress}
                            isVisiblePreloader={isAppealCreationInProgress}
                            preloaderColor="#fff"
                        />
                    </div>

                    <Transition
                        in={isAppealSuccessfulCreated}
                        timeout={100}
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state): JSX.Element => (
                            <div
                                className={cn([state, 'alert_wrapper'])}
                            >
                                <p>
                                    {t('Обращение успешно создано', 'Appeal was created successfully')}
                                </p>
                            </div>
                        )}
                    </Transition>

                    <Transition
                        in={isAppealCreationError}
                        timeout={100}
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state): JSX.Element => (
                            <div
                                className={cn([state, 'errorMessage'])}
                            >
                                <p>
                                    {appealCreationError}
                                </p>
                                <p>
                                    {t('Пожалуйста, попробуйте еще раз или обратитесь в поддержку', 'Please try again or contact support')}
                                </p>
                            </div>
                        )}
                    </Transition>
                </form>
            </div>
        </div>
    );
};

export default observer(CreateAppealForm);
