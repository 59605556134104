import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useInstances } from 'react-ioc';
import { OrderService } from '@/app/services';
import I18NService from '@services/I18NService';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import OrderTableProductRow from './OrderTableProductRow';
import SwapProduct from './includes/SwapProduct';
import EmptyMainProductsNotification from '../includes/EmptyMainProductsNotification';

interface IOrderTableProps {
    readonly haveItemsInBasket: boolean;
    readonly mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved: IBasketItemModel[];
    readonly mainItemsToShow: IBasketItemModel[];
    readonly mainItems: IBasketItemModel[];
    readonly giftItemsToShow: IBasketItemModel[];
    readonly giftItems: IBasketItemModel[];
    readonly promoItemsToShow: IBasketItemModel[];
    readonly promoItems: IBasketItemModel[];
    readonly dynamicItems: IBasketItemModel[];
    readonly dynamicItemsToShow: IBasketItemModel[];
    readonly staticItems: IBasketItemModel[];
    readonly staticItemsToShow: IBasketItemModel[];
    readonly isShow: boolean;
    readonly canEditProductPrice: boolean;
    readonly isConditionForPromoCarts: boolean;
    readonly hasPromoItemsInCart: boolean;
    readonly setOrderProductPrice: (productId: number, price: number) => Promise<boolean>;
    readonly animationOnOrErrorFieldsActive: boolean;
    onAddToCatalogClick: React.Dispatch<React.SetStateAction<Record<string, 'active'>>>;
}

const OrderTable: FunctionComponent<IOrderTableProps> = ({
    haveItemsInBasket = false,
    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved = [],
    mainItemsToShow = [],
    giftItemsToShow = [],
    promoItemsToShow = [],
    isShow = false,
    isConditionForPromoCarts,
    canEditProductPrice = false,
    animationOnOrErrorFieldsActive = false,
}) => {
    const [
        orderService,
        { t },
    ] = useInstances(
        OrderService,
        I18NService,
    );

    if (orderService.currentOrder.replaceTransaction) {
        // Если есть транзакция по замене набора на другой, либо по замене товара в наборе, то показываем экран замены
        return (
            <SwapProduct isShow={isShow} />
        );
    }

    return (
        <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
            {
                haveItemsInBasket && (
                    <>
                        <div className="currentProducts__table-head">
                            <div className="currentProducts__table-col currentProducts__table-col-4">
                                {t('Товар', 'Product')}
                            </div>
                            <div className="currentProducts__table-col currentProducts__table-col-2">
                                {t('Количество', 'Quantity')}
                            </div>
                            <div
                                className="currentProducts__table-col currentProducts__table-col-1 currentProducts__table-col-price-col"
                            >
                                {t('Цена', 'Price')}
                            </div>
                            <div className="currentProducts__table-col currentProducts__table-col-1">
                                {t('Итого', 'Total')}
                            </div>
                        </div>
                        <div className="currentProducts__table-container">
                            <div
                                data-cy="main_products"
                                className="currentProducts__table-container-rows"
                            >
                                {
                                    !mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved.length && (
                                        <EmptyMainProductsNotification
                                            className="currentProducts__table-row EmptyValueForMainProducts"
                                            animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                                        />
                                    )
                                }
                                {
                                    mainItemsToShow.map((mainCartItem: IBasketItemModel) => (
                                        <OrderTableProductRow
                                            item={mainCartItem}
                                            canEditProductPrice={canEditProductPrice}
                                            key={mainCartItem.id}
                                        />
                                    ))
                                }
                            </div>
                            <div
                                data-cy="gift_products"
                                className={cn(['currentProducts__table-container-rows', giftItemsToShow.length > 0 && 'has-items'])}
                            >
                                {giftItemsToShow.map((giftCartItem: IBasketItemModel) => (
                                    <OrderTableProductRow item={giftCartItem} canEditProductPrice={canEditProductPrice} key={giftCartItem.id} />
                                ))}
                            </div>
                            <div
                                data-cy="promo_products"
                                className={cn(['currentProducts__table-container-rows', promoItemsToShow.length > 0 && 'has-items'])}
                            >
                                {isConditionForPromoCarts && (
                                    <>
                                        {promoItemsToShow.map((promoCartItem: IBasketItemModel) => (
                                            <OrderTableProductRow item={promoCartItem} canEditProductPrice={canEditProductPrice} key={promoCartItem.id} />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )
            }
            {
                !haveItemsInBasket && (
                    <EmptyMainProductsNotification
                        className="currentProducts__table-noProducts"
                        animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                    />
                )
            }
        </div>
    );
};


export default observer(OrderTable);
