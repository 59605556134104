class SpeedTestServer {
    public dlURL: string;

    public ulURL: string;

    public pingURL: string;

    public getIpURL: string;

    public server: string;

    constructor(public name: string) {}

    public static create(serverName: string): SpeedTestServer {
        const model = new SpeedTestServer(serverName);

        model.server = `https://${serverName}`;
        model.dlURL = 'speedtest/garbage.php';
        model.ulURL = 'speedtest/empty.php';
        model.pingURL = 'speedtest/empty.php';
        model.getIpURL = 'speedtest/getIP.php';

        return model;
    }
}


export default SpeedTestServer;
