import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import { action, computed } from 'mobx';
import { v4 } from 'uuid';
import each from 'lodash/each';
import { getSnapshot } from 'mobx-state-tree';

import { Store } from '@store/store';
import { BasketItemModel, IBasketItemModel, TBasketOptionalParams } from '@models/mobx-state-tree/newModels/BasketItem.model';
import { IProductItemModel, ProductItemSubTypeEnum, ProductItemTypeEnum } from '@models/mobx-state-tree/newModels/ProductItem.model';
import { ICurrentOrderModel } from '@models/mobx-state-tree/currentOrder.model';
import { ISetProductComposition } from '@models/mobx-state-tree/newModels/SetProductComposition';
import { IPromotionBuilderModel } from '@/app/models/mobx-state-tree/newModels/PromotionBuilder.models';
import ModalService from '@core/services/ModalService';
import I18NService from '@services/I18NService';
import { customI18NTFunction } from '@services/I18NService';
type TBasketItemModel = IBasketItemModel;
type BasketOptional = TBasketOptionalParams;
type TProductItemModel = IProductItemModel;
type TPromotionBuilderModel = IPromotionBuilderModel;


/**
 * Сервис, который содержит методы и вычисляемые свойства для работы с корзиной.
 */
class BasketService {
    private get _currentOrder(): ICurrentOrderModel {
        return this._store.currentOrder;
    }

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    @computed
    public get mainProductForWhichPromotionBuilderHasBeenApplied(): IBasketItemModel {
        const {
            appliedPromotionBuilder,
            additionalParamsCurrentOrder,
        } = this._currentOrder;

        if (appliedPromotionBuilder) {
            const basketItem = additionalParamsCurrentOrder.promotionBuilderBasket
                .get(appliedPromotionBuilder.mainProductModelId);

            if (basketItem) {
                return basketItem;
            }
        }

        return {} as IBasketItemModel;
    }

    constructor(
        private readonly _store: Store,
        private readonly _modalService: ModalService,
        private readonly _I18NService: I18NService,
    ) {
    }

    /**
     * Считаем количество всех товаров в корзине, включая составляющие части динамического набора. Статические наборы не трогаем.
     * Возвращает объект, в котором ключом является идентификатор партнерского товара, а значением является количество товара в корзине.
     */
    public allBasketItemsQuantityInfoByPartnerProductId(): Record<string, number> {
        const dynamicSetItemsQuantityInfo: Record<string, number> = {};

        each(this._currentOrder.dynamicSetItemsInBasket, (dynamicItem: IBasketItemModel) => { // Кирилл, сорри, редьюсами сложно)))
            const setComp = dynamicItem.getSetProductCompositions();
            if (!setComp) return;

            each(setComp, (item: ISetProductComposition) => {
                const selectedProductItem = item.getSelectedProductItem;
                if (!selectedProductItem.quantityInSet) return; // для набора не может null

                if (dynamicSetItemsQuantityInfo[selectedProductItem.partnerProductId]) {
                    dynamicSetItemsQuantityInfo[selectedProductItem.partnerProductId] += (selectedProductItem.quantityInSet * dynamicItem.quantity);
                } else {
                    dynamicSetItemsQuantityInfo[selectedProductItem.partnerProductId] = (selectedProductItem.quantityInSet * dynamicItem.quantity);
                }
            });
        });

        return reduce(this._currentOrder.allBasketItems, (acc: Record<string, number>, item: IBasketItemModel) => ({
            ...acc,
            [item.productItem.partnerProductId]: acc[item.productItem.partnerProductId] ? acc[item.productItem.partnerProductId] + item.quantity : item.quantity,
        }), dynamicSetItemsQuantityInfo);
    }

    /**
     * Сравнивает quantityInStock модели товара ProductItemModel и значение, возвращаемое в методе `allBasketItemsQuantityInfoByPartnerProductId`
     * @param productItem - модель товара
     */
    public isItemOutOfStock(productItem: IProductItemModel): boolean {
        const info = this.allBasketItemsQuantityInfoByPartnerProductId();
        const totalQuantity = info[productItem.partnerProductId];

        return totalQuantity > productItem.quantityInStock;
    }

    /**
     * Только для динамического набора. \
     * Если какой-либо из дочерних товаров набора закончился на складе, то метод вернет `true`, иначе `false`
     * @param basketItem
     */
    public isSomeOfMyChildrenItemsOutOfStock(basketItem: IBasketItemModel): boolean {
        let result = false;

        const setComp = basketItem.getSetProductCompositions();
        if (!setComp) {
            return result;
        }

        each<ISetProductComposition>(
            setComp,
            (item) => {
                const selectedProductItem = item.getSelectedProductItem;

                if (this.isItemOutOfStock(selectedProductItem)) {
                    result = true;
                }
            },
        );

        return result;
    }

    /**
     * Создать модель IBasketItemModel на основании модели IProductItemModel
     * @param catalogItem - модель IProductItemModel
     * @param basketParams - необязательные параметры IBasketItemModel
     */
    @action
    createBasketItemModel(
        catalogItem: TProductItemModel,
        basketParams?: BasketOptional,
    ): IBasketItemModel | undefined {
        const isGift = catalogItem.subType === ProductItemSubTypeEnum.GIFT;
        if (!catalogItem.price && catalogItem.price !== 0) {
            // eslint-disable-next-line no-console
            console.warn('Cannot add to basket products without price. Product snapshot:', catalogItem);
            return undefined;
        }

        return BasketItemModel.create({
            id: v4(),
            existingOrderProductId: null, // новые продукты в заказе должны быть без идентификатора в заказе (его генерит бэк)
            price: isGift ? 0 : catalogItem.price,
            quantity: 1,
            productItem: catalogItem.id,
            editablePrice: false, // TODO: редактировать цену
            removed: false,
            setProductCompositions: catalogItem.originalSetProductCompositions && getSnapshot(catalogItem.originalSetProductCompositions),
            ...basketParams,
        });
    }

    public putAnItemInBasket(
        catalogItem: TProductItemModel,
        basketParams?: BasketOptional,
    ): void | never {
        if (!catalogItem.price && catalogItem.price !== 0) {
            // eslint-disable-next-line no-console
            console.warn('Cannot add to basket products without price. Product snapshot:', catalogItem);
            throw false;
        }

        const prepareBasketItem = this.createBasketItemModel(catalogItem, basketParams);

        if (!prepareBasketItem) {
            // console.warn бросили в createBasketItemModel
            throw false;
        }

        if (basketParams?.promotionBuilderId) {
            this._currentOrder._pushToPromotionBuilderBasket(prepareBasketItem);
            return;
        }

        this._currentOrder._pushToBasket(prepareBasketItem);
        void this._currentOrder.showAnimationOnAddToBasket();
    }

    /**
     * Возвращает все таки-же товары (главные) из корзины
     * @param catalogItem - модель IProductItemModel
     */
    public findSameMainItemsInBasket(catalogItem: TProductItemModel): TBasketItemModel[] {
        return filter<TBasketItemModel>(
            this._currentOrder.allBasketItems,
            (basketItem) => basketItem.productItem.subType === catalogItem.subType
                && basketItem.productItem.type === catalogItem.type
                && Number(basketItem.productItem.partnerProductId) === Number(catalogItem.partnerProductId),
        );
    }

    /**
     * Создать модель IBasketItemModel на основании модели IProductItemModel, \
     * затем поместить модель в атрибут currentOrder.basketItemPreview
     */
    @action
    pushPreviewModelToBasket(): void {
        const { basketItemPreview } = this._currentOrder;

        if (!basketItemPreview) {
            return;
        }
        /**
         * Создаем новую модель, т.к. нельзя экземпляр модели
         * из одной ветки mobX дерева скопировать в другую - будет ругаться, что есть уже линк в другом месте.
         * Поэтому генерим новый id, копируем количество и цену (т.к. при просмотре перед добавлением их можно изменить),
         * а также копируем snapshot setProductCompositions (состав набора)
         */
        const BasketItemModelToAdd = BasketItemModel.create({
            id: v4(),
            existingOrderProductId: null,
            price: basketItemPreview.price,
            quantity: basketItemPreview.quantity,
            productItem: basketItemPreview.productItem.id,
            editablePrice: false, // TODO: редактировать цену
            removed: false,
            setProductCompositions: basketItemPreview.setProductCompositions && getSnapshot(basketItemPreview.setProductCompositions),
        });

        this._currentOrder._pushToBasket(BasketItemModelToAdd);
        this._currentOrder._deleteBasketItemPreview();
    }

    /**
     * Создать модель IBasketItemModel на основании модели IProductItemModel, \
     * затем поместить модель в атрибут currentOrder.basketItemPreview
     * @param catalogItem - модель IProductItemModel
     */
    @action
    createBasketItemModelPreview(catalogItem: TProductItemModel): void {
        const BasketItemModelForPreview = this.createBasketItemModel(catalogItem);

        if (!BasketItemModelForPreview) {
            return;
        }

        this._currentOrder._pushToBasketItemPreview(BasketItemModelForPreview);
    }

    /**
     * Проверяет, добавлен ли простой промо товар с таким partnerProductId в корзину?
     */
    public isThisSimplePromoExistsInBasket(partnerProductId: string): boolean {
        return some<IBasketItemModel[]>(
            this._currentOrder.promoItemsInBasket,
            (promoItem: IBasketItemModel) => promoItem.productItem.partnerProductId === partnerProductId
                && promoItem.productItem.type === ProductItemTypeEnum.REGULAR
                && promoItem.productItem.subType === ProductItemSubTypeEnum.PROMO,
        );
    }

    /**
     * Проверить, нет ли такого товара уже в корзине. Сравнение идет по идентификатору партнерского товара, цене, типу и подтипу товара.
     */
    private _isItemAddedToBasket(catalogItem: TProductItemModel): boolean {
        return some<IBasketItemModel>(
            this._currentOrder.allBasketItems,
            (basketItem) => basketItem.productItem.subType === catalogItem.subType
                && basketItem.productItem.type === catalogItem.type
                && basketItem.price === catalogItem.price
                && Number(basketItem.productItem.partnerProductId) === Number(catalogItem.partnerProductId),
        );
    }

    public transferringPriceOfMainProductFromPromotionBuilder(): void {
        const {
            mainItemBasketPromotionBuilder,
            appliedPromotionBuilder,
        } = this._currentOrder;

        if (appliedPromotionBuilder?.mainItemForPromotionBuilder && mainItemBasketPromotionBuilder) {
            const {
                type,
                subType,
                partnerProductId,
            } = appliedPromotionBuilder.mainItemForPromotionBuilder;

            const { price } = mainItemBasketPromotionBuilder;

            const basketItem = this.findAnItemInMainProductByTypeAndPartnerProductId(
                type,
                subType,
                partnerProductId,
            );

            if (basketItem) {
                basketItem.setPriceAfterDeletingPromotionBuilder(price);
            }
        }
    }

    /**
     * Создать модель IBasketItemModel на основании модели IProductItemModel
     * @param catalogItem - модель IProductItemModel
     * @param basketParams - необязательные параметры IBasketItemModel
     */
    @action
    public addItemFromCatalogToBasket(
        catalogItem: TProductItemModel,
        basketParams?: BasketOptional,
    ): void {
        const isPromo = catalogItem.subType === ProductItemSubTypeEnum.PROMO;
        const isYetAdded = this._isItemAddedToBasket(catalogItem);

        if (!isPromo || !isYetAdded) {
            this.putAnItemInBasket(catalogItem, basketParams);

            return;
        }
        /**
         * TODO: в интерфейсе тоже нужно показать такой нотификейшн.
         * Если в рамках реализации акций (конструктор и старые акции) окажется, что можно добавлять несколько номенклатур товара, то тудушка отпадает
         */
        // eslint-disable-next-line no-console
        console.warn('Cannot add to basket more than one row of promo item');
    }

    public findAnItemInMainProductByTypeAndPartnerProductId(
        type: ProductItemTypeEnum,
        subType: ProductItemSubTypeEnum,
        partnerProductId: string,
    ): IBasketItemModel | undefined {
        return find<IBasketItemModel>(
            this._currentOrder.allBasketItems,
            {
                productItem: {
                    type,
                    subType,
                    partnerProductId,
                },
            },
        );
    }

    /**
     * Добавить основной товар и обязательные подарки из К.А. в корзину
     * Создать модель IBasketItemModel на основании модели IProductItemModel
     * @param promotionBuilder
     */
    @action
    public addMandatoryItemsFromPromotionBuilderToBasket(
        promotionBuilder: TPromotionBuilderModel,
    ): void {
        const {
            mainItemForPromotionBuilder,
            mandatoryGifts,
        } = promotionBuilder;

        if (mainItemForPromotionBuilder) {
            /**
             *  Находим главный товар в общей корзине, к которому применяем конструктор акций.
             */
            const mainItemFromCartToWhichWeApplyPromotionBuilder = this.findAnItemInMainProductByTypeAndPartnerProductId(
                mainItemForPromotionBuilder.type,
                mainItemForPromotionBuilder.subType,
                mainItemForPromotionBuilder.partnerProductId,
            );

            const mainBasketParams: BasketOptional = {
                quantity: mainItemFromCartToWhichWeApplyPromotionBuilder?.quantity || mainItemForPromotionBuilder.amountToAdd || undefined,
                promotionBuilderId: promotionBuilder.promotionBuilderId,
                price: mainItemFromCartToWhichWeApplyPromotionBuilder?.price,
            };

            this.putAnItemInBasket(
                mainItemForPromotionBuilder,
                mainBasketParams,
            );

            // обязательные подарки, которые есть на складе:
            if (mandatoryGifts.length) {
                each<IProductItemModel>(
                    mandatoryGifts,
                    (gift) => {
                        const giftBasketParams: BasketOptional = {
                            quantity: gift.maxAmount || undefined,
                            promotionBuilderId: promotionBuilder.promotionBuilderId,
                            mandatoryGift: true,
                        };

                        this.putAnItemInBasket(
                            gift,
                            giftBasketParams,
                        );
                    },
                );
            }
        }
    }

    /**
     * Добавить опциональные подарки или промо из К.А. в корзину
     * Создать модель IBasketItemModel на основании модели IProductItemModel
     * @param catalogItem - модель IProductItemModel
     * @param basketParams - необязательные параметры IBasketItemModel
     */
    @action
    public addOptionalGiftOrPromoFromPromotionBuilderToBasket = (
        catalogItem: TProductItemModel,
        basketParams?: BasketOptional,
    ): void => {
        const { appliedPromotionBuilder } = this._currentOrder;

        this.putAnItemInBasket(catalogItem, {
            promotionBuilderId: appliedPromotionBuilder?.promotionBuilderId,
            mandatoryGift: false,
            ...basketParams,
        });
    };

    /**
     * Проверяет, добавлен ли простой промо товар с таким partnerProductId в корзину конструктора акций
     */
    public isThisSimplePromoExistsInBasketPromotionBuilder(partnerProductId: string): boolean {
        return some<IBasketItemModel[]>(
            this._currentOrder.promoItemsBasketPromotionBuilder,
            (promoItem: IBasketItemModel) => promoItem.productItem.partnerProductId === partnerProductId
                && promoItem.productItem.type === ProductItemTypeEnum.REGULAR
                && promoItem.productItem.subType === ProductItemSubTypeEnum.PROMO,
        );
    }

    public removeItemsFromProductOfPromotionBuilder(product: IBasketItemModel): void {
        this._currentOrder._removePromotionBuilderItem(product.id);
    }
}


export default BasketService;
