import { Instance } from 'mobx-state-tree';

import ValidateService from '@core/services/ValidateService';
import I18NService from '@services/I18NService';
import { CustomerModeModel, ICustomerModeModel } from '@models/mobx-state-tree/customer-mode/CustomerMode.model';
import { Store } from '@store/store';
import OrderStatusesBase from '@core/models/OrderStatusesBase';


export type IEnvCustomerStore = {
    validateService: ValidateService;
    I18NService: I18NService;
    rootStore: Store;
    orderStatusesBase: OrderStatusesBase;
};


export class CustomerModeStore {
    static create(
        rootStore: Store,
        I18NService: I18NService,
    ): ICustomerModeModel {
        const env: IEnvCustomerStore = {
            rootStore,
            I18NService,
            validateService: new ValidateService(),
            orderStatusesBase: new OrderStatusesBase(),
        };

        return CustomerModeModel.create({}, env);
    }
}


export interface CustomerModeStore extends Instance<typeof CustomerModeModel> {}
