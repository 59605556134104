import React, {
    FunctionComponent, useMemo, useRef,
} from 'react';
import cn from 'classnames';
import CallWidget from '@components/main/order-page/call-panel/call-widget/CallWidget';
import CallPanelCommunicationQualityScreen
    from '@components/main/order-page/call-panel/CallPanelCommunicationQualityScreen/CallPanelCommunicationQualityScreen';
import { useInstance } from 'react-ioc';
import { Store } from '@store/store';
import useOnClickOutside from 'use-onclickoutside';
import { observer } from 'mobx-react';
import { BottomTabEnum } from '@models/mobx-state-tree/ui/BottomMobileMenuUIStateModel';


interface ICallPanelProps {
    className?: string;
    status?: string;
    callStatus?: string;
}


const CallPanel: FunctionComponent<ICallPanelProps> = ({
    callStatus,
    className,
    status,
}) => {
    const {
        ui: {
            orderPage: {
                callPanelVisible,
                closeCallPanel,
            },
            bottomMobileTab: {
                activeBottomTab,
            },
        },
        currentOrder: {
            offersBlockTabs: {
                setIsCollapsed,
            },
        },
    } = useInstance(Store);

    const ref = useRef(null);

    const showCallPanel = useMemo<boolean>(
        () => callPanelVisible || activeBottomTab === BottomTabEnum.CALL,
        [callPanelVisible, activeBottomTab],
    );

    const handleCloseCallPanel = (): void => {
        if (showCallPanel) {
            setIsCollapsed(false);
            closeCallPanel();
        }
    };

    useOnClickOutside(ref, handleCloseCallPanel);

    return (
        <>
            <div className={cn(['overlay', showCallPanel && 'visible'])} />
            <div ref={ref} className={cn(['b-call-panel', className, status, showCallPanel && 'visible'])}>
                <div className={cn('tabs-container', 'active')}>
                    <CallWidget
                        className="call-widget__wrapper"
                        status={callStatus}
                    />
                    <CallPanelCommunicationQualityScreen />
                </div>
            </div>
        </>
    );
};


export default observer(CallPanel);
