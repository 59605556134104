import AlertIconSvg from '@components/resources/SVG/Alert/AlertIconSvg';
import getBrowserName from '@/utils/getBrowserName';
import Button from '@UIElements/Button';
import React from 'react';
import { useInstances } from 'react-ioc';

import I18NService from '@services/I18NService';
import { UserService } from '@/app/services';

import './NotAllowedBrowserMessage.scss';


const NotAllowedBrowserMessage = () => {
    const [
        { t },
        userService,
    ] = useInstances(
        I18NService,
        UserService,
    );

    return (
        <div className="browser-not-allowed">
            <div className="container">
                <div className="icon-wrapper">
                    <AlertIconSvg />
                </div>
                <div className="message">
                    {t(
                        'Вы используете {{browser}}. Для доступа к системе 2WCALL необходимо использовать браузер Google Chrome, обновлённый до последней версии. Выйдите из 2wcall, закройте вкладки в {{browser}}, войдите в 2wcall, используя Google Chrome.',
                        'You are using the {{browser}}. To access 2WCALL you always have to use the Google Chrome browser of latest version. Exit 2wcall, close tabs in the {{browser}} and login into 2wcall using Google Chrome.',
                        { browser: getBrowserName() },
                    )}
                </div>
                <div className="action">
                    <Button
                        className="button"
                        variant="7"
                        onClick={userService.signOut}
                        size="1"
                        text={t('Выйти', 'Logout')}
                        width="128px"
                    />
                </div>
            </div>
        </div>
    );
};

export default NotAllowedBrowserMessage;
