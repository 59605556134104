import { inject } from 'react-ioc';

import { customI18NTFunction } from '@services/I18NService';
import I18NService from '@services/I18NService';
import XHR404Error from '@core/error/XHR404Error';
import XHR400Error from '@core/error/XHR400Error';


class ErrorService {
    @inject
    private readonly _I18NService: I18NService;

    private get _t(): customI18NTFunction {
        return this._I18NService.t;
    }

    public XHR404Error(errorMessage = 'Data not found'): XHR404Error {
        return new XHR404Error(this._t, errorMessage);
    }

    public XHR400Error(message?: string): XHR400Error {
        return new XHR400Error(message);
    }

    public toReturnAFabricatedError<T>(Base: new (t: customI18NTFunction) => Record<string, any>): T {
        return <T> new class extends Base {}(this._t);
    }
}


export default ErrorService;
