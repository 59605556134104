import { customI18NTFunction } from '@services/I18NService';
import ErrorBase from '@core/error/ErrorBase';


class HaveNotAccessToMicrophoneError extends ErrorBase {
    constructor(t: customI18NTFunction) {
        const message = {
            key: 'Предоставьте браузеру доступ к вашему микрофону.',
            defaultValue: 'Give browser access to your microphone.',
        };

        super(message, t);
    }
}


export default HaveNotAccessToMicrophoneError;
